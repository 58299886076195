import { Space } from 'antd';
import { get, merge } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import TABLE_COLUMNS from '../../../enum/TableColumns';
import { CustomTable } from '../..';
import LinkText from '../../Text/LinkText';

const UserSettingsTable = props => {
  const { t, onUserSettingUpdate, onEditSettings, ...rest } = props;

  const onTripTrackingToggle = useCallback(
    (checked, record) => {
      const userSettings = get(record, 'settings', {});
      onUserSettingUpdate(
        get(record, '_id'),
        merge(userSettings, {
          tripTracking: checked ? 'radarSDK' : 'kliksCustom',
        }),
      );
    },
    [onUserSettingUpdate],
  );

  const COLUMNS = useMemo(
    () => [
      TABLE_COLUMNS.USER_PROFILE_NAME({ t, sorter: true, defaultSortOrder: 'ascend' }),
      TABLE_COLUMNS.EMAIL({ t, sorter: true }),
      {
        title: t('Group'),
        key: 'user.group.name',
        dataIndex: ['user', 'group', 'name'],
        render: groupName => groupName || '-',
        sorter: true,
      },
      TABLE_COLUMNS.RADAR_SDK_TRIP_TRACKING({
        t,
        sorter: true,
        onChange: onTripTrackingToggle,
      }),
      {
        width: 200,
        key: 'action',
        render: data => {
          if (STATUS_LIST().Status.DELETED === get(data, 'user.status')) return null;
          return (
            <Space direction="horizontal" align="center">
              <LinkText onClick={() => onEditSettings(data)}>{t('editSettings')}</LinkText>
            </Space>
          );
        },
      },
    ],
    [t, onTripTrackingToggle, onEditSettings],
  );

  return (
    <CustomTable
      showSearchInput={false}
      {...rest}
      rowKey={record => record._id}
      columns={COLUMNS}
    />
  );
};

export default UserSettingsTable;
