import { Form } from 'antd';
import React from 'react';

import SelectGroup from '../SelectGroup';

const GroupsFormSection = props => {
  const {
    t,
    groupOptions,
    groupValues,
    onGroupChange,
    onCreateGroup,
    groupsWithRates,
    currentRateName,
    companyGroups,
    isSubmitting,
  } = props;

  return (
    <Form.Item label={t('Select Groups')} name="groups">
      <div style={{ marginTop: 10 }}>
        <SelectGroup
          showPopover
          disabled={isSubmitting}
          currentRateName={currentRateName}
          groupsWithRates={groupsWithRates}
          options={groupOptions}
          defaultValues={groupValues}
          onGroupChange={onGroupChange}
          onCreateGroup={onCreateGroup}
          companyGroups={companyGroups}
        />
      </div>
    </Form.Item>
  );
};

GroupsFormSection.propTypes = {};

export default GroupsFormSection;
