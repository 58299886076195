import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../../components';
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';
import LinkText from '../../../../components/Text/LinkText';
import { IMAGES, INTERNAL_LINKS } from '../../../../enum';
import className from './styles.module.scss';

const SetHomeAddressBox = props => {
  const { t, onSkipClick, canSkip } = props;

  return (
    <Box className={className.card}>
      <Row>
        <img width="60px" src={IMAGES.HOME_PIN_ICON} alt="Home pin" />
      </Row>

      <br />

      <Text variant="h4">{t('setYourHomeAddress')}</Text>

      <Text>{t('appKnowsWhereYouStartEndDay')}</Text>

      <br />

      <Row align="middle" justify="space-between">
        <Col>
          <Link to={`${INTERNAL_LINKS.USER_ONBOARDING}${INTERNAL_LINKS.HOME_ADDRESS}`}>
            <Button>{t('setHomeAddress')}</Button>
          </Link>
        </Col>

        {canSkip && (
          <Col>
            <LinkText variant="strong" onClick={onSkipClick}>
              {t('skip')}
            </LinkText>
          </Col>
        )}
      </Row>
    </Box>
  );
};

export default SetHomeAddressBox;
