export const normalizeRateAuditSchema = rateAudits => {
  const normalizedArray = [];

  rateAudits.forEach(rateHistory => {
    if (rateHistory.type === 'create') {
      normalizedArray.push({
        _id: rateHistory._id,
        created: rateHistory.created,
        modifiedBy: {
          firstName: 'Kliks',
          lastName: 'System',
        },
        field: 'rate',
        oldValue: '-',
        newValue: '-',
      });
    } else {
      rateHistory.dataModified.forEach(data => {
        if (data.newValue?.ruleType) {
          normalizedArray.push({
            _id: `${data.newValue.ruleType}-${rateHistory.created}`,
            created: rateHistory.created,
            modifiedBy: rateHistory.modifiedBy,
            field: data.newValue.ruleType,
            oldValue: data.oldValue.unitRate,
            newValue: data.newValue.unitRate,
          });
        } else {
          normalizedArray.push({
            _id: rateHistory._id,
            created: rateHistory.created,
            modifiedBy: rateHistory.modifiedBy,
            field: data.field,
            oldValue: data.oldValue,
            newValue: data.newValue,
          });
        }
      });
    }
  });

  return normalizedArray;
};
