import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AddressForm from '../AddressForm';
import Box from '../Box';
import Button from '../Button';
import Text from '../Text';
import classNames from './style.module.scss';

const DEFAULT_ADDRESS_VALUES = { country: 'US' };

/**
 * Renders the Address forms for the User Details Profile page
 */
const UserAddressFormSection = props => {
  const {
    t,
    sectionTitle,
    companyAddress,
    countries,
    isSubmitting,
    addressInitialValues,
    onSubmit,
    onConfirmAddress,
    canEditAddress,
    hasConfirmedAddress,
  } = props;

  const [form] = Form.useForm();

  const [userAddress, setUserAddress] = useState({
    ...DEFAULT_ADDRESS_VALUES,
    ...addressInitialValues,
  });

  const handleUserAddressChange = (value = {}) => {
    setUserAddress({ ...userAddress, ...value });
  };

  useEffect(() => {
    if (addressInitialValues) {
      setUserAddress({ ...DEFAULT_ADDRESS_VALUES, ...addressInitialValues });
    }
  }, [addressInitialValues]);

  return (
    <Col span={24}>
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Box>
            <Row>
              <Col span={24}>
                <Row>
                  <Text variant="h5" className={classNames.formTitle}>
                    {sectionTitle}
                  </Text>
                </Row>

                <AddressForm
                  t={t}
                  formInstance={form}
                  countries={countries}
                  companyAddress={companyAddress}
                  formDisabled={!canEditAddress || isSubmitting}
                  values={userAddress}
                  onValueChange={handleUserAddressChange}
                />
              </Col>
            </Row>
            {canEditAddress && (
              <Row justify="end" gutter={16}>
                {typeof onConfirmAddress === 'function' && (
                  <Col>
                    <Button
                      loading={isSubmitting}
                      onClick={onConfirmAddress}
                      disabled={hasConfirmedAddress || !addressInitialValues?.streetOne}
                    >
                      {t('confirmAddress')}
                    </Button>
                  </Col>
                )}

                <Col>
                  <Button
                    loading={isSubmitting}
                    onClick={() => {
                      form.validateFields().then(val => onSubmit(val));
                    }}
                  >
                    {t('updateAddress')}
                  </Button>
                </Col>
              </Row>
            )}
          </Box>
        </Col>
      </Row>
    </Col>
  );
};

UserAddressFormSection.propTypes = {
  isAdmin: PropTypes.bool,
  t: PropTypes.func.isRequired,
  homeAddressInitialValues: PropTypes.shape({}),
  officeAddressInitialValues: PropTypes.shape({}),
  countries: PropTypes.arrayOf(PropTypes.shape({})),
};

UserAddressFormSection.defaultProps = {
  isAdmin: false,
  homeAddressInitialValues: {},
  officeAddressInitialValues: {},
  countries: [],
};

export default UserAddressFormSection;
