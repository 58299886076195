import React from 'react';

import { momentTimezone } from '../../../utils/common';
import { sortColumnByDateString } from '../../../utils/tables';
import { CustomTable } from '../..';

const CompanyApiKeysTable = props => {
  const { t, dataSource, ...rest } = props;

  const COLUMNS = [
    {
      title: t('token'),
      dataIndex: 'key',
    },
    {
      title: t('createdDate'),
      dataIndex: t('createdDate'),
      render: createdDate => momentTimezone(createdDate, null).format('MM-DD-YYYY'),
      ...sortColumnByDateString('createdDate'),
    },
  ];

  return (
    <CustomTable
      {...rest}
      showSearchInput={false}
      columns={COLUMNS}
      rowKey={data => data._id}
      dataSource={dataSource}
    />
  );
};

export default CompanyApiKeysTable;
