import { Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { FAVR_COMPLIANCES_API } from '../../../api/favr-compliances';
import { createInsurancePolicy, updateInsurancePolicy } from '../../../api/insurance-policies';
import { fetchUserInsurancePolicies, USER_API } from '../../../api/user';
import { HelpIcon } from '../../../components';
import Box from '../../../components/Box';
import SpaceSpinner from '../../../components/SpaceSpinner';
import StatusTag from '../../../components/Tag/StatusTag';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import UserInsuranceSettingFormContent from '../../../components/UserInsuranceSettingFormContent';
import { STATUS_LIST } from '../../../enum';
import { formatPageTitle } from '../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { normalizeUserInsurancePolicy } from '../../../utils/insurancePolicies';
import { removeFalseyParams } from '../../../utils/queryParams';
import {
  selectStoreCompanySettings,
  selectStoreCurrentAuthUser,
  selectStoreInsuranceCompany,
  useStoreSelector,
} from '../../../utils/storeSelectors';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Insurance Info')}</title>
  </Helmet>
);

const _formatInsuranceAddressFormValues = values => {
  return {
    insurancePolicyName:
      selectStoreInsuranceCompany(values.insurancePolicyName)?._id || values.insurancePolicyName,
    insuredAddress: removeFalseyParams({
      streetOne: values.streetOne,
      streetTwo: values.streetTwo,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      country: values.country,
    }),
    effectiveStartDate: getMomentDateWithoutTimezone(values.effectiveStartDate.clone()),
    effectiveEndDate: getMomentDateWithoutTimezone(values.effectiveEndDate.clone()),
  };
};

const UserInsuranceInformationView = props => {
  const { t, userDetails, userComplianceDetails, isUserDeleted } = props;

  const queryClient = useQueryClient();
  const companySettings = useStoreSelector(selectStoreCompanySettings);

  const currentAuthUser = useSelector(selectStoreCurrentAuthUser);
  const { supportedCountries } = useSelector(store => store.common);
  const [insurancePolicy, setInsurancePolicy] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const measureOneInsurancePolicyQuery = useQuery({
    enabled: !insurancePolicy && companySettings.measureOneIntegration,
    queryKey: ['fetchUserMeasureOnePolicyInformation', userDetails._id],
    queryFn: () => USER_API.fetchUserMeasureOnePolicyInformation(userDetails._id),
    onError: () => {
      Toast({
        type: 'error',
        message: t('errorLoadingUserInsurancePolicy'),
      });
    },
  });

  const insurancePolicyQuery = useQuery({
    enabled: companySettings.measureOneIntegration
      ? !!measureOneInsurancePolicyQuery.isFetched && !measureOneInsurancePolicyQuery.data
      : !insurancePolicy,
    queryKey: ['fetchUserInsurancePolicies', userDetails._id],
    queryFn: () => fetchUserInsurancePolicies(userDetails._id),
    onSuccess: policy => {
      if (policy) {
        const normalized = normalizeUserInsurancePolicy(policy[0]);
        setInsurancePolicy(normalized);
      }
    },
    onError: () => {
      Toast({
        type: 'error',
        message: t('errorLoadingUserInsurancePolicy'),
      });
    },
  });

  const handleInsuranceSubmit = async values => {
    setIsSubmitting(true);
    try {
      // If user HAS insurance
      const formattedValues = _formatInsuranceAddressFormValues(values);

      if (insurancePolicy) {
        const data = await updateInsurancePolicy(insurancePolicy._id, formattedValues);
        setInsurancePolicy(normalizeUserInsurancePolicy(data));
        Toast({
          type: 'open',
          message: t('updateInsurancePolicySuccess'),
        });
      } else {
        // Else, if user DOESN'T HAVE insurance, create one
        var formData = new FormData();
        formData.append('insurancePolicyName', values.insurancePolicyName);
        formData.append('effectiveStartDate', formattedValues.effectiveStartDate);
        formData.append('effectiveEndDate', formattedValues.effectiveEndDate);

        const insuredAddress = formattedValues.insuredAddress;
        formData.append('insuredAddress', JSON.stringify(insuredAddress));

        // formData.append('insuranceCoverages', JSON.stringify(formattedValues.insuranceCoverages));

        values.files.forEach(f => {
          formData.append('files', f.originFileObj);
        });

        const data = await createInsurancePolicy(formData);
        await confirmComplianceInsuranceInformation.mutateAsync();
        setInsurancePolicy(normalizeUserInsurancePolicy(data));
        Toast({
          type: 'open',
          message: t('createInsurancePolicySuccess'),
        });
      }
    } catch (error) {
      handleApiErrors(error.response, () => {
        if (insurancePolicy) {
          Toast({
            type: 'error',
            message: t('updateInsurancePolicyError'),
          });
        } else {
          Toast({
            type: 'error',
            message: t('createInsurnceCompaniesError'),
          });
        }
      });
    }
    setIsSubmitting(false);
  };

  const confirmComplianceInsuranceInformation = useMutation(
    () => FAVR_COMPLIANCES_API.confirmComplianceInformation('insurance'),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['fetchUserFavrComplianceStatuses', userDetails._id]),
    },
  );

  if (
    companySettings.measureOneIntegration
      ? measureOneInsurancePolicyQuery.isFetching || insurancePolicyQuery.isFetching
      : typeof insurancePolicy === 'undefined'
  ) {
    return (
      <Box>
        <PageHelmet />
        <SpaceSpinner />
      </Box>
    );
  }

  return (
    <Box>
      <PageHelmet />
      <Space direction="vertical" size="small">
        <Row align="middle" gutter={[16, 16]}>
          <Col>
            <Text variant="h5" style={{ margin: 0 }}>
              {t('insurancePolicyInfo')}
            </Text>
          </Col>

          {userComplianceDetails?.insuranceComplianceStatus && (
            <Col>
              <StatusTag status={userComplianceDetails.insuranceComplianceStatus} />
            </Col>
          )}

          {userComplianceDetails?.insuranceComplianceStatus === STATUS_LIST().Status.APPROVED && (
            <Col>
              <HelpIcon variant="info" hint={t('modifyingInsuranceDriverLicenseWarning')} />
            </Col>
          )}
        </Row>

        <UserInsuranceSettingFormContent
          t={t}
          disabled={isUserDeleted || userDetails._id !== currentAuthUser.profile._id}
          insurancePolicy={insurancePolicy}
          canUpdateMeasureOneInsuranceDoc={userDetails._id === currentAuthUser.profile._id}
          measureOneInsurancePolicy={measureOneInsurancePolicyQuery.data}
          userComplianceDetails={userComplianceDetails}
          supportedCountries={supportedCountries}
          user={userDetails}
          isSubmitting={isSubmitting || confirmComplianceInsuranceInformation.isLoading}
          onSubmit={handleInsuranceSubmit}
          onConfirm={confirmComplianceInsuranceInformation.mutateAsync}
        />
      </Space>
    </Box>
  );
};

export default UserInsuranceInformationView;
