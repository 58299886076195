import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';

import { getUserFullName, getUserInitials, momentFormat } from '../../../utils/common';
import { parseUserComplianceStatus } from '../../../utils/user';
import AnonymizedAsterisk from '../../AnonymizedAsterisk';
import Avatar from '../../Avatar';
import OutOfComplianceIcon from '../../OutOfComplianceIcon';
import SummaryCardColumn from '../../shared-ui/SummaryCardColumn';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';

const ReceiptUserColumn = ({ t, receipt }) => {
  const userName = getUserFullName(receipt.submittedBy);

  const [isOutOfCompliance, outOfComplianceComponents] = useMemo(
    () => parseUserComplianceStatus(get(receipt, 'complianceStatus')),
    [receipt],
  );

  return (
    <Col xs={24} sm={12} lg={8}>
      <Row gutter={6} align="start" justify="start" style={{ flexWrap: 'nowrap', width: '100%' }}>
        <Col flex="32px">
          {isOutOfCompliance ? (
            <OutOfComplianceIcon t={t} outOfComplianceComponents={outOfComplianceComponents} />
          ) : (
            <Avatar
              size="small"
              shape="circle"
              src={receipt.submittedBy.profilePicture}
              alt={userName}
              initials={getUserInitials(receipt.submittedBy)}
            />
          )}
        </Col>
        <Col flex={1}>
          <SummaryCardColumn title={momentFormat(receipt.created, 'ddd, DD MMM YYYY')}>
            <Row gutter={6} align="middle" justify="start" wrap={false} style={{ width: '100%' }}>
              <Col>
                <Text
                  color="secondary"
                  title={receipt.submittedBy.isAnonymized ? t('anonymizedUser') : userName}
                  variant="strong"
                  size="xl"
                  renderAs="div"
                >
                  {userName} {receipt.submittedBy.isAnonymized && <AnonymizedAsterisk t={t} />}
                </Text>
              </Col>

              <Col flex="80px">
                <StatusTag size="small" status={receipt.submittedBy.status} />
              </Col>
            </Row>
          </SummaryCardColumn>
        </Col>
      </Row>
    </Col>
  );
};

export default ReceiptUserColumn;
