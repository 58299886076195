import { Col, Row } from 'antd';
import React from 'react';

import { renderCheckmarkFromBoolean } from '../../utils/tables';
import Text from '../Text';

const VehicleComplianceItemsList = props => {
  const { t, complianceComponents } = props;

  return (
    <Row gutter={[0, 10]}>
      <Col flex="100%">
        <Row align="middle" gutter={5} wrap={false}>
          <Col>{renderCheckmarkFromBoolean(complianceComponents?.vehicleYearCompliance)}</Col>
          <Col flex={1}>
            <Text size="sm">{t('vehicleYearMeetsRetentionPeriodReqs')}</Text>
          </Col>
        </Row>
      </Col>
      <Col flex="100%">
        <Row align="middle" gutter={5} wrap={false}>
          <Col>{renderCheckmarkFromBoolean(complianceComponents?.depreciationCompliance)}</Col>
          <Col flex={1}>
            <Text size="sm">{t('vehicleMeetsTaxDepreciationReqs')}</Text>
          </Col>
        </Row>
      </Col>
      <Col flex="100%">
        <Row align="middle" gutter={5} wrap={false}>
          <Col>{renderCheckmarkFromBoolean(complianceComponents?.odometerReading)}</Col>
          <Col flex={1}>
            <Text size="sm">{t('vehicleYearMeetsOdometerReadingReqs')}</Text>
          </Col>
        </Row>
      </Col>
      <Col flex="100%">
        <Row align="middle" gutter={5} wrap={false}>
          <Col>{renderCheckmarkFromBoolean(complianceComponents?.msrpCompliance)}</Col>
          <Col flex={1}>
            <Text size="sm">{t('vehicleMeetsMsrpReqs')}</Text>
          </Col>
        </Row>
      </Col>
      <Col flex="100%">
        <Row align="middle" gutter={5} wrap={false}>
          <Col>
            {renderCheckmarkFromBoolean(complianceComponents?.electronicSignatureCompliance)}
          </Col>
          <Col flex={1}>
            <Text size="sm">{t('vehicleMeetsElectronicSignatureReqs')}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default VehicleComplianceItemsList;
