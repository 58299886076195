import { Col, DatePicker, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { ERROR_MESSAGE } from '../../utils/constants';
import { getApprovalSettingValue } from '../../utils/rates';
import { selectStoreCountryByCode } from '../../utils/storeSelectors';
import Checkbox from '../Checkbox';
import Select from '../Select';
import Text from '../Text';
import TextInput from '../TextInput';
import classNames from './style.module.scss';

/**
 * Reimbursement approval configuration form section
 *
 * @param {object} props Component props
 */
const ApprovalSettingsFormSection = props => {
  const {
    t,
    rateCountry,
    approvalConfig,
    approvalSetting,
    onApprovalSettingChange,
    onApprovalConfigChange,
    approvalConfigOptions,
    effectiveDate,
    onEffectiveDateChange,
    tripApprovalWorkflow,
    fixedReceiptAutoApproval,
    onFormValueChange,
    isSubmitting,
    isFAVR,
  } = props;

  const DISTANCE_LIMIT = 'distance';
  const GPS_LIMIT = 'gps_percentage';
  const REIMBURSEMENT_LIMIT = 'reimbursement';

  const [selectedLimits, setSelectedLimits] = useState(
    Array.isArray(approvalSetting) ? approvalSetting.map(s => s.approvalType) : [],
  );
  const [distanceSetting, setDistanceSetting] = useState(
    getApprovalSettingValue(DISTANCE_LIMIT, approvalSetting),
  );
  const [gpsSetting, setGpsSetting] = useState(getApprovalSettingValue(GPS_LIMIT, approvalSetting));
  const [reimbursementSetting, setReimbursementSetting] = useState(
    getApprovalSettingValue(REIMBURSEMENT_LIMIT, approvalSetting),
  );

  const country = useMemo(() => selectStoreCountryByCode(rateCountry), [rateCountry]);

  const APPROVAL_OPTIONS = [
    {
      value: 'auto',
      label: t('automaticApprovalAll'),
    },
    ...(!!tripApprovalWorkflow
      ? [
          {
            value: 'semi',
            label: t('automaticApprovalWithLimits'),
          },
          {
            value: 'manual',
            label: t('managerApprovalOnlyAll'),
          },
        ]
      : []),
  ];

  const LIMITS = [
    {
      label: t('tripsApprovedIfDistanceLessThan'),
      value: DISTANCE_LIMIT,
      placeholder: t('enterWholeNumber'),
      limit: distanceSetting,
      onChange: setDistanceSetting,
      inputProps: { suffix: country.distanceShort },
    },
    {
      label: t('percentOfTripsRecordedEqualOrHigher'),
      value: GPS_LIMIT,
      placeholder: t('enterWholeNumber'),
      limit: gpsSetting,
      onChange: setGpsSetting,
      inputProps: { suffix: '%' },
    },
    {
      label: t('totalRbAmountEqualOrLess'),
      value: REIMBURSEMENT_LIMIT,
      placeholder: t('enterWholeNumber'),
      limit: reimbursementSetting,
      onChange: setReimbursementSetting,
      inputProps: { suffix: country.currencyCode },
    },
  ];

  useEffect(() => {
    const updatedApprovalSetting = [];

    if (selectedLimits.includes(DISTANCE_LIMIT)) {
      updatedApprovalSetting.push({
        limit: parseFloat(distanceSetting),
        approvalType: DISTANCE_LIMIT,
      });
    }

    if (selectedLimits.includes(GPS_LIMIT)) {
      updatedApprovalSetting.push({
        limit: parseFloat(gpsSetting),
        approvalType: GPS_LIMIT,
      });
    }

    if (selectedLimits.includes(REIMBURSEMENT_LIMIT)) {
      updatedApprovalSetting.push({
        limit: parseFloat(reimbursementSetting),
        approvalType: REIMBURSEMENT_LIMIT,
      });
    }

    onApprovalSettingChange(updatedApprovalSetting);
    // eslint-disable-next-line
  }, [selectedLimits, distanceSetting, gpsSetting, reimbursementSetting]);

  return (
    <>
      <div style={{ marginBottom: 15, lineHeight: 14 }}>
        <Text variant="p" size="sm" renderAs="div">
          {t('effectiveDateDefinition')}
        </Text>
        <Text variant="p" size="sm" renderAs="div">
          {t('changesTakeEffectImmediately')}
        </Text>
      </div>

      <Form.Item
        label={t('effectiveDate')}
        name="effectiveDate"
        rules={[
          {
            required: true,
            message: ERROR_MESSAGE().BLANK_FIELD,
          },
        ]}
      >
        <DatePicker
          disabled={isSubmitting}
          allowClear={false}
          defaultValue={effectiveDate}
          format={'MM-DD-YYYY'}
          onChange={onEffectiveDateChange}
        />
      </Form.Item>

      <Form.Item
        name="approvalConfig"
        label={t('selectHowThisRateShouldApprove')}
        rules={[
          {
            required: true,
            message: ERROR_MESSAGE().BLANK_FIELD,
          },
        ]}
      >
        <Select
          disabled={isSubmitting}
          placeholder={t('select')}
          value={approvalConfig}
          onChange={onApprovalConfigChange}
          options={APPROVAL_OPTIONS}
        />
      </Form.Item>

      <Form.Item name="fixedReceiptAutoApproval" hidden={!isFAVR}>
        <Checkbox
          disabled={isSubmitting}
          checked={fixedReceiptAutoApproval}
          onChange={e => onFormValueChange({ fixedReceiptAutoApproval: e.target.checked })}
        >
          {t('automaticallyApproveFixedRBs')}
        </Checkbox>
      </Form.Item>

      {approvalConfig === approvalConfigOptions.SEMI && (
        <div>
          <div className="approval-configuration">
            <div>
              {LIMITS.map(limit => (
                <Row key={limit.label} gutter={[17, 17]} align="start">
                  <Col flex="1 1 465px">
                    <Checkbox
                      disabled={isSubmitting}
                      style={{ marginTop: 8 }}
                      checked={selectedLimits.includes(limit.value)}
                      value={limit.value}
                      onChange={e =>
                        e.target.checked
                          ? setSelectedLimits([...selectedLimits, e.target.value])
                          : setSelectedLimits([...selectedLimits].filter(v => v !== limit.value))
                      }
                    >
                      {limit.label}
                    </Checkbox>
                  </Col>
                  <Col flex="210px">
                    <Form.Item
                      name={limit.value}
                      className={classNames.formItemsWithNoMargin}
                      rules={[
                        {
                          required: selectedLimits.includes(limit.value),
                          message: ERROR_MESSAGE().BLANK_FIELD,
                        },
                      ]}
                    >
                      <TextInput
                        {...limit.inputProps}
                        disabled={!selectedLimits.includes(limit.value) || isSubmitting}
                        value={limit.limit}
                        placeholder={limit.placeholder}
                        onChange={e => limit.onChange(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ApprovalSettingsFormSection.propTypes = {};

export default ApprovalSettingsFormSection;
