import { Col, Collapse, Row, Timeline } from 'antd';
import classnames from 'classnames';
import React, { useState } from 'react';

import BottomBorderTextRows from '../../components/BottomBorderTextRows';
import HorizontalLineDivider from '../../components/HorizontalLineDivider';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import LinkText from '../../components/Text/LinkText';
import { IMAGES } from '../../enum';
import { momentTimezone } from '../../utils/common';
import { convertMillisecondsIntoReadableTime } from '../../utils/datetime';
import { formatCoordinatesToGoogleMapsURL } from '../../utils/map';
import classNames from './styles.module.scss';

const TimelineSection = props => {
  const { t, trips, isOptimalRoute, destinationLabelsChangedOnOptimization } = props;

  const getStopDuration = (trip, index) => {
    if (index > 0) {
      const arrivalTime = momentTimezone(trip.tripEndTime, 'h:mma');
      const departure = trips[index + 1]?.tripStartTime;
      const departureTime = momentTimezone(departure, 'h:mma');

      if (departure) {
        return Math.abs(departureTime.diff(arrivalTime, 'minutes'));
      }
    }
  };

  const setTripDetailsArray = (trip, index) => {
    const array = [];

    if (index > 0) {
      array.push({ label: t('arrive'), value: trip.tripEndTime });

      const stopDuration = getStopDuration(trip, index);
      if (typeof stopDuration !== 'undefined' && stopDuration > 0) {
        array.push({ label: t('stopDuration'), value: `${stopDuration}min` });
      }
    }

    if (index === 0) {
      array.push({ label: t('depart'), value: trip.tripStartTime });
    } else if (index > 0 && trips[index + 1]?.tripStartTime) {
      array.push({ label: t('depart'), value: trips[index + 1]?.tripStartTime });
    }

    return array;
  };

  const [expandedKeys, setExpandedKeys] = useState([]);

  const collapseRow = rowKey => setExpandedKeys(keys => keys.filter(key => key !== rowKey));
  const expandRow = rowKey => setExpandedKeys(keys => [...keys, rowKey]);

  return (
    <Row className={classNames.timelineSection}>
      <Col flex={1}>
        <Timeline mode="left" className={classNames.timelineWrapper}>
          {trips.map((trip, i) => (
            <Timeline.Item
              key={i}
              label={<Text variant="b">{trip.label}</Text>}
              color={
                isOptimalRoute && destinationLabelsChangedOnOptimization.includes(trip.label)
                  ? 'green'
                  : 'gray'
              }
              dot={
                i + 1 === trips.length ? (
                  <img width="20px" src={IMAGES.GRAY_PIN_ICON} alt="pin" />
                ) : undefined
              }
            >
              <Collapse
                ghost
                activeKey={expandedKeys}
                expandIcon={({ showArrow, collapsible, isActive }) => {
                  if (showArrow && collapsible) {
                    return (
                      <div
                        onClick={() => (isActive ? collapseRow(trip.label) : expandRow(trip.label))}
                        className={
                          isActive
                            ? classnames(classNames.expandArrow, classNames.expanded)
                            : classnames(classNames.expandArrow, classNames.collapsed)
                        }
                      >
                        <img src={IMAGES.GREEN_CHEVRON_DOWN_ICON} alt="expand icon" />
                      </div>
                    );
                  }

                  return null;
                }}
                expandIconPosition="right"
                className={classNames.collapseWrapper}
              >
                <Collapse.Panel
                  key={trip.label}
                  collapsible={i > 0 && !!trip.itineraryNotes ? 'header' : 'disabled'}
                  showArrow={i > 0 && !!trip.itineraryNotes}
                  header={
                    <div className={classNames.timelineRowItem}>
                      <div>
                        <LinkText
                          renderAs="a"
                          rel="noopener noreferrer"
                          target="_blank"
                          variant="b"
                          href={formatCoordinatesToGoogleMapsURL(
                            trip.coordinates[0],
                            trip.coordinates[1],
                          )}
                        >
                          {trip.address}
                        </LinkText>
                      </div>

                      <div className={classNames.tripDetailsTableSection}>
                        <BottomBorderTextRows
                          borderless
                          rowContent={setTripDetailsArray(trip, i)}
                        />
                      </div>
                    </div>
                  }
                >
                  <Row gutter={[10, 10]}>
                    {trip.itineraryNotes && (
                      <Col span={24}>
                        <Text variant="b">Notes:</Text>
                        <Text>{trip.itineraryNotes}</Text>
                      </Col>
                    )}
                  </Row>
                </Collapse.Panel>
              </Collapse>

              {(i === 0 || (i > 0 && !!trips[i + 1]?.tripStartTime)) && (
                <Row
                  className={classNames.travelTimeSection}
                  wrap={false}
                  align="middle"
                  gutter={[10, 10]}
                >
                  <Col>
                    <img width="24px" height="24px" src={IMAGES.CAR_RETRO_DARK_ICON} alt="car" />
                  </Col>
                  <Col>
                    <FadedText size="sm" variant="b">
                      {i === 0 &&
                        t('nTravelTime', {
                          time: convertMillisecondsIntoReadableTime(trip.visitDuration),
                        })}

                      {i > 0 &&
                        !!trips[i + 1]?.tripStartTime &&
                        t('nTravelTime', {
                          time: convertMillisecondsIntoReadableTime(trips[i + 1]?.visitDuration),
                        })}
                    </FadedText>
                  </Col>

                  <Col flex={1}>
                    <HorizontalLineDivider color="rgba(118, 123, 132, 0.2)" />
                  </Col>
                </Row>
              )}
            </Timeline.Item>
          ))}
        </Timeline>
      </Col>
    </Row>
  );
};

export default TimelineSection;
