import { Badge, Col, Row, Tabs } from 'antd';
import queryString from 'querystring';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { COMPANY_LOCATIONS_API } from '../../api/company-locations';
import { fetchLocationImportRequests, uploadLocationsCsvFile } from '../../api/import-requests';
import { HelpIcon } from '../../components';
import Box from '../../components/Box';
import Button from '../../components/Button';
import ImportStatusCSVSection from '../../components/ImportStatusCSVSection';
import Spinner from '../../components/Spinner';
import LocationsTable from '../../components/Table/LocationsTable';
import TabsContainer from '../../components/TabsContainer';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { STATUS_LIST } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useFileInput from '../../hooks/useFileInput';
import useTablePagination from '../../hooks/useTablePagination';
import { formatPageTitle } from '../../utils/common';
import { canUpdateImportLocations } from '../company/company-settings/company-settings-permissions';
import ImportStatusAPISection from './ImportStatusAPISection';
import { LOCATIONS_CSV_EMPTY_DATA, LOCATIONS_CSV_HEADERS } from './locationsCsvTemplate';
import UploadLocationsSection from './UploadLocationsSection';

export const LOCATION_TAB = 'locations';
export const LOCATION_IMPORT_CSV_TAB = 'import-csv';
export const LOCATION_IMPORT_API_TAB = 'import-api';

const CompanyLocations = props => {
  const { t, history, location } = props;

  const { currentCompany } = useSelector(store => store.common);
  const companySettings = currentCompany.companySettingId || {};

  const { activeTab } = queryString.parse(location.search.replace('?', ''));

  const [activePane, setActivePane] = useState(activeTab || LOCATION_TAB);

  const locationsPagination = useTablePagination();
  const locationsQuery = useQuery({
    queryKey: [
      'fetchCompanyLocations',
      locationsPagination.paginationConfig.current,
      locationsPagination.paginationConfig.pageSize,
    ],
    placeholderData: { documents: [], totalCount: 0 },
    queryFn: () =>
      COMPANY_LOCATIONS_API.fetchCompanyLocations(
        locationsPagination.paginationConfig.current,
        locationsPagination.paginationConfig.pageSize,
      ),
    onSuccess: ({ totalCount }) => locationsPagination.handleTotalUpdate(totalCount),
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorLoadingCompanyLocations'),
        });
      });
    },
  });

  const CAN_IMPORT_LOCATION = canUpdateImportLocations();
  const HAS_LOCATIONS =
    Array.isArray(locationsQuery.data?.documents) && !!locationsQuery.data.documents.length;
  const HAS_EMPTY_LOCATIONS =
    !locationsQuery.data ||
    (Array.isArray(locationsQuery.data?.documents) && !locationsQuery.data.documents.length);
  const HAS_CSV_SETTING_ENABLED = companySettings.values?.importLocationsMethod === 'csv';

  const importRequestPagination = useTablePagination({ initialPageSize: 10 });
  const locationsImportRequestQuery = useQuery({
    enabled: !(!CAN_IMPORT_LOCATION || !HAS_CSV_SETTING_ENABLED),
    queryKey: [
      'fetchLocationImportRequests',
      currentCompany._id,
      importRequestPagination.paginationConfig.current,
      importRequestPagination.paginationConfig.pageSize,
    ],
    placeholderData: { documents: [], totalCount: 0 },
    queryFn: () =>
      fetchLocationImportRequests(
        currentCompany._id,
        importRequestPagination.paginationConfig.current,
        importRequestPagination.paginationConfig.pageSize,
      ),
    onSuccess: ({ totalCount }) => importRequestPagination.handleTotalUpdate(totalCount),
    onError: error => handleApiErrors(error.response),
  });

  const uploadLocationsCsvMutation = useMutation(file => uploadLocationsCsvFile(file), {
    onSuccess: () => {
      locationsImportRequestQuery.refetch();
      Toast({
        type: 'open',
        message: t('csvUploadSuccess'),
      });
    },
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('csvImportError'),
        });
      }),
  });

  const handleHiddenCSVSelect = useFileInput(
    files => {
      if (files[0]) {
        handleTabChange(LOCATION_IMPORT_CSV_TAB);
        return uploadLocationsCsvMutation.mutateAsync(files[0]);
      }
    },
    { accept: 'text/csv', multiple: false },
  );

  const PENDING_REQUESTS_COUNT =
    CAN_IMPORT_LOCATION &&
    companySettings.values?.importLocationsMethod === 'csv' &&
    Array.isArray(locationsImportRequestQuery.data?.documents)
      ? locationsImportRequestQuery.data.documents.filter(
          req => req.status === STATUS_LIST().Status.AWAITING_CONFIRMATION,
        ).length
      : 0;

  const handleTabChange = tabKey => {
    setActivePane(tabKey);
    history.replace({ search: `activeTab=${tabKey}` });
  };

  return (
    <PageContainer
      loading={locationsQuery.isFetching && locationsImportRequestQuery.isFetching}
      title={t('manageLocations')}
      sideActionComponent={
        <>
          <Helmet>
            <title>{formatPageTitle('Company Locations')}</title>
          </Helmet>

          {HAS_CSV_SETTING_ENABLED && (
            <Row gutter={10} wrap={false} align="middle">
              <Col>
                <Button
                  loading={uploadLocationsCsvMutation.isLoading}
                  disabled={
                    PENDING_REQUESTS_COUNT > 0 ||
                    uploadLocationsCsvMutation.isLoading ||
                    !CAN_IMPORT_LOCATION
                  }
                  onClick={() => {
                    const uploaderButton = document.querySelector(
                      '.ant-upload.ant-upload-drag button',
                    );

                    if (uploaderButton) {
                      uploaderButton.click();
                    } else {
                      handleHiddenCSVSelect();
                    }
                  }}
                >
                  {t('uploadCSV')}
                </Button>
              </Col>

              {PENDING_REQUESTS_COUNT !== 0 && (
                <Col>
                  <HelpIcon
                    hint={
                      <div>
                        <Text variant="p" size="sm" style={{ marginBottom: 10 }}>
                          {t('importLocationOnlyOneCSV')}
                        </Text>
                        <Text variant="p" size="sm">
                          {t('importLocationCancelExistingProcess')}
                        </Text>
                      </div>
                    }
                  />
                </Col>
              )}
            </Row>
          )}
        </>
      }
    >
      {activePane === LOCATION_TAB && (
        <>
          <Text>
            <Trans
              t={t}
              i18nKey="locationsPageDescription"
              components={[
                <CSVLink
                  target="_self"
                  filename={t('importLocationsTemplateCsv')}
                  headers={LOCATIONS_CSV_HEADERS}
                  data={LOCATIONS_CSV_EMPTY_DATA}
                >
                  DummyText
                </CSVLink>,
              ]}
            />
          </Text>
          <br />
        </>
      )}

      <Box>
        <TabsContainer activeKey={activePane} onChange={handleTabChange}>
          <Tabs.TabPane
            key={LOCATION_TAB}
            tab={t('locationsWithCount', {
              count: Array.isArray(locationsQuery.data?.documents)
                ? locationsQuery.data.documents.length
                : 0,
            })}
          >
            {((HAS_CSV_SETTING_ENABLED && HAS_LOCATIONS) || !HAS_CSV_SETTING_ENABLED) && (
              <LocationsTable
                t={t}
                loading={locationsQuery.isFetching}
                dataSource={locationsQuery.data?.documents}
                hiddenColumns={['streetOne', 'streetTwo']}
                onChange={({ current }) => locationsPagination.handlePageChange(current)}
                pagination={{
                  pageSize: locationsPagination.paginationConfig.pageSize,
                  total: locationsPagination.paginationConfig.total,
                  current: locationsPagination.paginationConfig.current,
                  onShowSizeChange: locationsPagination.handlePageSizeChange,
                }}
              />
            )}

            {HAS_CSV_SETTING_ENABLED && HAS_EMPTY_LOCATIONS && (
              <UploadLocationsSection
                t={t}
                loading={uploadLocationsCsvMutation.isLoading}
                disabled={!CAN_IMPORT_LOCATION || PENDING_REQUESTS_COUNT > 0}
                onUploadSuccess={() => {
                  handleTabChange(LOCATION_IMPORT_CSV_TAB);
                  locationsImportRequestQuery.refetch();
                }}
                csvLink={
                  <CSVLink
                    target="_self"
                    filename={t('importLocationsTemplateCsv')}
                    headers={LOCATIONS_CSV_HEADERS}
                    data={LOCATIONS_CSV_EMPTY_DATA}
                  >
                    {t('importLocationsTemplateCsv')}
                  </CSVLink>
                }
              />
            )}

            {locationsQuery.isFetching && HAS_CSV_SETTING_ENABLED && (
              <Row justify="center" align="middle">
                <Col>
                  <Spinner />
                </Col>
              </Row>
            )}
          </Tabs.TabPane>

          <Tabs.TabPane
            key={LOCATION_IMPORT_CSV_TAB}
            disabled={!CAN_IMPORT_LOCATION || !HAS_CSV_SETTING_ENABLED}
            tab={
              <Badge size="small" count={PENDING_REQUESTS_COUNT}>
                <span style={{ paddingRight: 15 }}>{t('importLocationsStatusCSV')}</span>
              </Badge>
            }
          >
            <ImportStatusCSVSection
              t={t}
              importType="locations"
              importRequests={locationsImportRequestQuery.data?.documents}
              isLoadingImportRequests={locationsImportRequestQuery.isFetching}
              onPageChange={page => importRequestPagination.handlePageChange(page)}
              pagination={{
                pageSize: importRequestPagination.paginationConfig.pageSize,
                total: importRequestPagination.paginationConfig.total,
                current: importRequestPagination.paginationConfig.current,
              }}
            />
          </Tabs.TabPane>

          <Tabs.TabPane
            key={LOCATION_IMPORT_API_TAB}
            tab={t('importLocationViaAPI')}
            disabled={
              companySettings.values?.importLocationsMethod !== 'api' || !CAN_IMPORT_LOCATION
            }
          >
            <ImportStatusAPISection t={t} />
          </Tabs.TabPane>
        </TabsContainer>
      </Box>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(CompanyLocations));
