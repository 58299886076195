import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import useAdminsAndManagersUsersLists from '../../../hooks/useAdminsAndManagersUsersLists';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, getUserFullName } from '../../../utils/common';
import {
  renderBooleanCheckmarks,
  sortColumnByBoolean,
  sortColumnByStringField,
} from '../../../utils/tables';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import LinkText from '../../Text/LinkText';

const GroupsTable = props => {
  const { t, dataSource, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();
  const { groupManagersLists } = useAdminsAndManagersUsersLists();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(group => {
        const rateName = group.productId ? group.productId.name : '-';

        return (
          checkIfStringContainsValue(group.name, searchTerm) ||
          checkIfStringContainsValue(`${group.users.length}`, searchTerm) ||
          checkIfStringContainsValue(group.defaultGroup ? 'Yes' : 'No', searchTerm) ||
          checkIfStringContainsValue(getUserFullName(group.groupManager), searchTerm) ||
          checkIfStringContainsValue(rateName, searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      defaultSortOrder: 'ascend',
      title: t('groupName'),
      dataIndex: 'name',
      key: 'group',
      ...sortColumnByStringField('name'),
      render: (actions, data) =>
        data._id ? (
          <Link to={INTERNAL_LINKS.GROUP + '/' + data._id}>
            <LinkText variant="strong" size="sm">
              {data.name}
            </LinkText>
          </Link>
        ) : (
          data.name
        ),
    },
    {
      title: t('groupManager'),
      dataIndex: 'groupManager',
      key: 'groupManager',
      sorter: {
        compare: (a, b) =>
          getUserFullName(a.groupManager).localeCompare(getUserFullName(b.groupManager)),
      },
      render: (actions, data) => {
        const groupManagers = [];
        if (data.groupManager) {
          groupManagers.push(
            <Col xs={24}>
              <ProfileNamePicture t={t} user={data.groupManager} />
            </Col>,
          );
        }

        data.additionalManagers.forEach(managerId => {
          const user = groupManagersLists.find(user => user._id === managerId);
          if (user) {
            groupManagers.push(
              <Col xs={24}>
                <ProfileNamePicture t={t} user={user} />
              </Col>,
            );
          }
        });

        return <Row gutter={[0, 10]}>{groupManagers}</Row>;
      },
    },
    {
      title: t('rate'),
      key: 'product',
      dataIndex: 'productId',
      sorter: {
        compare: (a, b) => {
          const rateNameA = a.productId ? a.productId.name : '-';
          const rateNameB = b.productId ? b.productId.name : '-';

          return rateNameA.localeCompare(rateNameB);
        },
      },
      render: (actions, data) =>
        data.productId ? (
          <Link to={INTERNAL_LINKS.RATE_DETAILS + '/' + data.productId._id}>
            <LinkText variant="strong" size="sm">
              {data.productId.name}
            </LinkText>
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: t('numberOfUsers'),
      dataIndex: 'users',
      key: 'users',
      sorter: (a, b) => a.users.length - b.users.length,
      render: users => users.length,
    },
    {
      title: t('defaultGroup'),
      key: 'default',
      ...sortColumnByBoolean('defaultGroup'),
      ...renderBooleanCheckmarks('defaultGroup'),
    },
  ];

  return (
    <CustomTable
      // Search props
      onSearchTermChange={setSearchTerm}
      // Table props
      {...rest}
      rowKey={g => g._id}
      columns={COLUMNS}
      dataSource={filteredDataSource}
    />
  );
};

export default GroupsTable;
