import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { INTERNAL_LINKS } from '../../../enum';
import { checkIfMobileAppSettingIsValid, momentTimezone } from '../../../utils/common';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import Text from '../../Text';

const CompanyUsersMobileAppSettings = props => {
  const { t, dataSource, ...rest } = props;

  const checkIfLastXDaysIsValid = useCallback((type, appSetting = {}) => {
    const isNA = get(appSetting, type) === 'N/A';
    if (isNA) return 'N/A';

    return get(appSetting, type) === 'valid';
  }, []);

  const renderValidText = useCallback(
    isValid => {
      return (
        <Text size="sm" color={isValid === 'N/A' ? undefined : isValid ? 'primary' : 'red'}>
          {t(isValid === 'N/A' ? 'N/A' : isValid ? 'valid' : 'invalid')}
        </Text>
      );
    },
    [t],
  );

  const renderRowPerMobileSettings = useCallback(
    (mobileAppSettings = [], renderSetting, showIcon = true) => {
      if (!Array.isArray(mobileAppSettings) || typeof renderSetting !== 'function') return '-';

      const mobileSymbolStyle = { fontSize: 17 };

      return (
        <Row gutter={[0, 8]}>
          {mobileAppSettings.map(setting => {
            return (
              <Col xs={24}>
                <Row align="middle" gutter={25} wrap={false} style={{ minHeight: 40 }}>
                  {showIcon && (
                    <Col flex="20px">
                      {setting.type === 'android' ? (
                        <AndroidOutlined style={mobileSymbolStyle} />
                      ) : (
                        <AppleOutlined style={mobileSymbolStyle} />
                      )}
                    </Col>
                  )}
                  <Col flex={1}>{renderSetting(setting)}</Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      );
    },
    [],
  );

  const columns = useMemo(
    () => [
      {
        key: ['firstName', 'lastName'].join(','),
        title: t('name'),
        render: data => (
          <ProfileNamePicture
            t={t}
            user={data}
            redirectTo={INTERNAL_LINKS.USER_DETAILS_MOBILE_APP_SETTINGS(data._id)}
          />
        ),
      },
      {
        key: 'created',
        title: t('date'),
        width: 230,
        dataIndex: 'mobileAppSettings',
        render: mobileAppSettings => {
          return renderRowPerMobileSettings(mobileAppSettings, setting => (
            <Text size="sm" style={{ whiteSpace: 'pre' }}>
              {momentTimezone(new Date(setting.created).toISOString()).format('LL[\n]hh:mm a zz')}
            </Text>
          ));
        },
      },
      {
        key: 'mobileAppSettings',
        title: t('overallSettingsStatus'),
        dataIndex: 'mobileAppSettings',
        render: mobileAppSettings => {
          return renderRowPerMobileSettings(
            mobileAppSettings,
            setting => {
              const isLocationValid = checkIfMobileAppSettingIsValid(
                setting.type,
                'location',
                get(setting, 'location'),
              );
              const isBatteryValid = checkIfMobileAppSettingIsValid(
                setting.type,
                'battery',
                get(setting, 'battery'),
              );
              const isOtherValid = checkIfMobileAppSettingIsValid(
                setting.type,
                'other',
                get(setting, 'other'),
              );
              const isSettingValid = isLocationValid && isBatteryValid && isOtherValid;

              return renderValidText(isSettingValid);
            },
            false,
          );
        },
      },
      {
        key: 'location',
        title: t('locationSettings'),
        dataIndex: 'mobileAppSettings',
        render: mobileAppSettings => {
          return renderRowPerMobileSettings(
            mobileAppSettings,
            setting => {
              const isLocationValid = checkIfMobileAppSettingIsValid(
                setting.type,
                'location',
                get(setting, 'location'),
              );

              return renderValidText(isLocationValid);
            },
            false,
          );
        },
      },
      {
        key: '',
        title: t('powerSettings'),
        dataIndex: 'mobileAppSettings',
        render: mobileAppSettings => {
          return renderRowPerMobileSettings(
            mobileAppSettings,
            setting => {
              const isBatteryValid = checkIfMobileAppSettingIsValid(
                setting.type,
                'battery',
                get(setting, 'battery'),
              );

              return renderValidText(isBatteryValid);
            },
            false,
          );
        },
      },
      {
        key: 'other',
        title: t('notificationsSettings'),
        dataIndex: 'mobileAppSettings',
        render: mobileAppSettings => {
          return renderRowPerMobileSettings(
            mobileAppSettings,
            setting => {
              const isOtherValid = checkIfMobileAppSettingIsValid(
                setting.type,
                'other',
                get(setting, 'other'),
              );

              return renderValidText(isOtherValid);
            },
            false,
          );
        },
      },
      {
        key: 'lastDay',
        title: t('previous_x_day', { count: 1 }),
        dataIndex: 'mobileAppSettings',
        render: mobileAppSettings => {
          return renderRowPerMobileSettings(
            mobileAppSettings,
            setting => {
              const isSettingValid = checkIfLastXDaysIsValid('lastDay', setting);
              return renderValidText(isSettingValid);
            },
            false,
          );
        },
      },
      {
        key: 'last5Days',
        title: t('previous_x_day', { count: 5 }),
        dataIndex: 'mobileAppSettings',
        render: mobileAppSettings => {
          return renderRowPerMobileSettings(
            mobileAppSettings,
            setting => {
              const isSettingValid = checkIfLastXDaysIsValid('last5Days', setting);
              return renderValidText(isSettingValid);
            },
            false,
          );
        },
      },
      {
        key: 'last10Days',
        title: t('previous_x_day', { count: 10 }),
        dataIndex: 'mobileAppSettings',
        render: mobileAppSettings => {
          return renderRowPerMobileSettings(
            mobileAppSettings,
            setting => {
              const isSettingValid = checkIfLastXDaysIsValid('last10Days', setting);
              return renderValidText(isSettingValid);
            },
            false,
          );
        },
      },
    ],
    [t, renderValidText, checkIfLastXDaysIsValid, renderRowPerMobileSettings],
  );

  return (
    <CustomTable
      {...rest}
      showSearchInput={false}
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 900 }}
    />
  );
};

export default CompanyUsersMobileAppSettings;
