import { useCallback, useState } from 'react';

const useTableSort = (defaultSort = {}) => {
  const [tableSort, setTableSort] = useState(defaultSort);

  const handleTableSort = useCallback((sortKey, sortOrder) => {
    if (sortOrder) {
      if (sortKey.includes(',')) {
        const sortKeyArray = sortKey.split(',');
        const sort = {};
        sortKeyArray.forEach(key => {
          sort[key] = sortOrder === 'ascend' ? 1 : -1;
        });
        setTableSort(sort);
      } else {
        setTableSort({ [sortKey]: sortOrder === 'ascend' ? 1 : -1 });
      }
    } else {
      setTableSort({});
    }
  }, []);

  return { tableSort, stringTableSort: JSON.stringify(tableSort), handleTableSort };
};

export default useTableSort;
