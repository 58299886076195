import { useRef, useEffect } from 'react';

/**
 * Hook that works just as useEffect
 * but doesn't run on mount, only on subsequent renders or dep changes.
 *
 * This hook won't allow clean-up functions.
 *
 * @param {Function} fn
 * @param {any[]} deps
 */
const useDidUpdateEffect = (fn, deps = []) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else if (isMounted.current && typeof fn === 'function') {
      fn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useDidUpdateEffect;
