class BaseAPI {
  baseUrl;

  constructor({ suffix }) {
    this.baseUrl = this.customBaseUrl(suffix);
  }

  customBaseUrl(suffix) {
    return `${process.env.REACT_APP_HOST_API}${suffix}`;
  }
}

export default BaseAPI;
