import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { FAVR_COMPLIANCES_API } from '../../api/favr-compliances';
import CompanyLookupSelect from '../../components/CompanyLookupSelect';
import TripsCommentsModal from '../../components/Modal/TripsCommentsModal';
import PageFiltersRenderer from '../../components/shared-ui/PageFiltersRenderer';
import ComplianceWorklistTable from '../../components/Table/ComplianceWorklistTable';
import PageContainer from '../../containers/PageContainer';
import { STATUS_LIST } from '../../enum';
import useModal from '../../hooks/useModal';
import { formatPageTitle } from '../../utils/common';
import useComplianceWorklist from './useComplianceWorklist';

const ComplianceWorklistView = props => {
  const { t, history } = props;

  const {
    isAddingNote,

    pageFilter,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    complianceWorklistQuery,

    handleFiltersChange,
    handleComplianceDownload,
    handleComplianceApprove,
    handleComplianceDeny,
    handleAddNoteToCompliance,
  } = useComplianceWorklist();

  const [viewingNotesForInsurance, setViewingNotesForInsurance] = useState();
  const [isCommentsModalOpen, openCommentsModal, closeCommentsModal] = useModal();

  const handleOpenCommentsModal = insurance => {
    setViewingNotesForInsurance(insurance);
    openCommentsModal();
  };

  const handleCloseCommentsModal = () => {
    closeCommentsModal();
    setViewingNotesForInsurance();
  };

  const complianceNotesQuery = useQuery({
    enabled: !!viewingNotesForInsurance?._id,
    queryKey: ['fetchFavrComplianceNotes', viewingNotesForInsurance?._id],
    queryFn: () => FAVR_COMPLIANCES_API.fetchFavrComplianceNotes(viewingNotesForInsurance?._id),
    onError: error => handleApiErrors(error.response),
  });

  return (
    <PageContainer title={t('complianceWorklist')}>
      <Helmet>
        <title>{formatPageTitle('Compliance Worklist')}</title>
      </Helmet>

      <PageFiltersRenderer
        t={t}
        history={history}
        loading={complianceWorklistQuery.isFetching}
        onFiltersChange={handleFiltersChange}
        filters={[
          {
            componentType: 'custom',
            label: t('Company'),
            responsive: { xs: 24, md: 12, lg: 4 },
            render: ({ updateFilterValues }) => (
              <CompanyLookupSelect
                t={t}
                showAllOption
                disabled={complianceWorklistQuery.isFetching}
                defaultValue={pageFilter.companyId}
                onChange={companyId => updateFilterValues({ companyId })}
              />
            ),
          },
          {
            componentType: 'select',
            label: t('complianceStatus'),
            name: 'status',
            responsive: { xs: 24, md: 12, lg: 4 },
            defaultValue: pageFilter.status,
            options: [
              { label: t('All'), value: null },
              {
                label: STATUS_LIST().StatusTagColorConfig.pending.label,
                value: STATUS_LIST().Status.PENDING,
              },
              {
                label: STATUS_LIST().StatusTagColorConfig.inCompliance.label,
                value: STATUS_LIST().Status.IN_COMPLIANCE,
              },
              {
                label: STATUS_LIST().StatusTagColorConfig.outOfCompliance.label,
                value: STATUS_LIST().Status.OUT_OF_COMPLIANCE,
              },
            ],
          },
          {
            componentType: 'select',
            label: t('gracePeriod'),
            name: 'inGracePeriod',
            responsive: { xs: 24, md: 12, lg: 4 },
            defaultValue: pageFilter.inGracePeriod,
            options: [
              { label: t('All'), value: null },
              { label: 'In', value: true },
              { label: 'Out', value: false },
            ],
          },
        ]}
        search={{
          defaultValue: pageFilter.searchTerm,
        }}
      />

      <ComplianceWorklistTable
        t={t}
        key={complianceWorklistQuery.dataUpdatedAt}
        loading={complianceWorklistQuery.isFetching}
        dataSource={complianceWorklistQuery.data.documents}
        onDownload={handleComplianceDownload}
        onApprove={handleComplianceApprove}
        onDeny={handleComplianceDeny}
        onChange={({ current }) => handlePageChange(current)}
        onViewNotes={handleOpenCommentsModal}
        onSearch={searchTerm => handleFiltersChange({ searchTerm })}
        searchTerm={pageFilter.searchTerm}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />

      <TripsCommentsModal
        t={t}
        canAddComment
        visible={isCommentsModalOpen}
        loading={complianceNotesQuery?.isFetching}
        isAddingComment={isAddingNote || complianceNotesQuery?.isFetching}
        comments={complianceNotesQuery?.data || []}
        onCancel={handleCloseCommentsModal}
        onAddComment={note => handleAddNoteToCompliance(viewingNotesForInsurance?._id, note)}
      />
    </PageContainer>
  );
};

export default withNamespaces()(ComplianceWorklistView);
