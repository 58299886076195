import React, { useMemo } from 'react';

import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue } from '../../../utils/common';
import { CustomTable } from '../..';
import Button from '../../Button';

const CustomPlansTable = props => {
  const { t, dataSource, onEdit, ...rest } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (debouncedSearchTerm) {
      array = array.filter(adj => {
        return (
          checkIfStringContainsValue(adj.name, debouncedSearchTerm) ||
          checkIfStringContainsValue(adj.stripePriceId, debouncedSearchTerm)
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  const COLUMNS = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('stripeApiId'),
      dataIndex: 'stripePriceId',
    },
    {
      title: '',
      render: data =>
        typeof onEdit === 'function' ? (
          <Button block size="xs" onClick={() => onEdit(data)}>
            {t('Edit')}
          </Button>
        ) : null,
    },
  ];

  return (
    <CustomTable
      {...rest}
      rowKey={data => data._id}
      columns={COLUMNS}
      dataSource={filteredDataSource}
      onSearchTermChange={setSearchTerm}
    />
  );
};

CustomPlansTable.defaultProps = {
  dataSource: [],
};

export default CustomPlansTable;
