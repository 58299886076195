import { Col, List, Row } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { HelpIcon } from '../../../components';
import Box from '../../../components/Box';
import FormItem from '../../../components/Form/FormItem';
import HorizontalLineDivider from '../../../components/HorizontalLineDivider';
import Select from '../../../components/Select';
import Text from '../../../components/Text';
import AmountText from '../../../components/Text/AmountText';
import { momentCompanyTimezone } from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import { selectStoreCompanySettings } from '../../../utils/storeSelectors';

const AccountBalanceSummaryBox = props => {
  const {
    t,
    balanceAmount,
    paymentDate,
    nextPaymentDate,
    onPaymentScheduleChange,
    currentPaymentScheduleId,
    paymentScheduleOptions,
    pendingReimbursements,
    totalReimbursementsWithBank,
    totalReimbursementsWithoutBank,
  } = props;

  const companySettings = useSelector(selectStoreCompanySettings);

  const renderBalanceTotal = () => {
    return balanceAmount - (pendingReimbursements || 0);
  };

  return (
    <Box maxWidth={500}>
      {paymentScheduleOptions.length > 1 && (
        <Row>
          <Col flex={1}>
            <FormItem label={t('paymentSchedule')}>
              <Select
                fullWidth
                allowClear={false}
                value={currentPaymentScheduleId}
                options={paymentScheduleOptions}
                onChange={onPaymentScheduleChange}
              />
            </FormItem>
          </Col>
        </Row>
      )}

      <Row
        wrap={false}
        gutter={16}
        align="middle"
        justify="space-between"
        style={{ marginBottom: 15 }}
      >
        <Col flex={1}>
          <Row wrap={false} gutter={16} align="middle" justify="space-between">
            <Col flex={1}>
              <Row wrap={false} gutter={16} align="middle" justify="space-between">
                <Col flex={1}>
                  <Text variant="b" renderAs="p" flex="210px">
                    {t('current')}
                  </Text>
                  <Text variant="b" renderAs="p" flex="210px">
                    {t('kliksAccountBalance')}
                  </Text>
                </Col>
                <Col>
                  <AmountText
                    size="xl"
                    variant="b"
                    amount={formatCurrencyToCompanyLocale(balanceAmount)}
                  />{' '}
                </Col>
              </Row>
            </Col>

            <Col flex="31px" />
          </Row>
        </Col>
      </Row>

      <Row>
        <Col flex={1}>
          <Row wrap={false} gutter={16} align="middle" justify="space-between">
            <Col flex={1}>
              <Row wrap={false} gutter={16} align="middle" justify="space-between">
                <Col flex="210px">
                  <Text variant="b" renderAs="p">
                    {t(companySettings.hideReceipts ? 'pendingReimbursements' : 'pendingReceipts')}
                  </Text>
                </Col>

                <Col>
                  <AmountText
                    size="xl"
                    amount={formatCurrencyToCompanyLocale(-(pendingReimbursements || 0))}
                  />
                </Col>
              </Row>
            </Col>

            <Col flex="31px">
              <HelpIcon
                hint={
                  <List
                    size="small"
                    dataSource={[
                      t(
                        companySettings.hideReceipts
                          ? 'dwolla:sumOfAllPendingReimbursements'
                          : 'dwolla:sumOfAllPendingReceipts',
                      ),
                      <span>
                        <Trans
                          t={t}
                          i18nKey="dwolla:usersWithValidBankAccount"
                          components={[<b>dummytext</b>]}
                          values={{
                            amount: formatCurrencyToCompanyLocale(totalReimbursementsWithBank || 0),
                          }}
                        />
                      </span>,
                      <span>
                        <Trans
                          t={t}
                          i18nKey={
                            companySettings.hideReceipts
                              ? 'dwolla:excludeUsersWithoutValidBankAccount_reimbursement'
                              : 'dwolla:excludeUsersWithoutValidBankAccount_receipt'
                          }
                          components={[<b>dummytext</b>]}
                          values={{
                            amount: formatCurrencyToCompanyLocale(
                              totalReimbursementsWithoutBank || 0,
                            ),
                          }}
                        />
                      </span>,
                    ]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                  />
                }
              />
            </Col>
          </Row>

          {paymentDate && (
            <Row>
              <Text>
                {t('dwolla:toBePaidOn_Date', {
                  date: momentCompanyTimezone(paymentDate).format('MMM DD, YYYY'),
                })}
              </Text>
            </Row>
          )}
        </Col>
      </Row>

      <HorizontalLineDivider />

      <Row>
        <Col flex={1}>
          <Row wrap={false} gutter={16} align="middle" justify="space-between">
            <Col flex={1}>
              <Row wrap={false} gutter={16} align="middle" justify="space-between">
                <Col flex="210px">
                  <Text variant="b" renderAs="p">
                    {t('dwolla:kliksAccountBalanceAfterNextPaymentDate')}
                  </Text>
                </Col>
                <Col>
                  <AmountText
                    size="xl"
                    variant="b"
                    amount={formatCurrencyToCompanyLocale(renderBalanceTotal())}
                  />
                </Col>
              </Row>
            </Col>

            <Col flex="31px" />
          </Row>

          {nextPaymentDate && (
            <Row>
              <Text>
                {t('dwolla:nextPaymentDate_Date', {
                  date: momentCompanyTimezone(nextPaymentDate).format('MMM DD, YYYY'),
                })}
              </Text>
            </Row>
          )}
        </Col>
      </Row>
    </Box>
  );
};

AccountBalanceSummaryBox.defaultProps = {
  balanceAmount: 0,
};

export default AccountBalanceSummaryBox;
