export const SET_REG_STATUS = 'SET_REG_STATUS';

export function setRegStatus(regStatus) {
  return {
    type: SET_REG_STATUS,
    data: {
      regStatus,
    },
  };
}
