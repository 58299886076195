import { Form } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { ERROR_MESSAGE } from '../../utils/constants';
import Select from '../Select';
import TextInput from '../TextInput';

const RateInformationFormSection = props => {
  const { t, countryValue, onRateNameChange, onCountryChange, isSubmitting } = props;

  const { supportedCountries } = useSelector(state => state.common);

  return (
    <>
      <Form.Item
        label={t('enterRateName')}
        name="name"
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput
          disabled={isSubmitting}
          placeholder={t('enterRateName')}
          onChange={onRateNameChange}
        />
      </Form.Item>

      <Form.Item
        name="country"
        label={t('selectCountryOfOperation')}
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        help={t('countryShouldMatchLocationOfUsers')}
      >
        <Select
          disabled={isSubmitting}
          defaultValue="US"
          value={countryValue}
          options={supportedCountries.map(c => ({
            label: c.name,
            value: c.code,
            disabled: c.status !== 'active',
          }))}
          onChange={onCountryChange}
          placeholder={t('select')}
        />
      </Form.Item>
    </>
  );
};

RateInformationFormSection.propTypes = {};

export default RateInformationFormSection;
