import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Text from '../Text';
import LinkText from '../Text/LinkText';

/**
 * Breadcrumbs component to be used on the PageContainers title
 */
const PageBreadcrumbs = props => {
  const { items } = props;

  const breadcrumbTextProps = { variant: 'h3', renderAs: 'span' };

  const filteredItems = items.filter(Boolean);

  return (
    <Breadcrumb separator={<Text {...breadcrumbTextProps}>/</Text>}>
      {filteredItems.map((item, index) => (
        <Breadcrumb.Item key={`${item.link || item.label}-${index}`}>
          {typeof item.onClick === 'function' ? (
            <LinkText {...breadcrumbTextProps} onClick={item.onClick}>
              {item.label}
            </LinkText>
          ) : (
            <Text {...breadcrumbTextProps}>{item.label}</Text>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

PageBreadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

export default PageBreadcrumbs;
