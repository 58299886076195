import { selectStoreCurrentCompany } from './storeSelectors';

export const formatCrmContactFormValuesToBE = values => {
  if (!values) return values;

  const currentCompany = selectStoreCurrentCompany();

  return {
    companyId: currentCompany._id,
    firstName: values.firstName,
    lastName: values.lastName,
    nickName: values.nickName,
    preferredTimezone: values.preferredTimezone,
    formattedAddress: values.formattedAddress,
    phone: values.phone,
    email: values.email,
    notes: values.notes,
    associatedClients: values.associatedClients,
    // commission: {
    //   budget: values.budget,
    //   commissionPercentage: values.commissionPercentage,
    //   projectedCommission: values.projectedCommission,
    // },
  };
};

export const formatCrmContactBEToFormValues = values => {
  if (!values) return values;

  return {
    firstName: values.firstName,
    lastName: values.lastName,
    nickName: values.nickName,
    preferredTimezone: values.preferredTimezone,
    formattedAddress: values.formattedAddress,
    phone: values.phone,
    email: values.email,
    notes: values.notes,
    // budget: values?.commission?.budget,
    // commissionPercentage: values?.commission?.commissionPercentage,
    // projectedCommission: values?.commission?.projectedCommission,
    associatedClients: values.associatedClients,
  };
};
