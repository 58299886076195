import React from 'react';

import ConfirmModal from '../ConfirmModal';

const ReceiptDeleteConfirmationModal = props => {
  const { t, visible, loading, isReimbursement, onCancel, onOk } = props;

  return (
    <ConfirmModal
      visible={visible}
      closable={!loading}
      loading={loading}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('yes')}
      cancelText={t('no')}
      title={t(isReimbursement ? 'reimbursementDelete' : 'receiptDelete')}
      message={t(
        isReimbursement
          ? 'areYouSureYouWantToDeleteReimbursement'
          : 'areYouSureYouWantToDeleteReceipt',
      )}
    />
  );
};

export default ReceiptDeleteConfirmationModal;
