import { handleApiCalls, performApiCallIfCompanySubIsActiveOrCompanyAdmin } from './axiosInstance';

export const fetchUserVehicles = async userID => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle/getUserVehicles/${userID}`;
  const result = await handleApiCalls('get', url);
  return result.data.data;
};

/**
 *
 * @param {object} data
 */
export const addVehicle = async data => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle/addVehicle`;
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('post', url, data);
  return result.data.data.vehicles;
};

/**
 *
 * @param {object} data
 */
export const updateVehicle = async (vehicleID, data) => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle/updateVehicle/${vehicleID}`;
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('put', url, data);
  return result.data.data;
};

/**
 *
 * @param {string} vehicleID
 */
export const deleteVehicle = async vehicleID => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle/${vehicleID}`;
  await performApiCallIfCompanySubIsActiveOrCompanyAdmin('delete', url);
};

/**
 * Get vehicle info from vin
 * @typedef {Object} FetchVehicleFromVINResult
 * @property {string} make
 * @property {string} model
 * @property {string} modelYear
 * @property {string} vehicleType
 * @property {string} bodyClass
 * @property {string} doors
 * @property {string} engineNumberOfCylinders
 * @property {string} displacementL
 * @property {string} fuelTypePrimary
 */

export const VEHICLE_API = {
  /**
   * Get vehicle info from vin
   * @param {string} vin
   * @returns {FetchVehicleFromVINResult}
   */
  fetchVehicleFromVIN: async vin => {
    const url = `${process.env.REACT_APP_HOST_API}vehicle/${vin}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  },
};
