import { useCallback, useEffect } from 'react';
import storage from 'store';

import useDebounce from '../../hooks/useDebounce';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { triggerGoogleAnalyticsPageView } from '../../utils/googleAnalytics';

const AnalyticsToolPrivatePageTracker = ({ location }) => {
  const debouncedPathname = useDebounce(location.pathname, 700);

  const sendPageViewAnalyticsEvent = useCallback(() => {
    const accessToken = storage.get('accessToken');

    if (accessToken) {
      triggerGoogleAnalyticsPageView();
    }
  }, []);

  useDidUpdateEffect(() => {
    sendPageViewAnalyticsEvent();
  }, [sendPageViewAnalyticsEvent, debouncedPathname]);

  useEffect(() => {
    setTimeout(() => {
      sendPageViewAnalyticsEvent();
    }, 1000);
    // eslint-disable-next-line
  }, []);

  return null;
};

export default AnalyticsToolPrivatePageTracker;
