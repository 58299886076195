import React from 'react';
import PropTypes from 'prop-types';

import Text from './index';

/**
 * Variant of the Text component with the `faded` Color prop on
 *
 * @param {object} props - Component props.
 */
const FadedText = props => {
  return <Text {...props} color="faded" />;
};

FadedText.propTypes = {
  className: PropTypes.string,
};

export default FadedText;
