import React from 'react';

import CustomPlanForm from '../../Forms/CustomPlanForm';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import GenericModal from '../GenericModal';

const CustomPlansModal = props => {
  const { t, visible, onCancel, onSubmit, isSubmitting, formInstance, initialValues } = props;

  const IS_CREATE = initialValues?._id === 'new';

  return (
    <GenericModal
      destroyOnClose
      title={IS_CREATE ? t('createCustomPlan') : t('updateCustomPlan')}
      visible={visible || isSubmitting}
      onCancel={onCancel}
      footer={
        <SubmitCancelButtonGroup
          loading={isSubmitting}
          disabled={isSubmitting}
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitText={IS_CREATE ? t('create') : t('update')}
        />
      }
    >
      <CustomPlanForm
        t={t}
        isSubmitting={isSubmitting}
        formInstance={formInstance}
        initialValues={initialValues}
        isUpdate={!IS_CREATE}
      />
    </GenericModal>
  );
};

export default CustomPlansModal;
