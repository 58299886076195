import { handleApiCalls } from './axiosInstance';

/**
 * Performs an API call to fetch an array of supported countries and their config
 *
 * @returns {object[]} Array of country data
 */
export const fetchCountryConfig = async () => {
  const url = `${process.env.REACT_APP_HOST_API}country-rates/countryCodes`;

  const result = await handleApiCalls('get', url);

  return result.data.data;
};
