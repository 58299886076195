import { PLANS } from '../enum';

const checkPlan = (planData, planNumber) => {
  if (planData && planData.status === 'active') {
    if (planData.planId) {
      return planData.planId.pricePlan === planNumber;
    }
  }

  return false;
};

export const hasBasicPlan = planData => {
  return checkPlan(planData, PLANS.BASIC_PLAN.number);
};

export const hasAdvancedPlan = planData => {
  return checkPlan(planData, PLANS.ADVANCED_PLAN.number);
};

export const hasPremiumPlan = planData => {
  return checkPlan(planData, PLANS.PREMIUM_PLAN.number);
};
