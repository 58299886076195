import { debounce, put } from 'redux-saga/effects';

import * as actions from '../actions/browser';

export function* windowSizeUpdate() {
  yield put(actions.updateBrowserStore());
}

/**
 * watcher saga
 */
export const browserSaga = [debounce(1000, actions.WINDOW_SIZE_CHANGE, windowSizeUpdate)];
