import { Col, Form, Row } from 'antd';
import React from 'react';

import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { ERROR_MESSAGE } from '../../../utils/constants';
import FormItem from '../../Form/FormItem';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import TextInput from '../../TextInput';
import GenericModal from '../GenericModal';

const DwollaCustomerFormModal = props => {
  const { t, visible, initialValues, isSubmitting, onCancel, onSubmit } = props;

  const [form] = Form.useForm();

  useDidUpdateEffect(() => {
    if (visible) {
      form.setFieldsValue(
        initialValues
          ? {
              controllerFirstName: initialValues.firstName,
              controllerLastName: initialValues.lastName,
              email: initialValues.email,
            }
          : {},
      );
    } else {
      form.resetFields();
    }
  }, [visible]);

  return (
    <GenericModal
      centered
      title={t('addBankAccountInformation')}
      visible={visible}
      onCancel={onCancel}
      closable={!isSubmitting}
      footer={
        <SubmitCancelButtonGroup
          onCancel={onCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
          submitText={t('create')}
          onSubmit={() => form.validateFields().then(onSubmit)}
        />
      }
    >
      <Form form={form} initialValues={initialValues}>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <FormItem
              required
              name="firstName"
              label={t('firstName')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput autoFocus disabled={isSubmitting} />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem
              required
              name="lastName"
              label={t('lastName')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput disabled={isSubmitting} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24}>
            <FormItem required name="email" label={t('email')}>
              <TextInput disabled />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </GenericModal>
  );
};

export default DwollaCustomerFormModal;
