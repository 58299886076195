import { Form } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import useDebouncedState from '../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useRateForm from '../../hooks/useRateForm';
import { emitUpdateWizardDirty } from '../../stores/actions/rateWizard';
import FormItem from '../Form/FormItem';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import HorizontalSwitch from '../Switch/HorizontalSwitch';
import TextInput from '../TextInput';

const PayrollFileSection = props => {
  const { t, formInstance, onNextClick, onBackClick } = props;

  const dispatch = useDispatch();
  const { rateWizard, handleFormValuesChange } = useRateForm(formInstance);

  const [accountNumber, setAccountNumber] = useDebouncedState(rateWizard.rateValues.accountNumber);
  const [sapCode, setSapCode] = useDebouncedState(rateWizard.rateValues.sapCode);

  useDidUpdateEffect(() => {
    handleFormValuesChange({ accountNumber });
  }, [accountNumber, handleFormValuesChange]);

  useDidUpdateEffect(() => {
    handleFormValuesChange({ sapCode });
  }, [sapCode, handleFormValuesChange]);

  return (
    <Form
      form={formInstance}
      onValuesChange={() => {
        if (!rateWizard.isDirty) {
          dispatch(emitUpdateWizardDirty(true));
        }
      }}
    >
      <section>
        <FormItem required name="includeFixedReceiptsInFile" valuePropName="checked">
          <HorizontalSwitch
            label={t('includeFixedReceiptsInFile?')}
            defaultChecked={rateWizard.rateValues.includeFixedReceiptsInFile}
            onChange={checked => {
              handleFormValuesChange({
                includeFixedReceiptsInFile: checked,
              });
            }}
          />
        </FormItem>

        <FormItem name="accountNumber" label={t('accountNumber')}>
          <TextInput
            disabled={rateWizard.isSubmitting}
            onChange={event => setAccountNumber(event.target.value)}
          />
        </FormItem>

        <FormItem name="sapCode" label={t('sapCode')}>
          <TextInput
            disabled={rateWizard.isSubmitting}
            onChange={event => setSapCode(event.target.value)}
          />
        </FormItem>
      </section>

      <SubmitCancelButtonGroup
        cancelText={t('Back')}
        submitText={t('Next')}
        loading={rateWizard.isSubmitting}
        onCancel={onBackClick}
        onSubmit={onNextClick}
      />
    </Form>
  );
};

export default PayrollFileSection;
