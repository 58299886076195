import { RATE_FORM_WIZARD_STEPS } from '../enum/Rates';

const initialState = {
  browser: {
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
    orientation: window.innerHeight > window.innerWidth ? 'portrait' : 'landscape',
    isMobile: window.innerWidth <= 425,
    isTablet: window.innerWidth > 425 && window.innerWidth <= 768,
    isDesktop: window.innerWidth > 768,
  },
  layout: {
    sidebarCollapsed: true,
  },
  profile: null,
  invoices: {
    isLoadingInvoices: false,
    listByYear: {},
  },
  prices: {
    isLoadingData: false,
    plans: undefined,
    customPlans: undefined,
    freeTrialPlan: undefined,
  },
  userVehicles: {},
  common: {
    basename: '',
    navigateToAfterBasenameUpdate: undefined,
    timezones: [],
    countryRates: [],
    header: {},
    companyData: {
      companyList: [],
      fetchFlag: false,
    },
    // for registration flow
    signupSubStep: '',
    phoneForPin: '',
    emailForConfirm: '',
    emailConfirmed: false,
    visitedSetupPage: false,
    currentCompany: {},
  },
  group: {
    groupList: [],
    fetchFlag: false,
  },
  user: {
    userList: [],
    fetchFlag: false,
    regStatus: 0,
  },
  systemAdmin: {
    paginatedTotalCompanies: 0,

    isLoadingSystemAdmins: false,
    systemAdmins: [],

    countryCodeMappings: [],

    globalSettings: undefined,
  },
  vehicles: {
    isSetup: false,

    // Loaders
    isLoadingYears: false,
    isLoadingMakes: false,
    isLoadingModels: false,

    // Data
    availableYears: [],
    byYearData: {},
  },
  favrVehicles: {
    isSetup: false,

    // Loaders
    isLoadingYears: false,
    isLoadingMakes: false,
    isLoadingModels: false,

    // Data
    availableYears: [],
    byYearData: {},
  },
  realtor: {
    isFetchingContacts: false,
    contacts: undefined,
  },
  rateWizard: {
    currentStep: RATE_FORM_WIZARD_STEPS.RATE_FORM,
    isSettingUpWizard: false,
    isUpdateWizard: false,

    isDirty: false,
    isSelectedVehicleDirty: false,
    isSubmitted: false,
    isSubmitting: false,

    rateBands: undefined,
    groupOptions: [],
    usersInRate: [],

    rateValues: {},
    initialValues: {},
    originalRateDetails: {},
  },
};

export default initialState;
