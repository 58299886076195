const regStatus = {
  PLAN: 1,
  COMPANY: 2,
  PRICES: 3,
  // FEATURES: 3,
  // PAYMENT: 4,
  INVITE: 4,
  SUCCESS: 5,
};

export default regStatus;
