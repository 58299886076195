import { Col, Row } from 'antd';
import React, { useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import {
  checkIfStringContainsValue,
  getUserFullName,
  momentFormat,
  momentTimezone,
} from '../../../utils/common';
import { CustomTable } from '../..';
import ComplianceItemsList from '../../ComplianceItemsList';
import StatusTag from '../../Tag/StatusTag';

const ComplianceUserDetailsTable = props => {
  const { t, dataSource, searchTerm, ...rest } = props;

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(item => {
        return checkIfStringContainsValue(getUserFullName(item), searchTerm);
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      key: 'type',
      title: t('type'),
      dataIndex: 'type',
      render: type => t(type),
    },
    {
      key: 'complianceStatus',
      title: t('complianceStatus'),
      dataIndex: 'status',
      render: (status, data) => (
        <Row align="middle" gutter={5} wrap={false}>
          <Col>
            <StatusTag status={status} />
          </Col>
        </Row>
      ),
    },
    {
      key: 'nonComplianceStartDate',
      title: t('nonComplianceStartDate'),
      dataIndex: 'nonComplianceStartDate',
      render: startDate => (startDate ? momentFormat(startDate, 'DD MMM, YYYY') : '-'),
    },
    {
      width: 400,
      key: 'nonComplianceDetails',
      title: t('nonComplianceDetails'),
      render: data =>
        data.status === STATUS_LIST().Status.INVALID ? (
          <ComplianceItemsList
            t={t}
            type={data.type}
            complianceComponents={data.complianceComponents || data}
          />
        ) : (
          '-'
        ),
    },
    {
      key: 'lastAction',
      title: t('lastAction'),
      dataIndex: ['lastAction', 'reason'],
      render: (reason, data) => (reason ? t(`${data.type}-${reason}`) : '-'),
    },
    {
      key: 'lastActionDate',
      title: t('lastActionDate'),
      dataIndex: ['lastAction', 'created'],
      render: created =>
        created
          ? `${momentFormat(created, 'DD MMM, YYYY')} (${momentTimezone(created).fromNow()})`
          : '-',
    },
  ];

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      showSearchInput={false}
      dataSource={filteredDataSource}
      scroll={{ x: 1100 }}
    />
  );
};

export default ComplianceUserDetailsTable;
