import React, { useCallback, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AndroidAppPrompt from '../components/AndroidAppPrompt';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import { hasUserRole } from '../utils/roles';
import { selectStoreCurrentAuthUser } from '../utils/storeSelectors';

const withMobileAppRedirect = WrappedComponent => {
  return props => {
    const location = useLocation();
    const authUser = useSelector(selectStoreCurrentAuthUser);
    const [displayBanner, setDisplayBanner] = useState(true);

    const closeBanner = useCallback(() => setDisplayBanner(false), []);

    useDidUpdateEffect(() => {
      if (displayBanner) {
        closeBanner();
      }
    }, [location.pathname, displayBanner, closeBanner]);

    const APPLE_HELMENT_CONTENT =
      authUser && hasUserRole(authUser) && isIOS ? (
        <Helmet>
          <meta name="apple-itunes-app" content="app-id=1542561197" />
        </Helmet>
      ) : null;

    const ANDROID_HELMENT_CONTENT =
      authUser && hasUserRole(authUser) && isAndroid && displayBanner ? (
        <AndroidAppPrompt onClose={closeBanner} />
      ) : null;

    return (
      <>
        {APPLE_HELMENT_CONTENT}
        {ANDROID_HELMENT_CONTENT}
        {/* Main Site */}
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withMobileAppRedirect;
