import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../enum';

const useUserFavrComplianceTaxReport = complianceId => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [userComplianceDetails, setUserComplianceDetails] = useState();

  useEffect(() => {
    const currentComplianceUserQueries = queryClient.getQueriesData({
      exact: false,
      queryKey: ['getAllUsersFavrComplianceData'],
    });

    let details;
    for (let i = 0; i < currentComplianceUserQueries.length; i++) {
      const { documents } = currentComplianceUserQueries[i][1] || {};
      if (Array.isArray(documents)) {
        details = documents.find(doc => doc._id === complianceId);
        if (details) {
          setUserComplianceDetails(details);
          break;
        }
      }
    }

    if (!details) {
      return history.replace(INTERNAL_LINKS.USER_PROGRAM_COMPLIANCE);
    }
  }, [history, queryClient, complianceId]);

  return userComplianceDetails;
};

export default useUserFavrComplianceTaxReport;
