import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../components';
import BoxWithHeader from '../../../components/Box/BoxWithHeader';
import RealtorContactLookupInput from '../../../components/RealtorContactLookupInput';
import { IMAGES, INTERNAL_LINKS } from '../../../enum';

const ClientLookCard = props => {
  const { t, history } = props;

  return (
    <BoxWithHeader
      header={t('clientLookup')}
      extra={
        <Link to={INTERNAL_LINKS.CLIENT}>
          <Button
            sixe="xs"
            type="secondary"
            icon={
              <img
                src={IMAGES.GREEN_PLUS_ICON}
                width="18px"
                alt="plus"
                style={{ marginRight: 5 }}
              />
            }
          >
            {t('add')}
          </Button>
        </Link>
      }
    >
      <RealtorContactLookupInput
        t={t}
        onChange={clientID => {
          history.push(INTERNAL_LINKS.CLIENT_DETAILS(clientID));
        }}
      />
    </BoxWithHeader>
  );
};

export default ClientLookCard;
