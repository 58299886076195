import { Input } from 'antd';
import { isUndefined } from 'lodash';
import React, { useState } from 'react';

const NumericInput = React.forwardRef((props, ref) => {
  const { onChange, defaultValue, value, ...rest } = props;

  const [inputValue, setInputValue] = useState(!isUndefined(value) ? value : defaultValue);

  return (
    <Input
      {...rest}
      type="string"
      ref={ref}
      value={inputValue}
      onChange={e => {
        let tempValue = e.target.value;
        tempValue =
          tempValue.charAt(0) === '-'
            ? `-${tempValue.replace(/-/gi, '')}`
            : tempValue.replace(/-/gi, '');

        const reg = /^-?\d*(\.\d*)?$/;

        if (reg.test(tempValue) || tempValue === '' || tempValue === '-') {
          tempValue = tempValue === '.' ? '0.' : tempValue;

          setInputValue(tempValue);
          if (typeof onChange === 'function') onChange(tempValue);
        } else {
          const initialValue = !isUndefined(inputValue) ? inputValue : '';
          setInputValue(initialValue);
          if (typeof onChange === 'function') onChange(initialValue);
        }
      }}
      onBlur={({ target }) => {
        let tempValue = target.value;

        if (tempValue.charAt(tempValue.length - 1) === '.' || tempValue === '-') {
          tempValue = tempValue.slice(0, -1);
        }

        const cleanValue = tempValue.replace(/0*(\d+)/, '$1');
        setInputValue(cleanValue);
        if (typeof onChange === 'function') onChange(cleanValue);
      }}
    />
  );
});

export default NumericInput;
