import { Col, Form, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import { withNamespaces } from 'react-i18next';

import { ERROR_MESSAGE } from '../../../utils/constants';
import { selectStoreItineraryClientByID, useStoreSelector } from '../../../utils/storeSelectors';
import FormItem from '../../Form/FormItem';
import LocationInput from '../../LocationInput';
import RealtorContactLookupInput from '../../RealtorContactLookupInput';
import SpaceSpinner from '../../SpaceSpinner';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';
import TextInput from '../../TextInput';
import DraggableItineraryDestinationsSection from './DraggableItineraryDestinationsSection';

const ItineraryForm = props => {
  const {
    t,
    formInstance,
    initialValues,
    isSubmitting,
    createNewContact,
    onCreateNewContact,
    disabledClientChange,
  } = props;

  const [formValues, setFormValues] = useState(initialValues);
  const { isFetchingContacts } = useStoreSelector('realtor');

  const handleValueChange = useCallback(
    (newValues = {}) => {
      setFormValues(state => ({ ...state, ...newValues }));
      if (formInstance) {
        formInstance.setFieldsValue(newValues);
      }
    },
    [formInstance],
  );

  const handleDestinationsChange = useCallback(
    (index, newValues = {}) => {
      const updatedDestinations = formValues.destinations;

      updatedDestinations[index] = { ...updatedDestinations[index], ...newValues };

      const formattedDestinations = {};
      updatedDestinations.forEach((destination, i) => {
        formattedDestinations[`destination_addr_${i}`] = destination.address;
        formattedDestinations[`destination_reachingTime_${i}`] = destination.reachingTime;
      });

      handleValueChange({ ...formattedDestinations, destinations: updatedDestinations });
    },
    [formValues.destinations, handleValueChange],
  );

  const handleAddNewDestination = () => {
    if (!isSubmitting) {
      const index = formValues.destinations.length;
      handleDestinationsChange(index, {
        id: String(+new Date()),
        address: undefined,
        reachingTime: '',
      });
    }
  };

  const handleDestinationRemoval = index => {
    if (!isSubmitting) {
      const updatedDestinations = [...formValues.destinations];

      updatedDestinations.splice(index, 1);

      const formattedDestinations = {};
      updatedDestinations.forEach((destination, i) => {
        formattedDestinations[`destination_addr_${i}`] = destination.address;
        formattedDestinations[`destination_reachingTime_${i}`] = destination.reachingTime;
      });

      handleValueChange({ ...formattedDestinations, destinations: updatedDestinations });
    }
  };

  if (isFetchingContacts) {
    return <SpaceSpinner />;
  }

  return (
    <Form initialValues={formValues} form={formInstance} autoComplete="chrome-off">
      {!createNewContact && (
        <Row>
          <Col flex={1}>
            <FormItem name="clientDetails" label={t('clientAssociatedWithThisItinerary')}>
              <RealtorContactLookupInput
                t={t}
                allowClear
                disabled={isSubmitting || disabledClientChange}
                onAddNewClient={onCreateNewContact}
                onChange={clientID => {
                  if (formInstance) {
                    const fullClient = selectStoreItineraryClientByID(clientID);
                    handleValueChange({ startAddress: fullClient.formattedAddress });
                  }
                }}
              />
            </FormItem>
          </Col>
        </Row>
      )}

      {createNewContact && (
        <>
          <Text variant="b">{t('clientAssociatedWithThisItinerary')}</Text>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <FormItem
                required
                name="firstName"
                label={t('firstName')}
                rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
              >
                <TextInput disabled={isSubmitting} />
              </FormItem>
            </Col>

            <Col xs={24} xl={12}>
              <FormItem
                required
                name="lastName"
                label={t('lastName')}
                rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
              >
                <TextInput disabled={isSubmitting} />
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col flex={1}>
              <FormItem
                required
                name="email"
                label={t('Email')}
                rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
              >
                <TextInput type="email" disabled={isSubmitting} />
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col flex={1}>
              <FormItem name="formattedAddress" label={t('address')}>
                <LocationInput
                  defaultValue={initialValues?.formattedAddress}
                  disabled={isSubmitting}
                  onSelect={formattedAddress => formInstance?.setFieldsValue({ formattedAddress })}
                  onChange={formattedAddress => formInstance?.setFieldsValue({ formattedAddress })}
                />
              </FormItem>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col flex={1}>
          <FormItem
            name="startAddress"
            label={t('clientStartAddress_label')}
            required
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <LocationInput
              disabled={isSubmitting}
              defaultValue={formValues.startAddress}
              placeholder={t('clientStartAddress_placeholder')}
              onSelect={address => handleValueChange({ startAddress: address })}
              onChange={address => handleValueChange({ startAddress: address })}
            />
          </FormItem>
        </Col>
      </Row>

      <DraggableItineraryDestinationsSection
        t={t}
        destinations={formValues.destinations}
        isSubmitting={isSubmitting}
        onDestinationChange={handleDestinationsChange}
        onDestinationRemoval={handleDestinationRemoval}
      />

      <LinkText variant="b" onClick={handleAddNewDestination}>
        + {t('addAnotherDestinationToThisRoute')}
      </LinkText>
    </Form>
  );
};

ItineraryForm.defaultProps = {
  initialValues: {
    destinations: [{ id: String(+new Date()) }],
  },
};

export default withNamespaces()(ItineraryForm);
