import { Col, List, Row } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import {
  checkIfStringContainsValue,
  getUserFullName,
  joinComponentsWithCommas,
} from '../../../utils/common';
import { convertMillisecondsIntoReadableTime } from '../../../utils/datetime';
import {
  calcItineraryTripsTotalDistance,
  calcItineraryTripsTotalDrivingTime,
  getLatestCompletedItinerary,
  getUpcomingItinerary,
} from '../../../utils/itinerary';
import { formatInternationalPhoneNumber, formatNumberToLocale } from '../../../utils/numbers';
import { selectStoreItineraryClientByID } from '../../../utils/storeSelectors';
import { sortColumnByStringField } from '../../../utils/tables';
import { CustomTable } from '../..';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';
import ItineraryColumnContent from './ItineraryColumnContent';
import classNames from './styles.module.scss';

const CrmContactTable = props => {
  const { t, dataSource, ...rest } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState();

  const filteredTable = useMemo(() => {
    let array = [...dataSource];

    if (debouncedSearchTerm) {
      array = array.filter(contact => {
        const completedItinerary = getLatestCompletedItinerary(contact.itineraries);

        return (
          checkIfStringContainsValue(getUserFullName(contact), debouncedSearchTerm) ||
          checkIfStringContainsValue(contact.nickName || '', debouncedSearchTerm) ||
          checkIfStringContainsValue(contact?.phone || '-', debouncedSearchTerm) ||
          contact.itineraries.some(itinerary =>
            itinerary.trips.some(trip =>
              checkIfStringContainsValue(trip.tripEndLocationAddress, debouncedSearchTerm),
            ),
          ) ||
          (completedItinerary &&
            (checkIfStringContainsValue(
              formatNumberToLocale(calcItineraryTripsTotalDistance(completedItinerary.trips), 2),
              debouncedSearchTerm,
            ) ||
              checkIfStringContainsValue(
                convertMillisecondsIntoReadableTime(
                  calcItineraryTripsTotalDrivingTime(completedItinerary.trips),
                ),
                debouncedSearchTerm,
              )))
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  const COLUMNS = [
    {
      width: 250,
      title: t('client'),
      render: (_, contact) => {
        const associatedClients = [];

        if (Array.isArray(contact.associatedClients)) {
          contact.associatedClients.forEach(clientId => {
            const client = selectStoreItineraryClientByID(clientId);

            if (client) {
              associatedClients.push(
                <Link key={client._id} to={`${INTERNAL_LINKS.CLIENT}/${client._id}`}>
                  <LinkText>{getUserFullName(client)}</LinkText>
                </Link>,
              );
            }
          });
        }

        return (
          <Row>
            <div>
              <div>
                <Link to={`${INTERNAL_LINKS.CLIENT}/${contact._id}`}>
                  <LinkText variant="b">{getUserFullName(contact)}</LinkText>
                </Link>
              </div>
              {contact.nickName && (
                <Text size="sm" style={{ marginTop: 8 }}>
                  "{contact.nickName}"
                </Text>
              )}
              {!!associatedClients.length && (
                <div>
                  <Text size="sm" style={{ marginTop: 20 }}>
                    Partner: {joinComponentsWithCommas(associatedClients)}
                  </Text>
                </div>
              )}
            </div>
          </Row>
        );
      },
      sorter: (a, b) => {
        const aFullName = getUserFullName(a);
        const bFullName = getUserFullName(b);

        return aFullName.toLowerCase().localeCompare(bFullName.toLowerCase());
      },
    },
    {
      title: t('itineraries'),
      dataIndex: 'itineraries',
      render: (itineraries, contact) => {
        const upcomingItinerary = getUpcomingItinerary(itineraries);
        const completedItinerary = getLatestCompletedItinerary(itineraries);

        const itineraryList = [];

        if (upcomingItinerary)
          itineraryList.push({
            ...upcomingItinerary,
            isUpcoming: true,
          });

        if (completedItinerary) itineraryList.push({ ...completedItinerary });

        return (
          <List
            size="small"
            dataSource={itineraryList}
            renderItem={itinerary => {
              return (
                <List.Item key={itinerary._id}>
                  <ItineraryColumnContent
                    t={t}
                    itinerary={itinerary}
                    isUpcoming={itinerary.isUpcoming}
                  />
                </List.Item>
              );
            }}
          />
        );
      },
    },
    {
      width: 220,
      title: t('miles/time'),
      dataIndex: 'itineraries',
      render: itineraries => {
        const upcomingItinerary = getUpcomingItinerary(itineraries);
        const completedItinerary = getLatestCompletedItinerary(itineraries);

        return completedItinerary ? (
          <Row
            align="bottom"
            className={upcomingItinerary ? classNames.completedItineraryInfo : undefined}
          >
            <Col flex={1}>
              <Row wrap={false} align="middle" gutter={[8, 8]} style={{ marginBottom: 8 }}>
                <Col>
                  <img width="20px" src={IMAGES.CAR_RETRO_DARK_ICON} alt="map" />
                </Col>
                <Col>
                  {t('nTotalMiles', {
                    miles: formatNumberToLocale(
                      calcItineraryTripsTotalDistance(completedItinerary.trips),
                      2,
                    ),
                  })}
                </Col>
              </Row>
              <Row wrap={false} align="middle" gutter={[8, 8]}>
                <Col>
                  <img width="20px" src={IMAGES.TIME_CLOCK_CIRCLE_GRAY} alt="clock" />
                </Col>
                <Col>
                  {t('nTotalTime', {
                    time: convertMillisecondsIntoReadableTime(
                      calcItineraryTripsTotalDrivingTime(completedItinerary.trips),
                    ),
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          '-'
        );
      },
    },
    {
      width: 200,
      title: t('contact'),
      dataIndex: 'phone',
      render: phone => (phone ? formatInternationalPhoneNumber(phone) : '-'),
      ...sortColumnByStringField('phone'),
    },
  ];

  return (
    <CustomTable
      rowKey={data => data._id}
      {...rest}
      columns={COLUMNS}
      dataSource={filteredTable}
      onSearchTermChange={setSearchTerm}
      scroll={{ x: 1200 }}
    />
  );
};

export default CrmContactTable;
