import { AutoComplete, Col, Row, Space } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import LocationsAPI from '../../api/locations';
import { IMAGES } from '../../enum';
import useDebouncedState from '../../hooks/useDebouncedState';
import { analyseAddressAutoCompleteString } from '../../utils/address';
import { removeAccentedCharactersFromString } from '../../utils/common';
import HorizontalLineDivider from '../HorizontalLineDivider';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import inputClassNames from '../TextInput/style.module.scss';
import Toast from '../Toast';
import classNames from './styles.module.scss';

const COUNTRIES_UNSUPPORTED_BY_RADAR = ['PR'];

const LocationInput = props => {
  const {
    t,
    onSelect,
    onChange,
    defaultValue,
    size,
    className,
    limitedToCountryCode,
    ...rest
  } = props;

  const inputRef = useRef();
  const [userGeoLocation, setUserGeoLocation] = useState();
  const inputClasses = [
    className,
    inputClassNames.input,
    inputClassNames[size],
    classNames.locationInput,
  ]
    .filter(Boolean)
    .join(' ');

  const [searchTerm, setSearchTerm] = useDebouncedState(defaultValue, 300);
  const [radarAddressOptions, setRadarAddressOptions] = useState(undefined);
  const [googleAddressOptions, setGoogleAddressOptions] = useState(undefined);

  const handleSearch = useCallback(term => setSearchTerm(term), [setSearchTerm]);

  const fetchLocationFromGoogle = useMutation(
    async addressToSearch => {
      setGoogleAddressOptions(undefined);
      return new LocationsAPI().fetchLocationsGoogleMapsAutocomplete(
        addressToSearch,
        limitedToCountryCode,
        userGeoLocation,
      );
    },
    {
      onSuccess: matchingLocations => {
        if (Array.isArray(matchingLocations)) {
          const options = matchingLocations.map(match => {
            const parsedAddress = analyseAddressAutoCompleteString(match.value);

            return {
              address: match.address,
              value: removeAccentedCharactersFromString(match.value),
              label: (
                <Row>
                  <Col xs={24}>
                    <Text>{parsedAddress.streetOne}</Text>
                  </Col>
                  <Col xs={24}>
                    <FadedText>
                      {[parsedAddress.city, parsedAddress.state, parsedAddress.country]
                        .filter(Boolean)
                        .join(', ')}
                    </FadedText>
                  </Col>
                </Row>
              ),
            };
          });

          setGoogleAddressOptions(options);
        }
      },
      onError: e => {
        handleApiErrors(e.response, () => {
          Toast({
            type: 'error',
            message: t('fetchLocationsAutocompleteError'),
          });
        });
      },
    },
  );

  const fetchGooglePlaceDetails = useMutation(
    placeId => {
      return new LocationsAPI().fetchGoogleMapLocationDetails(placeId);
    },
    { onError: e => {} },
  );

  const locationFromRadarQuery = useQuery({
    enabled: !!searchTerm && !COUNTRIES_UNSUPPORTED_BY_RADAR.includes(limitedToCountryCode),
    queryKey: ['fetchLocationsAutocomplete', limitedToCountryCode, userGeoLocation, searchTerm],
    queryFn: () => {
      setRadarAddressOptions(undefined);
      setGoogleAddressOptions(undefined);
      return new LocationsAPI().fetchLocationsAutocomplete(
        searchTerm,
        limitedToCountryCode,
        userGeoLocation,
      );
    },
    onSuccess: matchingLocations => {
      if (Array.isArray(matchingLocations)) {
        const options = matchingLocations.map(match => ({
          address: match.address,
          value: removeAccentedCharactersFromString(match.value),
          label: (
            <Row>
              <Col xs={24}>
                <Text>
                  {match.address.placeLabel && match.address.placeLabel + ', '}
                  {match.address.number}, {match.address.street}
                </Text>
              </Col>
              <Col xs={24}>
                <FadedText>
                  {[match.address.city, match.address.stateCode, match.address.country]
                    .filter(Boolean)
                    .join(', ')}
                </FadedText>
              </Col>
            </Row>
          ),
        }));

        setRadarAddressOptions(options);
      }
    },
    onError: () => e => {
      handleApiErrors(e.response, () => {
        Toast({
          type: 'error',
          message: t('fetchLocationsAutocompleteError'),
        });
      });
    },
  });

  const locationFromGoogleQuery = useQuery({
    enabled: !!searchTerm && COUNTRIES_UNSUPPORTED_BY_RADAR.includes(limitedToCountryCode),
    queryKey: ['fetchLocationFromGoogle', limitedToCountryCode, userGeoLocation, searchTerm],
    queryFn: () => {
      setRadarAddressOptions([]);
      setGoogleAddressOptions(undefined);
      return fetchLocationFromGoogle.mutateAsync(searchTerm, limitedToCountryCode, userGeoLocation);
    },
  });

  const handleSelect = useCallback(
    (addressString, option) => {
      handleSearch(addressString);
      if (typeof onSelect === 'function') {
        if (option?.address?.isGoogle) {
          fetchGooglePlaceDetails
            .mutateAsync(option.address.placeId)
            .then(fullAddress => {
              onSelect(addressString, fullAddress);
            })
            .catch(() => {
              onSelect(addressString, option.address);
            });
        } else {
          onSelect(addressString, option.address);
        }
      }
    },
    [handleSearch, onSelect, fetchGooglePlaceDetails],
  );

  const handleChange = useCallback(
    (addressString, option) => {
      handleSearch(addressString);
      if (typeof onChange === 'function') onChange(addressString, option.address);
    },
    [handleSearch, onChange],
  );

  const requestGeoLocationPermissions = () => {
    navigator.geolocation.getCurrentPosition(pos => {
      setUserGeoLocation(pos.coords);
    });
  };

  return (
    <AutoComplete
      // Using this to fully disable autocomplete of field on every browser
      autoComplete="off"
      aria-autocomplete="none"
      defaultActiveFirstOption
      {...rest}
      ref={inputRef}
      allowClear={false}
      filterOption={false}
      className={inputClasses}
      options={
        Array.isArray(radarAddressOptions)
          ? [
              ...(Array.isArray(googleAddressOptions) ? googleAddressOptions : []),
              ...radarAddressOptions,
            ]
          : undefined
      }
      dropdownRender={menu => {
        return (
          <>
            {Array.isArray(radarAddressOptions) &&
              !Array.isArray(googleAddressOptions) &&
              !COUNTRIES_UNSUPPORTED_BY_RADAR.includes(limitedToCountryCode) && (
                <>
                  <Space
                    direction="horizontal"
                    size="small"
                    style={{ margin: '5px 12px', cursor: 'pointer' }}
                    align="center"
                    wrap={false}
                  >
                    <img width="24px" height="24px" src={IMAGES.SEARCH_CIRCLE_ICON} alt="search" />

                    <Text size="sm" onClick={() => fetchLocationFromGoogle.mutateAsync(searchTerm)}>
                      {t('didntFindLookFor')}
                    </Text>
                  </Space>
                  <HorizontalLineDivider marginTop="0px" marginBottom="0px" />
                </>
              )}
            {locationFromRadarQuery.isFetching ||
            locationFromGoogleQuery.isFetching ||
            fetchLocationFromGoogle.isLoading ? (
              <Space size="small" style={{ margin: '5px 12px' }}>
                <FadedText>{t('searching...')}</FadedText>
              </Space>
            ) : (
              menu
            )}
          </>
        );
      }}
      onSelect={handleSelect}
      onChange={handleChange}
      onFocus={requestGeoLocationPermissions}
      notFoundContent={
        locationFromRadarQuery.isFetching ||
        locationFromGoogleQuery.isFetching ||
        fetchLocationFromGoogle.isLoading ? (
          <FadedText>{t('searching...')}</FadedText>
        ) : Array.isArray(radarAddressOptions) || Array.isArray(googleAddressOptions) ? (
          <FadedText>{t('noMatchesFound')}</FadedText>
        ) : null
      }
    />
  );
};

LocationInput.defaultProps = {
  size: 'small',
};

export default withNamespaces()(LocationInput);
