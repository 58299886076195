import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import PlansAPI from '../../api/plans';
import CustomPlansModal from '../../components/Modal/CustomPlansModal';
import CustomPlansTable from '../../components/Table/CustomPlansTable';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { emitFetchCustomPricesData } from '../../stores/actions/prices';
import { formatPageTitle } from '../../utils/common';
import { useStoreSelector } from '../../utils/storeSelectors';

const CustomPlansView = props => {
  const { t } = props;

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const pricesStore = useStoreSelector('prices');
  const [planToEdit, setPlanToEdit] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(emitFetchCustomPricesData());
  }, [dispatch]);

  const handleCloseModal = () => setPlanToEdit();

  const handlePlanUpdate = async values => {
    setIsSubmitting(true);

    try {
      await PlansAPI.updateCustomPlan(planToEdit._id, values.name, values.stripePriceId);
      dispatch(emitFetchCustomPricesData());
      handleCloseModal();
      Toast({
        type: 'open',
        message: t('customPlanCreateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorUpdatingCustomPlan'),
        });
      });
    }

    setIsSubmitting(false);
  };

  const handlePlanCreate = async values => {
    setIsSubmitting(true);

    try {
      await PlansAPI.createCustomPlan(values.name, values.stripePriceId);
      dispatch(emitFetchCustomPricesData());
      handleCloseModal();
      Toast({
        type: 'open',
        message: t('customPlanUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorCreatingCustomPlan'),
        });
      });
    }

    setIsSubmitting(false);
  };

  return (
    <PageContainer
      title={t('customPlans')}
      pageActionText={t('createCustomPlan')}
      onPageActionClick={
        pricesStore.isLoadingData ? undefined : () => setPlanToEdit({ _id: 'new' })
      }
    >
      <Helmet>
        <title>{formatPageTitle('Custom Plans')}</title>
      </Helmet>

      <CustomPlansTable
        t={t}
        onEdit={setPlanToEdit}
        loading={pricesStore.isLoadingData}
        dataSource={pricesStore.customPlans}
      />

      <CustomPlansModal
        t={t}
        isSubmitting={isSubmitting}
        visible={!!planToEdit}
        formInstance={form}
        initialValues={planToEdit}
        onCancel={handleCloseModal}
        onSubmit={() => {
          form.validateFields().then(values => {
            if (planToEdit?._id === 'new') {
              handlePlanCreate(values);
            } else {
              handlePlanUpdate(values);
            }
          });
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(CustomPlansView);
