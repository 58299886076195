import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiCalls } from '../../../api/axiosInstance';
import { fetchNewCompanyApiKey } from '../../../api/company';
import { Button, ConfirmModal } from '../../../components';
import CompanyApiKeysTable from '../../../components/Table/CompanyApiKeysTable';
import Text from '../../../components/Text';
import PageContainer from '../../../containers/PageContainer';
import { saveCompany } from '../../../stores/actions/common';
import { formatPageTitle } from '../../../utils/common';
import { selectStoreCurrentCompany } from '../../../utils/storeSelectors';

const CompanyApiKeysView = props => {
  const { t } = props;

  const dispatch = useDispatch();
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [apiKeys, setApiKeys] = useState(currentCompany?.api ? [currentCompany.api] : []);

  const generateApiKey = async () => {
    setIsLoading(true);

    try {
      const data = await fetchNewCompanyApiKey();
      setApiKeys([data]);
      dispatch(saveCompany({ api: data }));
      setIsConfirmationVisible(false);
    } catch (error) {
      handleApiCalls(error.response);
    }

    setIsLoading(false);
  };

  return (
    <PageContainer
      noSurroundingSpace
      title={
        <Text renderAs="p" variant="b" size="sm" style={{ marginBottom: 16 }}>
          {t('apiKeys')}
        </Text>
      }
      sideActionComponent={
        <Button
          size="sm"
          loading={isLoading}
          disabled={isLoading}
          onClick={currentCompany.api ? () => setIsConfirmationVisible(true) : generateApiKey}
        >
          {t(currentCompany.api ? 'refreshToken' : 'createToken')}
        </Button>
      }
    >
      <Helmet>
        <title>{formatPageTitle('API Keys')}</title>
      </Helmet>

      <Text>{t('theseKeysWillAuthenticateApiRequests')}</Text>

      <br />

      <CompanyApiKeysTable t={t} dataSource={apiKeys} loading={isLoading} />

      <ConfirmModal
        loading={isLoading}
        title={t('refreshApiKey?')}
        message={
          <div>
            <Text>{t('refreshToken_confirmation_line_1')}</Text>
            <br />
            <Text>{t('refreshToken_confirmation_line_2')}</Text>
          </div>
        }
        visible={isConfirmationVisible}
        okText={t('yes')}
        onOk={generateApiKey}
        onCancel={() => setIsConfirmationVisible(false)}
      />
    </PageContainer>
  );
};

export default CompanyApiKeysView;
