import { Button as AntdButton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
// the hoc
import { withNamespaces } from 'react-i18next';

import classNames from './style.module.scss';

const Button = ({
  text,
  type,
  icon,
  className,
  size,
  onClick,
  hidden,
  t,
  reportNS,
  defaultNS,
  tReady,
  i18nOptions,
  disabled,
  children,
  ...rest
}) => {
  const btnClassName = [
    'ge-button',
    classNames.geButton,
    classNames[size],
    classNames[type],
    hidden ? classNames.hidden : '',
    disabled ? classNames.disabled : '',
    className,
  ];

  const onButtonClick = e => {
    if (!hidden && !disabled && typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <AntdButton
      className={btnClassName.join(' ')}
      onClick={onButtonClick}
      icon={typeof icon == 'string' ? <i className={icon} /> : icon}
      {...rest}
    >
      {children || t(text) ? <span className={classNames.text}>{children || t(text)}</span> : null}
    </AntdButton>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary']),
  icon: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'medium', 'large']),
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  text: '',
  type: 'primary',
  icon: undefined,
  className: '',
  size: 'medium',
  onClick: () => {},
  hidden: false,
  disabled: false,
  children: undefined,
};

export default withNamespaces()(Button);
