import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { COMPANY_API } from '../../api/company';
import CommonTableFilters from '../../components/shared-ui/CommonTableFilters';
import CompaniesTable from '../../components/Table/CompaniesTable';
import Text from '../../components/Text';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import useTablePagination from '../../hooks/useTablePagination';
import { removeCompany } from '../../stores/actions/common';
import { formatPageTitle } from '../../utils/common';
import { getMinutesAsMilliseconds } from '../../utils/numbers';

const SelectCompanyView = props => {
  const { t } = props;

  const dispatch = useDispatch();
  const [companyNameSearchTerm, setCompanyNameSearchTerm] = useState('');

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  const handleCompanyNameSearch = useCallback(
    term => {
      setCompanyNameSearchTerm(term);
      handlePageChange(1);
    },
    [handlePageChange],
  );
  const allCompaniesQuery = useQuery({
    queryKey: [
      'getAllCompanies',
      companyNameSearchTerm,
      paginationConfig.current,
      paginationConfig.pageSize,
    ],
    staleTime: getMinutesAsMilliseconds(10),
    placeholderData: {
      documents: [],
      totalCount: 0,
    },
    queryFn: () =>
      COMPANY_API.getAllCompanies({
        pageNumber: paginationConfig.current,
        pageSize: paginationConfig.pageSize,
        searchTerm: companyNameSearchTerm,
      }),
    onError: error => handleApiErrors(error.response),
  });

  useEffect(() => {
    dispatch(removeCompany());
  }, [dispatch]);

  useEffect(() => {
    if (
      !allCompaniesQuery.isFetching &&
      paginationConfig.total !== allCompaniesQuery.data.totalCount
    ) {
      handleTotalUpdate(allCompaniesQuery.data.totalCount);
    }
  }, [allCompaniesQuery, paginationConfig, handleTotalUpdate]);

  return (
    <PageContainer>
      <Helmet>
        <title>{formatPageTitle('Select Companies')}</title>
      </Helmet>

      <Text>
        {t('totalNumberCompanies')}: {paginationConfig.total}
      </Text>

      <Row justify="end">
        <Col flex="400px">
          <CommonTableFilters
            disabledFilters={allCompaniesQuery.isFetching}
            hiddenFilters={['user', 'type', 'dateRange', 'group', 'status', 'crmMatch']}
            searchTermValue={companyNameSearchTerm}
            onSearchTermChange={handleCompanyNameSearch}
            searchInputPlaceholder={t('searchCompanyName')}
          />
        </Col>
      </Row>

      <CompaniesTable
        t={t}
        showSearchInput={false}
        loading={allCompaniesQuery.isFetching}
        dataSource={allCompaniesQuery.data.documents}
        onChange={({ current }) => handlePageChange(current)}
        pagination={{
          pageSize: paginationConfig.pageNumber,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(SelectCompanyView));
