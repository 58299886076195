import { first, get } from 'lodash';
import moment from 'moment-timezone';

import i18n from '../i18n';
import { formatQueryRange, momentFormat } from '../utils/common';
import { cleanQueryParams, removeFalseyParams } from '../utils/queryParams';
import { sortColumnByStringField } from '../utils/tables';
import { normalizeUserSchema } from '../utils/users';
import {
  handleApiCalls,
  handleApiErrors,
  handleApiFileDownloads,
  performApiCallIfCompanySubIsActive,
} from './axiosInstance';
import BaseAPI from './baseAPI';

/**
 * Check if the provided User is a member in any of the provided Groups

 * @param {object} userProfile User Profile data
 * @param {object} groups Groups array
 * @return {boolean} is member in group?
 */
export const isUserMemberInAnyGroup = (userProfile, groups) => {
  let memberInGroup = false;

  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    const users = Array.isArray(group?.users) ? group.users.map(user => user?._id) : [];
    memberInGroup = users.includes(userProfile._id);
    if (memberInGroup) break;
  }

  return memberInGroup;
};

export const fetchCurrentUserDetails = async () => {
  const url = `${process.env.REACT_APP_HOST_API}user/me`;
  const result = await handleApiCalls('get', url);
  return result.data.data;
};

export const updateUser = async (userID, data) => {
  const url = `${process.env.REACT_APP_HOST_API}user/${userID}`;
  const result = await performApiCallIfCompanySubIsActive('put', url, data);

  return result.data.data;
};

/**
 * Performs an API call to update the currently logged in User
 *
 * @param {{ userID: string, imageFile: File }} params Data to update the User schema
 * @param {Function=} onSuccess On Success callback
 * @param {Function=} onError On Error callback
 * @return {Promise<void>}
 */
export async function uploadUserProfileImage(params, onSuccess, onError) {
  const { userID, imageFile } = params;

  const formData = new FormData();
  formData.append('file', imageFile);
  let result;
  try {
    result = await performApiCallIfCompanySubIsActive(
      'put',
      `${process.env.REACT_APP_HOST_API}user/${userID}/profile-picture`,
      formData,
      { 'Content-Type': 'multipart/form-data' },
    );
    if (typeof onSuccess === 'function') {
      onSuccess(result);
    }
  } catch (error) {
    handleApiErrors(error.response, () => {
      if (typeof onError === 'function') {
        onError(result);
      }
    });
  }
}

/**
 * Performs an API call to update the vacations of a given User
 *
 * @param {{ userID: string, disabilityAndLeaveDates: object[] }} params Vacations array
 * @return {Promise<void>}
 */
export async function updateUserVacationsApiCall(params) {
  const { userID, disabilityAndLeaveDates } = params;

  const result = await performApiCallIfCompanySubIsActive(
    'patch',
    `${process.env.REACT_APP_HOST_API}user/${userID}/leave-dates`,
    { disabilityAndLeaveDates },
  );

  return result.data.data;
}

/**
 * Performs an API call to update the OBD Device of a given User
 *
 * @param {{ userID: string, vacationDates: object[] }} params Vacations array
 * @param {Function=} onSuccess On Success callback
 * @param {Function=} onError On Error callback
 * @return {Promise<void>}
 */
export async function updateUserObdDeviceApiCall(params, onSuccess, onError) {
  const { userID, device } = params;

  const result = await handleApiCalls(
    'patch',
    `${process.env.REACT_APP_HOST_API}user/${userID}/devices`,
    { devices: [device] },
  );
  // {
  //   deviceID: device.obd,
  //   deviceStatus: device.obd_connected,
  //   shipDate: moment(devices.shippedOn, 'DD MMM YYYY'),
  //   shipTo: device.shippedTo,
  // }

  if (result.status === 200 && typeof onSuccess === 'function') {
    onSuccess(result);
  } else if (typeof onError === 'function') {
    onError(result);
  }
}

/**
 *
 * @param {string} userId ID of User to fetch activity
 * @param {string} fromDate Year - format: 2021-03-08
 * @param {string} toDate Year - format: 2021-03-08
 * @param {number} skip Amount of items to skip
 * @param {number} fetchAmount Amount of items to fetch
 * @param {string} type Type of activity to fetch
 */
export const fetchCurrentUserActivityHistory = async (
  userId,
  fromDate,
  toDate,
  skip = 0,
  fetchAmount = 20,
  type = 'all',
) => {
  const url = `${
    process.env.REACT_APP_HOST_API
  }user/${userId}/activity?fromDate=${fromDate}&toDate=${toDate}&type=${type}&range=[${skip},${
    skip + fetchAmount
  }]`;
  const result = await handleApiCalls('get', url);

  return result.data.data.userActivity;
};

/**
 * Performs an API call to update a User's role or multiple Users'
 *
 * @param {string} role Role name to update user
 * @param {string[]} userIds Array if UserIDs that will change roles
 */
export const changeUserRole = async (role, userIds = []) => {
  if (!userIds.length) throw new Error(i18n.t('provideValidListOfUsers'));

  const result = await performApiCallIfCompanySubIsActive(
    'patch',
    `${process.env.REACT_APP_HOST_API}user/role`,
    {
      role,
      userIds,
    },
  );

  return result.data.data;
};

/**
 * Performs an API call to update an User's status
 *
 * @param {string} userIds ID of User that will change roles
 * @param {string} status Status to update user
 */
export const changeUserStatus = async (userId, status) => {
  const result = await performApiCallIfCompanySubIsActive(
    'patch',
    `${process.env.REACT_APP_HOST_API}user/${userId}/status`,
    { status },
  );

  return result.data.data;
};

export const fetchUserByID = async userID => {
  const result = await handleApiCalls('get', `${process.env.REACT_APP_HOST_API}user/${userID}`);
  return result.data.data;
};

export const fetchUserFavrRates = async userId => {
  const url = `${process.env.REACT_APP_HOST_API}user/${userId}/favr-rates?range=[0,50]&sort={"created":"desc"}&active=true`;
  const result = await handleApiCalls('get', url);

  return result.data;
};

export const fetchUserActivityInsights = async (params = {}, pageNumber, pageSize) => {
  const range = formatQueryRange(pageNumber, pageSize);
  const date = momentFormat(params.date, 'YYYY-MM-DD');
  const queryParams = cleanQueryParams({ ...params, date, range });
  const url = `${process.env.REACT_APP_HOST_API}company/users/activity-insight?${queryParams}`;
  const result = await handleApiCalls('get', url);

  return result.data;
};

export const fetchUserInsurancePolicies = async userId => {
  const url = `${process.env.REACT_APP_HOST_API}user/${userId}/vehicle-insurance-policies`;
  const result = await handleApiCalls('get', url);
  return result.data.data;
};

export const sendAppConfigToLog = async userId => {
  const url = `${process.env.REACT_APP_HOST_API}user/sendAppConfigToLog`;
  await handleApiCalls('post', url, { userId });
};

export const fetchAppConfigLog = async companyId => {
  const url = `${process.env.REACT_APP_HOST_API}user/getAppConfigLogs/${companyId}`;
  const result = await handleApiCalls('get', url);
  return result.data.data;
};

export const deleteUserLicense = async fileKey => {
  const url = `${process.env.REACT_APP_HOST_API}user/${fileKey}/delete-driver-license`;
  const result = await handleApiCalls('delete', url);
  return result.data;
};

export const downloadUserLicense = async (fileKey, filename) => {
  const url = `${process.env.REACT_APP_HOST_API}user/download-driver-license`;
  await handleApiFileDownloads('post', url, { fileKey }, filename);
};

export const fetchUserFavrInformation = async userId => {
  const url = `${process.env.REACT_APP_HOST_API}user/${userId}/favr-informations`;
  const result = await handleApiCalls('get', url);
  return result.data;
};

/**
 * @param {"Android" | "iOS"} mobileOS
 */
export const fetchUserTripsAppVersion = async (userID, mobileOS) => {
  // const range = formatQueryRange(1, 3);
  const url = `${process.env.REACT_APP_HOST_API}user/${userID}/trips/app-versions?mobileOS=${mobileOS}`;
  const result = await handleApiCalls('get', url);
  return result.data;
};

class User extends BaseAPI {
  constructor() {
    super({ suffix: 'user' });
  }
  /**
   * Fetch user's data through API
   * @param {string} userId
   */
  async getUserDetails(userId) {
    const url = `${this.baseUrl}/${userId}`;
    const result = await handleApiCalls('get', url);
    const normalizedData = normalizeUserSchema(result.data.data);
    return normalizedData;
  }
  async getCompanyAdminsAndManagers(companyID) {
    const sort = JSON.stringify({ firstName: 1, lastName: 1 });
    const url = `${this.baseUrl}/getCompanyAdminsAndManagers?companyId=${companyID}&sort=${sort}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  }
  /**
   * Returns the list of users that are part of a company from the API
   *
   * @param {string} companyID ID of Company
   * @param {object} filters
   * @param {string[]?} status
   * @param {number} pageNumber
   * @param {number} pageSize
   */
  async getUserList(
    companyID,
    filter = {},
    status,
    pageNumber,
    pageSize,
    sort = JSON.stringify({ firstName: 1, lastName: 1 }),
  ) {
    const {
      groupId,
      searchTerm,
      isAnonymized,
      globalSearch = false,
      userId,
      ...otherFilters
    } = filter;
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({
      range,
      status: status ? JSON.stringify(status) : status,
      groupId,
      companyId: companyID,
      filter: JSON.stringify(removeFalseyParams(otherFilters)),
      isAnonymized,
      searchTerm,
      sort,
      userId,
      globalSearch,
    });

    const url = `${this.baseUrl}?${queryParams}`;
    const result = await handleApiCalls('get', url);
    return {
      users: result.data.documents,
      totalCount: result.data.totalCount,
    };
  }
  /**
   * Get user tax reports
   * @param {string} userID
   * @param {number} year
   */
  async fetchUserTaxReport(userID, year) {
    const queryParams = cleanQueryParams({ year });
    const url = `${this.baseUrl}/${userID}/tax-reports?${queryParams}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  }
  /**
   * Get list of users managed by Company Manager
   * @param {string} managerUserId
   * @param {object} filters
   * @param {number} pageNumber
   * @param {number} pageSize
   * @returns
   */
  async fetchManagedUsers(managerUserId, filters, sort, pageNumber, pageSize) {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range, sort, ...filters });
    const url = `${this.baseUrl}/${managerUserId}/managed?${queryParams}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  }
  /**
   * Get user emissions
   * @param {string} userId
   * @param {number} pageNumber
   * @param {number} pageSize
   */
  async fetchUserEmissions(userId, sort, pageNumber, pageSize) {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range, sort });
    const url = `${this.baseUrl}/${userId}/emissions?${queryParams}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  }
  /**
   * @param {string} userId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @returns {{ _id: string, name: string }[]}
   */
  async fetchUserGroups(userId, pageNumber, pageSize) {
    const url = `${this.baseUrl}/${userId}/groups`;
    const result = await handleApiCalls('get', url);
    return result.data.sort(sortColumnByStringField('name').sorter);
  }
  /**
   * @param {string} userId
   * @returns {string[]}
   */
  async fetchYearsWithFavrValuesData(userId) {
    const url = `${this.baseUrl}/${userId}/favr-values/years`;
    const result = await handleApiCalls('get', url);
    return get(result.data, 'years', []);
  }
  /**
   * @param {object} filters
   * @param {string} filters.userId
   * @param {string} filters.year
   * @param {string} sort
   * @param {number} pageNumber
   * @param {number} pageSize
   */
  async fetchUserFavrValuesHistory({ userId, year }, sort, pageNumber, pageSize) {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ year, range, sort });
    const url = `${this.baseUrl}/${userId}/favr-values?${queryParams}`;
    const result = await handleApiCalls('get', url);
    return first(result.data);
  }
  /**
   * @param {string[]} users
   * @param {string} companyId
   * @param {Date} sendActivationEmailDate
   * @returns { data: { data: string, userDetails: object[] }}
   */
  async inviteUsers(users = [], companyId) {
    const url = `${this.baseUrl}/invite`;
    return performApiCallIfCompanySubIsActive('post', url, { users, companyId });
  }
  /**
   * @param {string} userID
   * @param {object} data
   */
  async updateUser(userID, data) {
    const url = `${this.baseUrl}/${userID}`;
    const result = await performApiCallIfCompanySubIsActive('put', url, data);

    return result.data.data;
  }
  /**
   * @param {string} userID ID if User
   * @param {object} data
   * @param {object} data.preferences
   * @param {number} data.preferences.tripStopDurationMinimum
   * @param {'minutes'} data.preferences.tripStopDurationUnit
   * @param {boolean} data.preferences.businessHoursAutoStart
   * @param {string} data.preferences.preferredTimezone
   * @param {{ day: string, startTime: number, endTime: number }[]} data.preferences.businessDayTime
   */
  async updateUserTripRecordingPreferences(userID, data) {
    return this.updateUser(userID, { preferences: data });
  }
  /**
   * @typedef {Object} DashboardData
   * @property {string} date30DaysBefore
   * @property {number} newActiveUsersCount
   * @property {string?} oldestJourneyStartTs
   * @property {string?} oldestPendingReimbursementCreatedDate
   * @property {string?} oldestPendingTripReceiptCreatedDate
   * @property {number} paidReimbursementsAmount
   * @property {number} pendingReimbursementsAmount
   * @property {number} potentialAmount
   * @property {number} receiptsCount
   * @property {number} tripsCount
   */

  /**
   * Performs an API call to fetch the data to populate the Dashboard for each user role
   * @param {object} params
   * @returns {DashboardData}
   */
  async fetchDashboardData(params) {
    const result = await handleApiCalls(
      'get',
      `${this.baseUrl}/dashboard-data?${cleanQueryParams(params)}`,
    );

    const data = get(result, 'data.data');

    return {
      ...data,
      oldestJourneyStartTs: data.oldestJourneyStartTs
        ? moment(data.oldestJourneyStartTs).startOf('day').toISOString()
        : null,
      oldestPendingReimbursementCreatedDate: data.oldestPendingReimbursementCreatedDate
        ? moment(data.oldestPendingReimbursementCreatedDate).startOf('day').toISOString()
        : null,
      oldestPendingTripReceiptCreatedDate: data.oldestPendingTripReceiptCreatedDate
        ? moment(data.oldestPendingTripReceiptCreatedDate).startOf('day').toISOString()
        : null,
      date30DaysBefore: moment().subtract(30, 'd').startOf('day').toISOString(),
    };
  }

  /**
   * @typedef {"ios" | "android"} MobileAppType
   */

  /**
   * @typedef {Object} MobileAppSetting
   * @property {string} _id
   * @property {MobileAppType} type
   * @property {object} battery
   * @property {boolean} battery.lowPowerMode
   * @property {object} location
   * @property {boolean} location.locationService
   * @property {boolean} location.preciseLocation
   * @property {string} created
   */

  /**
   * Get user's mobile app settings
   * @param {string} userId
   * @param {MobileAppType} type
   * @param {number} pageNumber
   * @param {number} pageSize
   * @returns {{ documents: MobileAppSetting[], totalCount: number }}
   */
  async fetchMobileAppSettings(userId, type, pageNumber = 1, pageSize = 1) {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ type, range });
    const result = await handleApiCalls(
      'get',
      `${this.baseUrl}/${userId}/mobile-app-settings?${queryParams}`,
    );
    return result.data;
  }

  /**
   * Get users trips aggregated data based on role.
   * company-manager: all managed users.
   * company-admin: all company's users.
   * @returns {{ users: number, trips: number }}
   */
  async fetchUsersAgregatedDataBasedOnRole() {
    const result = await handleApiCalls('get', `${this.baseUrl}/trips/aggregated-data`);
    return result.data;
  }

  /**
   * Confirm home address
   * @param {string} userId
   * @param {"skip" | "yes"} action
   */
  async confirmUserHomeAddress(userId, action) {
    await handleApiCalls('patch', `${this.baseUrl}/${userId}/home-address/confirm`, {
      action,
    });
  }

  /**
   * Get user tax reports years
   * @param {string} userId
   */
  async fetchUserTaxReportYears(userId) {
    const result = await handleApiCalls('get', `${this.baseUrl}/${userId}/tax-reports/years`);
    return result.data.sort().reverse();
  }

  async fetchAchReportData(filters, sort, pageNumber, pageSize) {
    const queryParams = cleanQueryParams({
      ...(filters || {}),
      status: JSON.stringify(get(filters, 'status', [])),
      sort,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const result = await handleApiCalls('get', `${this.baseUrl}/ach-report?${queryParams}`);
    return result.data;
  }

  async exportAchReportData(filters, sort, pageNumber, pageSize) {
    const queryParams = cleanQueryParams({
      ...(filters || {}),
      status: JSON.stringify(get(filters, 'status', [])),
      sort,
      range: formatQueryRange(pageNumber, pageSize),
    });
    await handleApiFileDownloads('get', `${this.baseUrl}/ach-report/export?${queryParams}`);
  }

  async fetchUserMeasureOnePolicyInformation(userId) {
    const result = await handleApiCalls(
      'get',
      `${this.baseUrl}/${userId}/measure-one/informations`,
    );

    return result.data;
  }

  async fetchUserMeasureOnePolicyInformationItems(userId, pageNumber, pageSize) {
    const queryParams = cleanQueryParams({
      range: formatQueryRange(pageNumber, pageSize),
    });
    const result = await handleApiCalls(
      'get',
      `${this.baseUrl}/${userId}/measure-one/items?${queryParams}`,
    );
    return result.data;
  }

  async fetchUserMileageCap(userId) {
    const result = await handleApiCalls('get', `${this.baseUrl}/${userId}/mileage-cap`);
    return result.data;
  }

  async exportUsersToCSV(sort, status) {
    const queryParams = cleanQueryParams({
      status: status ? JSON.stringify(status) : status,
      sort,
    });
    await handleApiFileDownloads('get', `${this.baseUrl}/export?${queryParams}`);
  }
}

export const USER_API = new User();
