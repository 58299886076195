import React from 'react';

import CRMTicketForm from '../../CRMTicketForm';
import GenericModal from '../GenericModal';

const CRMTicketModal = props => {
  const { t, visible, loading, onSubmit, onCancel, defaultValues } = props;

  return (
    <GenericModal visible={visible} title={t('accessRequest')} footer={null} onCancel={onCancel}>
      {visible ? (
        <CRMTicketForm
          t={t}
          loading={loading}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : (
        <div style={{ minHeight: 200 }} />
      )}
    </GenericModal>
  );
};

export default CRMTicketModal;
