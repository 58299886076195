import { Col, Collapse, Row } from 'antd';
import classnames from 'classnames';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from '../../components';
import Avatar from '../../components/Avatar';
import StickyBox from '../../components/Box/StickyBox';
import GreenTopBorderHeader from '../../components/GreenTopBorderHeader';
import ItineraryMap from '../../components/ItineraryMap';
import CreateItineraryModal from '../../components/Modal/CreateItineraryModal';
import SharedItineraryModal from '../../components/Modal/SharedItineraryModal';
import OptimizedItinerarySwitch from '../../components/OptimizedItinerarySwitch';
import SpaceSpinner from '../../components/SpaceSpinner';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import LinkText from '../../components/Text/LinkText';
import TextInput from '../../components/TextInput';
import { IMAGES, INTERNAL_LINKS } from '../../enum';
import { ReactComponent as EditSVG } from '../../images/pencil-write.svg';
import useLayout from '../../layout/useLayout';
import {
  formatPageTitle,
  getUserFullName,
  getUserInitials,
  momentFormat,
  momentTimezone,
} from '../../utils/common';
import { getTimeDifferenceBetweenTwoDates } from '../../utils/datetime';
import { formatCoordinatesToGoogleMapsURL } from '../../utils/map';
import { selectStoreCurrentAuthUser } from '../../utils/storeSelectors';
import useItineraryDetails from '../itinerary-details/useItineraryDetails';
import classNames from './styles.module.scss';

const renderRoutePanelContent = (t, destination, isPublic, onInputChange) => {
  if (isPublic && destination.itineraryNotes) {
    return <Text>{destination.itineraryNotes}</Text>;
  }

  if (!isPublic) {
    return (
      <TextInput
        textArea
        style={{ marginLeft: 18, maxWidth: '75%' }}
        defaultValue={destination.itineraryNotes}
        autoSize={{ minRows: 3, maxRows: 3 }}
        onChange={onInputChange}
        placeholder={t('notes')}
      />
    );
  }

  return null;
};

const renderCollapsibleRoutePanels = (t, destinations = [], isPublic, onInputChange) => {
  const panels = [];

  destinations.forEach((destination, i) => {
    let header = '';

    const destinationLink = (
      <LinkText
        renderAs="a"
        rel="noopener noreferrer"
        target="_blank"
        variant="b"
        href={formatCoordinatesToGoogleMapsURL(
          destination.coordinates[0],
          destination.coordinates[1],
        )}
      >
        {destination.address}
      </LinkText>
    );

    if (i === 0) {
      header = (
        <Text variant="b" className={classNames.firstRowText}>
          {t('Leave')}
          <span className={classnames(classNames.firstLetterDepart, classNames.letterMarker)}>
            <span>{destination.label}</span>
          </span>
          {destinationLink} {t('by_time')} {destination.tripStartTime}
        </Text>
      );
    } else {
      const nextDestination = destinations[i + 1];
      const timeUntilDepart = getTimeDifferenceBetweenTwoDates(
        momentTimezone(destination.tripEndTime, 'h:mma'),
        momentTimezone(nextDestination?.tripStartTime, 'h:mma'),
      );
      header = (
        <div>
          <Text variant="b">
            {destination.tripEndTime} - {t('arriveAt')} {destinationLink}
          </Text>
          {nextDestination && (
            <Text>({t('time_beforeNextDeparture', { time: timeUntilDepart })})</Text>
          )}
        </div>
      );
    }

    panels.push(
      <Collapse.Panel collapsible="disabled" key={i === 0 ? `${i}-depart` : i} header={header}>
        {renderRoutePanelContent(t, destination, isPublic, e =>
          onInputChange(i - 1, e.target.value),
        )}
      </Collapse.Panel>,
    );

    if (i > 0 && i + 1 !== destinations.length) {
      const subHeader = (
        <Text variant="b">
          {destinations[i + 1]?.tripStartTime} - Depart {destinationLink}
        </Text>
      );

      panels.push(<Collapse.Panel collapsible="disabled" key={`${i}-depart`} header={subHeader} />);
    }
  });

  return panels;
};

const ShareItineraryDetailsView = props => {
  const { t, history, match, location, isPublic } = props;

  const [isPreviewMode, setIsPreviewMode] = useState(isPublic);

  const [isSetup] = useState(!location.pathname.includes('public'));

  const togglePreviewMode = () => {
    setIsPreviewMode(!isPreviewMode);
    document.querySelector('.main-app-container').scrollTop = 0;
  };

  const {
    isOptimalRoute,
    isUpdatingItinerary,
    isLoadingItinerary,
    isItineraryModalOpen,
    isShareModalOpen,
    isSharingItinerary,
    itineraryDetails,
    itineraryMarkers,
    optimizedItineraryMarkers,
    itineraryShareMessage,

    openItineraryModal,
    closeItineraryModal,
    closeShareModal,
    handleUpdateItinerary,
    handleUpdateTripNote,
    handleShareItinerary,
    handleSaveWithoutShare,
    handleItineraryShareMessageChange,
    toggleOptimalRoute,
  } = useItineraryDetails({ id: match.params.id, isPublic });
  const { browserStore } = useLayout({ history });
  const authUser = useSelector(selectStoreCurrentAuthUser);

  const PAGE_HELMET = (
    <Helmet>
      <title>{formatPageTitle(isPublic ? 'Public Itinerary' : 'Share Itinerary Details')}</title>
    </Helmet>
  );

  if (isLoadingItinerary || !itineraryDetails) {
    return (
      <>
        {PAGE_HELMET}
        <SpaceSpinner />
      </>
    );
  }

  const ACTION_BUTTON_ROWS = (
    <>
      {!isPreviewMode && (
        <Row justify="end" gutter={[16, 16]}>
          <Col>
            <Button
              disabled={isUpdatingItinerary}
              size={browserStore.isMobile ? 'sm' : undefined}
              type="secondary"
              onClick={() => history.push(INTERNAL_LINKS.ITINERARY_DETAILS(match.params.id))}
            >
              {t('Back')}
            </Button>
          </Col>
          <Col>
            <Button
              disabled={isUpdatingItinerary}
              size={browserStore.isMobile ? 'sm' : undefined}
              onClick={togglePreviewMode}
            >
              {t('donePreviewItinerary')}
            </Button>
          </Col>
          <Col>
            <Button
              disabled={isUpdatingItinerary}
              size={browserStore.isMobile ? 'sm' : undefined}
              onClick={handleSaveWithoutShare}
            >
              {t('Save')}
            </Button>
          </Col>
        </Row>
      )}

      {isPreviewMode && isSetup && (
        <Row justify="end" gutter={[16, 16]}>
          <Col>
            <Button
              size={browserStore.isMobile ? 'sm' : undefined}
              type="secondary"
              onClick={togglePreviewMode}
              disabled={isSharingItinerary || isUpdatingItinerary}
            >
              {t('Back')}
            </Button>
          </Col>
          <Col>
            <Button
              size={browserStore.isMobile ? 'sm' : undefined}
              loading={isSharingItinerary}
              disabled={isSharingItinerary || isUpdatingItinerary}
              onClick={handleShareItinerary}
              icon={
                <img
                  width="20px"
                  alt="arrow"
                  style={{ marginRight: 5 }}
                  src={IMAGES.NAVIGATION_RIGHT_WHITE_ARROW_ICON}
                />
              }
            >
              {t('shareItinerary')}
            </Button>
          </Col>
          <Col>
            <Button
              disabled={isSharingItinerary || isUpdatingItinerary}
              size={browserStore.isMobile ? 'sm' : undefined}
              onClick={handleSaveWithoutShare}
            >
              {t('Save')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );

  const currentMarkers = isOptimalRoute ? optimizedItineraryMarkers : itineraryMarkers;

  return (
    <div className={classNames.pageWrapper}>
      {PAGE_HELMET}

      <GreenTopBorderHeader>
        <Row gutter={[16, 16]} wrap={false}>
          {itineraryDetails?.logo && (
            <Col>
              <img className={classNames.clientLogo} src={itineraryDetails?.logo} alt="logo" />
            </Col>
          )}
          <Col>
            <Avatar
              size={64}
              shape="circle"
              src={itineraryDetails?.userId?.profilePicture}
              alt={getUserFullName(itineraryDetails?.userId)}
              initials={getUserInitials(itineraryDetails?.userId)}
            />
          </Col>
          <Col flex={1}>
            <div className={classNames.clientInfoColumn}>
              {<Text>{getUserFullName(itineraryDetails?.userId)}</Text>}

              {<FadedText size="xs">{itineraryDetails?.companyId?.name}</FadedText>}

              {itineraryDetails?.userId?.phone && (
                <FadedText size="xs">
                  {t('cell')}: {itineraryDetails?.userId?.phone}
                </FadedText>
              )}
            </div>
          </Col>
        </Row>
      </GreenTopBorderHeader>

      <Row>
        <Col flex={1}>
          <Text variant="h3">
            {t('itineraryFor')} {momentFormat(itineraryDetails.dateFrom, 'MMMM Do, YYYY')}
          </Text>

          <br />

          {isPreviewMode ? (
            <Text>{itineraryShareMessage}</Text>
          ) : (
            <TextInput
              textArea
              defaultValue={itineraryShareMessage || itineraryDetails?.shareMessage}
              autoSize={{ minRows: 4, maxRows: 6 }}
              placeholder={t('typeYourMessage')}
              onChange={e => handleItineraryShareMessageChange(e.target.value)}
            />
          )}
        </Col>
      </Row>

      <div className={classNames.pageRow}>
        <div className={classNames.mapColumnGrid}>
          <Row justify="end" style={{ width: '100%' }}>
            <Col>
              <OptimizedItinerarySwitch
                t={t}
                isOptimalRoute={isOptimalRoute}
                itineraryMarkers={itineraryMarkers}
                optimizedItineraryMarkers={optimizedItineraryMarkers}
                onToggleChange={toggleOptimalRoute}
              />
            </Col>
          </Row>

          <ItineraryMap
            t={t}
            isOptimalRoute={isOptimalRoute}
            markers={currentMarkers}
            trips={isOptimalRoute ? itineraryDetails.trips : itineraryDetails.optimizedTrips}
            routes={
              isOptimalRoute && Array.isArray(itineraryDetails.optimalRouteCoordinates)
                ? itineraryDetails.optimalRouteCoordinates
                : itineraryDetails.routeCoordinates
            }
          />
        </div>
        <div className={classNames.todaysRouteSection}>
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col>
              <Text variant="h3">{t('ourRouteToday')}</Text>
            </Col>

            {!isPreviewMode && (
              <Col>
                <Button
                  size="sm"
                  type="secondary"
                  icon={<EditSVG width="18px" alt="arrow" style={{ marginRight: 5 }} />}
                  onClick={openItineraryModal}
                >
                  {t('Edit')}
                </Button>
              </Col>
            )}
          </Row>

          <div>
            <Collapse
              ghost
              activeKey={currentMarkers.map((destination, i) => {
                if (i === 0) return '';
                if ((isPreviewMode && destination.itineraryNotes) || !isPreviewMode) return `${i}`;
                return '';
              })}
              expandIcon={item => {
                return item.panelKey.includes('depart') ? (
                  <img
                    className={classNames.carIcon}
                    src={IMAGES.CAR_DARK_FILL_ICON}
                    alt="car depart"
                  />
                ) : (
                  <span className={classNames.letterMarker}>
                    <span>{currentMarkers[item.panelKey].label}</span>
                  </span>
                );
              }}
            >
              {renderCollapsibleRoutePanels(t, currentMarkers, isPreviewMode, handleUpdateTripNote)}
            </Collapse>
          </div>

          {!browserStore.isMobile && ACTION_BUTTON_ROWS}
        </div>
      </div>

      {authUser && browserStore.isMobile && (
        <StickyBox placement="bottom">{ACTION_BUTTON_ROWS}</StickyBox>
      )}

      {!isPublic && (
        <CreateItineraryModal
          t={t}
          isUpdate
          isSavingItinerary={isUpdatingItinerary}
          initialValues={itineraryDetails}
          visible={isItineraryModalOpen}
          onCancel={closeItineraryModal}
          onSubmit={handleUpdateItinerary}
        />
      )}

      {!isPublic && (
        <SharedItineraryModal
          t={t}
          user={itineraryDetails.userId}
          clients={itineraryDetails.clientDetails}
          visible={isShareModalOpen}
          onCancel={closeShareModal}
        />
      )}
    </div>
  );
};

export default withNamespaces()(ShareItineraryDetailsView);
