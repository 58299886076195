import React from 'react';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import { USER_API } from '../../../api/user';
import MobileAppSettingHistoryTable from '../../../components/Table/MobileAppSettingHistoryTable';
import useTablePagination from '../../../hooks/useTablePagination';
import { getMinutesAsMilliseconds } from '../../../utils/numbers';

const MobileAppSettingHistorySection = props => {
  const { t, userId, type } = props;

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  const historyQuery = useQuery({
    staleTime: getMinutesAsMilliseconds(5),
    placeholderData: { documents: [], totalCount: 0 },
    queryKey: [
      'fetchMobileAppSettings',
      userId,
      type,
      paginationConfig.current,
      paginationConfig.pageSize,
    ],
    queryFn: () =>
      USER_API.fetchMobileAppSettings(
        userId,
        type,
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    onSuccess: ({ totalCount }) => handleTotalUpdate(totalCount),
    onError: error => handleApiErrors(error.resposne),
  });

  return (
    <section>
      <MobileAppSettingHistoryTable
        t={t}
        type={type}
        loading={historyQuery.isFetching}
        dataSource={historyQuery.data.documents}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
        onChange={({ current }) => handlePageChange(current)}
      />
    </section>
  );
};

export default MobileAppSettingHistorySection;
