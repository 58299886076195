import { useFlagsmith } from 'flagsmith/react';
import _, { get, kebabCase } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'store';

import { fetchCountryConfig } from '../../api/common';
import { getCompanyInfo } from '../../api/company';
import { fetchCurrentUserDetails } from '../../api/user';
import { INTERNAL_LINKS } from '../../enum';
import { BACK_OFFICE_BASENAME, KLIKS_SYSTEM_BASENAME } from '../../enum/InternalLinks';
import i18n from '../../i18n';
import {
  emitUpdateGlobalBasename,
  saveCompany,
  updateSupportedCountries,
} from '../../stores/actions/common';
import { updateProfile } from '../../stores/actions/profile';
import { emitNavigateTo } from '../../stores/actions/router';
import { setRegStatus } from '../../stores/actions/user';
import { getRedirectUri, logoutUser } from '../../utils/auth';
import { setAnalyticsUserProperties } from '../../utils/googleAnalytics';
import { PENDO_ANALYTICS } from '../../utils/pendoAnalytics';
import {
  hasBackOfficeUserRole,
  hasCompanyManagerOrAdminRole,
  hasSystemAdminRole,
  hasUserRole,
} from '../../utils/roles';
import { selectStoreCompanySettings } from '../../utils/storeSelectors';
import { normalizeUserSchema } from '../../utils/users';
import Toast from '../Toast';
import { BACK_OFFICE, KLIKS_APP, SYSTEM_ADMIM } from './authenticated-views-models';

const useAuthenticatedViewsState = ({ location }) => {
  const dispatch = useDispatch();
  const flagsmith = useFlagsmith();
  const authUser = useSelector(state => state.profile);
  const { supportedCountries, currentCompany } = useSelector(state => state.common);
  const companySettings = useSelector(selectStoreCompanySettings);

  const [hasMounted, setHasMounted] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [portalToRender, setPortalToRender] = useState();

  const loadCompanyData = useCallback(
    async userData => {
      const IS_IN_SYSTEM_ROUTES = new RegExp(INTERNAL_LINKS.KLIKS_SYSTEM).test(location.pathname);
      const IS_IN_COMPANY_ROUTE = new RegExp(INTERNAL_LINKS.COMPANY_ONBOARDING).test(
        location.pathname,
      );
      const IS_SYSTEM_ADMIN = hasSystemAdminRole({ profile: userData });

      setIsSystemAdmin(IS_SYSTEM_ADMIN);

      if (IS_SYSTEM_ADMIN) {
        dispatch(emitUpdateGlobalBasename(KLIKS_SYSTEM_BASENAME));
        if (!IS_IN_SYSTEM_ROUTES) dispatch(emitNavigateTo());
      } else if (
        (hasCompanyManagerOrAdminRole({ profile: userData }) ||
          hasUserRole({ profile: userData })) &&
        !currentCompany?.name
      ) {
        const company = await getCompanyInfo(userData);

        if (company?.preferredTimezone) {
          moment.tz.setDefault(company?.preferredTimezone);
        }

        if (company?.name) {
          await dispatch(saveCompany(company));
          await dispatch(setRegStatus(company.regStatus));

          PENDO_ANALYTICS.update(userData, company);

          const basename = _.kebabCase(company.name);
          const PATHNAME_INCLUDES_BASENAME = new RegExp(`/${basename}/`).test(
            window.location.pathname,
          );

          dispatch(emitUpdateGlobalBasename(basename));

          if (!company.regCompleted && !IS_IN_COMPANY_ROUTE) {
            dispatch(emitNavigateTo(INTERNAL_LINKS.COMPANY_ONBOARDING));
          } else if (company.regCompleted && !PATHNAME_INCLUDES_BASENAME) {
            dispatch(emitNavigateTo(INTERNAL_LINKS.DASHBOARD));
          }
        } else {
          logoutUser();
        }
      }
    },
    // eslint-disable-next-line
    [currentCompany, dispatch],
  );

  const handleInvalidTokenError = () => {
    logoutUser();

    Toast({
      type: 'error',
      message: i18n.t('sessionHasExpired'),
    });
  };

  useQuery({
    enabled: !authUser,
    queryKey: ['current-user-details'],
    queryFn: () => (storage.get('accessToken') ? fetchCurrentUserDetails() : undefined),
    onSuccess: currentUserDetails => {
      if (currentUserDetails) {
        PENDO_ANALYTICS.update(currentUserDetails);
        setAnalyticsUserProperties(currentUserDetails);
        const normalizedUser = normalizeUserSchema(currentUserDetails);
        dispatch(updateProfile(normalizedUser));

        if (hasSystemAdminRole({ profile: normalizedUser })) {
          dispatch(emitUpdateGlobalBasename(KLIKS_SYSTEM_BASENAME));
        } else if (hasBackOfficeUserRole({ profile: normalizedUser })) {
          dispatch(emitUpdateGlobalBasename(BACK_OFFICE_BASENAME));
        } else if (currentUserDetails?.companyName) {
          dispatch(emitUpdateGlobalBasename(kebabCase(currentUserDetails?.companyName)));
        }

        const language = normalizedUser?.preferences?.language || 'en';
        i18n.changeLanguage(language);

        const timezone =
          currentCompany?.preferredTimezone || normalizedUser?.preferences?.preferredTimezone;

        if (timezone) {
          moment.tz.setDefault(timezone);
        }

        setHasMounted(true);
      }
    },
    onError: () => handleInvalidTokenError(),
  });

  useQuery({
    enabled: !supportedCountries,
    queryKey: ['supported-countries'],
    queryFn: fetchCountryConfig,
    onSuccess: countries => dispatch(updateSupportedCountries(countries)),
    onError: () => {
      Toast({
        type: 'error',
        message: i18n.t('errorLoadingSupportedCountries'),
      });
    },
  });

  useEffect(() => {
    const accessToken = storage.get('accessToken');
    if (!accessToken) {
      Toast({
        type: 'error',
        message: i18n.t('mustBeLoggedInToAccess'),
      });

      dispatch(emitNavigateTo(`${INTERNAL_LINKS.LOGIN}?redirect_uri=${getRedirectUri()}`));

      return () => undefined;
    }

    if (authUser) {
      setHasMounted(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authUser && authUser.profile) {
      const IS_SYSTEM_ADMIN = hasSystemAdminRole(authUser);
      setIsSystemAdmin(IS_SYSTEM_ADMIN);
    }
    // eslint-disable-next-line
  }, [authUser, currentCompany]);

  useEffect(() => {
    if (
      !currentCompany?.name &&
      Array.isArray(authUser?.profile?.businessInfor?.companies) &&
      !!authUser?.profile?.businessInfor?.companies.length
    ) {
      loadCompanyData(authUser.profile);
    }
  }, [currentCompany, loadCompanyData, authUser]);

  useEffect(() => {
    if (hasSystemAdminRole(authUser)) {
      setPortalToRender(SYSTEM_ADMIM);
    } else if (hasBackOfficeUserRole(authUser)) {
      setPortalToRender(BACK_OFFICE);
    } else if (hasCompanyManagerOrAdminRole(authUser) || hasUserRole(authUser)) {
      setPortalToRender(KLIKS_APP);
    }
  }, [authUser]);

  useEffect(() => {
    if (
      authUser &&
      !flagsmith?.identity &&
      !!currentCompany?.name &&
      !hasSystemAdminRole(authUser) &&
      !hasBackOfficeUserRole(authUser)
    ) {
      flagsmith.identify(get(authUser, 'profile.email'), {
        user_id: get(authUser, 'profile._id'),
        user_email: get(authUser, 'profile.email'),
        group_name: get(authUser, 'profile.group.name'),
        company_name: currentCompany.name,
        web_app_version: process.env.REACT_APP_VERSION,
      });
    }
  }, [authUser, currentCompany, flagsmith]);

  return {
    portalToRender,
    authUser,
    currentCompany,
    companySettings,
    hasMounted,
    isSystemAdmin,
  };
};

export default useAuthenticatedViewsState;
