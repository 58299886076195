import { Col, Row } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '../../../../components';
import AnimatedCheckmark from '../../../../components/Lottie/AnimatedCheckmark';
import GenericModal from '../../../../components/Modal/GenericModal';
import Text from '../../../../components/Text';
import LinkText from '../../../../components/Text/LinkText';
import { INTERNAL_LINKS } from '../../../../enum';

const AchCustomerVerificationSubmittedModal = props => {
  const { t, visible } = props;
  return (
    <GenericModal
      width={700}
      closable={false}
      visible={visible}
      title={t('achCustomVerification')}
      footer={
        <Row justify="center" align="middle">
          <Col>
            <Link to={INTERNAL_LINKS.ACH_CONFIG}>
              <Button>{t('backToHome')}</Button>
            </Link>
          </Col>
        </Row>
      }
    >
      <Row justify="center">
        <AnimatedCheckmark width={100} height={100} />
      </Row>

      <br />

      <Text variant="b" renderAs="p" textAlign="center">
        {t('submittedForReview')}
      </Text>

      <br />

      <Text textAlign="center">{t('dwollaCustomerVerificationTakesDays')}</Text>
      <Text textAlign="center">
        <Trans
          t={t}
          i18nKey="contactKliksSupportIfQuestions"
          components={[
            <LinkText renderAs="a" href="mailto:support@kliks.io">
              Dummy
            </LinkText>,
          ]}
        />
      </Text>
    </GenericModal>
  );
};

export default AchCustomerVerificationSubmittedModal;
