import React from 'react';

const AnimatedMailSent = () => {
  return (
    <lottie-player
      src="https://assets2.lottiefiles.com/packages/lf20_kw6w2x7a.json"
      background="transparent"
      speed="1"
      autoplay
      style={{
        width: '200px',
        height: '180px',
        margin: '0px auto 20px auto',
      }}
    ></lottie-player>
  );
};

export default AnimatedMailSent;
