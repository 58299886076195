import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../api/axiosInstance';
import {
  exportBillingInformationUsersCSV,
  fetchBillingInformationUsers,
} from '../api/billing-informations';
import Toast from '../components/Toast';
import { emitFetchInvoices } from '../stores/actions/invoices';
import { parseQueryParams } from '../utils/queryParams';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';
import useTablePagination from './useTablePagination';

const useInvoiceBilledUsers = ({ t, match, location }) => {
  const dispatch = useDispatch();
  const { listByYear } = useSelector(store => store.invoices);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const { year } = parseQueryParams(location.search);

  const [isLoadingBillingInformation, setIsLoadingBillingInformation] = useState(false);
  const [isExportingToCSV, setIsExportingToCSV] = useState(false);

  const [billedUsers, setBilledUsers] = useState([]);

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  const invoice = useMemo(() => {
    const invoiceList = listByYear[year];
    if (Array.isArray(invoiceList)) {
      const item = invoiceList.find(i => i.billingInformationId === match.params.id);
      if (item) return item;
    }

    return undefined;
    // eslint-disable-next-line
  }, [listByYear[year], match.params.id]);

  const loadBillingInformation = async (currentPage, pageSize) => {
    setIsLoadingBillingInformation(true);

    try {
      const response = await fetchBillingInformationUsers(match.params.id, currentPage, pageSize);
      setBilledUsers(response.users);
      handleTotalUpdate(response.totalCount);
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsLoadingBillingInformation(false);
  };

  const handleExportUsersToCSV = async () => {
    setIsExportingToCSV(true);

    try {
      await exportBillingInformationUsersCSV(match.params.id, invoice.number);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('exportBilledUsersError'),
        });
      });
    }

    setIsExportingToCSV(false);
  };

  useEffect(() => {
    if (!listByYear[year] && year) {
      dispatch(emitFetchInvoices(currentCompany._id, year));
    }
    // eslint-disable-next-line
  }, [listByYear]);

  useEffect(() => {
    if (invoice) {
      loadBillingInformation(paginationConfig.current, paginationConfig.pageSize);
    }
    // eslint-disable-next-line
  }, [invoice, paginationConfig.current, paginationConfig.pageSize]);

  return {
    isExportingToCSV,
    isLoadingBillingInformation,

    invoice,
    billedUsers,
    paginationConfig,

    handlePageChange,
    handlePageSizeChange,
    handleExportUsersToCSV,
  };
};

export default useInvoiceBilledUsers;
