import { Col, Row } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';

import { HelpIcon } from '../../../../components';
import FavrFixedRateSummary from '../../../../components/FAVR/FavrFixedRateSummary';
import FavrVariableRateSummary from '../../../../components/FAVR/FavrVariableRateSummary';
import Text from '../../../../components/Text';
import LinkText from '../../../../components/Text/LinkText';
import { EXTERNAL_LINKS, IMAGES } from '../../../../enum';
import { momentFormat } from '../../../../utils/common';
// import { INTERNAL_LINKS } from '../../../../enum';

const CompanyStandardAutomobileSection = props => {
  const { t, favrInformation, onViewFavrHistory } = props;
  // const location = useLocation();

  return (
    <Row gutter={16} justify="space-between">
      <Col flex={1}>
        <Text variant="h5">Your Company's Standard Automobile</Text>

        <br />

        <Row gutter={[16, 16]} justify="start">
          <Col flex="230px">
            <Text variant="strong">
              {capitalize(favrInformation?.standardVehicle?.make || '')}{' '}
              {capitalize(favrInformation?.standardVehicle?.model || '')}
            </Text>
            <br />
            <img width="140px" alt="vehicle" src={IMAGES.CAR_CLOUD_ICON} />
          </Col>

          <Col flex="420px">
            <Row gutter={16} justify="space-between">
              <Col flex="200px">
                <Row align="middle" gutter={[5, 5]} wrap={false}>
                  <Col>
                    <Text variant="strong">{t('fixedRate')}</Text>
                  </Col>
                  {favrInformation?.fixedRateCreated && (
                    <Col>
                      <HelpIcon
                        minWidth="150px"
                        placement="bottom"
                        hint={t('setOn__date', {
                          date: momentFormat(favrInformation.fixedRateCreated, 'MMM DD, YYYY'),
                        })}
                      />
                    </Col>
                  )}
                </Row>

                <FavrFixedRateSummary
                  t={t}
                  currency={favrInformation?.fixedRateCurrency}
                  fixedRate={favrInformation?.fixedRate}
                />
              </Col>

              <Col flex="200px">
                <Row align="middle" gutter={[5, 5]} wrap={false}>
                  <Col>
                    <Text variant="strong">{t('variableRate')}</Text>
                  </Col>
                  {favrInformation?.variableRateCreated && (
                    <Col>
                      <HelpIcon
                        minWidth="150px"
                        placement="bottom"
                        hint={t('setOn__date', {
                          date: momentFormat(favrInformation.variableRateCreated, 'MMM DD, YYYY'),
                        })}
                      />
                    </Col>
                  )}
                </Row>

                <FavrVariableRateSummary
                  t={t}
                  currency={favrInformation?.variableRateCurrency}
                  variableRate={favrInformation?.variableRate}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Text>
              {t('thisCanBeDifferentFromDrivenVehicle')}{' '}
              <LinkText
                renderAs="a"
                target="_blank"
                rel="noopener noreferrer"
                href={EXTERNAL_LINKS.FRESHDESK_FAQS_DIFFERENT_FAVR_STANDARD_VEHICLE}
              >
                {t('why?')}
              </LinkText>
            </Text>
          </Col>

          <Col>
            <LinkText onClick={onViewFavrHistory}>{t('viewHistoricalRates')}</LinkText>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CompanyStandardAutomobileSection;
