import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { emitFetchContacts } from '../../stores/actions/realtor';
import { useStoreSelector } from '../../utils/storeSelectors';

const useClientList = () => {
  const dispatch = useDispatch();
  const { isFetchingContacts, contacts } = useStoreSelector('realtor');

  useEffect(() => {
    dispatch(emitFetchContacts(true));
    // eslint-disable-next-line
  }, []);

  return { isFetchingContacts, contacts };
};

export default useClientList;
