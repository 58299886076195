import { Col, DatePicker, Row } from 'antd';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { IMAGES } from '../../enum';
import FormItem from '../Form/FormItem';
import classNames from './style.module.scss';

/**
 * Vacation forms
 */
const VacationForm = props => {
  const { t, vacation, onDelete, onDateChange, disabled, hasError, canDelete, canUpdate } = props;

  /**
   * Handler for the date changes on any of the Date Pickers
   *
   * @param {"startDate" | "endDate"} dateType - Is this a start or end end date
   * @param {moment} date - Moment instance of the date
   */
  const handleDateChange = (dateType, date) => {
    if (typeof onDateChange === 'function') {
      onDateChange({ ...vacation, [dateType]: date });
    }
  };

  return (
    <Row gutter={17} align="middle" style={{ width: '100%' }}>
      <Col flex="1 0 260px">
        <FormItem
          label={t('From')}
          validateStatus={hasError ? 'error' : ''}
          help={hasError ? '' : undefined}
        >
          <DatePicker
            disabled={disabled || !canUpdate}
            style={{ width: '100%' }}
            onChange={date => handleDateChange('startDate', date)}
            defaultValue={vacation.startDate ? moment(vacation.startDate) : undefined}
          />
        </FormItem>
      </Col>

      <Col flex="1 0 260px">
        <FormItem
          label={t('To')}
          validateStatus={hasError ? 'error' : ''}
          help={hasError ? t('startDateCannotBeAfterEndDate') : undefined}
        >
          <DatePicker
            disabled={disabled || !canUpdate}
            style={{ width: '100%' }}
            onChange={date => handleDateChange('endDate', date)}
            defaultValue={vacation.endDate ? moment(vacation.endDate) : undefined}
          />
        </FormItem>
      </Col>

      {canDelete && (
        <Col>
          <img
            alt="Trash"
            src={IMAGES.TRASH_ICON}
            onClick={!disabled ? onDelete : undefined}
            className={classNames.trashIcon}
          />
        </Col>
      )}
    </Row>
  );
};

VacationForm.propTypes = {
  t: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default VacationForm;
