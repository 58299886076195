import { Col, Form, Row } from 'antd';
import React from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import SpaceSpinner from '../../SpaceSpinner';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import TextInput from '../../TextInput';

const FreeTrialSettingsForm = props => {
  const { t, isSubmitting, initialValues, onSubmit } = props;

  const [form] = Form.useForm();

  const handleCancel = () => {
    if (initialValues) {
      const fieldsToReset = Object.keys(initialValues).map(key => ({
        name: key,
        value: initialValues[key],
      }));

      form.setFields(fieldsToReset);
    }
  };

  if (!initialValues) {
    return <SpaceSpinner />;
  }

  return (
    <Form form={form} labelCol={{ span: 24 }} initialValues={initialValues} onFinish={onSubmit}>
      <Row gutter={16} align="middle">
        <Col>
          <Form.Item
            required
            name="freeTrialDays"
            label={t('freeTrialNumberDays')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput min="0" step="any" type="number" disabled={isSubmitting} />
          </Form.Item>
        </Col>

        <Col>
          <SubmitCancelButtonGroup
            isForm
            loading={isSubmitting}
            submitText={t('Save')}
            onCancel={handleCancel}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FreeTrialSettingsForm;
