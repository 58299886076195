import { first } from 'lodash';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { STANDARD_VEHICLES_API } from '../../api/cars';
import PageFiltersRenderer from '../../components/shared-ui/PageFiltersRenderer';
import StandardVehiclesTable from '../../components/Table/StandardVehiclesTable';
import PageContainer from '../../containers/PageContainer';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import { formatPageTitle } from '../../utils/common';
import { replaceCurrentPageSearchQueryParams } from '../../utils/queryParams';

const StandardVehiclesView = props => {
  const { t, history, location } = props;

  const filters = useLocationSearchQueryParser(location);

  const updateFilters = useCallback(
    (newFilters = {}) => {
      replaceCurrentPageSearchQueryParams(history, newFilters);
    },
    [history],
  );

  const vehiclesYearQuery = useQuery({
    queryKey: ['fetchFavrCarYears'],
    placeholderData: [],
    queryFn: () => STANDARD_VEHICLES_API.fetchFavrCarYears(),
    onSuccess: years => {
      if (!filters.year) {
        updateFilters({ year: first(years) });
      }
    },
    onError: error => {
      handleApiErrors(error.response);
    },
  });

  const standardVehiclesQuery = useQuery({
    enabled: !!filters?.year && vehiclesYearQuery.isFetched && !vehiclesYearQuery.isFetching,
    queryKey: ['fetchAllStandardVehicles', filters?.year],
    placeholderData: [],
    queryFn: () => STANDARD_VEHICLES_API.fetchAllStandardVehicles(filters?.year),
    onError: error => {
      handleApiErrors(error.response);
    },
  });

  return (
    <PageContainer title={t('standardVehicles')}>
      <Helmet>
        <title>{formatPageTitle('Standard Vehicles')}</title>
      </Helmet>

      <PageFiltersRenderer
        t={t}
        filters={[
          {
            componentType: 'select',
            label: t('year'),
            name: 'year',
            defaultValue: filters?.year,
            value: filters?.year,
            options: vehiclesYearQuery.data.map(year => ({
              value: year,
              label: year,
            })),
            responsive: {
              xs: 24,
              md: 6,
              lg: 4,
            },
          },
        ]}
        loading={vehiclesYearQuery.isFetching || standardVehiclesQuery.isLoading}
        onFiltersChange={updateFilters}
        search={{
          defaultValue: filters.searchTerm,
        }}
      />

      <StandardVehiclesTable
        t={t}
        loading={standardVehiclesQuery.isLoading}
        dataSource={standardVehiclesQuery.data}
        showSearchInput={false}
        defaultSearchTerm={filters?.searchTerm}
      />
    </PageContainer>
  );
};

export default withNamespaces()(StandardVehiclesView);
