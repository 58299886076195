import { Col, Row } from 'antd';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { DWOLLA_API } from '../../../api/dwolla';
import { Button, ConfirmModal } from '../../../components';
import BankAccountRemovedAlert from '../../../components/Alert/BankAccountRemovedAlert';
import BankAccountSummary from '../../../components/BankAccountSummary';
import Box from '../../../components/Box';
import BankAccountFormModal from '../../../components/Modal/BankAccountFormModal';
import BankAccountVerifyFormModal from '../../../components/Modal/BankAccountVerifyFormModal';
import StatusTag from '../../../components/Tag/StatusTag';
import Text from '../../../components/Text';
import FadedText from '../../../components/Text/FadedText';
import LinkText from '../../../components/Text/LinkText';
import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import useDwollaBankAccount from '../../../hooks/useDwollaBankAccount';
import useDwollaCompanyInfo from '../../../hooks/useDwollaCompanyInfo';
import useModal from '../../../hooks/useModal';
import { saveCompany } from '../../../stores/actions/common';
import { formatPageTitle } from '../../../utils/common';
import className from './styles.module.scss';

const AchConfigView = props => {
  const { t } = props;

  const dispatch = useDispatch();
  const [isModalVisible, openModal, closeModal] = useModal();
  const [isVerificationModalVisible, openVerificationModal, closeVerificationModal] = useModal();
  const [isDeleteModalVisible, openDeleteModal, closeDeleteModal] = useModal();
  const {
    isCustomerVerified,
    dwollaPaymentInfo,
    companyOtherFundingSource,
    companyFundingSourceRemovalInfo,
  } = useDwollaCompanyInfo();
  const {
    isSavingBankAccount,
    isDeletingBankAccount,
    isVerifyingMicroTransaction,
    saveCompanyBankAccount,
    deleteCompanyBankAccount,
    verifyBankAccountMicroTransaction,
  } = useDwollaBankAccount(t, {
    onSaveBankAccountSuccess: closeModal,
    onDeleteBankAccountSuccess: closeDeleteModal,
    onVerifyBankAccountMicroTransactionSuccess: closeVerificationModal,
  });

  useEffect(() => {
    if (
      companyOtherFundingSource &&
      companyOtherFundingSource.microDepositStatus === STATUS_LIST().Status.NOT_INITIATED
    ) {
      DWOLLA_API.initiateMicroDeposit(companyOtherFundingSource.fundingSourceId).then(
        updatedCompany => {
          dispatch(saveCompany({ dwollaPaymentInfo: updatedCompany.dwollaPaymentInfo }));
        },
      );
    }
  }, [dispatch, companyOtherFundingSource]);

  const handleBankAccountClick = () => {
    if (
      [STATUS_LIST().Status.PENDING, STATUS_LIST().Status.PROCESSED].includes(
        companyOtherFundingSource?.microDepositStatus,
      )
    ) {
      openVerificationModal();
    } else if (isCustomerVerified) {
      openModal();
    }
  };

  return (
    <div>
      <Helmet>
        <title>{formatPageTitle('ACH Config')}</title>
      </Helmet>

      {companyFundingSourceRemovalInfo && (
        <BankAccountRemovedAlert t={t} removalReason={companyFundingSourceRemovalInfo} />
      )}

      <Row gutter={[16, 16]}>
        <Col flex="100%">
          <Box className={classnames(className.clickableCard)}>
            <Row wrap={false} align="middle" gutter={16}>
              <Col flex={1}>
                <Link to={INTERNAL_LINKS.ACH_CUSTOMER_VERIFICATION}>
                  <LinkText variant="b">{t('achCustomVerification')}</LinkText>
                </Link>
              </Col>
              <Col>
                <StatusTag
                  status={
                    dwollaPaymentInfo?.controllerInfo?.needCertification
                      ? STATUS_LIST().Status.BENEFICIAL_REQUIRES_CERTIFICATION
                      : dwollaPaymentInfo?.controllerInfo?.customerStatus ||
                        STATUS_LIST().Status.NOT_STARTED
                  }
                />
              </Col>
            </Row>
          </Box>
        </Col>

        <Col flex="100%">
          <Box
            variant={isCustomerVerified ? undefined : 'gray'}
            className={classnames({ [className.cardDisabled]: !isCustomerVerified })}
          >
            <Row wrap={false} align="middle" gutter={16}>
              <Col flex={1}>
                {isCustomerVerified ? (
                  <LinkText variant="b" onClick={handleBankAccountClick}>
                    {t('bankAccountInformation')}
                  </LinkText>
                ) : (
                  <FadedText variant="b">{t('bankAccountInformation')}</FadedText>
                )}
              </Col>

              {isCustomerVerified && companyOtherFundingSource?.fundingSourceId && (
                <Col>
                  <Button size="sm" type="secondary" onClick={openDeleteModal}>
                    {t('deleteBankAccount')}
                  </Button>
                </Col>
              )}

              {isCustomerVerified &&
                ![STATUS_LIST().Status.PENDING, STATUS_LIST().Status.PROCESSED].includes(
                  companyOtherFundingSource?.microDepositStatus,
                ) && (
                  <Col>
                    <Button size="sm" onClick={handleBankAccountClick}>
                      {t(
                        companyOtherFundingSource?.fundingSourceStatus
                          ? 'editBankAccount'
                          : 'addBankAccount',
                      )}
                    </Button>
                  </Col>
                )}

              {[STATUS_LIST().Status.PENDING, STATUS_LIST().Status.PROCESSED].includes(
                companyOtherFundingSource?.microDepositStatus,
              ) && (
                <Col>
                  <Button size="sm" onClick={openVerificationModal}>
                    {t('verify')}
                  </Button>
                </Col>
              )}

              <Col>
                <StatusTag
                  status={
                    [STATUS_LIST().Status.PENDING, STATUS_LIST().Status.PROCESSED].includes(
                      companyOtherFundingSource?.microDepositStatus,
                    )
                      ? STATUS_LIST().Status.PENDING_VERIFICATION
                      : companyOtherFundingSource?.fundingSourceStatus ||
                        STATUS_LIST().Status.NOT_STARTED
                  }
                />
              </Col>
            </Row>

            {companyOtherFundingSource && (
              <BankAccountSummary
                t={t}
                style={{ marginTop: 30 }}
                fundingSource={companyOtherFundingSource}
                showStatus={false}
              />
            )}
          </Box>
        </Col>
      </Row>

      <BankAccountFormModal
        t={t}
        visible={isModalVisible}
        onCancel={closeModal}
        fundingSourceId={companyOtherFundingSource?.fundingSourceId}
        isSourceVerified={
          companyOtherFundingSource?.fundingSourceStatus === STATUS_LIST().Status.VERIFIED ||
          [
            STATUS_LIST().Status.PENDING,
            STATUS_LIST().Status.PROCESSED,
            STATUS_LIST().Status.VERIFIED,
          ].includes(companyOtherFundingSource?.microDepositStatus)
        }
        initialValues={companyOtherFundingSource?.accountDetails}
        isSubmitting={isSavingBankAccount}
        onSubmit={saveCompanyBankAccount}
      />

      <BankAccountVerifyFormModal
        t={t}
        visible={isVerificationModalVisible}
        onCancel={closeVerificationModal}
        fundingSourceId={companyOtherFundingSource?.fundingSourceId}
        isSubmitting={isVerifyingMicroTransaction}
        onSubmit={verifyBankAccountMicroTransaction}
      />

      <ConfirmModal
        loading={isDeletingBankAccount}
        visible={isDeleteModalVisible}
        message={<Text variant="b">{t('deleteBankAccount?')}</Text>}
        onCancel={closeDeleteModal}
        cancelText={t('No')}
        onOk={() => deleteCompanyBankAccount(companyOtherFundingSource?.fundingSourceId)}
        okText={t('Yes')}
      />
    </div>
  );
};

export default withNamespaces()(AchConfigView);
