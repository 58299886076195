import React from 'react';

import PlanCard from '../../views/price-plans/PlanCard';
import classNames from './style.module.scss';

const PlanListSection = props => {
  const { selectedPlan, plans, disabled, onCardClick } = props;

  return (
    <div className={classNames.pricePlansPriceForm}>
      <div>
        <div className={classNames.priceFormPlans}>
          {plans.map((p, index) => (
            <PlanCard
              data={p}
              disabled={disabled}
              onClick={() => onCardClick(p.order)}
              key={'price-plan-' + index}
              selected={p.order === selectedPlan}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

PlanListSection.defaultProps = {
  plans: [],
  selectedPlan: undefined,
  isPlanCancelled: false,
};

export default PlanListSection;
