import { Col, Row } from 'antd';
import React from 'react';

import { HelpIcon } from '../../../components';
import Switch from '../../../components/Switch';
import Text from '../../../components/Text';
import FadedText from '../../../components/Text/FadedText';
import className from './styles.module.scss';

const FeatureFormItem = props => {
  const { label, helpText, value, onChange, disabled, style } = props;

  const HELP_CONTENT = (
    <Col flex="40px">
      {helpText && (
        <div style={{ marginLeft: 10 }}>
          <HelpIcon
            hint={
              <Text size="sm" style={{ width: 250 }}>
                {helpText}
              </Text>
            }
          />
        </div>
      )}
    </Col>
  );

  return (
    <Row
      gutter={[10, 6]}
      wrap={false}
      align="middle"
      style={{ ...style, width: '100%', minHeight: 26 }}
    >
      <Col flex={1}>
        <FadedText size="xs" className={className.featureFormItemText}>
          {label}
        </FadedText>
      </Col>

      <Col>
        <Switch disabled={disabled} checked={value} onChange={onChange} />
      </Col>

      {HELP_CONTENT}
    </Row>
  );
};

FeatureFormItem.defaultProps = {
  style: {},
};

export default FeatureFormItem;
