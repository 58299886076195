import React, { useMemo } from 'react';

import AmountText from './AmountText';

const AmountTextReverseColors = props => {
  const { amount, ...rest } = props;

  const cleanAmount = useMemo(() => {
    return parseFloat(`${amount}`.replace(/[^\d.-]/g, ''));
  }, [amount]);

  return <AmountText amount={amount} color={cleanAmount > 0 ? 'red' : 'primary'} {...rest} />;
};

export default AmountTextReverseColors;
