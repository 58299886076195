import './style.scss';

import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Popover from '../Popover';

const ICONS = {
  info: ({ size }) => (
    <InfoCircleOutlined style={{ fontSize: size === 'small' ? 13 : undefined }} />
  ),
  question: ({ size }) => (
    <QuestionCircleOutlined style={{ fontSize: size === 'small' ? 13 : undefined }} />
  ),
};

const HelpIcon = props => {
  const { className, placement, hint, size, variant, width, minWidth, style } = props;

  return (
    <div className={`help-icon ${className}`}>
      <Popover
        content={
          <div className="help-icon-hint" style={merge({ maxWidth: width, minWidth }, style)}>
            {typeof hint === 'string' ? <p>{hint}</p> : hint}
          </div>
        }
        title={null}
        placement={placement}
      >
        {ICONS[variant]({ size })}
      </Popover>
    </div>
  );
};

HelpIcon.propTypes = {
  className: PropTypes.string,
  hint: PropTypes.node,
  placement: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.oneOf(['question', 'info']),
};

HelpIcon.defaultProps = {
  hint: '',
  className: '',
  placement: 'right',
  size: 'medium',
  variant: 'question',
};

export default HelpIcon;
