import { Col, Row } from 'antd';
import { capitalize, get } from 'lodash';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, getUserFullName, momentFormat } from '../../../utils/common';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import { hasTripsWithDeductedCommuteMiles } from '../../../utils/trips';
import { canDeleteReceipt } from '../../../views/receipt/receipt-permissions';
import { Button, CustomTable } from '../../index';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import LinkText from '../../Text/LinkText';
import Tooltip from '../../Tooltip';

/**
 * A Table to display the Receipts data
 */
const ReceiptsTable = props => {
  const { t, dataSource, onDeleteReceipt, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(receipt => {
        return (
          checkIfStringContainsValue(receipt.seqId, searchTerm) ||
          checkIfStringContainsValue(getUserFullName(receipt.submittedBy), searchTerm) ||
          checkIfStringContainsValue(receipt.type, searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      width: 50,
      title: t('ID'),
      defaultSortOrder: 'descend',
      dataIndex: 'seqId',
      key: 'seqId',
      render: (actions, data) => (
        <Link
          to={{
            pathname: `${INTERNAL_LINKS.RECEIPTS}/${data._id}`,
            state: { goReceiptsQueryParams: window.location.search },
          }}
        >
          <LinkText variant="strong" size="sm">
            {data.seqId || 'Receipt'}
          </LinkText>
        </Link>
      ),
      sorter: true,
    },
    {
      width: 125,
      title: t('name'),
      key: ['submittedBy.lastName', 'submittedBy.firstName'].toString(),
      sorter: true,
      render: (actions, data) => (
        <ProfileNamePicture
          t={t}
          user={data.submittedBy}
          complianceStatus={get(data, 'complianceStatus')}
        />
      ),
    },
    {
      width: 75,
      title: t('rbId'),
      key: 'reimbursementId',
      dataIndex: 'reimbursementId',
      sorter: true,
    },
    {
      width: 65,
      title: t('date'),
      dataIndex: 'created',
      key: 'created',
      render: (actions, data) => momentFormat(data.created, 'MM/DD/YYYY'),
      sorter: true,
    },
    {
      width: 100,
      align: 'right',
      title: t('totalDistance'),
      dataIndex: 'totalDistance',
      key: 'totalDistance',
      sorter: true,
    },
    {
      width: 100,
      align: 'right',
      title: t('totalAmount'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: true,
      render: (actions, data) => {
        const adjustment = data.adjustment ? data.adjustment.amount : 0;

        return formatNumberWithCurrency(
          parseFloat(adjustment) + parseFloat(data.totalAmount),
          data.currency,
          2,
        );
      },
    },
    {
      width: 90,
      align: 'right',
      title: t('gpsVerified'),
      dataIndex: 'gpsPercent',
      key: 'gpsPercent',
      render: (actions, data) => `${typeof data.gpsPercent === 'number' ? data.gpsPercent : 0}%`,
      sorter: true,
    },
    {
      width: 140,
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (actions, data) => (
        <StatusTag
          status={
            data.status === STATUS_LIST().Status.PENDING
              ? STATUS_LIST().Status.AWAITING_APPROVAL
              : data.status
          }
        />
      ),
      sorter: true,
    },
    {
      width: 100,
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      render: type => capitalize(type),
      sorter: true,
    },
    {
      width: 100,
      title: '',
      render: receipt => {
        return (
          <Row>
            {typeof onDeleteReceipt === 'function' && (
              <Col>
                <Tooltip
                  placement="left"
                  enabled={!canDeleteReceipt(receipt)}
                  title={t('onlyFixedReceiptCanBeDeleted')}
                >
                  <Button
                    size="sm"
                    type="secondary"
                    disabled={!canDeleteReceipt(receipt)}
                    onClick={() => onDeleteReceipt(get(receipt, '_id'))}
                  >
                    {t('Delete')}
                  </Button>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <CustomTable
      onSearchTermChange={setSearchTerm}
      {...rest}
      onRow={row => ({
        leftBorderColor: hasTripsWithDeductedCommuteMiles(row.trips) ? 'danger' : '',
      })}
      dataSource={filteredDataSource}
      rowKey={data => data._id}
      columns={COLUMNS}
      scroll={{ x: 970 }}
    />
  );
};

export default ReceiptsTable;
