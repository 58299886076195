export default {
  FRESHDESK_FAQS: 'https://kliks.freshdesk.com/support/solutions',
  FRESHDESK_LIMIT_PERCENT_ON_FAVR_RATE:
    'https://kliks.freshdesk.com/a/solutions/articles/71000003526',
  FRESHDESK_FAQS_CRM_MATCH: 'https://kliks.freshdesk.com/support/solutions/articles/71000001986',
  FRESHDESK_FAQS_TRIP_STATUS: 'https://kliks.freshdesk.com/support/solutions/articles/71000000482',
  FRESHDESK_FAQS_DIFFERENT_FAVR_STANDARD_VEHICLE:
    'https://kliks.freshdesk.com/a/solutions/articles/71000003216',
  FRESHDESK_FAQS_STOP_DURATION_CALCULATION:
    'https://kliks.freshdesk.com/support/solutions/articles/71000002287',
  FRESHDESK_FAQS_TRIP_CSV_IMPORT_VALIDAITON_RULES:
    'https://kliks.freshdesk.com/a/solutions/articles/71000002781',
  FRESHDESK_SUPPORT: 'https://kliks.freshdesk.com/support/solutions',
  FRESHDESK_NEW_TICKET: 'https://kliks.freshdesk.com/support/tickets/new',
  FRESHDESK_ANDROID_DEVICE_SETTINGS:
    'https://kliks.freshdesk.com/support/solutions/articles/71000002289-which-android-settings-are-needed-for-kliks-to-automatically-record-my-trips',
  FRESHDESK_IOS_DEVICE_SETTINGS:
    'https://kliks.freshdesk.com/support/solutions/articles/71000002435-which-iphone-settings-are-needed-for-kliks-to-automatically-record-my-trips',
  KLIKS_APP_STORE:
    'https://apps.apple.com/us/app/kliksio/id1542561197?itsct=apps_box_badge&amp;itscg=30200',
  KLIKS_PLAY_STORE:
    'https://play.google.com/store/apps/details?id=com.app.viantr.dev&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
  KLIKS_PRIVACY_POLICY: 'https://www.kliks.io/kliks_privacy_policy_v1',
  KLIKS_TERMS_OF_SERVICE: 'https://www.kliks.io/kliks_terms_service_v1',
  DWOLLA_PRIVACY_POLICY: 'https://www.dwolla.com/legal/privacy/',
  DWOLLA_TERMS_OF_SERVICE: 'https://www.dwolla.com/legal/tos/',
  US_HOLIDAYS: 'https://www.federalreserve.gov/aboutthefed/k8.htm',
};
