import './styles.scss';

import { Alert } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Banner component based on the Ant Design Alert
 * but following Kliks style guide and branding
 *
 * @param {object} props - Component props
 */
const Banner = props => {
  const {
    color,
    title,
    description,
    onClose,
    onActionClick,
    actionText,
    className,
    ...rest
  } = props;

  const alertClassNames = ['custom-alert', `alert-${color}`, className].filter(Boolean).join(' ');

  const hasCallbackAction = typeof onActionClick === 'function';

  return (
    <Alert
      {...rest}
      closable
      onClose={onClose}
      closeText={<img width="12px" alt="alert close" src="/images/icons/close.svg" />}
      message={title}
      description={
        (description || hasCallbackAction) && (
          <div>
            {description && <p className="alert-description-text">{description}</p>}
            {hasCallbackAction && (
              <button className="alert-action" onClick={onActionClick}>
                {actionText}
              </button>
            )}
          </div>
        )
      }
      className={alertClassNames}
      banner={false}
      icon={null}
    />
  );
};

Banner.propTypes = {
  title: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['white', 'green', 'gray', 'red']),
  actionText: PropTypes.string,
  onActionClick: PropTypes.func,
  description: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Banner.defaultProps = {
  onClose: () => undefined,
  onActionClick: undefined,
  actionText: 'OK',
  color: 'gray',
  description: '',
  className: '',
};

export default Banner;
