import { handleApiFileDownloads } from './axiosInstance';
import BaseAPI from './baseAPI';

class FAVRTaxReportsAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'favr-tax-reports' });
  }
  /**
   * @param {string} taxId
   * @param {"annual" | "monthly"} type
   */
  async exportTaxReport(taxId, type) {
    await handleApiFileDownloads('get', `${this.baseUrl}/${taxId}/csv`, {
      type,
    });
  }
}

export default FAVRTaxReportsAPI;
