import { Col, DatePicker, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import { Button } from '../../components';
import InvoiceTable from '../../components/Table/InvoiceTable';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import useInvoices from '../../hooks/useInvoices';
import { formatPageTitle } from '../../utils/common';

const InvoicesView = props => {
  const { t } = props;

  const {
    isLoadingInvoices,

    invoices,
    selectedYear,

    loadInvoices,
    handleYearChange,
  } = useInvoices();

  return (
    <PageContainer title={'Invoices'}>
      <Helmet>
        <title>{formatPageTitle('Invoices')}</title>
      </Helmet>

      <Row align="middle" gutter={16} wrap={false}>
        <Col>
          <DatePicker
            picker="year"
            allowClear={false}
            defaultValue={selectedYear}
            disabled={isLoadingInvoices}
            onChange={handleYearChange}
          />
        </Col>
        <Col>
          <Button
            loading={isLoadingInvoices}
            text="Search"
            onClick={() => loadInvoices(selectedYear.year())}
          />
        </Col>
      </Row>

      <InvoiceTable
        t={t}
        selectedYear={selectedYear.year()}
        loading={isLoadingInvoices}
        dataSource={invoices}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(InvoicesView));
