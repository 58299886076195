import { Col, Row } from 'antd';
import React from 'react';

import Checkbox from '../../../../components/Checkbox';
import RowButton from '../../../../components/RowButton';
import Text from '../../../../components/Text';
import LinkText from '../../../../components/Text/LinkText';
import { IMAGES } from '../../../../enum';

const AnonymizeDataOptions = props => {
  const {
    t,
    isAdmin,
    disabled,
    tripChecked,
    reimbursementChecked,
    receiptsChecked,
    profileChecked,
    onTripsClick,
    onReceiptClick,
    onReimbursmentClick,
    onProfileClick,
    onSelectAllData,
    showRemoveAccount,
  } = props;

  const ROW_TEXT_PROPS = { size: 'sm' };

  const IS_ANY_SELECTED =
    !tripChecked || !receiptsChecked || !reimbursementChecked || !profileChecked;

  return (
    <>
      {!disabled && (
        <Row justify="space-between" gutter={[0, 10]}>
          <Col>
            {typeof onSelectAllData === 'function' && (
              <LinkText onClick={onSelectAllData}>
                {isAdmin ? t('allData') : t('allMyData')}
              </LinkText>
            )}
          </Col>

          <Col>
            <LinkText
              onClick={() => {
                onTripsClick(IS_ANY_SELECTED);
                onReceiptClick(IS_ANY_SELECTED);
                onReimbursmentClick(IS_ANY_SELECTED);
                onProfileClick(IS_ANY_SELECTED);
              }}
            >
              {IS_ANY_SELECTED ? t('selectAll') : t('deselectAll')}
            </LinkText>
          </Col>
        </Row>
      )}
      <RowButton
        disabled={disabled}
        onClick={() => onTripsClick(!tripChecked)}
        icon={IMAGES.TRIP_PINS_ICON}
        title={t('Trips')}
        content={
          <>
            <Text {...ROW_TEXT_PROPS}>{t('Trips')}</Text>
            <Text {...ROW_TEXT_PROPS}>{t('tripsRecordedAdded')}</Text>
          </>
        }
        sideContent={<Checkbox disabled={disabled} checked={tripChecked} />}
      />
      <RowButton
        disabled={disabled}
        onClick={() => onReceiptClick(!receiptsChecked)}
        icon={IMAGES.RECEIPT_DOLLAR_ICON}
        title={t('Receipts')}
        content={
          <>
            <Text {...ROW_TEXT_PROPS}>{t('Receipts')}</Text>
            <Text {...ROW_TEXT_PROPS}>{t('tripReceiptSubmitReimbursement')}</Text>
          </>
        }
        sideContent={<Checkbox disabled={disabled} checked={receiptsChecked} />}
      />
      <RowButton
        disabled={disabled}
        onClick={() => onReimbursmentClick(!reimbursementChecked)}
        icon={IMAGES.CALENDAR_CASH_ICON}
        title={t('Reimbursements')}
        content={
          <>
            <Text {...ROW_TEXT_PROPS}>{t('Reimbursements')}</Text>
            <Text {...ROW_TEXT_PROPS}>{t('reimbursementPaidToYou')}</Text>
          </>
        }
        sideContent={<Checkbox disabled={disabled} checked={reimbursementChecked} />}
      />
      <RowButton
        disabled={disabled}
        onClick={() => onProfileClick(!profileChecked)}
        icon={IMAGES.USER_SINGLE_ICON}
        title={t('profileInformation')}
        content={
          <>
            <Text {...ROW_TEXT_PROPS}>{t('profileInformation')}</Text>
            <Text {...ROW_TEXT_PROPS}>{t('contactInfoAddress')}</Text>
          </>
        }
        sideContent={<Checkbox disabled={disabled} checked={profileChecked} />}
      />
      {showRemoveAccount && (
        <RowButton
          disabled
          icon={IMAGES.USER_SINGLE_ICON}
          title={t('removeAccount')}
          sideContent={<Checkbox disabled checked />}
          content={
            <>
              <Text {...ROW_TEXT_PROPS}>{t('removeAccount')}</Text>
              <Text {...ROW_TEXT_PROPS}>{t('requestRemoveAccountCompletely')}</Text>
            </>
          }
        />
      )}
    </>
  );
};

AnonymizeDataOptions.defaultProps = {
  showRemoveAccount: false,
};

export default AnonymizeDataOptions;
