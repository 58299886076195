import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { handleApiErrors } from '../../api/axiosInstance';
import { deleteRate } from '../../api/rates';
import { Button } from '../../components';
import RateDeleteConfirmationModal from '../../components/Modal/RateDeleteConfirmationModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import RateFormWizard from '../../components/RateFormWizard';
import SpaceSpinner from '../../components/SpaceSpinner';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import PageContainerWithFadedLeftNavigation from '../../containers/PageContainerWithFadedLeftNavigation';
import { INTERNAL_LINKS } from '../../enum';
import useDefaultRate from '../../hooks/queries/useDefaultRate';
import useUsersInRateQuery from '../../hooks/queries/useUsersInRateQuery';
import useModal from '../../hooks/useModal';
import { emitResetRateWizard, emitSetupRateWizard } from '../../stores/actions/rateWizard';
import { formatPageTitle } from '../../utils/common';
import { selectStoreCompanyGroupByID, useStoreSelector } from '../../utils/storeSelectors';
import UsersAssociatedToThisRateView from '../productsettings/productDetails/UsersAssociatedToThisRateView';

const RateDetailsView = props => {
  const { t, match, history } = props;

  const dispatch = useDispatch();

  const [isDeletingRate, setIsDeletingRate] = useState(false);

  const [
    isDeleteConfirmationVisible,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
  ] = useModal();
  const rateWizard = useStoreSelector('rateWizard');
  const defaultRateQuery = useDefaultRate();

  useEffect(() => {
    dispatch(emitSetupRateWizard(match.params.id));

    return () => {
      dispatch(emitResetRateWizard());
    };
  }, [dispatch, match.params.id]);

  const handleRateDelete = useCallback(async () => {
    setIsDeletingRate(true);

    try {
      await deleteRate(match.params.id);

      closeDeleteConfirmationModal();
      setIsDeletingRate(false);

      Toast({
        type: 'info',
        message: t('deleteRateSuccess'),
      });

      history.push(INTERNAL_LINKS.RATES);
    } catch (error) {
      setIsDeletingRate(false);

      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('deleteRateError'),
        });
      });
    }
  }, [t, match.params.id, closeDeleteConfirmationModal, history]);

  const { usersInRateQuery } = useUsersInRateQuery(
    match.params.id,
    rateWizard.rateValues.rateType,
    rateWizard.initialValues.groups,
  );

  const fullGroupsList = useMemo(() => {
    const groupList = [];
    if (Array.isArray(rateWizard.rateValues.groups)) {
      rateWizard.rateValues.groups.forEach(groupId => {
        groupList.push(selectStoreCompanyGroupByID(groupId));
      });
    }

    return groupList;
  }, [rateWizard.rateValues.groups]);

  if (rateWizard.isSettingUpWizard || defaultRateQuery.isFetching) {
    return <SpaceSpinner />;
  }

  return (
    <PageContainerWithFadedLeftNavigation
      orientation="vertical"
      fullWidthPage
      title={
        <PageBreadcrumbs
          items={[
            {
              label: 'Rates',
              onClick: () => history.push(INTERNAL_LINKS.RATES),
            },
            { label: rateWizard.rateValues.name },
          ]}
        />
      }
      navRoutes={[
        {
          to: `${INTERNAL_LINKS.RATE_DETAILS}/${match.params.id}`,
          label: t('rateInfo'),
        },
        {
          to: `${INTERNAL_LINKS.RATE_DETAILS}/${match.params.id}/users`,
          label: t('usersOfThisRate_withCount', {
            count: usersInRateQuery?.data?.count || 0,
          }),
        },
      ]}
      sideActionComponent={
        rateWizard.originalRateDetails._id && (
          <Button
            disabled={rateWizard.rateValues.defaultRate}
            onClick={() => {
              if (rateWizard.originalRateDetails.defaultRate) {
                Toast({
                  type: 'error',
                  message: t('defaultRateDeleteError'),
                });
              } else {
                openDeleteConfirmationModal();
              }
            }}
          >
            {t('deleteRate')}
          </Button>
        )
      }
    >
      <Helmet>
        <title>{formatPageTitle('Rate Details')}</title>
      </Helmet>

      <Switch>
        <Route
          exact
          path={`${INTERNAL_LINKS.RATE_DETAILS}/:id/users`}
          component={UsersAssociatedToThisRateView}
        />

        <Route
          exact
          path={`${INTERNAL_LINKS.RATE_DETAILS}/:id?`}
          render={() => (
            <PageContainer noSurroundingSpace pageActionText="">
              <RateFormWizard t={t} match={match} history={history} />
            </PageContainer>
          )}
        />
      </Switch>

      <RateDeleteConfirmationModal
        t={t}
        isDeletingRate={isDeletingRate}
        visible={isDeleteConfirmationVisible || isDeletingRate}
        affectedGroups={rateWizard.rateValues.name ? fullGroupsList : []}
        defaultRate={defaultRateQuery.data}
        onCancel={() => {
          if (!isDeletingRate) {
            closeDeleteConfirmationModal();
          }
        }}
        onDelete={handleRateDelete}
      />
    </PageContainerWithFadedLeftNavigation>
  );
};

export default withNamespaces()(RateDetailsView);
