import { get } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import { DWOLLA_RESOURCES_API } from '../../../api/dwolla-resources';
import { setModalFullscreenWidth } from '../../../utils/common';
import UsersWithoutBankAccountsTable from '../../Table/UsersWithoutBankAccountsTable';
import GenericModal from '../GenericModal';

const UsersWithoutBankAccountsModal = props => {
  const { t, massPaymentId, visible, onCancel } = props;

  const usersWithoutBankAccountsQuery = useQuery({
    enabled: !!massPaymentId,
    queryKey: ['fetchUsersWithoutBankAccounts', massPaymentId],
    queryFn: () => DWOLLA_RESOURCES_API.fetchUsersWithoutBankAccounts(massPaymentId),
    onError: error => handleApiErrors(error.response),
  });

  return (
    <GenericModal
      title={t('usersWithoutValidACHInfo')}
      visible={visible}
      onCancel={onCancel}
      width={setModalFullscreenWidth(900)}
      footer={null}
    >
      <UsersWithoutBankAccountsTable
        t={t}
        loading={usersWithoutBankAccountsQuery.isFetching}
        dataSource={get(usersWithoutBankAccountsQuery.data, 'usersWithoutBankAccount', [])}
      />
    </GenericModal>
  );
};

export default UsersWithoutBankAccountsModal;
