import { Col, Row } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { setModalFullscreenWidth } from '../../utils/common';
import Button from '../Button';
import Text from '../Text';
import GenericModal from './GenericModal';

const ReimbursementReceiptConfirmationWithMoreInfoTripModal = props => {
  const {
    t,
    visible,
    type,
    onCancel,
    tripsCount,
    onApproveAllTripsClick,
    onTripsAwaitingForApprovalClick,
  } = props;

  return (
    <GenericModal
      centered
      width={setModalFullscreenWidth(700)}
      title={
        type === 'receipt'
          ? t('sureYouWantToApproveReceipt')
          : t('sureYouWantToApproveReimbursement')
      }
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <Text color="secondary" variant="p" size="sm">
        <Trans
          i18nKey="thereAreNTripsWithMoreInfo"
          count={tripsCount}
          values={{ count: tripsCount }}
          components={[<b>bold</b>]}
        />
      </Text>

      <Text color="secondary" variant="p" size="sm">
        <Trans
          i18nKey="doYouWantToApproveAllTripsOrAwaitingForApproval"
          components={[<b>bold</b>]}
        />
      </Text>

      <br />

      <Text color="secondary" variant="p" size="sm">
        * {t('moreInfoTripsWillBeSentBackToEmployee')}
      </Text>

      <br />

      <Row gutter={16} justify="end">
        <Col>
          <Button type="secondary" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Col>
        <Col>
          <Button onClick={onApproveAllTripsClick}>{t('approveAllTrips')}</Button>
        </Col>
        <Col>
          <Button onClick={onTripsAwaitingForApprovalClick}>{t('tripsAwaitingForApproval')}</Button>
        </Col>
      </Row>
    </GenericModal>
  );
};

ReimbursementReceiptConfirmationWithMoreInfoTripModal.defaultProps = {
  type: 'reimbursement',
  tripsCount: 0,
};

export default ReimbursementReceiptConfirmationWithMoreInfoTripModal;
