import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { UserRoleHomePageRoute } from '../enum/UserRoles';
import { selectStoreCurrentAuthUser } from '../utils/storeSelectors';

const HomeRedirect = () => {
  const authUser = useSelector(selectStoreCurrentAuthUser);
  if (!authUser || !get(authUser, 'profile.role')) return null;

  return <Redirect to={UserRoleHomePageRoute[get(authUser, 'profile.role')]} />;
};

export default HomeRedirect;
