export const normalizeAddressSchema = schema => {
  if (!schema) return {};

  return {
    streetOne: schema.streetOne || schema.street1,
    streetTwo: schema.streetTwo || schema.street2,
    city: schema.city,
    state: schema.state,
    postalCode: schema.postalCode || schema.zipcode,
    country: schema.country,
  };
};

export const analyseAddressAutoCompleteString = addressString => {
  let [streetOne, city, stateInfo] = addressString.split(',').map(item => item.trim());
  let [state, country, postalCode] = (stateInfo || '')
    .split(/^(\w+) (\w+) (.+)/g)
    .filter(Boolean)
    .map(item => item.trim());

  switch (country) {
    case 'CAN':
    case 'Canada':
      country = 'CA';
      break;
    case 'USA':
    case 'United States':
      country = 'US';
      break;
    default:
      break;
  }

  return { streetOne, city, state, country, postalCode };
};
