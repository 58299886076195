export const INSURANCE_FIELDS = [
  'bodilyInjuryToOthers',
  'damageToProperty',
  'personalInjuryProtection',
  'bodilyInjuryByUninsuredAuto',
  'optionalBodilyInjuryToOthers',
  'medicalPayments',
  'collision',
  'comprehensive',
  'bodilyInjuryByUnderinsuredAuto',
];
