import { Col, Row } from 'antd';
import React from 'react';

import Box from '../../../components/Box';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import { IMAGES } from '../../../enum';
import { momentCompanyFormat, momentCompanyTimezone } from '../../../utils/common';
import classNames from '../styles.module.scss';

const ENOUGH_BALANCE = 'balance';
const FUNDING_DEADLINE = 'deadline';
const PAYMENT_DATE = 'payment-date';

const ReimbursementBalanceTimelineBoxItem = props => {
  const { t, timelineItem, onAddFunds } = props;

  if (!timelineItem) return null;

  const renderLock = () => <img src={IMAGES.GREEN_LOCK_ICON} alt="Lock" />;

  const renderIcon = item => {
    switch (item.type) {
      case FUNDING_DEADLINE:
      case PAYMENT_DATE:
        const daysLeft = momentCompanyTimezone(item.date).diff(momentCompanyTimezone(), 'days');
        const hoursLeft = momentCompanyTimezone(item.date).diff(momentCompanyTimezone(), 'hours');

        if (hoursLeft <= 0) return renderLock();

        return (
          <Box noShadow style={{ background: '#DAF0E0', marginTop: 5 }} padding="1px">
            <Text size="xs" variant="strong">
              {daysLeft <= 0
                ? t('hoursLeft', { count: hoursLeft })
                : t('daysLeft', { count: daysLeft })}
            </Text>
          </Box>
        );
      default:
        return null;
    }
  };

  const renderTitle = item => {
    switch (item.type) {
      case ENOUGH_BALANCE:
        return (
          <Text variant="h4" style={{ marginBottom: 5 }}>
            {t('enoughFundsforReimbursement')}
          </Text>
        );
      case FUNDING_DEADLINE:
        return (
          <Text variant="h4" style={{ marginBottom: 5 }}>
            {t('achFundingDeadline')}
          </Text>
        );
      case PAYMENT_DATE:
        return (
          <Text variant="h4" style={{ marginBottom: 5 }}>
            {t('paymentDate')}
          </Text>
        );
      default:
        return (
          <Text variant="h4" style={{ marginBottom: 5 }}>
            {t(timelineItem.type)}
          </Text>
        );
    }
  };

  const renderContent = item => {
    switch (item.type) {
      case ENOUGH_BALANCE:
        return (
          <Row>
            <Col flex={1}>
              <Row justify="center">
                <Col>
                  <img
                    width="50px"
                    height="50px"
                    alt="balance-status"
                    src={
                      item.finalBalance >= 0
                        ? IMAGES.CIRCLE_CHECK_MARK_ICON
                        : IMAGES.CIRCLE_REMOVE_ICON
                    }
                  />
                </Col>
              </Row>

              {item.finalBalance < 0 && (
                <>
                  <br />

                  <Row justify="center">
                    <LinkText variant="b" renderAs="a" textAlign="center" onClick={onAddFunds}>
                      {t('addFunds')}
                    </LinkText>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        );
      case FUNDING_DEADLINE:
      case PAYMENT_DATE:
        return (
          <>
            <Text>{momentCompanyFormat(item.date, 'MMMM DD, YYYY')}</Text>
            <Text>{momentCompanyFormat(item.date, 'hh:mm A z')}</Text>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box className={classNames.timelineBox}>
      <div className={classNames.abosoluteIcon}>{renderIcon(timelineItem)}</div>

      {renderTitle(timelineItem)}

      <div className={classNames.timelineBoxContent}>{renderContent(timelineItem)}</div>
    </Box>
  );
};

export default ReimbursementBalanceTimelineBoxItem;
