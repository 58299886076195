import _, { get } from 'lodash';
import queryString from 'query-string';

import { formatQueryRange, momentTimezone } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls, performApiCallIfCompanySubIsActiveOrCompanyAdmin } from './axiosInstance';

export const fetchPaymentScheduleDetails = async paymentScheduleID => {
  const url = `${process.env.REACT_APP_HOST_API}payment-schedules/${paymentScheduleID}`;
  const response = await handleApiCalls('get', url);

  return response?.data;
};

export const createPaymentScheduleDetails = async data => {
  const url = `${process.env.REACT_APP_HOST_API}payment-schedules`;
  const response = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('post', url, data);

  return response?.data;
};

export const updatePaymentScheduleDetails = async (paymentScheduleID, data) => {
  const url = `${process.env.REACT_APP_HOST_API}payment-schedules/${paymentScheduleID}`;
  const response = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('put', url, data);

  return response?.data;
};

/**
 * Performs an API call to delete a Payment Schedule
 *
 * @param {string} groupId ID of Group to delete payment schedule for
 */
export const deletePaymentSchedule = async groupId => {
  const url = `${process.env.REACT_APP_HOST_API}payment-schedules/${groupId}`;
  await performApiCallIfCompanySubIsActiveOrCompanyAdmin('delete', url);
};

export const fetchPaymentScheduleTimeline = async (groupId, startDate, endDate) => {
  const filters = queryString.stringify(_.pickBy({ groupId, startDate, endDate }, _.identity));
  const url = `${process.env.REACT_APP_HOST_API}payment-schedules/timeline?${filters}`;

  const result = await handleApiCalls('get', url);

  return result?.data?.map(item => ({
    ...item,
    date: momentTimezone(item.date, 'MM/DD/YYYY h:mm:ss').toISOString(),
  }));
};

/**
 * Anomaly
 * @typedef {Object} Anomaly
 * @property {string} _id
 * @property {number} betweenCount
 * @property {number} highestCount
 * @property {number} highCount
 * @property {number} lessCount
 * @property {number} overCount
 * @property {number} underCount
 */

/**
 * Anomalies Arrays from Payment Schedule Periods
 * @typedef {object} PaymentSchedulesPeriodAnomalies
 * @property {Anomaly[]} commuteAlerts
 * @property {Anomaly[]} highManualMiles
 * @property {Anomaly[]} mileageAdjustments
 * @property {Anomaly[]} receiptsOverAverage
 * @property {Anomaly[]} tripsOverAverage
 */

export const PAYMENT_SCHEDULES_API = {
  fetchCompanyPaymentSchedules: async (companyID, params, pageNumber, pageSize) => {
    const queryParams = cleanQueryParams({
      ...(params || {}),
      range: formatQueryRange(pageNumber, pageSize),
    });
    const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/payment-schedules?${queryParams}`;
    const response = await handleApiCalls('get', url);

    return response?.data;
  },
  getPaymentScheduleBalance: async paymentScheduleID => {
    const url = `${process.env.REACT_APP_HOST_API}payment-schedules/${paymentScheduleID}/balance`;
    const response = await handleApiCalls('get', url);

    return response.data;
  },
  /**
   * Get payment schedule period anomalies
   *
   * @param {object} params
   * @param {string | Date} params.paymentPeriodEnd
   * @param {string | Date} params.paymentPeriodStart
   * @returns {PaymentSchedulesPeriodAnomalies}
   */
  getPaymentSchedulesPeriodAnomalies: async (
    paymentScheduleID,
    { paymentPeriodEnd, paymentPeriodStart },
  ) => {
    const queryParams = cleanQueryParams({ paymentPeriodEnd, paymentPeriodStart });
    const url = `${process.env.REACT_APP_HOST_API}payment-schedules/${paymentScheduleID}/period-anomalies?${queryParams}`;
    const response = await handleApiCalls('get', url);

    return get(response, 'data');
  },
  /**
   * Get payment schedule emissions
   * @param {string} paymentScheduleID
   * @param {object} filters
   * @param {object} sort
   * @param {number} pageNumber
   * @param {number} pageSize
   */
  fetchPaymentScheduleEmissions: async (
    paymentScheduleID,
    filters = {},
    sort = {},
    pageNumber,
    pageSize,
  ) => {
    const queryParams = cleanQueryParams({
      ...filters,
      sort,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const url = `${process.env.REACT_APP_HOST_API}payment-schedules/${paymentScheduleID}/emissions?${queryParams}`;
    const response = await handleApiCalls('get', url);

    return response.data;
  },
};
