import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Space } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { USER_API } from '../../api/user';
import SpaceSpinner from '../../components/SpaceSpinner';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import { INTERNAL_LINKS } from '../../enum';
import useAuthUser from '../../hooks/useAuthUser';
import useDebounce from '../../hooks/useDebounce';
import { getUserFullName } from '../../utils/common';
import { hasSystemAdminRole } from '../../utils/roles';
import classNames from '../style.module.scss';

const GlobalSearch = props => {
  const { t, history } = props;
  const { authUser } = useAuthUser();

  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 500);
  const [options, setOptions] = useState([]);

  const searchUserByID = useMutation(({ id, email }) =>
    USER_API.getUserList(undefined, { globalSearch: true, userId: id, searchTerm: email }),
  );

  const onSearch = useCallback(searchText => {
    setInputValue(searchText);
  }, []);

  const onSelect = useCallback(
    (optionValue, option) => {
      switch (option?.type) {
        case 'user': {
          history.push(INTERNAL_LINKS.USER_DETAILS_PROFILE(optionValue));
          break;
        }
        default:
          break;
      }

      setInputValue('');
      setOptions([]);
    },
    [history],
  );

  const handleAsyncSearch = useCallback(
    async searchString => {
      const dropdownOptions = [];
      let foundUsers;
      const isID = /[a-zA-Z0-9]{24}$/gi.test(searchString);
      const params = {};
      if (isID) {
        params.id = searchString;
      } else {
        params.email = searchString;
      }

      try {
        const response = await searchUserByID.mutateAsync(params);
        foundUsers = response.users;
      } catch (error) {
        foundUsers = [];
      }

      if (foundUsers.length) {
        dropdownOptions.push({
          label: (
            <Space direction="horizontal" align="baseline" size="small">
              <UserOutlined style={{ fontSize: 14 }} />

              <Text variant="b" size="xs">
                {t('user', { count: foundUsers.length })}
              </Text>
            </Space>
          ),
          options: foundUsers.map(user => ({
            type: 'user',
            label: (
              <Space direction="vertical" size={[5, 5]}>
                <Space direction="vertical" size={[0, 0]}>
                  <Text variant="b">{getUserFullName(user)}</Text>
                  <FadedText size="sm">{user.email}</FadedText>
                </Space>

                <FadedText variant="b" size="sm">
                  {get(user, 'company.name')}
                </FadedText>
              </Space>
            ),
            value: user._id,
          })),
        });
      }

      setOptions(dropdownOptions);
    },
    // eslint-disable-next-line
    [t],
  );

  useEffect(() => {
    if (debouncedValue) {
      handleAsyncSearch(debouncedValue);
    }
  }, [debouncedValue, handleAsyncSearch]);

  if (!hasSystemAdminRole(authUser)) {
    return null;
  }

  return (
    <AutoComplete
      options={options}
      value={inputValue}
      style={{ width: '100%' }}
      onSelect={onSelect}
      onSearch={onSearch}
      autoClearSearchValue={true}
      defaultActiveFirstOption={false}
      notFoundContent={
        searchUserByID.isLoading ? (
          <SpaceSpinner height={100} />
        ) : inputValue && debouncedValue === inputValue ? (
          <FadedText>{t('noResultsFoundForQuery', { searchString: inputValue })}</FadedText>
        ) : null
      }
    >
      <Input
        style={{ width: '100%' }}
        type="text"
        size="large"
        placeholder={t('search')}
        className={classNames['search-input']}
        prefix={<SearchOutlined className={classNames['search-input-icon']} />}
      />
    </AutoComplete>
  );
};

export default GlobalSearch;
