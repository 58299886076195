import DOMPurify from 'dompurify';
import { capitalize, get } from 'lodash';
import moment from 'moment-timezone';

import { validAndroidSettings, validAppleSettinngs } from '../enum/MobileSettings';
import { formatNumberToLocale } from './numbers';
import {
  selectStoreBrowserState,
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from './storeSelectors';

export const formatPageTitle = title => {
  return [title, 'Kliks.io'].filter(Boolean).join(' | ');
};

export function dateCompare(a, b, format) {
  const first = momentTimezone(a, format);
  const second = momentTimezone(b, format);
  if (first.isAfter(second)) {
    return 1;
  } else if (first.isBefore(second)) {
    return -1;
  }
  return 0;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Use moment.js to format a given date string
 *
 * @param {string | Date} date Date object
 * @param {string} format Date format
 * @param {string} originFormat Format of the date inputted
 * @return {string} Formatted Date
 */
export function formatDate(date, format = 'MM/DD/YYYY', originFormat = 'DD MMM, YYYY') {
  return moment(date, originFormat).format(format);
}

/**
 * Takes a given date and returns a Moment.js instance
 * taking ONLY into account company preferences on timezone.
 *
 * If there's no company, just returns the Moment instance of the date
 */
export function momentCompanyTimezone(date, originFormat, config) {
  const currentCompany = selectStoreCurrentCompany();

  if (currentCompany?.preferredTimezone) {
    if (/Etc\/GMT/gi.test(currentCompany.preferredTimezone)) {
      return momentEST(date, originFormat, config);
    }

    return moment(date, originFormat, config).tz(currentCompany.preferredTimezone);
  }

  return momentEST(date, originFormat, config);
}

/**
 * Takes a given date and returns a Moment.js instance
 * taking into account the user or company preferences on timezone.
 *
 * If there's no user or company, just returns the Moment instance of the date
 *
 * NOTE: User settings take precendence
 */
export function momentTimezone(date, originFormat, config) {
  const authUser = selectStoreCurrentAuthUser();

  if (
    authUser?.profile?.preferences?.preferredTimezone &&
    !/Etc\/GMT/gi.test(authUser.profile.preferences.preferredTimezone)
  ) {
    return moment(date, originFormat, config).tz(authUser.profile.preferences.preferredTimezone);
  }

  return momentCompanyTimezone(date, originFormat, config);
}

/**
 * Shorthand for `moment(date).format(string)`
 * but takes into account user or company timezone preference
 */
export function momentFormat(date, format) {
  return momentTimezone(date).format(format);
}

/**
 * Converts given date to EST (America/New_York)
 */
export function momentEST(date, originFormat, config) {
  return moment(date, originFormat, config).tz('America/New_York');
}

/**
 * Shorthand for `moment(date).format(string)`
 * but ONLY takes into account the company timezone preference
 */
export function momentCompanyFormat(date, format) {
  return momentCompanyTimezone(date).format(format);
}

/**
 * Joins the first name and last name from a user's object
 * to return the user's full name
 *
 * @param {{ firstname?: string, firstName?: string, lastname?: string, lastName?: string }} userData
 * @return {string} User full name
 */
export function getUserFullName(userData) {
  if (!userData) return '';
  const first = userData.firstname || userData.firstName;
  const last = userData.lastname || userData.lastName;
  return [first, last].filter(Boolean).join(' ');
}

/**
 * Returns the initial from the first name and last name within a user's object data
 *
 * @param {{ firstname?: string, firstName?: string, lastname?: string, lastName?: string }} userData User's data object
 * @return {string} User initials
 */
export function getUserInitials(userData) {
  const firstName = userData.firstname || userData.firstName || ' ';
  const lastName = userData.lastname || userData.lastName || ' ';

  return [firstName[0].toUpperCase(), lastName[0].toUpperCase()].join('');
}

/**
 * Searches for a query param on the current page's URL
 *
 * @param {string} search Query param name to look for
 */
export function getUrlQueryParameter(search) {
  // eslint-disable-next-line no-useless-escape
  search = search.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + search + '=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * Check if a given string contains a specific value
 *
 * @param {string} string String to check
 * @param {string} value Value to search on string
 * @returns {boolean}
 */
export const checkIfStringContainsValue = (string, value) => {
  if (typeof string !== 'string' || typeof value !== 'string') return false;

  return string.toLowerCase().includes(value.toLowerCase());
};

/**
 * Returns an array with a range of numbers given the start and end numbers
 *
 * @param {number} start Start number
 * @param {number} end End number
 */
export const getNumberRage = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

/**
 * Updates an array, replacing a value in array of objects by matching the IDs of the items
 *
 * @param {{ [idKey: string]: string }} mainArray Array to be updated
 * @param {{ [idKey: string]: string }} valueToReplace Value to update with, must have a matching ID value
 */
export const replaceValueInArrayByID = (mainArray, valueToReplace, customID = '_id') => {
  const updatedArray = mainArray;
  const itemIndex = updatedArray.findIndex(
    item => get(item, customID) === get(valueToReplace, customID),
  );

  if (itemIndex === -1) {
    console.error('New value is not matching any items in the provided array');
    return;
  }

  updatedArray[itemIndex] = {
    ...updatedArray[itemIndex],
    ...valueToReplace,
  };

  return [...updatedArray];
};

/**
 * Formats a byte number into its corresponding file size type format
 *
 * @param {string | number} bytes
 * @param {number} decimals
 */
export const formatBytes = (bytes, decimals = 2) => {
  const thresh = 1024;

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (Math.abs(bytes) < thresh) {
    return formatNumberToLocale(bytes / Math.pow(thresh, 1), decimals) + ' ' + units[0];
  }

  let u = -1;
  const r = 10 ** decimals;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return formatNumberToLocale(bytes, decimals) + ' ' + units[u];
};

export const formatQueryRange = (pageNumber, pageSize) => {
  if (typeof pageNumber === 'undefined' || typeof pageSize === 'undefined') return '';

  const currentSize = (pageNumber - 1) * pageSize;
  const range = `[${currentSize},${currentSize + pageSize}]`;
  return range;
};

export const prependPlusSignIfNoneFound = string => {
  if (!string || typeof string !== 'string') return '';
  const hasPlus = string.includes('+');
  return hasPlus ? string : `+${string}`;
};

export const setModalFullscreenWidth = (maxWidth = 1400) => {
  const windowState = selectStoreBrowserState();
  const viewportWidth = windowState.viewportWidth - 50;
  const modalWidth = viewportWidth > maxWidth ? maxWidth : viewportWidth;
  return modalWidth;
};

export const setFullscreenMaxHeight = (maxHeight = 500) => {
  const windowState = selectStoreBrowserState();
  const viewportHeight = windowState.viewportHeight - 50;
  const modalHeight = viewportHeight > maxHeight ? maxHeight : viewportHeight;
  return modalHeight;
};

export const showTruthyValueOrEmpty = (value, path, emptyValue = '-') => {
  const valueToShow = get(value, path, emptyValue);
  if (!valueToShow) return emptyValue;
  return valueToShow;
};

export const removeAccentedCharactersFromString = string => {
  if (typeof string === 'string') {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  return string;
};

export const addLineBreakTagsToString = string => {
  return DOMPurify.sanitize(string).replace(/\n/g, '<br />');
};

export const removeLineBreakTagsToString = string => {
  return string.replace(/<br \/>/g, '\n');
};

export const joinComponentsWithCommas = (components = []) => {
  return components.reduce((prevElements, el) => {
    if (prevElements) {
      return [prevElements, ', ', el];
    }

    return [el];
  }, null);
};

export const capitalizeEveryWordOfString = (string = '') => {
  return (string || '').replace(/\w+/g, capitalize);
};

export const checkIfElementIsScrolledToBottom = element => {
  if (!element) return false;
  return Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) <= 1;
};

export const getValidMobileAppSettings = (mobileDevice, settingType) => {
  const isAndroid = mobileDevice === 'android';
  const validSettings = isAndroid ? validAndroidSettings : validAppleSettinngs;
  return get(validSettings, settingType, {});
};

export const checkIfSingleMobileSettingIsValid = (
  mobileDevice,
  settingType,
  settingName,
  settingValue,
) => {
  const validSettingTypeToCheck = getValidMobileAppSettings(mobileDevice, settingType);

  return get(validSettingTypeToCheck, settingName) === settingValue;
};

export const checkIfMobileAppSettingIsValid = (mobileDevice, settingType, settings = {}) => {
  const objectValues = Object.values(settings || {});
  if (!objectValues.length) return false;
  const validSettingTypeToCheck = getValidMobileAppSettings(mobileDevice, settingType);

  return Object.keys(validSettingTypeToCheck).every(key => {
    return checkIfSingleMobileSettingIsValid(mobileDevice, settingType, key, get(settings, key));
  });
};

export const getDisplayCountryCode = () => {
  const currentUser = selectStoreCurrentAuthUser();
  const currentCompany = selectStoreCurrentCompany();

  return (
    // country of rate
    get(currentUser, 'profile.group.productId.country') ||
    // home address country
    get(currentUser, 'profile.personalInfor.homeAddress.country') ||
    // company's country
    get(currentCompany, 'address.country', 'US')
  );
};
