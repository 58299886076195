import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { formatPageTitle } from '../../utils/common';
import { selectStoreCompanySettings } from '../../utils/storeSelectors';
import Dashboard from './Dashboard';
import RealtorDashboard from './RealtorDashboard';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Dashboard')}</title>
  </Helmet>
);

const DashboardPageSwitch = props => {
  const companySettings = useSelector(selectStoreCompanySettings);

  if (companySettings?.realtorFeatures) {
    return (
      <>
        <PageHelmet />
        <RealtorDashboard {...props} />;
      </>
    );
  } else {
    return (
      <>
        <PageHelmet />
        <Dashboard {...props} />;
      </>
    );
  }
};

export default DashboardPageSwitch;
