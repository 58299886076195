import React from 'react';
import PropTypes from 'prop-types';
// the hoc
import { withNamespaces } from 'react-i18next';

import { CustomCheckbox } from '../../components';

import './style.scss';

class FilterPanel extends React.Component {
  render() {
    const { t, options, type } = this.props

    return (
      <div className="filter-panel">
        <h5 className="filter-panel-clear" onClick={this.props.clearAll}>{t('Clear all')}</h5>
        <div className="filter-panel-options">
          {options.map((f, index) => {
            return (
              <CustomCheckbox
                key={index}
                type={type}
                checked={f.checked}
                onChange={e => this.props.onChange(index, e.target.checked)}
              >
                {t(f.title)}
              </CustomCheckbox>
            )
          })}
        </div>
      </div>
    )
  }
}

FilterPanel.propTypes = {
  options: PropTypes.array,
  clearAll: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.string,
}

FilterPanel.defaultProps = {
  options: () => [],
  clearAll: () => { },
  onChange: () => { },
  type: 'primary',
}

export default withNamespaces()(FilterPanel);