import { Col, DatePicker, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import useModal from '../../../hooks/useModal';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { ConfirmModal } from '../..';
import Checkbox from '../../Checkbox';
import Select from '../../Select';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import TextInput from '../../TextInput';
import RateFormSegmentCA from './RateFormSegmentCA';
import RateFormSegmentGB from './RateFormSegmentGB';
import RateFormSegmentUSA from './RateFormSegmentUSA';

const COUNTRY_RATE_RULES = {
  CA: [
    'mileage-band-1',
    'mileage-band-2',
    'additional-rate-North-West-Territory',
    'additional-rate-Yukon-Territory',
    'additional-rate-Nunavet-Territory',
  ],
  GB: ['cars-and-vans-first10kMiles', 'cars-and-vans-over10kMiles', 'motorcycle', 'bicycles'],
  US: ['businessMiles', 'medicalMovingMiles', 'charitableOrganizationMiles'],
};

const CountryRateForm = props => {
  const { t, isUpdate, isSubmitting, initialValues, countries, onSubmit, onCancel } = props;

  const [form] = Form.useForm();
  const [formCountry, setFormCountry] = useState(initialValues?.countryCode);

  const [isInfoModalOpen, openInfoModal, closeInfoModal] = useModal();

  const handleSubmit = values => {
    if (getMomentDateWithoutTimezone(values.effectiveStartDate).isAfter(values.effectiveEndDate)) {
      form.setFields([
        {
          name: 'effectiveEndDate',
          errors: [t('endDateAfterStartDateError')],
        },
      ]);
    } else {
      let rules = {};

      const COUNTRY_RULES = Array.isArray(COUNTRY_RATE_RULES[values.countryCode])
        ? COUNTRY_RATE_RULES[values.countryCode]
        : COUNTRY_RATE_RULES.US;

      COUNTRY_RULES.forEach(key => {
        rules[key] = values[key];
      });

      return onSubmit({
        ...values,
        rules,
        effectiveStartDate: values.effectiveStartDate.format('YYYY-MM-DD'),
        effectiveEndDate: values.effectiveEndDate.format('YYYY-MM-DD'),
        country: countries.find(country => country.code === values.countryCode)?.name,
      });
    }
  };

  useEffect(() => {
    if (formCountry) {
      const countryObject = countries.find(country => country.code === formCountry);
      form.setFieldsValue({ currency: countryObject.currencyCode });
    }
    // eslint-disable-next-line
  }, [formCountry]);

  return (
    <Form
      form={form}
      initialValues={
        initialValues
          ? {
              ...initialValues,
              effectiveStartDate: getMomentDateWithoutTimezone(initialValues.effectiveStartDate),
              effectiveEndDate: getMomentDateWithoutTimezone(initialValues.effectiveEndDate),
            }
          : {
              effectiveStartDate: getMomentDateWithoutTimezone(),
              effectiveEndDate: getMomentDateWithoutTimezone().add(1, 'year').endOf('month'),
            }
      }
      labelCol={{ span: 24 }}
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="name"
            label={t('Name')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting} placeholder={t('enterNameOfRate')} />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            required
            name="effectiveStartDate"
            label={t('effectiveStartDate')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <DatePicker
              allowClear={false}
              disabled={isSubmitting}
              style={{ width: '100%' }}
              onChange={date => {
                if (isUpdate && getMomentDateWithoutTimezone().isAfter(date, 'date')) {
                  openInfoModal();
                }
              }}
            />
          </Form.Item>

          <Form.Item
            required
            name="effectiveEndDate"
            label={t('effectiveEndDate')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <DatePicker allowClear={false} disabled={isSubmitting} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="countryCode"
            label={t('country')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <Select
              disabled={isSubmitting}
              placeholder={t('selectCountry')}
              onChange={country => setFormCountry(country)}
              options={countries.map(c => ({
                label: c.name,
                value: c.code,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="currency"
            label={t('currency')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <Select
              disabled
              options={countries.map(c => ({
                label: c.currencyName,
                value: c.currencyCode,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Checkbox checked disabled>
          {t('govermentRate?')}
        </Checkbox>
      </Form.Item>

      {formCountry && !['CA', 'GB'].includes(formCountry) && (
        <RateFormSegmentUSA t={t} isSubmitting={isSubmitting} />
      )}
      {formCountry === 'CA' && <RateFormSegmentCA t={t} isSubmitting={isSubmitting} />}
      {formCountry === 'GB' && <RateFormSegmentGB t={t} isSubmitting={isSubmitting} />}

      <SubmitCancelButtonGroup
        isForm
        loading={isSubmitting}
        submitText={t(isUpdate ? 'update' : 'create')}
        onCancel={onCancel}
      />

      <ConfirmModal
        hideCancel
        visible={isInfoModalOpen}
        onOk={closeInfoModal}
        title={t('tripsUsingRatePrioWillNotUpdate')}
      />
    </Form>
  );
};

CountryRateForm.defaultProps = {
  countries: [],
  isUpdate: false,
};

export default CountryRateForm;
