import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import CrmContactTable from '../../components/Table/CrmContactTable';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle } from '../../utils/common';
import useClientList from './useClientList';

const ClientListView = props => {
  const { t } = props;

  const { isFetchingContacts, contacts } = useClientList();

  return (
    <PageContainer title={t('clients')}>
      <Helmet>
        <title>{formatPageTitle('Clients')}</title>
      </Helmet>

      <CrmContactTable t={t} loading={isFetchingContacts} dataSource={contacts} />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(ClientListView));
