import { useFlags } from 'flagsmith/react';
import React from 'react';
import { Redirect } from 'react-router-dom';

import { INTERNAL_LINKS } from '../enum';

const withFlagEnabled = (
  WrappedComponent,
  features = [],
  redirectOnFail = INTERNAL_LINKS.DASHBOARD,
) => {
  return props => {
    const flags = useFlags(features);

    if (!features.every(featureName => flags[featureName]?.enabled)) {
      return <Redirect to={redirectOnFail} />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withFlagEnabled;
