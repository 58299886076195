import { Col, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import Steps from '../../components/Steps';
import Text from '../../components/Text';
import PageContainer from '../../containers/PageContainer';
import { IMAGES, INTERNAL_LINKS } from '../../enum';
import { formatPageTitle } from '../../utils/common';

const AccountBalanceTransactionsHowItWorksView = props => {
  const { t, history } = props;
  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('kliksAccountBalance'),
              onClick: () => history.push(INTERNAL_LINKS.ACCOUNT_BALANCE),
            },
            { label: t('achTransfersHowItWorks') },
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('Account Balance | How It Works')}</title>
      </Helmet>

      <br />

      <Row justify="center" style={{ minWidth: 800 }}>
        <Col flex="900px">
          <Steps current={3} progressDot>
            <Steps.Step
              title={
                <Text variant="b" style={{ whiteSpace: 'nowrap' }}>
                  {t('totalReimbursement')}
                </Text>
              }
            />
            <Steps.Step title={<Text variant="b">{t('achFunding')}</Text>} />
            <Steps.Step title={<Text variant="b">{t('paymentDate')}</Text>} />
          </Steps>
        </Col>
      </Row>

      <Row justify="center" style={{ minWidth: 800 }}>
        <Col flex="1000px">
          <Row wrap={false} gutter={16}>
            <Col flex={1}>
              <br />

              <Row wrap={false} gutter={16} align="middle">
                <Col>
                  <img alt="calendar" width="40px" src={IMAGES.USER_SINGLE_SHIELD_ICON} />
                </Col>
                <Col flex={1}>
                  <Text>{t('companyAdminAprovesReceiptsInReimbursementPeriod')}</Text>
                </Col>
              </Row>
            </Col>

            <Col flex={1}>
              <br />

              <Row wrap={false} gutter={16} align="middle">
                <Col>
                  <img alt="calendar" width="40px" src={IMAGES.USER_SINGLE_SHIELD_ICON} />
                </Col>
                <Col flex={1}>
                  <Text>{t('companyAdminAddsFundsToCoverTotalReimbursementAmount')}</Text>
                </Col>
              </Row>

              <br />

              <Row wrap={false} gutter={16} align="middle">
                <Col>
                  <img alt="calendar" width="40px" src={IMAGES.CALENDAR_GREEN_ICON} />
                </Col>
                <Col flex={1}>
                  <Text>{t('transferTime')}:</Text>
                  <Text>{t('45BusinessDays')}</Text>
                </Col>
              </Row>
            </Col>

            <Col flex={1}>
              <br />

              <Row wrap={false} gutter={16} align="middle">
                <Col>
                  <img alt="calendar" width="40px" src={IMAGES.BANK_ACCOUNT_ICON} />
                </Col>
                <Col flex={1}>
                  <Text>{t('kliksSystemTransfersFundsFromCompanyMasterAccount')}</Text>
                </Col>
              </Row>

              <br />

              <Row wrap={false} gutter={16} align="middle">
                <Col>
                  <img alt="calendar" width="40px" src={IMAGES.CALENDAR_GREEN_ICON} />
                </Col>
                <Col flex={1}>
                  <Text>{t('transferTime')}:</Text>
                  <Text>{t('45BusinessDays')}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(AccountBalanceTransactionsHowItWorksView);
