export const TOGGLE_YEARS_LOADER = 'TOGGLE_YEARS_LOADER';
export const TOGGLE_MAKES_LOADER = 'TOGGLE_MAKES_LOADER';
export const TOGGLE_MODELS_LOADER = 'TOGGLE_MODELS_LOADER';

export const GET_VEHICLE_YEARS = 'GET_VEHICLE_YEARS';
export const UPDATE_VEHICLE_YEARS = 'UPDATE_VEHICLE_YEARS';

export const GET_VEHICLE_MAKES = 'GET_VEHICLE_MAKES';
export const UPDATE_VEHICLE_MAKES = 'UPDATE_VEHICLE_MAKES';

export const GET_VEHICLE_MODELS = 'GET_VEHICLE_MODELS';
export const UPDATE_VEHICLE_MODELS = 'UPDATE_VEHICLE_MODELS';

export const SETUP_VEHICLES = 'SETUP_VEHICLES';
export const COMPLETE_SETUP_USER_VEHICLES = 'COMPLETE_SETUP_USER_VEHICLES';

export const toggleYearsLoader = loaderState => {
  return {
    type: TOGGLE_YEARS_LOADER,
    data: loaderState,
  };
};

export const toggleMakesLoader = loaderState => {
  return {
    type: TOGGLE_MAKES_LOADER,
    data: loaderState,
  };
};

export const toggleModelsLoader = loaderState => {
  return {
    type: TOGGLE_MODELS_LOADER,
    data: loaderState,
  };
};

export const emitSetupUserVehicles = (year, make) => {
  return { type: SETUP_VEHICLES, data: { year, make } };
};

export const emitGetVehicleYears = () => {
  return { type: GET_VEHICLE_YEARS };
};

export const setYearData = ({ min_year, max_year }) => {
  return {
    type: UPDATE_VEHICLE_YEARS,
    data: { min_year, max_year },
  };
};

export const emitGetVehicleMakes = year => {
  return { type: GET_VEHICLE_MAKES, data: { year } };
};

export const updateVehicleMakes = (year, makesData) => {
  return { type: UPDATE_VEHICLE_MAKES, data: { year, makesData } };
};

export const emitGetVehicleModels = (year, make) => {
  return { type: GET_VEHICLE_MODELS, data: { year, make } };
};

export const updateVehicleModels = (year, make, modelsData) => {
  return { type: UPDATE_VEHICLE_MODELS, data: { year, make, modelsData } };
};

export const completeVehicleSetup = (year, make, modelsData) => {
  return { type: COMPLETE_SETUP_USER_VEHICLES };
};
