import { get } from 'lodash';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { COMPANY_API } from '../../../api/company';
import StripeAPI from '../../../api/stripe';
import SubscriptionsAPI from '../../../api/subscription';
import Toast from '../../../components/Toast';
import { STATUS_LIST } from '../../../enum';
import { saveCompany, updateCompanyPlan } from '../../../stores/actions/common';
import { selectStoreCurrentCompany, selectStoreCurrentPlan } from '../../../utils/storeSelectors';
import { formatInvoiceFormValues } from './billing-method.utils';

const useBillingMethodState = ({ t }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const currentPlan = useSelector(selectStoreCurrentPlan);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(currentPlan?.collectionMethod);

  const preferredPaymentMethodMutation = useMutation({
    mutationFn: method => {
      if (get(currentCompany, 'companySettingId.settings.allow_invoice_for_billing')) {
        setSelectedPaymentMethod(method);
        return new SubscriptionsAPI().updateCollectionMethod(currentPlan._id, method);
      }
    },
    onSuccess: () => {
      COMPANY_API.getActivePlan(currentCompany._id, plan => {
        dispatch(saveCompany({ status: STATUS_LIST().Status.ACTIVE }));
        dispatch(updateCompanyPlan(plan));
      });
    },
    onError: () => {
      setSelectedPaymentMethod();
      Toast({
        type: 'error',
        message: t('preferredPaymentMethodMutationError'),
      });
    },
  });

  const invoiceSubmitMutation = useMutation({
    mutationFn: formValues => {
      const params = formatInvoiceFormValues(formValues);
      return new StripeAPI().updateCustomer(params);
    },
    onSuccess: async data => {
      queryClient.setQueryData(['fetchCustomerInformation', currentCompany.stripeCustomerId], data);

      try {
        await COMPANY_API.getActivePlan(currentCompany._id, plan => {
          dispatch(saveCompany({ status: STATUS_LIST().Status.ACTIVE }));
          dispatch(updateCompanyPlan(plan));
        });

        Toast({
          type: 'open',
          message: t('billingInformationUpdateSuccess'),
        });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('billingInformationUpdateError'),
          });
        });
      }
    },
    onError: error => handleApiErrors(error.response),
  });

  return {
    invoiceSubmitMutation,
    preferredPaymentMethodMutation,
    selectedPaymentMethod,
  };
};

export default useBillingMethodState;
