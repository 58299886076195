import { selectStoreCountries } from './storeSelectors';

export const findFullSupportedCountryData = (countryCodes = 'US') => {
  const supportedCountries = selectStoreCountries();

  let fullCountry;

  if (Array.isArray(countryCodes)) {
    for (let i = 0; i < countryCodes.length; i++) {
      const countryCode = countryCodes[i];
      fullCountry = supportedCountries.find(c => c.code === countryCode);
      if (fullCountry) break;
    }
  } else if (typeof countryCodes === 'string') {
    fullCountry = supportedCountries.find(c => c.code === countryCodes);
  }

  if (!fullCountry) {
    fullCountry = supportedCountries.find(c => c.code === 'US');
  }

  return fullCountry;
};
