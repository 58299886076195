import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls, handleApiFileDownloads } from './axiosInstance';

export const ESG_SCOPE_EMISSIONS_API = {
  exportEmissionsToCSV: async params => {
    const queryParams = cleanQueryParams({ ...params });
    const url = `${process.env.REACT_APP_HOST_API}esg-scope-emissions/export?${queryParams}`;
    await handleApiFileDownloads('get', url, undefined);
  },
  fetchVehicleEmissionsModelOptions: async ({ year, make, model }) => {
    const queryParams = cleanQueryParams({ year, make, model });
    const url = `${process.env.REACT_APP_HOST_API}esg-scope-emissions/models/options?${queryParams}`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },
  fetchCarModelOptions: async ({ year, make }) => {
    const queryParams = cleanQueryParams({ year, make });
    const url = `${process.env.REACT_APP_HOST_API}esg-scope-emissions/models?${queryParams}`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },
  fetchVehicleEmissionsFromModelID: async modelID => {
    const url = `${process.env.REACT_APP_HOST_API}esg-scope-emissions/vehicles/${modelID}`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },
};
