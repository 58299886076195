import React from 'react';

import LOTTIE_FILE from './lottie-file.json';

const AnimatedCheckmark = props => {
  const { width, height } = props;

  return (
    <lottie-player
      src={JSON.stringify(LOTTIE_FILE)}
      background="transparent"
      speed="1"
      style={{ width: width || 150, height: height || 150 }}
      autoplay
    ></lottie-player>
  );
};

export default AnimatedCheckmark;
