import React from 'react';

import ComplianceItemsList from '../../ComplianceItemsList';
import HelpIcon from '../../HelpIcon';

const FavrComplianceComponentsInfoTooltip = props => {
  const { t, type, complianceComponents } = props;

  if (!['business-vehicle', 'insurance', 'business-milage'].includes(type)) return null;

  return (
    <HelpIcon
      variant="info"
      hint={<ComplianceItemsList t={t} type={type} complianceComponents={complianceComponents} />}
    />
  );
};

export default FavrComplianceComponentsInfoTooltip;
