import { merge, orderBy } from 'lodash';

import { formatQueryRange, momentCompanyFormat } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls, handleApiFileDownloads } from './axiosInstance';

export const DWOLLA_RESOURCES_API = {
  /**
   * @param {string} companyId
   * @param {'["mass-payment"]' | '["transfer"]'} type
   * @param {number} pageNumber
   * @param {number} pageSize
   */
  fetchDwollaResources: async (companyId, type, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range, companyId, type });

    const url = `${process.env.REACT_APP_HOST_API}dwolla-resources?${queryParams}`;
    const response = await handleApiCalls('get', url);
    return {
      count: response.data.count,
      documents: orderBy(response.data.documents, ['created'], 'desc'),
    };
  },
  fetchMassPaymentDetails: async (massPaymentId, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range });

    const url = `${process.env.REACT_APP_HOST_API}dwolla-resources/mass-payments/${massPaymentId}/items?${queryParams}`;
    const response = await handleApiCalls('get', url);

    return merge(response.data, {
      documents: orderBy(response.data.documents, ['created'], 'desc'),
    });
  },
  /**
   * Fetch Users without valid bank account info
   * @param {string} massPaymentId
   */
  fetchUsersWithoutBankAccounts: async massPaymentId => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla-resources/${massPaymentId}`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },
  /**
   * Export dwolla resource mass payment items
   * @param {string} massPaymentId
   */
  exportDwollaMassPaymentItems: async (massPaymentId, period) => {
    const paymentPeriod = period
      ? [
          momentCompanyFormat(period.paymentPeriodStart, 'MMMM DD'),
          momentCompanyFormat(period.paymentPeriodEnd, 'MMMM DD'),
        ].join(' - ')
      : 'Period';

    const url = `${process.env.REACT_APP_HOST_API}dwolla-resources/mass-payments/${massPaymentId}/items/export`;
    await handleApiFileDownloads(
      'get',
      url,
      {},
      `Reimbursement Transactions - ${paymentPeriod}.csv`,
    );
  },
};
