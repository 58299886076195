import { Form } from 'antd';
import { merge, omit } from 'lodash';
import React from 'react';

import FormItem from '../../../components/Form/FormItem';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import TextInput from '../../../components/TextInput';
import { ERROR_MESSAGE } from '../../../utils/constants';

const SftpForm = props => {
  const { t, formInstance, initialValues, disabledForm, isSubmitting, onSubmit } = props;

  const DEFAULT_VALUES = { port: 22 };

  return (
    <Form
      form={formInstance}
      initialValues={omit(merge(DEFAULT_VALUES, initialValues), 'password')}
      onFinish={onSubmit}
    >
      <FormItem
        name="host"
        label={t('sftpHost')}
        rules={[{ required: !disabledForm, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput disabled={disabledForm || isSubmitting} />
      </FormItem>

      <FormItem
        name="port"
        label={t('sftpPort')}
        rules={[{ required: !disabledForm, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput disabled={disabledForm || isSubmitting} />
      </FormItem>

      <FormItem
        name="directory"
        label={t('sftpDirectory')}
        rules={[{ required: !disabledForm, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput disabled={disabledForm || isSubmitting} />
      </FormItem>

      <FormItem
        name="username"
        label={t('sftpUsername')}
        rules={[{ required: !disabledForm, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput disabled={disabledForm || isSubmitting} />
      </FormItem>

      <FormItem
        name="password"
        label={t('sftpPassword')}
        rules={[{ required: !disabledForm, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput password disabled={disabledForm || isSubmitting} />
      </FormItem>

      <SubmitCancelButtonGroup
        isForm
        hideCancel
        loading={isSubmitting}
        disabled={disabledForm || isSubmitting}
        submitText={t('Save')}
      />
    </Form>
  );
};

export default SftpForm;
