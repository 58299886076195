import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

export const COMPANY_LOCATIONS_API = {
  /**
   * Performs an API call to fetch custom locations for a Company
   */
  fetchCompanyLocations: async (pageNumber, pageSize) => {
    const queryParams = cleanQueryParams({ range: formatQueryRange(pageNumber, pageSize) });
    const url = `${process.env.REACT_APP_HOST_API}company-locations?${queryParams}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  },

  /**
   * Performs an API call to fetch a specific custom location for a Company
   *
   * @param {string} locationID ID of Location to fetch
   */
  fetchCompanyLocationByID: async locationID => {
    const url = `${process.env.REACT_APP_HOST_API}company-locations/${locationID}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  },

  /**
   * Performs an API call to create a custom location
   *
   * @param {object} data
   * @param {string} data.streetOne
   * @param {string} data.city
   * @param {string} data.country
   * @param {string} data.state
   * @param {string} data.postalCode
   * @param {string} data.label
   */
  createCompanyLocation: async data => {
    const url = `${process.env.REACT_APP_HOST_API}company-locations`;
    const result = await handleApiCalls('post', url, data);
    return result.data;
  },

  /**
   * Performs an API cll to update a custom location
   *
   * @param {object} data
   * @param {string} data.streetOne
   * @param {string} data.city
   * @param {string} data.country
   * @param {string} data.state
   * @param {string} data.postalCode
   * @param {string} data.label
   */
  updateCompanyLocation: async data => {
    const url = `${process.env.REACT_APP_HOST_API}company-locations`;
    const result = await handleApiCalls('put', url, data);
    return result.data;
  },

  /**
   * Performs an API call to delete a custom location for a Company
   *
   * @param {string} locationID ID of Location to delete
   */
  deleteCompanyLocation: async locationID => {
    const url = `${process.env.REACT_APP_HOST_API}company-locations/${locationID}`;
    const result = await handleApiCalls('delete', url);
    return result.data;
  },
};
