import React from 'react';
import { Form } from 'antd';

import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';

const ImportStatusAPISection = props => {
  const { t } = props;

  return (
    <div>
      <Text>{t('yourCompanyIsConfiguredToImport')}</Text>

      <br />

      <Form labelCol={{ span: 24 }} onFinish={() => alert('SAVE')}>
        <Form.Item label={t('yourApiKey')}>
          <TextInput />
        </Form.Item>

        <Form.Item label={t('locationsApi')}>
          <TextInput />
        </Form.Item>

        <SubmitCancelButtonGroup isForm hideCancel submitText={t('Save')} />
      </Form>
    </div>
  );
};

export default ImportStatusAPISection;
