import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import GenericModal from '../../../../components/Modal/GenericModal';
import SubmitCancelButtonGroup from '../../../../components/SubmitCancelButtonGroup';
import Text from '../../../../components/Text';
import { getUserFullName } from '../../../../utils/common';
import { selectStoreCurrentAuthUser } from '../../../../utils/storeSelectors';

const CertifyOwnerModal = props => {
  const { t, visible, onCancel, isLoading, onSubmit } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);

  return (
    <GenericModal
      closable={!isLoading}
      visible={visible}
      onCancel={onCancel}
      title={t('certifyBeneficialOwners')}
      footer={
        <SubmitCancelButtonGroup
          onCancel={onCancel}
          onSubmit={onSubmit}
          loading={isLoading}
          disabled={isLoading}
          submitText={t('certifyOwners')}
        />
      }
    >
      <Text>
        <Trans
          t={t}
          i18nKey="iCertifyTheInformationProvided"
          values={{ name: getUserFullName(authUser.profile) }}
          components={[<Text variant="b">Dummy</Text>]}
        />
      </Text>
    </GenericModal>
  );
};

CertifyOwnerModal.defaultProps = {
  dataToCertify: [],
};

export default CertifyOwnerModal;
