import { useEffect, useMemo, useState } from 'react';

const useIsInViewport = ref => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          if (!isIntersecting) setIsIntersecting(entry.isIntersecting);
        },
        { threshold: 0.5 },
      ),
    [isIntersecting],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export default useIsInViewport;
