import { useCallback, useState } from 'react';

const useModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const openModal = useCallback(props => {
    setIsModalVisible(true);
    setModalProps(props);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setModalProps({});
  }, []);

  return [isModalVisible, openModal, closeModal, modalProps];
};

export default useModal;
