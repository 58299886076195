import { formatQueryRange } from '../utils/common';
import { cleanQueryParams, removeFalseyParams } from '../utils/queryParams';
import { selectStoreCountryByCode, selectStoreCurrentCompany } from '../utils/storeSelectors';
import { handleApiCalls, performApiCallIfCompanySubIsActive } from './axiosInstance';

/**
 * Peforms an API call to fetch the current user's yearly trip statistics
 *
 * @param {string} userId ID of User to fetch statistics
 * @param {string} year Year to fetch the statistics for
 */
export const fetchTripsYearlyStatistics = async (userId, year) => {
  const url = `${process.env.REACT_APP_HOST_API}user/${userId}/trips/yearly-data?year=${year}`;
  const result = await handleApiCalls('get', url);

  return result.data.data;
};

/**
 * Peforms an API call fetch the current user's Trip Activity data
 *
 * @param {string} userId ID of User to fetch activity
 */
export const fetchCurrentUserTripActivity = async (userId, year = new Date().getFullYear()) => {
  const url = `${process.env.REACT_APP_HOST_API}user/${userId}/trips/activity?year=${year}`;
  const result = await handleApiCalls('get', url);

  return result.data.data;
};

export const fetchTripsImportedViaCSV = async requestID => {
  const url = `${process.env.REACT_APP_HOST_API}trip?importRequestId=${requestID}`;
  const result = await handleApiCalls('get', url);

  return result.data.data;
};

export const fetchTripComments = async (tripId, pageNumber, pageSize) => {
  const url = `${process.env.REACT_APP_HOST_API}trip-comments?${cleanQueryParams({
    tripId,
    range: formatQueryRange(pageNumber, pageSize),
    sort: JSON.stringify({ created: -1 }),
  })}`;
  const result = await handleApiCalls('get', url);

  return result.data;
};

export const postTripComment = async (tripId, comment) => {
  const url = `${process.env.REACT_APP_HOST_API}trip-comments`;
  const result = await handleApiCalls('post', url, { tripId, comment });

  return result.data;
};

export const TRIP_API = {
  fetchTrips: async (params, pageNumber, pageSize) => {
    const queryParams = cleanQueryParams({
      ...params,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const url = `${process.env.REACT_APP_HOST_API}trip?${queryParams}`;
    const result = await handleApiCalls('get', url);

    return result.data;
  },
  /**
   * Performs an API call to fetch the Trip's details
   *
   * @param {string} tripId ID of Trip to fetch
   */
  fetchTripDetails: async (tripId, config) => {
    const url = `${process.env.REACT_APP_HOST_API}trip/${tripId}`;
    const result = await handleApiCalls('get', url, config);
    return {
      ...result.data.data,
      latestComment: result.data.latestTripComment,
    };
  },
  fetchTripsImportedViaCSV: async (importRequestId, pageNumber, pageSize) => {
    const queryParams = cleanQueryParams({
      importRequestId,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const url = `${process.env.REACT_APP_HOST_API}trip?${queryParams}`;
    const result = await handleApiCalls('get', url);

    return result.data;
  },
  exporTrips: async params => {
    const url = `${process.env.REACT_APP_HOST_API}trip/export`;
    await handleApiCalls('post', url, removeFalseyParams(params));
  },
  /**
   * Create manual trip
   * @param {object} tripData
   * @param {"business" | "commute"} tripData.tripType
   * @param {object} tripData.startLocation
   * @param {object} tripData.endLocation
   * @param {object} tripData.journeyStartTs
   * @param {object} tripData.journeyEndTs
   * @returns {"accepted"}
   */
  createManualTrip: async tripData => {
    const url = `${process.env.REACT_APP_HOST_API}trip/create-manual`;
    const response = await performApiCallIfCompanySubIsActive('post', url, {
      sourceFrom: 'WEB',
      tripType: tripData.tripType,
      journeyStartTs: tripData.journeyStartTs,
      journeyEndTs: tripData.journeyEndTs,
      address: [tripData.startLocation, tripData.endLocation],
      // extra fields
      journeyHardDeccel: 0,
      confirmed_dtc: '',
      journeyHardStart: 0,
      journeyFuelComs: 0,
      journeyServerTs: null,
      journeyDriverId: '',
      vin: '',
      journeyHardTurn: 0,
      journeyDrivingTs: 0,
      firmware_ver: 1,
      journeyHardStop: 0,
      journeyHardAccel: 0,
      journeyOverSpeed: null,
      radio_local_ip: '',
      journeyIdleTime: 0,
      addressFlag: true,
      associationInfor: {
        tripPurposeMetainfo: [],
      },
    });
    return response.data;
  },
  updateTrip: async (tripID, data) => {
    return performApiCallIfCompanySubIsActive(
      'put',
      `${process.env.REACT_APP_HOST_API}trip/${tripID}`,
      data,
    );
  },
  /**
   * Performs an API call to delete the provided Trips
   *
   * @param {string[]} tripIds
   */
  deleteTrip: async (tripIds = []) => {
    const url = `${process.env.REACT_APP_HOST_API}trip`;
    const result = await performApiCallIfCompanySubIsActive('delete', url, {
      params: { trips: tripIds },
    });

    return result.data.data;
  },
  /**
   * Performs an API call to recalculate a Trip's amount
   *
   * @param {object} tripId ID of Trip to recalculate
   */
  recalculateTripAmount: async tripId => {
    const url = `${process.env.REACT_APP_HOST_API}trip/calculate/${tripId}`;
    const result = await handleApiCalls('get', url);

    return result.data.data;
  },
  /**
   * Create Mileage
   *
   * @param {object} params
   * @param {{ journeyDate: string, journeyDistance: number }[]} params.trips
   * @param {boolean} params.submitMileage
   */
  createMileage: async params => {
    const currentCompany = selectStoreCurrentCompany();
    const country = selectStoreCountryByCode(currentCompany.address.country);

    const url = `${process.env.REACT_APP_HOST_API}trip/createMileage`;
    const result = await handleApiCalls('post', url, {
      ...params,
      sourceFrom: 'WEB',
      distanceUnit: country.distanceLong,
    });

    return result.data.data;
  },
  /**
   * Update Mileage
   *
   * @param {object} params
   * @param {{ tripId: string, journeyDistance: number }[]} params.trips
   * @param {boolean} params.submitMileage
   */
  updateMileage: async params => {
    const url = `${process.env.REACT_APP_HOST_API}trip/updateMileage`;
    const result = await handleApiCalls('put', url, params);

    return result.data.data;
  },
  /**
   * Get All Mileages for month-calendar wise
   *
   * @param {string} fromDate
   * @param {string} toDate
   */
  getAllMileages: async (fromDate, toDate) => {
    const url = `${process.env.REACT_APP_HOST_API}trip/getAllMileages`;
    const result = await handleApiCalls('post', url, { fromDate, toDate });

    return result.data.data;
  },
  uploadTripsCsvFile: async file => {
    const url = `${process.env.REACT_APP_HOST_API}trip/import`;
    var formData = new FormData();
    formData.append('file', file);
    const result = await performApiCallIfCompanySubIsActive('post', url, formData, {
      'Content-Type': 'multipart/form-data',
    });
    return result.data;
  },
};
