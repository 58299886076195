import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { withNamespaces } from 'react-i18next';

import CreateItineraryModal from '../../components/Modal/CreateItineraryModal';
import VisibleWhenOnViewport from '../../components/VisibleWhenOnViewport';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import useCreateItinerary from '../../hooks/useCreateitinerary';
import { formatAddressIntoString } from '../../utils/trips';
import ClientLookCard from './Cards/ClientLookCard';
import ItinerariesScheduledCard from './Cards/ItinerariesScheduledCard';
import RealtorDayMapBox from './RealtorDayMapBox';
import classNames from './style.module.scss';
import useRealtorDashboard from './useRealtorDashboard';

const RealtorDashboard = props => {
  const { t, history } = props;

  const { layoutStore, authUser } = useRealtorDashboard();

  const {
    isCreatingItinerary,
    isItineraryCreateModalOpen,
    openItineraryCreateModal,
    closeItineraryCreateModal,

    handleCreateItinerary,
  } = useCreateItinerary({ history });

  const TOP_ROW_COL_CONFIG = useMemo(() => {
    if (layoutStore.sidebarCollapsed) {
      return { xs: 24, md: 12 };
    } else {
      return { xs: 24, xl: 12 };
    }
  }, [layoutStore.sidebarCollapsed]);

  return (
    <PageContainer
      title={t('welcomeUser', { name: authUser.profile.firstName })}
      className={classNames.dashboardPage}
    >
      <Row gutter={[16, 16]} className="rowOfCards">
        <Col {...TOP_ROW_COL_CONFIG}>
          <ItinerariesScheduledCard t={t} onCreateItineraryClick={openItineraryCreateModal} />
        </Col>

        <Col {...TOP_ROW_COL_CONFIG}>
          <ClientLookCard t={t} history={history} />
        </Col>
      </Row>

      <br />

      <VisibleWhenOnViewport height={300}>
        <RealtorDayMapBox t={t} onScheduleItineraryClick={openItineraryCreateModal} />
      </VisibleWhenOnViewport>

      <CreateItineraryModal
        t={t}
        isSavingItinerary={isCreatingItinerary}
        initialValues={{
          trips: [
            {
              tripStartLocationAddress: formatAddressIntoString(
                authUser.profile?.personalInfor?.homeAddress,
              ),
            },
          ],
        }}
        visible={isItineraryCreateModalOpen}
        onCancel={closeItineraryCreateModal}
        onSubmit={handleCreateItinerary}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(RealtorDashboard));
