import { Col, Row } from 'antd';
import React from 'react';

import { Button } from '../../../components';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import { IMAGES } from '../../../enum';

const ItinerariesScheduledCard = props => {
  const { t, onCreateItineraryClick } = props;

  return (
    <Box>
      <Row gutter={[16, 16]} justify="space-between" style={{ height: '100%' }}>
        <Col span={24}>
          <Text variant="h4">{t('yourItineraries')}</Text>
          <Text>{t('youGoingPlacesMapOutYourDay')}</Text>
        </Col>

        <Col span={24} style={{ height: 'max-content', alignSelf: 'end' }}>
          <Button
            block
            type="secondary"
            onClick={onCreateItineraryClick}
            icon={
              <img width="18px" alt="plus" style={{ marginRight: 5 }} src={IMAGES.MAP_PIN_ICON} />
            }
          >
            {t('createYourItinerary')}
          </Button>
        </Col>
      </Row>
    </Box>
  );
};

export default ItinerariesScheduledCard;
