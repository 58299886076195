import React from 'react';

import GenericModal from '../Modal/GenericModal';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Text from '../Text';

const SelectedVehicleSettingsChangedModal = props => {
  const { t, visible, onCancel, onSubmit } = props;

  return (
    <GenericModal
      visible={visible}
      title={t('sureYouWantToSaveChanges?')}
      footer={
        <SubmitCancelButtonGroup
          onCancel={onCancel}
          cancelText={t('no')}
          onSubmit={onSubmit}
          submitText={t('yes')}
        />
      }
    >
      <Text>{t('youHaveChangedStandardVehicleSettings')}</Text>
    </GenericModal>
  );
};

export default SelectedVehicleSettingsChangedModal;
