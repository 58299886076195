import { Col, Row } from 'antd';
import React from 'react';

import { momentFormat } from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import {
  sortColumnByDateString,
  sortColumnByMomentTime,
  sortColumnByNumber,
  sortColumnByStringField,
} from '../../../utils/tables';
import { CustomTable } from '../..';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';

/**
 * Renders a Table with a list of Payroll files
 *
 * @param {object} props Component props
 */
const PayrollFileTable = props => {
  const { t, dataSource, loading, onDownload, onViewDownloadHistory } = props;

  const COLUMNS = [
    {
      title: t('fileName'),
      dataIndex: 'fileName',
      key: 'filename',
      ...sortColumnByStringField('fileName'),
      render: (actions, data) => {
        return (
          <LinkText variant="strong" onClick={() => onDownload(data.downloadToken)}>
            {data.fileName}
          </LinkText>
        );
      },
    },
    {
      defaultSortOrder: 'descend',
      title: t('dateDownloaded'),
      dataIndex: 'created',
      key: 'date',
      ...sortColumnByDateString('created'),
      render: date => momentFormat(new Date(date), 'MM-DD-YYYY'),
    },
    {
      defaultSortOrder: 'descend',
      title: t('timeDownloaded'),
      key: 'timeDownloaded',
      dataIndex: 'created',
      ...sortColumnByMomentTime('created', 'h:mm a'),
      render: date => momentFormat(new Date(date), 'h:mm a'),
    },
    {
      title: t('downloadedBy'),
      dataIndex: 'Downloaded By',
      key: 'downloadedBy',
      ...sortColumnByStringField('downloadedBy'),
      render: (actions, data) => {
        return (
          <Row gutter={8} align="middle">
            <Col>
              <Text variant="strong" size="sm">
                {data.downloadedBy}
              </Text>
            </Col>
            {data.downloadHistory.length > 0 && (
              <Col>
                <LinkText size="sm" onClick={() => onViewDownloadHistory(data.downloadHistory)}>
                  {t('viewHistory')}
                </LinkText>
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      align: 'right',
      title: t('numberOfUsers'),
      dataIndex: 'countOfUsers',
      key: 'user',
      ...sortColumnByNumber('countOfUsers'),
    },
    {
      align: 'right',
      title: t('totalAmount'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      ...sortColumnByNumber('totalAmount'),
      render: totalAmount => {
        return formatCurrencyToCompanyLocale(totalAmount);
      },
    },
  ];

  return (
    <CustomTable
      loading={loading}
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource}
      scroll={{ x: 1400 }}
    />
  );
};

export default PayrollFileTable;
