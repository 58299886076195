import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import {
  deleteCountryCodeMapping,
  fetchCountryCodeMappingByID,
  updateCountryCodeMapping,
} from '../../api/country-code-mappings';
import { ConfirmModal } from '../../components';
import Box from '../../components/Box';
import CountryForm from '../../components/Forms/CountryForm';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import { updateSupportedCountries } from '../../stores/actions/common';
import * as systemAdminActions from '../../stores/actions/systemAdmin';
import { formatPageTitle } from '../../utils/common';

const UpdateCountryView = props => {
  const { t, history, match } = props;

  const dispatch = useDispatch();
  const { supportedCountries } = useSelector(store => store.common);
  const { countryCodeMappings } = useSelector(store => store.systemAdmin);

  const [countryToUpdate, setCountryToUpdate] = useState();
  const [isUpdatingCountry, setIsUpdatingCountry] = useState(false);
  const [isDeletingCountry, setIsDeletingCountry] = useState(false);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);

  const handleCountryUpdate = async data => {
    setIsUpdatingCountry(true);

    try {
      const country = await updateCountryCodeMapping(countryToUpdate._id, data);

      let updatedCountries = [...supportedCountries];
      const updatedCountryIndex = updatedCountries.findIndex(c => c._id === countryToUpdate._id);

      if (updatedCountryIndex === -1) {
        updatedCountries = [...updatedCountries, country];
      } else {
        updatedCountries[updatedCountryIndex] = country;
      }

      dispatch(updateSupportedCountries(updatedCountries));
      dispatch(systemAdminActions.updateCountryCodeMappingByID(countryToUpdate._id, country));

      Toast({
        type: 'open',
        message: t('countryUpdateSuccess'),
      });

      history.push(INTERNAL_LINKS.GLOBAL_SETTINGS);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('countryUpdateError'),
        });
      });
    }

    setIsUpdatingCountry(false);
  };

  const handleCountryDelete = async data => {
    setIsDeletingCountry(true);

    try {
      await deleteCountryCodeMapping(countryToUpdate._id, data);

      setIsDeleteConfirmationVisible(false);

      dispatch(
        updateSupportedCountries(supportedCountries.filter(c => c._id !== countryToUpdate._id)),
      );
      dispatch(
        systemAdminActions.updateCountryCodeMapping(
          countryCodeMappings.filter(c => c._id !== countryToUpdate._id),
        ),
      );

      Toast({
        type: 'open',
        message: t('countryDeleteSuccess'),
      });

      history.push(INTERNAL_LINKS.GLOBAL_SETTINGS);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('countryDeleteError'),
        });
      });
    }

    setIsDeletingCountry(false);
  };

  const loadCountryCodeMapping = async () => {
    const { id } = match.params;
    try {
      const country = await fetchCountryCodeMappingByID(id);

      if (country) {
        setCountryToUpdate(country);
      } else {
        history.push(INTERNAL_LINKS.GLOBAL_SETTINGS);
      }
    } catch (error) {
      history.push(INTERNAL_LINKS.GLOBAL_SETTINGS);
    }
  };

  useEffect(() => {
    loadCountryCodeMapping();

    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer
      loading={!supportedCountries || !countryToUpdate}
      title={
        <>
          <Helmet>
            <title>{formatPageTitle('Update Country')}</title>
          </Helmet>
          <PageBreadcrumbs
            items={[
              { label: t('Rates'), onClick: () => history.push(INTERNAL_LINKS.GLOBAL_SETTINGS) },
              { label: countryToUpdate?.name },
            ]}
          />
        </>
      }
    >
      <Row justify="center">
        <Col flex="832px">
          <Box>
            <CountryForm
              t={t}
              isUpdate
              isSubmitting={isUpdatingCountry || isDeletingCountry}
              initialValues={countryToUpdate}
              onSubmit={handleCountryUpdate}
              onDelete={() => setIsDeleteConfirmationVisible(true)}
              onCancel={() => history.push(INTERNAL_LINKS.GLOBAL_SETTINGS)}
              supportedCountries={Array.isArray(supportedCountries) ? supportedCountries : []}
            />
          </Box>
        </Col>
      </Row>

      <ConfirmModal
        title={t('deleteCountry?')}
        loading={isDeletingCountry}
        visible={isDeleteConfirmationVisible}
        onOk={handleCountryDelete}
        onCancel={() => setIsDeleteConfirmationVisible(false)}
        okText={t('Delete')}
        message={<Text size="sm">{t('sureYouWantToDeleteCountry')}</Text>}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(UpdateCountryView));
