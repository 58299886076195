import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { USER_API } from '../api/user';
import { hasCompanyAdminRole } from '../utils/roles';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';

const useAdminsAndManagersUsersLists = () => {
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const companyAdminsAndManagersListQuery = useQuery({
    placeholderData: { users: [] },
    enabled: !!currentCompany?._id,
    queryKey: ['getCompanyAdminsAndManagers', currentCompany?._id],
    queryFn: () => USER_API.getCompanyAdminsAndManagers(currentCompany._id),
  });

  const [companyManagersList, companyAdminsList, groupManagersLists] = useMemo(() => {
    if (Array.isArray(companyAdminsAndManagersListQuery?.data?.users)) {
      const managersList = [];
      const adminsList = [];

      companyAdminsAndManagersListQuery.data.users.forEach(user => {
        if (hasCompanyAdminRole({ profile: user })) {
          adminsList.push(user);
        } else {
          managersList.push(user);
        }
      });

      return [managersList, adminsList, companyAdminsAndManagersListQuery.data.users];
    }

    return [[], [], []];
  }, [companyAdminsAndManagersListQuery]);

  return {
    groupManagersLists,
    companyManagersList,
    companyAdminsList,
    companyAdminsAndManagersListQuery,
  };
};

export default useAdminsAndManagersUsersLists;
