import React, { useMemo } from 'react';

import Text from './index';

const AmountText = props => {
  const { amount, ...rest } = props;

  const cleanAmount = useMemo(() => {
    return parseFloat(`${amount}`.replace(/[^\d.-]/g, ''));
  }, [amount]);

  const amountWithoutNegativeSymbol = useMemo(() => {
    return `${amount}`.replace(/-/g, '');
  }, [amount]);

  return (
    <Text color={cleanAmount < 0 ? 'red' : 'primary'} {...rest}>
      {cleanAmount < 0 ? `(${amountWithoutNegativeSymbol})` : amount}
    </Text>
  );
};

export default AmountText;
