import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { handleApiErrors } from '../../../api/axiosInstance';
import Box from '../../../components/Box';
import FreeTrialSettingsForm from '../../../components/Forms/FreeTrialSettingsForm';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';

const FreeTrialSettingsSection = props => {
  const { t, globalSettings, onSettingsSave } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async values => {
    setIsSubmitting(true);
    try {
      await onSettingsSave(values);

      Toast({
        type: 'open',
        message: t('freeTrialSettingUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('freeTrialSettingUpdateError'),
        });
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Row id="freeTrialSetting">
      <Col flex={1}>
        <Box>
          <Text variant="h5">{t('freeTrialSetting')}</Text>
          <br />
          <FreeTrialSettingsForm
            t={t}
            isSubmitting={isSubmitting}
            initialValues={globalSettings}
            onSubmit={handleSubmit}
          />
        </Box>
      </Col>
    </Row>
  );
};

export default FreeTrialSettingsSection;
