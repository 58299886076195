import { Card } from 'antd';
import moment from 'moment-timezone';
import React from 'react';

import { momentFormat } from '../../utils/common';

export const FinishPopupContent = ({ point }) => {
  return (
    <Card bordered={false} bodyStyle={{ padding: 0, textAlign: 'left' }}>
      <strong>{point.formattedAddress}</strong>
      {point.journeyEndTs ? (
        <div>
          <strong>End time:</strong> {momentFormat(point.journeyEndTs, 'YYYY-MM-DD hh:mm a zz')}
        </div>
      ) : null}
      {point.journeyEndTs && point.journeyStartTs ? (
        <div>
          <strong>Duration:</strong>{' '}
          {Math.round(
            moment.duration(moment(point.journeyEndTs).diff(point.journeyStartTs)).asMinutes(),
          )}{' '}
          min.
        </div>
      ) : null}
      {point.journeyDistance ? (
        <div>
          <strong>Distance:</strong> {point.journeyDistance}
        </div>
      ) : null}
      <div>
        <strong>Idle time:</strong> {point.journeyIdleTime || 0}
      </div>
    </Card>
  );
};
