import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { HelpIcon } from '../../components';
import Box from '../../components/Box';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import LinkText from '../../components/Text/LinkText';
import { IMAGES, INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import { momentFormat, momentTimezone } from '../../utils/common';
import { formatNumberWithCurrency, parseTotalAmountsArray } from '../../utils/numbers';
import { cleanQueryParams } from '../../utils/queryParams';
import { hasCompanyAdminRole } from '../../utils/roles';
import { selectStoreCompanySettings, selectStoreCurrentAuthUser } from '../../utils/storeSelectors';
import classNames from './style.module.scss';

const SUBMISSION_TYPE = 'submissions';
const APPROVALS_TYPE = 'approvals';
const PAYMENTS_TYPE = 'payments';
const COMPLIANCE_ALERT_TYPE = 'complianceAlerts';

const TimelineBox = props => {
  const { t, timelineItem, groupId, fromDate, endDate } = props;

  const companySettings = useSelector(selectStoreCompanySettings);
  const authUser = useSelector(selectStoreCurrentAuthUser);

  if (!timelineItem) return null;

  const renderLock = () => <img src={IMAGES.GREEN_LOCK_ICON} alt="Lock" />;

  const renderIcon = item => {
    switch (item.type) {
      case SUBMISSION_TYPE:
      case APPROVALS_TYPE:
        const isAfterDate = momentTimezone().unix() >= momentTimezone(item.date).unix();
        if (isAfterDate) return renderLock();
        return null;
      case PAYMENTS_TYPE:
        const daysLeft = momentTimezone(item.date).diff(momentTimezone(), 'days');
        const hoursLeft = momentTimezone(item.date).diff(momentTimezone(), 'hours');

        if (hoursLeft <= 0) return renderLock();

        return (
          <Box noShadow style={{ background: '#DAF0E0', marginTop: 5 }} padding="1px">
            <Text size="xs" variant="strong">
              {daysLeft <= 0
                ? t('hoursLeft', { count: hoursLeft })
                : t('daysLeft', { count: daysLeft })}
            </Text>
          </Box>
        );
      default:
        return null;
    }
  };

  const renderTitle = item => {
    switch (item.type) {
      case PAYMENTS_TYPE:
        return (
          <Row align="middle" gutter={6} wrap={false} style={{ marginBottom: 5 }}>
            <Col>
              <Text variant="h4" style={{ marginBottom: 0 }}>
                {t(timelineItem.type)}
              </Text>
            </Col>

            <Col>
              <HelpIcon hint={t('paymentsCanChangeUntilTripSubmissionDueDateIsReached')} />
            </Col>
          </Row>
        );
      default:
        return (
          <Text variant="h4" style={{ marginBottom: 5 }}>
            {t(timelineItem.type)}
          </Text>
        );
    }
  };

  const renderDescription = item => {
    switch (item.type) {
      case SUBMISSION_TYPE:
      case APPROVALS_TYPE:
      case PAYMENTS_TYPE:
        return t('dueOnDate', { date: momentFormat(item.date, 'MM/DD/YYYY') });
      case COMPLIANCE_ALERT_TYPE:
        return t('Deadline 30 Days from start');
      default:
        return null;
    }
  };

  const renderContent = item => {
    switch (item.type) {
      case SUBMISSION_TYPE:
        return (
          <>
            <Text variant="strong" renderAs="p">
              {t('nOutOfnUsersSubmittedTrips', {
                count: item.sumOfUsers,
                submittedCount: item.submitted,
              })}
            </Text>
          </>
        );
      case APPROVALS_TYPE:
        return (
          <>
            <Text variant="strong" renderAs="p">
              {t('nOutOfnApprovedReceipts', {
                approvedCount: item.approved,
                count: item.sumOfReceipts,
              })}
            </Text>
          </>
        );
      case PAYMENTS_TYPE:
        const parsedTotalPayment = parseTotalAmountsArray(item.totalPayment);
        const parsedPreviousPayment = parseTotalAmountsArray(item.previousPayment);
        return (
          <>
            <Row wrap={false} gutter={[8, 8]} align="middle">
              <Col>
                <Text>
                  {t('totalPayment')}:{' '}
                  {formatNumberWithCurrency(parsedTotalPayment.total, parsedTotalPayment.currency)}
                </Text>
              </Col>

              {item.totalPayment.length > 1 && (
                <Col>
                  <HelpIcon hint={parsedTotalPayment.amounts} />
                </Col>
              )}
            </Row>
            <Row wrap={false} gutter={[8, 8]} align="middle">
              <Col>
                <Text>
                  {t('prevPayment')}:{' '}
                  {formatNumberWithCurrency(
                    parsedPreviousPayment.total,
                    parsedPreviousPayment.currency,
                  )}
                </Text>
              </Col>

              {item.previousPayment.length > 1 && (
                <Col>
                  <HelpIcon hint={parsedPreviousPayment.amounts} />
                </Col>
              )}
            </Row>
          </>
        );
      case COMPLIANCE_ALERT_TYPE:
        return (
          <>
            <Text>{t('insuranceUserIssues', { count: 0 })}</Text>
            <Text>{t('vehicleUserIssues', { count: 0 })}</Text>
          </>
        );
      default:
        return null;
    }
  };

  const renderBottomCallback = item => {
    let redirectTo = '#';
    let callbackText = 'View';

    if (item.type === SUBMISSION_TYPE) {
      callbackText = t('viewSubmittedTrips');
      const pageRoot = companySettings?.hideReceipts
        ? INTERNAL_LINKS.REIMBURSEMENT
        : INTERNAL_LINKS.RECEIPTS;

      redirectTo = `${pageRoot}?${cleanQueryParams({
        status: STATUS_LIST().Status.PENDING,
        groupId,
        ...(companySettings?.hideReceipts
          ? { period: fromDate && endDate ? JSON.stringify({ fromDate, endDate }) : null }
          : { startDateRange: fromDate, endDateRange: endDate }),
      })}`;
    }
    if (item.type === APPROVALS_TYPE) {
      callbackText = t('approveReimbursements');

      const pageRoot = companySettings?.hideReceipts
        ? INTERNAL_LINKS.REIMBURSEMENT
        : INTERNAL_LINKS.RECEIPTS;

      redirectTo = `${pageRoot}?${cleanQueryParams({
        status: STATUS_LIST().Status.PENDING,
        groupId,
        ...(companySettings?.hideReceipts
          ? { period: fromDate && endDate ? JSON.stringify({ fromDate, endDate }) : null }
          : { startDateRange: fromDate, endDateRange: endDate }),
      })}`;
    }

    if (item.type === PAYMENTS_TYPE) {
      if (!hasCompanyAdminRole(authUser)) return null;

      callbackText = t('viewPayrollFile');

      redirectTo = `${INTERNAL_LINKS.PAYROLL_FILE_HISTORY}?${cleanQueryParams({
        startDateRange: fromDate,
        endDateRange: endDate,
      })}`;
    }

    if (item.type === COMPLIANCE_ALERT_TYPE) {
      callbackText = t('viewAlerts');

      redirectTo = '#';
    }

    return (
      <Link to={redirectTo}>
        <LinkText className={classNames.callback} renderAs="p" variant="strong">
          {callbackText}
        </LinkText>
      </Link>
    );
  };

  return (
    <Box className={classNames.timelineBox}>
      <div className={classNames.abosoluteIcon}>{renderIcon(timelineItem)}</div>

      <div>
        {renderTitle(timelineItem)}

        <FadedText size="sm">{renderDescription(timelineItem)}</FadedText>

        <div className={classNames.timelineBoxContent}>{renderContent(timelineItem)}</div>
      </div>

      {renderBottomCallback(timelineItem)}
    </Box>
  );
};

export default TimelineBox;
