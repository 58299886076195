import { Radio as AntdRadio } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import classNames from './style.module.scss';

/**
 * Custom Radio component based on the Ant Design Radio
 * but following Kliks style guide and branding
 *
 * @param {object} props - Component props
 */
const Radio = props => {
  const { children, size, className, ...rest } = props;

  const newClassName = [
    'custom-radio',
    classNames.customRadio,
    classNames[`radio-${size}`],
    className,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <AntdRadio {...rest} className={newClassName}>
      {children}
    </AntdRadio>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
};

Radio.defaultProps = {
  className: '',
  size: 'small',
};

Radio.Group = AntdRadio.Group;
Radio.Button = AntdRadio.Button;

export default Radio;
