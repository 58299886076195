import { get, last } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { STATUS_LIST } from '../enum';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';

const useDwollaCompanyInfo = () => {
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const dwollaPaymentInfo = useMemo(() => {
    return get(currentCompany, 'dwollaPaymentInfo', {});
  }, [currentCompany]);

  const isCustomerVerified = useMemo(() => {
    return dwollaPaymentInfo?.controllerInfo?.customerStatus === STATUS_LIST().Status.VERIFIED;
  }, [dwollaPaymentInfo]);

  const companyFundingSources = useMemo(() => {
    const fundingSources = get(dwollaPaymentInfo, 'controllerInfo.fundingSources');
    return Array.isArray(fundingSources) ? fundingSources : [];
  }, [dwollaPaymentInfo]);

  const companyBalanceFundingSource = useMemo(() => {
    return companyFundingSources.find(source => source?.fundingSourceType === 'balance');
  }, [companyFundingSources]);

  const companyOtherFundingSource = useMemo(() => {
    return last(companyFundingSources.filter(source => source?.fundingSourceType !== 'balance'));
  }, [companyFundingSources]);

  const companyFundingSourceRemovalInfo = useMemo(() => {
    return get(dwollaPaymentInfo, 'controllerInfo.fundingSourceRemoval');
  }, [dwollaPaymentInfo]);

  const isCompanyFundingSourceVerified = useMemo(() => {
    return companyOtherFundingSource?.fundingSourceStatus === STATUS_LIST().Status.VERIFIED;
  }, [companyOtherFundingSource]);

  return {
    dwollaPaymentInfo,
    isCustomerVerified,
    isCompanyFundingSourceVerified,

    companyFundingSources,
    companyBalanceFundingSource,
    companyOtherFundingSource,
    companyFundingSourceRemovalInfo,
  };
};

export default useDwollaCompanyInfo;
