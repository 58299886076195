import { Col, List, Row } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { getUserFullName, setModalFullscreenWidth } from '../../../utils/common';
import { Modal } from '../..';
import Button from '../../Button';
import AnimatedCheckmark from '../../Lottie/AnimatedCheckmark';
import Text from '../../Text';
import FadedText from '../../Text/FadedText';

const SharedItineraryModal = props => {
  const { t, visible, user, clients, onCancel } = props;

  const SHARED_WITH = useMemo(() => {
    const users = [];
    if (user?.email) {
      users.push({ name: getUserFullName(user), email: user.email });
    }

    if (Array.isArray(clients)) {
      clients.forEach(client => {
        if (client.clientId && client.clientId.email !== user.email) {
          users.push({
            name: getUserFullName(client.clientId),
            email: client.clientId.email,
          });
        }
      });
    }

    return users;
  }, [clients, user]);

  return (
    <Modal
      centered
      footer={null}
      destroyOnClose
      onCancel={onCancel}
      visible={visible}
      width={setModalFullscreenWidth(600)}
    >
      <Row justify="center">
        <AnimatedCheckmark width="120px" height="120px" />
      </Row>

      <br />

      <Row gutter={[16, 16]} justify="center">
        <Col flex="calc(100% - 10px)">
          <Row justify="center">
            <Text variant="h4">{t('itinerarySentTo')}</Text>
          </Row>

          <List
            size="small"
            itemLayout="horizontal"
            dataSource={SHARED_WITH}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={<Text variant="b">{item.name}</Text>}
                  description={<FadedText>{item.email}</FadedText>}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>

      <br />

      <Row justify="center">
        <Link to={INTERNAL_LINKS.DASHBOARD}>
          <Button>{t('Back to Dashboard')}</Button>
        </Link>
      </Row>
    </Modal>
  );
};

export default SharedItineraryModal;
