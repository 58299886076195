import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '../Tooltip';
import classNames from './style.module.scss';

/**
 * A Bar to display a summary of the different trip's Tracking Sources.
 */
const TripSourcePercentSummaryBar = props => {
  const { t, mobilePercent, vehicleDevicePercent, manualPercent } = props;

  const renderBar = (text, classname, percent) => (
    <div className={classname} style={{ width: `${percent}%` }}>
      <Tooltip title={`${text}: ${percent}%`}>
        <span style={{ display: 'block', width: '100%', visibility: 'hidden' }}>{text}</span>
      </Tooltip>
    </div>
  );

  return (
    <div className={classNames.wrapperBar}>
      {mobilePercent > 0 && renderBar(t('mobileApp'), classNames.mobileBar, mobilePercent)}
      {vehicleDevicePercent > 0 &&
        renderBar(t('vehicleDevice'), classNames.vehicleBar, vehicleDevicePercent)}
      {manualPercent > 0 && renderBar('manuallyCreated', classNames.manualBar, manualPercent)}
    </div>
  );
};

TripSourcePercentSummaryBar.propTypes = {
  mobilePercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  vehicleDevicePercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  manualPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default TripSourcePercentSummaryBar;
