import { Row } from 'antd';
import React from 'react';

import { formatNumberWithCurrency } from '../../../utils/numbers';
// import BottomBorderTextRows from '../../BottomBorderTextRows';
import Text from '../../Text';

const FavrFixedRateSummary = props => {
  const { t, currency, fixedRate } = props;

  return (
    <div>
      <Row>
        <Text size="xl">
          <Text size="xl" variant="strong">
            {formatNumberWithCurrency(fixedRate || 0, currency || 'USD')}
          </Text>
          {' / '}
          {t('monthly')}
        </Text>
      </Row>

      <br />

      {/* <BottomBorderTextRows
        rowContent={[
          {
            label: t('insurance'),
            value: formatCurrencyToCompanyLocale('80.49'),
          },
          {
            label: t('depreciation'),
            value: formatCurrencyToCompanyLocale('152.16'),
          },
          {
            label: t('taxesAndFees'),
            value: formatCurrencyToCompanyLocale('45.20'),
          },
        ]}
      /> */}
    </div>
  );
};

export default FavrFixedRateSummary;
