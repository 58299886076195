import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle } from '../../utils/common';
import LimitedOneReceiptReimbursementsView from './limit-one-receipt-reimbursements';
import UserReimbursements from './reimbursements';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Reimbursements')}</title>
  </Helmet>
);

const ReimbursementPageSwitcher = props => {
  const companySettings = useSelector(store => store.common.currentCompany.companySettingId);

  if (companySettings?.settings?.hideReceipts) {
    return (
      <>
        <PageHelmet />
        <LimitedOneReceiptReimbursementsView {...props} />;
      </>
    );
  }

  return (
    <>
      <PageHelmet />
      <UserReimbursements {...props} />;
    </>
  );
};

export default withNamespaces()(withAuthentication(ReimbursementPageSwitcher));
