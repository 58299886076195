import { Col, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import StatusTag from '../../../../components/Tag/StatusTag';
import Text from '../../../../components/Text';
import { STATUS_LIST } from '../../../../enum';
import useDwollaCompanyInfo from '../../../../hooks/useDwollaCompanyInfo';
import useModal from '../../../../hooks/useModal';
import { formatPageTitle } from '../../../../utils/common';
import AchCustomerVerificationSubmittedModal from './AchCustomerVerificationSubmittedModal';
import BeneficialOwnersBox from './BeneficialOwnersBox';
import BusinessAndControllerStepBox from './BusinessAndControllerStepBox';

const AchVerificationView = props => {
  const { t } = props;

  const [isVerificationCompleted, openVerificationCompletedModal] = useModal();
  const { dwollaPaymentInfo } = useDwollaCompanyInfo();

  return (
    <div>
      <Helmet>
        <title>{formatPageTitle('ACH Verifications')}</title>
      </Helmet>

      <Row wrap={false} gutter={16}>
        <Col>
          <Text variant="h4" size="sm" style={{ marginBottom: 16 }}>
            {t('achCustomVerification')}
          </Text>
        </Col>

        <Col>
          <StatusTag
            status={
              dwollaPaymentInfo?.controllerInfo?.customerStatus || STATUS_LIST().Status.NOT_STARTED
            }
          />
        </Col>
      </Row>

      <BusinessAndControllerStepBox
        t={t}
        customerId={dwollaPaymentInfo?.controllerInfo?.customerId}
        controllerInfo={dwollaPaymentInfo?.controllerInfo}
      />

      <br />

      {dwollaPaymentInfo?.controllerInfo?.customerStatus && (
        <BeneficialOwnersBox
          t={t}
          onStepComplete={openVerificationCompletedModal}
          controllerInfo={dwollaPaymentInfo?.controllerInfo}
          beneficialOwners={dwollaPaymentInfo?.beneficialOwners}
        />
      )}

      <AchCustomerVerificationSubmittedModal t={t} visible={isVerificationCompleted} />
    </div>
  );
};

export default withNamespaces()(AchVerificationView);
