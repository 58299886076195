import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../api/axiosInstance';
import { fetchItineraries } from '../api/itinerary';
import Toast from '../components/Toast';
import { sortColumnByDateString } from '../utils/tables';
import useTablePagination from './useTablePagination';

const useItineraries = ({ t, initialFilters }) => {
  const [itineraryFilters, setItineraryFilters] = useState(initialFilters || {});

  const {
    paginationConfig: itinerariesPaginationConfig,
    handlePageChange: handleItinerariesPageChange,
    handleTotalUpdate,
  } = useTablePagination();

  const handleFilterUpdate = useCallback(
    (newFilters = {}) => {
      setItineraryFilters(current => ({ ...current, ...newFilters }));
      handleItinerariesPageChange(1);
    },
    [handleItinerariesPageChange],
  );

  const itinerariesQuery = useQuery({
    enabled: !!itineraryFilters.fromDate && !!itineraryFilters.toDate,
    queryKey: [
      'fetchItineraries',
      ...Object.values(itineraryFilters),
      itinerariesPaginationConfig.current,
      itinerariesPaginationConfig.pageSize,
    ],
    queryFn: () =>
      fetchItineraries(
        itineraryFilters.fromDate,
        itineraryFilters.toDate,
        itinerariesPaginationConfig.current,
        itinerariesPaginationConfig.pageSize,
      ),
    placeholderData: { documents: [], totalCount: 0 },
    select: ({ totalCount, documents }) => ({
      documents: documents.sort(sortColumnByDateString('dateFrom').sorter),
      totalCount,
    }),
    onSuccess: ({ totalCount }) => handleTotalUpdate(totalCount),
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchItineraryError'),
        });
      });
    },
  });

  return {
    itinerariesQuery,
    itinerariesPaginationConfig,
    handleFilterUpdate,
    handleItinerariesPageChange,
  };
};

export default useItineraries;
