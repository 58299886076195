import { Empty } from 'antd';
import React from 'react';

import Text from '../../Text';
import BusinessVehicleSummary from '../BusinessVehicleSummary';
import classNames from './styles.module.scss';

const BusinessVehicleList = props => {
  const {
    t,
    vehicles,
    deletingVehicleIds,
    onEdit,
    onDelete,
    isConfirming,
    onConfirm,
    onMakeDefault,
    requiresConfirmation,
  } = props;

  if (vehicles.length === 0) {
    return (
      <Empty
        description={
          <Text variant="strong" size="sm" textAlign="center">
            {t('noVehicleData')}
          </Text>
        }
      />
    );
  }

  return (
    <div className={classNames.vehicleSummaryList}>
      {vehicles.map(vehicle => (
        <div key={vehicle._id} className={classNames.vehicleSummaryItem}>
          <BusinessVehicleSummary
            t={t}
            vehicle={vehicle}
            requiresConfirmation={requiresConfirmation}
            isDeleting={deletingVehicleIds.includes(vehicle._id)}
            onEdit={typeof onEdit === 'function' ? () => onEdit(vehicle._id) : undefined}
            onDelete={typeof onDelete === 'function' ? () => onDelete(vehicle._id) : undefined}
            onMakeDefault={
              typeof onMakeDefault === 'function' ? () => onMakeDefault(vehicle._id) : undefined
            }
            isConfirming={isConfirming}
            onConfirm={onConfirm}
          />
        </div>
      ))}
    </div>
  );
};

BusinessVehicleList.defaultProps = {
  deletingVehicleIds: [],
};

export default BusinessVehicleList;
