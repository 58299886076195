import { isNumber, isUndefined } from 'lodash';

import { cleanQueryParams } from '../utils/queryParams';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';
import { formatAddressIntoString } from '../utils/trips';
import { handleApiCalls, performApiCallIfCompanySubIsActive } from './axiosInstance';
import BaseAPI from './baseAPI';

/**
 * Performs an API call to upload a CSV file with company locations to be imported
 *
 * @param {File} csvFile CSV file to import
 * @param {Function=} onUploadProgress Upload progress handler
 * @returns {boolean} Import successful?
 */
export const importLocationsFromCSV = async (csvFile, onUploadProgress) => {
  const result = await performApiCallIfCompanySubIsActive(
    'post',
    `${process.env.REACT_APP_HOST_API}location/import`,
    csvFile,
    { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  );

  return result.data.success;
};

class LocationsAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'locations' });
  }

  async fetchLocationsAutocomplete(addressToSearch, limitedToCountryCode, userGeoLocation) {
    const country = limitedToCountryCode || selectStoreCurrentCompany()?.address?.country;
    const cleanParams = cleanQueryParams({
      query: addressToSearch,
      country,
      near:
        isNumber(userGeoLocation?.latitude) && isNumber(userGeoLocation?.longitude)
          ? `${userGeoLocation.latitude},${userGeoLocation.longitude}`
          : undefined,
    });
    const url = `${this.baseUrl}/autocomplete?${cleanParams}`;
    const result = await handleApiCalls('get', url);
    return result.data
      .filter(
        address =>
          !!address.street && !!address.city && !!address.stateCode && !isUndefined(address.number),
      )
      .map(address => ({
        address,
        value: formatAddressIntoString({
          streetOne: [address.number, address.street].join(' '),
          city: address.city,
          state: address.stateCode,
          country: address.countryCode,
          postalCode: address.postalCode,
        }),
      }));
  }

  async fetchLocationsGoogleMapsAutocomplete(
    addressToSearch,
    limitedToCountryCode,
    userGeoLocation,
  ) {
    const country = limitedToCountryCode || selectStoreCurrentCompany()?.address?.country;
    const cleanParams = cleanQueryParams({
      query: addressToSearch,
      country,
      near:
        isNumber(userGeoLocation?.latitude) && isNumber(userGeoLocation?.longitude)
          ? `${userGeoLocation.latitude},${userGeoLocation.longitude}`
          : undefined,
    });
    const url = `${this.baseUrl}/autocomplete/google-maps?${cleanParams}`;
    const result = await handleApiCalls('get', url);
    return result.data
      .filter(address => !!address.description)
      .map(address => ({ address: { ...address, isGoogle: true }, value: address.description }));
  }

  async fetchGoogleMapLocationDetails(placeId) {
    const cleanParams = cleanQueryParams({ placeId });
    const url = `${this.baseUrl}/place/details/google-maps?${cleanParams}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  }

  async fetchAddressFromCoordinates(latitude, longitude) {
    const queryParams = cleanQueryParams({ latitude, longitude });
    const result = await handleApiCalls('get', `${this.baseUrl}/reverse-geocoding?${queryParams}`);

    return result.data;
  }

  /**
   * @param {string} addressString
   * @param {string} country
   */
  async fetchAddressDetailsFromString(addressString, country) {
    const queryParams = cleanQueryParams({ country, query: addressString });
    const response = await handleApiCalls(
      'get',
      `${this.baseUrl}/forward-geocoding?${queryParams}`,
    );
    return response.data;
  }
}

export default LocationsAPI;
