import { flatten, get } from 'lodash';
import { useMemo } from 'react';
import { useInfiniteQuery as useInfiniteReactQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';

/**
 *
 * @param {import('react-query').UseQueryOptions} queryOptions
 */
const useInfiniteQuery = (
  queryOptions = {},
  countKey = 'count',
  itemKeyPath,
  itemsPerPage = 25,
) => {
  const query = useInfiniteReactQuery({
    onError: error => handleApiErrors(error.response),
    ...queryOptions,
    placeholderData: { pages: [] },
    queryKey: ['infinite', ...queryOptions.queryKey],
    getNextPageParam: (lastPage, pages) => {
      const totalCount = get(lastPage, countKey, 0);
      const totalPages = Math.ceil(totalCount / itemsPerPage);
      if (pages.length < totalPages) return pages.length + 1;
    },
  });

  const fullyFlatPages = useMemo(() => flatten(query?.data?.pages), [query]);

  const flatPages = useMemo(() => {
    let optionsArray = [];

    if (Array.isArray(query?.data?.pages)) {
      query.data.pages.forEach(item => {
        const itemToContact = itemKeyPath ? get(item, itemKeyPath) : item;
        optionsArray = optionsArray.concat(itemToContact);
      });
    }

    return optionsArray;
  }, [query, itemKeyPath]);

  return {
    query,
    flatPages,
    fullyFlatPages,
  };
};

export default useInfiniteQuery;
