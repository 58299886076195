export const START_FETCHING_CONTACTS = 'START_FETCHING_CONTACTS';
export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS';
export const UPDATE_CONTACT_BY_ID = 'UPDATE_CONTACT_BY_ID';
export const ADD_NEW_CONTACT = 'ADD_NEW_CONTACT';

export function emitStartFetchingContacts() {
  return {
    type: START_FETCHING_CONTACTS,
  };
}

export function emitFetchContacts(forceFetch = false) {
  return {
    type: FETCH_CONTACTS,
    data: { forceFetch },
  };
}

export function emitUpdateContacts(contacts) {
  return {
    type: UPDATE_CONTACTS,
    data: { contacts },
  };
}

export function emitUpdateContactByID(contact) {
  return {
    type: UPDATE_CONTACT_BY_ID,
    data: { contact },
  };
}

export function emitAddNewContact(contact) {
  return {
    type: ADD_NEW_CONTACT,
    data: { contact },
  };
}
