import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue } from '../../../utils/common';
import { renderBooleanCheckmarks } from '../../../utils/tables';
import CustomTable from '../../Table';
import LinkText from '../../Text/LinkText';

const RatesTable = props => {
  const { t, dataSource, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(rate => {
        return (
          checkIfStringContainsValue(rate.name, searchTerm) ||
          checkIfStringContainsValue(rate.groups, searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: true,
      render: (actions, data) => (
        <Link to={`${INTERNAL_LINKS.RATE_DETAILS}/${data._id}`}>
          <LinkText variant="strong" size="sm">
            {data.name}
          </LinkText>
        </Link>
      ),
    },
    {
      title: t('active'),
      key: 'active',
      ...renderBooleanCheckmarks('active'),
      sorter: true,
    },
    {
      title: t('effectiveDate'),
      dataIndex: 'effective_date',
      key: 'effectiveDate',
      sorter: true,
    },
    {
      title: t('Groups'),
      dataIndex: 'groups',
      key: 'groups',
      sorter: true,
    },
    {
      title: t('Users'),
      dataIndex: 'usersCount',
      key: 'usersCount',
      sorter: true,
    },
    {
      title: t('default'),
      key: 'default',
      ...renderBooleanCheckmarks('default'),
      sorter: true,
    },
  ];

  return (
    <CustomTable
      showSearchInput={false}
      onSearchTermChange={setSearchTerm}
      {...rest}
      dataSource={filteredDataSource}
      columns={COLUMNS}
      scroll={{ x: 970 }}
    />
  );
};

RatesTable.propTypes = {};

export default RatesTable;
