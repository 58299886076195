import { Row } from 'antd';
import React from 'react';

// import { Trans } from 'react-i18next';
import { ConfirmModal } from '../..';
import Text from '../../Text';

const PaymentScheduleDeleteConfirmationModal = props => {
  const { t, visible, isDeletingSchedule, onDelete, onCancel, group, defaultGroup } = props;

  if (!group || !defaultGroup) return null;

  return (
    <ConfirmModal
      loading={isDeletingSchedule}
      visible={visible}
      title={t('deletePaymentSchedule?')}
      onOk={onDelete}
      onCancel={onCancel}
      okText={t('yesDelete')}
      message={
        <div style={{ display: 'block' }}>
          {/* <Row style={{ marginBottom: 15 }}>
            <Text size="sm" renderAs="p">
              <Trans
                t={t}
                i18nKey="groupAssociatedWithScheduleWillBeDeleted"
                components={[<Text variant="strong">DummyText</Text>]}
                values={{ groupName: group.name }}
              />
            </Text>
          </Row>

          <Row style={{ marginBottom: 15 }}>
            <Text size="sm" renderAs="p">
              <Trans
                t={t}
                i18nKey="usersWillBeAutomaticallyAddedToDefaultGroup"
                components={[<Text variant="strong">DummyText</Text>]}
                count={group.users.length}
                values={{
                  defaultGroupName: defaultGroup.name,
                }}
              />
            </Text>
          </Row> */}

          <Row style={{ marginBottom: 15 }}>
            <Text size="sm" renderAs="p">
              {t('sureYouWantToDeletePaymentSchedule')}
            </Text>
          </Row>
        </div>
      }
    />
  );
};

export default PaymentScheduleDeleteConfirmationModal;
