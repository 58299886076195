import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { emitFetchTimezones } from '../stores/actions/common';

const useSupportedTimezones = () => {
  const dispatch = useDispatch();
  const { timezones } = useSelector(state => state.common);

  useEffect(() => {
    dispatch(emitFetchTimezones());
  }, [dispatch]);

  return timezones;
};

export default useSupportedTimezones;
