import { createReducer } from '../../utils/storeCreators';
import { SET_REG_STATUS } from '../actions/user';
import initialState from '../initialState';

const userReducer = createReducer({
  initialState: initialState.user,
  reducer: (state, action) => {
    let user = state;
    switch (action.type) {
      case SET_REG_STATUS:
        user.regStatus = action.data.regStatus;
        return { ...state, ...user };
      default:
        return state;
    }
  },
});

export default userReducer;
