import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

export const fetchItineraries = async (fromDate, toDate, pageNumber, pageSize) => {
  const queryParams = cleanQueryParams({
    fromDate,
    toDate,
    range: formatQueryRange(pageNumber, pageSize),
  });
  const url = `${process.env.REACT_APP_HOST_API}itinerary?${queryParams}`;
  const response = await handleApiCalls('get', url);

  return response.data;
};

export const fetchItineraryDetails = async id => {
  const url = `${process.env.REACT_APP_HOST_API}itinerary/${id}`;
  const response = await handleApiCalls('get', url);

  return response.data.itinerary;
};

export const fetchContactItineraryHistory = async (
  crmContactId,
  numberOfPage = 1,
  numberOfRecord = 10,
) => {
  const url = `${process.env.REACT_APP_HOST_API}itinerary/getItineraryByCRMContact`;
  const response = await handleApiCalls('post', url, {
    crmContactId,
    numberOfPage,
    numberOfRecord,
  });

  return response.data;
};

/**
 *
 * @param {object} params
 * @param {string} params.dateFrom
 * @param {string} params.dateTo
 * @param {string} params.userId
 * @param {string} params.startAddress
 *
 * @param {object[]} params.clientDetails
 * @param {string} params.clientDetails.crmContactId
 * @param {string} params.clientDetails.clientAddress
 * @param {object[]} params.destinationAddresses
 *
 * @param {string} params.destinationAddresses.address
 * @param {string} params.destinationAddresses.reachingTime
 * @param {string} params.destinationAddresses.visitNotes
 * @param {string} params.destinationAddresses.itineraryNotes
 */
export const createItinerary = async params => {
  const url = `${process.env.REACT_APP_HOST_API}itinerary`;
  const response = await handleApiCalls('post', url, { ...params, tripOptimizationNeeded: true });

  return response.data.data;
};

/**
 *
 * @param {string} itineraryID
 * @param {object} params
 * @param {string} params.dateFrom
 * @param {string} params.dateTo
 * @param {string} params.userId
 * @param {string} params.startAddress
 *
 * @param {object[]} params.clientDetails
 * @param {string} params.clientDetails.crmContactId
 * @param {string} params.clientDetails.clientAddress
 * @param {object[]} params.destinationAddresses
 *
 * @param {string} params.destinationAddresses.address
 * @param {string} params.destinationAddresses.reachingTime
 * @param {string} params.destinationAddresses.visitNotes
 * @param {string} params.destinationAddresses.itineraryNotes
 */
export const updateItinerary = async (itineraryID, params) => {
  const url = `${process.env.REACT_APP_HOST_API}itinerary/${itineraryID}`;
  const response = await handleApiCalls('put', url, { ...params });

  return response.data.data;
};

export const shareItinerary = async (itineraryID, message) => {
  const url = `${process.env.REACT_APP_HOST_API}itinerary/share/${itineraryID}`;
  const response = await handleApiCalls('post', url, { message });

  return response.data.data;
};
