import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class MeasureOneDataRequestsAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'measure-one-data-requests' });
  }

  /**
   * @param {object} data
   * @param {object} data.itemId
   * @param {object} data.coverageItemId
   */
  async updateSelectedInsuranceDocument(data) {
    await handleApiCalls('patch', `${this.baseUrl}/coverage-item`, data);
  }

  async fetchMeasureOneInsuranceDocumentPreview(id, itemId) {
    const response = await handleApiCalls('get', `${this.baseUrl}/${id}/items/document`, {
      params: { itemId },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    return URL.createObjectURL(response.data);
  }
}

export default MeasureOneDataRequestsAPI;
