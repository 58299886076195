import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Space } from 'antd';
import { ThreeInput } from 'components';
import Select from 'components/Select';
import { USER_REG_STATUS } from 'enum';
import { get } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { APPROVAL_CONFIG_VALUES, RATE_TYPES, SEMI_AUTO_VALUES } from '../../enum/Rates';
import useOnboardingRateForm from '../../hooks/useOnboardingRateForm';
import { ERROR_MESSAGE } from '../../utils/constants';
import { formatNumberWithCurrency } from '../../utils/numbers';
import { getApprovalSettingValue, isGovernmentFlatRate, isTieredRate } from '../../utils/rates';
import { selectStoreCompanySettings, selectStoreCountryByCode } from '../../utils/storeSelectors';
import Checkbox from '../Checkbox';
import FormItem from '../Form/FormItem';
import HorizontalLineDivider from '../HorizontalLineDivider';
import SpaceSpinner from '../SpaceSpinner';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Text from '../Text';
import LinkText from '../Text/LinkText';
import TextInput from '../TextInput';
import Toast from '../Toast';
import RadioCard from './RadioCard';
import classNames from './styles.module.scss';

const DISTANCE_LIMIT = 'distance';
const GPS_LIMIT = 'gps_percentage';
const REIMBURSEMENT_LIMIT = 'reimbursement';

const ReimbursementForm = ({ currentCompany, currentProduct, product, t, props }) => {
  const [form] = Form.useForm();
  const { isMobile } = useSelector(state => state.browser);
  const regStatus = useSelector(state => state.user.regStatus);
  const { supportedCountries } = useSelector(state => state.common);
  const [showCountrySelector, setShowCountrySelector] = useState(false);

  const [approvalSetting, setApprovalSetting] = useState(currentProduct.approvalSetting || []);
  const [selectedLimits, setSelectedLimits] = useState(
    Array.isArray(approvalSetting) ? approvalSetting.map(s => s.approvalType) : [],
  );
  const [distanceSetting, setDistanceSetting] = useState(
    getApprovalSettingValue(DISTANCE_LIMIT, approvalSetting),
  );
  const [gpsSetting, setGpsSetting] = useState(getApprovalSettingValue(GPS_LIMIT, approvalSetting));
  const [reimbursementSetting, setReimbursementSetting] = useState(
    getApprovalSettingValue(REIMBURSEMENT_LIMIT, approvalSetting),
  );

  const [userRate, setUserRate] = useState(
    currentProduct
      ? {
          ...currentProduct,
          approvalConfig: currentProduct.approvalConfig || 'auto',
          name: currentProduct.name,
          value:
            currentProduct.rates && currentProduct.rates.rateType === RATE_TYPES.GOVT
              ? currentProduct.rates && currentProduct.rates.govtRate
              : currentProduct.rates && currentProduct.rates.flatRate,
        }
      : {
          approvalConfig: 'auto',
          type: RATE_TYPES.GOVT,
        },
  );

  const {
    countryRate,
    country,
    bands,
    // Form State changers
    setCountry,
    setBands,
    // Form onChange handlers
    handleAddBand,
    handleBandsReset,
    handleRemoveLastBand,
    handleBandChange,
    formatBandInitialValues,
  } = useOnboardingRateForm({ formInstance: form, defaultValues: currentProduct });

  const { tripApprovalWorkflow } = useSelector(selectStoreCompanySettings);

  const APPROVAL_OPTIONS = [
    {
      value: 'auto',
      label: t('automaticApprovalAll'),
    },
    ...(!!tripApprovalWorkflow
      ? [
          {
            value: 'semi',
            label: t('automaticApprovalWithLimits'),
          },
          {
            value: 'manual',
            label: t('managerApprovalOnlyAll'),
          },
        ]
      : []),
  ];

  const COUNTRY = useMemo(() => selectStoreCountryByCode(country), [country]);

  const LIMITS = [
    {
      label: t('tripsApprovedIfDistanceLessThann'),
      value: DISTANCE_LIMIT,
      placeholder: t('enterWholeNumber'),
      limit: distanceSetting,
      onChange: setDistanceSetting,
      inputProps: {
        suffix: COUNTRY.distanceShort,
      },
    },
    {
      label: t('percentOfTripsRecordedEqualOrHigher'),
      value: GPS_LIMIT,
      placeholder: t('enterWholeNumber'),
      limit: gpsSetting,
      onChange: setGpsSetting,
      inputProps: { suffix: '%' },
    },
    {
      label: t('totalRbAmountEqualOrLess'),
      value: REIMBURSEMENT_LIMIT,
      placeholder: t('enterWholeNumber'),
      limit: reimbursementSetting,
      onChange: setReimbursementSetting,
      inputProps: {
        suffix: COUNTRY.currencyCode,
      },
    },
  ];

  useEffect(() => {
    if (currentCompany.address) {
      setCountry(currentCompany.address.country);
    }
    if (regStatus > USER_REG_STATUS.PRICES && currentProduct && currentProduct.country) {
      setCountry(currentProduct.country);
    }

    return () => {};
    // eslint-disable-next-line
  }, []);

  const onSubmit = values => {
    let requestParams = {
      name: values.rateName,
      country: countryRate.country,
      approvalConfig: userRate.approvalConfig,
      company: currentCompany._id,
      effectiveDate: moment(countryRate.effectiveDate).format('YYYY-MM-DD'),
      rateType: userRate.type,
    };

    if (isGovernmentFlatRate(requestParams.rateType, requestParams.country)) {
      requestParams.govtRate = countryRate.defaultRate;
    } else if (requestParams.rateType === RATE_TYPES.FLAT) {
      requestParams.flatRate = userRate.value;
    } else if (requestParams.rateType === RATE_TYPES.TIERED) {
      const bandValues = bands.filter(value => value.bandEnd && value.bandRate);
      setUserRate(currentUserRate => ({ ...currentUserRate, bands: bandValues }));
      requestParams.bands = bands;
    }

    if (userRate.approvalConfig === APPROVAL_CONFIG_VALUES.SEMI) {
      requestParams.approvalSetting = approvalSetting;
    } else {
      requestParams.approvalSetting = null;
    }

    product(requestParams);
  };

  const onSubmitFailed = error => {
    Toast({
      type: 'error',
      message: t('rateCreateError'),
    });
    return;
  };

  useEffect(() => {
    const updatedApprovalSetting = [];

    if (selectedLimits.includes(DISTANCE_LIMIT)) {
      updatedApprovalSetting.push({
        limit: parseFloat(distanceSetting),
        approvalType: DISTANCE_LIMIT,
      });
    }

    if (selectedLimits.includes(GPS_LIMIT)) {
      updatedApprovalSetting.push({
        limit: parseFloat(gpsSetting),
        approvalType: GPS_LIMIT,
      });
    }

    if (selectedLimits.includes(REIMBURSEMENT_LIMIT)) {
      updatedApprovalSetting.push({
        limit: parseFloat(reimbursementSetting),
        approvalType: REIMBURSEMENT_LIMIT,
      });
    }

    setApprovalSetting(updatedApprovalSetting);
  }, [selectedLimits, distanceSetting, gpsSetting, reimbursementSetting]);

  const handleRateTypeRadioCardClick = value => {
    let updatedUserRate = { type: value };
    handleBandsReset();

    if (isGovernmentFlatRate(value, country)) {
      updatedUserRate.value = countryRate.defaultRate;
    } else if (value === RATE_TYPES.FLAT) {
      updatedUserRate.value = get(currentProduct, 'rates.flatRate', null);
    } else if (isTieredRate(value, country)) {
      setBands(countryRate.defaultBands);

      if (Array.isArray(countryRate.defaultBands)) {
        countryRate.defaultBands.forEach((band, i) => {
          form.setFieldsValue({
            ['bandStart-' + i]: band.bandStart,
            ['bandEnd-' + i]: band.bandEnd,
            ['bandRate-' + i]: band.bandRate,
          });
        });
      }
    } else {
      updatedUserRate.value = null;
    }

    setUserRate(state => ({ ...state, ...updatedUserRate }));
  };

  const handleApprovalConfigRadioCardClick = value => {
    setUserRate(state => ({ ...state, approvalConfig: value }));
  };

  useEffect(() => {
    if (!userRate.type && countryRate) {
      handleRateTypeRadioCardClick(
        currentProduct?.rates?.rateType ? currentProduct.rates.rateType : RATE_TYPES.GOVT,
      );
    }
    // eslint-disable-next-line
  }, [userRate.type, countryRate]);

  if (!countryRate || !userRate.type) {
    return <SpaceSpinner />;
  }

  return (
    <Form
      form={form}
      name="reimbursement"
      className={classNames.onboardingReimbursementForm}
      onFinish={onSubmit}
      onFinishFailed={onSubmitFailed}
      initialValues={{
        rateName: currentProduct && currentProduct.name ? currentProduct.name : 'Default Rate',
        milageRate:
          currentProduct && currentProduct.rates && currentProduct.rates.rateType
            ? currentProduct.rates.rateType
            : RATE_TYPES.GOVT,
        approvalConfig:
          currentProduct && currentProduct.approvalConfig ? currentProduct.approvalConfig : 'auto',
        flatRate:
          currentProduct &&
          currentProduct.rates &&
          currentProduct.rates.rateType === RATE_TYPES.FLAT
            ? currentProduct.rates.flatRate
            : '',
        ...(userRate.approvalConfig === APPROVAL_CONFIG_VALUES.SEMI
          ? {
              [SEMI_AUTO_VALUES.DISTANCE]: getApprovalSettingValue(
                SEMI_AUTO_VALUES.DISTANCE,
                approvalSetting,
              ),
              [SEMI_AUTO_VALUES.GPS]: getApprovalSettingValue(
                SEMI_AUTO_VALUES.GPS,
                approvalSetting,
              ),
              [SEMI_AUTO_VALUES.REIMBURSEMENT]: getApprovalSettingValue(
                SEMI_AUTO_VALUES.REIMBURSEMENT,
                approvalSetting,
              ),
            }
          : {}),
        ...formatBandInitialValues(),
      }}
    >
      <Text variant="h5" className="form-item-title">
        {t('rateName')}
      </Text>

      <FormItem
        name="rateName"
        rules={[
          {
            required: true,
            message: ERROR_MESSAGE().BLANK_FIELD,
          },
        ]}
      >
        <TextInput
          autoFocus
          placeholder={t('Default Rate')}
          defaultValue={currentProduct.name ? currentProduct.name : undefined}
          onChange={e =>
            setUserRate({
              ...userRate,
              name: e.target.value,
            })
          }
        />
      </FormItem>

      <HorizontalLineDivider marginTop={6} marginBottom={30} />

      <Row>
        <div className="form-item-title">
          <Row gutter={20}>
            <Col>
              <Text variant="h5">
                {t('selectMileageRateFor')}: {COUNTRY.name}
              </Text>
            </Col>
            <Col>
              <LinkText
                href="#"
                onClick={() => setShowCountrySelector(!showCountrySelector)}
                className="country-selector-link"
              >
                {t('changeCountrOfOperation')}
              </LinkText>
            </Col>
          </Row>
        </div>
      </Row>

      {showCountrySelector && (
        <Row>
          <div className="country-selector">
            <Text variant="h5">{t('countryOfOperation')}</Text>
            <FormItem extra={t('ratesDeterminedByCountry')}>
              <div className="forms">
                <Select
                  placeholder={t('selectCountryOfOperation')}
                  value={country}
                  onChange={e => {
                    setCountry(e);
                    setShowCountrySelector(false);
                    setUserRate(state => ({ ...state, type: undefined }));
                  }}
                  options={supportedCountries.map(c => ({
                    label: c.name,
                    value: c.code,
                    disabled: c.status !== 'active',
                  }))}
                />
              </div>
            </FormItem>
          </div>
        </Row>
      )}

      <Space direction="vertical">
        <RadioCard
          title={t('government')}
          checked={userRate.type === RATE_TYPES.GOVT}
          onClick={() => handleRateTypeRadioCardClick(RATE_TYPES.GOVT)}
        >
          <div className="rate-type">
            <Text variant="h5">
              {`${countryRate.name}`}{' '}
              {countryRate.defaultRate
                ? `(${COUNTRY.currencyCode} ${countryRate.defaultRate} / ${COUNTRY.distanceShort})`
                : undefined}
            </Text>
          </div>
          <div className="rate-details govt">
            {isGovernmentFlatRate(userRate.type, COUNTRY.code) && (
              <div className="radio-content">
                {t('driversWillReimbursedAtGovtRateOf', {
                  rate: formatNumberWithCurrency(
                    countryRate.defaultRate,
                    COUNTRY.currencyCode,
                    undefined,
                    'code',
                  ),
                  distanceUnit: COUNTRY.distanceLong,
                })}
              </div>
            )}

            {isTieredRate(userRate.type, COUNTRY.code) &&
              Array.isArray(bands) &&
              bands.map((band, index) => {
                return (
                  <div className={classNames.threeInputContainer} key={`band-${index}`}>
                    <ThreeInput
                      showLabel={isMobile || index === 0}
                      custom={true}
                      fieldOneProps={{
                        type: 'number',
                        name: 'bandStart-' + index,
                        value: band.bandStart,
                        defaultValue: band.bandStart,
                        suffix: COUNTRY.distanceShort,
                        disabled: true,
                      }}
                      fieldTwoProps={{
                        step: '1',
                        type: 'number',
                        name: 'bandEnd-' + index,
                        value: band.bandEnd,
                        defaultValue: band.bandEnd,
                        suffix: COUNTRY.distanceShort,
                        disabled: true,
                      }}
                      fieldThreeProps={{
                        step: 'any',
                        type: 'number',
                        name: 'bandRate-' + index,
                        value: band.bandRate,
                        defaultValue: band.bandRate,
                        suffix: COUNTRY.currencyCode,
                        disabled: true,
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </RadioCard>

        <RadioCard
          title={t('customRate')}
          checked={userRate.type === RATE_TYPES.FLAT}
          onClick={() => handleRateTypeRadioCardClick(RATE_TYPES.FLAT)}
        >
          <FormItem
            style={{ maxWidth: 200 }}
            name="flatRate"
            rules={[
              {
                required: true,
                message: ERROR_MESSAGE().BLANK_FIELD,
              },
            ]}
          >
            <div className="flat-rate-contents">
              <TextInput
                type="number"
                prefix={COUNTRY.currencyCode}
                suffix={`/ ${COUNTRY.distanceShort}`}
                defaultValue={
                  currentProduct && currentProduct.rates && currentProduct.rates.flatRate
                    ? currentProduct.rates.flatRate
                    : ''
                }
                onChange={value => {
                  setUserRate({
                    ...userRate,
                    value: Number(parseFloat(value).toFixed(3)),
                  });
                }}
              />
            </div>
          </FormItem>
        </RadioCard>

        <RadioCard
          title={t('tieredRate')}
          checked={userRate.type === RATE_TYPES.TIERED}
          onClick={() => handleRateTypeRadioCardClick(RATE_TYPES.TIERED)}
        >
          <div className="banded-contents">
            <Row>{t('annualMileage')}</Row>
          </div>

          {Array.isArray(bands) &&
            bands.map((band, index) => {
              return (
                <div className={classNames.threeInputContainer} key={`band-${index}`}>
                  <ThreeInput
                    showLabel={isMobile || index === 0}
                    custom={true}
                    fieldOneProps={{
                      type: 'number',
                      name: 'bandStart-' + index,
                      value: band.bandStart,
                      defaultValue: band.bandStart,
                      suffix: COUNTRY.distanceShort,
                    }}
                    fieldTwoProps={{
                      step: '1',
                      type: 'number',
                      name: 'bandEnd-' + index,
                      value: band.bandEnd,
                      defaultValue: band.bandEnd,
                      onChange: val => {
                        handleBandChange(index, 'bandEnd', val);
                        form.setFieldsValue({
                          ['bandEnd-' + index]: val,
                        });
                      },
                      suffix: COUNTRY.distanceShort,
                    }}
                    fieldThreeProps={{
                      step: 'any',
                      type: 'number',
                      name: 'bandRate-' + index,
                      value: band.bandRate,
                      defaultValue: band.bandRate,
                      onChange: val => {
                        handleBandChange(index, 'bandRate', val);
                        form.setFieldsValue({
                          ['bandRate-' + index]: val,
                        });
                      },
                      suffix: COUNTRY.currencyCode,
                    }}
                  />
                  {index !== 0 && index === bands.length - 1 && (
                    <DeleteFilled className="item-container-trash" onClick={handleRemoveLastBand} />
                  )}
                </div>
              );
            })}

          <Row style={{ marginTop: 5 }} justify="end">
            <Col>
              <div className="item-add" onClick={handleAddBand}>
                <PlusCircleOutlined className="item-add-icon" />
                <span style={{ marginLeft: 5 }}>{t(`Add new band`)}</span>
              </div>
            </Col>
          </Row>
        </RadioCard>
      </Space>

      <HorizontalLineDivider marginTop={30} marginBottom={30} />

      <Text variant="h5" className="form-item-title">
        {t('setApprovalPreference')}
      </Text>

      <Space direction="vertical">
        <RadioCard
          title={t('automatic')}
          checked={userRate.approvalConfig !== 'manual' || !userRate.approvalConfig}
          onClick={() => {
            handleApprovalConfigRadioCardClick('auto');
          }}
        >
          <Select
            fullWidth
            className="approval-config-dropdown"
            placeholder={t('selectCountryOfOperation')}
            value={userRate.approvalConfig}
            onChange={val => {
              setUserRate({ ...userRate, approvalConfig: val });
              form.setFieldValue('approvalConfig', val);
            }}
            options={APPROVAL_OPTIONS}
          />

          {userRate.approvalConfig === APPROVAL_CONFIG_VALUES.SEMI && (
            <div>
              <div className="approval-configuration">
                <div>
                  {LIMITS.map(limit => (
                    <Row key={limit.label} gutter={[17, 17]} align="start">
                      <Col flex="1 1 465px">
                        <Checkbox
                          style={{ marginTop: 8 }}
                          checked={selectedLimits.includes(limit.value)}
                          value={limit.value}
                          onChange={e => {
                            e.target.checked
                              ? setSelectedLimits([...selectedLimits, e.target.value])
                              : setSelectedLimits(
                                  [...selectedLimits].filter(v => v !== limit.value),
                                );
                          }}
                        >
                          {limit.label}
                        </Checkbox>
                      </Col>
                      <Col flex="210px">
                        <Form.Item
                          name={limit.value}
                          rules={[
                            {
                              required: selectedLimits.includes(limit.value),
                              message: ERROR_MESSAGE().BL,
                            },
                          ]}
                        >
                          <TextInput
                            {...limit.inputProps}
                            disabled={!selectedLimits.includes(limit.value)}
                            value={limit.limit}
                            placeholder={limit.placeholder}
                            onChange={e => limit.onChange(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </div>
              </div>
            </div>
          )}
        </RadioCard>

        <RadioCard
          title={t('manual')}
          checked={userRate.approvalConfig === 'manual'}
          onClick={() => handleApprovalConfigRadioCardClick('manual')}
        >
          <div className="radio-content">{t('managerApprovalOnlyAll')}</div>
        </RadioCard>
      </Space>

      <SubmitCancelButtonGroup
        isForm
        style={{ marginTop: 20 }}
        onCancel={() => props.history.goBack()}
        submitText={t('Save')}
      />
    </Form>
  );
};

ReimbursementForm.propTypes = {
  currentCompany: PropTypes.object,
  currentProduct: PropTypes.object,
};

export default ReimbursementForm;
