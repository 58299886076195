import Toast from '../components/Toast';
import i18n from '../i18n';
import { handleApiCalls } from './axiosInstance';

/**
 * Performs an API call to send an email with a link to the mobile App to a given User
 *
 * @param {string} userId ID of User
 * @param {function} onSuccess On Success ballback
 * @param {onError} onError On Error callback
 */
export const sendAppLink = async (userId, onSuccess, onError) => {
  try {
    const result = await handleApiCalls(
      'post',
      `${process.env.REACT_APP_HOST_API}emails/send-application`,
      { id: userId },
    );

    if ((result.status === 200 || result.data.success) && typeof onSuccess === 'function') {
      onSuccess(result.data);
    }
  } catch (error) {
    if (typeof onError === 'function') {
      onError(error);
    } else {
      Toast({
        type: 'error',
        message: error.message || i18n.t('unableToSendLinkToApp'),
      });
    }
  }
};
