import { handleApiCalls } from './axiosInstance';

/**
 * Performs an API call to create a new Receipt Review
 * @param {string} tripReceiptId ID of Receipt
 * @param {string[]} tripsIds IDs of Trips to be reviewed
 * @param {string} comment Comment on the Trips
 * @returns {object}
 */
export const createReceiptReview = async (tripReceiptId, tripsIds, comment) => {
  const url = `${process.env.REACT_APP_HOST_API}trip-receipt/trips/more-info`;
  const result = await handleApiCalls('post', url, { tripReceiptId, tripsIds, comment });
  return result.data.data;
};

/**
 * Performs an API call to add a comment on a Receipt Review
 * @param {string} tripReceiptReviewId ID of Receipt Review
 * @param {string} comment Comment on the Trips
 * @returns {object}
 */
export const createReceiptReviewComment = async (tripReceiptReviewId, comment) => {
  const url = `${process.env.REACT_APP_HOST_API}trip-receipt-reviews/comments`;
  const result = await handleApiCalls('post', url, { tripReceiptReviewId, comment });
  return result.data.data;
};

/**
 * Performs an API call to resubmit receipts to be approved
 * @param {string[]} tripReceiptsIds ID of Receipt
 * @returns {object}
 */
export const resubmitReceiptForApproval = async tripReceiptsIds => {
  const url = `${process.env.REACT_APP_HOST_API}trip-receipt/resubmit`;
  const result = await handleApiCalls('post', url, { tripReceiptsIds });
  return result.data.data;
};
