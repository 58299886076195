import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import StepWizard from 'react-step-wizard';

import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import TabsContainer from '../../../components/TabsContainer';
import VehicleFavrAdditionalInfoForm from './VehicleFavrAdditionalInfoForm';
import VehicleForm from './VehicleForm';

const VEHICLE_INFO_TAB = '0';
const OTHER_INFO_TAB = '1';

const VehicleFavrFormWizard = props => {
  const {
    t,
    isUpdate,
    isSubmitting,
    selectedVehicle,
    formInstance,
    initialValues,
    onSubmit,
    onChange,
    onFooterUpdate,
    msrpAndSalesTax,
    rateCountry,
  } = props;

  const [wizardInstance, setWizardInstance] = useState();
  const [activeTab, setActiveTab] = useState(VEHICLE_INFO_TAB);

  const { isLoadingYears, isLoadingMakes, isLoadingModels } = useSelector(store => store.vehicles);

  const handleTabChange = useCallback(
    step => {
      setActiveTab(step);
      wizardInstance.setActiveStep(+step);
      document.querySelector('.ant-modal-body').scrollTo({ top: 0, behavior: 'smooth' });
    },
    [wizardInstance],
  );

  useEffect(() => {
    formInstance.setFieldsValue(initialValues);
    return () => {
      formInstance.resetFields();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onFooterUpdate(
      <Row align="middle" gutter={8} wrap={false}>
        <Col flex={2}>
          <SubmitCancelButtonGroup
            hideCancel={activeTab === VEHICLE_INFO_TAB}
            loading={isSubmitting}
            disabled={isSubmitting || isLoadingYears || isLoadingMakes || isLoadingModels}
            submitText={
              activeTab === OTHER_INFO_TAB ? (isUpdate ? t('Update') : t('Save')) : t('Next')
            }
            onCancel={() => handleTabChange(VEHICLE_INFO_TAB)}
            cancelText={t('Back')}
            onSubmit={() => {
              formInstance.validateFields().then(() => {
                if (activeTab === OTHER_INFO_TAB) {
                  onSubmit(selectedVehicle);
                } else {
                  handleTabChange(OTHER_INFO_TAB);
                }
              });
            }}
          />
        </Col>

        {isUpdate && <Col flex="50%">{t('modifyingVehicleWarning')}</Col>}
      </Row>,
    );
  }, [
    t,
    isUpdate,
    isSubmitting,
    activeTab,
    formInstance,
    onFooterUpdate,
    handleTabChange,
    selectedVehicle,
    onSubmit,
    isLoadingYears,
    isLoadingMakes,
    isLoadingModels,
  ]);

  return (
    <div>
      <TabsContainer
        activeKey={activeTab}
        onTabClick={step => {
          if (step === OTHER_INFO_TAB) {
            formInstance.validateFields().then(() => {
              handleTabChange(step);
            });
          } else {
            handleTabChange(step);
          }
        }}
      >
        <TabsContainer.TabsPane
          key={VEHICLE_INFO_TAB}
          tabKey={VEHICLE_INFO_TAB}
          tab={t('vehicleInfo')}
          disabled={isSubmitting}
        />
        <TabsContainer.TabsPane
          disabled={isSubmitting}
          key={OTHER_INFO_TAB}
          tabKey={OTHER_INFO_TAB}
          tab={t('otherInfo')}
        />
      </TabsContainer>

      <StepWizard
        initialStep={+VEHICLE_INFO_TAB}
        instance={setWizardInstance}
        transitions={{
          enterRight: '',
          enterLeft: '',
          exitRight: '',
          exitLeft: '',
        }}
      >
        <VehicleForm
          t={t}
          isFAVR
          isSubmitting={isSubmitting}
          selectedVehicle={selectedVehicle}
          formInstance={formInstance}
          initialValues={initialValues}
          onChange={onChange}
          isVisible={activeTab === VEHICLE_INFO_TAB}
        />

        <VehicleFavrAdditionalInfoForm
          t={t}
          rateCountry={rateCountry}
          formInstance={formInstance}
          isRequired={activeTab === OTHER_INFO_TAB}
          isSubmitting={isSubmitting}
          msrpAndSalesTax={msrpAndSalesTax}
          onFieldChange={onChange}
        />
      </StepWizard>
    </div>
  );
};

export default VehicleFavrFormWizard;
