export const LOAD_PRICE_DATA = 'LOAD_PRICE_DATA';
export const LOAD_CUSTOM_PRICE_DATA = 'LOAD_CUSTOM_PRICE_DATA';
export const UPDATE_PRICE_DATA = 'UPDATE_PRICE_DATA';
export const UPDATE_CUSTOM_PRICE_DATA = 'UPDATE_CUSTOM_PRICE_DATA';

export function loadPricesData(companyId) {
  return {
    type: LOAD_PRICE_DATA,
    data: { companyId },
  };
}

export function emitFetchCustomPricesData() {
  return {
    type: LOAD_CUSTOM_PRICE_DATA,
  };
}

export function updatePricesData(priceData) {
  return {
    type: UPDATE_PRICE_DATA,
    data: { priceData },
  };
}

export function updateCustomPricesData(priceData) {
  return {
    type: UPDATE_CUSTOM_PRICE_DATA,
    data: { priceData },
  };
}
