import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './style.scss';

class PriceInput extends React.Component {

  render() {

    const PriceSymbol = {
      'None': '$',
      Canada: 'C$',
      'United States': 'US$',
    }

    const { country } = this.props

    return (
      <Input {...this.props} addonBefore={PriceSymbol[country]} />
    )
  }
}

PriceInput.propTypes = {
  country: PropTypes.string,
}

PriceInput.defaultProps = {
  country: 'None',
}

export default PriceInput;