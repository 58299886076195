import { Form } from 'antd';
import MaskedInput from 'antd-mask-input';
import { CountryCodeSelect } from 'components';
import PropTypes from 'prop-types';
import React from 'react';

class PhoneNumberInput extends React.Component {
  render() {
    const prefixSelector = (
      <Form.Item name="prefix" noStyle>
        <CountryCodeSelect
          showSearch
          autoComplete="tel-country-code"
          defaultValue={this.props.countryCodeValue}
          onChange={this.props.onCodeChange}
        />
      </Form.Item>
    );

    return (
      <MaskedInput
        autoComplete="tel-national"
        mask="000-000-0000"
        placeholder=""
        addonBefore={prefixSelector}
        value={this.props.value}
        onChange={this.props.onNumberChange}
      />
    );
  }
}

PhoneNumberInput.propTypes = {
  value: PropTypes.string,
  countryCodeValue: PropTypes.string,
  onCodeChange: PropTypes.func,
  onNumberChange: PropTypes.func,
};

PhoneNumberInput.defaultProps = {
  value: '',
  countryCodeValue: '',
  onCodeChange: () => {},
  onNumberChange: () => {},
};

export default PhoneNumberInput;
