import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { emitFetchCustomPricesData, loadPricesData } from '../stores/actions/prices';
import { hasSystemAdminRole } from '../utils/roles';
import { selectStoreCurrentAuthUser, selectStoreCurrentCompany } from '../utils/storeSelectors';

const usePricePlans = (t, withCustoms = false) => {
  const dispatch = useDispatch();
  const pricesStore = useSelector(store => store.prices);
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const currentAuthUser = useSelector(selectStoreCurrentAuthUser);

  const standardPlansOptions = useMemo(() => {
    const plans = [];

    if (Array.isArray(pricesStore.plans)) {
      pricesStore.plans.forEach(plan => {
        plans.push({
          label: plan.level,
          value: plan.order,
        });
      });
    }

    return plans;
  }, [pricesStore.plans]);

  const customPlansOptions = useMemo(() => {
    const plans = [];

    if (Array.isArray(pricesStore.customPlans)) {
      pricesStore.customPlans.forEach(plan => {
        plans.push({
          label: plan.name,
          value: plan._id,
        });
      });
    }

    return plans;
    // eslint-disable-next-line
  }, [pricesStore.customPlans]);

  const fetchPrices = useCallback(async () => {
    dispatch(loadPricesData(currentCompany._id));
    // eslint-disable-next-line
  }, []);

  const fetchCustomPrices = useCallback(async () => {
    if (hasSystemAdminRole(currentAuthUser)) {
      dispatch(emitFetchCustomPricesData());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!Array.isArray(pricesStore.plans)) {
      fetchPrices();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (withCustoms && !Array.isArray(pricesStore.customPlans)) {
      fetchCustomPrices();
    }
    // eslint-disable-next-line
  }, [withCustoms]);

  return {
    pricesStore,
    customPlansOptions,
    standardPlansOptions,
  };
};

export default usePricePlans;
