import { cloneDeep } from 'lodash';

/**
 * @param {object} options
 * @param {object?} options.initialState
 * @param {Function} options.reducer
 */
export const createReducer = (options = {}) => {
  const { initialState, reducer } = options;

  const defaultState = cloneDeep(initialState);

  return (state = defaultState, action) => reducer(state, action, defaultState);
};
