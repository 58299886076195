import { get, isUndefined, omit } from 'lodash';
import { useMemo } from 'react';

import { USER_API } from '../../api/user';
import useTableSort from '../useTableSort';
import usePaginatedFiltersQuery from './usePaginatedFiltersQuery';

/**
 * @param {string} companyId
 * @param {object} filters
 * @param {import('react-query').UseQueryOptions?} options
 */
const usePaginatedAllCompanyUsersQuery = (companyId, filters = {}, options = {}) => {
  const { stringTableSort, handleTableSort: allUsersHandleTableSort } = useTableSort({
    firstName: 1,
    lastName: 1,
  });

  const resetPageQueryKey = useMemo(() => {
    if (!!Object.keys(filters).length) {
      return Object.values(filters).map(val => {
        if (Array.isArray(val)) {
          return val.join(',');
        }
        return val;
      });
    }

    return [];
  }, [filters]);

  const { query: allUsersQuery, ...allUsersTablePagination } = usePaginatedFiltersQuery(
    {
      ...options,
      enabled: !isUndefined(options.enabled) ? options.enabled && !!companyId : !!companyId,
      placeholderData: { users: [], totalCount: 0 },
      queryKey: ['getUserList', companyId, ...Object.values(filters), stringTableSort],
      queryFn: () =>
        USER_API.getUserList(
          companyId,
          omit(filters, 'status'),
          get(filters, 'status'),
          allUsersTablePagination.paginationConfig.current,
          allUsersTablePagination.paginationConfig.pageSize,
          stringTableSort,
        ),
      onSuccess: options.onSuccess,
    },
    { resetPageQueryKey },
    'totalCount',
  );

  return {
    stringTableSort,
    allUsersQuery,
    allUsersTablePagination,
    allUsersHandleTableSort,
  };
};

export default usePaginatedAllCompanyUsersQuery;
