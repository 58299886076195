import { Tooltip as AntdTooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Text from '../Text';
import classNames from './style.module.scss';

class Tooltip extends Component {
  render() {
    const { children, className, title, enabled, ...rest } = this.props;

    if (!enabled) {
      return (
        <React.Fragment>
          <span>{children}</span>
        </React.Fragment>
      );
    }

    return (
      <AntdTooltip
        {...rest}
        destroyTooltipOnHide
        overlayClassName={className}
        title={
          typeof title === 'string' ? (
            <Text variant="p" size="sm" className={classNames.text}>
              {title}
            </Text>
          ) : (
            title
          )
        }
      >
        <span>{children}</span>
      </AntdTooltip>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf([
    'top',
    'left',
    'right',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'leftTop',
    'leftBottom',
    'rightTop',
    'rightBottom',
  ]),
  trigger: PropTypes.oneOf([
    'hover',
    'focus',
    'click',
    'contextMenu',
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Tooltip.defaultProps = {
  className: '',
  placement: 'top',
  enabled: true,
};

export default Tooltip;
