import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  emitGetVehicleMakes,
  emitGetVehicleModels,
  setupFavrVehicles,
} from '../stores/actions/favrVehicles';
import { useStoreSelector } from '../utils/storeSelectors';

/**
 *
 * @param {number} year Year of vehicles to fetch.
 * @param {string} make Vehicle make to fetch.
 */
const useFavrVehicleDataByYear = (year, make) => {
  const dispatch = useDispatch();
  const {
    isSetup,

    isLoadingYears,
    isLoadingMakes,
    isLoadingModels,

    availableYears,
    byYearData,
  } = useStoreSelector('favrVehicles', {});

  const [currentYear, setCurrentYear] = useState(year);
  const [currentMake, setCurrentMake] = useState(make);

  const vehicleYearData = byYearData[currentYear];
  const vehicleMakes = vehicleYearData?.availableMakes || [];
  const vehicleModels =
    currentMake && vehicleYearData?.modelsByMake
      ? vehicleYearData?.modelsByMake[currentMake] || []
      : [];

  const vehicleMakeOptions = useMemo(() => {
    return vehicleMakes.map(make => {
      return { label: capitalize(make), value: make };
    });
  }, [vehicleMakes]);

  const vehicleModelOptions = useMemo(() => {
    return vehicleModels.map(model => {
      return {
        label: capitalize(model.model),
        value: model.model,
        title: `${model.model}**${model.fuel}`,
      };
    });
  }, [vehicleModels]);

  useEffect(() => {
    if (!isSetup) {
      dispatch(setupFavrVehicles(year, make));
    }
    // eslint-disable-next-line
  }, [dispatch, isSetup, year, make]);

  useEffect(() => {
    if (year) {
      setCurrentYear(year);
      setCurrentMake();
    }
  }, [year]);

  useEffect(() => {
    if (isSetup && currentYear && !vehicleYearData?.availableMakes?.length) {
      setCurrentMake();
      dispatch(emitGetVehicleMakes(year));
    }
    // eslint-disable-next-line
  }, [isSetup, currentYear, vehicleYearData]);

  useEffect(() => {
    if (make) {
      setCurrentMake(make);
    }
  }, [make]);

  useEffect(() => {
    const modelsByMake = vehicleYearData?.modelsByMake || {};
    if (isSetup && currentMake && currentYear && !modelsByMake[currentMake]?.length) {
      dispatch(emitGetVehicleModels(currentYear, currentMake));
    }
    // eslint-disable-next-line
  }, [isSetup, currentYear, currentMake, vehicleYearData]);

  return {
    isLoadingYears,
    isLoadingMakes,
    isLoadingModels,
    //
    availableYears,
    //
    vehicleMakes,
    vehicleMakeOptions,
    vehicleModels,
    vehicleModelOptions,
  };
};

export default useFavrVehicleDataByYear;
