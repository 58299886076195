import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { Col, Empty, Row } from 'antd';
import { first, get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';

import { USER_API } from '../../../api/user';
import Box from '../../../components/Box';
import Spinner from '../../../components/Spinner';
import TabsContainer from '../../../components/TabsContainer';
import Text from '../../../components/Text';
import FadedText from '../../../components/Text/FadedText';
import withPageState from '../../../hocs/withPageState';
import { formatPageTitle } from '../../../utils/common';
import { getMinutesAsMilliseconds } from '../../../utils/numbers';
import MobileAppSettingsContent from './MobileAppSettingsContent';

const MobileAppSettings = props => {
  const { t, userDetails } = props;

  const [activeTab, setActiveTab] = useState('ios');

  const iosLatestSettingsQuery = useQuery({
    staleTime: getMinutesAsMilliseconds(5),
    placeholderData: { documents: [], totalCount: 0 },
    select: data => first(get(data, 'documents')),
    queryKey: ['fetchMobileAppSettings', 'ios', userDetails._id],
    queryFn: () => USER_API.fetchMobileAppSettings(userDetails._id, 'ios'),
  });

  const androidLatestSettingsQuery = useQuery({
    staleTime: getMinutesAsMilliseconds(5),
    placeholderData: { documents: [], totalCount: 0 },
    select: data => first(get(data, 'documents')),
    queryKey: ['fetchMobileAppSettings', 'android', userDetails._id],
    queryFn: () => USER_API.fetchMobileAppSettings(userDetails._id, 'android'),
  });

  const hasAndroidData = useMemo(
    () => !!androidLatestSettingsQuery.isFetched && !!androidLatestSettingsQuery.data,
    [androidLatestSettingsQuery.data, androidLatestSettingsQuery.isFetched],
  );

  const hasAppleData = useMemo(
    () => !!iosLatestSettingsQuery.isFetched && !!iosLatestSettingsQuery.data,
    [iosLatestSettingsQuery.data, iosLatestSettingsQuery.isFetched],
  );

  useEffect(() => {
    if (!!iosLatestSettingsQuery.isFetched && !iosLatestSettingsQuery.data) {
      setActiveTab('android');
    }
  }, [iosLatestSettingsQuery.data, iosLatestSettingsQuery.isFetched]);

  return (
    <Box>
      <Helmet>
        <title>{formatPageTitle('Mobile App Settins')}</title>
      </Helmet>

      <Text variant="h5">{t('mobileAppSettings')}</Text>

      <Spinner
        spinning={androidLatestSettingsQuery.isFetching || iosLatestSettingsQuery.isFetching}
      >
        {!hasAndroidData && !hasAppleData ? (
          <Empty description={<Text textAlign="center">{t('noMobileAppData')}</Text>} />
        ) : (
          <TabsContainer
            activeKey={activeTab}
            onChange={setActiveTab}
            destroyInactiveTabPane
            items={[
              {
                key: 'ios',
                disabled: !hasAppleData,
                label: (
                  <Row gutter={[2, 2]} wrap={false}>
                    <Col>
                      <AppleOutlined style={{ fontSize: 17 }} />
                    </Col>
                    <Col>
                      {hasAppleData ? (
                        <Text style={{ color: 'inherit' }}>iOS</Text>
                      ) : (
                        <FadedText>iOS</FadedText>
                      )}
                    </Col>
                  </Row>
                ),
                children: (
                  <MobileAppSettingsContent
                    t={t}
                    userId={userDetails._id}
                    settings={iosLatestSettingsQuery.data}
                  />
                ),
              },
              {
                key: 'android',
                disabled: !hasAndroidData,
                label: (
                  <Row>
                    <Col>
                      <AndroidOutlined style={{ fontSize: 17 }} />
                    </Col>
                    <Col>
                      {hasAppleData ? (
                        <Text style={{ color: 'inherit' }}>Android</Text>
                      ) : (
                        <FadedText>Android</FadedText>
                      )}
                    </Col>
                  </Row>
                ),
                children: (
                  <MobileAppSettingsContent
                    t={t}
                    userId={userDetails._id}
                    settings={androidLatestSettingsQuery.data}
                  />
                ),
              },
            ]}
          ></TabsContainer>
        )}
      </Spinner>
    </Box>
  );
};

export default withPageState(MobileAppSettings, true);
