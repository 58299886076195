import { Col, Row } from 'antd';
import React from 'react';

import { sortColumnByStringField } from '../../../utils/tables';
import { CustomTable } from '../..';
import SimpleEditPopoverForm from '../../Forms/SimpleEditPopoverForm';
import LinkText from '../../Text/LinkText';

const DomainsTable = props => {
  const { t, dataSource, isSubmitting, onEdit, onDelete, popoverTitle, ...rest } = props;

  const COLUMNS = [
    {
      key: 'name',
      title: t('domain'),
      dataIndex: 'name',
      ...sortColumnByStringField('name'),
    },
    {
      width: 150,
      key: 'actions',
      title: t('actions'),
      render: (action, data) => (
        <Row gutter={16}>
          <Col>
            <SimpleEditPopoverForm
              t={t}
              inputName="name"
              title={popoverTitle}
              isSubmitting={isSubmitting}
              initialValues={{ name: data.name }}
              onSubmit={values =>
                onEdit({
                  prevValue: data.name,
                  newValue: values.name,
                })
              }
            >
              <LinkText>{t('Edit')}</LinkText>
            </SimpleEditPopoverForm>
          </Col>
          <Col>
            <LinkText onClick={() => onDelete(data.name)}>{t('Delete')}</LinkText>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <CustomTable
      {...rest}
      rowKey={data => data._id}
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource}
    />
  );
};

export default DomainsTable;
