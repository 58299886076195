import { Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ADJUSTMENT_TYPE_OPTIONS } from '../../../enum/AdjustmentTypes';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { Button } from '../..';
import Select from '../../Select';
import AdjustmentHistorySmallTable from '../../Table/AdjustmentHistorySmallTable';
import Text from '../../Text';
import TextInput from '../../TextInput';
import GenericModal from '../GenericModal';

const ReimbursementAdjustmentModal = props => {
  const { t, visible, addAdjustment, onOk, onCancel, onRemove, currency, adjustmentsList } = props;
  const [form] = Form.useForm();

  const [adjustmentType, setAdjustmentType] = useState();
  const [amount, setAmount] = useState();
  const [note, setNote] = useState();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <GenericModal
      centered
      width={Math.min(window.innerWidth - 50, 900)}
      destroyOnClose
      footer={null}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      title={t('applyReceiptAdjustment')}
      className="reimbursement-adjustment-modal"
    >
      {!!adjustmentsList.length && (
        <div style={{ marginBottom: 20 }}>
          <Text variant="strong" size="sm">
            {t('appliedAdjustments')}
          </Text>

          <AdjustmentHistorySmallTable t={t} dataSource={adjustmentsList} currency={currency} />
        </div>
      )}

      <Form form={form} labelCol={{ span: 24 }} onFinish={addAdjustment}>
        <Form.Item
          label={t('adjustmentType')}
          name="type"
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <Select
            placeholder={t('select')}
            value={adjustmentType}
            onChange={type => {
              form.setFieldsValue({ type });
              setAdjustmentType(type);
            }}
            options={ADJUSTMENT_TYPE_OPTIONS}
          />
        </Form.Item>

        <Form.Item
          label={t('adjustment')}
          name="amount"
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          extra={t('typeNegativeForNegativeAdjustment')}
        >
          <TextInput
            step="any"
            placeholder={t('amountToAdjust')}
            type="number"
            className={amount < 0 ? 'negative-currency' : undefined}
            value={amount}
            onChange={value => {
              form.setFieldsValue({ addAdjustment: value });
              setAmount(parseFloat(value));
            }}
          />
        </Form.Item>

        <Form.Item
          label={t('note')}
          name="note"
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <TextInput
            textArea
            value={note}
            placeholder={t('reasonForAdjustment')}
            onChange={e => {
              form.setFieldsValue({ note: e.target.value });
              setNote(e.target.value);
            }}
          />
        </Form.Item>

        <Row gutter={15} justify="end">
          {!!adjustmentsList.length && (
            <Col flex={1}>
              <Button type="secondary" text={t(`remove`)} onClick={onRemove} />
            </Col>
          )}

          <Col>
            <Button type="secondary" text={t(`Cancel`)} onClick={onCancel} />
          </Col>
          <Col>
            <Button text={t('apply')} className="border" type="primary" htmlType="submit" />
          </Col>
        </Row>
      </Form>
    </GenericModal>
  );
};

ReimbursementAdjustmentModal.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  addAdjustment: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  adjustmentsList: PropTypes.arrayOf(PropTypes.shape({})),
};

ReimbursementAdjustmentModal.defaultProps = {
  adjustmentsList: [],
};

export default ReimbursementAdjustmentModal;
