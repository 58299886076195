import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import classNames from './style.module.scss';

const Spinner = ({ ...props }) => {
  return (
    <Spin
      size="large"
      spinning={props.spinning}
      className={classNames.customSpinner}
      delay={props.delay}
    >
      {props.children}
    </Spin>
  );
};

Spinner.prototypes = {
  spinning: PropTypes.bool,
};

export default Spinner;
