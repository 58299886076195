import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { createCountryCodeMapping } from '../../api/country-code-mappings';
import Box from '../../components/Box';
import CountryForm from '../../components/Forms/CountryForm';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import { updateSupportedCountries } from '../../stores/actions/common';
import { updateCountryCodeMapping } from '../../stores/actions/systemAdmin';
import { formatPageTitle } from '../../utils/common';

const CreateCountryView = props => {
  const { t, history } = props;

  const dispatch = useDispatch();
  const { supportedCountries } = useSelector(store => store.common);
  const { countryCodeMappings } = useSelector(store => store.systemAdmin);

  const [isCreatingCountry, setIsCreatingCountry] = useState(false);

  const handleCountryCreate = async data => {
    setIsCreatingCountry(true);

    try {
      const country = await createCountryCodeMapping(data);

      const updatedCountries = [...supportedCountries, country];
      const updatedCountryCodeMappings = [...countryCodeMappings, country];

      dispatch(updateSupportedCountries(updatedCountries));
      dispatch(updateCountryCodeMapping(updatedCountryCodeMappings));

      Toast({
        type: 'open',
        message: t('countryCreateSuccess'),
      });

      history.push(INTERNAL_LINKS.GLOBAL_SETTINGS);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('countryCreateError'),
        });
      });
    }

    setIsCreatingCountry(false);
  };

  return (
    <PageContainer
      loading={!supportedCountries}
      title={
        <>
          <Helmet>
            <title>{formatPageTitle('Create Country')}</title>
          </Helmet>

          <PageBreadcrumbs
            items={[
              {
                label: t('countries'),
                onClick: () => history.push(INTERNAL_LINKS.GLOBAL_SETTINGS),
              },
              { label: t('createNewCountry') },
            ]}
          />
        </>
      }
    >
      <Row justify="center">
        <Col flex="832px">
          <Box>
            <CountryForm
              t={t}
              isSubmitting={isCreatingCountry}
              onSubmit={handleCountryCreate}
              onCancel={() => history.push(INTERNAL_LINKS.GLOBAL_SETTINGS)}
              supportedCountries={Array.isArray(supportedCountries) ? supportedCountries : []}
            />
          </Box>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(CreateCountryView));
