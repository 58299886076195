import { get, isUndefined, omit } from 'lodash';
import { useInfiniteQuery } from 'react-query';

import { USER_API } from '../../api/user';

/**
 * @param {string} companyId
 * @param {object} filters
 * @param {import('react-query').UseQueryOptions?} options
 */
const useInfiniteAllCompanyUsersQuery = (companyId, filters = {}, options = {}) => {
  const itemsPerPage = 25;

  return useInfiniteQuery({
    ...options,
    enabled: !isUndefined(options.enabled) ? options.enabled && !!companyId : !!companyId,
    placeholderData: { pages: [] },
    queryKey: ['infinite', 'getUserList', companyId, ...Object.values(filters)],
    queryFn: ({ pageParam = 1 }) =>
      USER_API.getUserList(
        companyId,
        omit(filters, 'status'),
        get(filters, 'status'),
        pageParam,
        itemsPerPage,
      ),
    getNextPageParam: (lastPage, pages) => {
      const { totalCount = 0 } = lastPage || {};
      const totalPages = Math.ceil(totalCount / itemsPerPage);
      if (pages.length < totalPages) return pages.length + 1;
    },
  });
};

export default useInfiniteAllCompanyUsersQuery;
