import React from 'react';

import Text from '../../../../components/Text';
import InvoiceMethodForm from '../InvoiceMethodForm';

const InvoiceSection = props => {
  const {
    t,
    isSubmitting,
    isInvoicePreferred,
    onSubmit,
    initialValues,
    needsConfirmation,
    paymentTerms,
  } = props;

  return (
    <section>
      <Text variant="h5">{t('billingInformation')}</Text>

      {isInvoicePreferred && (
        <Text variant="p" size="sm" style={{ marginBottom: 10 }}>
          {t('invoicesSentInArrears', { count: paymentTerms })}
        </Text>
      )}

      <InvoiceMethodForm
        t={t}
        needsConfirmation={needsConfirmation}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </section>
  );
};

InvoiceSection.defaultProps = {
  paymentTerms: 30,
};

export default InvoiceSection;
