import { notification } from 'antd';
import flagsmith from 'flagsmith';
import React from 'react';
import { Trans } from 'react-i18next';
import storage from 'store';

import { handleApiCalls, handleApiErrors } from '../api/axiosInstance';
import { REDUX_STORE } from '../App';
import Text from '../components/Text';
import LinkText from '../components/Text/LinkText';
import Toast from '../components/Toast';
import { INTERNAL_LINKS } from '../enum';
import i18n from '../i18n';
import { clearProfileState } from '../stores/actions/profile';
import { emitNavigateTo } from '../stores/actions/router';
import { setAnalyticsUserProperties } from './googleAnalytics';
import { PENDO_ANALYTICS } from './pendoAnalytics';

/**
 * Resets the store and removes any access token from the storage
 */
export const logoutUser = async () => {
  const refreshToken = storage.get('refreshToken');
  if (refreshToken) {
    try {
      const url = `${process.env.REACT_APP_HOST_API}auth/logout`;
      await handleApiCalls('post', url, { token: refreshToken });
    } catch (error) {}
  }

  setAnalyticsUserProperties();
  PENDO_ANALYTICS.update();

  await storage.remove('accessToken');
  await storage.remove('refreshToken');
  await storage.remove('previousAccessToken');
  await storage.remove('previousRefreshToken');

  await REDUX_STORE.dispatch(clearProfileState());

  if (typeof window.queryClient?.clear === 'function') {
    window.queryClient.clear();
  }

  if (typeof window.flagsmith?.logout === 'function') {
    flagsmith.logout();
  }

  sessionStorage.clear();

  await REDUX_STORE.dispatch(emitNavigateTo(INTERNAL_LINKS.LOGIN));
};

export const openRequestConfirmationEmailResendToast = (email, resendConfirmationEmail) => {
  const notificiationKey = email;

  return Toast({
    key: notificiationKey,
    type: 'error',
    message: i18n.t('loginError'),
    description: (
      <>
        <Text size="sm" renderAs="span" style={{ display: 'block' }}>
          {i18n.t('pleaseConfirmEmail')}
        </Text>
        <Text size="sm" renderAs="span" style={{ display: 'block' }}>
          {
            <Trans
              i18n={i18n}
              i18nKey="clickHereToRequestANewEmailConfirmation"
              components={[
                <LinkText
                  variant="strong"
                  onClick={async () => {
                    notification.close(notificiationKey);
                    try {
                      await resendConfirmationEmail(email);
                      Toast({
                        type: 'open',
                        message: i18n.t('confirmationEmailResendSuccess'),
                      });
                    } catch (resendError) {
                      handleApiErrors(resendError.response);
                    }
                  }}
                >
                  DummyText
                </LinkText>,
              ]}
            />
          }
        </Text>
      </>
    ),
    duration: null,
  });
};

export const getRedirectUri = () => {
  return encodeURIComponent(`${window.location.pathname}${window.location.search}`);
};
