import { Space } from 'antd';
import React from 'react';

import Text from '../../Text';
import ConfirmModal from '../ConfirmModal';

const CompanyCommuteSettingConfirmModal = props => {
  const { t, visible, commuteSettingLevel, onCancel, onConfirm } = props;

  return (
    <ConfirmModal
      visible={visible}
      message={
        <Space direction="vertical">
          <div>
            <Text>{t('youChangedCommuteDeductionMethod')}</Text>

            <Text variant="b" renderAs="p">
              {commuteSettingLevel === 'company' && t('sameCommuteDeductionAllUser')}
              {commuteSettingLevel === 'group' && t('differentCommuteByGroup')}
            </Text>
          </div>

          <Text>
            {commuteSettingLevel === 'company' && t('allUsersWillHaveTheSameCommuteDeduction')}
            {commuteSettingLevel === 'group' &&
              t('savingThisChangeWillAllowEachGroupToHaveDifferentCommutSettings')}
          </Text>
          <Text>{t('sureYouWantToSaveChanges?')}</Text>
        </Space>
      }
      onCancel={onCancel}
      onOk={onConfirm}
      okText={t('yes')}
    />
  );
};

export default CompanyCommuteSettingConfirmModal;
