import { UploadOutlined } from '@ant-design/icons';
import { Col, Row, Upload } from 'antd';
import { isNull } from 'lodash';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DWOLLA_API, parseDwollaApiErrorMessage } from '../../../../api/dwolla';
import { Button } from '../../../../components';
import GenericModal from '../../../../components/Modal/GenericModal';
import Select from '../../../../components/Select';
import Text from '../../../../components/Text';
import LinkText from '../../../../components/Text/LinkText';
import Toast from '../../../../components/Toast';
import { IMAGES } from '../../../../enum';
import { DWOLLA_BUSINESS_TYPES } from '../../../../enum/Dwolla';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import {
  emitAddDwollaBeneficialOwnerDocuments,
  emitAddDwollaControllerDocuments,
} from '../../../../stores/actions/common';

const AdditionalDocumentsRequiredForVerificationModal = props => {
  const { t, visible, businessType, customerId, beneficialOwnerId, documents, onCancel } = props;

  const dispatch = useDispatch();

  const isEINRequired = businessType && businessType !== DWOLLA_BUSINESS_TYPES.SOLE_PROPRIETORSHIP;
  const [filesToUpload, setFilesToUpload] = useState([{ id: +new Date(), file: null }]);

  const handleAddFileRow = () => {
    setFilesToUpload(state => [...state, { id: +new Date(), file: null }]);
  };

  // const handleRemoveFileRow = () => {
  //   setFilesToUpload(state => [...state, { id: +new Date(), file: null }]);
  // };

  const handleFileChange = (values = {}, index) => {
    setFilesToUpload(state => {
      const files = [...state];
      files[index] = { ...files[index], ...values };
      return files;
    });
  };

  const hasGovernmentID = useMemo(() => {
    const requiredDocumentTypes = ['idCard', 'idcard', 'passport', 'license'];

    const hasDocumentInExisting = documents.find(file =>
      requiredDocumentTypes.includes(file.docType),
    );
    const hasDocumentInRecentlyUploaded = filesToUpload.find(
      file => requiredDocumentTypes.includes(file.type) && file.file?.status === 'done',
    );

    return !!hasDocumentInRecentlyUploaded || !!hasDocumentInExisting;
  }, [filesToUpload, documents]);

  const hasEin = useMemo(() => {
    const requiredDocumentTypes = ['other'];

    const hasDocumentInExisting = documents.find(file =>
      requiredDocumentTypes.includes(file.docType),
    );
    const hasDocumentInRecentlyUploaded = filesToUpload.find(
      file => requiredDocumentTypes.includes(file.type) && file.file?.status === 'done',
    );

    return !!hasDocumentInRecentlyUploaded || !!hasDocumentInExisting;
  }, [filesToUpload, documents]);

  const handleFileUpload = async (options, index) => {
    const { file, onSuccess, onError } = options;

    try {
      const [documentItem] = await DWOLLA_API.uploadDocuments(file, filesToUpload[index].type, {
        customerId,
        beneficialOwnerId,
      });
      onSuccess();

      const document = {
        path: documentItem.path,
        docId: documentItem.status.id,
        status: documentItem.status.status,
        documentVerificationStatus: documentItem.status.documentVerificationStatus,
        docType: documentItem.status.type,
        created: documentItem.status.created,
      };

      if (customerId) {
        dispatch(emitAddDwollaControllerDocuments(customerId, document));
      } else if (beneficialOwnerId) {
        dispatch(emitAddDwollaBeneficialOwnerDocuments(beneficialOwnerId, document));
      }
    } catch (error) {
      onError();
      handleFileChange({ file: null }, index);
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('fileUploadError'),
        });
      });
    }
  };

  const validateImage = file => {
    const MAX_FILE_SIZE = 10;
    const isLt10M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt10M) {
      Toast({
        type: 'error',
        message: `File must smaller than ${MAX_FILE_SIZE}MB`,
      });
    }

    return isLt10M;
  };

  useDidUpdateEffect(() => {
    if (visible) {
      setFilesToUpload([{ id: +new Date(), file: null }]);
    }
  }, [visible]);

  return (
    <GenericModal
      width={800}
      visible={visible}
      onCancel={onCancel}
      title={t('additionalDocumentsRequiredForVerification')}
      footer={
        <Row>
          <Col xs={24} md={12}>
            <ul style={{ marginBottom: 0 }}>
              <li>
                <Text>{t('edgesOfDocumentShouldBeVisible')}</Text>
              </li>
              <li>
                <Text>{t('darkContrastBackground')}</Text>
              </li>
              <li>
                <Text>{t('90imageShouldBeInDocument')}</Text>
              </li>
              <li>
                <Text>{t('shouldBe300dpi')}</Text>
              </li>
              <li>
                <Text>{t('captureImageFromAboveDocument')}</Text>
              </li>
            </ul>
          </Col>

          <Col xs={24} md={12}>
            <ul style={{ marginBottom: 0 }}>
              <li>
                <Text>{t('makeSureImageIsAligned')}</Text>
              </li>
              <li>
                <Text>{t('noFlashToReduceGlare')}</Text>
              </li>
              <li>
                <Text>{t('noBlackAndWhiteDocuments')}</Text>
              </li>
              <li>
                <Text>{t('noExpiredIds')}</Text>
              </li>
            </ul>
          </Col>
        </Row>
      }
    >
      <Row justify="center">
        <Col flex="500px">
          <Row wrap={false} gutter={24} justify="center" align="middle">
            <Col>
              <img width="60px" src={IMAGES.DOCS_ICON} alt="docs" />
            </Col>
            <Col>
              <Text>{t('occassionallyAdditionalDocumentsAreRequired')}</Text>

              <br />

              {isEINRequired && (
                <Row wrap={false} gutter={8} style={{ marginBottom: 10 }}>
                  <Col>
                    <img
                      src={hasEin ? IMAGES.CHECK_MARK_ICON : IMAGES.CIRCLE_REMOVE_ICON}
                      alt="check"
                    />
                  </Col>
                  <Col>
                    <Text>{t('einLetter')}</Text>
                  </Col>
                </Row>
              )}

              <Row wrap={false} gutter={8}>
                <Col>
                  <img
                    src={hasGovernmentID ? IMAGES.CHECK_MARK_ICON : IMAGES.CIRCLE_REMOVE_ICON}
                    alt="check"
                  />
                </Col>
                <Col>
                  <Text>{t('governmentIdPassportLicense')}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <br />

      <Row justify="center">
        <Col flex="500px">
          {filesToUpload.map((file, index) => {
            return (
              <Row wrap={false} gutter={16} key={file.id} style={{ marginBottom: 10 }}>
                <Col flex="300px">
                  <Select
                    fullWidth
                    disabled={file?.file?.status === 'done'}
                    onChange={type => handleFileChange({ type }, index)}
                    options={[
                      { value: 'idCard', label: t('governmentIssuedPhotoIdCard') },
                      { value: 'license', label: t('stateIssuedDriversLicense') },
                      { value: 'other', label: t('businessDocumentsAndExample') },
                    ]}
                  />
                </Col>
                <Col flex="200px" style={{ maxWidth: 200 }}>
                  <Upload
                    multiple={false}
                    fileList={!isNull(file.file) ? [file.file] : []}
                    accept={`image/jpg,image/jpeg,image/pjpeg,image/png${
                      file.type === 'other' ? ',application/pdf' : ''
                    }`}
                    disabled={file?.file?.status === 'done'}
                    beforeUpload={validateImage}
                    customRequest={async options => await handleFileUpload(options, index)}
                    onChange={({ file }) => {
                      if (file && file?.status === 'removed') {
                        handleFileChange({ file: null }, index);
                      } else {
                        handleFileChange({ file }, index);
                      }
                    }}
                  >
                    <Button
                      size="sm"
                      variant="secondary"
                      icon={<UploadOutlined />}
                      disabled={!file.type || file?.file?.status === 'done'}
                    >
                      {t('selectFile')}
                    </Button>
                  </Upload>
                </Col>
              </Row>
            );
          })}

          <br />

          {((isEINRequired && !hasEin) || !hasGovernmentID) && (
            <Row justify="center">
              <LinkText onClick={handleAddFileRow}>+ {t('addAnotherFile')}</LinkText>
            </Row>
          )}

          {(isEINRequired ? hasEin : true) && hasGovernmentID && (
            <Row justify="center">
              <Button onClick={onCancel}>{t('Close')}</Button>
            </Row>
          )}
        </Col>
      </Row>
    </GenericModal>
  );
};

AdditionalDocumentsRequiredForVerificationModal.defaultProps = {
  documents: [],
};

export default AdditionalDocumentsRequiredForVerificationModal;
