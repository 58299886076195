export default {
  BASIC_PLAN: {
    number: 1,
    label: 'Kliks Basic',
  },
  ADVANCED_PLAN: {
    number: 2,
    label: 'Kliks Advanced',
  },
  PREMIUM_PLAN: {
    number: 3,
    label: 'Kliks Premium',
  },
};
