import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from './style.module.scss';

/**
 * Typography component for Kliks
 */
class Text extends Component {
  render() {
    const {
      className,
      variant,
      color,
      size,
      italic,
      children,
      renderAs,
      textAlign,
      ...rest
    } = this.props;

    return React.createElement(renderAs || variant, {
      ...rest,
      children,
      className: [
        classNames.text,
        classNames[textAlign],
        classNames[variant],
        classNames[size],
        classNames[color],
        italic ? classNames.italic : '',
        className || '',
      ]
        .filter(Boolean)
        .join(' '),
    });
  }
}

Text.propTypes = {
  textAlign: PropTypes.string,
  className: PropTypes.string,
  italic: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'faded', 'red']),
  size: PropTypes.oneOf(['xl', 'l', 'large', 'm', 'medium', 'sm', 'xs']),
  children: PropTypes.node,

  /*
   This is used to set _**style**_ of the text based on the Design System.
  */
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'b', 'strong', 'p']),
  /*
   This is the HTML tag that you wish to be used to render the element.
   In case the ones in "variant" are not enough for the use-case.
  */
  renderAs: PropTypes.string,
};

Text.defaultProps = {
  italic: false,
  className: undefined,
  color: undefined,
  size: 'medium',
  renderAs: undefined,
  children: null,
  variant: 'p',
};

export default Text;
