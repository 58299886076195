import { Col, List, notification, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { SENDGRID_API } from '../../../api/sendgrid';
import Box from '../../../components/Box';
import SpaceSpinner from '../../../components/SpaceSpinner';
import Switch from '../../../components/Switch';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import { SENGRID_HIDDEN_EMAIL_OPT_OUT_OPTIONS } from '../../../enum/sendgrid';
import withAuthentication from '../../../hocs/withAuthentication';
import { formatPageTitle, replaceValueInArrayByID } from '../../../utils/common';

const EmailPreferencesView = props => {
  const { t, userDetails } = props;

  const queryClient = useQueryClient();

  const suppressionGroupsQueryKey = ['fetchEmailSuppressionGroups', userDetails._id];

  const suppressionGroupsQuery = useQuery({
    queryKey: suppressionGroupsQueryKey,
    placeholderData: [],
    queryFn: SENDGRID_API.fetchEmailSuppressionGroups,
    select: options => {
      if (Array.isArray(options)) {
        return options.filter(
          option => !SENGRID_HIDDEN_EMAIL_OPT_OUT_OPTIONS.includes(option.name),
        );
      }

      return [];
    },
  });

  const updateSuppressionGroupQuery = (groupId, suppressed) => {
    const currentData = queryClient.getQueryData(suppressionGroupsQueryKey);
    const updatedData = replaceValueInArrayByID(currentData, { id: groupId, suppressed }, 'id');
    queryClient.setQueryData(suppressionGroupsQueryKey, updatedData);
  };

  const addSuppressionGroupMutation = useMutation(
    groupId => {
      notification.close(groupId);
      return SENDGRID_API.addEmailSuppressionGroup(groupId);
    },
    {
      onSuccess: (_, groupId) => {
        updateSuppressionGroupQuery(groupId, true);
        Toast({
          type: 'success',
          key: groupId,
          message: t('emailPreferencesSavedSuccess'),
        });
      },
    },
  );
  const removeSuppressionGroupMutation = useMutation(
    groupId => {
      notification.close(groupId);
      return SENDGRID_API.removeEmailSuppressionGroup(groupId);
    },
    {
      onSuccess: (_, groupId) => {
        updateSuppressionGroupQuery(groupId, false);
        Toast({
          type: 'success',
          key: groupId,
          message: t('emailPreferencesSavedSuccess'),
        });
      },
    },
  );

  if (suppressionGroupsQuery.isFetching || !Array.isArray(suppressionGroupsQuery.data)) {
    return <SpaceSpinner />;
  }

  return (
    <Box>
      <Helmet>
        <title>{formatPageTitle('Email Preferences')}</title>
      </Helmet>

      <Text variant="h5">{t('optOutPreferences')}</Text>

      <div>
        <Text>{t('youCanChooseToOptOutAnyOfTheFollowingEmail')}</Text>
        <Text>{t('yourChangesWillSaveAutomatically')}</Text>
      </div>

      <br />

      <List
        itemLayout="horizontal"
        dataSource={suppressionGroupsQuery.data}
        header={
          <Row justify="space-between" wrap={false}>
            <Col flex={1}>
              <Text variant="b">{t('emailType')}</Text>
            </Col>
            <Col flex="80px">
              <Text variant="b">{t('optOut')}</Text>
            </Col>
          </Row>
        }
        renderItem={group => (
          <List.Item
            extra={[
              <Col flex="80px">
                <Switch
                  size="medium"
                  style={{ width: '60px' }}
                  checkedChildren={t('yes')}
                  unCheckedChildren={t('no')}
                  defaultChecked={group.suppressed}
                  onChange={isSuppressed => {
                    if (isSuppressed) {
                      addSuppressionGroupMutation.mutateAsync(group.id);
                    } else {
                      removeSuppressionGroupMutation.mutateAsync(group.id);
                    }
                  }}
                />
              </Col>,
            ]}
          >
            <List.Item.Meta title={group.name} description={group.description} />
          </List.Item>
        )}
      />
    </Box>
  );
};

export default withNamespaces()(withAuthentication(EmailPreferencesView));
