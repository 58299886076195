import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { getUserFullName } from '../../../utils/common';
import { DataModal } from '../..';
import Select from '../../Select';
import Text from '../../Text';
import TextInput from '../../TextInput';

const ChangeMultipleGroupsManager = props => {
  const {
    t,
    visible,
    onGroupManagerChange,
    onFinish,
    onCancel,
    managersList,
    groupsToChangeList,
  } = props;

  const [form] = Form.useForm();

  const [isChangingManagers, setIsChangingManagers] = useState(false);

  const [changingGroupIDs, setChangingGroupIDs] = useState([]);
  const [changedGroupIDs, setChangedGroupIDs] = useState([]);
  const [erroredGroupIDs, setErroredGroupIDs] = useState([]);

  const [managersToChange, setManagersToChange] = useState([]);
  const [currentManager, setCurrentManager] = useState();

  const [groupManagers, setGroupManagers] = useState({});

  const setFieldValidationStatus = groupID => {
    if (erroredGroupIDs.includes(groupID)) return 'error';
    if (changedGroupIDs.includes(groupID)) return 'success';
    if (changingGroupIDs.includes(groupID)) return 'validating';

    return undefined;
  };

  const clearModalState = () => {
    form.resetFields();
    setGroupManagers({});
    setChangingGroupIDs([]);
    setChangedGroupIDs([]);
    setErroredGroupIDs([]);
  };

  const handleNextManager = async () => {
    setErroredGroupIDs([]);

    form.resetFields();
    setGroupManagers({});

    const remainingGroupManagers = managersToChange.filter(
      manager => currentManager._id !== manager._id,
    );

    if (!!remainingGroupManagers.length) {
      setManagersToChange(remainingGroupManagers);
      setCurrentManager(remainingGroupManagers[0]);
    } else {
      await onCancel(); // close modal

      if (typeof onFinish === 'function') {
        await onFinish();
      }
    }
  };

  const handleOnGroupManagerChange = async (groupIDs, managerID, onError) => {
    const updatedManager = await onGroupManagerChange(groupIDs, managerID);

    if (updatedManager) {
      setChangedGroupIDs(currState => [...currState, ...groupIDs]);
    } else {
      setErroredGroupIDs(currState => [...currState, ...groupIDs]);
      onError();
    }
  };

  const handleSaveAndContinue = async () => {
    if (erroredGroupIDs.length) {
      handleNextManager();
      return;
    }

    await form.validateFields().then(async () => {
      setIsChangingManagers(true);
      let errorsOnCurrentBatch = 0;

      if (!!managersToChange.length) {
        const currentGroupBatch = groupsToChangeList.filter(
          group => group.groupManager._id === currentManager._id,
        );
        setChangingGroupIDs(currentGroupBatch.map(group => group._id));

        const managersWithGroups = {};

        Object.keys(groupManagers).forEach(groupID => {
          const newManagerID = groupManagers[groupID];

          if (Array.isArray(managersWithGroups[newManagerID])) {
            managersWithGroups[newManagerID].push(groupID);
          } else {
            managersWithGroups[newManagerID] = [groupID];
          }
        });

        await Object.keys(managersWithGroups).reduce(async (changePromise, newManagerID, i) => {
          await changePromise;

          return handleOnGroupManagerChange(managersWithGroups[newManagerID], newManagerID, () => {
            errorsOnCurrentBatch += 1;
          });
        }, Promise.resolve());

        if (errorsOnCurrentBatch === 0) {
          handleNextManager();
        }
      } else {
        await onCancel(); // close modal

        if (typeof onFinish === 'function') {
          await onFinish();
        }
      }

      setIsChangingManagers(false);
    });
  };

  useEffect(() => {
    if (visible) {
      clearModalState();

      const managerDictionary = {};
      const uniqueManagersArray = [];

      groupsToChangeList.forEach(group => {
        if (!managerDictionary[group.groupManager._id]) {
          uniqueManagersArray.push(group.groupManager);
        }
      });

      setManagersToChange(uniqueManagersArray);
      setCurrentManager(uniqueManagersArray[0]);
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <DataModal
      width={700}
      footer={null}
      visible={visible}
      onCancel={onCancel}
      okText={t(
        !!erroredGroupIDs.length
          ? 'continueAnyway'
          : managersToChange.length >= 1
          ? 'saveAndContinue'
          : 'Save',
      )}
      onOk={handleSaveAndContinue}
      loading={isChangingManagers}
      title={
        currentManager && (
          <>
            <Text>
              <Trans
                t={t}
                i18nKey="user_managesGroups"
                values={{ name: getUserFullName(currentManager) }}
                components={[<b>dummy</b>]}
              />
            </Text>
            <Text>{t('pleaseChooseNewGroupManager')}</Text>
          </>
        )
      }
    >
      <Form form={form}>
        {currentManager &&
          groupsToChangeList.map(
            (group, i) =>
              group.groupManager._id === currentManager._id && (
                <Row key={group._id} gutter={17}>
                  <Col>
                    <Form.Item labelCol={{ span: 24 }}>
                      <TextInput disabled value={group.name} />
                    </Form.Item>
                  </Col>

                  <Col flex={1}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      hasFeedback={isChangingManagers || !!erroredGroupIDs.length}
                      name={group._id}
                      validateStatus={setFieldValidationStatus(group._id)}
                      rules={[{ required: true, message: t('pleaseSelectOne') }]}
                    >
                      <Select
                        disabled={isChangingManagers}
                        loading={isChangingManagers}
                        placeholder={t('selectManagerOrCompanyAdmin')}
                        value={groupManagers[group._id]}
                        onChange={value =>
                          setGroupManagers({
                            ...groupManagers,
                            [group._id]: value,
                          })
                        }
                        options={managersList.map(user => ({
                          label: getUserFullName(user),
                          value: user._id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ),
          )}
      </Form>
    </DataModal>
  );
};

export default ChangeMultipleGroupsManager;
