import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { DWOLLA_RESOURCES_API } from '../../api/dwolla-resources';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';

/**
 * @param {"mass-payment" | "transfer"} type
 * @param {number} pageNumber
 * @param {number} pageSize
 * @param {import('react-query').UseQueryOptions} otherOptions
 */
const useDwollaResourcesQuery = (type, pageNumber, pageSize, otherOptions = {}) => {
  const currentCompany = useSelector(selectStoreCurrentCompany);

  return useQuery({
    ...otherOptions,
    initialData: { count: 0, documents: [] },
    queryKey: ['dwolla-resources', type, currentCompany._id, pageNumber, pageSize],
    queryFn: () => {
      return DWOLLA_RESOURCES_API.fetchDwollaResources(
        currentCompany._id,
        JSON.stringify(type),
        pageNumber,
        pageSize,
      );
    },
    onError: error => {
      handleApiErrors(error.response);
    },
  });
};

export default useDwollaResourcesQuery;
