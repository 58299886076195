import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { emitFetchCountryRate } from '../stores/actions/common';

/**
 * Hook to to reuse the Rate Form's logic in other parts of the site, like the onboarding rate setup
 *
 * @param {{ formInstance: object, defaultValues: object: onFinish: function }} params
 */
const useOnboardingRateForm = ({ formInstance, defaultValues = {}, onFinish }) => {
  const dispatch = useDispatch();
  const { countryRates } = useSelector(state => state.common);

  const [country, setCountry] = useState();

  const countryRate = useMemo(() => {
    return countryRates.find(rate => rate.country === country);
  }, [countryRates, country]);

  const [bands, setBands] = useState(
    Array.isArray(defaultValues.rates?.bands) && !!defaultValues.rates.bands.length
      ? defaultValues.rates?.bands
      : [{ bandStart: 0, bandEnd: 99999 }],
  );

  useEffect(() => {
    if (country) {
      dispatch(emitFetchCountryRate(country));
    }
    // eslint-disable-next-line
  }, [country]);

  const handleAddBand = () => {
    const bandIndex = Array.isArray(bands) ? bands.length - 1 : 0;
    const newBand = {
      bandStart:
        Array.isArray(bands) && bands[bandIndex]
          ? parseFloat(bands[bandIndex].bandEnd || 0) + 1
          : 0,
    };

    let updatedBands = [];

    if (Array.isArray(bands)) {
      updatedBands = [...bands, newBand];
    } else {
      updatedBands = [newBand];
    }

    setBands(updatedBands);
    formInstance.setFieldsValue({ ['bandStart-' + (updatedBands.length - 1)]: newBand.bandStart });
  };

  const handleRemoveLastBand = () => {
    const updatedBads = [...bands];
    updatedBads.length -= 1; // Remove last item
    setBands(updatedBads);
  };

  const handleBandChange = (index, field, value) => {
    setBands(state => {
      let updatedBands = [...state];
      value = value === '' || value === undefined ? value : parseFloat(value);

      updatedBands[index][field] = value;
      formInstance.setFieldsValue({ [field + '-' + index]: value });

      // auto set next bandStart
      updatedBands = updatedBands.map((band, i) => {
        const updatedBand = band;
        if (i > index && updatedBands[i] && updatedBands[i - 1]) {
          const newValue = parseFloat(updatedBands[i - 1].bandEnd || 0) + 1;
          updatedBand.bandStart = newValue;
          formInstance.setFieldsValue({ ['bandStart-' + i]: newValue });
        }
        return updatedBand;
      });

      // Add a final item with a bandEnd of 99999 if none exists
      const currentLastIndex = updatedBands.length - 1;
      if (parseFloat(updatedBands[currentLastIndex].bandEnd) !== 99999) {
        const finalBand = {
          bandStart: parseFloat(updatedBands[currentLastIndex].bandEnd || 0) + 1,
          bandEnd: 99999,
        };
        updatedBands.push(finalBand);
        const newLastIndex = updatedBands.length - 1;
        formInstance.setFieldsValue({
          ['bandStart-' + newLastIndex]: finalBand.bandStart,
          ['bandEnd-' + newLastIndex]: finalBand.bandEnd,
        });
      }

      return updatedBands;
    });
  };

  const formatBandInitialValues = () => {
    let initialValues = {};

    if (Array.isArray(bands)) {
      bands.forEach((band, i) => {
        initialValues['bandStart-' + i] = band.bandStart;
        initialValues['bandEnd-' + i] = band.bandEnd;
        initialValues['bandRate-' + i] = band.bandRate;
      });
    }

    return initialValues;
  };

  const handleBandsReset = () => {
    const bandStart = 0;
    const bandEnd = 99999;

    if (Array.isArray(bands)) {
      bands.forEach((_, i) => {
        formInstance.setFieldsValue({
          ['bandStart-' + i]: i === 0 ? bandStart : '',
          ['bandEnd-' + i]: i === 0 ? bandEnd : '',
          ['bandRate-' + i]: '',
        });
      });
    }

    setBands([{ bandStart, bandEnd }]);
  };

  return {
    countryRate,
    country,
    bands,
    // Form State changers
    setCountry,
    setBands,
    // Form onChange handlers
    handleAddBand,
    handleBandsReset,
    handleRemoveLastBand,
    handleBandChange,
    formatBandInitialValues,
  };
};

export default useOnboardingRateForm;
