import { Descriptions } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { formatCurrencyToCompanyLocale } from '../../utils/numbers';
import Text from '../Text';
import LinkText from '../Text/LinkText';

const UnpaidDueToInvalidACHInfoSummary = props => {
  const { t, massPaymentId, totalApprovedAmount, totalPaidAmount, onTotalPaidInfoClick } = props;

  return (
    <Descriptions bordered size="small" column={1} contentStyle={{ textAlign: 'right' }}>
      <Descriptions.Item label={<Text variant="b">{t('totalApproved')}</Text>}>
        {formatCurrencyToCompanyLocale(totalApprovedAmount)}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <>
            <Text variant="b" style={{ display: 'block' }}>
              {t('notPaid')}
            </Text>
            <Text variant="b" style={{ display: 'block', maxWidth: 185 }}>
              (
              <Trans
                t={t}
                i18nKey="dueToUsersInvalidACH"
                components={[
                  <LinkText onClick={() => onTotalPaidInfoClick({ massPaymentId })}>
                    dummytext
                  </LinkText>,
                ]}
              />
              )
            </Text>
          </>
        }
      >
        {formatCurrencyToCompanyLocale(totalApprovedAmount - totalPaidAmount)}
      </Descriptions.Item>
      <Descriptions.Item label={<Text variant="b">{t('totalPaid')}</Text>}>
        {formatCurrencyToCompanyLocale(totalPaidAmount)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UnpaidDueToInvalidACHInfoSummary;
