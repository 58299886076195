import { handleApiErrors } from '../../api/axiosInstance';
import { FAVR_COMPLIANCES_API } from '../../api/favr-compliances';
import usePaginatedFiltersQuery from './usePaginatedFiltersQuery';

const usePaginatedComplianceWorklist = (pageFilter = {}) => {
  const {
    query,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      placeholderData: {
        totalCount: 0,
        documents: [],
      },
      queryKey: ['favr-compliances', ...Object.values(pageFilter)],
      queryFn: () =>
        FAVR_COMPLIANCES_API.fetchFavrCompliances(
          pageFilter,
          paginationConfig.current,
          paginationConfig.pageSize,
        ),
      onError: error => handleApiErrors(error.response),
    },
    {
      resetPageQueryKey: Object.values(pageFilter),
    },
    'totalCount',
  );

  return {
    query,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  };
};

export default usePaginatedComplianceWorklist;
