import { Descriptions } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';

import { capitalizeEveryWordOfString } from '../../utils/common';
import { formatNumberToLocale } from '../../utils/numbers';
import Text from '../Text';

const VehicleEmissionsDescriptions = props => {
  const { t, title, vehicle, withExtraFields, ...rest } = props;

  const descriptionItens = useMemo(() => {
    const isElectricVehicle = /^(ev|bev)/gi.test(vehicle?.electrificationLevel);
    return [
      { label: t('make'), content: t(capitalizeEveryWordOfString(vehicle.make || '-')) },
      { label: t('model'), content: t(capitalizeEveryWordOfString(vehicle.model || '-')) },
      { label: t('year'), content: t(vehicle.year || '-') },
      { label: t('type'), content: t(vehicle.vehicleType) || '-' },
      { label: t('bodyClass'), content: t(vehicle.bodyClass) || '-' },
      { label: t('doors'), content: get(vehicle, 'doors', '-') },
      {
        hidden: isElectricVehicle,
        label: t('engineNumberOfCylinders'),
        content: formatNumberToLocale(get(vehicle, 'engineNumberOfCylinders')) || '-',
      },
      {
        hidden: isElectricVehicle,
        label: t('displacementL'),
        content: get(vehicle, 'displacementL') || '-',
      },
      {
        label: t('fuelTypePrimary'),
        content: t(capitalizeEveryWordOfString(vehicle.fuelTypePrimary || '-')),
      },
      {
        hidden: !withExtraFields,
        label: t('city/Highway'),
        content: [
          formatNumberToLocale(get(vehicle, 'city08', '')),
          formatNumberToLocale(get(vehicle, 'highway08', '')),
        ]
          .filter(val => val !== '')
          .join('/'),
      },
      {
        hidden: !withExtraFields,
        label: t('ghgScore'),
        content: formatNumberToLocale(get(vehicle, 'ghgScore', '-')),
      },
      {
        hidden: !withExtraFields,
        label: t('co2eGms'),
        content: formatNumberToLocale(get(vehicle, 'co2TailpipeGpm', '-')),
      },
    ];
  }, [t, vehicle, withExtraFields]);

  return (
    <Descriptions
      {...rest}
      title={<Text variant="h5">{title || t('vehicleInfo')}</Text>}
      layout="vertical"
      bordered
      column={{
        xs: 1,
        sm: 2,
        md: 3,
      }}
    >
      {descriptionItens.map(
        description =>
          !description.hidden && (
            <Descriptions.Item
              key={description.label}
              label={<Text variant="b">{description.label}</Text>}
            >
              {description.content}
            </Descriptions.Item>
          ),
      )}
    </Descriptions>
  );
};

export default VehicleEmissionsDescriptions;
