import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactMapboxGl, { Feature, Layer, Marker, Popup, ZoomControl } from 'react-mapbox-gl';

import { IMAGES } from '../../enum';
import { MAPBOX_LINE_LAYOUT, MAPBOX_LINE_PAINT } from '../../enum/Mapbox';
import useDebouncedState from '../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { getMapBoundaries } from '../../utils/map';
import { FinishPopupContent } from './FinishPopup';
import { StartPopupContent } from './StartPopup';

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
});

const redMarker = <img src={IMAGES.RED_MARK_MAP_ICON} style={{ width: '40px' }} alt="marker" />;

const greenMarker = <img src={IMAGES.GREEN_MARK_MAP_ICON} style={{ width: '40px' }} alt="marker" />;

export const MapboxMap = ({ routes, config }) => {
  const [visibleMarkerPopup, setVisibleMarkerPopup] = React.useState();
  const [mapInstance, setMapInstance] = useState();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [debouncedMapBounds, setDebouncedMapBounds] = useDebouncedState([], 500);

  useDidUpdateEffect(() => {
    if (mapInstance) {
      setTimeout(() => {
        mapInstance.resize();
      }, [200]);
    }
  }, [mapInstance]);

  const polylines = useMemo(
    () =>
      routes.map((route, index) => {
        const lastPolyline = route.polyline.map(point => [point.lng, point.lat]);
        return <Feature key={index} coordinates={lastPolyline} />;
      }),
    [routes],
  );

  const togglePopup = React.useCallback(
    key => {
      if (key === visibleMarkerPopup) {
        setVisibleMarkerPopup();
      } else {
        setVisibleMarkerPopup(key);
      }
    },
    [visibleMarkerPopup],
  );

  const closePopup = React.useCallback(() => {
    setVisibleMarkerPopup();
  }, []);

  const markers = [];
  const popups = [];
  routes.forEach(route => {
    route.points.forEach(point => {
      const props = {
        coordinates: [point.position.lng, point.position.lat],
        key: point.key,
      };
      switch (point.type) {
        case 'start':
          markers.push(
            <Marker
              {...props}
              onClick={() => {
                togglePopup(route.key + 'start');
              }}
            >
              {greenMarker}
            </Marker>,
          );
          popups.push(
            <Popup
              {...props}
              offset={[0, -35]}
              className="route-popup"
              style={{
                display: visibleMarkerPopup === route.key + point.key ? 'flex' : 'none',
              }}
            >
              <StartPopupContent point={point} />
            </Popup>,
          );
          break;
        case 'end':
          markers.push(
            <Marker
              {...props}
              onClick={() => {
                togglePopup(route.key + 'end');
              }}
            >
              {redMarker}
            </Marker>,
          );
          popups.push(
            <Popup
              {...props}
              offset={[0, -35]}
              className="route-popup"
              style={{
                display: visibleMarkerPopup === route.key + point.key ? 'flex' : 'none',
              }}
            >
              <FinishPopupContent point={point} />
            </Popup>,
          );
          break;
        default:
          break;
      }
    });
  });

  useEffect(() => {
    const bounds = [];

    routes.forEach(route => {
      const start = get(route, 'points[0].position');
      const end = get(route, 'points[1].position');
      if (start) bounds.push(start);
      if (end) bounds.push(end);
    });

    setDebouncedMapBounds(getMapBoundaries(bounds));
  }, [routes, setDebouncedMapBounds]);

  return (
    <Map
      center={config.center}
      fitBounds={debouncedMapBounds.length > 1 ? debouncedMapBounds : undefined}
      fitBoundsOptions={{ padding: 100 }}
      // eslint-disable-next-line
      style="mapbox://styles/mapbox/streets-v12"
      containerStyle={{ height: '100%', width: '100%' }}
      onClick={closePopup}
      onRender={setMapInstance}
      onStyleLoad={setIsMapLoaded}
    >
      <Layer
        id="route"
        source="route"
        type="line"
        layout={MAPBOX_LINE_LAYOUT}
        paint={MAPBOX_LINE_PAINT}
      >
        {isMapLoaded && polylines}
      </Layer>

      {markers}
      {popups}
      <ZoomControl position="top-left" />
    </Map>
  );
};

MapboxMap.defaultProps = {};
