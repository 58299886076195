import { Col, Row } from 'antd';
import React from 'react';

import { IMAGES } from '../../enum/';
import FadedText from '../Text/FadedText';

const ImporRequestItemTripStatusCount = props => {
  const { t, validCount, totalCount } = props;

  const IS_WARNING = validCount > 0 && validCount !== totalCount;
  const IS_ERROR = validCount === 0;
  const IS_SUCCESS = validCount === totalCount && !IS_ERROR;

  const imageSharedProps = {
    width: '14px',
    height: '14px',
  };

  return (
    <Row gutter={6} align="middle">
      <Col style={{ minHeight: 26 }}>
        {IS_SUCCESS && (
          <img {...imageSharedProps} alt="success" src={IMAGES.CIRCLE_CHECK_MARK_ICON} />
        )}
        {IS_ERROR && <img {...imageSharedProps} alt="error" src={IMAGES.CIRCLE_REMOVE_ICON} />}
        {IS_WARNING && (
          <img {...imageSharedProps} alt="warning" src={IMAGES.ALERT_WARNING_TRIANGLE_ICON} />
        )}
      </Col>

      <Col>
        <FadedText size="sm">
          {t('nOutOfnTripsImported', {
            validCount,
            count: totalCount,
          })}
        </FadedText>
      </Col>
    </Row>
  );
};

export default ImporRequestItemTripStatusCount;
