import { Space } from 'antd';
import React from 'react';

import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import ReceiptStatusHistoryTable from '../../Table/ReceiptStatusHistoryTable';
import Text from '../../Text';
import GenericModal from '../GenericModal';

const ReceiptStatusHistoryModal = props => {
  const { t, title, visible, statusList, proration, paymentPeriodEnd, onCancel } = props;

  const WINDOW_WIDTH = window.innerWidth - 50;
  const MODAL_WIDTH = WINDOW_WIDTH > 1100 ? 1100 : WINDOW_WIDTH;

  return (
    <GenericModal
      title={title}
      width={MODAL_WIDTH}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <Space size="small" direction="vertical">
        {proration && paymentPeriodEnd && (
          <>
            <Text>
              {t(
                proration.prorationMethod === 'calendar'
                  ? 'fixedAmountProratedByCalendarDays'
                  : 'fixedAmountProratedByBusinessDays',
              )}
            </Text>
            <Text>
              {t('daysRemainingInMonth', {
                month: getMomentDateWithoutTimezone(paymentPeriodEnd).format('MMMM'),
                amount: proration.numberOfWorkedDays,
              })}
            </Text>
          </>
        )}

        <ReceiptStatusHistoryTable t={t} dataSource={statusList} />
      </Space>
    </GenericModal>
  );
};

export default ReceiptStatusHistoryModal;
