import React from 'react';

import { INTERNAL_LINKS } from '../../../enum';
import { capitalizeEveryWordOfString } from '../../../utils/common';
import { formatNumberToLocale } from '../../../utils/numbers';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';

const DriversEmissionsTable = props => {
  const { t, dataSource, ...rest } = props;

  const COLUMNS = [
    {
      width: 150,
      title: t('name'),
      sorter: true,
      defaultSortOrder: 'ascend',
      key: ['userId.lastName', 'userId.firstName'].toString(),
      render: (actions, data) => (
        <ProfileNamePicture
          t={t}
          user={data.userId}
          redirectTo={`${INTERNAL_LINKS.USER_DETAILS}/${data.userId._id}${INTERNAL_LINKS.EMISSIONS_DETAILS}`}
        />
      ),
    },
    {
      title: t('year'),
      dataIndex: ['vehicle', 'year'],
      key: 'vehicle.year',
      sorter: true,
    },
    {
      title: t('make'),
      dataIndex: ['vehicle', 'make'],
      key: 'vehicle.make',
      sorter: true,
      render: make => capitalizeEveryWordOfString(make),
    },
    {
      title: t('model'),
      dataIndex: ['vehicle', 'model'],
      key: 'vehicle.model',
      sorter: true,
      render: model => capitalizeEveryWordOfString(model),
    },
    {
      title: t('fuelTypePrimary'),
      dataIndex: ['vehicle', 'fuelTypePrimary'],
      key: 'vehicle.fuelTypePrimary',
      sorter: true,
    },
    {
      title: t('ghgScore'),
      dataIndex: ['vehicle', 'ghgScore'],
      key: 'vehicle.ghgScore',
      sorter: true,
    },
    {
      title: t('businessMileage'),
      dataIndex: 'mileage',
      key: 'mileage',
      sorter: true,
      render: mileage => formatNumberToLocale(mileage),
    },
    {
      title: t('co2e'),
      dataIndex: 'co2Emission',
      key: 'co2Emission',
      sorter: true,
      render: co2Emission => `${formatNumberToLocale(co2Emission)} lbs`,
    },
  ];

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

export default DriversEmissionsTable;
