import { createReducer } from '../../utils/storeCreators';
import { COLLAPSE_LAYOUT_SIDEBAR, EXPAND_LAYOUT_SIDEBAR } from '../actions/layout';
import initialState from '../initialState';

const layoutReducer = createReducer({
  initialState: initialState.layout,
  reducer: (state, action) => {
    switch (action.type) {
      case COLLAPSE_LAYOUT_SIDEBAR:
        state.sidebarCollapsed = true;
        return { ...state };
      case EXPAND_LAYOUT_SIDEBAR:
        state.sidebarCollapsed = false;
        return { ...state };
      default:
        return state;
    }
  },
});

export default layoutReducer;
