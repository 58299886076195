import { Col, Row, Tabs } from 'antd';
import queryString from 'querystring';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { downloadUserDataFile, fetchUserDataActions } from '../../api/userDataActions';
import Box from '../../components/Box';
import Spinner from '../../components/Spinner';
import TabsContainer from '../../components/TabsContainer';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import { selectStoreCurrentAuthUser } from '../../utils/storeSelectors';
import StatusTab from '../gdpr/components/StatusTab';

const STATUS_TAB_KEY = 'status';

const ExportDataView = props => {
  const { t, location, history } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);

  const { activeTab } = queryString.parse(location.search.replace('?', ''));
  const [activePane, setActivePane] = useState(activeTab || STATUS_TAB_KEY);

  const [downloadingRequestID, setDownloadingRequestID] = useState();

  const handleTabChange = tabKey => {
    setActivePane(tabKey);
    history.replace({ search: `activeTab=${tabKey}` });
  };

  const userDataActionsQuery = useQuery({
    queryKey: ['fetchUserDataActions', 'export'],
    queryFn: () => fetchUserDataActions({ subtype: 'export' }),
  });

  const downloadFile = async (blob, filename) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.csv`;
    a.click();
  };

  const handleDownload = async (id, filename) => {
    setDownloadingRequestID(id);
    try {
      const blob = await downloadUserDataFile(id);
      await downloadFile(blob, filename);

      Toast({
        type: 'success',
        message: t('requestDownloaded'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorDownloadingDataRequest'),
        });
      });
    }
    setDownloadingRequestID(undefined);
  };

  return (
    <PageContainer title={t('exports')}>
      <Box>
        <TabsContainer activeKey={activePane} onChange={handleTabChange}>
          <Tabs.TabPane key={STATUS_TAB_KEY} tab={t('status')}>
            {userDataActionsQuery.isFetching || !Array.isArray(userDataActionsQuery.data) ? (
              <Row justify="center" align="middle">
                <Col style={{ minHeight: 300 }}>
                  <Spinner />
                </Col>
              </Row>
            ) : (
              <StatusTab
                isAdmin
                t={t}
                loggedInUserID={authUser.profile._id}
                downloadingRequestID={downloadingRequestID}
                requests={userDataActionsQuery.data.filter(
                  request => request.requestingUser._id === authUser.profile._id,
                )}
                onDownload={(id, request) => handleDownload(id, request.filename)}
              />
            )}
          </Tabs.TabPane>
        </TabsContainer>
      </Box>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(ExportDataView));
