import { Col, Row, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import { getUserFullName, momentFormat } from '../../utils/common';
import { checkForCreateErrorsOnImportRequest } from '../../utils/importRequest';
import { HelpIcon } from '..';
import Button from '../Button';
import ImporRequestItemTripStatusCount from '../ImporRequestItemTripStatusCount';
import RowButton from '../RowButton';
import StatusTag from '../Tag/StatusTag';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import LinkText from '../Text/LinkText';

const ImportRequestItem = props => {
  const { t, importType, onDownloadCSV, onCancel, request, onViewImportedTripsClick } = props;

  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
  const [isCancellingRequest, setIsCancellingRequest] = useState(false);

  const handleDownloadCSV = async () => {
    setIsDownloadingCSV(true);
    await onDownloadCSV(request._id, request.fileName);
    setIsDownloadingCSV(false);
  };

  const handleCancel = async () => {
    setIsCancellingRequest(true);
    await onCancel(request._id);
    setIsCancellingRequest(false);
  };

  const TITLES = {
    trips: t('importTrips'),
    locations: t('importLocations'),
  };

  const [validTripsCount, totalTrips] = useMemo(() => {
    let validCount = 0;

    request.validation.forEach(trip => {
      if (!['invalid', 'error'].includes(trip.result)) {
        validCount += 1;
      }
    });

    return [validCount, request.validation.length];
  }, [request]);

  const handleViewImportedTrips = () => {
    if (typeof onViewImportedTripsClick === 'function') {
      onViewImportedTripsClick(request);
    }
  };

  const DETAILS_REDIRECT_URL =
    importType === 'trips'
      ? `${INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS}/${request._id}`
      : `${INTERNAL_LINKS.LOCATIONS_DETAILS}/${request._id}`;

  return (
    <div>
      <RowButton
        clickable={false}
        title={TITLES[importType]}
        icon={IMAGES.CSV_FILE_ICON}
        content={
          <>
            <Row align="middle" gutter={17}>
              <Col>
                <Text variant="strong" size="sm">
                  {TITLES[importType]}
                </Text>
              </Col>

              <Col>
                {isDownloadingCSV ? (
                  <Text size="sm">
                    <Spin size="small" /> {t('fetching')}
                  </Text>
                ) : (
                  <LinkText size="sm" onClick={handleDownloadCSV}>
                    {t('downloadCSV')}
                  </LinkText>
                )}
              </Col>

              {request.status === STATUS_LIST().Status.COMPLETED && importType === 'trips' && (
                <Col>
                  <LinkText size="sm" onClick={handleViewImportedTrips}>
                    {t('viewCreatedTrips')}
                  </LinkText>
                </Col>
              )}
            </Row>

            <FadedText size="sm">
              <Trans
                t={t}
                i18nKey={'requestOnDateBy'}
                values={{
                  date: momentFormat(request.created, 'LL'),
                  time: momentFormat(request.created, 'hh:mm A'),
                  name: request.userId.name || getUserFullName(request.userId),
                }}
                components={[<span style={{ whiteSpace: 'nowrap' }}>DummyText</span>]}
              />
            </FadedText>

            {request.status === STATUS_LIST().Status.COMPLETED && (
              <FadedText size="sm">
                <Trans
                  t={t}
                  i18nKey={'completedOnDate'}
                  values={{
                    date: momentFormat(request.completedDate || new Date().toISOString(), 'LL'),
                    time: momentFormat(
                      request.completedDate || new Date().toISOString(),
                      'hh:mm A',
                    ),
                  }}
                  components={[<span style={{ whiteSpace: 'nowrap' }}>DummyText</span>]}
                />
              </FadedText>
            )}
          </>
        }
        sideContent={
          <>
            <Row justify="end" align="middle" gutter={17}>
              <Col>
                <Row gutter={7} align="middle" justify="end">
                  {[STATUS_LIST().Status.COMPLETED].includes(request.status) &&
                    checkForCreateErrorsOnImportRequest(request) && (
                      <Col>
                        <Row gutter={16} align="middle" wrap={false}>
                          <Col>
                            <Link to={DETAILS_REDIRECT_URL}>
                              <Button size="sm" type="primary" disabled={isCancellingRequest}>
                                {t('viewErrors')}
                              </Button>
                            </Link>
                          </Col>

                          <Col>
                            <StatusTag status={STATUS_LIST().Status.COMPLETED_WITH_ERRORS} />
                          </Col>

                          <Col>
                            <HelpIcon hint={t('someFailedToBeCreated_clickOnToSeeMore')} />
                          </Col>
                        </Row>
                      </Col>
                    )}

                  {[STATUS_LIST().Status.AWAITING_CONFIRMATION].includes(request.status) && (
                    <Col>
                      <Link to={DETAILS_REDIRECT_URL}>
                        <Button size="sm" type="primary" disabled={isCancellingRequest}>
                          {t('viewDetails')}
                        </Button>
                      </Link>
                    </Col>
                  )}

                  {![STATUS_LIST().Status.COMPLETED, STATUS_LIST().Status.CANCELLED].includes(
                    request.status,
                  ) && (
                    <Col>
                      <Button
                        size="sm"
                        type="secondary"
                        onClick={handleCancel}
                        loading={isCancellingRequest}
                        disabled={isCancellingRequest}
                      >
                        {t('Cancel')}
                      </Button>
                    </Col>
                  )}

                  {!(
                    [STATUS_LIST().Status.COMPLETED].includes(request.status) &&
                    checkForCreateErrorsOnImportRequest(request)
                  ) && (
                    <Col>
                      <StatusTag status={request.status} />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {importType === 'trips' && [STATUS_LIST().Status.COMPLETED].includes(request.status) && (
              <Row justify="end">
                <ImporRequestItemTripStatusCount
                  t={t}
                  validCount={validTripsCount}
                  totalCount={totalTrips}
                />
              </Row>
            )}
          </>
        }
      />
    </div>
  );
};

export default ImportRequestItem;
