import { createReducer } from '../../utils/storeCreators';
import { UPDATE_GROUP_LIST } from '../actions/group';
import initialState from '../initialState';

const groupReducer = createReducer({
  initialState: initialState.group,
  reducer: (state, action) => {
    let group = state;
    switch (action.type) {
      case UPDATE_GROUP_LIST:
        group.groupList = action.data.groupList;
        group.fetchFlag = true;
        return { ...state, ...group };
      default:
        return state;
    }
  },
});

export default groupReducer;
