import { Col, Row } from 'antd';
import { first, get, last, mapKeys } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { ConfirmModal, CustomRadio } from '../../../components';
import Box from '../../../components/Box';
import FormItem from '../../../components/Form/FormItem';
import CreditCardFormModal from '../../../components/Modal/CreditCardFormModal';
import Text from '../../../components/Text';
import PageContainer from '../../../containers/PageContainer';
import { PREFERRED_PAYMENT_METHODS } from '../../../enum/Billing';
import withPageState from '../../../hocs/withPageState';
import useStripeCreditCards from '../../../hooks/useStripeCreditCards';
import { formatPageTitle } from '../../../utils/common';
import {
  selectStoreCompanySettings,
  selectStoreCompanyValuesSettings,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import CreditCardSelectionSection from './CreditCardSelectionSection';
import InvoiceSection from './InvoiceSection';
import useBillingMethodState from './useBillingMethodState';

const PAGE_TITLE = 'Update your payment method';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle(PAGE_TITLE)}</title>
  </Helmet>
);

const BillingMethod = props => {
  const { t, location } = props;
  const companySetting = useSelector(selectStoreCompanySettings);
  const companySettingValues = useSelector(selectStoreCompanyValuesSettings);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const {
    invoiceSubmitMutation,
    preferredPaymentMethodMutation,
    selectedPaymentMethod,
  } = useBillingMethodState(props);

  const {
    closeCreditCardModal,
    closeDeleteCreditCardModal,
    creditCardToEdit,
    deleteCardMutation,
    fetchMoreStripeCards,
    handleCreditCardSubmitMutation,
    hasMoreStripeCards,
    isCreditCardModalVisible,
    isDeleteCardModalOpen,
    isFetchingStripeInformation,
    openCreditCardModal,
    openDeleteCreditCardModal,
    openEditCreditCardModal,
    setDefaultCardMutation,
    stripeCards,
    stripeCustomerQuery,
  } = useStripeCreditCards({
    t,
    location,
    preferredPaymentMethod: selectedPaymentMethod,
  });

  const invoiceInitialValues = useMemo(() => {
    if (!stripeCustomerQuery.isFetched) return;

    const customerFullName = (get(stripeCustomerQuery, 'data.name') || '').split(' ');

    return {
      ...(get(stripeCustomerQuery, 'data.address') ||
        mapKeys(currentCompany.address, (_, key) => {
          if (key === 'streetOne') return 'line1';
          if (key === 'streetTwo') return 'line2';
          if (key === 'postalCode') return 'postal_code';
          return key;
        })),
      phone: get(stripeCustomerQuery, 'data.phone') || currentCompany.phone,
      email: get(stripeCustomerQuery, 'data.email') || currentCompany.email,
      firstName: first(customerFullName),
      lastName: last(customerFullName),
    };
  }, [stripeCustomerQuery, currentCompany]);

  return (
    <PageContainer title={PAGE_TITLE} loading={!stripeCustomerQuery.isFetched}>
      <PageHelmet />

      <Row gutter={[16, 16]} style={{ justifyContent: 'flex-start', maxWidth: 832 }}>
        <Col xs={24}>
          <Box>
            <Text variant="h5">{t('preferredPaymentMethod')}</Text>

            <FormItem>
              <CustomRadio.Group
                value={selectedPaymentMethod}
                onChange={e => preferredPaymentMethodMutation.mutateAsync(e.target.value)}
              >
                <CustomRadio value={PREFERRED_PAYMENT_METHODS.CREDIT_CARD}>
                  {t('creditCard')}
                </CustomRadio>
                <CustomRadio
                  value={PREFERRED_PAYMENT_METHODS.SEND_INVOICE}
                  disabled={!get(companySetting, 'allow_invoice_for_billing', false)}
                >
                  {t('invoice')}
                </CustomRadio>
              </CustomRadio.Group>
            </FormItem>
          </Box>
        </Col>

        {stripeCustomerQuery.isFetched && invoiceInitialValues && (
          <Col xs={24}>
            <Box>
              <InvoiceSection
                t={t}
                paymentTerms={get(companySettingValues, 'stripe.invoiceDaysUntilDue', 30)}
                isLoading={isFetchingStripeInformation}
                isSubmitting={invoiceSubmitMutation.isLoading}
                onSubmit={invoiceSubmitMutation.mutateAsync}
                needsConfirmation={!get(stripeCustomerQuery, 'data.name')}
                initialValues={invoiceInitialValues}
                isInvoicePreferred={
                  selectedPaymentMethod === PREFERRED_PAYMENT_METHODS.SEND_INVOICE
                }
              />
            </Box>
          </Col>
        )}

        {selectedPaymentMethod === PREFERRED_PAYMENT_METHODS.CREDIT_CARD && (
          <Col xs={24}>
            <Box>
              <CreditCardSelectionSection
                t={t}
                isLoading={isFetchingStripeInformation || setDefaultCardMutation.isLoading}
                cards={stripeCards.otherCards}
                defaultCard={stripeCards.defaultCard}
                hasMoreCards={hasMoreStripeCards}
                onFetchMoreCards={fetchMoreStripeCards}
                onAddClick={openCreditCardModal}
                onEditClick={openEditCreditCardModal}
                onDefaultClick={setDefaultCardMutation.mutateAsync}
                onDeleteClick={openDeleteCreditCardModal}
              />
            </Box>
          </Col>
        )}
      </Row>

      <CreditCardFormModal
        t={t}
        isUpdate={!!creditCardToEdit}
        visible={isCreditCardModalVisible}
        onCancel={closeCreditCardModal}
        isLoading={handleCreditCardSubmitMutation.isLoading}
        onSubmit={handleCreditCardSubmitMutation.mutateAsync}
        initialValues={creditCardToEdit}
      />

      <ConfirmModal
        visible={isDeleteCardModalOpen}
        loading={deleteCardMutation.isLoading}
        message={<Text>{t('sureYouWantToDeleteCreditCard')}</Text>}
        cancelText={t('no')}
        onCancel={closeDeleteCreditCardModal}
        okText={t('yes')}
        onOk={deleteCardMutation.mutateAsync}
      />
    </PageContainer>
  );
};

BillingMethod.propTypes = {
  data: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

BillingMethod.defaultProps = {
  data: {},
  readOnly: false,
  onChange: () => {},
};

export default withPageState(BillingMethod, true);
