import React, { useState } from 'react';

import { getUserFullName, momentFormat } from '../../../utils/common';
import {
  renderBooleanCheckmarks,
  sortColumnByBoolean,
  sortColumnByNumber,
  sortColumnByStringField,
} from '../../../utils/tables';
import { CustomTable } from '../..';
import TripPurposeChip from '../../Chip/TripPurposeChip';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import LinkText from '../../Text/LinkText';
import Tooltip from '../../Tooltip';

const TripsCsvErrorsTable = props => {
  const { t, dataSource, ...rest } = props;

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const toggleExpandedRow = row => {
    if (!expandedRowKeys.includes(row.rowNumber)) {
      setExpandedRowKeys([row.rowNumber]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const COLUMNS = [
    {
      key: 'rowNumber',
      title: t('rowNumber'),
      dataIndex: 'rowNumber',
      ...sortColumnByNumber('rowNumber'),
    },
    {
      key: 'employeeEmail',
      title: t('employeeEmail'),
      dataIndex: ['importData', 'employeeEmail'],
      render: email => email || '-',
      sorter: (a, b) => {
        const emailA = a?.importData?.employeeEmail || '-';
        const emailB = b?.importData?.employeeEmail || '-';
        return emailA.localeCompare(emailB);
      },
    },
    {
      key: 'employeeName',
      title: t('employeeName'),
      dataIndex: ['fields', 'userId', 'name'],
      render: (name, data) => {
        if (!name) return '-';

        return data?.fields?.userId ? <ProfileNamePicture t={t} user={data.fields.userId} /> : '-';
      },
      sorter: (a, b) => {
        const joinedNameA = a?.fields?.userId ? getUserFullName(a?.fields?.userId) : '-';
        const joinedNameB = b?.fields?.userId ? getUserFullName(b?.fields?.userId) : '-';
        return joinedNameA.localeCompare(joinedNameB);
      },
    },
    {
      key: 'originAddress',
      title: t('originAddress'),
      dataIndex: ['importData', 'tripOriginAddress'],
      render: (address, data) => {
        if (!address) return '-';

        if (data?.importData?.tripOriginCompanyLocation) {
          return (
            <Tooltip title={address}>
              <LinkText style={{ minWidth: 150 }}>
                {data.importData.tripOriginCompanyLocation}
              </LinkText>
            </Tooltip>
          );
        }

        return <p style={{ minWidth: 150 }}>{address}</p>;
      },
      sorter: (a, b) => {
        return sortColumnByStringField('address').sorter(
          { address: a?.importData?.tripOriginAddress || '-' },
          { address: b?.importData?.tripOriginAddress || '-' },
        );
      },
    },
    {
      key: 'destinationAddress',
      title: t('destinationAddress'),
      dataIndex: ['importData', 'tripDestinationAddress'],
      render: (address, data) => {
        if (!address) return '-';

        if (data?.importData?.tripDestinationCompanyLocation) {
          return (
            <Tooltip title={address}>
              <LinkText style={{ minWidth: 150 }}>
                {data.importData.tripDestinationCompanyLocation}
              </LinkText>
            </Tooltip>
          );
        }

        return <p style={{ minWidth: 150 }}>{address}</p>;
      },
      sorter: (a, b) => {
        return sortColumnByStringField('address').sorter(
          { address: a?.importData?.tripDestinationAddress || '-' },
          { address: b?.importData?.tripDestinationAddress || '-' },
        );
      },
    },
    {
      width: 100,
      key: 'originDate',
      title: t('originDate'),
      dataIndex: ['importData', 'tripOriginStartDate'],
      render: (timestamp, data) => {
        return <p style={{ minWidth: 140, maxWidth: 'max-content' }}>{timestamp || '-'}</p>;
      },
      sorter: (a, b) => {
        return sortColumnByStringField('timestamp').sorter(
          {
            timestamp: a?.importData?.tripOriginStartDate || '-',
          },
          {
            timestamp: b?.importData?.tripOriginStartDate || '-',
          },
        );
      },
    },
    {
      width: 140,
      key: 'originTime',
      title: t('originTime'),
      dataIndex: ['importData', 'tripOriginStartTime'],
      render: (timestamp, data) => {
        return <p style={{ minWidth: 140, maxWidth: 'max-content' }}>{timestamp || '-'}</p>;
      },
      sorter: (a, b) => {
        return sortColumnByStringField('timestamp').sorter(
          {
            timestamp: a?.importData?.tripOriginStartTime || '-',
          },
          {
            timestamp: b?.importData?.tripOriginStartTime || '-',
          },
        );
      },
    },
    {
      width: 100,
      key: 'destinationDate',
      title: t('destinationDate'),
      dataIndex: ['importData', 'tripDestinationEndDate'],
      render: (timestamp, data) => {
        return <p style={{ minWidth: 140, maxWidth: 'max-content' }}>{timestamp || '-'}</p>;
      },
      sorter: (a, b) => {
        return sortColumnByStringField('timestamp').sorter(
          {
            timestamp: a?.importData?.tripDestinationEndDate || '-',
          },
          {
            timestamp: b?.importData?.tripDestinationEndDate || '-',
          },
        );
      },
    },
    {
      width: 140,
      key: 'destinationTime',
      title: t('destinationTime'),
      dataIndex: ['importData', 'tripDestinationEndTime'],
      render: (timestamp, data) => {
        return <p style={{ minWidth: 140, maxWidth: 'max-content' }}>{timestamp || '-'}</p>;
      },
      sorter: (a, b) => {
        return sortColumnByStringField('timestamp').sorter(
          {
            timestamp: a?.importData?.tripDestinationEndTime || '-',
          },
          {
            timestamp: b?.importData?.tripDestinationEndTime || '-',
          },
        );
      },
    },
    {
      width: 100,
      key: 'purpose',
      title: t('purpose'),
      dataIndex: ['importData', 'purpose'],
      render: purpose => (purpose ? <TripPurposeChip purpose={purpose.toLowerCase()} /> : '-'),
      sorter: (a, b) => {
        return sortColumnByStringField('purpose').sorter(
          { purpose: a?.importData?.purpose || '-' },
          { purpose: b?.importData?.purpose || '-' },
        );
      },
    },
    {
      key: 'useTollRoads',
      title: t('useTollRoads'),
      dataIndex: ['importData', 'useTollRoads'],
      render: useTollRoads => {
        return renderBooleanCheckmarks('useTollRoads').render({ useTollRoads });
      },
      sorter: (a, b) => {
        return sortColumnByBoolean('useTollRoads').sorter(
          { purpose: a?.importData?.useTollRoads },
          { purpose: b?.importData?.useTollRoads },
        );
      },
    },
    {
      key: 'autoSubmit',
      title: t('autoSubmit'),
      dataIndex: ['importData', 'autoSubmit'],
      render: autoSubmit => {
        return renderBooleanCheckmarks('autoSubmit').render({ autoSubmit });
      },
      sorter: (a, b) => {
        return sortColumnByBoolean('autoSubmit').sorter(
          { purpose: a?.importData?.autoSubmit },
          { purpose: b?.importData?.autoSubmit },
        );
      },
    },
  ];

  return (
    <CustomTable
      {...rest}
      rowKey={data => data.rowNumber}
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource}
      scroll={{ x: 970 }}
      withExpandableRows
      expandedRowKeys={expandedRowKeys}
      onExpand={(expanded, record) => {
        toggleExpandedRow(record);
      }}
      onRow={row => ({
        onClick: e => {
          e.preventDefault();
          e.stopPropagation();
          toggleExpandedRow(row);
        },
      })}
      expandedRowRender={data => {
        return (
          <ul style={{ margin: 0 }}>
            {Array.isArray(data.errors) &&
              data.errors.map(({ param, message }, i) => {
                return (
                  <li style={{ marginTop: i > 0 ? 12 : 0 }}>
                    <p>
                      <b>{t('item')}</b>: {param}
                    </p>
                    <p>
                      <b>{t('providedValue')}</b>: {data.importData[param]}
                    </p>
                    <p>
                      <b>{t('error')}</b>: {message}
                    </p>
                  </li>
                );
              })}

            {Array.isArray(data.createErrors) &&
              data.createErrors.map(({ timestamp, message }, i) => {
                return (
                  <li style={{ marginTop: i > 0 ? 12 : 0 }}>
                    <p>
                      <b>{t('time')}</b>: {momentFormat(timestamp, 'LL [-] hh:mm a zz')}
                    </p>
                    <p>
                      <b>{t('error')}</b>: {message}
                    </p>
                  </li>
                );
              })}
          </ul>
        );
      }}
    />
  );
};

export default TripsCsvErrorsTable;
