import React from 'react';

import ConfirmModal from '../ConfirmModal';

const ReimbursementRejectConfirmation = props => {
  const { t, isRejecting, visible, onConfirm, onCancel } = props;

  return (
    <ConfirmModal
      loading={isRejecting}
      visible={visible}
      title={t('sureYouWantToRejectReimbursement')}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={t('yes')}
      cancelText={t('no')}
    />
  );
};

export default ReimbursementRejectConfirmation;
