import { Layout } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import storage from 'store';

import CompanySubscriptionInactiveWarningBar from '../../components/CompanySubscriptionInactiveWarningBar';
import ImpersonationWarningBar from '../../components/ImpersonationWarningBar';
import { isCurrentCompanySubActive } from '../../utils/company';
import { hasCompanyAdminRole } from '../../utils/roles';
import { selectStoreCurrentAuthUser } from '../../utils/storeSelectors';
import LayoutHeader from '../LayoutHeader';
import classNames from '../style.module.scss';
import useLayout from '../useLayout';

const LayoutContent = props => {
  const { withBasicHeader, children } = props;

  const { browserStore, layoutStore, handleStopImpersonation } = useLayout({
    history: props.history,
  });

  const authUser = useSelector(selectStoreCurrentAuthUser);
  const isCompanyActive = useSelector(isCurrentCompanySubActive);

  const IS_IMPERSONATING = !!storage.get('previousAccessToken');

  const stopMobilePropagationSidebarOpen = event => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  };

  return (
    <Layout className="site-layout">
      {browserStore.isMobile && !layoutStore.sidebarCollapsed && (
        <div
          className={classNames.layoutContentClickCatch}
          onClick={stopMobilePropagationSidebarOpen}
        />
      )}

      {IS_IMPERSONATING && authUser?.profile && (
        <ImpersonationWarningBar
          t={props.t}
          impersonatedProfile={authUser.profile}
          onStopImpersonating={handleStopImpersonation}
        />
      )}

      {!isCompanyActive && hasCompanyAdminRole(authUser) && (
        <CompanySubscriptionInactiveWarningBar t={props.t} />
      )}

      {/* Header */}
      <LayoutHeader t={props.t} history={props.history} basicHeader={withBasicHeader} />

      {/* Content / Body */}
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};

export default LayoutContent;
