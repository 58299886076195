import { Col, Row } from 'antd';
import React from 'react';

import Checkbox from '../../../components/Checkbox';
import classNames from './style.module.scss';

const SelectPaymentScheduleGroups = props => {
  const { groups, selectedGroups, onGroupSelect } = props;

  const totalUsers = groups.reduce((total, group) => {
    return total + group.users.length;
  }, 0);

  const toggleSelectAll = event => {
    if (event.target.checked) {
      onGroupSelect(groups.map(group => group._id));
    } else {
      onGroupSelect([]);
    }
  };

  const selectGroup = id => {
    onGroupSelect([...selectedGroups, id]);
  };

  const removeGroup = id => {
    onGroupSelect(selectedGroups.filter(groupId => groupId !== id));
  };

  return (
    <div>
      <Row className={classNames.kliksRow}>
        <Checkbox
          checked={groups.length > 0 && selectedGroups.length === groups.length}
          onChange={toggleSelectAll}
        >
          All groups ({totalUsers} users)
        </Checkbox>
      </Row>

      <Row>
        <Col className={classNames.subColWrapper}>
          {groups.map((group, i) => (
            <Row className={classNames.kliksRow} key={group._id}>
              <Checkbox
                checked={selectedGroups.includes(group._id)}
                onChange={e => (e.target.checked ? selectGroup(group._id) : removeGroup(group._id))}
              >
                {group.name} ({group.users.length} users)
              </Checkbox>
            </Row>
          ))}
        </Col>
      </Row>
    </div>
  );
};

SelectPaymentScheduleGroups.defaultProps = {
  groups: [],
  selectedGroups: [],
};

export default SelectPaymentScheduleGroups;
