import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import classNames from './style.module.scss';

const TabsContainer = props => {
  const { activeKey, onChange, children, ...rest } = props;

  return (
    <Tabs {...rest} className={classNames.tab} activeKey={activeKey} onChange={onChange}>
      {children}
    </Tabs>
  );
};

TabsContainer.propTypes = {
  activeKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
      children: PropTypes.node,
      disabled: PropTypes.bool,
    }),
  ),
};

TabsContainer.TabsPane = Tabs.TabPane;

export default TabsContainer;
