import { Col, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { FAVR_COMPLIANCES_API } from '../../../api/favr-compliances';
import Box from '../../../components/Box';
import PageBreadcrumbs from '../../../components/PageBreadcrumbs';
import SpaceSpinner from '../../../components/SpaceSpinner';
import ComplianceUserDetailsTable from '../../../components/Table/ComplianceUserDetailsTable';
import ComplianceUserHistoryTable from '../../../components/Table/ComplianceUserHistoryTable';
import Text from '../../../components/Text';
import AmountTextReverseColors from '../../../components/Text/AmountTextReverseColors';
import PageContainer from '../../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../../enum';
import withAuthentication from '../../../hocs/withAuthentication';
import usePrevLocationSearch from '../../../hooks/usePrevLocationSearch';
import useTablePagination from '../../../hooks/useTablePagination';
import { formatPageTitle, getUserFullName } from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import useUserFavrComplianceTaxReport from '../useUserFavrComplianceTaxReport';

const PAGE_HEADER = (
  <Helmet>
    <title>{formatPageTitle('User Program Compliance Details')}</title>
  </Helmet>
);

const ComplianceStatusDetailsView = props => {
  const { t, match, history } = props;

  const userComplianceDetails = useUserFavrComplianceTaxReport(match.params.id);

  const {
    paginationConfig,
    handleTotalUpdate,
    handlePageChange,
    handlePageSizeChange,
  } = useTablePagination();

  const userComplianceDetailsQuery = useQuery({
    enabled: !!match.params.id && !!userComplianceDetails,
    queryKey: ['fetchUserFavrComplianceDetails', match.params.id],
    queryFn: () => FAVR_COMPLIANCES_API.fetchUserFavrComplianceDetails(match.params.id),
    initialData: [],
  });

  const userComplianceHistoryQuery = useQuery({
    enabled: !!match.params.id && !!userComplianceDetails,
    initialData: { documents: [], count: 0 },
    queryKey: [
      'fetchUserFavrComplianceHistory',
      match.params.id,
      paginationConfig.current,
      paginationConfig.pageSize,
    ],
    queryFn: () =>
      FAVR_COMPLIANCES_API.fetchUserFavrComplianceHistory(
        match.params.id,
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    onSuccess: ({ count }) => handleTotalUpdate(count),
  });

  const prevPageSearchParams = usePrevLocationSearch();

  if (!userComplianceDetails) {
    return (
      <>
        {PAGE_HEADER}
        <SpaceSpinner />
      </>
    );
  }

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('userProgramCompliance'),
              onClick: () =>
                history.push({
                  pathname: INTERNAL_LINKS.USER_PROGRAM_COMPLIANCE,
                  search: prevPageSearchParams,
                }),
            },
            { label: getUserFullName(userComplianceDetails) },
          ]}
        />
      }
    >
      {PAGE_HEADER}

      <Row justify="end" align="middle" gutter={[16, 16]}>
        <Col flex="300px">
          <Box>
            <Text variant="h5" textAlign="center">
              {t('taxLiability')}
            </Text>

            <Link to={INTERNAL_LINKS.USER_TAX_REPORT(match.params.id)}>
              <AmountTextReverseColors
                variant="h4"
                renderAs="p"
                textAlign="center"
                amount={formatCurrencyToCompanyLocale(userComplianceDetails?.taxDue || 0)}
              />
            </Link>
          </Box>
        </Col>
      </Row>

      <br />

      <ComplianceUserDetailsTable
        t={t}
        showSearchInput={false}
        loading={userComplianceDetailsQuery.isFetching}
        dataSource={userComplianceDetailsQuery.data}
      />

      <br />

      <Text variant="h4">{t('complianceHistory')}</Text>

      <ComplianceUserHistoryTable
        t={t}
        showSearchInput={false}
        loading={userComplianceHistoryQuery.isFetching}
        dataSource={userComplianceHistoryQuery.data.documents}
        onChange={({ current }) => handlePageChange(current)}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(ComplianceStatusDetailsView));
