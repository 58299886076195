import { createReducer } from '../../utils/storeCreators';
import {
  START_SYSTEM_ADMINS_LOADER,
  STOP_SYSTEM_ADMINS_LOADER,
  UPDATE_COUNTRY_CODE_MAPPINGS,
  UPDATE_COUNTRY_CODE_MAPPINGS_BY_ID,
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_SYSTEM_ADMINS,
} from '../actions/systemAdmin';
import initialState from '../initialState';

const systemAdminReducer = createReducer({
  initialState: initialState.systemAdmin,
  reducer: (state, action) => {
    switch (action.type) {
      case UPDATE_SYSTEM_ADMINS:
        return { ...state, ...action.data };
      case START_SYSTEM_ADMINS_LOADER:
        return { ...state, isLoadingSystemAdmins: true };
      case STOP_SYSTEM_ADMINS_LOADER:
        return { ...state, isLoadingSystemAdmins: false };
      case UPDATE_COUNTRY_CODE_MAPPINGS:
        return { ...state, countryCodeMappings: action.data.countryCodeMappings };
      case UPDATE_COUNTRY_CODE_MAPPINGS_BY_ID:
        const updatedCountries = [...state.countryCodeMappings];
        const updatedCountryIndex = updatedCountries.findIndex(c => c._id === action.data.id);
        updatedCountries[updatedCountryIndex] = action.data.countryCodeMapping;
        return { ...state, countryCodeMappings: updatedCountries };
      case UPDATE_GLOBAL_SETTINGS:
        return { ...state, globalSettings: action.data.globalSettings };
      default:
        return state;
    }
  },
});

export default systemAdminReducer;
