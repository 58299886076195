import React from 'react';
import { Popup } from 'react-mapbox-gl';

import { IMAGES } from '../../enum';
import { momentFormat } from '../../utils/common';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import classNames from './styles.module.scss';

const EventInsightMapPopup = props => {
  const { t, coordinates, event } = props;

  return (
    <Popup coordinates={coordinates} offset={[0, -35]} style={{ width: 300 }}>
      <Text size="xs" variant="strong">
        {event.subject}
      </Text>

      <div className={classNames.contentSection}>
        <div className={classNames.rowWithIcon}>
          <img width="20px" src={IMAGES.TIME_CLOCK_CIRCLE_GRAY} alt="clock" />
          <FadedText size="xs">
            {t('arrival_time', { time: momentFormat(event.endDateTime, 'hh:mm a') })}
          </FadedText>
        </div>

        <div className={classNames.rowWithIcon}>
          <img width="20px" src={IMAGES.MAP_PIN_ICON} alt="map" />
          <FadedText size="xs">{event.locationName}</FadedText>
        </div>
      </div>

      <div className={classNames.contentSection}>
        <Text size="xs" className={classNames.textRow}>
          {t('assignedTo')}:{' '}
          <FadedText size="xs" renderAs="span">
            {event.owner}
          </FadedText>
        </Text>

        {event.associatedPerson && (
          <Text size="xs" className={classNames.textRow}>
            {t('associatedTo')}:{' '}
            <FadedText size="xs" renderAs="span">
              {event.associatedPerson}
            </FadedText>
          </Text>
        )}
      </div>
    </Popup>
  );
};

export default EventInsightMapPopup;
