import { Col, DatePicker, Form, Pagination, Row, Space } from 'antd';
import classnames from 'classnames';
import { last } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import Radio from '../../../components/Radio';
import SpaceSpinner from '../../../components/SpaceSpinner';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import { INTERNAL_LINKS } from '../../../enum';
import { getUserFullName, momentTimezone } from '../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { selectStoreItineraryClientByID } from '../../../utils/storeSelectors';
import classNames from './styles.module.scss';

const DayMapSideMenu = props => {
  const {
    t,
    visible,
    isMobile,
    isLoading,
    itineraries,
    selectedDates,
    selectedItinerary,
    paginationConfig,
    onPageChange,
    onScheduleItineraryClick,
    onItinerarySelect,
    onDateChange,
  } = props;

  const [startDate, setStartDate] = useState(selectedDates[0]);
  const [endDate, setEndDate] = useState(selectedDates[1]);

  const handleStartDateChange = useCallback(
    date => {
      const startNoTimezone = getMomentDateWithoutTimezone(date);
      const currentStartNoTimezone = getMomentDateWithoutTimezone(selectedDates[0]);
      const endNoTimezone = getMomentDateWithoutTimezone(selectedDates[1]);

      if (startNoTimezone.isAfter(endNoTimezone, 'date')) {
        setStartDate(date);
        setEndDate(date);
      } else if (!currentStartNoTimezone.isSame(startNoTimezone)) {
        setStartDate(date);
      }
    },
    [selectedDates],
  );

  const handleEndDateChange = useCallback(
    date => {
      const startNoTimezone = getMomentDateWithoutTimezone(selectedDates[0]);
      const endNoTimezone = getMomentDateWithoutTimezone(date);
      const currentEndNoTimezone = getMomentDateWithoutTimezone(selectedDates[1]);

      if (endNoTimezone.isBefore(startNoTimezone, 'date')) {
        setEndDate(date);
        setStartDate(date);
      } else if (!currentEndNoTimezone.isSame(endNoTimezone)) {
        setEndDate(date);
      }
    },
    [selectedDates],
  );

  const paginator = !!itineraries.length && !isLoading && (
    <Row justify="end">
      <Col>
        <Pagination
          current={paginationConfig.current}
          total={paginationConfig.total}
          pageSize={paginationConfig.pageSize}
          onChange={onPageChange}
        />
      </Col>
    </Row>
  );

  return (
    <div
      className={classnames(classNames.sideMenu, {
        [classNames.hiddenContent]: !visible,
      })}
    >
      <Form labelCol={{ span: 24 }}>
        <Row>
          <Col>
            <Text variant="h5">{t('todaysItineraries')}</Text>
          </Col>
        </Row>

        {isMobile ? (
          <Row>
            <Col xs={24}>
              <Form.Item label={t('from')}>
                <DatePicker
                  disabled={!visible}
                  allowClear={false}
                  value={startDate}
                  style={{ width: '100%' }}
                  onChange={handleStartDateChange}
                  onOpenChange={open => {
                    if (!open) {
                      onDateChange([startDate, endDate]);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label={t('to')}>
                <DatePicker
                  disabled={!visible}
                  allowClear={false}
                  value={endDate}
                  style={{ width: '100%' }}
                  onChange={handleEndDateChange}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Form.Item label={t('date')}>
            <DatePicker.RangePicker
              disabled={!visible}
              allowClear={false}
              defaultValue={selectedDates}
              style={{ width: '100%' }}
              onChange={dates => {
                setStartDate(dates[0]);
                setEndDate(dates[1]);
              }}
              onOpenChange={open => {
                if (!open) {
                  onDateChange([startDate, endDate]);
                }
              }}
            />
          </Form.Item>
        )}

        <section className={classNames.section}>
          {itineraries.length === 0 && !isLoading && <Text>{t('noItinerariesToday')}</Text>}

          {paginator}

          <Radio.Group
            value={selectedItinerary?._id}
            disabled={isLoading || !visible}
            onChange={e => {
              const match = itineraries.find(itinerary => itinerary._id === e.target.value);
              onItinerarySelect(match);
            }}
          >
            <Space direction="vertical">
              {itineraries.length > 0 &&
                !isLoading &&
                itineraries.map(itinerary => {
                  const client = selectStoreItineraryClientByID(
                    itinerary.clientDetails[0]?.clientId,
                  );

                  const lastTrip = last(itinerary.trips);

                  return (
                    <Radio key={itinerary._id} value={itinerary._id}>
                      <Row>
                        <Col>
                          <Text variant="b">
                            {getMomentDateWithoutTimezone(itinerary.dateFrom).format(
                              'dddd, MMM DD, YYYY',
                            )}
                          </Text>
                          <Text>
                            {momentTimezone(itinerary.dateFrom).format('h:mma')} -{' '}
                            {momentTimezone(lastTrip.tripEndTime).format('h:mma z')}
                          </Text>

                          {client && <Text>{getUserFullName(client)}</Text>}

                          <Link to={INTERNAL_LINKS.ITINERARY_DETAILS(itinerary._id)}>
                            <LinkText>{t('viewDetails')}</LinkText>
                          </Link>
                        </Col>
                      </Row>
                    </Radio>
                  );
                })}
            </Space>
          </Radio.Group>

          {paginator}
        </section>

        {isLoading && <SpaceSpinner height={isMobile ? 'max-content' : undefined} />}

        {itineraries.length === 0 && !isLoading && (
          <LinkText variant="b" onClick={visible ? onScheduleItineraryClick : undefined}>
            {t('scheduleItinerary')}
          </LinkText>
        )}
      </Form>
    </div>
  );
};

export default DayMapSideMenu;
