import { call, put, select, takeLatest } from 'redux-saga/effects';

import { CARS_API } from '../../api/cars';
import Toast from '../../components/Toast';
import i18n from '../../i18n';
import * as actions from '../actions/vehicles';

/**
 * Populate the Vehicle years
 */
export function* loadCarYears() {
  try {
    yield put(actions.toggleYearsLoader(true));

    const yearsData = yield call(CARS_API.fetchCarYears);
    yield put(actions.setYearData(yearsData));

    yield put(actions.toggleYearsLoader(false));
  } catch (err) {
    yield put(actions.toggleYearsLoader(false));
    Toast({
      type: 'error',
      message: i18n.t('loadCarYearsError'),
    });
  }
}

export function* loadCarMakes(action) {
  try {
    yield put(actions.toggleMakesLoader(true));

    const makesData = yield call(CARS_API.fetchCarMakes, action.data.year);
    yield put(actions.updateVehicleMakes(action.data.year, makesData));

    yield put(actions.toggleMakesLoader(false));
  } catch (err) {
    yield put(actions.toggleMakesLoader(false));
    Toast({
      type: 'error',
      message: i18n.t('loadCarMakesError'),
    });
  }
}

export function* loadCarModels(action) {
  try {
    yield put(actions.toggleModelsLoader(true));

    const modelsData = yield call(CARS_API.fetchCarModels, action.data.year, action.data.make);
    yield put(actions.updateVehicleModels(action.data.year, action.data.make, modelsData));

    yield put(actions.toggleModelsLoader(false));
  } catch (err) {
    yield put(actions.toggleModelsLoader(false));
    Toast({
      type: 'error',
      message: i18n.t('loadCarModelsError'),
    });
  }
}

function* setupVehicles(action) {
  const availableYears = yield select(store => store.vehicles.availableYears);

  if (!availableYears.length) {
    yield loadCarYears();
  }

  const byYearData = yield select(store => store.vehicles.byYearData);

  if (action.data.year && !byYearData[action.data.year]?.availableMakes?.length) {
    yield loadCarMakes(action);
  }

  const modelsByMake = yield select(
    store => store.vehicles.byYearData[action.data.year]?.modelsByMake,
  );

  if (action.data.year && action.data.make && !modelsByMake[action.data.make]?.length) {
    yield loadCarModels(action);
  }

  yield put(actions.completeVehicleSetup());
}

/**
 * watcher saga
 */
export const vehiclesSaga = [
  takeLatest(actions.SETUP_VEHICLES, setupVehicles),
  takeLatest(actions.GET_VEHICLE_YEARS, loadCarYears),
  takeLatest(actions.GET_VEHICLE_MAKES, loadCarMakes),
  takeLatest(actions.GET_VEHICLE_MODELS, loadCarModels),
];
