import { Col, Drawer, List, Row } from 'antd';
import React from 'react';

import { IMAGES } from '../../enum';
import Text from '../Text';

const SelectDrawer = props => {
  const { isOpen, label, value, options, onClose, onChange } = props;

  const renderListItem = option => (
    <List.Item key={option.value} onClick={e => onChange(e, option.value)}>
      <Row wrap={false} gutter={16} align="middle" style={{ width: '100%' }}>
        <Col flex="44px">
          {value === option.value ? (
            <img src={IMAGES.CHECK_MARK_ICON} alt="checkmark" />
          ) : (
            <i className="fa fa-circle-thin" aria-hidden="true"></i>
          )}
        </Col>
        <Col flex={1}>
          <Text variant={value === option.value ? 'b' : 'p'}>{option.label}</Text>
        </Col>
      </Row>
    </List.Item>
  );

  return (
    <Drawer
      closable={false}
      className="mobile-select-drawer"
      title={label}
      placement="bottom"
      open={isOpen}
      onClose={onClose}
    >
      <List
        dataSource={options}
        renderItem={option =>
          Array.isArray(option.options) ? (
            <List
              header={<Text variant="h5">{option.label}</Text>}
              dataSource={option.options}
              renderItem={op => renderListItem(op)}
            />
          ) : (
            renderListItem(option)
          )
        }
      />
    </Drawer>
  );
};

export default SelectDrawer;
