import { Col, DatePicker, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import useDebounce from '../../../hooks/useDebounce';
import FormItem from '../../Form/FormItem';
import Select from '../../Select';
import TextInput from '../../TextInput';

const _setInitialValues = filters => {
  let values = {};

  filters.forEach(filter => {
    if (Array.isArray(filter.name)) {
      filter.name.forEach((name, i) => {
        if (Array.isArray(filter.defaultValue)) {
          values[name] = filter.defaultValue[i];
        } else {
          values[name] = filter.defaultValue;
        }
      });
    } else if (filter.name) {
      values[filter.name] = filter.defaultValue;
    }
  });

  return values;
};

const PageFiltersRenderer = props => {
  const { t, filters, search, justify, loading, onFiltersChange } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [searchTerm, setSearchTerm] = useState(search?.defaultValue);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [filterValues, setFilterValues] = useState(_setInitialValues(filters));

  const updateFilterValues = (newValues = {}) => {
    setFilterValues(values => ({ ...(values || {}), ...newValues }));
  };

  const _handleValueChange = async values => {
    await onFiltersChange(values);
  };

  const _renderFilter = (key, filterProps) => {
    const {
      // prevent usage
      // eslint-disable-next-line no-unused-vars
      onChange,
      // props
      label,
      options,
      disabled,
      placeholder,
      defaultValue,
      name,
      render,
      ...rest
    } = filterProps;

    switch (key) {
      case 'select': {
        return (
          <FormItem key={name} label={label}>
            <Select
              {...rest}
              options={options}
              disabled={disabled || loading}
              placeholder={placeholder}
              defaultValue={defaultValue}
              onChange={value => updateFilterValues({ [name]: value })}
            />
          </FormItem>
        );
      }
      case 'datePicker': {
        return (
          <FormItem key={name} label={label || t('date')}>
            <DatePicker
              {...rest}
              style={{ width: '100%' }}
              disabled={disabled || loading}
              defaultValue={defaultValue}
              onChange={date => updateFilterValues({ [name || 'date']: date })}
            />
          </FormItem>
        );
      }
      case 'dateRange': {
        return (
          <FormItem key={name} label={label || t('dateRange')}>
            <DatePicker.RangePicker
              {...rest}
              style={{ width: '100%' }}
              disabled={disabled || loading}
              defaultValue={defaultValue}
              onChange={(dates, dateStrings) => {
                const fromDateLabel = Array.isArray(name) ? name[0] : 'startDateRange';
                const toDateLabel = Array.isArray(name) ? name[1] : 'endDateRange';

                if (Array.isArray(dates)) {
                  updateFilterValues({
                    [fromDateLabel]: dates[0].startOf('day'),
                    [toDateLabel]: dates[1].startOf('day'),
                  });
                } else {
                  updateFilterValues({
                    [fromDateLabel]: null,
                    [toDateLabel]: null,
                  });
                }
              }}
            />
          </FormItem>
        );
      }
      case 'custom': {
        if (typeof render !== 'function') return null;

        return (
          <FormItem key={name} label={label}>
            {render({ updateFilterValues, disabled, loading })}
          </FormItem>
        );
      }
      default:
        return null;
    }
  };

  const _renderFilterColumn = ({ componentType, flex, responsive, hidden, ...rest }, index) => {
    if (hidden) return null;

    return (
      <Col
        key={`${componentType}-${index}`}
        xs={responsive?.xs}
        md={responsive?.md}
        lg={responsive?.lg}
        flex={responsive ? undefined : flex || 1}
      >
        {_renderFilter(componentType, rest)}
      </Col>
    );
  };

  const memoizedFilter = useMemo(() => filters, [filters]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && filterValues) {
      _handleValueChange(filterValues);
    }
    // eslint-disable-next-line
  }, [filterValues]);

  useEffect(() => {
    if (isMounted) {
      updateFilterValues({ searchTerm: debouncedSearchTerm });
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const SEARCH_INPUT = search ? (
    <Col flex={1}>
      <Form.Item label={t('Search')}>
        <TextInput
          allowClear
          value={searchTerm}
          disabled={search.disabled}
          placeholder={search.placeholder || t('Search')}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Form.Item>
    </Col>
  ) : null;

  return (
    <Form labelCol={{ span: 24 }}>
      <Row gutter={17} justify={justify} style={{ alignItems: 'flex-end' }}>
        {memoizedFilter.map(_renderFilterColumn)}

        {SEARCH_INPUT}
      </Row>
    </Form>
  );
};

PageFiltersRenderer.defaultProps = {
  filters: [],
  justify: 'start',
};

PageFiltersRenderer.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      componentType: PropTypes.oneOf(['select', 'datePicker', 'dateRange', 'custom']),
    }),
  ),
};

export default PageFiltersRenderer;
