import React from 'react';

import Chip from '.';

const TripPurposeChip = props => {
  const { purpose } = props;

  const STYLE_CONFIG_BY_PURPOSE = {
    business: {
      variant: 'secondary',
      text: 'Business',
    },
    commute: {
      variant: 'warning',
      text: 'Commute',
    },
    personal: {
      variant: 'secondary',
      text: 'Personal',
    },
  };

  if (!STYLE_CONFIG_BY_PURPOSE[purpose]) return null;

  return (
    <Chip
      fullWidth
      size="xs"
      variant={STYLE_CONFIG_BY_PURPOSE[purpose].variant}
      text={STYLE_CONFIG_BY_PURPOSE[purpose].text}
    />
  );
};

export default TripPurposeChip;
