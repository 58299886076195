import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchCountryCodeMapping } from '../../api/country-code-mappings';
import { SETTINGS_API } from '../../api/settings';
import { getAllSystemAdmins } from '../../api/systemAdmin';
import { normalizeGlobalSettings } from '../../utils/settings';
import * as actions from '../actions/systemAdmin';

export function* loadSystemAdmins() {
  yield put(actions.startSystemAdminsLoader());

  const response = yield call(getAllSystemAdmins);
  yield put(actions.updateSystemAdmins(response.documents));

  yield put(actions.stopSystemAdminsLoader());
}

export function* loadCountryCodeMapping() {
  const countryCodeMappings = yield call(fetchCountryCodeMapping);

  yield put(
    actions.updateCountryCodeMapping(
      countryCodeMappings.sort((a, b) => a.name.localeCompare(b.name)),
    ),
  );
}

export function* loadGlobalSettings() {
  const globalSettings = yield call(SETTINGS_API.fetchGlobalSettings);

  yield put(actions.emitUpdateGlobalSettings(normalizeGlobalSettings(globalSettings)));
}

export const systemAdminSaga = [
  takeLatest(actions.FETCH_SYSTEM_ADMINS, loadSystemAdmins),
  takeLatest(actions.FETCH_COUNTRY_CODE_MAPPINGS, loadCountryCodeMapping),
  takeLatest(actions.FETCH_GLOBAL_SETTINGS, loadGlobalSettings),
];
