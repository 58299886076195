import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class UserSettingsAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'user-settings' });
  }

  fetchAllUsersSettings = async (companyId, pageNumber, pageSize, sort) => {
    const params = cleanQueryParams({
      sort,
      companyId,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const response = await handleApiCalls('get', this.baseUrl + `?${params}`);
    return response.data;
  };

  updateUserSettings = async (userSettingId, settings = {}) => {
    const response = await handleApiCalls('put', `${this.baseUrl}/${userSettingId}`, settings);
    return response.data;
  };

  /**
   * @param {object[]} data
   * @param {string} data._id
   * @param {object} data.settings
   * @param {"radarSDK" | "kliksCustom"} data.settings.tripTracking
   * @returns {object[]} updated users
   */
  updateUserSettingsInBulk = async data => {
    const response = await handleApiCalls('patch', this.baseUrl + '/bulk', { updates: data });
    return response.data;
  };
}

export default UserSettingsAPI;
