import { Col, Row } from 'antd';
import React, { useMemo } from 'react';

import { convertMillisecondsIntoReadableTime } from '../../utils/datetime';
import {
  calcItineraryTripsTotalDistance,
  calcItineraryTripsTotalDrivingTime,
} from '../../utils/itinerary';
import { formatDistanceToLocale } from '../../utils/numbers';
import Switch from '../Switch';
import Text from '../Text';
import FadedText from '../Text/FadedText';

const OptimizedItinerarySwitch = props => {
  const { t, isOptimalRoute, itineraryMarkers, optimizedItineraryMarkers, onToggleChange } = props;

  const improvementsByOptimizing = useMemo(() => {
    const itineraryMarkersWithoutFirst = itineraryMarkers.slice(1);
    const optimizedItineraryMarkersWithoutFirst = optimizedItineraryMarkers.slice(1);

    const distanceShorter = Math.abs(
      calcItineraryTripsTotalDistance(optimizedItineraryMarkersWithoutFirst) -
        calcItineraryTripsTotalDistance(itineraryMarkersWithoutFirst),
    );

    const timeLessDrivingTime = Math.abs(
      calcItineraryTripsTotalDrivingTime(optimizedItineraryMarkersWithoutFirst) -
        calcItineraryTripsTotalDrivingTime(itineraryMarkersWithoutFirst),
    );

    return [
      distanceShorter > 0
        ? t('distanceShorter', {
            distance: formatDistanceToLocale(distanceShorter, 2),
          })
        : null,
      timeLessDrivingTime > 0
        ? t('timeLessDrivingTime', {
            time: convertMillisecondsIntoReadableTime(timeLessDrivingTime),
          })
        : null,
    ]
      .filter(Boolean)
      .join(', ');

    // eslint-disable-next-line
  }, [optimizedItineraryMarkers, itineraryMarkers]);

  return (
    <Row align="middle" gutter={8} wrap={false}>
      <Col>
        <Text renderAs="p" variant="b" size="sm" textAlign="right">
          {t('showOptimizedRoute')}
        </Text>
        <FadedText size="xs" textAlign="right">
          {isOptimalRoute ? improvementsByOptimizing : t('mappingDetectsBetterRoute')}
        </FadedText>
      </Col>
      <Col>
        <Switch size="medium" defaultChecked={isOptimalRoute} onChange={onToggleChange} />
      </Col>
    </Row>
  );
};

export default OptimizedItinerarySwitch;
