import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Popconfirm } from 'antd';
import { Button } from 'components';
import './style.scss';

class ResendEmailButton extends React.Component {

  render() {
    const { t, email, resendEmail } = this.props
    const okButtonStyle = {
      
    }
    const cancelButtonStyle = {
      
    }

    return (
      <Popconfirm
        placement="top"
        title={() => (
          <div>
            {`${t('Are you sure to resend the email to')} `}
            <span style={{ color: "#F5222D", fontWeight: 'bold' }}>
              {email}
            </span>
            {`?`}
          </div>
        )}
        onConfirm={resendEmail}
        okButtonProps={{ style: okButtonStyle }}
        cancelButtonProps={{ style: cancelButtonStyle }}
      >
        <Button text={t("Resend Email")} />
      </Popconfirm>
    )
  }
}

ResendEmailButton.propTypes = {
  email: PropTypes.string,
  resendEmail: PropTypes.func,
}

ResendEmailButton.defaultProps = {
  email: '',
  resendEmail: () => { },
}

export default withNamespaces()(ResendEmailButton);