import { Col, Row } from 'antd';
import React from 'react';

import DashboardUsersTable from '../../../components/Table/DashboardUsersTable';
import Text from '../../../components/Text';
import DashboardEventTimeline from '../DashboardEventTimeline';
import classNames from '../style.module.scss';

const ManagerBlurredContent = props => {
  const { t } = props;
  return (
    <div className={classNames.blurredManagerRow}>
      <Row align="middle" justify="center">
        <Col xs={24} style={{ marginBottom: 30 }}>
          <DashboardEventTimeline t={t} timeline={[]} timelineFilters={{}} />
        </Col>
        <Col xs={24}>
          <DashboardUsersTable t={t} dataSource={[]} />
        </Col>
      </Row>

      <Text variant="h4">{t('managerWithoutGroupDashboardMessage')}</Text>
    </div>
  );
};

export default ManagerBlurredContent;
