import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS } from '../../enum/index.jsx';
import LinkText from '../Text/LinkText.js';
import Alert from './index.js';

const OverdueReceiptAlert = props => {
  const { t, overdueCount, onClick } = props;

  if (typeof overdueCount !== 'number') return null;

  if (overdueCount === 0) {
    return (
      <Alert
        closable={false}
        icon={<img width="24px" src={IMAGES.CALENDAR_CHECK_ICON} alt="calendar check" />}
        message={
          <Trans
            t={t}
            i18nKey="noOverdueReceipt"
            components={[
              <Link to={INTERNAL_LINKS.DASHBOARD}>
                <LinkText variant="strong">DummyText</LinkText>
              </Link>,
            ]}
          />
        }
      />
    );
  }

  return (
    <Alert
      closable={false}
      icon={<img width="24px" src={IMAGES.CALENDAR_WARNING_ICON} alt="calendar warning" />}
      message={
        <Trans
          t={t}
          i18nKey="overdueReceipt"
          count={overdueCount}
          values={{ count: overdueCount }}
          components={[
            <LinkText variant="strong" onClick={onClick}>
              DummyText
            </LinkText>,
          ]}
        />
      }
    />
  );
};

export default OverdueReceiptAlert;
