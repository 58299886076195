import { get } from 'lodash';

const canTriggerGoogleAnalytics = () => {
  return (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_GOOGLE_ANALYTICS_ID &&
    typeof window.gtag !== 'undefined'
  );
};

export const setAnalyticsUserProperties = userDetails => {
  const properties = {
    user_id: get(userDetails, '_id', ''),
    user_role: get(userDetails, 'role', ''),
  };

  if (canTriggerGoogleAnalytics()) {
    window.gtag('set', 'user_properties', properties);
  } else if (process.env.NODE_ENV === 'development') {
    console.log('GA set user_properties:', properties);
  }
};

export const triggerGoogleAnalyticsPageView = () => {
  const currentPageURL = window.location.pathname + window.location.search;

  const attributes = {
    send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
    page_title: document.title,
    page_location: currentPageURL,
  };

  if (canTriggerGoogleAnalytics()) {
    window.gtag('event', 'page_view', attributes);
  } else if (process.env.NODE_ENV === 'development') {
    console.log('GA page_view:', attributes);
  }
};
