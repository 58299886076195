export const DEFAULT_COUNTRY_ADDRESS_FIELD_ORDER = [
  'streetOne',
  'streetTwo',
  'country',
  'state',
  'city',
  'postalCode',
];
export const DEFAULT_COUNTRY_ADDRESS_REQUIRED_FIELDS = [
  'streetOne',
  'country',
  'state',
  'city',
  'postalCode',
];

export const COUNTRY_LIST = [
  {
    name: 'Afghanistan',
    countryCode: 'AF',
    currencyCode: 'AFN',
    locales: ['ps-AF', 'fa-AF', 'uz-Arab-AF'],
    defaultLocale: 'ps-AF',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x60B;',
    currencyName: 'Afghani',
    fuel: 'liter',
  },
  {
    name: 'Albania',
    countryCode: 'AL',
    currencyCode: 'ALL',
    locales: ['sq-AL'],
    defaultLocale: 'sq-AL',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'L',
    currencyName: 'Lek',
    fuel: 'liter',
  },
  {
    name: 'Algeria',
    countryCode: 'DZ',
    currencyCode: 'DZD',
    locales: ['ar-DZ', 'kab-DZ'],
    defaultLocale: 'ar-DZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x62F;&#x62C;',
    currencyName: 'Algerian Dinar',
    fuel: 'liter',
  },
  {
    name: 'American Samoa',
    countryCode: 'AS',
    currencyCode: 'USD',
    locales: ['en-AS'],
    defaultLocale: 'en-AS',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Andorra',
    countryCode: 'AD',
    currencyCode: 'EUR',
    locales: ['ca'],
    defaultLocale: 'ca',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Angola',
    countryCode: 'AO',
    currencyCode: 'AOA',
    locales: ['pt'],
    defaultLocale: 'pt',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Kz',
    currencyName: 'Kwanza',
    fuel: 'liter',
  },
  {
    name: 'Anguilla',
    countryCode: 'AI',
    currencyCode: 'XCD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'East Caribbean Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Antarctica',
    countryCode: 'AQ',
    currencyCode: 'USD',
    locales: ['en-US'],
    defaultLocale: 'en-US',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Antigua and Barbuda',
    countryCode: 'AG',
    currencyCode: 'XCD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'East Caribbean Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Argentina',
    countryCode: 'AR',
    currencyCode: 'ARS',
    locales: ['es-AR'],
    defaultLocale: 'es-AR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Argentine Peso',
    fuel: 'liter',
  },
  {
    name: 'Armenia',
    countryCode: 'AM',
    currencyCode: 'AMD',
    locales: ['hy-AM'],
    defaultLocale: 'hy-AM',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x58F;',
    currencyName: 'Armenian Dram',
    fuel: 'liter',
  },
  {
    name: 'Aruba',
    countryCode: 'AW',
    currencyCode: 'AWG',
    locales: ['nl'],
    defaultLocale: 'nl',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&fnof;',
    currencyName: 'Aruban Florin',
    fuel: 'liter',
  },
  {
    name: 'Australia',
    countryCode: 'AU',
    currencyCode: 'AUD',
    locales: ['en-AU'],
    defaultLocale: 'en-AU',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Austria',
    countryCode: 'AT',
    currencyCode: 'EUR',
    locales: ['de-AT'],
    defaultLocale: 'de-AT',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Azerbaijan',
    countryCode: 'AZ',
    currencyCode: 'AZN',
    locales: ['az-Cyrl-AZ', 'az-Latn-AZ'],
    defaultLocale: 'az-Cyrl-AZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20BC;',
    currencyName: 'Azerbaijan Manat',
    fuel: 'liter',
  },
  {
    name: 'Bahamas',
    countryCode: 'BS',
    currencyCode: 'BSD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: '&dollar;',
    currencyName: 'Bahamian Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Bahrain',
    countryCode: 'BH',
    currencyCode: 'BHD',
    locales: ['ar-BH'],
    defaultLocale: 'ar-BH',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&period;&#x62F;&period;&#x628;',
    currencyName: 'Bahraini Dinar',
    fuel: 'liter',
  },
  {
    name: 'Bangladesh',
    countryCode: 'BD',
    currencyCode: 'BDT',
    locales: ['bn-BD'],
    defaultLocale: 'bn-BD',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x9F3;',
    currencyName: 'Taka',
    fuel: 'liter',
  },
  {
    name: 'Barbados',
    countryCode: 'BB',
    currencyCode: 'BBD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Barbados Dollar',
    fuel: 'liter',
  },
  {
    name: 'Belarus',
    countryCode: 'BY',
    currencyCode: 'BYN',
    locales: ['be-BY'],
    defaultLocale: 'be-BY',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Br',
    currencyName: 'Belarusian Ruble',
    fuel: 'liter',
  },
  {
    name: 'Belgium',
    countryCode: 'BE',
    currencyCode: 'EUR',
    locales: ['nl-BE', 'en-BE', 'fr-BE', 'de-BE'],
    defaultLocale: 'nl-BE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Belize',
    countryCode: 'BZ',
    currencyCode: 'BZD',
    locales: ['en-BZ'],
    defaultLocale: 'en-BZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'BZ&dollar;',
    currencyName: 'Belize Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Benin',
    countryCode: 'BJ',
    currencyCode: 'XOF',
    locales: ['fr-BJ'],
    defaultLocale: 'fr-BJ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CFA',
    currencyName: 'CFA Franc BCEAO',
    fuel: 'liter',
  },
  {
    name: 'Bermuda',
    countryCode: 'BM',
    currencyCode: 'BMD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Bermudian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Bhutan',
    countryCode: 'BT',
    currencyCode: 'BTN',
    locales: ['dz'],
    defaultLocale: 'dz',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Nu&period;',
    currencyName: 'Ngultrum',
    fuel: 'liter',
  },
  {
    name: 'Bolivia',
    countryCode: 'BO',
    currencyCode: 'BTN',
    locales: ['es-BO'],
    defaultLocale: 'es-BO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Nu&period;',
    currencyName: 'Ngultrum',
    fuel: 'liter',
  },
  {
    name: 'Bonaire',
    countryCode: 'BQ',
    currencyCode: 'USD',
    locales: ['nl'],
    defaultLocale: 'nl',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Bosnia and Herzegovina',
    countryCode: 'BA',
    currencyCode: 'BAM',
    locales: ['bs-BA', 'sr-Cyrl-BA', 'sr-Latn-BA'],
    defaultLocale: 'bs-BA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'KM',
    currencyName: 'Convertible Mark',
    fuel: 'liter',
  },
  {
    name: 'Botswana',
    countryCode: 'BW',
    currencyCode: 'BWP',
    locales: ['en-BW'],
    defaultLocale: 'en-BW',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'P',
    currencyName: 'Pula',
    fuel: 'liter',
  },
  {
    name: 'Bouvet Island',
    countryCode: 'BV',
    currencyCode: 'NOK',
    locales: ['no'],
    defaultLocale: 'no',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Norwegian Krone',
    fuel: 'liter',
  },
  {
    name: 'Brazil',
    countryCode: 'BR',
    currencyCode: 'BRL',
    locales: ['pt-BR'],
    defaultLocale: 'pt-BR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'R&dollar;',
    currencyName: 'Brazilian Real',
    fuel: 'liter',
  },
  {
    name: 'British Indian Ocean Territory',
    countryCode: 'IO',
    currencyCode: 'USD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Brunei Darussalam',
    countryCode: 'BN',
    currencyCode: 'BND',
    locales: ['ms-BN'],
    defaultLocale: 'ms-BN',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Brunei Dollar',
    fuel: 'liter',
  },
  {
    name: 'Bulgaria',
    countryCode: 'BG',
    currencyCode: 'BGN',
    locales: ['bg-BG'],
    defaultLocale: 'bg-BG',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&lcy;&vcy;',
    currencyName: 'Bulgarian Lev',
    fuel: 'liter',
  },
  {
    name: 'Burkina Faso',
    countryCode: 'BF',
    currencyCode: 'XOF',
    locales: ['fr-BF'],
    defaultLocale: 'fr-BF',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CFA',
    currencyName: 'CFA Franc BCEAO',
    fuel: 'liter',
  },
  {
    name: 'Burundi',
    countryCode: 'BI',
    currencyCode: 'BIF',
    locales: ['fr-BI'],
    defaultLocale: 'fr-BI',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FBu',
    currencyName: 'Burundi Franc',
    fuel: 'liter',
  },
  {
    name: 'Cabo Verde',
    countryCode: 'CV',
    currencyCode: 'CVE',
    locales: ['kea-CV'],
    defaultLocale: 'kea-CV',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Cabo Verde Escudo',
    fuel: 'liter',
  },
  {
    name: 'Cambodia',
    countryCode: 'KH',
    currencyCode: 'KHR',
    locales: ['km-KH'],
    defaultLocale: 'km-KH',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x17DB;',
    currencyName: 'Riel',
    fuel: 'liter',
  },
  {
    name: 'Cameroon',
    countryCode: 'CM',
    currencyCode: 'XAF',
    locales: ['fr-CM'],
    defaultLocale: 'fr-CM',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FCFA',
    currencyName: 'CFA Franc BEAC',
    fuel: 'liter',
  },
  {
    name: 'Canada',
    countryCode: 'CA',
    currencyCode: 'CAD',
    locales: ['en-CA', 'fr-CA'],
    defaultLocale: 'en-CA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Canadian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Cayman Islands',
    countryCode: 'KY',
    currencyCode: 'KYD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Cayman Islands Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Central African Republic',
    countryCode: 'CF',
    currencyCode: 'XAF',
    locales: ['fr-CF', 'sg-CF'],
    defaultLocale: 'fr-CF',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FCFA',
    currencyName: 'CFA Franc BEAC',
    fuel: 'liter',
  },
  {
    name: 'Chad',
    countryCode: 'TD',
    currencyCode: 'XAF',
    locales: ['fr-TD'],
    defaultLocale: 'fr-TD',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FCFA',
    currencyName: 'CFA Franc BEAC',
    fuel: 'liter',
  },
  {
    name: 'Chile',
    countryCode: 'CL',
    currencyCode: 'CLP',
    locales: ['es-CL'],
    defaultLocale: 'es-CL',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Chilean Peso',
    fuel: 'liter',
  },
  {
    name: 'China',
    countryCode: 'CN',
    currencyCode: 'CNY',
    locales: ['zh-CN', 'zh-Hans-CN', 'ii-CN', 'bo-CN'],
    defaultLocale: 'zh-CN',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&yen;',
    currencyName: 'Yuan Renminbi',
    fuel: 'liter',
  },
  {
    name: 'Christmas Island',
    countryCode: 'CX',
    currencyCode: 'AUD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Cocos Islands',
    countryCode: 'CC',
    currencyCode: 'AUD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Colombia',
    countryCode: 'CO',
    currencyCode: 'COP',
    locales: ['es-CO'],
    defaultLocale: 'es-CO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Colombian Peso',
    fuel: 'gallon',
  },
  {
    name: 'Comoros',
    countryCode: 'KM',
    currencyCode: 'KMF',
    locales: ['fr-KM'],
    defaultLocale: 'fr-KM',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CF',
    currencyName: 'Comorian Franc ',
    fuel: 'liter',
  },
  {
    name: 'Democratic Republic of the Congo',
    countryCode: 'CD',
    currencyCode: 'CDF',
    locales: ['fr-CD'],
    defaultLocale: 'fr-CD',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FC',
    currencyName: 'Congolese Franc',
    fuel: 'liter',
  },
  {
    name: 'Congo',
    countryCode: 'CG',
    currencyCode: 'XAF',
    locales: ['fr-CG'],
    defaultLocale: 'fr-CG',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FCFA',
    currencyName: 'CFA Franc BEAC',
    fuel: 'liter',
  },
  {
    name: 'Cook Islands',
    countryCode: 'CK',
    currencyCode: 'NZD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'New Zealand Dollar',
    fuel: 'liter',
  },
  {
    name: 'Costa Rica',
    countryCode: 'CR',
    currencyCode: 'CRC',
    locales: ['es-CR'],
    defaultLocale: 'es-CR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A1;',
    currencyName: 'Costa Rican Colon',
    fuel: 'liter',
  },
  {
    name: 'Croatia',
    countryCode: 'HR',
    currencyCode: 'HRK',
    locales: ['hr-HR'],
    defaultLocale: 'hr-HR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kn',
    currencyName: 'Kuna',
    fuel: 'liter',
  },
  {
    name: 'Cuba',
    countryCode: 'CU',
    currencyCode: 'CUC',
    locales: ['es'],
    defaultLocale: 'es',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Peso Convertible',
    fuel: 'liter',
  },
  {
    name: 'Curaçao',
    countryCode: 'CW',
    currencyCode: 'ANG',
    locales: ['nl'],
    defaultLocale: 'nl',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&fnof;',
    currencyName: 'Netherlands Antillean Guilder',
    fuel: 'liter',
  },
  {
    name: 'Cyprus',
    countryCode: 'CY',
    currencyCode: 'EUR',
    locales: ['el-CY'],
    defaultLocale: 'el-CY',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Czechia',
    countryCode: 'CZ',
    currencyCode: 'CZK',
    locales: ['cs-CZ'],
    defaultLocale: 'cs-CZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'K&ccaron;',
    currencyName: 'Czech Koruna',
    fuel: 'liter',
  },
  {
    name: "Côte d'Ivoire",
    countryCode: 'CI',
    currencyCode: 'CZK',
    locales: ['fr-CI'],
    defaultLocale: 'fr-CI',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'K&ccaron;',
    currencyName: 'Czech Koruna',
    fuel: 'liter',
  },
  {
    name: 'Denmark',
    countryCode: 'DK',
    currencyCode: 'DKK',
    locales: ['da-DK'],
    defaultLocale: 'da-DK',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Danish Krone',
    fuel: 'liter',
  },
  {
    name: 'Djibouti',
    countryCode: 'DJ',
    currencyCode: 'DJF',
    locales: ['fr-DJ', 'so-DJ'],
    defaultLocale: 'fr-DJ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Fdj',
    currencyName: 'Djibouti Franc',
    fuel: 'liter',
  },
  {
    name: 'Dominica',
    countryCode: 'DM',
    currencyCode: 'XCD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'East Caribbean Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Dominican Republic',
    countryCode: 'DO',
    currencyCode: 'DOP',
    locales: ['es-DO'],
    defaultLocale: 'es-DO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'RD&dollar;',
    currencyName: 'Dominican Peso',
    fuel: 'gallon',
  },
  {
    name: 'Ecuador',
    countryCode: 'EC',
    currencyCode: 'USD',
    locales: ['es-EC'],
    defaultLocale: 'es-EC',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Egypt',
    countryCode: 'EG',
    currencyCode: 'EGP',
    locales: ['ar-EG'],
    defaultLocale: 'ar-EG',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'Egyptian Pound',
    fuel: 'liter',
  },
  {
    name: 'El Salvador',
    countryCode: 'SV',
    currencyCode: 'USD',
    locales: ['es-SV'],
    defaultLocale: 'es-SV',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Equatorial Guinea',
    countryCode: 'GQ',
    currencyCode: 'XAF',
    locales: ['fr-GQ', 'es-GQ'],
    defaultLocale: 'fr-GQ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FCFA',
    currencyName: 'CFA Franc BEAC',
    fuel: 'liter',
  },
  {
    name: 'Eritrea',
    countryCode: 'ER',
    currencyCode: 'ERN',
    locales: ['ti-ER'],
    defaultLocale: 'ti-ER',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Nfk',
    currencyName: 'Nakfa',
    fuel: 'liter',
  },
  {
    name: 'Estonia',
    countryCode: 'EE',
    currencyCode: 'EUR',
    locales: ['et-EE'],
    defaultLocale: 'et-EE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Eswatini',
    countryCode: 'SZ',
    currencyCode: 'EUR',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Ethiopia',
    countryCode: 'ET',
    currencyCode: 'ETB',
    locales: ['am-ET', 'om-ET', 'so-ET', 'ti-ET'],
    defaultLocale: 'am-ET',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Br',
    currencyName: 'Ethiopian Birr',
    fuel: 'liter',
  },
  {
    name: 'Falkland Islands',
    countryCode: 'FK',
    currencyCode: 'DKK',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Danish Krone',
    fuel: 'liter',
  },
  {
    name: 'Faroe Islands',
    countryCode: 'FO',
    currencyCode: 'DKK',
    locales: ['fo-FO'],
    defaultLocale: 'fo-FO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Danish Krone',
    fuel: 'liter',
  },
  {
    name: 'Fiji',
    countryCode: 'FJ',
    currencyCode: 'FJD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Fiji Dollar',
    fuel: 'liter',
  },
  {
    name: 'Finland',
    countryCode: 'FI',
    currencyCode: 'EUR',
    locales: ['fi-FI', 'sv-FI'],
    defaultLocale: 'fi-FI',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'France',
    countryCode: 'FR',
    currencyCode: 'EUR',
    locales: ['fr-FR'],
    defaultLocale: 'fr-FR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
    addressFieldsOrder: ['streetOne', 'streetTwo', 'country', 'postalCode', 'city'],
    requiredAddressFields: ['streetOne', 'country', 'city', 'postalCode'],
  },
  {
    name: 'French Guiana',
    countryCode: 'GF',
    currencyCode: 'EUR',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'French Polynesia',
    countryCode: 'PF',
    currencyCode: 'XPF',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A3;',
    currencyName: 'CFP Franc',
    fuel: 'liter',
  },
  {
    name: 'French Southern Territories',
    countryCode: 'TF',
    currencyCode: 'EUR',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Gabon',
    countryCode: 'GA',
    currencyCode: 'XAF',
    locales: ['fr-GA'],
    defaultLocale: 'fr-GA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FCFA',
    currencyName: 'CFA Franc BEAC',
    fuel: 'liter',
  },
  {
    name: 'Gambia',
    countryCode: 'GM',
    currencyCode: 'GMD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'D',
    currencyName: 'Dalasi',
    fuel: 'liter',
  },
  {
    name: 'Georgia',
    countryCode: 'GE',
    currencyCode: 'GEL',
    locales: ['ka-GE'],
    defaultLocale: 'ka-GE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20BE;',
    currencyName: 'Lari',
    fuel: 'liter',
  },
  {
    name: 'Germany',
    countryCode: 'DE',
    currencyCode: 'EUR',
    locales: ['de-DE'],
    defaultLocale: 'de-DE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Ghana',
    countryCode: 'GH',
    currencyCode: 'GHS',
    locales: ['ak-GH', 'ee-GH', 'ha-Latn-GH'],
    defaultLocale: 'ak-GH',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'GH&#x20B5;',
    currencyName: 'Ghana Cedi',
    fuel: 'liter',
  },
  {
    name: 'Gibraltar',
    countryCode: 'GI',
    currencyCode: 'GIP',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: '&pound;',
    currencyName: 'Gibraltar Pound',
    fuel: 'liter',
  },
  {
    name: 'Greece',
    countryCode: 'GR',
    currencyCode: 'EUR',
    locales: ['el-GR'],
    defaultLocale: 'el-GR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Greenland',
    countryCode: 'GL',
    currencyCode: 'DKK',
    locales: ['kl-GL'],
    defaultLocale: 'kl-GL',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Danish Krone',
    fuel: 'liter',
  },
  {
    name: 'Grenada',
    countryCode: 'GD',
    currencyCode: 'XCD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: '&dollar;',
    currencyName: 'East Caribbean Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Guadeloupe',
    countryCode: 'GP',
    currencyCode: 'EUR',
    locales: ['fr-GP'],
    defaultLocale: 'fr-GP',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Guam',
    countryCode: 'GU',
    currencyCode: 'USD',
    locales: ['en-GU'],
    defaultLocale: 'en-GU',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Guatemala',
    countryCode: 'GT',
    currencyCode: 'GTQ',
    locales: ['es-GT'],
    defaultLocale: 'es-GT',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Q',
    currencyName: 'Quetzal',
    fuel: 'gallon',
  },
  {
    name: 'Guernsey',
    countryCode: 'GG',
    currencyCode: 'GBP',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'Pound Sterling',
    fuel: 'liter',
  },
  {
    name: 'Guinea',
    countryCode: 'GN',
    currencyCode: 'GNF',
    locales: ['fr-GN'],
    defaultLocale: 'fr-GN',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'FG',
    currencyName: 'Guinean Franc',
    fuel: 'liter',
  },
  {
    name: 'Guinea-Bissau',
    countryCode: 'GW',
    currencyCode: 'XOF',
    locales: ['pt-GW'],
    defaultLocale: 'pt-GW',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CFA',
    currencyName: 'CFA Franc BCEAO',
    fuel: 'liter',
  },
  {
    name: 'Guyana',
    countryCode: 'GY',
    currencyCode: 'GYD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Guyana Dollar',
    fuel: 'liter',
  },
  {
    name: 'Haiti',
    countryCode: 'HT',
    currencyCode: 'USD',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Heard Island and McDonald Islands',
    countryCode: 'HM',
    currencyCode: 'AUD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Holy See',
    countryCode: 'VA',
    currencyCode: 'EUR',
    locales: ['it'],
    defaultLocale: 'it',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Honduras',
    countryCode: 'HN',
    currencyCode: 'HNL',
    locales: ['es-HN'],
    defaultLocale: 'es-HN',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'L',
    currencyName: 'Lempira',
    fuel: 'gallon',
  },
  {
    name: 'Hong Kong',
    countryCode: 'HK',
    currencyCode: 'HKD',
    locales: ['yue-Hant-HK', 'zh-Hans-HK', 'zh-Hant-HK', 'en-HK'],
    defaultLocale: 'en-HK',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Hong Kong Dollar',
    fuel: 'liter',
  },
  {
    name: 'Hungary',
    countryCode: 'HU',
    currencyCode: 'HUF',
    locales: ['hu-HU'],
    defaultLocale: 'hu-HU',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Ft',
    currencyName: 'Forint',
    fuel: 'liter',
  },
  {
    name: 'Iceland',
    countryCode: 'IS',
    currencyCode: 'ISK',
    locales: ['is-IS'],
    defaultLocale: 'is-IS',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Iceland Krona',
    fuel: 'liter',
  },
  {
    name: 'India',
    countryCode: 'IN',
    currencyCode: 'INR',
    locales: [
      'as-IN',
      'bn-IN',
      'en-IN',
      'gu-IN',
      'hi-IN',
      'kn-IN',
      'kok-IN',
      'ml-IN',
      'mr-IN',
      'ne-IN',
      'or-IN',
      'pa-Guru-IN',
      'ta-IN',
      'te-IN',
      'bo-IN',
      'ur-IN',
    ],
    defaultLocale: 'hi-IN',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20B9;',
    currencyName: 'Indian Rupee',
    fuel: 'liter',
  },
  {
    name: 'Indonesia',
    countryCode: 'ID',
    currencyCode: 'IDR',
    locales: ['id-ID'],
    defaultLocale: 'id-ID',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Rp',
    currencyName: 'Rupiah',
    fuel: 'liter',
  },
  {
    name: 'Iran',
    countryCode: 'IR',
    currencyCode: 'IRR',
    locales: ['fa-IR'],
    defaultLocale: 'fa-IR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#xFDFC;',
    currencyName: 'Iranian Rial',
    fuel: 'liter',
  },
  {
    name: 'Iraq',
    countryCode: 'IQ',
    currencyCode: 'IQD',
    locales: ['ar-IQ'],
    defaultLocale: 'ar-IQ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x639;&period;&#x62F;',
    currencyName: 'Iraqi Dinar',
    fuel: 'liter',
  },
  {
    name: 'Ireland',
    countryCode: 'IE',
    currencyCode: 'EUR',
    locales: ['en-IE', 'ga-IE'],
    defaultLocale: 'en-IE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Isle of Man',
    countryCode: 'IM',
    currencyCode: 'GBP',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'Pound Sterling',
    fuel: 'liter',
  },
  {
    name: 'Israel',
    countryCode: 'IL',
    currencyCode: 'ILS',
    locales: ['en-IL', 'he-IL'],
    defaultLocale: 'he-IL',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20AA;',
    currencyName: 'New Israeli Sheqel',
    fuel: 'liter',
  },
  {
    name: 'Italy',
    countryCode: 'IT',
    currencyCode: 'EUR',
    locales: ['it-IT'],
    defaultLocale: 'it-IT',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Jamaica',
    countryCode: 'JM',
    currencyCode: 'JMD',
    locales: ['en-JM'],
    defaultLocale: 'en-JM',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'J&dollar;',
    currencyName: 'Jamaican Dollar',
    fuel: 'liter',
  },
  {
    name: 'Japan',
    countryCode: 'JP',
    currencyCode: 'JPY',
    locales: ['ja-JP'],
    defaultLocale: 'ja-JP',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&yen;',
    currencyName: 'Yen',
    fuel: 'liter',
  },
  {
    name: 'Jersey',
    countryCode: 'JE',
    currencyCode: 'GBP',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'Pound Sterling',
    fuel: 'liter',
  },
  {
    name: 'Jordan',
    countryCode: 'JO',
    currencyCode: 'JOD',
    locales: ['ar-JO'],
    defaultLocale: 'ar-JO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'JD',
    currencyName: 'Jordanian Dinar',
    fuel: 'liter',
  },
  {
    name: 'Kazakhstan',
    countryCode: 'KZ',
    currencyCode: 'KZT',
    locales: ['kk-Cyrl-KZ'],
    defaultLocale: 'kk-Cyrl-KZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&lcy;&vcy;',
    currencyName: 'Tenge',
    fuel: 'liter',
  },
  {
    name: 'Kenya',
    countryCode: 'KE',
    currencyCode: 'KES',
    locales: [
      'ebu-KE',
      'guz-KE',
      'kln-KE',
      'kam-KE',
      'ki-KE',
      'luo-KE',
      'luy-KE',
      'mas-KE',
      'mer-KE',
      'om-KE',
      'saq-KE',
      'so-KE',
      'sw-KE',
      'dav-KE',
      'teo-KE',
    ],
    defaultLocale: 'ebu-KE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'KSh',
    currencyName: 'Kenyan Shilling',
    fuel: 'liter',
  },
  {
    name: 'Kiribati',
    countryCode: 'KI',
    currencyCode: 'AUD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'North Korea',
    countryCode: 'KP',
    currencyCode: 'KPW',
    locales: ['ko'],
    defaultLocale: 'ko',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A9;',
    currencyName: 'North Korean Won',
    fuel: 'liter',
  },
  {
    name: 'South Korea',
    countryCode: 'KR',
    currencyCode: 'KRW',
    locales: ['ko-KR'],
    defaultLocale: 'ko-KR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A9;',
    currencyName: 'Won',
    fuel: 'liter',
  },
  {
    name: 'Kuwait',
    countryCode: 'KW',
    currencyCode: 'KWD',
    locales: ['ar-KW'],
    defaultLocale: 'ar-KW',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'KD',
    currencyName: 'Kuwaiti Dinar',
    fuel: 'liter',
  },
  {
    name: 'Kyrgyzstan',
    countryCode: 'KG',
    currencyCode: 'KGS',
    locales: ['ky'],
    defaultLocale: 'ky',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&lcy;&vcy;',
    currencyName: 'Som',
    fuel: 'liter',
  },
  {
    name: "Lao People's Democratic Republic",
    countryCode: 'LA',
    currencyCode: 'LAK',
    locales: ['lo'],
    defaultLocale: 'lo',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20AD;',
    currencyName: 'Lao Kip',
    fuel: 'liter',
  },
  {
    name: 'Latvia',
    countryCode: 'LV',
    currencyCode: 'EUR',
    locales: ['lv-LV'],
    defaultLocale: 'lv-LV',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Lebanon',
    countryCode: 'LB',
    currencyCode: 'LBP',
    locales: ['ar-LB'],
    defaultLocale: 'ar-LB',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'Lebanese Pound',
    fuel: 'liter',
  },
  {
    name: 'Lesotho',
    countryCode: 'LS',
    currencyCode: 'ZAR',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'R',
    currencyName: 'Rand',
    fuel: 'liter',
  },
  {
    name: 'Liberia',
    countryCode: 'LR',
    currencyCode: 'LRD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: '&dollar;',
    currencyName: 'Liberian Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Libya',
    countryCode: 'LY',
    currencyCode: 'LYD',
    locales: ['ar-LY'],
    defaultLocale: 'ar-LY',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'LD',
    currencyName: 'Libyan Dinar',
    fuel: 'liter',
  },
  {
    name: 'Liechtenstein',
    countryCode: 'LI',
    currencyCode: 'CHF',
    locales: ['de-LI'],
    defaultLocale: 'de-LI',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CHF',
    currencyName: 'Swiss Franc',
    fuel: 'liter',
  },
  {
    name: 'Lithuania',
    countryCode: 'LT',
    currencyCode: 'EUR',
    locales: ['lt-LT'],
    defaultLocale: 'lt-LT',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Luxembourg',
    countryCode: 'LU',
    currencyCode: 'EUR',
    locales: ['fr-LU', 'de-LU'],
    defaultLocale: 'fr-LU',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Macao',
    countryCode: 'MO',
    currencyCode: 'MOP',
    locales: ['zh-Hans-MO', 'zh-Hant-MO'],
    defaultLocale: 'zh-Hans-MO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'MOP&dollar;',
    currencyName: 'Pataca',
    fuel: 'liter',
  },
  {
    name: 'Madagascar',
    countryCode: 'MG',
    currencyCode: 'MGA',
    locales: ['fr-MG', 'mg-MG'],
    defaultLocale: 'fr-MG',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Ar',
    currencyName: 'Malagasy Ariary',
    fuel: 'liter',
  },
  {
    name: 'Malawi',
    countryCode: 'MW',
    currencyCode: 'MWK',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'MK',
    currencyName: 'Malawi Kwacha',
    fuel: 'liter',
  },
  {
    name: 'Malaysia',
    countryCode: 'MY',
    currencyCode: 'MYR',
    locales: ['ms-MY'],
    defaultLocale: 'ms-MY',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'RM',
    currencyName: 'Malaysian Ringgit',
    fuel: 'liter',
  },
  {
    name: 'Maldives',
    countryCode: 'MV',
    currencyCode: 'MVR',
    locales: ['dv'],
    defaultLocale: 'dv',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Rf',
    currencyName: 'Rufiyaa',
    fuel: 'liter',
  },
  {
    name: 'Mali',
    countryCode: 'ML',
    currencyCode: 'XOF',
    locales: ['bm-ML', 'fr-ML', 'khq-ML', 'ses-ML'],
    defaultLocale: 'fr-ML',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CFA',
    currencyName: 'CFA Franc BCEAO',
    fuel: 'liter',
  },
  {
    name: 'Malta',
    countryCode: 'MT',
    currencyCode: 'EUR',
    locales: ['en-MT', 'mt-MT'],
    defaultLocale: 'en-MT',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Marshall Islands',
    countryCode: 'MH',
    currencyCode: 'USD',
    locales: ['en-MH'],
    defaultLocale: 'en-MH',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Martinique',
    countryCode: 'MQ',
    currencyCode: 'EUR',
    locales: ['fr-MQ'],
    defaultLocale: 'fr-MQ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Mauritania',
    countryCode: 'MR',
    currencyCode: 'MRU',
    locales: ['ar'],
    defaultLocale: 'ar',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'UM',
    currencyName: 'Ouguiya',
    fuel: 'liter',
  },
  {
    name: 'Mauritius',
    countryCode: 'MU',
    currencyCode: 'MUR',
    locales: ['en-MU', 'mfe-MU'],
    defaultLocale: 'en-MU',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A8;',
    currencyName: 'Mauritius Rupee',
    fuel: 'liter',
  },
  {
    name: 'Mayotte',
    countryCode: 'YT',
    currencyCode: 'EUR',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Mexico',
    countryCode: 'MX',
    currencyCode: 'MXN',
    locales: ['es-MX'],
    defaultLocale: 'es-MX',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Mexican Peso',
    fuel: 'liter',
  },
  {
    name: 'Micronesia',
    countryCode: 'FM',
    currencyCode: 'USD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Moldova',
    countryCode: 'MD',
    currencyCode: 'MDL',
    locales: ['ro-MD', 'ru-MD'],
    defaultLocale: 'ro-MD',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'lei',
    currencyName: 'Moldovan Leu',
    fuel: 'liter',
  },
  {
    name: 'Monaco',
    countryCode: 'MC',
    currencyCode: 'EUR',
    locales: ['fr-MC'],
    defaultLocale: 'fr-MC',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Mongolia',
    countryCode: 'MN',
    currencyCode: 'MNT',
    locales: ['mn'],
    defaultLocale: 'mn',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20AE;',
    currencyName: 'Tugrik',
    fuel: 'liter',
  },
  {
    name: 'Montenegro',
    countryCode: 'ME',
    currencyCode: 'EUR',
    locales: ['sr-Cyrl-ME', 'sr-Latn-ME'],
    defaultLocale: 'sr-Cyrl-ME',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Montserrat',
    countryCode: 'MS',
    currencyCode: 'XCD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'East Caribbean Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Morocco',
    countryCode: 'MA',
    currencyCode: 'MAD',
    locales: ['ar-MA', 'tzm-Latn-MA', 'shi-Latn-MA', 'shi-Tfng-MA'],
    defaultLocale: 'ar-MA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'MAD',
    currencyName: 'Moroccan Dirham',
    fuel: 'liter',
  },
  {
    name: 'Mozambique',
    countryCode: 'MZ',
    currencyCode: 'MZN',
    locales: ['pt-MZ', 'seh-MZ'],
    defaultLocale: 'pt-MZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'MT',
    currencyName: 'Mozambique Metical',
    fuel: 'liter',
  },
  {
    name: 'Myanmar',
    countryCode: 'MM',
    currencyCode: 'MMK',
    locales: ['my-MM'],
    defaultLocale: 'my-MM',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: 'K',
    currencyName: 'Kyat',
    fuel: 'liter',
  },
  {
    name: 'Namibia',
    countryCode: 'NA',
    currencyCode: 'ZAR',
    locales: ['af-NA', 'en-NA', 'naq-NA'],
    defaultLocale: 'en-NA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'R',
    currencyName: 'Rand',
    fuel: 'liter',
  },
  {
    name: 'Nauru',
    countryCode: 'NR',
    currencyCode: 'AUD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Nepal',
    countryCode: 'NP',
    currencyCode: 'NPR',
    locales: ['ne-NP'],
    defaultLocale: 'ne-NP',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A8;',
    currencyName: 'Nepalese Rupee',
    fuel: 'liter',
  },
  {
    name: 'Netherlands',
    countryCode: 'NL',
    currencyCode: 'EUR',
    locales: ['nl-NL'],
    defaultLocale: 'nl-NL',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'New Caledonia',
    countryCode: 'NC',
    currencyCode: 'XPF',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A3;',
    currencyName: 'CFP Franc',
    fuel: 'liter',
  },
  {
    name: 'New Zealand',
    countryCode: 'NZ',
    currencyCode: 'NZD',
    locales: ['en-NZ'],
    defaultLocale: 'en-NZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'New Zealand Dollar',
    fuel: 'liter',
  },
  {
    name: 'Nicaragua',
    countryCode: 'NI',
    currencyCode: 'NIO',
    locales: ['es-NI'],
    defaultLocale: 'es-NI',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'C&dollar;',
    currencyName: 'Cordoba Oro',
    fuel: 'gallon',
  },
  {
    name: 'Niger',
    countryCode: 'NE',
    currencyCode: 'XOF',
    locales: ['fr-NE', 'ha-Latn-NE'],
    defaultLocale: 'fr-NE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CFA',
    currencyName: 'CFA Franc BCEAO',
    fuel: 'liter',
  },
  {
    name: 'Nigeria',
    countryCode: 'NG',
    currencyCode: 'NGN',
    locales: ['ha-Latn-NG', 'ig-NG', 'yo-NG'],
    defaultLocale: 'ha-Latn-NG',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A6;',
    currencyName: 'Naira',
    fuel: 'liter',
  },
  {
    name: 'Niue',
    countryCode: 'NU',
    currencyCode: 'NZD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'New Zealand Dollar',
    fuel: 'liter',
  },
  {
    name: 'Norfolk Island',
    countryCode: 'NF',
    currencyCode: 'AUD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'North Macedonia',
    countryCode: 'MK',
    currencyCode: 'AUD',
    locales: ['mk-MK'],
    defaultLocale: 'mk-MK',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Northern Mariana Islands',
    countryCode: 'MP',
    currencyCode: 'USD',
    locales: ['en-MP'],
    defaultLocale: 'en-MP',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Norway',
    countryCode: 'NO',
    currencyCode: 'NOK',
    locales: ['nb-NO', 'nn-NO'],
    defaultLocale: 'nb-NO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Norwegian Krone',
    fuel: 'liter',
  },
  {
    name: 'Oman',
    countryCode: 'OM',
    currencyCode: 'OMR',
    locales: ['ar-OM'],
    defaultLocale: 'ar-OM',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#xFDFC;',
    currencyName: 'Rial Omani',
    fuel: 'liter',
  },
  {
    name: 'Pakistan',
    countryCode: 'PK',
    currencyCode: 'PKR',
    locales: ['en-PK', 'pa-Arab-PK', 'ur-PK'],
    defaultLocale: 'en-PK',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A8;',
    currencyName: 'Pakistan Rupee',
    fuel: 'liter',
  },
  {
    name: 'Palau',
    countryCode: 'PW',
    currencyCode: 'USD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Palestine',
    countryCode: 'PS',
    currencyCode: 'USD',
    locales: ['ar'],
    defaultLocale: 'ar',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Panama',
    countryCode: 'PA',
    currencyCode: 'USD',
    locales: ['es-PA'],
    defaultLocale: 'es-PA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Papua New Guinea',
    countryCode: 'PG',
    currencyCode: 'PGK',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'K',
    currencyName: 'Kina',
    fuel: 'liter',
  },
  {
    name: 'Paraguay',
    countryCode: 'PY',
    currencyCode: 'PYG',
    locales: ['es-PY'],
    defaultLocale: 'es-PY',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Gs',
    currencyName: 'Guarani',
    fuel: 'liter',
  },
  {
    name: 'Peru',
    countryCode: 'PE',
    currencyCode: 'PEN',
    locales: ['es-PE'],
    defaultLocale: 'es-PE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'S&sol;&period;',
    currencyName: 'Sol',
    fuel: 'gallon',
  },
  {
    name: 'Philippines',
    countryCode: 'PH',
    currencyCode: 'PHP',
    locales: ['en-PH', 'fil-PH'],
    defaultLocale: 'en-PH',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20B1;',
    currencyName: 'Philippine Peso',
    fuel: 'liter',
  },
  {
    name: 'Pitcairn',
    countryCode: 'PN',
    currencyCode: 'NZD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'New Zealand Dollar',
    fuel: 'liter',
  },
  {
    name: 'Poland',
    countryCode: 'PL',
    currencyCode: 'PLN',
    locales: ['pl-PL'],
    defaultLocale: 'pl-PL',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'z&lstrok;',
    currencyName: 'Zloty',
    fuel: 'liter',
  },
  {
    name: 'Portugal',
    countryCode: 'PT',
    currencyCode: 'EUR',
    locales: ['pt-PT'],
    defaultLocale: 'pt-PT',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Puerto Rico',
    countryCode: 'PR',
    currencyCode: 'USD',
    locales: ['es-PR'],
    defaultLocale: 'es-PR',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Qatar',
    countryCode: 'QA',
    currencyCode: 'QAR',
    locales: ['ar-QA'],
    defaultLocale: 'ar-QA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#xFDFC;',
    currencyName: 'Qatari Rial',
    fuel: 'liter',
  },
  {
    name: 'Romania',
    countryCode: 'RO',
    currencyCode: 'RUB',
    locales: ['ro-RO'],
    defaultLocale: 'ro-RO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20BD;',
    currencyName: 'Russian Ruble',
    fuel: 'liter',
  },
  {
    name: 'Russia',
    countryCode: 'RU',
    currencyCode: 'RUB',
    locales: ['ru-RU'],
    defaultLocale: 'ru-RU',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20BD;',
    currencyName: 'Russian Ruble',
    fuel: 'liter',
  },
  {
    name: 'Rwanda',
    countryCode: 'RW',
    currencyCode: 'RWF',
    locales: ['fr-RW', 'rw-RW'],
    defaultLocale: 'fr-RW',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'R&#x20A3;',
    currencyName: 'Rwanda Franc',
    fuel: 'liter',
  },
  {
    name: 'Réunion',
    countryCode: 'RE',
    currencyCode: 'RWF',
    locales: ['fr-RE'],
    defaultLocale: 'fr-RE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'R&#x20A3;',
    currencyName: 'Rwanda Franc',
    fuel: 'liter',
  },
  {
    name: 'Saint Barthélemy',
    countryCode: 'BL',
    currencyCode: 'EUR',
    locales: ['fr-BL'],
    defaultLocale: 'fr-BL',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Saint Helena',
    countryCode: 'SH',
    currencyCode: 'SHP',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'Saint Helena Pound',
    fuel: 'liter',
  },
  {
    name: 'Saint Kitts and Nevis',
    countryCode: 'KN',
    currencyCode: 'XCD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'East Caribbean Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Saint Lucia',
    countryCode: 'LC',
    currencyCode: 'XCD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'East Caribbean Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Saint Martin',
    countryCode: 'MF',
    currencyCode: 'EUR',
    locales: ['fr-MF'],
    defaultLocale: 'fr-MF',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
    currencyCode: 'EUR',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    currencyCode: 'XCD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'East Caribbean Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Samoa',
    countryCode: 'WS',
    currencyCode: 'WST',
    locales: ['sm'],
    defaultLocale: 'sm',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: 'WS&dollar;',
    currencyName: 'Tala',
    fuel: 'gallon',
  },
  {
    name: 'San Marino',
    countryCode: 'SM',
    currencyCode: 'EUR',
    locales: ['it'],
    defaultLocale: 'it',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Sao Tome and Principe',
    countryCode: 'ST',
    currencyCode: 'STN',
    locales: ['pt'],
    defaultLocale: 'pt',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Db',
    currencyName: 'Dobra',
    fuel: 'liter',
  },
  {
    name: 'Saudi Arabia',
    countryCode: 'SA',
    currencyCode: 'SAR',
    locales: ['ar-SA'],
    defaultLocale: 'ar-SA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#xFDFC;',
    currencyName: 'Saudi Riyal',
    fuel: 'liter',
  },
  {
    name: 'Senegal',
    countryCode: 'SN',
    currencyCode: 'XOF',
    locales: ['fr-SN', 'ff-SN'],
    defaultLocale: 'fr-SN',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CFA',
    currencyName: 'CFA Franc BCEAO',
    fuel: 'liter',
  },
  {
    name: 'Serbia',
    countryCode: 'RS',
    currencyCode: 'RSD',
    locales: ['sr-Cyrl-RS', 'sr-Latn-RS'],
    defaultLocale: 'sr-Cyrl-RS',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&Dcy;&icy;&ncy;&period;',
    currencyName: 'Serbian Dinar',
    fuel: 'liter',
  },
  {
    name: 'Seychelles',
    countryCode: 'SC',
    currencyCode: 'SCR',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A8;',
    currencyName: 'Seychelles Rupee',
    fuel: 'liter',
  },
  {
    name: 'Sierra Leone',
    countryCode: 'SL',
    currencyCode: 'SLL',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'Le',
    currencyName: 'Leone',
    fuel: 'liter',
  },
  {
    name: 'Singapore',
    countryCode: 'SG',
    currencyCode: 'SGD',
    locales: ['zh-Hans-SG', 'en-SG'],
    defaultLocale: 'en-SG',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Singapore Dollar',
    fuel: 'liter',
  },
  {
    name: 'Sint Maarten',
    countryCode: 'SX',
    currencyCode: 'ANG',
    locales: ['nl'],
    defaultLocale: 'nl',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&fnof;',
    currencyName: 'Netherlands Antillean Guilder',
    fuel: 'liter',
  },
  {
    name: 'Slovakia',
    countryCode: 'SK',
    currencyCode: 'EUR',
    locales: ['sk-SK'],
    defaultLocale: 'sk-SK',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Slovenia',
    countryCode: 'SI',
    currencyCode: 'EUR',
    locales: ['sl-SI'],
    defaultLocale: 'sl-SI',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Solomon Islands',
    countryCode: 'SB',
    currencyCode: 'SBD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Solomon Islands Dollar',
    fuel: 'liter',
  },
  {
    name: 'Somalia',
    countryCode: 'SO',
    currencyCode: 'SOS',
    locales: ['so-SO'],
    defaultLocale: 'so-SO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'S',
    currencyName: 'Somali Shilling',
    fuel: 'liter',
  },
  {
    name: 'South Africa',
    countryCode: 'ZA',
    currencyCode: 'ZAR',
    locales: ['af-ZA', 'en-ZA', 'zu-ZA'],
    defaultLocale: 'af-ZA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'R',
    currencyName: 'Rand',
    fuel: 'liter',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
    currencyCode: 'USD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'South Sudan',
    countryCode: 'SS',
    currencyCode: 'SSP',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'South Sudanese Pound',
    fuel: 'liter',
  },
  {
    name: 'Spain',
    countryCode: 'ES',
    currencyCode: 'EUR',
    locales: ['eu-ES', 'ca-ES', 'gl-ES', 'es-ES'],
    defaultLocale: 'es-ES',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
  {
    name: 'Sri Lanka',
    countryCode: 'LK',
    currencyCode: 'LKR',
    locales: ['si-LK', 'ta-LK'],
    defaultLocale: 'si-LK',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A8;',
    currencyName: 'Sri Lanka Rupee',
    fuel: 'liter',
  },
  {
    name: 'Sudan',
    countryCode: 'SD',
    currencyCode: 'SDG',
    locales: ['ar-SD'],
    defaultLocale: 'ar-SD',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x62C;&period;&#x633;&period;',
    currencyName: 'Sudanese Pound',
    fuel: 'liter',
  },
  {
    name: 'Suriname',
    countryCode: 'SR',
    currencyCode: 'SRD',
    locales: ['nl'],
    defaultLocale: 'nl',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Surinam Dollar',
    fuel: 'liter',
  },
  {
    name: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
    currencyCode: 'NOK',
    locales: ['no'],
    defaultLocale: 'no',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Norwegian Krone',
    fuel: 'liter',
  },
  {
    name: 'Sweden',
    countryCode: 'SE',
    currencyCode: 'SEK',
    locales: ['sv-SE'],
    defaultLocale: 'sv-SE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'kr',
    currencyName: 'Swedish Krona',
    fuel: 'liter',
  },
  {
    name: 'Switzerland',
    countryCode: 'CH',
    currencyCode: 'CHF',
    locales: ['fr-CH', 'de-CH', 'it-CH', 'rm-CH', 'gsw-CH'],
    defaultLocale: 'fr-CH',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CHF',
    currencyName: 'Swiss Franc',
    fuel: 'liter',
  },
  {
    name: 'Syrian Arab Republic',
    countryCode: 'SY',
    currencyCode: 'SYP',
    locales: ['ar-SY'],
    defaultLocale: 'ar-SY',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'Syrian Pound',
    fuel: 'liter',
  },
  {
    name: 'Taiwan',
    countryCode: 'TW',
    currencyCode: 'SYP',
    locales: ['zh-Hant-TW'],
    defaultLocale: 'zh-Hant-TW',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&pound;',
    currencyName: 'Syrian Pound',
    fuel: 'liter',
  },
  {
    name: 'Tajikistan',
    countryCode: 'TJ',
    currencyCode: 'TJS',
    locales: ['tg'],
    defaultLocale: 'tg',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'SM',
    currencyName: 'Somoni',
    fuel: 'liter',
  },
  {
    name: 'Tanzania',
    countryCode: 'TZ',
    currencyCode: 'TZS',
    locales: [
      'asa-TZ',
      'bez-TZ',
      'lag-TZ',
      'jmc-TZ',
      'kde-TZ',
      'mas-TZ',
      'rof-TZ',
      'rwk-TZ',
      'sw-TZ',
      'vun-TZ',
    ],
    defaultLocale: 'asa-TZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'TSh',
    currencyName: 'Tanzanian Shilling',
    fuel: 'liter',
  },
  {
    name: 'Thailand',
    countryCode: 'TH',
    currencyCode: 'THB',
    locales: ['th-TH'],
    defaultLocale: 'th-TH',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#xE3F;',
    currencyName: 'Baht',
    fuel: 'liter',
  },
  {
    name: 'Timor-Leste',
    countryCode: 'TL',
    currencyCode: 'USD',
    locales: ['pt'],
    defaultLocale: 'pt',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'Togo',
    countryCode: 'TG',
    currencyCode: 'XOF',
    locales: ['ee-TG', 'fr-TG'],
    defaultLocale: 'fr-TG',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'CFA',
    currencyName: 'CFA Franc BCEAO',
    fuel: 'liter',
  },
  {
    name: 'Tokelau',
    countryCode: 'TK',
    currencyCode: 'NZD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'New Zealand Dollar',
    fuel: 'liter',
  },
  {
    name: 'Tonga',
    countryCode: 'TO',
    currencyCode: 'TOP',
    locales: ['to-TO'],
    defaultLocale: 'to-TO',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'T&dollar;',
    currencyName: 'Pa’anga',
    fuel: 'liter',
  },
  {
    name: 'Trinidad and Tobago',
    countryCode: 'TT',
    currencyCode: 'TTD',
    locales: ['en-TT'],
    defaultLocale: 'en-TT',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'TT&dollar;',
    currencyName: 'Trinidad and Tobago Dollar',
    fuel: 'liter',
  },
  {
    name: 'Tunisia',
    countryCode: 'TN',
    currencyCode: 'TND',
    locales: ['ar-TN'],
    defaultLocale: 'ar-TN',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x62F;&period;&#x62A;',
    currencyName: 'Tunisian Dinar',
    fuel: 'liter',
  },
  {
    name: 'Turkey',
    countryCode: 'TR',
    currencyCode: 'TRY',
    locales: ['tr-TR'],
    defaultLocale: 'tr-TR',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20BA;',
    currencyName: 'Turkish Lira',
    fuel: 'liter',
  },
  {
    name: 'Turkmenistan',
    countryCode: 'TM',
    currencyCode: 'TMT',
    locales: ['tk'],
    defaultLocale: 'tk',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'T',
    currencyName: 'Turkmenistan New Manat',
    fuel: 'liter',
  },
  {
    name: 'Turks and Caicos Islands',
    countryCode: 'TC',
    currencyCode: 'USD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Tuvalu',
    countryCode: 'TV',
    currencyCode: 'AUD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'Australian Dollar',
    fuel: 'liter',
  },
  {
    name: 'Uganda',
    countryCode: 'UG',
    currencyCode: 'UGX',
    locales: ['cgg-UG', 'lg-UG', 'nyn-UG', 'xog-UG', 'teo-UG'],
    defaultLocale: 'cgg-UG',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'USh',
    currencyName: 'Uganda Shilling',
    fuel: 'liter',
  },
  {
    name: 'Ukraine',
    countryCode: 'UA',
    currencyCode: 'UAH',
    locales: ['ru-UA', 'uk-UA'],
    defaultLocale: 'uk-UA',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20B4;',
    currencyName: 'Hryvnia',
    fuel: 'liter',
  },
  {
    name: 'United Arab Emirates',
    countryCode: 'AE',
    currencyCode: 'AED',
    locales: ['ar-AE'],
    defaultLocale: 'ar-AE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x62F;&period;&#x625;',
    currencyName: 'UAE Dirham',
    fuel: 'liter',
  },
  {
    name: 'United Kingdom',
    countryCode: 'GB',
    currencyCode: 'GBP',
    locales: ['kw-GB', 'en-GB', 'gv-GB', 'cy-GB'],
    defaultLocale: 'en-GB',
    distanceUnitLong: 'mile',
    distanceUnitShort: 'mi',
    currencySymbol: '&pound;',
    currencyName: 'Pound Sterling',
    fuel: 'liter',
  },
  {
    name: 'United States Minor Outlying Islands',
    countryCode: 'UM',
    currencyCode: 'USD',
    locales: ['en-UM'],
    defaultLocale: 'en-UM',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'liter',
  },
  {
    name: 'United States',
    countryCode: 'US',
    currencyCode: 'USD',
    locales: ['chr-US', 'en-US', 'haw-US', 'es-US'],
    defaultLocale: 'en-US',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
    addressFieldsOrder: DEFAULT_COUNTRY_ADDRESS_FIELD_ORDER,
    requiredAddressFields: DEFAULT_COUNTRY_ADDRESS_REQUIRED_FIELDS,
  },
  {
    name: 'Uruguay',
    countryCode: 'UY',
    currencyCode: 'UYU',
    locales: ['es-UY'],
    defaultLocale: 'es-UY',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;U',
    currencyName: 'Peso Uruguayo',
    fuel: 'liter',
  },
  {
    name: 'Uzbekistan',
    countryCode: 'UZ',
    currencyCode: 'UZS',
    locales: ['uz-Cyrl-UZ', 'uz-Latn-UZ'],
    defaultLocale: 'uz-Cyrl-UZ',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&lcy;&vcy;',
    currencyName: 'Uzbekistan Sum',
    fuel: 'liter',
  },
  {
    name: 'Vanuatu',
    countryCode: 'VU',
    currencyCode: 'VUV',
    locales: ['bi'],
    defaultLocale: 'bi',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'VT',
    currencyName: 'Vatu',
    fuel: 'liter',
  },
  {
    name: 'Venezuela',
    countryCode: 'VE',
    currencyCode: 'VES',
    locales: ['es-VE'],
    defaultLocale: 'es-VE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'VT',
    currencyName: 'Vatu',
    fuel: 'liter',
  },
  {
    name: 'Vietnam',
    countryCode: 'VN',
    currencyCode: 'VND',
    locales: ['vi-VN'],
    defaultLocale: 'vi-VN',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20AB;',
    currencyName: 'Dong',
    fuel: 'liter',
  },
  {
    name: 'Virgin Islands (British)',
    countryCode: 'VG',
    currencyCode: 'USD',
    locales: ['en'],
    defaultLocale: 'en',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Virgin Islands (U.S.)',
    countryCode: 'VI',
    currencyCode: 'USD',
    locales: ['en-VI'],
    defaultLocale: 'en-VI',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&dollar;',
    currencyName: 'US Dollar',
    fuel: 'gallon',
  },
  {
    name: 'Wallis and Futuna',
    countryCode: 'WF',
    currencyCode: 'XPF',
    locales: ['fr'],
    defaultLocale: 'fr',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#x20A3;',
    currencyName: 'CFP Franc',
    fuel: 'liter',
  },
  {
    name: 'Western Sahara',
    countryCode: 'EH',
    currencyCode: 'MAD',
    locales: ['es'],
    defaultLocale: 'es',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: 'MAD',
    currencyName: 'Moroccan Dirham',
    fuel: 'liter',
  },
  {
    name: 'Yemen',
    countryCode: 'YE',
    currencyCode: 'YER',
    locales: ['ar-YE'],
    defaultLocale: 'ar-YE',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&#xFDFC;',
    currencyName: 'Yemeni Rial',
    fuel: 'liter',
  },
  {
    name: 'Zambia',
    countryCode: 'ZM',
    currencyCode: 'ZMW',
    locales: ['bem-ZM'],
    defaultLocale: 'bem-ZM',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '',
    currencyName: 'Zambian Kwacha',
    fuel: 'liter',
  },
  {
    name: 'Zimbabwe',
    countryCode: 'ZW',
    currencyCode: 'ZWL',
    locales: ['en-ZW', 'nd-ZW', 'sn-ZW'],
    defaultLocale: 'en-ZW',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '',
    currencyName: 'Zimbabwean dollar',
    fuel: 'liter',
  },
  {
    name: 'Åland Islands',
    countryCode: 'AX',
    currencyCode: 'EUR',
    locales: ['sv'],
    defaultLocale: 'sv',
    distanceUnitLong: 'kilometer',
    distanceUnitShort: 'km',
    currencySymbol: '&euro;',
    currencyName: 'Euro',
    fuel: 'liter',
  },
];
