import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { formatCurrencyToCompanyLocale, formatNumberToLocale } from '../../../utils/numbers';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import LinkText from '../../Text/LinkText';

const AnomaliesHighManualMilesTable = props => {
  const { t, dataSource, hideReceipts, ...rest } = props;

  const COLUMNS = useMemo(
    () => [
      {
        key: 'user',
        title: t('user'),
        dataIndex: 'user',
        render: user => <ProfileNamePicture user={user} />,
      },
      {
        key: 'paymentPeriod',
        title: t('paymentPeriod'),
        render: ({ paymentPeriodStart, paymentPeriodEnd }, data) => {
          return (
            <Link
              disabled={!data.tripReceiptId}
              to={
                hideReceipts
                  ? `${INTERNAL_LINKS.REIMBURSEMENT}/${data.tripReceiptId}`
                  : `${INTERNAL_LINKS.RECEIPTS}/${data.tripReceiptId}`
              }
            >
              <LinkText variant="b">
                {[
                  getMomentDateWithoutTimezone(paymentPeriodStart).format('MM-DD-YYYY'),
                  getMomentDateWithoutTimezone(paymentPeriodEnd).format('MM-DD-YYYY'),
                ].join(' to ')}
              </LinkText>
            </Link>
          );
        },
      },
      {
        align: 'right',
        key: 'totalTrips',
        title: t('totalTrips'),
        dataIndex: 'totalTrips',
        sorter: true,
        render: totalTrips => formatNumberToLocale(totalTrips),
      },
      {
        align: 'right',
        key: 'gpsTrips',
        title: t('anomalies:gpsTrips'),
        dataIndex: 'gpsTrips',
        sorter: true,
        render: gpsTrips => formatNumberToLocale(gpsTrips),
      },
      {
        align: 'right',
        key: 'manualTrips',
        title: t('anomalies:manualTrips'),
        dataIndex: 'manualTrips',
        sorter: true,
        render: manualTrips => formatNumberToLocale(manualTrips),
      },
      {
        align: 'right',
        defaultSortOrder: 'descend',
        key: 'manualTripsPercentage',
        title: t('anomalies:manualTrips%'),
        dataIndex: 'manualTripsPercentage',
        sorter: true,
        render: manualTripsPercentage => formatNumberToLocale(manualTripsPercentage),
      },
      {
        align: 'right',
        key: 'manualTripsTotalAmount',
        title: t('anomalies:manualTripsAmount'),
        dataIndex: 'manualTripsTotalAmount',
        sorter: true,
        render: manualTripsTotalAmount => formatCurrencyToCompanyLocale(manualTripsTotalAmount),
      },
    ],
    [t, hideReceipts],
  );

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

export default AnomaliesHighManualMilesTable;
