import { Col, Empty, Row } from 'antd';
import React from 'react';

import Box from '../../../components/Box';
import SpaceSpinner from '../../../components/SpaceSpinner';
import Text from '../../../components/Text';
import EventTimeline from '../EventTimeline';
import classNames from './styles.module.scss';

const DashboardEventTimeline = props => {
  const { t, timeline, isLoading, timelineFilters } = props;

  if (!timeline || isLoading) {
    return (
      <Box>
        <SpaceSpinner height={242} />
      </Box>
    );
  }

  if (
    (Array.isArray(timeline) && !timeline.length) ||
    (timelineFilters.groupID && !timelineFilters.startDate && !timelineFilters.endDate)
  ) {
    return (
      <Box>
        <Row className={classNames.timelineRow}>
          <Col flex={1}>
            <Empty
              description={
                <Text variant="strong" size="sm" textAlign="center">
                  {t('noDataToDisplay')}
                </Text>
              }
            />
          </Col>
        </Row>
      </Box>
    );
  }

  return (
    <Box padding="0px">
      <Row className={classNames.timelineRow} wrap={false}>
        <Col flex={1}>
          <EventTimeline
            t={t}
            groupId={timelineFilters.groupID}
            fromDate={timelineFilters.startDate}
            toDate={timelineFilters.endDate}
            timeline={timeline}
          />
        </Col>

        {/* <Col flex="270px" className={classNames.complianceColumn}>
          <Row align="middle" justify="center">
            <div>
              <Text variant="h4" style={{ marginBottom: 5 }}>
                Compliance Alerts
              </Text>
              <FadedText size="sm">{t('Deadline 30 Days from start')}</FadedText>

              <div style={{ marginTop: 15, marginBottom: 15 }}>
                <Text>Insurance: 0 user issues</Text>
                <Text>Vehicle: 0 user issues</Text>
              </div>

              <Link to="#">
                <LinkText renderAs="p" variant="strong" style={{ marginTop: 'auto' }}>
                  {t('View Alerts')}
                </LinkText>
              </Link>
            </div>
          </Row>
        </Col> */}
      </Row>
    </Box>
  );
};

export default DashboardEventTimeline;
