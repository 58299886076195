import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class MeasureOneAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'measure-one' });
  }

  /**
   * Fetch MeasureOne public token
   * @returns {{ dataRequestId: string, publicToken: string }}
   */
  async fetchUserMeasureOnePublicToken() {
    const result = await handleApiCalls('post', `${this.baseUrl}/data-request`);
    return result.data;
  }
}

export default MeasureOneAPI;
