import { get, join, startCase } from 'lodash';
import React, { useMemo } from 'react';

import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, momentTimezone } from '../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import { CustomTable } from '../..';

const FixedReceiptDetailsTable = props => {
  const { t, dataSource, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(receipt => {
        const standardVehicleClass = get(receipt, 'favrInformations.standardVehicle.class', '');
        const standardVehicleYear = get(receipt, 'favrInformations.standardVehicle.year', '');
        const standardVehicleMake = get(receipt, 'favrInformations.standardVehicle.make', '');
        const standardVehicleModel = get(receipt, 'favrInformations.standardVehicle.model', '');

        return (
          checkIfStringContainsValue(
            momentTimezone(receipt.date, null).format('MM-DD-YYYY'),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            getMomentDateWithoutTimezone(receipt.paymentFor, null).format('MMMM - YYYY'),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            formatNumberWithCurrency(receipt.fixedAmount, receipt.currency),
            searchTerm,
          ) ||
          checkIfStringContainsValue(standardVehicleClass, searchTerm) ||
          checkIfStringContainsValue(standardVehicleYear, searchTerm) ||
          checkIfStringContainsValue(standardVehicleMake, searchTerm) ||
          checkIfStringContainsValue(standardVehicleModel, searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      title: t('date'),
      dataIndex: 'date',
      render: date => momentTimezone(date, null).format('MM-DD-YYYY'),
    },
    {
      title: t('paymentFor'),
      dataIndex: 'paymentFor',
      render: paymentFor => getMomentDateWithoutTimezone(paymentFor).format('MMMM - YYYY'),
    },
    {
      title: t('fixedAmount'),
      dataIndex: 'fixedAmount',
      render: (fixedAmount, data) => formatNumberWithCurrency(fixedAmount, data.currency),
    },
    {
      title: t('vehicleClass'),
      dataIndex: ['favrInformations', 'standardVehicle'],
      render: vehicle => startCase(vehicle?.class),
    },
    {
      title: t('standardVehicle'),
      dataIndex: ['favrInformations', 'standardVehicle'],
      render: vehicle => startCase(join([vehicle?.year, vehicle?.make, vehicle?.model], ' ')),
    },
  ];

  return (
    <CustomTable
      onSearchTermChange={setSearchTerm}
      {...rest}
      dataSource={filteredDataSource}
      rowKey={data => data._id}
      columns={COLUMNS}
      scroll={{ x: 970 }}
      pagination={false}
    />
  );
};

export default FixedReceiptDetailsTable;
