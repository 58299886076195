import React, { useCallback, useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import storage from 'store';

import NotificationBell from '../NotificationBell';
import NotificationItemList from '../NotificationItemList';
import Popover from '../Popover';
import Toast from '../Toast';

const AppNotificationWrapper = props => {
  const { t, user } = props;

  const [socketClient, setSocketClient] = useState();

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    const token = storage.get('accessToken');

    const socket = socketIOClient(process.env.REACT_APP_SOCKET_HOST, {
      path: '/socket.io',
      transports: ['websocket'],
      secure: true,
    });
    setSocketClient(socket);

    socket
      .emit('authenticate', { token })
      .on('authenticated', () => {
        //get user notifications
        socket.on('notifications', notifications => {
          setNotifications(notifications);
          setUnreadNotificationCount(notifications.filter(n => !n.read).length);
        });
      })
      .on('unauthorized', msg => {
        console.error(`unauthorized: ${JSON.stringify(msg.data)}`);
        Toast({
          type: 'error',
          message: msg.data.type,
        });
      });

    return () => {
      socket.close();
      setNotifications();
      setSocketClient();
      setUnreadNotificationCount(0);
    };
  }, [user._id]);

  const handleMarkNotificationAsRead = useCallback(
    async id => {
      socketClient.emit('markNotificationsAsRead', [id]);

      const updatedNotifications = [...notifications];
      const notificationIndex = updatedNotifications.findIndex(n => n._id === id);

      updatedNotifications[notificationIndex].read = true;

      setNotifications(updatedNotifications);
      setUnreadNotificationCount(updatedNotifications.filter(n => !n.read).length);
    },
    [socketClient, notifications],
  );

  const handleMarkAllNotificationAsRead = useCallback(
    async ids => {
      socketClient.emit('markNotificationsAsRead', ids);

      const updatedNotifications = notifications.map(n => ({
        ...n,
        read: true,
      }));

      setUnreadNotificationCount(0);
      setNotifications(updatedNotifications);
    },
    [socketClient, notifications],
  );

  return (
    <Popover
      noPadding
      placement="bottomRight"
      title={t('notification_plural')}
      content={
        <NotificationItemList
          t={t}
          unreadNotificationCount={unreadNotificationCount}
          notifications={notifications}
          onNotificationRead={handleMarkNotificationAsRead}
          onMarkAllRead={handleMarkAllNotificationAsRead}
        />
      }
    >
      <NotificationBell count={unreadNotificationCount} />
    </Popover>
  );
};

export default AppNotificationWrapper;
