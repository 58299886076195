import React from 'react';

import useDebounce from './useDebounce';
import useDidUpdateEffect from './useDidUpdateEffect';

/**
 * Hook similar to useState but where the update of the "state value" is delayed
 * by a specific amount of time to prevent frequent rerenders.
 *
 * @param {any} value Initial state value
 * @param {number=} delay Delay in milliseconds
 * @returns {[delayedValue: any, setState: React.Dispatch<any>]} values similar to useState
 */
const useDebouncedState = (value, delay = 200, callback) => {
  const [state, setState] = React.useState(value);
  const debouncedState = useDebounce(state, delay);

  useDidUpdateEffect(() => {
    if (typeof callback === 'function') {
      callback(debouncedState);
    }
  }, [debouncedState, callback]);

  return [debouncedState, setState];
};

export default useDebouncedState;
