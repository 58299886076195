import { Col, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { STATUS_LIST } from '../../enum';
import useFeatureToggle from '../../hooks/useFeatureToggle';
import { momentEST } from '../../utils/common';
import Box from '../Box';
import Button from '../Button';
import FormItem from '../Form/FormItem';
import HorizontalLineDivider from '../HorizontalLineDivider';
import Select from '../Select';
import UserProfileForm from '../UserProfileForm';

/**
 * Renders a Form to update the User's data
 */
const UserProfileFormSection = props => {
  const {
    t,
    isSubmitting,
    isSendingActivationLink,
    userDetails,
    timezoneOptions,
    canUpdateUserActiveStatus,
    canSendActivationLink,
    onSubmit,
    onImageUpload,
    onUserStatusChange,
    sendActivationLink,
    onChangePassword,
    canChangePassword,
    canEditUserIdentity,
    canViewDirectManager,
    canChangeDirectManager,
  } = props;

  const { isUserDataReadOnly } = useFeatureToggle();

  const [formValues, setFormValues] = useState({
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    email: userDetails.email,
    phone: userDetails.phone,
    preferences: userDetails.preferences || { preferredTimezone: undefined, language: 'en' },
    department: userDetails.department,
    employeeId: userDetails?.employee?.employeeId,
    directManager: userDetails?.directManager,
    sendActivationEmailDate: userDetails.sendActivationEmailDate
      ? momentEST(userDetails.sendActivationEmailDate)
      : null,
  });

  const handleValueChange = useCallback(
    field => setFormValues(currentValues => ({ ...currentValues, ...field })),
    [],
  );

  return (
    <Col span={24}>
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Box>
            <Space direction="vertical" size="middle">
              <Row>
                <Col span={24}>
                  <UserProfileForm
                    t={t}
                    userDetails={userDetails}
                    isSubmitting={isSubmitting}
                    isSendingActivationLink={isSendingActivationLink}
                    values={formValues}
                    currentStatus={userDetails.status}
                    formDisabled={
                      isUserDataReadOnly || userDetails.status === STATUS_LIST().Status.DELETED
                    }
                    onUserStatusChange={onUserStatusChange}
                    profilePicture={userDetails.profilePicture}
                    timezoneOptions={timezoneOptions}
                    canUpdateUserStatus={canUpdateUserActiveStatus}
                    onFieldChange={handleValueChange}
                    onImageUpload={onImageUpload}
                    sendActivationLink={sendActivationLink}
                    canSendActivationLink={canSendActivationLink}
                    canEditUserIdentity={canEditUserIdentity}
                    canViewDirectManager={canViewDirectManager}
                    canChangeDirectManager={canChangeDirectManager}
                  />
                </Col>
              </Row>

              <Row>
                <HorizontalLineDivider marginTop={8} marginBottom={8} />
              </Row>

              <Row>
                <Col xs={24} lg={12}>
                  <FormItem label={t('language')} className="no-margin-bottom">
                    <Select
                      disabled={
                        isUserDataReadOnly ||
                        isSubmitting ||
                        userDetails.status === STATUS_LIST().Status.DELETED
                      }
                      value={formValues.preferences.language}
                      onChange={language => {
                        handleValueChange({
                          preferences: { ...formValues.preferences, language },
                        });
                      }}
                      options={[
                        { label: t('english'), value: 'en' },
                        { label: t('french'), value: 'fr' },
                      ]}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <HorizontalLineDivider marginTop={8} marginBottom={8} />
              </Row>

              <Row justify="space-between" gutter={[16, 16]}>
                <Col>
                  {canChangePassword && (
                    <Button onClick={onChangePassword}>{t('changePassword')}</Button>
                  )}
                </Col>

                <Col>
                  <Button
                    loading={isSubmitting}
                    disabled={
                      isUserDataReadOnly || userDetails.status === STATUS_LIST().Status.DELETED
                    }
                    onClick={() => onSubmit(formValues)}
                  >
                    {t('updateProfile')}
                  </Button>
                </Col>
              </Row>
            </Space>
          </Box>
        </Col>
      </Row>
    </Col>
  );
};

UserProfileFormSection.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  userDetails: PropTypes.shape({}),
  canUpdateUserNames: PropTypes.bool,
  canUpdateUserActiveStatus: PropTypes.bool,
  timezoneOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

UserProfileFormSection.defaultProps = {
  isUpdating: false,
  userDetails: {},
  canUpdateUserNames: true,
  canUpdateUserActiveStatus: false,
  timezoneOptions: [],
};

export default UserProfileFormSection;
