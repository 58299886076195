export const checkForCreateErrorsOnImportRequest = req => {
  const { validation } = req;
  let hasErrors = false;

  for (let i = 0; i < validation.length; i++) {
    const trip = validation[i];
    if (
      (Array.isArray(trip?.createErrors) && !!trip.createErrors.length) ||
      (Array.isArray(trip?.errors) && !!trip.errors.length)
    ) {
      hasErrors = true;
      break;
    }
  }

  return hasErrors;
};
