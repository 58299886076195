import { useCallback, useState } from 'react';

const useTableExpandedRows = () => {
  const [expandedRowKeys, setExpandedRowsKeys] = useState([]);

  const toggleExpandedRow = useCallback(
    row => {
      const rowKey = row?._id;
      if (rowKey && !expandedRowKeys.includes(rowKey)) {
        setExpandedRowsKeys([rowKey]);
      } else {
        setExpandedRowsKeys([]);
      }
    },
    [expandedRowKeys],
  );

  return { expandedRowKeys, toggleExpandedRow };
};

export default useTableExpandedRows;
