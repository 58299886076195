import React from 'react';

import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const ReportsSettingsFormItems = props => {
  const { t, authUser, companySettings, onFeatureChange } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('Reports')}>
      <FeatureFormItem
        label={t('alertsDashboard')}
        value={companySettings.alertsDashboard}
        onChange={checked => onFeatureChange({ alertsDashboard: checked })}
      />

      <FeatureFormItem
        label={t('displayActiveReports')}
        value={companySettings.activeReports}
        onChange={checked => onFeatureChange({ activeReports: checked })}
      />

      <FeatureFormItem
        label={t('favr-mileage-adjustment')}
        value={companySettings.favrMileageAdjustment}
        onChange={checked => onFeatureChange({ favrMileageAdjustment: checked })}
      />
    </SettingSectionWrapper>
  );
};

export default ReportsSettingsFormItems;
