import { createReducer } from '../../utils/storeCreators';
import {
  LOAD_CUSTOM_PRICE_DATA,
  LOAD_PRICE_DATA,
  UPDATE_CUSTOM_PRICE_DATA,
  UPDATE_PRICE_DATA,
} from '../actions/prices';
import initialState from '../initialState';

const pricesReducer = createReducer({
  initialState: initialState.prices,
  reducer: (state, action) => {
    let prices = state;

    switch (action.type) {
      case LOAD_PRICE_DATA:
        prices.isLoadingData = true;
        return { ...state, ...prices };
      case LOAD_CUSTOM_PRICE_DATA:
        prices.isLoadingData = true;
        return { ...state, ...prices };
      case UPDATE_PRICE_DATA:
        prices.isLoadingData = false;
        prices.plans = action.data.priceData;

        return { ...state, ...prices };
      case UPDATE_CUSTOM_PRICE_DATA:
        prices.isLoadingData = false;
        prices.customPlans = action.data.priceData;

        return { ...state, ...prices };
      default:
        return state;
    }
  },
});

export default pricesReducer;
