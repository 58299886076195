import { performApiCallIfCompanySubIsActive } from './axiosInstance';

/**
 * Performs an API call to update a company's settings
 *
 * @param {string} companyID Company ID
 * @param {object} settingParams
 * @param {"importLocationsMethod"} settingParams.setting
 * @param {any} settingParams.value
 * @param {string} settingParams.planId
 * @returns
 */
export const updateCompanySettings = async (
  companyID,
  { settings, values, commute, planId, pricePlan },
) => {
  const url = `${process.env.REACT_APP_HOST_API}company-settings/${companyID}`;
  const params = { settings, values, commute, planId, pricePlan };
  const result = await performApiCallIfCompanySubIsActive('put', url, params);
  return result.data;
};
