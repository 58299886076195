import { Col, Empty, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import NotificationItem from '../NotificationItem';
import Spinner from '../Spinner';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import LinkText from '../Text/LinkText';

const NotificationItemList = props => {
  const history = useHistory();

  const { t, notifications, unreadNotificationCount, onNotificationRead, onMarkAllRead } = props;

  const handleMarkAllAsRead = () => {
    const notificationIds = notifications.map(n => n._id);
    onMarkAllRead(notificationIds);
  };

  const [newNotificationSection, earlierNotificationSection] = useMemo(() => {
    const newSection = [];
    const earlierSection = [];

    if (Array.isArray(notifications)) {
      notifications.forEach(notification => {
        const DAY_DIFF = moment(notification.created).diff(moment(), 'days');

        if (DAY_DIFF === 0) {
          newSection.push(notification);
        } else {
          earlierSection.push(notification);
        }
      });
    }

    return [newSection, earlierSection];
  }, [notifications]);

  if (!Array.isArray(notifications)) {
    return (
      <Row justify="center" align="middle" style={{ width: 350, minHeight: 300 }}>
        <Col>
          <Spinner />
        </Col>
      </Row>
    );
  }

  if (!notifications.length) {
    return (
      <Row style={{ width: 350, padding: '10px 0' }}>
        <Col flex={1}>
          <Empty
            description={
              <FadedText size="sm" textAlign="center" renderAs="span">
                {t('noNotification')}
              </FadedText>
            }
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row style={{ width: 350, height: 400, overflowY: 'auto' }}>
      <Col flex={1}>
        {!!newNotificationSection.length && (
          <Row align="middle" justify="space-between" style={{ padding: '10px 16px 5px' }}>
            <Col>
              <Text size="sm">{t('new')}</Text>
            </Col>
            {unreadNotificationCount > 0 && (
              <Col>
                <LinkText size="sm" onClick={handleMarkAllAsRead}>
                  {t('markAllRead')}
                </LinkText>
              </Col>
            )}
          </Row>
        )}
        {newNotificationSection.map(notification => (
          <Col key={notification._id} flex="100%" style={{ flexWrap: 'nowrap' }}>
            <NotificationItem
              t={t}
              notification={notification}
              onNotificationRead={onNotificationRead}
              history={history}
            />
          </Col>
        ))}

        {!!earlierNotificationSection.length && (
          <Row style={{ padding: '10px 16px 5px' }}>
            <Text size="sm">{t('earlier')}</Text>
          </Row>
        )}
        {earlierNotificationSection.map(notification => (
          <Col key={notification._id} flex="100%" style={{ flexWrap: 'nowrap' }}>
            <NotificationItem
              t={t}
              notification={notification}
              onNotificationRead={onNotificationRead}
              history={history}
            />
          </Col>
        ))}
      </Col>
    </Row>
  );
};

NotificationItemList.defaultProps = {
  notifications: [],
};

export default NotificationItemList;
