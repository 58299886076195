import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { RATE_FORM_WIZARD_STEPS, RATE_TYPES } from '../enum/Rates';
import * as rateWizardActions from '../stores/actions/rateWizard';
import { isGovernmentFlatRate, isTieredRate } from '../utils/rates';
import { useStoreSelector } from '../utils/storeSelectors';
import useDefaultRate from './queries/useDefaultRate';

const initalBands = [{ bandStart: 0, bandEnd: 99999 }];

const useRateForm = (formInstance, wizardInstance) => {
  const dispatch = useDispatch();

  const rateWizard = useStoreSelector('rateWizard');
  const companyGroups = useStoreSelector('group.groupList', []);
  const countryRates = useStoreSelector('common.countryRates', []);

  const currentDefaultRate = useDefaultRate().data;
  const currentCountryRate = useMemo(() => {
    return countryRates.find(rate => rate.country === rateWizard.rateValues.country);
  }, [countryRates, rateWizard.rateValues.country]);

  const goToStep = useCallback(
    stepNum => {
      if (wizardInstance && rateWizard.currentStep <= RATE_FORM_WIZARD_STEPS.REVIEW) {
        dispatch(rateWizardActions.emitSetWizardStep(stepNum));
        wizardInstance.goToStep(stepNum);
      }
    },
    [dispatch, rateWizard.currentStep, wizardInstance],
  );

  const handleFormValuesChange = useCallback(
    (newValues = {}) => {
      dispatch(rateWizardActions.emitUpdateRateValues(newValues));
      if (formInstance) formInstance.setFieldsValue(newValues);
    },
    [dispatch, formInstance],
  );

  const handleAddBand = useCallback(() => {
    const bands = rateWizard.rateValues.rateBands;
    const newBand = {
      bandStart: bands[bands.length - 1] ? parseFloat(bands[bands.length - 1].bandEnd || 0) + 1 : 0,
    };

    let updatedBands = [];

    if (Array.isArray(bands)) {
      updatedBands = [...bands, newBand];
    } else {
      updatedBands = [newBand];
    }

    dispatch(rateWizardActions.emitUpdateRateValues({ rateBands: updatedBands }));
    formInstance.setFieldsValue({ ['bandStart-' + (updatedBands.length - 1)]: newBand.bandStart });
  }, [dispatch, formInstance, rateWizard.rateValues.rateBands]);

  const _removeAllBands = useCallback(() => {
    if (Array.isArray(rateWizard.rateValues.rateBands)) {
      rateWizard.rateValues.rateBands.forEach((_, i) => {
        formInstance.setFieldsValue({
          ['bandStart-' + i]: i === 0 ? initalBands[0].bandStart : '',
          ['bandEnd-' + i]: i === 0 ? initalBands[0].bandEnd : '',
          ['bandRate-' + i]: '',
        });
      });
    }
  }, [formInstance, rateWizard.rateValues.rateBands]);

  const handleRemoveLastBand = useCallback(() => {
    const updatedBads = [...rateWizard.rateValues.rateBands];

    const lastIndex = updatedBads.length - 1;

    formInstance.setFieldsValue({
      ['bandStart-' + lastIndex]: '',
      ['bandEnd-' + lastIndex]: '',
      ['bandRate-' + lastIndex]: '',
    });

    updatedBads.length -= 1; // Remove last item
    dispatch(rateWizardActions.emitUpdateRateValues({ rateBands: updatedBads }));
  }, [dispatch, formInstance, rateWizard.rateValues.rateBands]);

  const handleBandChange = useCallback(
    (index, field, value) => {
      let updatedBands = [...rateWizard.rateValues.rateBands];
      value = value === '' || value === undefined ? value : parseFloat(value);

      updatedBands[index][field] = value;
      formInstance.setFieldsValue({ [field + '-' + index]: value });

      // auto set next bandStart
      updatedBands = updatedBands.map((band, i) => {
        const updatedBand = band;
        if (i > index && updatedBands[i] && updatedBands[i - 1]) {
          const newValue = parseFloat(updatedBands[i - 1].bandEnd || 0) + 1;
          updatedBand.bandStart = newValue;
          formInstance.setFieldsValue({ ['bandStart-' + i]: newValue });
        }
        return updatedBand;
      });

      // Add a final item with a bandEnd of 99999 if none exists
      const currentLastIndex = updatedBands.length - 1;
      if (parseFloat(updatedBands[currentLastIndex].bandEnd) !== 99999) {
        const finalBand = {
          bandStart: parseFloat(updatedBands[currentLastIndex].bandEnd || 0) + 1,
          bandEnd: 99999,
        };
        updatedBands.push(finalBand);
        const newLastIndex = updatedBands.length - 1;
        formInstance.setFieldsValue({
          ['bandStart-' + newLastIndex]: finalBand.bandStart,
          ['bandEnd-' + newLastIndex]: finalBand.bandEnd,
        });
      }

      dispatch(rateWizardActions.emitUpdateRateValues({ rateBands: updatedBands }));
    },
    [dispatch, formInstance, rateWizard.rateValues.rateBands],
  );

  const handleRateTypeChange = useCallback(
    rateType => {
      /**
       * FLAT GOVERNMENT RATES
       */
      if (isGovernmentFlatRate(rateType, rateWizard.rateValues.country)) {
        _removeAllBands();
        const countryRate = currentCountryRate?.defaultRate;

        handleFormValuesChange({ rateAmount: countryRate });
      } else if (isTieredRate(rateType, rateWizard.rateValues.country)) {
        /**
         * BAND RATES
         */
        _removeAllBands();

        if (rateType === RATE_TYPES.GOVT && Array.isArray(currentCountryRate.defaultBands)) {
          handleFormValuesChange({ rateBands: currentCountryRate.defaultBands });

          currentCountryRate.defaultBands.forEach((band, i) => {
            formInstance.setFieldsValue({
              ['bandStart-' + i]: band.bandStart,
              ['bandEnd-' + i]: band.bandEnd,
              ['bandRate-' + i]: band.bandRate,
            });
          });
        } else {
          handleFormValuesChange({ rateBands: initalBands });
        }
        /**
         * OTHER RATES / CUSTOM FLAT RATES / FAVR
         */
      } else {
        _removeAllBands();
        handleFormValuesChange({
          rateAmount: undefined,
          rateBands: initalBands,
          additionals: null,
        });
      }

      handleFormValuesChange({ rateType });
    },
    [
      formInstance,
      rateWizard.rateValues.country,
      currentCountryRate,
      handleFormValuesChange,
      _removeAllBands,
    ],
  );

  return {
    rateWizard,
    companyGroups,
    currentDefaultRate,
    currentCountryRate,

    // Handlers
    goToStep,
    handleRateTypeChange,
    handleAddBand,
    handleRemoveLastBand,
    handleBandChange,
    handleFormValuesChange,
  };
};

export default useRateForm;
