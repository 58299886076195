import { Col, Empty, Pagination, Row } from 'antd';
import React from 'react';
import { useQueryClient } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { cancelImportRequest, downloadImportRequestFile } from '../../api/import-requests';
import ImportRequestItem from '../ImportRequestItem';
import Spinner from '../Spinner';

const ImportStatusCSVSection = props => {
  const {
    t,
    importType,
    importRequests,
    isLoadingImportRequests,
    onViewImportedTripsClick,
    onPageChange,
    pagination,
  } = props;

  const queryClient = useQueryClient();

  const handleCsvDownload = async (requestID, fileName) => {
    await downloadImportRequestFile(requestID, fileName);
  };

  const handleCancelRequest = async requestID => {
    try {
      await cancelImportRequest(requestID);
      if (importType === 'trips') {
        queryClient.invalidateQueries({ queryKey: ['fetchTripsImportRequests'], exact: false });
      } else {
        queryClient.invalidateQueries({ queryKey: ['fetchLocationImportRequests'], exact: false });
      }
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  if (!Array.isArray(importRequests) || isLoadingImportRequests) {
    return (
      <Row justify="center" align="middle">
        <Col>
          <Spinner />
        </Col>
      </Row>
    );
  }

  if (!importRequests.length) {
    return (
      <div>
        <Empty />
      </div>
    );
  }

  const paginationRow = (
    <Row justify="end">
      <Col>
        <Pagination
          current={pagination?.current}
          total={pagination?.total}
          pageSize={pagination?.pageSize}
          onChange={page => onPageChange(page)}
        />
      </Col>
    </Row>
  );

  return (
    <div>
      {paginationRow}

      {importRequests.map(request => (
        <ImportRequestItem
          t={t}
          key={request._id}
          request={request}
          importType={importType}
          onDownloadCSV={handleCsvDownload}
          onCancel={handleCancelRequest}
          onViewImportedTripsClick={onViewImportedTripsClick}
        />
      ))}

      {paginationRow}
    </div>
  );
};

export default ImportStatusCSVSection;
