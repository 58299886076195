import { Steps as AntdSteps } from 'antd';
import React from 'react';

import classNames from './style.module.scss';

const Steps = props => {
  const { children, className, ...rest } = props;
  return (
    <AntdSteps {...rest} className={[classNames.steps, className].filter(Boolean).join(' ')}>
      {children}
    </AntdSteps>
  );
};

Steps.Step = AntdSteps.Step;

export default Steps;
