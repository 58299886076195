import { handleApiCalls } from './axiosInstance';

const CrmContactsAPI = {
  fetchCrmContacts: async () => {
    const url = `${process.env.REACT_APP_HOST_API}crm-contact`;
    const response = await handleApiCalls('get', url);

    return response.data.data;
  },

  fetchCrmContactDetails: async contactID => {
    const url = `${process.env.REACT_APP_HOST_API}crm-contact/${contactID}`;
    const response = await handleApiCalls('get', url);

    return response.data.data;
  },

  createCrmContacts: async params => {
    const url = `${process.env.REACT_APP_HOST_API}crm-contact`;
    const response = await handleApiCalls('post', url, params);

    return response.data.crmContact;
  },

  updateCrmContacts: async (contactID, params) => {
    const url = `${process.env.REACT_APP_HOST_API}crm-contact/${contactID}`;
    const response = await handleApiCalls('put', url, params);

    return response.data.crmContact;
  },
};

export default CrmContactsAPI;
