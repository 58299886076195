export const AD_HOC_SCHEDULE_TYPE = 'ad_hoc';
export const WEEKLY_SCHEDULE_TYPE = 'weekly';
export const MONTHLY_SCHEDULE_TYPE = 'monthly';
export const CUSTOM_SCHEDULE_TYPE = 'custom';

export const SCHEDULE_TYPES = {
  [AD_HOC_SCHEDULE_TYPE]: 'Ad hoc',
  [WEEKLY_SCHEDULE_TYPE]: 'Weekly',
  [MONTHLY_SCHEDULE_TYPE]: 'Monthly',
  [CUSTOM_SCHEDULE_TYPE]: 'Custom',
};
