import { Col, Row } from 'antd';
import React from 'react';

import { Button } from '../../../components';
import Text from '../../../components/Text';
import { IMAGES } from '../../../enum';

const EmptyVacationsSection = props => {
  const { t, addAnotherVacation, disabled } = props;

  return (
    <div>
      <Row justify="center">
        <Col>
          <Row justify="center">
            <Col>
              <img width="70px" src={IMAGES.VACATION_BEACH_ICON} alt="time off" />
            </Col>
          </Row>

          <br />

          <Text style={{ marginBottom: 30 }}>{t('noScheduledTimeOff')}</Text>

          <Row justify="center">
            <Col>
              <Button
                type="secondary"
                disabled={disabled}
                onClick={addAnotherVacation}
                icon={
                  <img
                    alt="plus"
                    src={IMAGES.GREEN_PLUS_ICON}
                    width="16px"
                    style={{ marginRight: 5 }}
                  />
                }
              >
                {t('addPeriod')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default EmptyVacationsSection;
