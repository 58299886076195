import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { fetchTripsImportRequests } from '../../api/import-requests';
import { TRIP_API } from '../../api/trips';
import { Modal } from '../../components';
import Box from '../../components/Box';
import Button from '../../components/Button';
import ImportStatusCSVSection from '../../components/ImportStatusCSVSection';
import FullScreenTripModal from '../../components/Modal/FullScreenTripModal';
import Spinner from '../../components/Spinner';
import TripsTable from '../../components/Table/TripsTable';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { EXTERNAL_LINKS, IMAGES, INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useFileInput from '../../hooks/useFileInput';
import useTablePagination from '../../hooks/useTablePagination';
import { formatPageTitle, setModalFullscreenWidth } from '../../utils/common';
import { selectStoreCurrentAuthUser } from '../../utils/storeSelectors';
import { normalizeTripsSchema } from '../../utils/trips';
import { canUploadTripViaCSV } from '../trips/trips-permissions';
import { TRIPS_CSV_EMPTY_DATA, TRIPS_CSV_HEADERS } from './tripsCsvTemplate';
import UploadTripsSection from './UploadTripsSection';

export const TRIPS_TAB = 'trips';
export const TRIPS_IMPORT_STATUS = 'import-status';

const importRequestPreviewInitialState = {
  trips: [],
  filename: '',
};

const TripsViaCSV = props => {
  const { t, history } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);
  const { currentCompany } = useSelector(store => store.common);

  const [selectedImportRequest, setSelectedImportRequest] = useState();

  const [importRequestPreview, setImportRequestPreview] = useState(
    importRequestPreviewInitialState,
  );

  const [isTripsDetailModalVisible, setIsTripsDetailModalVisible] = useState(false);
  const [selectedMapConfig, setSelectedMapConfig] = useState({
    routes: [],
    center: [0, 0],
  });

  const importRequestPagination = useTablePagination();
  const tripsImportRequestQuery = useQuery({
    queryKey: [
      'fetchTripsImportRequests',
      currentCompany._id,
      importRequestPagination.paginationConfig.current,
      importRequestPagination.paginationConfig.pageSize,
    ],
    queryFn: () =>
      fetchTripsImportRequests(
        currentCompany._id,
        importRequestPagination.paginationConfig.current,
        importRequestPagination.paginationConfig.pageSize,
      ),
    onError: error => handleApiErrors(error.response),
  });

  const PENDING_REQUESTS_COUNT = Array.isArray(tripsImportRequestQuery.data?.documents)
    ? tripsImportRequestQuery.data.documents.filter(
        req => req.status === STATUS_LIST().Status.AWAITING_CONFIRMATION,
      ).length
    : 0;

  const uploadTripsCsvMutation = useMutation(file => TRIP_API.uploadTripsCsvFile(file), {
    onSuccess: () => {
      tripsImportRequestQuery.refetch();
      Toast({
        type: 'open',
        message: t('csvUploadSuccess'),
      });
    },
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('csvImportError'),
        });
      }),
  });

  const handleHiddenCSVSelect = useFileInput(
    files => {
      if (files[0]) {
        return uploadTripsCsvMutation.mutateAsync(files[0]);
      }
    },
    { accept: 'text/csv', multiple: false },
  );

  useEffect(() => {
    if (!canUploadTripViaCSV(authUser)) {
      history.push(INTERNAL_LINKS.DASHBOARD);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedImportRequest) {
      setImportRequestPreview(importRequestPreviewInitialState);
    }
  }, [selectedImportRequest]);

  const importedTripsPagination = useTablePagination();
  const importedTripsQuery = useQuery({
    enabled: !!selectedImportRequest?._id,
    queryKey: [
      'fetchTripsImportedViaCSV',
      selectedImportRequest?._id,
      importedTripsPagination.paginationConfig.current,
      importedTripsPagination.paginationConfig.pageSize,
    ],
    queryFn: () => TRIP_API.fetchTripsImportedViaCSV(selectedImportRequest._id),
    onSuccess: response => {
      if (response) {
        const { data, totalCount } = response;
        importedTripsPagination.handleTotalUpdate(totalCount);
        setImportRequestPreview({
          trips: data,
          totalCount,
          filename: selectedImportRequest.fileName,
        });
      }
    },
    onError: error => handleApiErrors(error.response),
  });

  return (
    <PageContainer
      loading={importedTripsQuery.isFetching && tripsImportRequestQuery.isFetching}
      title={t('manageTrips')}
      sideActionComponent={
        <Row gutter={16} wrap={false}>
          <Helmet>
            <title>{formatPageTitle('Manage Trips CSV')}</title>
          </Helmet>

          {Array.isArray(tripsImportRequestQuery.data?.documents) &&
            !!tripsImportRequestQuery.data.documents.length && (
              <Col>
                <Button
                  loading={tripsImportRequestQuery.isFetching}
                  disabled={tripsImportRequestQuery.isFetching || uploadTripsCsvMutation.isLoading}
                  onClick={tripsImportRequestQuery.refetch}
                >
                  <img width="24px" src={IMAGES.REFRESH_ARROW_ICON} alt="refresh" />
                </Button>
              </Col>
            )}

          <Col>
            <Button
              loading={uploadTripsCsvMutation.isLoading}
              disabled={
                PENDING_REQUESTS_COUNT > 0 ||
                uploadTripsCsvMutation.isLoading ||
                tripsImportRequestQuery.isFetching
              }
              onClick={() => {
                const uploaderButton = document.querySelector('.ant-upload.ant-upload-drag button');

                if (uploaderButton) {
                  uploaderButton.click();
                } else {
                  handleHiddenCSVSelect();
                }
              }}
            >
              {t('uploadCSV')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Text>
        <Trans
          t={t}
          i18nKey="tripsPageDescription"
          components={[
            <CSVLink
              target="_self"
              filename={t('importTripsTemplateCsv')}
              headers={TRIPS_CSV_HEADERS}
              data={TRIPS_CSV_EMPTY_DATA}
            >
              DummyText
            </CSVLink>,
            <LinkText
              renderAs="a"
              rel="noopener noreferrer"
              target="_blank"
              href={EXTERNAL_LINKS.FRESHDESK_FAQS_TRIP_CSV_IMPORT_VALIDAITON_RULES}
            >
              DummyText
            </LinkText>,
          ]}
        />
      </Text>
      <br />

      <Box>
        {!Array.isArray(tripsImportRequestQuery.data?.documents) && (
          <Row justify="center" align="middle">
            <Col>
              <Spinner />
            </Col>
          </Row>
        )}

        {Array.isArray(tripsImportRequestQuery.data?.documents) &&
          !tripsImportRequestQuery.data.documents.length && (
            <UploadTripsSection
              t={t}
              disabled={PENDING_REQUESTS_COUNT > 0}
              loading={uploadTripsCsvMutation.isLoading}
              onUploadSuccess={() => {
                tripsImportRequestQuery.refetch();
              }}
              csvLink={
                <CSVLink
                  target="_self"
                  filename={t('importTripsTemplateCsv')}
                  headers={TRIPS_CSV_HEADERS}
                  data={TRIPS_CSV_EMPTY_DATA}
                >
                  {t('importTripsTemplateCsv')}
                </CSVLink>
              }
            />
          )}

        {Array.isArray(tripsImportRequestQuery.data?.documents) &&
          !!tripsImportRequestQuery.data.documents.length && (
            <ImportStatusCSVSection
              t={t}
              importType="trips"
              importRequests={tripsImportRequestQuery.data.documents}
              isLoadingImportRequests={tripsImportRequestQuery.isFetching}
              onViewImportedTripsClick={request => {
                if (request.status === STATUS_LIST().Status.COMPLETED) {
                  setSelectedImportRequest(request);
                }
              }}
              onPageChange={page => importRequestPagination.handlePageChange(page)}
              pagination={{
                pageSize: importRequestPagination.paginationConfig.pageSize,
                total: importRequestPagination.paginationConfig.total,
                current: importRequestPagination.paginationConfig.current,
              }}
            />
          )}
      </Box>

      <Modal
        footer={null}
        title={importRequestPreview?.filename}
        width={setModalFullscreenWidth(1600)}
        visible={!!selectedImportRequest || importedTripsQuery.isFetching}
        onCancel={() => setSelectedImportRequest()}
      >
        <TripsTable
          t={t}
          showSearchInput={false}
          loading={importedTripsQuery.isFetching}
          dataSource={
            Array.isArray(importRequestPreview?.trips)
              ? importRequestPreview.trips.map(trip => normalizeTripsSchema(trip))
              : []
          }
          handleViewFullScreen={(trp, activeRoutes, mapCenter) => {
            setIsTripsDetailModalVisible(true);
            setSelectedMapConfig({
              tripId: trp._id,
              isManual: !trp.gpsVerified || trp.gpsVerified === 'No',
              toLoc: trp.to_loc,
              fromLoc: trp.from_loc,
              routes: activeRoutes,
              center: mapCenter,
            });
          }}
          onChange={({ current }) => importedTripsPagination.handlePageChange(current)}
          pagination={{
            pageSize: importedTripsPagination.paginationConfig.pageSize,
            total: importedTripsPagination.paginationConfig.total,
            current: importedTripsPagination.paginationConfig.current,
            onShowSizeChange: importedTripsPagination.handlePageSizeChange,
          }}
        />
      </Modal>

      <FullScreenTripModal
        visible={isTripsDetailModalVisible}
        mapConfig={selectedMapConfig}
        onCancel={() => {
          setIsTripsDetailModalVisible(false);
          setSelectedMapConfig({ center: [0, 0], routes: [] });
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(TripsViaCSV));
