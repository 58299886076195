import React from 'react';

import ConfirmModal from '../ConfirmModal';

const ReceiptRejectConfirmation = props => {
  const { t, isRejecting, visible, onConfirm, onCancel, hasTripsWithMoreInfo } = props;

  return (
    <ConfirmModal
      loading={isRejecting}
      visible={visible}
      title={t('sureYouWantToRejectReceipt')}
      message={hasTripsWithMoreInfo ? t('rejectAllTripsAndSomeWithMoreInfoInReceipt') : undefined}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={t('yes')}
      cancelText={t('no')}
    />
  );
};

export default ReceiptRejectConfirmation;
