import { Col, Row, Spin } from 'antd';
import React, { useMemo, useState } from 'react';

import { RATES_API } from '../../api/rates';
import useInfiniteQuery from '../../hooks/queries/useInfiniteQuery';
import useDebouncedState from '../../hooks/useDebouncedState';
import { checkIfElementIsScrolledToBottom } from '../../utils/common';
import { selectStoreCurrentCompany, useStoreSelector } from '../../utils/storeSelectors';
import Select from '../Select';

const CompanyRatesLookupSelect = props => {
  const { t, showAllOption, onChange, notFoundContent, ...rest } = props;

  const currentCompany = useStoreSelector(selectStoreCurrentCompany);
  const [searchTerm, setSearchTerm] = useDebouncedState(null, 500);
  const [initialCount, setInitialCount] = useState();

  const { query: ratesQuery, flatPages } = useInfiniteQuery(
    {
      staleTime: Infinity,
      queryKey: ['fetchCompanyRates', currentCompany._id, null, searchTerm, ''],
      queryFn: ({ pageParam = 1 }) =>
        RATES_API.fetchCompanyRates(
          currentCompany._id,
          { searchTerm, getUsersCount: false },
          undefined,
          pageParam,
          25,
        ),
      onSuccess: ({ pages }) => {
        if (
          Array.isArray(pages) &&
          typeof pages[0]?.totalCount !== 'undefined' &&
          typeof initialCount === 'undefined'
        ) {
          setInitialCount(pages[0]?.totalCount);
        }
      },
    },
    'totalCount',
    'documents',
  );

  const options = useMemo(() => {
    let optionsArray = flatPages.map(rate => ({
      value: rate._id,
      label: rate.name,
    }));

    if (showAllOption) {
      optionsArray = [{ value: null, label: t('All') }, ...optionsArray];
    }

    return optionsArray;
  }, [t, showAllOption, flatPages]);

  const handleChange = val => {
    if (typeof onChange === 'function') {
      onChange(
        val,
        flatPages.find(rate => rate._id === val),
      );
    }
    setSearchTerm();
  };

  const handleOptionsScroll = e => {
    const bottom = checkIfElementIsScrolledToBottom(e.target);

    if (bottom && !ratesQuery.isFetchingNextPage && ratesQuery.hasNextPage) {
      ratesQuery.fetchNextPage();
    }
  };

  return (
    <Select
      {...rest}
      onChange={handleChange}
      fullWidth
      showSearch
      allowClear={false}
      loading={ratesQuery.isFetchingNextPage}
      options={options}
      onSearch={setSearchTerm}
      placeholder={t('select')}
      onPopupScroll={handleOptionsScroll}
      notFoundContent={
        ratesQuery.isFetching ? (
          <Row justify="center" align="middle">
            <Col>
              <Spin size="default" />
            </Col>
          </Row>
        ) : initialCount === 0 ? (
          /* message to display only if the initial search has not results,
           hence any other search term queries will be return the same */
          notFoundContent
        ) : undefined
      }
    />
  );
};

export default CompanyRatesLookupSelect;
