import { replaceValueInArrayByID } from '../../utils/common';
import { createReducer } from '../../utils/storeCreators';
import {
  ADD_NEW_CONTACT,
  START_FETCHING_CONTACTS,
  UPDATE_CONTACT_BY_ID,
  UPDATE_CONTACTS,
} from '../actions/realtor';
import initialState from '../initialState';

const realtorReducer = createReducer({
  initialState: initialState.realtor,
  reducer: (state, action) => {
    let realtor = state;

    switch (action.type) {
      case START_FETCHING_CONTACTS:
        realtor.isFetchingContacts = true;
        return { ...state, ...realtor };
      case UPDATE_CONTACTS:
        realtor.contacts = action.data.contacts;
        realtor.isFetchingContacts = false;
        return { ...state, ...realtor };
      case UPDATE_CONTACT_BY_ID:
        realtor.contacts = replaceValueInArrayByID(realtor.contacts || [], action.data.contact);
        return { ...state, ...realtor };
      case ADD_NEW_CONTACT:
        realtor.contacts = [...(realtor.contacts || []), action.data.contact];
        return { ...state, ...realtor };
      default:
        return state;
    }
  },
});

export default realtorReducer;
