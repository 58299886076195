import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { momentFormat } from '../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { formatCurrencyToCompanyLocale, formatNumberToLocale } from '../../../utils/numbers';
import { convertMetersToMiles, formatAddressIntoString } from '../../../utils/trips';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import LinkText from '../../Text/LinkText';

const AnomaliesCommuteTable = props => {
  const { t, dataSource, hideReceipts, ...rest } = props;

  const COLUMNS = useMemo(
    () => [
      {
        key: 'user',
        title: t('user'),
        dataIndex: 'user',
        render: user => <ProfileNamePicture user={user} />,
      },
      {
        key: 'paymentPeriod',
        title: t('paymentPeriod'),
        render: ({ paymentPeriodStart, paymentPeriodEnd }, data) => {
          return (
            <Link
              disabled={!data.tripReceiptId}
              to={
                hideReceipts
                  ? `${INTERNAL_LINKS.REIMBURSEMENT}/${data.tripReceiptId}`
                  : `${INTERNAL_LINKS.RECEIPTS}/${data.tripReceiptId}`
              }
            >
              <LinkText variant="b">
                {[
                  getMomentDateWithoutTimezone(paymentPeriodStart).format('MM-DD-YYYY'),
                  getMomentDateWithoutTimezone(paymentPeriodEnd).format('MM-DD-YYYY'),
                ].join(' to ')}
              </LinkText>
            </Link>
          );
        },
      },
      {
        align: 'right',
        key: 'totalMileage',
        title: t('totalMileage'),
        dataIndex: 'totalMileage',
        sorter: true,
        render: totalMileage => formatNumberToLocale(convertMetersToMiles(totalMileage)),
      },
      {
        align: 'right',
        key: 'totalReimbursement',
        title: t('totalReimbursement'),
        dataIndex: 'totalReimbursement',
        sorter: true,
        render: totalReimbursement => formatCurrencyToCompanyLocale(totalReimbursement),
      },
      {
        align: 'right',
        key: 'tripsCount',
        dataIndex: 'tripsCount',
        title: t('anomalies:numberOfTrips'),
        sorter: true,
      },
      {
        align: 'right',
        defaultSortOrder: 'descend',
        key: 'commutePercentage',
        title: t('anomalies:commute%'),
        dataIndex: 'commutePercentage',
        sorter: true,
        render: commutePercentage =>
          `${typeof commutePercentage === 'number' ? commutePercentage : 0}%`,
      },
      {
        key: 'homeAddress',
        title: t('homeAddress'),
        dataIndex: ['user', 'personalInfor', 'homeAddress'],
        render: homeAddress => formatAddressIntoString(homeAddress),
      },
      {
        key: 'homeAddressUpdatedAt',
        title: t('anomalies:homeAddressLastUpdated'),
        dataIndex: 'homeAddressUpdatedAt',
        sorter: true,
        render: homeAddressUpdatedAt => momentFormat(homeAddressUpdatedAt, 'DD MMM, YYYY'),
      },
    ],
    [t, hideReceipts],
  );

  return (
    <CustomTable
      {...rest}
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource}
      scroll={{
        x: 1200,
      }}
    />
  );
};

export default AnomaliesCommuteTable;
