import { hasSystemAdminRole } from '../../../utils/roles';
import {
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';

export const canUpdateImportLocations = () => {
  const authUser = selectStoreCurrentAuthUser();
  const company = selectStoreCurrentCompany();

  if (!company) return false;
  if (hasSystemAdminRole(authUser)) return true;

  return (
    company.companySettingId &&
    company.companySettingId.settings &&
    company.companySettingId.settings.importLocationsMethod
  );
};
