import { Col, Row, Space } from 'antd';
import React, { useEffect, useMemo } from 'react';

import Checkbox from '../../../components/Checkbox';
import RedAsterisk from '../../../components/RedAsterisk';
import Select from '../../../components/Select';
import Text from '../../../components/Text';
import { getNumberRage } from '../../../utils/common';

const SelectWeeklyParameters = props => {
  const {
    t,
    dayOfWeekForPayment,
    paymentDateUseBusinessDays,
    tripsSubmissionDeadline,
    tripsSubmissionDeadlineUseBusinessDays,
    managerApprovalDeadline,
    managerApprovalDeadlineUseBusinessDays,
    onSettingChange,
    disabled,
  } = props;

  const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const WEEKEND = ['Saturday', 'Sunday'];

  const DAYS = useMemo(() => {
    if (paymentDateUseBusinessDays) return WEEKDAYS;
    return [...WEEKDAYS, ...WEEKEND];
    // eslint-disable-next-line
  }, [paymentDateUseBusinessDays]);

  useEffect(() => {
    if (paymentDateUseBusinessDays && dayOfWeekForPayment >= WEEKDAYS.length) {
      onSettingChange({ dayOfWeekForPayment: WEEKDAYS.length }, 'dayOfWeekForPayment');
    }
    // eslint-disable-next-line
  }, [paymentDateUseBusinessDays]);

  const OPTIONS = [
    {
      key: 'dayOfWeekForPayment',
      businessDayKey: 'paymentDateUseBusinessDays',
      label: 'Select Day of Week for Payment',
      suffix: '',
      value: dayOfWeekForPayment,
      onlyBusinessDays: paymentDateUseBusinessDays,
      options: DAYS.map((day, i) => ({
        label: day,
        value: i + 1,
      })),
      disabled,
      checkboxDisabled: disabled,
    },
    {
      key: 'tripsSubmissionDeadline',
      label: 'Select Trips Submission Deadline',
      suffix: 'days prior to Payment date',
      value: tripsSubmissionDeadline,
      onlyBusinessDays: tripsSubmissionDeadlineUseBusinessDays,
      disabled,
      checkboxDisabled: disabled,
    },
    {
      key: 'managerApprovalDeadline',
      label: 'Select Manager Approval Deadline',
      suffix: 'days prior to Payment date',
      value: managerApprovalDeadline,
      onlyBusinessDays: managerApprovalDeadlineUseBusinessDays,
      disabled,
      checkboxDisabled: disabled,
    },
  ];

  return (
    <div>
      {OPTIONS.map(
        (option, index) =>
          !option.hidden && (
            <Row key={index} style={{ marginBottom: 20 }}>
              <Col flex="100%">
                <Space direction="horizontal" size={3} wrap={false} align="center">
                  {!option.optional && <RedAsterisk />}
                  <Text style={{ marginTop: 8, marginBottom: 8 }} variant="p" size="sm">
                    {option.label}
                  </Text>
                </Space>
              </Col>

              <Col flex={1}>
                <Row gutter={5}>
                  <Col flex="120px">
                    <Select
                      disabled={option.disabled}
                      placeholder={t('day')}
                      value={option.value}
                      style={{ width: '100%', marginBottom: 5 }}
                      onChange={value => onSettingChange({ [option.key]: value }, option.key)}
                      options={
                        option.options ||
                        getNumberRage(0, 6).map(n => ({
                          label: n,
                          value: n,
                        }))
                      }
                    />
                  </Col>
                  {option.suffix && (
                    <Col>
                      <Text variant="p" size="sm" style={{ marginTop: 8 }}>
                        {option.suffix}
                      </Text>
                    </Col>
                  )}
                </Row>
                <Checkbox
                  disabled={option.checkboxDisabled}
                  checked={option.onlyBusinessDays}
                  onChange={e =>
                    onSettingChange({
                      [option.businessDayKey || `${option.key}UseBusinessDays`]: e.target.checked,
                    })
                  }
                >
                  <p>{t('onlyUseBusinessDays')}</p>
                  {index === 0 && <p>({t('systemWillDefaultToPrevNextBusinessDay')})</p>}
                </Checkbox>
              </Col>
            </Row>
          ),
      )}
    </div>
  );
};

export default SelectWeeklyParameters;
