import { CheckCircleOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, List, Row } from 'antd';
import React from 'react';

import { Button } from '../../../../components';
import Tag from '../../../../components/Tag';
import Text from '../../../../components/Text';
import FadedText from '../../../../components/Text/FadedText';
import { IMAGES } from '../../../../enum';
import { ReactComponent as EditSVG } from '../../../../images/pencil-write.svg';
import { ReactComponent as WhitePlusIconSVG } from '../../../../images/plus.svg';
import classNames from './styles.module.scss';

const renderCreditCardIcon = brand => {
  const cardWidth = '50px';

  switch (brand) {
    case 'MasterCard':
      return (
        <img width={cardWidth} height={cardWidth} alt="mastercard" src={IMAGES.MASTERCARD_ICON} />
      );
    case 'Visa':
      return <img width={cardWidth} height={cardWidth} alt="visa" src={IMAGES.VISA_ICON} />;
    case 'Discover':
      return <img width={cardWidth} height={cardWidth} alt="visa" src={IMAGES.DISCOVER_ICON} />;
    case 'American Express':
      return (
        <div id="amex-logo" style={{ width: cardWidth, height: cardWidth }}>
          <img
            src="https://www.americanexpress.com/content/dam/amex/us/merchant/supplies-uplift/product/images/Amex_logo_color.png"
            width="100%"
            height="100%"
            alt="American Express Logo"
            border="0"
          />
        </div>
      );
    default:
      return (
        <img width={cardWidth} height={cardWidth} alt="cc" src={IMAGES.CREDIT_CARD_DARK_ICON} />
      );
  }
};

const renderCardListItemContent = ({
  t,
  card,
  isDefault,
  onEditClick,
  onDefaultClick,
  onDeleteClick,
}) => {
  const expMonth = String(card.expMonth).padStart(2, '0');

  let menuItems = [];

  if (!isDefault) {
    menuItems = [
      {
        icon: <CheckCircleOutlined />,
        label: <Text size="sm">{t('setAsDefault')}</Text>,
        onClick: () => onDefaultClick(card.id),
      },
      {
        icon: <DeleteOutlined />,
        label: <Text size="sm">{t('Delete')}</Text>,
        onClick: () => onDeleteClick(card.id),
      },
    ];
  }

  return (
    <Row gutter={[16, 5]} wrap={false}>
      <Col flex={1}>
        <List.Item.Meta
          style={{ marginBottom: 0 }}
          avatar={renderCreditCardIcon(card.brand)}
          title={
            <Row gutter={[10, 10]} wrap={false}>
              <Col flex={1}>
                <Text variant="b">
                  {card.name || t('creditCard')} {`(${card.last4})`}
                </Text>
              </Col>

              {isDefault && (
                <Col>
                  <Tag variant="primary">{t('default')}</Tag>
                </Col>
              )}
            </Row>
          }
          description={
            <FadedText size="sm">
              {t('expires')}: {expMonth}/{card.expYear}
            </FadedText>
          }
        />
      </Col>

      <Col>
        <Dropdown.Button
          buttonsRender={() => {
            return [
              <Button
                size="sm"
                type="secondary"
                icon={<EditSVG width="14px" alt="arrow" style={{ marginRight: 5 }} />}
                onClick={() => onEditClick(card)}
              >
                {t('Edit')}
              </Button>,
              !!menuItems.length ? (
                <Button size="sm" type="secondary">
                  <DownOutlined />
                </Button>
              ) : (
                <span></span>
              ),
            ];
          }}
          menu={{ items: menuItems }}
        />
      </Col>
    </Row>
  );
};

const CreditCardSelectionSection = props => {
  const {
    t,
    isLoading,
    cards,
    defaultCard,
    hasMoreCards,
    onFetchMoreCards,
    onAddClick,
    onEditClick,
    onDefaultClick,
    onDeleteClick,
  } = props;

  return (
    <section>
      <Row gutter={[16, 32]}>
        <Col xs={24}>
          <Row gutter={16} wrap={false}>
            <Col flex={1}>
              <Text variant="h5">{t('creditCard_plural')}</Text>
            </Col>
            <Col>
              <Button
                size="sm"
                onClick={onAddClick}
                icon={<WhitePlusIconSVG width="18px" style={{ marginRight: 5 }} />}
              >
                {t('addCard')}
              </Button>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <List
            className={classNames.creditCardList}
            loading={isLoading}
            dataSource={cards}
            itemLayout="vertical"
            loadMore={
              hasMoreCards ? (
                <Row justify="center" style={{ marginTop: 16 }}>
                  <Button size="sm" type="secondary" loading={isLoading} onClick={onFetchMoreCards}>
                    {t('loadMore')}
                  </Button>
                </Row>
              ) : null
            }
            renderItem={card => {
              return (
                <List.Item key={card.id} style={{ maxWidth: 500 }}>
                  {renderCardListItemContent({
                    t,
                    card,
                    onEditClick,
                    onDefaultClick,
                    onDeleteClick,
                    isDefault: defaultCard?.id === card?.id,
                  })}
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>
    </section>
  );
};

export default CreditCardSelectionSection;
