import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchActiveCountryRate, fetchTimezones } from '../../api/company';
import Toast from '../../components/Toast';
import i18n from '../../i18n';
import * as actions from '../actions/common';

function* loadTimezones() {
  try {
    const timezones = yield select(state => state.common.timezones);

    if (!timezones.length) {
      const data = yield call(fetchTimezones);

      const formattedData = data.map(tz => ({
        label: `${tz.name.replace('_', ' ')} (${tz.currentTimeFormat.substring(0, 6)})`,
        value: tz.name,
      }));

      yield put(actions.emitUpdateTimezones(formattedData));
    }
  } catch (error) {
    Toast({
      type: 'error',
      message: i18n.t('fetchTimezonesError'),
    });
  }
}

function* loadCountryRate(action) {
  const { countryCode } = action.data;

  try {
    const countryRates = yield select(state => state.common.countryRates);
    const rate = countryRates.find(rate => rate.country === countryCode);

    if (!rate) {
      const data = yield call(fetchActiveCountryRate, countryCode);
      yield put(actions.emitUpdateCountryRate(data));
    }
  } catch (error) {
    Toast({
      type: 'error',
      message: i18n.t('fetchCountryRateError'),
    });
  }
}

/**
 * watcher saga
 */
export const commonSaga = [
  takeLatest(actions.FETCH_TIMEZONES, loadTimezones),
  takeLatest(actions.FETCH_COUNTRY_RATE, loadCountryRate),
];
