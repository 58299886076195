import { Input } from 'antd';
import MaskedInput from 'antd-mask-input';
import PropTypes from 'prop-types';
import React from 'react';

import NumericInput from './NumericInput';
import classNames from './style.module.scss';

/**
 * Custom Input component based on the Ant Design Input
 * but following Kliks style guide and branding
 *
 * @param {object} props - Component props
 */
const TextInput = React.forwardRef((props, ref) => {
  const { placeholder, size, password, textArea, search, masked, mask, className, ...rest } = props;

  const inputClasses = [
    className,
    classNames.input,
    textArea ? classNames.textArea : '',
    classNames[size],
  ]
    .filter(Boolean)
    .join(' ');

  const inputProps = {
    ...rest,
    ref,
    size: size,
    placeholder: placeholder,
    className: inputClasses,
  };

  if (password) {
    return <Input.Password {...inputProps} />;
  }

  if (textArea) {
    return <Input.TextArea {...inputProps} />;
  }

  if (search) {
    return <Input.Search {...inputProps} />;
  }

  if (masked) {
    return (
      <MaskedInput
        {...inputProps}
        mask={mask}
        maskOptions={{
          overwrite: true,
          lazy: true,
        }}
      />
    );
  }

  if (inputProps.type === 'number') {
    return <NumericInput {...inputProps} />;
  }

  return <Input {...inputProps} />;
});

TextInput.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  password: PropTypes.bool,
  textArea: PropTypes.bool,
  masked: PropTypes.bool,
  search: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

TextInput.defaultProps = {
  size: 'small',
  password: false,
  textArea: false,
  masked: false,
  search: false,
  placeholder: undefined,
  className: '',
};

export default TextInput;
