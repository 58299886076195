import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import ReactMapboxGl, { Feature, Layer, Marker, ZoomControl } from 'react-mapbox-gl';

import { IMAGES } from '../../enum';
import { MAPBOX_LINE_LAYOUT, MAPBOX_LINE_PAINT } from '../../enum/Mapbox';
import useDebouncedState from '../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useLayout from '../../layout/useLayout';
import { convertMillisecondsIntoReadableTime } from '../../utils/datetime';
import {
  calcItineraryTripsTotalDistance,
  calcItineraryTripsTotalDrivingTime,
} from '../../utils/itinerary';
import { getMapBoundaries } from '../../utils/map';
import { formatDistanceToLocale } from '../../utils/numbers';
import ErrorBoundary from '../ErrorBoundary';
import MapLoader from '../MapLoader';
import ItineraryAddressMapPopup from '../MapPopups/ItineraryAddressMapPopup';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import classNames from './styles.module.scss';

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
});

const startMarkerStyle = {
  minWidth: 30,
  textAlign: 'center',
  background: 'red',
  padding: 5,
  color: 'white',
  borderRadius: '50%',
  cursor: 'pointer',
  fontWeight: 'bold',
};

const ItineraryMap = props => {
  const { t, markers, routes, isOptimalRoute } = props;

  const { layoutStore } = useLayout();
  const [visibleMarkerPopup, setVisibleMarkerPopup] = React.useState([]);

  const [mapInstance, setMapInstance] = useState();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [debouncedMapBounds, setDebouncedMapBounds] = useDebouncedState([], 500);

  useDidUpdateEffect(() => {
    if (mapInstance) {
      setTimeout(() => {
        mapInstance.resize();
      }, [200]);
    }
  }, [mapInstance, layoutStore.sidebarCollapsed]);

  const openMarker = useCallback(
    marker => {
      const key = JSON.stringify(marker.coordinates);
      if (!visibleMarkerPopup.includes(key)) {
        setVisibleMarkerPopup([key]);
      }
    },
    [visibleMarkerPopup],
  );

  const closePopups = () => {
    setVisibleMarkerPopup([]);
  };

  const MARKERS = useMemo(() => {
    return markers
      .filter(marker => !!marker.joinedLabel)
      .map((marker, i) => (
        <Marker key={i} coordinates={marker.coordinates} onClick={() => openMarker(marker)}>
          <div style={startMarkerStyle}>{marker.joinedLabel}</div>
        </Marker>
      ));
  }, [markers, openMarker]);

  const MARKER_POPUPS = useMemo(() => {
    return visibleMarkerPopup.map(key => {
      const trip = markers.find(u => JSON.stringify(u.coordinates) === key);
      const isDeparture = trip.label === 'A';

      return (
        <ItineraryAddressMapPopup
          t={t}
          key={JSON.stringify(trip.coordinates)}
          coordinates={trip.coordinates}
          isDeparture={isDeparture}
          time={isDeparture ? trip.tripStartTime : trip.tripEndTime}
          address={isDeparture ? trip.tripStartLocationAddress : trip.tripEndLocationAddress}
        />
      );
    });
    // eslint-disable-next-line
  }, [visibleMarkerPopup, markers]);

  useEffect(() => {
    const bounds = [];

    routes.forEach(route => {
      bounds.push({ lng: route[0], lat: route[1] });
    });

    setDebouncedMapBounds(getMapBoundaries(bounds));
  }, [routes, setDebouncedMapBounds]);

  const MAP_INFO = useMemo(() => {
    if (!markers.length) return null;

    // ignore first item since its a duplicate of start marker
    const markersWithoutFirst = markers.slice(1);

    const info = {
      distance: {
        img: IMAGES.MAP_PIN_ICON,
        value: formatDistanceToLocale(calcItineraryTripsTotalDistance(markersWithoutFirst), 2),
      },
      drivingTime: {
        img: IMAGES.TIME_CLOCK_CIRCLE_GRAY,
        value: convertMillisecondsIntoReadableTime(
          calcItineraryTripsTotalDrivingTime(markersWithoutFirst),
        ),
      },
    };

    return (
      <div className={classNames.mapInfoBox}>
        {Object.keys(info).map(key => {
          const item = info[key];

          return (
            <Row key={key} gutter={[8, 8]} wrap={false}>
              <Col>
                <img width="20px" src={item.img} alt={key} />
              </Col>
              <Col flex={1}>
                <Text size="sm" variant="b">
                  {item.value}
                </Text>
              </Col>
            </Row>
          );
        })}

        {isOptimalRoute && (
          <Row>
            <FadedText variant="b" size="sm">
              {t('optimizedRoute')}
            </FadedText>
          </Row>
        )}
      </div>
    );
    // eslint-disable-next-line
  }, [markers, isOptimalRoute]);

  return (
    <ErrorBoundary t={t} fallback={<MapLoader text={t('errorLoadingMap')} />}>
      <div className={classNames.mapWrapper}>
        <Map
          fitBounds={debouncedMapBounds.length > 1 ? debouncedMapBounds : undefined}
          fitBoundsOptions={{ padding: 100 }}
          // eslint-disable-next-line
          style="mapbox://styles/mapbox/streets-v12"
          containerStyle={{ height: '100%', width: '100%' }}
          onRender={setMapInstance}
          onStyleLoad={setIsMapLoaded}
          onClick={closePopups}
        >
          <ZoomControl position="top-left" />

          <Layer
            id="route"
            source="route"
            type="line"
            layout={MAPBOX_LINE_LAYOUT}
            paint={MAPBOX_LINE_PAINT}
          >
            {isMapLoaded && <Feature coordinates={routes} />}
          </Layer>

          {MARKERS}
          {MARKER_POPUPS}
        </Map>

        {MAP_INFO}
      </div>
    </ErrorBoundary>
  );
};

export default withNamespaces()(ItineraryMap);
