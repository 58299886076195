import { call, put, takeLatest } from 'redux-saga/effects';

import { handleApiErrors } from '../../api/axiosInstance';
import { fetchCompanyInvoices } from '../../api/company';
import * as actions from '../actions/invoices';

function* loadCompanyInvoicesByYear(action) {
  yield put(actions.emitStartFetchingInvoices());

  try {
    const invoices = yield call(fetchCompanyInvoices, action.data.companyID, action.data.year);
    yield put(actions.emitUpdateInvoices(action.data.year, invoices));
  } catch (err) {
    handleApiErrors(err.response);
    yield put(actions.emitUpdateInvoices(action.data.year, []));
  }
}

/**
 * watcher saga
 */
export const invoicesSaga = [takeLatest(actions.FETCH_INVOICES, loadCompanyInvoicesByYear)];
