import { Col, Row } from 'antd';
import { cloneDeep, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { handleApiErrors } from '../../../../api/axiosInstance';
import { USER_API } from '../../../../api/user';
import { Button } from '../../../../components';
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';
import Toast from '../../../../components/Toast';
import PageContainer from '../../../../containers/PageContainer';
import { EXTERNAL_LINKS, IMAGES, INTERNAL_LINKS } from '../../../../enum';
import { emitSkipHomeAddressVerification, updateProfile } from '../../../../stores/actions/profile';
import { formatPageTitle } from '../../../../utils/common';
import { hasCompanyManagerOrAdminRole } from '../../../../utils/roles';
import {
  selectStoreCompanySettings,
  selectStoreCurrentAuthUser,
} from '../../../../utils/storeSelectors';
import {
  hasSkippedHomeAddressVerification,
  hasVerifiedHomeAddress,
  normalizeUserSchema,
} from '../../../../utils/users';
import ConfirmHomeAddressBox from './ConfirmHomeAddressBox';
import HomeAddressSetSuccessBox from './HomeAddressSetSuccessBox';
import SetHomeAddressBox from './SetHomeAddressBox';
import className from './styles.module.scss';

const UserOnboarding = props => {
  const { t, history, location } = props;
  const dispatch = useDispatch();
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const currentCompanySettings = useSelector(selectStoreCompanySettings);
  const { homeAddress } = authUser?.profile?.personalInfor || {};

  const [isConfirming, setIsConfirming] = useState(false);

  const handleAddressSkip = () => {
    dispatch(emitSkipHomeAddressVerification());
  };

  const onUserUpdate = newUserData => {
    const updatedData = { ...authUser.profile, ...normalizeUserSchema(newUserData) };

    dispatch(updateProfile(updatedData));
  };

  const handleAddressConfirm = async () => {
    setIsConfirming(true);
    try {
      await USER_API.confirmUserHomeAddress(authUser.profile._id, 'yes');
      const updatedUser = cloneDeep(authUser.profile);
      set(updatedUser, 'personalInfor.homeAddress.homeAddrUserConf', 'yes');
      onUserUpdate(updatedUser);

      Toast({
        type: 'open',
        message: t('confirmAddressSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('confirmAddressError'),
        });
      });
    }
    setIsConfirming(false);
  };

  useEffect(() => {
    if (
      hasSkippedHomeAddressVerification(authUser.profile) &&
      ![INTERNAL_LINKS.DASHBOARD].includes(location.pathname)
    ) {
      history.push(INTERNAL_LINKS.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [authUser]);

  const SET_HOME_ADDRESS_BOX =
    !homeAddress || !homeAddress?.country ? (
      <Col xs={24} md={12}>
        <SetHomeAddressBox
          t={t}
          onSkipClick={handleAddressSkip}
          canSkip={
            hasCompanyManagerOrAdminRole(authUser) && !currentCompanySettings?.realtorFeatures
          }
        />
      </Col>
    ) : null;

  const HOME_ADDRESS_SET_SUCCESS_BOX =
    homeAddress && hasVerifiedHomeAddress(authUser.profile) ? (
      <Col xs={24} md={12}>
        <HomeAddressSetSuccessBox t={t} />
      </Col>
    ) : null;

  const CONFIRM_HOME_ADDRESS_BOX =
    homeAddress && !hasVerifiedHomeAddress(authUser.profile) && !!homeAddress?.country ? (
      <Col xs={24} md={12}>
        <ConfirmHomeAddressBox
          t={t}
          homeAddress={homeAddress}
          isConfirming={isConfirming}
          onConfirm={handleAddressConfirm}
        />
      </Col>
    ) : null;

  return (
    <PageContainer title="" className={className.onboardingPage}>
      <Helmet>
        <title>{formatPageTitle('User Onboarding')}</title>
      </Helmet>

      <Text variant="h2" textAlign="center" className="slide-down-title">
        {t('letsGetSetUpSoYouCanBeonCruiseControl')}
      </Text>

      <br />
      <br />

      <Row justify="center">
        <Col flex="850px">
          <Row justify="center" align="center" gutter={[20, 20]}>
            {SET_HOME_ADDRESS_BOX}
            {CONFIRM_HOME_ADDRESS_BOX}
            {HOME_ADDRESS_SET_SUCCESS_BOX}

            <Col xs={24} md={12}>
              <Box className={className.card}>
                <Row>
                  <img width="60px" src={IMAGES.PHONE_DOWNLOAD_ICON} alt="Phone download" />
                </Row>

                <br />

                <Text variant="h4">{t('downloadTheApp')}</Text>

                <Text>{t('fasterInstallFasterStart')}</Text>

                <br />

                <div className={className.storeButtonsRow}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={EXTERNAL_LINKS.KLIKS_PLAY_STORE}
                  >
                    <img
                      height="52px"
                      alt="Get it on Google Play"
                      src={IMAGES.PLAY_STORE_BADGE_IMAGE}
                    />
                  </a>

                  <a
                    className="appleLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={EXTERNAL_LINKS.KLIKS_APP_STORE}
                  >
                    <img
                      height="52px"
                      alt="Download on the App Store"
                      src={IMAGES.APP_STORE_BADGE_IMAGE}
                    />
                  </a>
                </div>
              </Box>
            </Col>
          </Row>

          <br />
          <br />

          {homeAddress && hasVerifiedHomeAddress(authUser.profile) && (
            <Row justify="center">
              <Col>
                <Link to={INTERNAL_LINKS.DASHBOARD}>
                  <Button size="large" text={t('takeMeToDashboard')} type="primary" />
                </Link>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(UserOnboarding);
