import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../enum';
import { Button } from '..';
import Box from '../Box';
import Text from '../Text';

const CompanySubscriptionInactiveWarningBar = props => {
  const { t } = props;

  return (
    <Box noShadow padding={'12px 24px'} variant="gray" style={{ borderRadius: 0 }}>
      <Row gutter={17} justify="space-between" align="middle">
        <Col>
          <Text variant="p" size="m" color="red">
            {t('kliksSubInnactiveFeatureNoLongerAvailable')}
          </Text>
        </Col>
        <Col>
          <Link to={INTERNAL_LINKS.PRICE_PLANS}>
            <Button size="sm">{t('updatePricingPlan')}</Button>
          </Link>
        </Col>
      </Row>
    </Box>
  );
};

export default CompanySubscriptionInactiveWarningBar;
