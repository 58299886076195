import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleApiErrors } from '../../api/axiosInstance';
import CrmContactsAPI from '../../api/crmContacts';
import * as actions from '../actions/realtor';

function* fetchRealtorContacts(action) {
  const existingContacts = yield select(store => store.realtor.contacts);

  if (!Array.isArray(existingContacts) || action.data.forceFetch) {
    yield put(actions.emitStartFetchingContacts());
    try {
      const contacts = yield call(CrmContactsAPI.fetchCrmContacts);
      yield put(actions.emitUpdateContacts(contacts));
    } catch (err) {
      handleApiErrors(err.response);
      yield put(actions.emitUpdateContacts([]));
    }
  }
}

/**
 * watcher saga
 */
export const realtorSaga = [takeLatest(actions.FETCH_CONTACTS, fetchRealtorContacts)];
