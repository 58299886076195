import React from 'react';

import RedAsterisk from '../RedAsterisk';
import Tooltip from '../Tooltip';

const AnonymizedAsterisk = props => {
  const { t } = props;

  if (typeof t !== 'function') return null;

  return (
    <Tooltip arrowPointAtCenter title={t('anonymizedUser')}>
      <RedAsterisk />
    </Tooltip>
  );
};

export default AnonymizedAsterisk;
