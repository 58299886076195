import { Col, Collapse, List, Row } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';

import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import { EXTERNAL_LINKS, IMAGES } from '../../../enum';
import {
  checkIfMobileAppSettingIsValid,
  checkIfSingleMobileSettingIsValid,
  getValidMobileAppSettings,
  momentTimezone,
} from '../../../utils/common';
import MobileAppSettingHistorySection from './MobileAppSettingHistorySection';
import className from './styles.module.scss';

const MobileAppSettingsContent = props => {
  const { t, userId, settings } = props;
  const [activeCollapsePanel, setActiveCollapsePanel] = useState();

  const howToFixLink =
    get(settings, 'type') === 'ios'
      ? EXTERNAL_LINKS.FRESHDESK_IOS_DEVICE_SETTINGS
      : EXTERNAL_LINKS.FRESHDESK_ANDROID_DEVICE_SETTINGS;

  const renderListItem = (type, settingKey) => {
    const isSettingValid = checkIfSingleMobileSettingIsValid(
      get(settings, 'type'),
      type,
      settingKey,
      get(settings, `${type}.${settingKey}`),
    );

    return (
      <List.Item key={settingKey}>
        <Col flex={1}>
          <Row align="middle" gutter={[12, 12]} wrap={false}>
            <Col flex="65px">
              <Text color={isSettingValid ? 'primary' : 'red'}>
                {t(isSettingValid ? 'valid' : 'invalid')}
              </Text>
            </Col>
            <Col flex={1}>
              <Text>{t(settingKey)}</Text>
            </Col>
          </Row>
        </Col>
      </List.Item>
    );
  };
  const renderListHeader = (title, settingType, setting) => {
    const isSettingValid = checkIfMobileAppSettingIsValid(
      get(settings, 'type'),
      settingType,
      setting,
    );
    return (
      <Row align="middle" gutter={[8, 8]} wrap={false}>
        <Col>
          <img
            width="17px"
            height="17px"
            style={{ display: 'block' }}
            alt={isSettingValid ? 'valid' : 'invalid'}
            src={isSettingValid ? IMAGES.CIRCLE_CHECK_MARK_ICON : IMAGES.CIRCLE_REMOVE_ICON}
          />
        </Col>
        <Col>
          <Text variant="b" renderAs="p">
            {title}
          </Text>
        </Col>
        {!isSettingValid && (
          <Col>
            <LinkText renderAs="a" target="_blank" rel="noopener noreferrer" href={howToFixLink}>
              {t('clickHereToFix')}
            </LinkText>
          </Col>
        )}
      </Row>
    );
  };

  const [locationSettings, batterySettings, notificationsSettings] = useMemo(() => {
    return [
      get(settings, 'location', {}),
      get(settings, 'battery', {}),
      get(settings, 'other', null),
    ];
  }, [settings]);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Text variant="b" style={{ display: 'block', paddingBottom: 12 }}>
          {t('lastUpdated')}
        </Text>
        <Text>{momentTimezone(get(settings, 'created')).format('LL [-] HH:mm a zz')}</Text>
      </Col>

      <Col xs={24}>
        <List
          header={renderListHeader(t('locationSettings'), 'location', locationSettings)}
          dataSource={Object.keys(getValidMobileAppSettings(get(settings, 'type'), 'location'))}
          renderItem={settingKey => renderListItem('location', settingKey)}
        />
      </Col>

      <Col xs={24}>
        <List
          header={renderListHeader(t('powerSettings'), 'battery', batterySettings)}
          dataSource={Object.keys(getValidMobileAppSettings(get(settings, 'type'), 'battery'))}
          renderItem={settingKey => renderListItem('battery', settingKey)}
        />
      </Col>

      <Col xs={24}>
        <List
          header={renderListHeader(t('notificationsSettings'), 'other', notificationsSettings)}
          dataSource={Object.keys(getValidMobileAppSettings(get(settings, 'type'), 'other'))}
          renderItem={settingKey => renderListItem('other', settingKey)}
        />
      </Col>

      <Col xs={24}>
        <Collapse
          ghost
          defaultActiveKey={activeCollapsePanel}
          onChange={setActiveCollapsePanel}
          className={className.collapsePanel}
          expandIcon={({ isActive }) => (
            <img
              src={IMAGES.GREEN_CHEVRON_DOWN_ICON}
              alt="expand icon"
              style={{ transform: `translateY(-3px) rotate(${isActive ? 0 : 180}deg)` }}
            />
          )}
        >
          <Collapse.Panel
            key="open"
            header={<Text variant="b">{t('viewHistory')}</Text>}
            style={{ padding: 0 }}
          >
            <MobileAppSettingHistorySection t={t} userId={userId} type={get(settings, 'type')} />
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default MobileAppSettingsContent;
