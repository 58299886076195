import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';

import Button from '../../Button';
import Text from '../../Text';
import GenericModal from '../GenericModal';

/**
 * Modal for Yes/No confirmations.
 * Takes a Title and Message for its body.
 */
class ConfirmModal extends React.Component {
  render() {
    const { t, className, title, message, visible, hideCancel, okText, cancelText } = this.props;
    const newClassName = `confirm-modal ${className}`;

    const messageIsText = message && typeof message === 'string';

    return (
      <GenericModal
        {...this.props}
        className={newClassName}
        title={title || ' '}
        centered
        visible={visible}
        closeIcon={[]}
        onOk={this.props.onOk}
        onCancel={this.props.onCancel}
        footer={
          <Row gutter={16} justify="end">
            {hideCancel ? (
              <>
                <Col>
                  <Button
                    key="ok"
                    disabled={this.props.loading}
                    loading={this.props.loading}
                    className="confirm-modal-action"
                    text={okText || t('OK')}
                    onClick={this.props.onOk}
                    type="primary"
                  />
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <Button
                    key="cancel"
                    disabled={this.props.loading}
                    className="confirm-modal-action"
                    text={cancelText || t('Cancel')}
                    type="secondary"
                    onClick={this.props.onCancel}
                  />
                </Col>
                <Col>
                  <Button
                    key="ok"
                    disabled={this.props.loading}
                    loading={this.props.loading}
                    className="confirm-modal-action"
                    text={okText || t('OK')}
                    onClick={this.props.onOk}
                    type="primary"
                  />
                </Col>
              </>
            )}
          </Row>
        }
      >
        <div className="confirm-modal-content">
          {messageIsText && (
            <Text className="modal-message" variant="p" size="sm">
              {message}
            </Text>
          )}

          {message && !messageIsText && (
            <div className="modal-message d-flex justify-content-center">{message}</div>
          )}
        </div>
      </GenericModal>
    );
  }
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  visible: PropTypes.bool,
  className: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  hideCancel: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  loading: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  loading: false,
  title: '',
  message: '',
  visible: false,
  className: '',
  hideCancel: false,
  okText: '',
  cancelText: '',
  onOk: () => {},
  onCancel: () => {},
};

export default withNamespaces()(ConfirmModal);
