import { Descriptions, Table } from 'antd';
import React from 'react';

import { formatNumberWithCurrency } from '../../../utils/numbers';
import { CustomTable, HelpIcon } from '../..';
import Text from '../../Text';

const FavrVariablePaymentPerMileTable = props => {
  const { t, currency, useAllowance, dataSource } = props;
  const COLUMNS = [
    {
      key: 'type',
      title: t('type'),
      dataIndex: 'type',
      render: type => t(type),
    },
    {
      align: 'right',
      key: 'amount',
      title: t('Amount'),
      dataIndex: 'amount',
      render: amount => (useAllowance ? 'N/A' : formatNumberWithCurrency(amount, currency)),
    },
    {
      width: 45,
      key: 'info',
      title: '',
      render: ({ pricePerGallon, type }) => {
        if (pricePerGallon) {
          return (
            <HelpIcon
              width={200}
              placement="left"
              hint={
                <Descriptions layout="vertical" size="small">
                  <Descriptions.Item
                    label={
                      <Text size="sm" variant="b">
                        Average Price
                      </Text>
                    }
                  >
                    {formatNumberWithCurrency(pricePerGallon, currency)}{' '}
                    {type === 'fuelPerMile' && 'per gallon'}
                  </Descriptions.Item>
                </Descriptions>
              }
            />
          );
        }

        return null;
      },
    },
  ];

  return (
    <CustomTable
      pagination={false}
      showSearchInput={false}
      dataSource={dataSource}
      columns={COLUMNS}
      summary={
        !!dataSource.length && !useAllowance
          ? pageData => {
              const totalAmount = pageData.reduce((total, current) => total + current.amount, 0);

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} />

                  <Table.Summary.Cell index={1} align="right">
                    <Text variant="b">{formatNumberWithCurrency(totalAmount, currency)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} />
                </Table.Summary.Row>
              );
            }
          : undefined
      }
    />
  );
};

export default FavrVariablePaymentPerMileTable;
