import { Empty } from 'antd';
import React, { useCallback, useMemo } from 'react';

import Text from '../../../../components/Text';
import AnonymizeRowButton from '../AnonymizeRowButton';
import DownloadRowButton from '../DownloadRowButton';

const StatusTab = props => {
  const { t, requests } = props;

  const renderRequestItemRow = useCallback(
    request => {
      if (request.requestType === 'download')
        return <DownloadRowButton key={request._id} {...props} request={request} />;
      if (request.requestType === 'anonymize')
        return <AnonymizeRowButton key={request._id} {...props} request={request} />;
      return null;
    },
    [props],
  );

  const RENDERED_ITEMS = useMemo(() => {
    if (!Array.isArray(requests)) return [];

    return requests.map(renderRequestItemRow).filter(Boolean);
  }, [requests, renderRequestItemRow]);

  if (!RENDERED_ITEMS.length) {
    return (
      <Empty
        description={
          <Text variant="strong" size="sm" textAlign="center">
            {t('noPendingRequests')}
          </Text>
        }
      />
    );
  }

  return <div>{RENDERED_ITEMS}</div>;
};

export default StatusTab;
