import { Col, Row } from 'antd';
import React from 'react';

import Spinner from '../Spinner';

const SpaceSpinner = props => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: props.height || 300 }}>
      <Col>
        <Spinner />
      </Col>
    </Row>
  );
};

export default SpaceSpinner;
