import './style.scss';

import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
// the hoc
import { withNamespaces } from 'react-i18next';

const StepCardIcon = ({ className, color, backgroundColor, fontSize, style, ...params }) => {
  const avatarClassName = `custom-avatar step-card-icon ${className}`;
  const styled = { ...style, backgroundColor: backgroundColor, color: color, fontSize: fontSize };

  return <Avatar className={avatarClassName} style={styled} children={params.children} />;
};

StepCardIcon.propTypes = {
  backgroundColor: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
};

StepCardIcon.defaultProps = {
  backgroundColor: '',
  fontSize: '18px',
  className: '',
  style: {},
};

export default withNamespaces()(StepCardIcon);
