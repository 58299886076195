import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { USER_API } from '../../../api/user';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useTablePagination from '../../../hooks/useTablePagination';
import useTableSort from '../../../hooks/useTableSort';
import { setFullscreenMaxHeight, setModalFullscreenWidth } from '../../../utils/common';
import FormItem from '../../Form/FormItem';
import Select from '../../Select';
import HistoricalFavrRatesTable from '../../Table/HistoricalFavrRatesTable';
import GenericModal from '../GenericModal';

const FavrValuesHistoryModal = props => {
  const { t, visible, userId, isStandardVehicleElectric, onCancel } = props;

  const { stringTableSort, handleTableSort } = useTableSort({ _id: -1 });
  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  const [selectedYear, setSelectedYear] = useState();

  const favrYearsQuery = useQuery({
    enabled: visible && !!userId,
    staleTime: 1000 * 60 * 10, // 10 minutes
    queryKey: ['fetchYearsWithFavrValuesData', userId],
    queryFn: () => USER_API.fetchYearsWithFavrValuesData(userId),
  });

  useDidUpdateEffect(() => {
    if (visible && Array.isArray(favrYearsQuery.data) && typeof selectedYear === 'undefined') {
      setSelectedYear(favrYearsQuery.data[0] || null);
    }
  }, [visible, favrYearsQuery.data]);

  const favrValuesHistoryQuery = useQuery({
    enabled: visible && !!userId && favrYearsQuery.isFetched && typeof selectedYear !== 'undefined',
    queryKey: [
      'fetchUserFavrValuesHistory',
      userId,
      selectedYear,
      stringTableSort,
      paginationConfig.current,
      paginationConfig.pageSize,
    ],
    queryFn: () =>
      USER_API.fetchUserFavrValuesHistory(
        { userId, year: selectedYear },
        stringTableSort,
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    onSuccess: ({ totalCount }) => handleTotalUpdate(totalCount),
  });

  useDidUpdateEffect(() => {
    if (!visible) {
      setSelectedYear();
    }
  }, [visible]);

  return (
    <GenericModal
      visible={visible}
      footer={null}
      title={t('historicalFavrRates')}
      onCancel={onCancel}
      width={setModalFullscreenWidth(800)}
      height={setFullscreenMaxHeight(762)}
      bodyStyle={{ overflowY: 'auto' }}
    >
      <Row>
        <Col>
          <FormItem label={t('year')}>
            <Select
              disabled={favrYearsQuery.isFetching || favrValuesHistoryQuery.isFetching}
              loading={favrYearsQuery.isFetching || favrValuesHistoryQuery.isFetching}
              value={selectedYear}
              onChange={year => {
                setSelectedYear(year);
                if (paginationConfig.current !== 1) handlePageChange(1);
              }}
              options={[
                { label: t('All'), value: null },
                ...(Array.isArray(favrYearsQuery.data)
                  ? favrYearsQuery.data.map(year => ({ value: year, label: year }))
                  : []),
              ]}
            />
          </FormItem>
        </Col>
      </Row>

      <HistoricalFavrRatesTable
        t={t}
        isStandardVehicleElectric={isStandardVehicleElectric}
        loading={favrValuesHistoryQuery.isFetching}
        dataSource={get(favrValuesHistoryQuery.data, 'documents', [])}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
          handleTableSort(sorters?.columnKey, sorters?.order);
        }}
      />
    </GenericModal>
  );
};

export default FavrValuesHistoryModal;
