import { Form } from 'antd';
import React from 'react';

import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { getCompanyCurrency } from '../../../utils/company';
import { ERROR_MESSAGE } from '../../../utils/constants';
import FormItem from '../../Form/FormItem';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import Text from '../../Text';
import TextInput from '../../TextInput';
import GenericModal from '../GenericModal';

const BankAccountVerifyFormModal = props => {
  const { t, visible, fundingSourceId, isSubmitting, onCancel, onSubmit } = props;

  const [form] = Form.useForm();

  useDidUpdateEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const handleSubmit = values => {
    onSubmit({ ...values, fundingSourceId });
  };

  return (
    <GenericModal
      centered
      title={t('verifyAccount')}
      visible={visible}
      onCancel={onCancel}
      closable={isSubmitting}
      footer={
        <SubmitCancelButtonGroup
          onCancel={onCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
          submitText={t('verify')}
          onSubmit={form.submit}
        />
      }
    >
      <Text>{t('toVerifyEnterTwoMicroDepositsBelow')}</Text>
      <Text>{t('ifYouDontSeeMicroDeposits')}</Text>

      <br />

      <Form form={form} autoComplete="off" onFinish={handleSubmit}>
        <FormItem
          required
          name="amount1"
          label={t('amount1')}
          rules={[
            { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
            {
              validateTrigger: 'onSubmit',
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                if (value < 0) return Promise.reject(ERROR_MESSAGE().MIN_NUMBER(0.0001));
                return Promise.resolve();
              },
            },
          ]}
        >
          <TextInput prefix={getCompanyCurrency().currencySymbol} type="number" step="any" />
        </FormItem>

        <FormItem
          required
          name="amount2"
          label={t('amount2')}
          rules={[
            { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
            {
              validateTrigger: 'onSubmit',
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                if (value < 0) return Promise.reject(ERROR_MESSAGE().MIN_NUMBER(0.0001));
                return Promise.resolve();
              },
            },
          ]}
        >
          <TextInput prefix={getCompanyCurrency().currencySymbol} type="number" step="any" />
        </FormItem>
      </Form>
    </GenericModal>
  );
};

export default BankAccountVerifyFormModal;
