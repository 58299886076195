import classnames from 'classnames';
import React from 'react';

import className from './style.module.scss';

const Chip = props => {
  const { variant, size, text, fullWidth, ...rest } = props;

  return (
    <div
      {...rest}
      className={classnames(className.chip, [className[variant], className[size]], {
        [className.fullWidth]: fullWidth,
      })}
    >
      {text}
    </div>
  );
};

Chip.defaultProps = {
  variant: 'warning',
  size: 'sm',
  fullWidth: false,
};

export default Chip;
