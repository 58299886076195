import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../components';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import AmountText from '../../../components/Text/AmountText';
import FadedText from '../../../components/Text/FadedText';
import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';

const AccountBalanceCard = props => {
  const {
    t,
    balanceAmount,
    pendingBalanceAmount,
    onAddFunds,
    onWithdrawFunds,
    fundingSource,
  } = props;

  return (
    <Box maxWidth={500}>
      <Row
        wrap={false}
        gutter={16}
        align="middle"
        justify="space-between"
        style={{ marginBottom: 15 }}
      >
        <Col flex={1}>
          <Text variant="b" renderAs="p" flex="210px">
            {t('current')}
          </Text>
          <Text variant="b" renderAs="p" flex="210px">
            {t('kliksAccountBalance')}
          </Text>
        </Col>
        <Col>
          <AmountText size="xl" variant="b" amount={formatCurrencyToCompanyLocale(balanceAmount)} />
        </Col>
      </Row>

      {pendingBalanceAmount > 0 && (
        <Row
          wrap={false}
          gutter={16}
          align="middle"
          justify="space-between"
          style={{ marginBottom: 15 }}
        >
          <Col flex={1}>
            <Text variant="b" renderAs="p" flex="210px">
              {t('pendingAccountBalance')}
            </Text>
          </Col>
          <Col>
            <AmountText
              size="xl"
              amount={formatCurrencyToCompanyLocale(pendingBalanceAmount)}
              style={{ color: '#F09F55' }}
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]}>
        <Col flex={1}>
          <Button
            block
            type="secondary"
            onClick={onWithdrawFunds}
            disabled={
              !fundingSource || fundingSource?.fundingSourceStatus !== STATUS_LIST().Status.VERIFIED
            }
          >
            {t('withdrawFunds')}
          </Button>
        </Col>

        <Col flex={1}>
          <Button
            block
            onClick={onAddFunds}
            disabled={
              !fundingSource || fundingSource?.fundingSourceStatus !== STATUS_LIST().Status.VERIFIED
            }
          >
            {t('addFunds')}
          </Button>
        </Col>
      </Row>

      {!fundingSource && (
        <Row gutter={[8, 8]} style={{ marginTop: 15 }}>
          <Col flex="100%">
            <Link to={INTERNAL_LINKS.ACH_CONFIG}>
              <Button block>{t('addBankAccount')}</Button>
            </Link>
          </Col>
          <Col flex="100%">
            <FadedText size="sm">{t('youMustSetupBankAccountToAddFunds')}</FadedText>
          </Col>
        </Row>
      )}

      {fundingSource && fundingSource.fundingSourceStatus !== STATUS_LIST().Status.VERIFIED && (
        <Row gutter={[8, 8]} style={{ marginTop: 15 }}>
          <Col flex="100%">
            <Link to={INTERNAL_LINKS.ACH_CONFIG}>
              <Button block>{t('goToAchConfig')}</Button>
            </Link>
          </Col>
          <Col flex="100%">
            <FadedText size="sm">{t('youMustVerifyYourAccountToAddFunds')}</FadedText>
          </Col>
        </Row>
      )}
    </Box>
  );
};

AccountBalanceCard.defaultProps = {
  balanceAmount: 0,
  pendingBalanceAmount: 0,
};

export default AccountBalanceCard;
