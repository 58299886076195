import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
import 'react-report-builder/lib/main.css';
import './index.css';
import './styles/root.scss';
import './styles/system.scss';
import './App.scss';
import 'typeface-mulish';
import 'react-calendar/dist/Calendar.css';
import 'font-awesome/css/font-awesome.min.css';
import '@lottiefiles/lottie-player';
import '@brainhubeu/react-carousel/lib/style.css';

import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { createBrowserHistory } from 'history';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import AnalyticsToolPublicPageTracker from './components/AnalyticsToolPublicPageTracker';
import AuthenticatedViews from './components/AuthenticatedViews';
import PublicRoute from './components/PublicRoute';
import { INTERNAL_LINKS } from './enum';
import initialState from './stores/initialState';
import createRootReducer from './stores/reducers';
import appSaga from './stores/sagas';
import { PENDO_ANALYTICS } from './utils/pendoAnalytics';
import UserAccountActivate from './views/activate';
import ForgotPassword from './views/forgotpassword';
import ConfirmEmail from './views/landingpage/confirmemail';
import NewLogInForm from './views/landingpage/newloginform';
import NewSignUp from './views/landingpage/newsignup';
import SignupSuccess from './views/landingpage/signupsuccess';
import PublicItineraryDetailsView from './views/public-itinerary-details';
import ResetPassword from './views/resetpassword';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('react-query/devtools/development').then(d => ({
    default: d.ReactQueryDevtools,
  })),
);

PENDO_ANALYTICS.initialize();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

window.queryClient = queryClient;

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'kliks-platform',
    env: 'prod',
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 5,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

const BASE_HISTORY = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //Redux browser plugin
const sentryReduxEnhancer = Sentry.createReduxEnhancer();
export const REDUX_STORE = createStore(
  createRootReducer(BASE_HISTORY),
  { ...initialState },
  composeEnhancers(
    applyMiddleware(routerMiddleware(BASE_HISTORY), sagaMiddleware),
    sentryReduxEnhancer,
  ),
);
sagaMiddleware.run(appSaga);

function App() {
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    window.toggleDevtools = () => setShowDevtools(old => !old);
  }, []);

  return (
    <div className="App">
      <FlagsmithProvider
        flagsmith={flagsmith}
        options={{
          environmentID: process.env.REACT_APP_FLAGSMITH_ENV_ID,
          traits: {
            web_app_version: process.env.REACT_APP_VERSION,
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />

          {showDevtools && (
            <React.Suspense fallback={null}>
              <ReactQueryDevtoolsProduction initialIsOpen={false} position="bottom-right" />
            </React.Suspense>
          )}

          <Provider store={REDUX_STORE}>
            <ConnectedRouter history={BASE_HISTORY}>
              <Route component={AnalyticsToolPublicPageTracker} />

              <Switch>
                <PublicRoute
                  exact
                  path={INTERNAL_LINKS.HOME}
                  render={() => <Redirect to={INTERNAL_LINKS.LOGIN} />}
                />
                <Route path={INTERNAL_LINKS.SIGNUP_SUCCESS} component={SignupSuccess} />
                <PublicRoute path={INTERNAL_LINKS.LOGIN} component={NewLogInForm} />
                <PublicRoute path={INTERNAL_LINKS.SIGNUP} component={NewSignUp} />
                <Route path={`${INTERNAL_LINKS.CONFIRMEMAIL}/:token`} component={ConfirmEmail} />
                <Route
                  path={`${INTERNAL_LINKS.USER_ACCOUNT_ACTIVATE}/:token`}
                  component={UserAccountActivate}
                />
                <Route path={INTERNAL_LINKS.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route path={`${INTERNAL_LINKS.RESET_PASSWORD}/:token`} component={ResetPassword} />
                <Route
                  exact
                  path={`${INTERNAL_LINKS.PUBLIC_ITINERARY_DETAILS()}`}
                  component={PublicItineraryDetailsView}
                />
                <Route component={AuthenticatedViews} />
              </Switch>
            </ConnectedRouter>
          </Provider>
        </QueryClientProvider>
      </FlagsmithProvider>
    </div>
  );
}

export default App;
