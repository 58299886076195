import React from 'react';

import { INTERNAL_LINKS } from '../enum';

const BackOfficeHomeView = React.lazy(() => import('./back-office-home-view'));
const InsurancePoliciesUserDetailsView = React.lazy(() =>
  import('./insurance-policies-user-details-view'),
);
const InsurancePoliciesUserListView = React.lazy(() =>
  import('./insurance-policies-user-list-view'),
);
const InsurancePolicyVerificationView = React.lazy(() =>
  import('./insurance-policy-verification-view'),
);
const VehiclePolicyVerificationView = React.lazy(() =>
  import('./vehicle-policy-verification-view'),
);

const BackOfficeViews = [
  {
    exact: true,
    path: INTERNAL_LINKS.BACK_OFFICE_HOME,
    component: BackOfficeHomeView,
  },
  {
    exact: true,
    path: `${INTERNAL_LINKS.INSURANCE_POLICY_VERIFICATION}/:id/user/:userID`,
    component: InsurancePoliciesUserDetailsView,
  },
  {
    exact: true,
    path: `${INTERNAL_LINKS.INSURANCE_POLICY_VERIFICATION}/:id`,
    component: InsurancePoliciesUserListView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.INSURANCE_POLICY_VERIFICATION,
    component: InsurancePolicyVerificationView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.VEHICLE_POLICY_VERIFICATION,
    component: VehiclePolicyVerificationView,
  },
];

export default BackOfficeViews;
