import { ACTIONS_MAP } from '../../enum';

export const UPDATE_COMPANY_LIST = 'UPDATE_COMPANY_LIST';
export const UPDATE_COMPANY_PLAN = 'UPDATE_COMPANY_PLAN';
export const UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS';
export const SET_ACCOUNT_CREATE_STEP = 'SET_ACCOUNT_CREATE_STEP';
export const SAVE_PHONE_PIN = 'SAVE_PHONE_PIN';
export const SAVE_EMAIL_CONFIRM = 'SAVE_EMAIL_CONFIRM';
export const SET_EMAIL_CONFIRMED = 'SET_EMAIL_CONFIRMED';
export const SET_VISITED_SETUP = 'SET_VISITED_SETUP';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const UNSET_CURRENT_COMPANY = 'UNSET_CURRENT_COMPANY';
export const UPDATE_SUPPORTED_COUNTRIES = 'UPDATE_SUPPORTED_COUNTRIES';
export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';
export const UPDATE_TIMEZONES = 'UPDATE_TIMEZONES';
export const FETCH_COUNTRY_RATE = 'FETCH_COUNTRY_RATE';
export const UPDATE_COUNTRY_RATE = 'UPDATE_COUNTRY_RATE';
export const RESET_STORES = 'RESET_STORES';
export const UPDATE_BASENAME = 'UPDATE_BASENAME';
export const ADD_DWOLLA_CONTROLLER_DOCUMENT = 'ADD_DWOLLA_CONTROLLER_DOCUMENT';
export const ADD_DWOLLA_BENEFICIAL_OWNER_DOCUMENT = 'ADD_DWOLLA_BENEFICIAL_OWNER_DOCUMENT';

export function setHeaderTitle(title, caption = '') {
  return {
    type: ACTIONS_MAP.SET_HEADER_TITLE,
    data: { title, caption },
  };
}

export function updateSupportedCountries(supportedCountries = []) {
  let sortedCountries = [];

  if (Array.isArray(supportedCountries)) {
    sortedCountries = supportedCountries.sort((a, b) => a.name.localeCompare(b.name));
    const UsIndex = sortedCountries.findIndex(c => c.code === 'US');
    const US = sortedCountries.splice(UsIndex, 1)[0];
    sortedCountries.unshift(US);
  }

  return {
    type: UPDATE_SUPPORTED_COUNTRIES,
    data: { supportedCountries: sortedCountries },
  };
}

export function updateCompanyList(companyList) {
  return {
    type: UPDATE_COMPANY_LIST,
    data: { companyList },
  };
}

export function updateCompanyPlan(companyPlan) {
  return {
    type: UPDATE_COMPANY_PLAN,
    data: { companyPlan },
  };
}

export function updateCompanySettingsStore(settings) {
  return {
    type: UPDATE_COMPANY_SETTINGS,
    data: { settings },
  };
}

export function setAccountCreateStatus(status) {
  return {
    type: SET_ACCOUNT_CREATE_STEP,
    data: status,
  };
}

export function savePhoneForPin(phone) {
  return {
    type: SAVE_PHONE_PIN,
    data: phone,
  };
}

export function saveEmailForConfirm(email) {
  return {
    type: SAVE_EMAIL_CONFIRM,
    data: email,
  };
}

export function setEmailConfirmed(confirmed) {
  return {
    type: SET_EMAIL_CONFIRMED,
    data: confirmed,
  };
}

export function setVisitedSetupPage(visited) {
  return {
    type: SET_VISITED_SETUP,
    data: visited,
  };
}

export function saveCompany(company) {
  return {
    type: SET_CURRENT_COMPANY,
    data: company,
  };
}

export function removeCompany() {
  return {
    type: UNSET_CURRENT_COMPANY,
  };
}

export function emitFetchTimezones() {
  return {
    type: FETCH_TIMEZONES,
  };
}

export function emitUpdateTimezones(timezones) {
  return {
    type: UPDATE_TIMEZONES,
    data: { timezones },
  };
}

export function emitFetchCountryRate(countryCode) {
  return {
    type: FETCH_COUNTRY_RATE,
    data: { countryCode },
  };
}

export function emitUpdateCountryRate(countryRate) {
  return {
    type: UPDATE_COUNTRY_RATE,
    data: { countryRate },
  };
}

export function emitResetStores() {
  return { type: RESET_STORES };
}

export function emitUpdateGlobalBasename(basename) {
  return { type: UPDATE_BASENAME, data: { basename } };
}

export function emitAddDwollaControllerDocuments(controllerId, document) {
  return {
    type: ADD_DWOLLA_CONTROLLER_DOCUMENT,
    data: { controllerId, document },
  };
}

export function emitAddDwollaBeneficialOwnerDocuments(beneficialOwnerId, document) {
  return {
    type: ADD_DWOLLA_BENEFICIAL_OWNER_DOCUMENT,
    data: { beneficialOwnerId, document },
  };
}
