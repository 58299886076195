import { INTERNAL_LINKS } from '../enum';
import { handleApiCalls } from './axiosInstance';

export const SETTINGS_API = {
  fetchGlobalSettings: async () => {
    const url = `${process.env.REACT_APP_HOST_API}settings`;
    const response = await handleApiCalls('get', url);

    return response.data[0];
  },
  /**
   * @param {string} id Id of Setting to update
   * @param {object} data
   * @param {number} data.freeTrialDays
   * @param {string[]} data.allowedDomains
   * @param {string[]} data.blockedDomains
   * @param {string[]} data.timezones
   * @param {string[]} data.mileageValues
   * @param {string[]} data.businessPurposeList
   * @param {number} data.numberOfRecentPasswords
   * @param {string[]} data.countriesWithMph
   * @param {string[]} data.countriesWithGallon
   * @param {object} data.metabase
   * @param {object[]} data.metabase.reports
   * @returns {object} setting
   */
  updateGlobalSettings: async (id, data) => {
    const url = `${process.env.REACT_APP_HOST_API}settings/${id}`;
    const response = await handleApiCalls('put', url, data);

    return response.data;
  },
  testSftpConnection: async sftpConfig => {
    const url = `${process.env.REACT_APP_HOST_API}settings/sftp-connection`;
    const response = await handleApiCalls('post', url, sftpConfig);

    return response?.data;
  },
  fetchLinks: async () => {
    const url = `${process.env.REACT_APP_HOST_API}settings/links`;
    const response = await handleApiCalls('get', url);

    return response.data;
  },
  fetchMetabaseReports: async () => {
    const url = `${process.env.REACT_APP_HOST_API}settings/metabase/reports`;
    const response = await handleApiCalls('get', url);

    return response.data.map(report => ({
      ...report,
      url: INTERNAL_LINKS.REPORTS_DETAILS(report.type, 'metabase-reports'),
    }));
  },
};
