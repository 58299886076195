import { get, merge } from 'lodash';
import React, { useState } from 'react';

import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';

const ReimbursementDetailsPageContainer = props => {
  const { t, companySettings, breadcrumbs, children, history, style, ...rest } = props;

  const [goReimbursementsQueryParams] = useState(
    get(history, 'location.state.goReimbursementsQueryParams', ''),
  );

  return (
    <PageContainer
      {...rest}
      style={merge(style || {}, { minHeight: '100%' })}
      title={
        <PageBreadcrumbs
          items={[
            {
              onClick: () =>
                history.push({
                  pathname: INTERNAL_LINKS.REIMBURSEMENT,
                  search: goReimbursementsQueryParams,
                }),
              label: t(
                companySettings?.settings?.tripApprovalWorkflow ? 'Reimbursements' : 'taxVault',
              ),
            },
            ...(Array.isArray(breadcrumbs) ? breadcrumbs : []),
          ]}
        />
      }
    >
      {children}
    </PageContainer>
  );
};

export default ReimbursementDetailsPageContainer;
