import { Col, Row } from 'antd';
import React from 'react';

import { IMAGES } from '../../enum';
import Text from '../Text';

const TextWithExternalLink = props => {
  const { text, linkProps, textProps } = props;
  return (
    <Row wrap={false} gutter={5} align="middle">
      <Col>
        <Text size="sm" renderAs="span" {...textProps}>
          {text}
        </Text>
      </Col>

      <Col>
        <a {...linkProps} rel="noopener noreferrer" target="_blank">
          <img
            width="14px"
            src={IMAGES.EXPAND_WINDOW_ICON}
            alt="open in new window"
            style={{ display: 'flex' }}
          />
        </a>
      </Col>
    </Row>
  );
};

TextWithExternalLink.defaultProps = {
  linkProps: {},
  textProps: {},
};

export default TextWithExternalLink;
