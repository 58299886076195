import { Col, Row, Spin } from 'antd';
import { first } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

import useUserGroupsQuery from '../../hooks/queries/useUserGroupsQuery';
import { checkIfElementIsScrolledToBottom } from '../../utils/common';
import FormItem from '../Form/FormItem';
import Select from '../Select';

const UserGroupsLookupSelect = props => {
  const {
    t,
    userId,
    value,
    showAllOption,
    onChange,
    notFoundContent,
    formItemProps,
    disabled,
    hideFormItem,
    ...rest
  } = props;

  const handleChange = useCallback(
    val => {
      if (typeof onChange === 'function') {
        onChange(val);
      }
    },
    [onChange],
  );

  const userGroupsQuery = useUserGroupsQuery(userId);

  const options = useMemo(() => {
    let optionsArray = Array.isArray(userGroupsQuery.data)
      ? userGroupsQuery.data.map(group => ({
          label: group.name,
          value: group._id,
        }))
      : [];

    if (showAllOption) {
      optionsArray = [{ value: null, label: t('All') }, ...optionsArray];
    }

    return optionsArray;
  }, [t, showAllOption, userGroupsQuery.data]);

  const handleOptionsScroll = e => {
    const bottom = checkIfElementIsScrolledToBottom(e.target);

    if (bottom && !userGroupsQuery.isFetchingNextPage && userGroupsQuery.hasNextPage) {
      userGroupsQuery.fetchNextPage();
    }
  };

  useEffect(() => {
    if (
      !!options.length &&
      ((showAllOption && typeof value === 'undefined') || (!showAllOption && !value))
    ) {
      if (showAllOption && options[1]?.value !== value) {
        handleChange(null);
      } else if (first(options)?.value !== value) {
        handleChange(first(options)?.value);
      }
    }
  }, [options, showAllOption, value, handleChange]);

  const [SelectWrapper, selectWrapperProps] = useMemo(
    () =>
      hideFormItem ? [React.Fragment, {}] : [FormItem, { label: t('Group'), ...formItemProps }],
    [t, hideFormItem, formItemProps],
  );

  return (
    <SelectWrapper {...selectWrapperProps}>
      <Select
        {...rest}
        value={value}
        onChange={handleChange}
        fullWidth
        allowClear={false}
        disabled={disabled || userGroupsQuery.isFetching}
        loading={userGroupsQuery.isFetching}
        options={options}
        placeholder={t('Select Groups')}
        onPopupScroll={handleOptionsScroll}
        notFoundContent={
          userGroupsQuery.isFetching ? (
            <Row justify="center" align="middle">
              <Col>
                <Spin size="default" />
              </Col>
            </Row>
          ) : (
            notFoundContent
          )
        }
      />
    </SelectWrapper>
  );
};

UserGroupsLookupSelect.defaultProps = {
  formItemProps: {},
  hideFormItem: false,
};

export default UserGroupsLookupSelect;
