import { Col, Row } from 'antd';
import React from 'react';

import { IMAGES } from '../../../enum';
import { capitalizeEveryWordOfString, momentTimezone } from '../../../utils/common';
import Button from '../../Button';
import Text from '../../Text';
import classNames from './styles.module.scss';

const BusinessVehicleSummary = props => {
  const {
    t,
    vehicle,
    onEdit,
    isDeleting,
    onDelete,
    isConfirming,
    onConfirm,
    onMakeDefault,
    requiresConfirmation,
  } = props;

  return (
    <Row>
      <Col flex="425px">
        <Row wrap={false} gutter={16} justify="space-between" align="middle">
          <Col>
            <div>
              <Text variant="h5">
                {vehicle.model.replace(/^_/, '')}{' '}
                {capitalizeEveryWordOfString(vehicle.make.replace(/^_/, ''))}, {vehicle.year}
              </Text>
            </div>

            <div>
              <Text>Added on {momentTimezone(vehicle.created).format('MMM DD, YYYY')}</Text>
            </div>
          </Col>

          <Col>
            <img className={classNames.carImage} alt="vehicle" src={IMAGES.CAR_CLOUD_ICON} />
          </Col>
        </Row>
        <Row gutter={10}>
          {typeof onEdit === 'function' && (
            <Col flex={1}>
              <Button block disabled={isDeleting} onClick={onEdit}>
                Edit
              </Button>
            </Col>
          )}

          {typeof onDelete === 'function' && (
            <Col flex={1}>
              <Button
                block
                type="secondary"
                loading={isDeleting}
                disabled={isDeleting}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Col>
          )}

          {typeof onMakeDefault === 'function' && (
            <Col flex={1}>
              <Button
                block
                type="primary"
                loading={isDeleting}
                disabled={isDeleting}
                onClick={onMakeDefault}
              >
                Make Default
              </Button>
            </Col>
          )}

          {requiresConfirmation && typeof onConfirm === 'function' && (
            <Col flex={1}>
              <Button
                block
                type="primary"
                loading={isConfirming}
                disabled={isConfirming}
                onClick={onConfirm}
              >
                {t('confirmInfoIsUnchanged')}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default BusinessVehicleSummary;
