import { Col, Row } from 'antd';
import { get } from 'lodash';
import React from 'react';

import { Button } from '../../../components';
import DownloadIcon from '../../../components/DownloadIcon';
import FormItem from '../../../components/Form/FormItem';
import Select from '../../../components/Select';

const AnomalyPagesFilterHeader = props => {
  const {
    t,
    isExportingData,
    selectedPaymentPeriod,
    selectedPaymentSchedulePeriods,
    selectedPaymentSchedule,
    paymentScheduleOptions,
    onPaymentScheduleSelect,
    onPeriodSelect,
    onDataExport,
    isAdjustingRates,
    isConfirmingRates,
    onAdjustRates,
    onConfirmRates,
  } = props;

  return (
    <Row gutter={16} align="middle" justify="space-between" wrap={false}>
      <Col flex={1}>
        {typeof onPaymentScheduleSelect === 'function' && (
          <>
            <Row gutter={[16, 16]} align="middle">
              <Col xs={24} md={12} lg={8}>
                <FormItem label={t('paymentSchedules')} className="no-margin-bottom">
                  <Select
                    fullWidth
                    defaultValue={get(selectedPaymentSchedule, '_id')}
                    options={paymentScheduleOptions}
                    onChange={onPaymentScheduleSelect}
                  />
                </FormItem>
              </Col>

              {selectedPaymentSchedulePeriods && selectedPaymentPeriod && (
                <Col xs={24} md={12} lg={6}>
                  <FormItem label={t('paymentPeriod')} className="no-margin-bottom">
                    <Select
                      options={selectedPaymentSchedulePeriods}
                      onChange={onPeriodSelect}
                      value={selectedPaymentPeriod}
                    />
                  </FormItem>
                </Col>
              )}
            </Row>
          </>
        )}
      </Col>

      {typeof onAdjustRates === 'function' && !isAdjustingRates && (
        <Col>
          <Button size="sm" onClick={onAdjustRates}>
            {t('adjustRates')}
          </Button>
        </Col>
      )}

      {typeof onConfirmRates === 'function' && isAdjustingRates && (
        <Col>
          <Button
            size="sm"
            disabled={isConfirmingRates}
            loading={isConfirmingRates}
            onClick={onConfirmRates}
          >
            {t('confirmAdjustment')}
          </Button>
        </Col>
      )}

      {typeof onDataExport === 'function' && (
        <Col>
          <DownloadIcon t={t} loading={isExportingData} onClick={onDataExport} />
        </Col>
      )}
    </Row>
  );
};

export default AnomalyPagesFilterHeader;
