export const COLLAPSE_LAYOUT_SIDEBAR = 'COLLAPSE_LAYOUT_SIDEBAR';
export const EXPAND_LAYOUT_SIDEBAR = 'EXPAND_LAYOUT_SIDEBAR';

export const emitCollapseLayoutSidebar = () => {
  return { type: COLLAPSE_LAYOUT_SIDEBAR };
};

export const emitExpandLayoutSidebar = () => {
  return { type: EXPAND_LAYOUT_SIDEBAR };
};
