import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class FAVRCreatorAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'favr-creator' });
  }
  /**
   * Allow company/system admin to apply adjusted rate values
   * @param {string} lastEvaluatedKey
   * @param {object} params
   * @param {string} params.email
   * @param {string} params.companyId
   * @param {number} pageSize
   */
  async fetchFavrRates(lastEvaluatedKey, params, pageSize = 50) {
    const queryParams = cleanQueryParams({
      lastEvaluatedKey: lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : lastEvaluatedKey,
      companyId: params?.companyId,
      email: params?.searchTerm,
      limit: pageSize,
    });
    const result = await handleApiCalls('get', `${this.baseUrl}/rates?${queryParams}`);

    return result.data;
  }
}

export default FAVRCreatorAPI;
