import './style.scss';

import { Select as AntdSelect, Spin } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { IMAGES } from '../../enum';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { useStoreSelector } from '../../utils/storeSelectors';
import SelectDrawer from './SelectDrawer';

/**
 * Select component based on the Ant Design Select
 * but following Kliks style guide and branding
 *
 * @param {object} props - Component props
 */
const Select = props => {
  const {
    options,
    className,
    size,
    fullWidth,
    loading,
    onChange,
    label,
    disabled,
    onClick,
    value,
    defaultValue,
    showSearch,
    resetOnNoMatch,
    ...rest
  } = props;

  const selectClassNames = classnames(className, 'custom-select', `select-${size}`, { fullWidth });

  const isMobile = useStoreSelector('browser.isMobile');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const renderOptionItem = option => (
    <AntdSelect.Option
      key={option.title || option.value}
      title={option.label}
      value={option.value}
      disabled={option.disabled}
    >
      {rest.isStateAbbreviated ? option.value : option.label}
    </AntdSelect.Option>
  );

  const renderOptionGroup = group => (
    <AntdSelect.OptGroup label={group.label}>
      {group.options.map(option => renderOptionItem(option))}
    </AntdSelect.OptGroup>
  );

  const renderOptions = options => {
    return options.map(option => {
      if (Array.isArray(option.options)) {
        return renderOptionGroup(option);
      }

      return renderOptionItem(option);
    });
  };

  const handleRadioChange = (e, value) => {
    e.stopPropagation();
    e.preventDefault();

    setIsDrawerOpen(false);
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  const flatOptions = useMemo(() => {
    let flat = [];

    options.forEach(option => {
      if (Array.isArray(option.options)) {
        flat = [...flat, ...option.options];
      } else {
        flat = [...flat, option];
      }
    });

    return flat;
  }, [options]);

  useDidUpdateEffect(() => {
    if (!!flatOptions.length && !!value) {
      if (
        resetOnNoMatch &&
        // eslint-disable-next-line eqeqeq
        !flatOptions.find(option => option.value == value) &&
        typeof onChange === 'function'
      ) {
        onChange('');
      }
    }
  }, [flatOptions, value]);

  return (
    <>
      <AntdSelect
        optionFilterProp="children"
        {...rest}
        showSearch={isMobile ? false : showSearch}
        defaultValue={defaultValue}
        value={value}
        open={isMobile ? false : undefined}
        disabled={disabled}
        loading={loading}
        size={size}
        menuItemSelectedIcon={<img src={IMAGES.CHECK_MARK_ICON} alt="checkmark" />}
        suffixIcon={
          loading ? (
            <Spin size="small" />
          ) : (
            <img src={IMAGES.CHEVRON_DOWN_ICON} alt="select-chevron" />
          )
        }
        className={selectClassNames}
        dropdownAlign={{ offset: [0, 0] }}
        popupClassName="custom-select-dropdown"
        onChange={onChange}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();

          if (!disabled) {
            if (typeof onClick === 'function') onClick(e);
            if (isMobile) setIsDrawerOpen(true);
          }
        }}
      >
        {renderOptions(options)}
      </AntdSelect>

      {isMobile && (
        <SelectDrawer
          label={label}
          value={value}
          isOpen={isDrawerOpen}
          options={options}
          onChange={handleRadioChange}
          onClose={() => setIsDrawerOpen(false)}
        />
      )}
    </>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.oneOf([null]),
      ]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
};

Select.defaultProps = {
  options: [],
  resetOnNoMatch: true,
  size: 'small',
  className: '',
  placeholder: 'Select',
};

export default Select;
