import { Col, Row } from 'antd';
import React, { useState } from 'react';

import AbsoluteFooterBox from '../../../../components/AbsoluteFooterBox';
import Button from '../../../../components/Button';
import StepCircle from '../../../../components/SectionStepWrapper/StepCircle';
import Text from '../../../../components/Text';
import TextInput from '../../../../components/TextInput';
import { STATUS_LIST } from '../../../../enum';
import { canResubmitReceiptForApproval, canUpdateReceiptStatus } from '../../receipt-permissions';

const ReceiptFooterAdminActionBoxContent = props => {
  const {
    // General
    t,
    isLoading,
    loggedInUser,
    disableRequestMoreInfo,

    // Receipt
    receipt,
    onApprove,
    onDeny,
    onResubmitForApprovalFixed,

    // More info
    isRequesting,
    isRequestingMoreInfo,
    selectedTrips,
    receiptTrips,
    toggleRequestMoreInfo,
    onSendToEmployee,
  } = props;

  const [comment, setComment] = useState();

  const CAN_REQUEST_MORE_INFO =
    isRequestingMoreInfo && receipt.status !== STATUS_LIST().Status.MORE_INFO;

  const CAN_UPDATE_RECEIPT_STATUS = canUpdateReceiptStatus(loggedInUser, receipt || undefined);

  const CAN_RESUBMIT_FIXED_RECEIPT_FOR_APPROVAL = canResubmitReceiptForApproval(
    loggedInUser,
    receipt || undefined,
  );

  if (!(CAN_REQUEST_MORE_INFO || CAN_UPDATE_RECEIPT_STATUS)) return null;

  return (
    <AbsoluteFooterBox>
      <Row gutter={16} justify="end" align="middle">
        {CAN_REQUEST_MORE_INFO ? (
          <>
            <Col flex="350px">
              <Row gutter={10} align="middle">
                <Col>
                  <StepCircle step={1} />
                </Col>
                <Col flex={1}>
                  <Text>
                    {!selectedTrips.length && t('selectTripRequestMoreInfo')}

                    {!!selectedTrips.length &&
                      selectedTrips.length !== receiptTrips.length &&
                      t('selectedTripsCount', {
                        count: selectedTrips.length,
                      })}

                    {!!selectedTrips.length &&
                      selectedTrips.length === receiptTrips.length &&
                      t('allTripsSelected', {
                        number: selectedTrips.length,
                      })}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col flex={1}>
              <Row gutter={10} align="middle">
                <Col>
                  <StepCircle step={2} disabled={!selectedTrips.length} />
                </Col>

                <Col flex={1}>
                  <TextInput
                    textArea
                    value={comment}
                    disabled={!selectedTrips.length || isRequesting}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder={t('enterComment')}
                    onChange={e => setComment(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Button
                type="secondary"
                onClick={toggleRequestMoreInfo}
                disabled={isLoading || isRequesting}
              >
                {t('Cancel')}
              </Button>
            </Col>
            <Col>
              <Button
                loading={isRequesting}
                disabled={!(!!selectedTrips.length && comment) || isRequesting}
                onClick={() =>
                  onSendToEmployee(receipt._id, selectedTrips, comment).then(() => setComment())
                }
              >
                {t('sendBackToEmployee')}
              </Button>
            </Col>
          </>
        ) : CAN_UPDATE_RECEIPT_STATUS ? (
          <>
            {CAN_RESUBMIT_FIXED_RECEIPT_FOR_APPROVAL && (
              <Col>
                <Button
                  type="primary"
                  disabled={isLoading}
                  onClick={() => onResubmitForApprovalFixed([receipt._id])}
                >
                  {t('submitApproval')}
                </Button>
              </Col>
            )}

            {!CAN_RESUBMIT_FIXED_RECEIPT_FOR_APPROVAL && (
              <>
                {receipt.status !== STATUS_LIST().Status.MORE_INFO &&
                  loggedInUser._id !== receipt?.submittedBy?._id && (
                    <Col>
                      <Button
                        onClick={toggleRequestMoreInfo}
                        type="secondary"
                        loading={isRequesting}
                        disabled={
                          isLoading ||
                          isRequesting ||
                          disableRequestMoreInfo ||
                          receipt.status === STATUS_LIST().Status.MORE_INFO ||
                          ![STATUS_LIST().Status.PENDING, STATUS_LIST().Status.OVERDUE].includes(
                            receipt.status,
                          )
                        }
                      >
                        {t('requestMoreInfo')}
                      </Button>
                    </Col>
                  )}

                <Col>
                  <Button
                    type="secondary"
                    onClick={onDeny}
                    disabled={
                      isLoading ||
                      [STATUS_LIST().Status.DENIED, STATUS_LIST().Status.PAID].includes(
                        receipt.status,
                      )
                    }
                  >
                    {t('rejectAll')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={onApprove}
                    disabled={
                      isLoading ||
                      [STATUS_LIST().Status.APPROVED, STATUS_LIST().Status.PAID].includes(
                        receipt.status,
                      )
                    }
                  >
                    {t('approveAll')}
                  </Button>
                </Col>
              </>
            )}
          </>
        ) : null}
      </Row>
    </AbsoluteFooterBox>
  );
};

export default ReceiptFooterAdminActionBoxContent;
