import classnames from 'classnames';
import React from 'react';

import Text from '../Text';
import classNames from './styles.module.scss';

const BottomBorderTextRows = props => {
  const { rowContent, borderless, labelTextSize } = props;

  return (
    <div className={classnames(classNames.bottomTable, { [classNames.borderless]: borderless })}>
      {rowContent.map(content => (
        <Text key={content.label} variant="p" size={labelTextSize}>
          <b>{content.label}:</b> <span>{content.value}</span>
        </Text>
      ))}
    </div>
  );
};

BottomBorderTextRows.defaultProps = {
  rowContent: [],
  labelTextSize: 'sm',
};

export default BottomBorderTextRows;
