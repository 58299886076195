import { STATUS_LIST } from '../enum';
import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

/**
 * Performs an API request to create a request to anonymize a user's data
 *
 * @param {string} firstName First name
 * @param {string} lastName Last name
 * @param {string[]} usersIds List if IDs to hard delete
 * @param {boolean} isAdminRequest Is a request from Company Admin
 */
export const createHardUserDataDeleteRequest = async (
  firstName,
  lastName,
  usersIds,
  isAdminRequest = false,
) => {
  const url = `${process.env.REACT_APP_HOST_API}user-data-actions/anonymize`;
  const result = await handleApiCalls('post', url, {
    firstName: encodeURIComponent(firstName),
    lastName: encodeURIComponent(lastName),
    usersIds,
    isAdminRequest,
  });
  return result.data;
};

/**
 * Performs an API call to create a request to soft delete a user's data
 *
 * @param {string} userId User ID
 * @param {string} reason Reason for request
 */
export const createSoftUserDataDeleteRequest = async (userId, reason = 'soft delete user') => {
  const url = `${process.env.REACT_APP_HOST_API}user-data-actions/soft-delete`;
  const result = await handleApiCalls('post', url, { userId, reason });
  return result.data.data;
};

/**
 * Perform an API call to update the status of an user data action
 *
 * @param {string} requestID
 * @param {"cancelled" | "approved" | "denied"} status
 */
export const updateUserDataActionStatus = async (requestID, status) => {
  const url = `${process.env.REACT_APP_HOST_API}user-data-actions/${requestID}/status`;
  const result = await handleApiCalls('patch', url, { status });
  // eslint-disable-next-line no-unused-vars
  const { requestingUser, ...data } = result.data.data;
  return data;
};

/**
 * Performs an API call to approve a user's data anonymization request
 * @param {string} requestID Request ID
 */
export const approveUserDataRequest = async requestID =>
  updateUserDataActionStatus(requestID, STATUS_LIST().Status.APPROVED);

/**
 * Performs an API call to cancel a user's data anonymization request
 *
 * @param {string} requestID Request ID
 */
export const cancelUserDataRequest = async requestID =>
  updateUserDataActionStatus(requestID, STATUS_LIST().Status.CANCELLED);

/**
 * Performs an API call to deny a user's data request
 *
 * @param {string} requestID Request ID
 */
export const denyUserDataRequest = async requestID =>
  updateUserDataActionStatus(requestID, STATUS_LIST().Status.DENIED);

/**
 * Perform an API call to create a user data download request
 *
 * @param {object} data Request data
 * @param {boolean} data.trips
 * @param {boolean} data.receipts
 * @param {boolean} data.reimbursements
 * @param {boolean} data.profile
 * @param {boolean} data.entireDateRange
 * @param {object[]} data.userIds
 */
export const createUserDataDownloadRequest = async data => {
  const url = `${process.env.REACT_APP_HOST_API}user-data-actions/download-request`;
  const result = await handleApiCalls('post', url, data);
  return result.data.data;
};

/**
 * Perform an API call to download a blob with an user's data
 *
 * @param {string} id Id of Download request
 */
export const downloadUserDataFile = async id => {
  const url = `${process.env.REACT_APP_HOST_API}user-data-actions/${id}/download`;
  const result = await handleApiCalls('get', url, { responseType: 'blob' });
  return result.data;
};

/**
 * Perform an API call to fetch every user data action belonging to a company
 *
 * @param {object} params
 * @param {["anonymize" | "download" | "soft-delete"]} params.type
 * @param {"gdpr" | "export"} params.subtype
 * @param {number[]=} params.range
 * @param {{ [key: string]: "desc" | "asc" }} params.sort
 */
export const fetchUserDataActions = async ({
  type,
  subtype,
  range = formatQueryRange(1, 50),
  sort = { created: 'desc' },
}) => {
  let queryParams = cleanQueryParams({
    type: JSON.stringify(type),
    subtype,
    sort: JSON.stringify(sort),
    range: range,
  });

  const url = `${process.env.REACT_APP_HOST_API}user-data-actions?${queryParams}`;
  const result = await handleApiCalls('get', url);
  return result.data.data;
};
