import { handleApiCalls } from './axiosInstance';

const PlansAPI = {
  fetchCustomPlans: async () => {
    const url = `${process.env.REACT_APP_HOST_API}plans?isCustom=true`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },

  createCustomPlan: async (name, stripePriceId) => {
    const url = `${process.env.REACT_APP_HOST_API}plans`;
    const response = await handleApiCalls('post', url, { name, stripePriceId });
    return response.data;
  },

  updateCustomPlan: async (planId, name, stripePriceId) => {
    const url = `${process.env.REACT_APP_HOST_API}plans/${planId}`;
    const response = await handleApiCalls('put', url, { name, stripePriceId });
    return response.data;
  },
};

export default PlansAPI;
