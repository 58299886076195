import { Col, Row } from 'antd';
import React, { useMemo } from 'react';

import { IMAGES, INTERNAL_LINKS } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { checkIfStringContainsValue, getUserFullName, momentFormat } from '../../../utils/common';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import { renderFavrComplianceStatusSymbol } from '../../../utils/rates';
import { selectStoreCountryByCode } from '../../../utils/storeSelectors';
import { sortColumnByStatus } from '../../../utils/tables';
import Button from '../../Button';
import HelpIcon from '../../HelpIcon';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import CustomTable from '../../Table';
import StatusTag from '../../Tag/StatusTag';

const RefreshIcon = (
  <img width="14px" src={IMAGES.REFRESH_ARROW_ICON} alt="refresh" style={{ marginRight: 5 }} />
);

const UserInFavrRateTable = props => {
  const {
    t,
    isCreate,
    dataSource,
    rateCountry,
    canRefreshRate,
    onRefreshRate,
    usersRefetching,
    onSearch,
    ...rest
  } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();
  const COUNTRY = useMemo(() => selectStoreCountryByCode(rateCountry), [rateCountry]);

  useDidUpdateEffect(() => {
    if (typeof onSearch === 'function') {
      onSearch(searchTerm);
    }
  }, [searchTerm]);

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(user => {
        return checkIfStringContainsValue(getUserFullName(user), searchTerm);
      });
    }

    return array;
  }, [searchTerm, dataSource]);

  const COLUMNS = [
    {
      width: '300px',
      title: t('name'),
      key: 'name',
      render: (actions, data) => (
        <ProfileNamePicture
          t={t}
          showStatus={false}
          user={data}
          redirectTo={`${INTERNAL_LINKS.USER_DETAILS_PROFILE(data._id)}`}
        />
      ),
      sorter: (a, b) => {
        const aFullName = getUserFullName(a);
        const bFullName = getUserFullName(b);

        return aFullName.toLowerCase().localeCompare(bFullName.toLowerCase());
      },
    },
    {
      key: 'status',
      title: t('Status'),
      render: (actions, data) => <StatusTag status={data.status} />,
      ...sortColumnByStatus('status'),
    },
    {
      align: 'center',
      width: '100px',
      title: t('driverLicenceCompliance'),
      dataIndex: 'dirverLicenseComplianceStatus',
      render: dirverLicenseComplianceStatus =>
        isCreate
          ? renderFavrComplianceStatusSymbol()
          : renderFavrComplianceStatusSymbol(dirverLicenseComplianceStatus),
    },
    {
      align: 'center',
      width: '100px',
      title: t('insuranceCompliance'),
      dataIndex: 'insuranceComplianceStatus',
      render: insuranceComplianceStatus =>
        isCreate
          ? renderFavrComplianceStatusSymbol()
          : renderFavrComplianceStatusSymbol(insuranceComplianceStatus),
    },
    {
      align: 'right',
      dataIndex: 'fixedRate',
      title: t('fixedRate'),
      render: fixedRate =>
        typeof fixedRate === 'number'
          ? formatNumberWithCurrency(fixedRate, COUNTRY?.currencyCode)
          : '-',
    },
    {
      align: 'right',
      dataIndex: 'variableRate',
      title: (
        <Row gutter={8} wrap={false} align="middle" justify="end">
          <Col>{t('variableRate')}</Col>
          <Col>
            <HelpIcon hint={'Variable rate is updated on a weekly basis.'} />
          </Col>
        </Row>
      ),
      render: variableRate =>
        typeof variableRate === 'number'
          ? formatNumberWithCurrency(variableRate, COUNTRY?.currencyCode)
          : '-',
    },
    {
      title: t('fixedLastUpdated'),
      dataIndex: 'fixedRateDate',
      render: fixedRateDate => (fixedRateDate ? momentFormat(fixedRateDate, 'LL') : '-'),
    },
    {
      title: t('variableLastUpdated'),
      dataIndex: 'variableRateDate',
      render: variableRateDate => (variableRateDate ? momentFormat(variableRateDate, 'LL') : '-'),
    },
    canRefreshRate
      ? {
          title: t('refreshRate?'),
          render: (_, user) => {
            return (
              <Row gutter={[6, 6]}>
                <Col flex={1}>
                  <Button
                    block
                    size="sm"
                    disabled={usersRefetching.includes(user._id)}
                    loading={usersRefetching.includes(user._id)}
                    onClick={() =>
                      onRefreshRate(user._id, { getFixedRate: true, getVariableRate: false })
                    }
                    icon={RefreshIcon}
                  >
                    {t('fixedRate')}
                  </Button>
                </Col>
                <Col flex={1}>
                  <Button
                    block
                    size="sm"
                    disabled={usersRefetching.includes(user._id)}
                    loading={usersRefetching.includes(user._id)}
                    onClick={() =>
                      onRefreshRate(user._id, { getFixedRate: false, getVariableRate: true })
                    }
                    icon={RefreshIcon}
                  >
                    {t('variableRate')}
                  </Button>
                </Col>
              </Row>
            );
          },
        }
      : {},
    // {
    //   width: '250px',
    //   align: 'right',
    //   title: (
    //     <Row gutter={8} wrap={false} align="middle" justify="end">
    //       <Col>{t('additionalAllowance')}</Col>
    //       <Col>
    //         <HelpIcon
    //           hint={
    //             'The additional allowance amount is a set amount to be added to each reimbursement payment.'
    //           }
    //         />
    //       </Col>
    //     </Row>
    //   ),
    //   render: () => '-',
    // },
  ];

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      rowKey={data => data._id}
      dataSource={filteredDataSource}
      onSearchTermChange={setSearchTerm}
      scroll={{ x: 1100 }}
    />
  );
};

UserInFavrRateTable.defaultProps = {
  dataSource: [],
  usersRefetching: [],
};

export default UserInFavrRateTable;
