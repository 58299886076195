export const FETCH_USER_VEHICLES = 'FETCH_USER_VEHICLES';
export const UPDATE_USER_VEHICLES = 'UPDATE_USER_VEHICLES';
export const UPDATE_USER_VEHICLE_BY_ID = 'UPDATE_USER_VEHICLE_BY_ID';
export const ADD_USER_VEHICLE = 'ADD_USER_VEHICLE';

export function emitFetchUserVehicles(userID, forceFetch = false) {
  return {
    type: FETCH_USER_VEHICLES,
    data: { userID, forceFetch },
  };
}

export const emitAddUserVehicle = (userID, vehicles) => {
  return { type: ADD_USER_VEHICLE, data: { userID, vehicles } };
};

export function emitUpdateUserVehicles(userID, vehicles) {
  return {
    type: UPDATE_USER_VEHICLES,
    data: { userID, vehicles },
  };
}

export function emitUpdateUserVehicleByID(userID, vehicleID, vehicle) {
  return {
    type: UPDATE_USER_VEHICLE_BY_ID,
    data: { userID, vehicleID, vehicle },
  };
}
