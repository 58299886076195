import { Col, Progress, Row } from 'antd';
import classnames from 'classnames';
import React from 'react';

import Tooltip from '../../../components/Tooltip';
import { momentCompanyFormat, momentCompanyTimezone } from '../../../utils/common';
import classNames from '../styles.module.scss';
import ReimbursementBalanceTimelineBoxItem from './ReimbursementBalanceTimelineBoxItem';

const TimelineDot = props => {
  const { content, active, title } = props;

  return (
    <div className={classNames.timelineDotContainer}>
      <Tooltip arrowPointAtCenter enabled={!!title} title={title}>
        <div className={classnames(classNames.timelineDot, { [classNames.active]: active })} />
      </Tooltip>
      <div className={classNames.timelineDotContent}>{content}</div>
    </div>
  );
};

const ReimbursementBalanceTimeline = props => {
  const { t, timeline, groupId, fromDate, toDate, onAddFunds } = props;

  const firstItem = timeline[0];
  const lastItem = timeline[timeline.length - 1];

  const today = momentCompanyTimezone().unix();
  const startDate = momentCompanyTimezone(firstItem.date).unix();
  const endDate = momentCompanyTimezone(lastItem.date).unix();

  const IS_PAST_END_DATE = today > endDate;

  const getPercent = (n, start, end) => ((n - start) * 100) / (end - start);
  const keepInPercentRange = n => Math.max(Math.min(100, n), 0);

  return (
    <div className={classNames.balanceTimeline}>
      <Row>
        <Col flex="150px">
          <Progress
            size="small"
            percent={today >= startDate ? 100 : 50}
            status="success"
            showInfo={false}
          />
        </Col>

        <Col flex={1}>
          <Row align="middle">
            {timeline.map((item, i) => {
              const isLastItem = i + 1 === timeline.length;
              const start = momentCompanyTimezone(item.date).unix();
              const end = !isLastItem ? momentCompanyTimezone(timeline[i + 1].date).unix() : null;

              const percent = !isLastItem
                ? keepInPercentRange(getPercent(today, start, end))
                : null;

              return (
                <React.Fragment key={item.type}>
                  <Col flex={'10px'}>
                    <TimelineDot
                      title={momentCompanyFormat(item.date, 'MMMM, DD YYYY')}
                      active={today >= start}
                      content={
                        <ReimbursementBalanceTimelineBoxItem
                          t={t}
                          onAddFunds={onAddFunds}
                          key={item.type}
                          timelineItem={item}
                          groupId={groupId}
                          fromDate={momentCompanyTimezone(fromDate)}
                          endDate={momentCompanyTimezone(toDate)}
                        />
                      }
                    />
                  </Col>

                  {!isLastItem && (
                    <Col flex={1}>
                      <Progress size="small" percent={percent} status="success" showInfo={false} />
                    </Col>
                  )}
                </React.Fragment>
              );
            })}
          </Row>
        </Col>

        <Col flex="150px">
          <Progress
            size="small"
            percent={IS_PAST_END_DATE ? 100 : 0}
            status={IS_PAST_END_DATE ? 'success' : 'normal'}
            showInfo={false}
          />
        </Col>
      </Row>
    </div>
  );
};

ReimbursementBalanceTimeline.defaultProps = {
  timeline: [],
};

export default ReimbursementBalanceTimeline;
