import { Col, DatePicker, Form, Row } from 'antd';
import React, { useMemo, useState } from 'react';

import FormItem from '../../../../components/Form/FormItem';
import LocationInput from '../../../../components/LocationInput';
import Radio from '../../../../components/Radio';
import Select from '../../../../components/Select';
import TextInput from '../../../../components/TextInput';
import { analyseAddressAutoCompleteString } from '../../../../utils/address';
import {
  DATE_OF_BIRTH_FORM_RULE,
  ERROR_MESSAGE,
  FORMAT_FORM_RULE,
} from '../../../../utils/constants';
import { selectStoreCountryByCode } from '../../../../utils/storeSelectors';

const ControllerInformationForm = props => {
  const {
    t,
    form,
    isSubmitting,
    initialValues,
    formDisabled,
    onFieldChange,
    fullSsnRequired,
  } = props;

  const UNITED_STATES = useMemo(() => selectStoreCountryByCode('US'), []);

  const [citizenship, setCitizenship] = useState(initialValues?.controllerCitizenship);

  const handleStreetOneSelect = address => {
    const analysedString = analyseAddressAutoCompleteString(address);
    const { streetOne, city, state, postalCode } = analysedString;

    if (form) {
      form.setFieldsValue({
        controllerAddress1: streetOne,
        controllerState: state,
        controllerCity: city,
        controllerPostalCode: postalCode,
      });
    }
  };

  return (
    <Form form={form} initialValues={initialValues} onValuesChange={onFieldChange}>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <FormItem
            required
            name="controllerFirstName"
            label={t('controllerFirstName')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput autoFocus disabled={isSubmitting || formDisabled} />
          </FormItem>
        </Col>

        <Col xs={24} md={12}>
          <FormItem
            required
            name="controllerLastName"
            label={t('controllerLastName')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting || formDisabled} />
          </FormItem>
        </Col>
      </Row>

      <FormItem
        required
        name="controllerTitle"
        label={t('title')}
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput
          disabled={isSubmitting || formDisabled}
          placeholder={t('chiefFinancialOfficer')}
        />
      </FormItem>

      <FormItem
        required
        name="controllerDateOfBirth"
        label={t('dateOfBirth')}
        rules={[
          { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
          ...DATE_OF_BIRTH_FORM_RULE(18),
        ]}
      >
        <DatePicker
          autoComplete="new-password"
          placeholder="YYYY-MM-DD"
          disabled={isSubmitting || formDisabled}
          style={{ width: '100%' }}
        />
      </FormItem>

      <FormItem
        required
        name="controllerAddress1"
        label={t('streetAddress1')}
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <LocationInput
          disabled={isSubmitting || formDisabled}
          autoComplete="new-password"
          limitedToCountryCode="US"
          onSelect={handleStreetOneSelect}
        />
      </FormItem>

      <FormItem name="controllerAddress2" label={t('streetAddress2')}>
        <TextInput disabled={isSubmitting || formDisabled} />
      </FormItem>

      <Row gutter={24}>
        <Col xs={24} md={8}>
          <FormItem
            required
            name="controllerState"
            label={t('state')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <Select
              fullWidth
              showSearch
              disabled={isSubmitting || formDisabled}
              optionFilterProp="children"
              options={UNITED_STATES.states}
            />
          </FormItem>
        </Col>

        <Col xs={24} md={8}>
          <FormItem
            required
            name="controllerCity"
            label={t('city')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting || formDisabled} />
          </FormItem>
        </Col>

        <Col xs={24} md={8}>
          <FormItem
            required
            name="controllerPostalCode"
            label={t('postalCode')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting || formDisabled} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} md={8}>
          <FormItem
            required
            name="controllerCitizenship"
            label={t('controllerCitizenship')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <Radio.Group
              disabled={isSubmitting || formDisabled}
              name="controllerCitizenship"
              onChange={e => {
                setCitizenship(e.target.value);
                form.setFieldsValue({ controllerSSN: undefined });
              }}
            >
              <Radio value="us">{t('usCitizen')}</Radio>
              <Radio value="non-us">{t('nonUsCitizen')}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
        <Col xs={24} md={16}>
          <FormItem
            required
            name="controllerSSN"
            label={t(citizenship === 'us' && !fullSsnRequired ? 'ssnLast4' : 'ssnAllDigits')}
            rules={[
              { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
              ...(citizenship === 'us' && !fullSsnRequired
                ? [
                    { type: 'string', max: 4, message: ERROR_MESSAGE().STRING_MAX_LENGTH(4) },
                    { type: 'string', min: 4, message: ERROR_MESSAGE().STRING_MIN_LENGTH(4) },
                  ]
                : [
                    { type: 'string', min: 9, message: ERROR_MESSAGE().STRING_MIN_LENGTH(9) },
                    ...FORMAT_FORM_RULE(/[0-9]{3}-[0-9]{2}-[0-9]{4}/),
                  ]),
            ]}
          >
            <TextInput
              pattern="\d*"
              disabled={isSubmitting || formDisabled}
              minLength={4}
              maxLength={citizenship === 'us' && !fullSsnRequired ? 4 : undefined}
              masked={citizenship !== 'us' || fullSsnRequired}
              mask={citizenship === 'us' && !fullSsnRequired ? '0000' : '000-00-0000'}
              placeholder={citizenship === 'us' && !fullSsnRequired ? '0000' : '000-00-0000'}
            />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default ControllerInformationForm;
