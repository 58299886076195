import { Col, Row } from 'antd';
import { difference } from 'lodash';
import React, { useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import { RATES_API } from '../../../api/rates';
import { Button } from '../../../components';
import UserInFavrRateTable from '../../../components/Table/UserInFavrRateTable';
import UserInRateTable from '../../../components/Table/UserInRateTable';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { RATE_TYPES } from '../../../enum/Rates';
import useUsersInRateQuery from '../../../hooks/queries/useUsersInRateQuery';
import { useStoreSelector } from '../../../utils/storeSelectors';

const UsersAssociatedToThisRateView = props => {
  const { t, isWizard, match } = props;

  const isCreate = !!match.params.id;

  const rateWizard = useStoreSelector('rateWizard');

  const isFAVR = rateWizard.rateValues.rateType === RATE_TYPES.FAVR;
  const canViewRefreshButton = match.params.id && isFAVR;

  const [searchTerm, setSearchTerm] = useState();
  const [userTotalCount, setUserTotalCount] = useState();
  const [refetchingUserIds, setRefetchingUserIds] = useState([]);
  const addUserToRefetch = usersIds => {
    setRefetchingUserIds(state => [...state, ...usersIds]);
  };
  const removeUserToRefetch = (userIds = []) => {
    setRefetchingUserIds(state => difference(state, userIds));
  };

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    usersInRateQuery,
  } = useUsersInRateQuery(
    match.params.id,
    rateWizard.rateValues.rateType,
    isWizard ? rateWizard.rateValues.groups : rateWizard.initialValues.groups,
    searchTerm,
    {
      onSuccess: ({ count }) => {
        if (!userTotalCount) setUserTotalCount(count);
      },
    },
  );

  const retryFavrValues = useMutation({
    mutationFn: params => {
      addUserToRefetch(params.usersIds);
      return RATES_API.retryFetchFavrValues(params);
    },
    onSuccess: async (data, variables) => {
      removeUserToRefetch(variables.usersIds);
      await usersInRateQuery.refetch();
      Toast({
        duration: 10, // seconds
        type: 'open',
        message: t('ratesForUsersRefreshSuccess'),
      });
    },
    onError: (error, variables) => {
      removeUserToRefetch(variables.usersIds);
      handleApiErrors(error.response);
    },
  });

  const isFavrRateGenerated = useMemo(() => {
    let generated = false;
    if (
      rateWizard.rateValues.rateType === RATE_TYPES.FAVR &&
      Array.isArray(usersInRateQuery?.data?.documents)
    ) {
      for (let i = 0; i < usersInRateQuery.data.documents.length; i++) {
        const userInRate = usersInRateQuery.data.documents[i];
        if (
          typeof userInRate.fixedRate === 'number' ||
          typeof userInRate.variableRate === 'number'
        ) {
          generated = true;
          break;
        }
      }
    }

    return generated;
  }, [rateWizard.rateValues.rateType, usersInRateQuery]);

  return (
    <PageContainer
      title={t('usersAssociatedToThisRate_withCount', {
        count: userTotalCount || 0,
      })}
      noSurroundingSpace
      sideActionComponent={
        canViewRefreshButton && (
          <Row gutter={[6, 6]}>
            <Col flex={1}>
              <Button
                size="sm"
                disabled={
                  refetchingUserIds.length > 0 ||
                  retryFavrValues.isLoading ||
                  usersInRateQuery.isFetching
                }
                loading={refetchingUserIds.length > 0}
                onClick={() =>
                  retryFavrValues.mutateAsync({
                    productId: match.params.id,
                    usersIds: [],
                    getFixedRate: true,
                    getVariableRate: false,
                  })
                }
              >
                {t('refreshFixedRate')}
              </Button>
            </Col>
            <Col flex={1}>
              <Button
                size="sm"
                disabled={
                  refetchingUserIds.length > 0 ||
                  retryFavrValues.isLoading ||
                  usersInRateQuery.isFetching
                }
                loading={refetchingUserIds.length > 0}
                onClick={() =>
                  retryFavrValues.mutateAsync({
                    productId: match.params.id,
                    usersIds: [],
                    getFixedRate: false,
                    getVariableRate: true,
                  })
                }
              >
                {t('refreshVariableRate')}
              </Button>
            </Col>
          </Row>
        )
      }
    >
      <br />

      <div style={{ maxWidth: 900 }}>
        {rateWizard.rateValues.rateType === RATE_TYPES.FAVR ? (
          <>
            <Text>{t('viewListOfUserAndTheirFavrRate')}</Text>
            {isCreate && !isFavrRateGenerated && (
              <>
                <br />
                <Text variant="b" renderAs="p">
                  * {t('generatingRatesWillBeDisplayedWhenGenerated')}
                </Text>
                <Text variant="b" renderAs="p">
                  * {t('saveRateAndViewListLater')}
                </Text>
              </>
            )}
          </>
        ) : (
          <Text>{t('viewListUsersUsingRate')}</Text>
        )}
      </div>

      <br />

      {rateWizard.rateValues.rateType === RATE_TYPES.FAVR ? (
        <UserInFavrRateTable
          t={t}
          isCreate={isCreate}
          rateCountry={rateWizard.rateValues?.country}
          loading={usersInRateQuery.isFetching || retryFavrValues.isLoading}
          dataSource={usersInRateQuery.data.documents}
          canRefreshRate={canViewRefreshButton}
          usersRefetching={refetchingUserIds}
          onSearch={setSearchTerm}
          onRefreshRate={(userId, { getFixedRate, getVariableRate }) =>
            retryFavrValues.mutateAsync({
              productId: match.params.id,
              usersIds: [userId],
              getFixedRate,
              getVariableRate,
            })
          }
          onChange={({ current }) => handlePageChange(current)}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
        />
      ) : (
        <UserInRateTable
          t={t}
          onSearch={setSearchTerm}
          loading={usersInRateQuery.isFetching}
          dataSource={usersInRateQuery.data.documents}
          onChange={({ current }) => handlePageChange(current)}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
        />
      )}
    </PageContainer>
  );
};

export default withNamespaces()(UsersAssociatedToThisRateView);
