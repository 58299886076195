import { Form } from 'antd';
import React, { useMemo } from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import { selectStoreCountries, useStoreSelector } from '../../../utils/storeSelectors';
import FormItem from '../../Form/FormItem';
import Select from '../../Select';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import TextInput from '../../TextInput';

const InsuranceCompanyForm = props => {
  const { t, isUpdate, disabled, loading, onFinish, onCancel, initialValues, ...rest } = props;

  const supportedCountries = useStoreSelector(selectStoreCountries, []);

  const supportedCountryOptions = useMemo(() => {
    const countries = [];
    if (Array.isArray(supportedCountries)) {
      return supportedCountries.map(c => ({
        label: c.name,
        value: c.code,
      }));
    }

    return countries;
  }, [supportedCountries]);

  return (
    <Form {...rest} disabled={disabled} onFinish={onFinish} initialValues={initialValues}>
      <FormItem
        name="name"
        label={t('name')}
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput />
      </FormItem>

      <FormItem
        name="country"
        label={t('countries')}
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD, type: 'array' }]}
      >
        <Select resetOnNoMatch={false} mode="multiple" options={supportedCountryOptions} />
      </FormItem>

      <SubmitCancelButtonGroup
        isForm
        onCancel={onCancel}
        disabled={disabled}
        loading={loading}
        submitText={t(isUpdate ? 'update' : 'create')}
      />
    </Form>
  );
};

export default InsuranceCompanyForm;
