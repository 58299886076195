import { find, first, get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { PAYMENT_SCHEDULES_API } from '../api/paymentSchedule';
import { AD_HOC_SCHEDULE_TYPE } from '../enum/ScheduleTypes';
import i18n from '../i18n';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';
import { sortColumnByStringField } from '../utils/tables';

const usePaymentSchedules = (initialPaymentScheduleId, includeAdHoc = true, isAch) => {
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const [selectedPaymentSchedule, setSelectedPaymentSchedule] = useState();

  const paymentSchedulesQuery = useQuery({
    staleTime: Infinity,
    queryKey: ['fetchCompanyPaymentSchedules', currentCompany._id, false, isAch],
    queryFn: () =>
      PAYMENT_SCHEDULES_API.fetchCompanyPaymentSchedules(currentCompany._id, {
        getUsers: false,
        isAch,
      }),
  });

  const selectPaymentSchedule = useCallback(schedule => setSelectedPaymentSchedule(schedule), []);

  const paymentSchedules = useMemo(() => {
    if (Array.isArray(paymentSchedulesQuery.data)) {
      if (includeAdHoc) return paymentSchedulesQuery.data;
      return paymentSchedulesQuery.data.filter(
        schedule => get(schedule, 'scheduleType') !== AD_HOC_SCHEDULE_TYPE,
      );
    }

    return [];
  }, [includeAdHoc, paymentSchedulesQuery.data]);

  const paymentScheduleOptions = useMemo(() => {
    return paymentSchedules
      .map(schedule => ({
        value: schedule._id,
        label: [schedule.name, i18n.t(schedule.scheduleType)].join(' - '),
      }))
      .sort(sortColumnByStringField('label').sorter);
  }, [paymentSchedules]);

  useEffect(() => {
    if (!!paymentSchedules.length && !selectedPaymentSchedule && !!paymentScheduleOptions.length) {
      selectPaymentSchedule(
        find(paymentSchedules, {
          _id: initialPaymentScheduleId || get(first(paymentScheduleOptions), 'value'),
        }),
      );
    }
  }, [
    initialPaymentScheduleId,
    selectPaymentSchedule,
    paymentSchedules,
    selectedPaymentSchedule,
    paymentScheduleOptions,
  ]);

  return {
    isFetchingPaymentSchedules: paymentSchedulesQuery.isFetching,
    paymentSchedules,
    paymentScheduleOptions,
    selectedPaymentSchedule,
    selectPaymentSchedule,
  };
};

export default usePaymentSchedules;
