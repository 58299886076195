import { filter, first, get, last } from 'lodash';
import moment from 'moment-timezone';

import { STATUS_LIST } from '../enum';
import { UserRoleMapping } from '../enum/UserRoles';
import { getUserFullName, momentFormat } from './common';
import { hasCompanyAdminRole, hasCompanyManagerRole, hasUserRole } from './roles';

/**
 * Check if the user is currently on vacations based on the provided Vacation dates
 *
 * @param {object[]} vacations Vacations array
 * @return {boolean} Is on Vacation?
 */
export const checkIfUserIsOnVacation = (vacations = []) => {
  let isOnVacation = false;

  if (!vacations || (Array.isArray(vacations) && !vacations.length)) return isOnVacation;

  for (let i = 0; i < vacations.length; i++) {
    const startDate = moment(vacations[i].startDate);
    const endDate = moment(vacations[i].endDate);

    if (moment().isSameOrAfter(startDate, 'date') && moment().isSameOrBefore(endDate, 'date')) {
      isOnVacation = true;
      break;
    }
  }

  return isOnVacation;
};

/**
 * Normalizes the Users data from the API
 * in order to be used in Tables or any other parts of the site
 *
 * @param {object} userData User's profile data
 */
export function normalizeUserSchema(userData) {
  const device = userData.devices && userData.devices.length ? userData.devices[0] : {};
  const activatedOn = get(first(filter(userData.statusLog, { logField: 'active' })), 'logDate');
  const terminatedOn = get(last(filter(userData.statusLog, { logField: 'inactive' })), 'logDate');

  return {
    ...userData,
    name: getUserFullName(userData),
    normalized: true,
    id: userData._id,
    emp: '',
    dept: '',
    terminatedOn,
    activatedOn,
    obdDeviceID: device.deviceID,
    obdConnected: device.deviceStatus,
    shipDate: device.shipDate ? momentFormat(device.shipDate, 'DD MMM YYYY') : undefined,
    shipTo: device.shipTo ? device.shipTo : undefined,
    vacations: get(userData, 'businessInfor.vacationDates', []),
  };
}

export const getUserRoleLabel = role => {
  return UserRoleMapping[role || 'user'];
};

export const getUserStatusLabel = status => {
  return STATUS_LIST().StatusTagColorConfig[status || 'pending'].label;
};

/**
 * Filter function for driver's list in Trips Page
 *
 * @param {object} user - User/Driver data
 * @param {object} loggedInUserProfile Logged in user's profile data
 * @param {object[]} groupsWhereIsManager Array of groups where the Logged in User is the Manager
 * @return {boolean} Should include in driver's list?
 */
export const filterUserList = (user, loggedInUserProfile, groupsWhereIsManager = []) => {
  const USER_IS_PENDING = user?.status === STATUS_LIST().Status.PENDING;

  if (hasUserRole(loggedInUserProfile)) {
    return user._id === loggedInUserProfile._id;
  }

  if (hasCompanyManagerRole(loggedInUserProfile)) {
    return (
      !USER_IS_PENDING &&
      ((user.group && groupsWhereIsManager.includes(user.group._id)) ||
        user._id === loggedInUserProfile._id)
    );
  }

  return !USER_IS_PENDING;
};

/**
 * Formats the User List into an array of Options for the Select components
 * and, if requested, filters the array of according to the User's role.
 *
 * @param {object[]} userList List of Users from the Company
 * @param {object} userProfile Logged in User's data
 * @param {object[]} companyGroups Array of Groups from the Company
 * @param {boolean} filterList Should this filter the list for Managers? It does by default.
 * @return {{ label: string, value: any }[]}
 */
export const convertUserListIntoValidSelectOptions = (
  userList,
  userProfile,
  companyGroups,
  filterList = true,
) => {
  let list = [...userList];

  if (filterList && Array.isArray(companyGroups)) {
    const groupsWhereLoggedInUserIsManager = [];
    if (hasCompanyManagerRole(userProfile)) {
      companyGroups.forEach(group => {
        if (
          group.groupManager &&
          (group.groupManager._id === userProfile.profile._id ||
            group.additionalManagers?.includes(userProfile.profile._id))
        )
          groupsWhereLoggedInUserIsManager.push(group._id);
      });
    }

    list = list.filter(user => filterUserList(user, userProfile, groupsWhereLoggedInUserIsManager));
  }

  return list
    .map(user => ({ value: user._id, label: getUserFullName(user) }))
    .filter(option => !!option.label)
    .sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Counts the amount of users that have activated their accounts in the last month
 *
 * @param {object[]} userList List of Users from the company
 */
export const countNewUsersInLastMonth = (userList = []) => {
  const newUsers = userList.filter(
    user =>
      user.personalInfor.activatedAt &&
      moment(user.personalInfor.activatedAt).isBetween(moment().subtract('1', 'month'), moment()),
  );

  return newUsers.length;
};

/**
 * Counts the amount of users that have activated
 *
 * @param {object[]} userList List of Users from the company
 */
export const countActivatedUsers = (userList = []) => {
  const newUsers = userList.filter(user => !!user.personalInfor.activatedAt);

  return newUsers.length;
};

/**
 * Splits the received array of users by their set Roles
 */
export const splitUsersByRole = (users = []) => {
  const usersWithUserRole = [];
  const usersWithManagerRole = [];
  const usersWithAdminRole = [];

  users.forEach(user => {
    if (hasCompanyManagerRole({ profile: user })) {
      usersWithManagerRole.push(user);
    } else if (hasCompanyAdminRole({ profile: user })) {
      usersWithAdminRole.push(user);
    } else {
      usersWithUserRole.push(user);
    }
  });

  return { usersWithUserRole, usersWithManagerRole, usersWithAdminRole };
};

/**
 * Returns the oldest User with the Company-Admin role from an array of Users
 *
 * @param {object[]} users Array of users
 */
export const getOldestAdminUser = (users = []) => {
  return users
    .filter(u => hasCompanyAdminRole({ profile: u }))
    .sort((a, b) => new Date(a.created) - new Date(b.created))[0];
};

/**
 * @param {object} profile User's profile
 * @returns {boolean}
 */
export const hasVerifiedHomeAddress = profile => {
  return ['yes'].includes(profile?.personalInfor?.homeAddress?.homeAddrUserConf);
};

/**
 * @param {object} profile User's profile
 * @returns {boolean}
 */
export const hasSkippedHomeAddressVerification = profile => {
  return ['skip'].includes(profile?.personalInfor?.homeAddress?.homeAddrUserConf);
};
