import { Space } from 'antd';
import { get, map } from 'lodash';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import {
  createInsuranceCompany,
  deleteInsuranceCompany,
  fetchInsuranceCompanies,
  updateInsuranceCompany,
} from '../../api/insurance-companies';
import { ConfirmModal } from '../../components';
import InsuranceCompanyForm from '../../components/Forms/InsuranceCompanyForm';
import GenericModal from '../../components/Modal/GenericModal';
import Table from '../../components/Table';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import useDebouncedState from '../../hooks/useDebouncedState';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import useModal from '../../hooks/useModal';
import useTableSort from '../../hooks/useTableSort';
import { formatPageTitle, momentTimezone } from '../../utils/common';
import { replaceCurrentPageSearchQueryParams } from '../../utils/queryParams';

const initialFilters = {};

const InsuranceCompaniesView = props => {
  const { t, location, history } = props;

  const filters = useLocationSearchQueryParser(location, initialFilters);
  const { stringTableSort } = useTableSort({ name: 1 });
  const [searchTerm, setSearchTerm] = useDebouncedState(filters.searchTerm || '');

  const [isEditModalVisible, openEditModal, closeEditModal, editModalProps] = useModal();
  const [isDeleteModalVisible, openDeleteModal, closeDeleteModal, deleteModalProps] = useModal();

  const {
    query: insuranceCompaniesQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      queryKey: ['fetchInsuranceCompanies', searchTerm, stringTableSort],
      queryFn: () => {
        replaceCurrentPageSearchQueryParams(history, {
          searchTerm,
          pageSize: paginationConfig.pageSize,
          page: paginationConfig.current,
        });
        return fetchInsuranceCompanies(
          paginationConfig.current,
          paginationConfig.pageSize,
          { name: searchTerm },
          stringTableSort,
        );
      },
    },
    {
      resetPageQueryKey: [filters.searchTerm],
      initialPageSize: filters.pageSize,
      initialPage: filters.page,
    },
  );

  const deleteInsuranceCompanyMutation = useMutation(deleteInsuranceCompany, {
    onError: e => {
      handleApiErrors(e.response, () => {
        Toast({
          type: 'error',
          message: t('deleteInsuranceCompanyError'),
        });
      });
    },
    onSuccess: () => {
      insuranceCompaniesQuery.refetch();
      closeDeleteModal();
      Toast({
        type: 'open',
        message: t('deleteInsuranceCompanySuccess'),
      });
    },
  });

  // TODO: CREATE COMPANY:  AAA (American Automobile Association)

  const createInsuranceCompanyMutation = useMutation(
    values =>
      createInsuranceCompany({
        ...values,
        country: map(values.country, countryCode => ({ name: countryCode })),
      }),
    {
      onError: e => {
        handleApiErrors(e.response, () => {
          Toast({
            type: 'error',
            message: t('createInsuranceCompanyError'),
          });
        });
      },
      onSuccess: () => {
        insuranceCompaniesQuery.refetch();
        closeEditModal();
        Toast({
          type: 'open',
          message: t('createInsuranceCompanySuccess'),
        });
      },
    },
  );

  const updateInsuranceCompanyMutation = useMutation(
    values =>
      updateInsuranceCompany({
        ...values,
        country: map(values.country, countryCode => ({ name: countryCode })),
        companyId: get(editModalProps, 'insuranceCompany._id'),
      }),
    {
      onError: e => {
        handleApiErrors(e.response, () => {
          Toast({
            type: 'error',
            message: t('updateInsuranceCompanyError'),
          });
        });
      },
      onSuccess: () => {
        insuranceCompaniesQuery.refetch();
        closeEditModal();
        Toast({
          type: 'open',
          message: t('updateInsuranceCompanySuccess'),
        });
      },
    },
  );

  const COLUMNS = useMemo(
    () => [
      {
        defaultSortOrder: 'ascend',
        key: 'name',
        title: t('name'),
        dataIndex: 'name',
      },
      {
        key: 'country',
        title: t('countries'),
        dataIndex: 'country',
        render: countries => countries.map(country => country.name).join(', '),
      },
      {
        key: 'created',
        title: t('createdDate'),
        dataIndex: 'created',
        render: date => momentTimezone(date).format('MM-DD-YYYY'),
      },
      {
        key: 'updated',
        title: t('lastUpdated'),
        dataIndex: 'updated',
        render: date => momentTimezone(date).format('MM-DD-YYYY'),
      },
      {
        key: 'action',
        title: '',
        render: data => (
          <Space direction="horizontal" size="large" wrap={false}>
            <LinkText
              onClick={() =>
                openEditModal({ title: t('updateInsuranceCompany'), insuranceCompany: data })
              }
            >
              {t('Edit')}
            </LinkText>
            <LinkText
              onClick={() =>
                openDeleteModal({
                  message: (
                    <Text textAlign="center">
                      <Trans
                        t={t}
                        i18nKey="sureYouWantToDelete__name"
                        values={{ name: get(data, 'name') }}
                        components={[<br />, <b>dummy</b>]}
                      />
                    </Text>
                  ),
                  onOk: () => deleteInsuranceCompanyMutation.mutateAsync(get(data, '_id')),
                  okText: t('Delete'),
                })
              }
            >
              {t('Delete')}
            </LinkText>
          </Space>
        ),
      },
    ],
    [t, openEditModal, openDeleteModal, deleteInsuranceCompanyMutation],
  );

  return (
    <PageContainer
      title={t('insuranceCompanies')}
      pageActionText={t('createCompany')}
      onPageActionClick={() => openEditModal({ title: t('createInsuranceCompany') })}
    >
      <Helmet>
        <title>{formatPageTitle('Insurance Companies')}</title>
      </Helmet>

      <Table
        columns={COLUMNS}
        onSearchTermChange={setSearchTerm}
        defaultSearchTerm={filters?.searchTerm}
        scroll={{ x: 1200 }}
        dataSource={get(insuranceCompaniesQuery, 'data.documents', [])}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
        }}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />

      <ConfirmModal
        {...deleteModalProps}
        loading={deleteInsuranceCompanyMutation.isLoading}
        onCancel={closeDeleteModal}
        visible={isDeleteModalVisible}
      />

      <GenericModal
        {...editModalProps}
        centered
        footer={null}
        visible={isEditModalVisible}
        onCancel={closeEditModal}
        closable={
          !createInsuranceCompanyMutation.isLoading && !updateInsuranceCompanyMutation.isLoading
        }
      >
        <InsuranceCompanyForm
          t={t}
          key={get(editModalProps, 'insuranceCompany._id')}
          isUpdate={!!get(editModalProps, 'insuranceCompany._id')}
          onCancel={closeEditModal}
          loading={
            createInsuranceCompanyMutation.isLoading || updateInsuranceCompanyMutation.isLoading
          }
          disabled={
            createInsuranceCompanyMutation.isLoading || updateInsuranceCompanyMutation.isLoading
          }
          onFinish={
            !!get(editModalProps, 'insuranceCompany._id')
              ? updateInsuranceCompanyMutation.mutateAsync
              : createInsuranceCompanyMutation.mutateAsync
          }
          initialValues={{
            name: get(editModalProps, 'insuranceCompany.name'),
            country: map(get(editModalProps, 'insuranceCompany.country'), country => country.name),
          }}
        />
      </GenericModal>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(InsuranceCompaniesView));
