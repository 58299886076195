import { Col, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useInfiniteAllCompanyUsersQuery from '../../hooks/queries/useInfiniteAllCompanyUsersQuery';
import useDebouncedState from '../../hooks/useDebouncedState';
import { checkIfElementIsScrolledToBottom } from '../../utils/common';
import { StorageUtils } from '../../utils/sessionStorage';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';
import { convertUserListIntoValidSelectOptions } from '../../utils/users';
import Select from '../Select';

const CompanyUsersLookupSelect = props => {
  const {
    t,
    showAllOption,
    groupId,
    disabled,
    value,
    onChange,
    optionsFilter,
    notFoundContent,
    additionalQueryParams,
    companyId,
    ...rest
  } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState('', 500);
  const [initialCount, setInitialCount] = useState();
  const [isLookingForMoreOptions, setIsLookingForMoreOptions] = useState(true);

  const currentCompany = useSelector(selectStoreCurrentCompany);
  const allUsersQuery = useInfiniteAllCompanyUsersQuery(
    companyId || currentCompany._id,
    { groupId, searchTerm, ...(additionalQueryParams || {}) },
    {
      staleTime: Infinity,
      onSuccess: ({ pages }) => {
        if (
          Array.isArray(pages) &&
          typeof pages[0]?.totalCount !== 'undefined' &&
          typeof initialCount === 'undefined'
        ) {
          setInitialCount(pages[0]?.totalCount);
        }
      },
    },
  );

  const flatUserPages = useMemo(() => {
    let optionsArray = [];

    if (Array.isArray(allUsersQuery?.data?.pages)) {
      allUsersQuery.data.pages.forEach(({ users }) => {
        optionsArray = optionsArray.concat(users);
      });
    }

    if (typeof optionsFilter === 'function') {
      optionsArray = optionsArray.filter(optionsFilter);
    }

    return optionsArray;
  }, [allUsersQuery, optionsFilter]);

  const options = useMemo(() => {
    let optionsArray = convertUserListIntoValidSelectOptions(flatUserPages);

    if (showAllOption) {
      optionsArray.unshift({ value: null, label: t('All') });
    }

    return optionsArray;
  }, [t, showAllOption, flatUserPages]);

  const optionsWithoutNullValues = useMemo(() => {
    return options.filter(opt => !!opt.value);
  }, [options]);

  const handleOptionsScroll = useCallback(
    e => {
      const bottom = checkIfElementIsScrolledToBottom(e.target);

      if (bottom && !allUsersQuery.isFetchingNextPage && allUsersQuery.hasNextPage) {
        allUsersQuery.fetchNextPage();
      }
    },
    [allUsersQuery],
  );

  const handleChange = useCallback(
    (value, option) => {
      if (typeof onChange === 'function') {
        const fullSelectedUser = flatUserPages.find(user => user._id === value);
        onChange(value, fullSelectedUser);
        if (typeof option === 'undefined') {
          StorageUtils.lastUserLookupSelection.remove();
        } else {
          StorageUtils.lastUserLookupSelection.set(option);
        }
      }
      setSearchTerm();
    },
    [flatUserPages, setSearchTerm, onChange],
  );

  useEffect(() => {
    if (value) {
      if (!!options?.length) {
        const valueInOptions = !!options.find(option => option.value === value);
        if (!valueInOptions && !allUsersQuery.isFetching && allUsersQuery.hasNextPage) {
          setIsLookingForMoreOptions(true);
          allUsersQuery.fetchNextPage();
        } else if (!allUsersQuery.isFetching && (!allUsersQuery.hasNextPage || valueInOptions)) {
          setIsLookingForMoreOptions(false);
        }
      }
    } else {
      setIsLookingForMoreOptions(false);
    }
  }, [value, options, allUsersQuery]);

  return (
    <Select
      {...rest}
      resetOnNoMatch={false}
      onChange={handleChange}
      value={
        (value && !optionsWithoutNullValues.length) || isLookingForMoreOptions ? undefined : value
      }
      fullWidth
      showSearch
      allowClear={false}
      disabled={disabled || isLookingForMoreOptions}
      loading={allUsersQuery.isFetching}
      options={options}
      onSearch={setSearchTerm}
      placeholder={isLookingForMoreOptions ? t('searching...') : t('user')}
      onPopupScroll={handleOptionsScroll}
      notFoundContent={
        allUsersQuery.isFetching ? (
          <Row justify="center" align="middle">
            <Col>
              <Spin size="default" />
            </Col>
          </Row>
        ) : initialCount === 0 ? (
          /* message to display only if the initial search has not results,
           hence any other search term queries will be return the same */
          notFoundContent
        ) : undefined
      }
    />
  );
};

export default CompanyUsersLookupSelect;
