import { put, retry, takeLatest } from 'redux-saga/effects';

import { handleApiCalls } from '../../api/axiosInstance';
import { USER_REG_STATUS } from '../../enum';
import { saveCompany } from '../actions/common';
import * as actions from '../actions/company';
import { setRegStatus } from '../actions/user';

export function* updateCompanyRegStatusAfterComplete(action) {
  try {
    // retry 3 times, every 3 seconds
    const result = yield retry(
      3,
      3000,
      async data => {
        return handleApiCalls(
          'patch',
          `${process.env.REACT_APP_HOST_API}company/${data.companyID}/update-registration-status`,
          { regStatus: USER_REG_STATUS.SUCCESS },
        );
      },
      action.data,
    );
    yield put(setRegStatus(USER_REG_STATUS.SUCCESS));
    yield put(
      saveCompany({
        regStatus: result.data.data.regStatus,
        regCompleted: result.data.data.regCompleted,
      }),
    );
  } catch (error) {
    console.error(error);
  }
}

export const companySaga = [
  takeLatest(actions.SKIP_INVITE_STEP, updateCompanyRegStatusAfterComplete),
];
