import { createReducer } from '../../utils/storeCreators';
import { UPDATE_DEVICE_WIDTH } from '../actions/browser';
import initialState from '../initialState';

const browserReducer = createReducer({
  initialState: initialState.browser,
  reducer: (state, action) => {
    switch (action.type) {
      case UPDATE_DEVICE_WIDTH:
        return { ...state, ...action.data };
      default:
        return state;
    }
  },
});

export default browserReducer;
