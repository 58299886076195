import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, getUserFullName } from '../../../utils/common';
import {
  renderBooleanCheckmarks,
  sortColumnByBoolean,
  sortColumnByStringField,
} from '../../../utils/tables';
import CustomTable from '../../Table';
import LinkText from '../../Text/LinkText';

const _formatDevice = data => data?.mobileAppInfo?.mobileDevice || '-';
const _formatMobileAppVersion = data => data?.mobileAppInfo?.mobileAppVersion || '-';
const _formatOsVersion = data =>
  data?.mobileAppInfo?.mobileOS && data?.mobileAppInfo?.mobileOSVersion
    ? [data.mobileAppInfo.mobileOS, data.mobileAppInfo.mobileOSVersion].join(' ')
    : '-';

const UserMobileSettingTable = props => {
  const { t, dataSource, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(user => {
        return (
          checkIfStringContainsValue(getUserFullName(user), searchTerm) ||
          checkIfStringContainsValue(user.email, searchTerm) ||
          checkIfStringContainsValue(user.preferredTimezone, searchTerm) ||
          checkIfStringContainsValue(_formatDevice(user), searchTerm) ||
          checkIfStringContainsValue(_formatOsVersion(user), searchTerm) ||
          checkIfStringContainsValue(_formatMobileAppVersion(user), searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      key: 'name',
      title: t('name'),
      render: (actions, data) => {
        return (
          <Link to={`${INTERNAL_LINKS.USER_DETAILS}/${data._id}/trip-recording-preferences`}>
            <LinkText renderAs="span" variant="strong" size="sm">
              {getUserFullName(data)}
            </LinkText>
          </Link>
        );
      },
      sorter: (a, b) => {
        const aFullName = getUserFullName(a);
        const bFullName = getUserFullName(b);

        return aFullName.toLowerCase().localeCompare(bFullName.toLowerCase());
      },
    },
    {
      key: 'email',
      title: t('Email'),
      dataIndex: 'email',
      ellipsis: true,
      ...sortColumnByStringField('email'),
    },
    {
      key: 'timezone',
      title: t('timezone'),
      dataIndex: 'preferredTimezone',
      ...sortColumnByStringField('preferredTimezone'),
    },
    {
      key: 'device',
      title: t('device'),
      render: (actions, data) => _formatDevice(data),
    },
    {
      width: 130,
      key: 'osVersion',
      title: t('osVersion'),
      render: (actions, data) => _formatOsVersion(data),
    },
    {
      width: 130,
      key: 'mobileVersion',
      title: t('mobileVersion'),
      render: (actions, data) => _formatMobileAppVersion(data),
    },
    {
      key: 'autoTracking',
      title: t('autoTracking'),
      ...renderBooleanCheckmarks('autoTracking'),
      ...sortColumnByBoolean('autoTracking'),
    },
  ];

  return (
    <CustomTable
      {...rest}
      onSearchTermChange={setSearchTerm}
      rowKey={data => data._id}
      columns={COLUMNS}
      dataSource={filteredDataSource}
      scroll={{ x: 900 }}
    />
  );
};

export default UserMobileSettingTable;
