import { Form } from 'antd';
import classnames from 'classnames';
import React from 'react';

import Text from '../../Text';
import classNames from './styles.module.scss';

const FormItem = props => {
  const { label, help, errorMessage, children, className, ...rest } = props;

  return (
    <Form.Item
      {...rest}
      className={classnames(classNames.formItem, className, {
        [classNames.noChildren]: !children,
      })}
      label={label}
      labelCol={{ span: 24 }}
      help={errorMessage}
      tooltip={
        help
          ? {
              title: (
                <Text variant="p" size="sm" className={classNames.text}>
                  {help}
                </Text>
              ),
            }
          : undefined
      }
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
