import { get } from 'lodash';

import { getUserFullName } from './common';
import { removeFalseyParams } from './queryParams';
import { hasBackOfficeUserRole, hasSystemAdminRole } from './roles';
import { getEnvironmentName } from './window';

const canTriggerPendoAnalytics = () => {
  return process.env.NODE_ENV === 'production' && typeof window.pendo !== 'undefined';
};

const appendEnvironment = string => {
  return `${getEnvironmentName()}_${string}`;
};

const formatPendoVisitorData = userData => {
  const creationDate = get(userData, 'created');

  return removeFalseyParams({
    id: appendEnvironment(get(userData, '_id', 'VISITOR-UNIQUE-ID')),
    email: get(userData, 'email'),
    full_name: getUserFullName(userData),
    role: get(userData, 'role', 'visitor'),
    creationDate: creationDate ? +new Date(creationDate) : undefined,
    groupName: get(userData, 'group.name'),
    language: 'en_US',
  });
};

const formatPendoAccountData = (userData = {}, companyData = {}, planData = {}) => {
  const isSystemAdmin = hasSystemAdminRole({ profile: userData });
  const isBackOfficeUser = hasBackOfficeUserRole({ profile: userData });

  if (isSystemAdmin) {
    return {
      id: appendEnvironment('kliks-system'),
      name: 'Kliks System',
    };
  } else if (isBackOfficeUser) {
    return {
      id: appendEnvironment('back-office'),
      name: 'Kliks Back Office',
    };
  } else {
    const creationDate = get(companyData, 'created');
    const planPrice = get(planData, 'planId.price');

    return removeFalseyParams({
      id: appendEnvironment(
        get(userData, 'businessInfor.companies[0].companyID', 'ACCOUNT-UNIQUE-ID'),
      ),
      name: get(userData, 'companyName'),
      creationDate: creationDate ? +new Date(creationDate) : undefined,
      is_paying: get(planData, 'isPaidPlanActive'),
      planName: get(planData, 'planId.type'),
      monthly_value: typeof planPrice === 'number' ? `${planPrice / 100}` : undefined,
      planPrice: typeof planPrice === 'number' ? `${planPrice / 100}` : undefined,
    });
  }
};

export const PENDO_ANALYTICS = {
  /**
   * Initialize Pendo Analytics
   * @param {*} userData
   * @param {*} companyData
   * @param {*} planData
   */
  initialize: (userData = {}, companyData = {}, planData = {}) => {
    if (canTriggerPendoAnalytics()) {
      window.pendo.initialize({
        visitor: formatPendoVisitorData(userData),
        account: formatPendoAccountData(userData, companyData, planData),
      });
    }
  },
  /**
   * Update Visitor and Account data
   * @param {*} userData
   * @param {*} companyData
   * @param {*} planData
   */
  update: (userData = {}, companyData = {}, planData = {}) => {
    if (canTriggerPendoAnalytics()) {
      window.pendo.identify({
        visitor: formatPendoVisitorData(userData),
        account: formatPendoAccountData(userData, companyData, planData),
      });
    }
  },
};
