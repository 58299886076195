import { Col, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { USER_API } from '../../../api/user';
import Box from '../../../components/Box';
import DriverEmissionsDetailsTable from '../../../components/Table/DriverEmissionsDetailsTable';
import Text from '../../../components/Text';
import VehicleEmissionsDescriptions from '../../../components/VehicleEmissionsDescriptions';
import PageContainer from '../../../containers/PageContainer';
import withAuthentication from '../../../hocs/withAuthentication';
import useTablePagination from '../../../hooks/useTablePagination';
import useTableSort from '../../../hooks/useTableSort';
import { emitFetchUserVehicles } from '../../../stores/actions/userVehicles';

const UserEmissionsDetailsView = props => {
  const { t, userDetails } = props;

  const dispatch = useDispatch();
  const userVehicles = useSelector(state => state.userVehicles);

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();
  const { handleTableSort, stringTableSort } = useTableSort({ paymentPeriodStart: 1 });
  const userEmissionsQuery = useQuery({
    enabled: !!userDetails?._id,
    queryKey: ['fetchUserEmissions', userDetails?._id, stringTableSort],
    queryFn: () =>
      USER_API.fetchUserEmissions(
        userDetails._id,
        stringTableSort,
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    onSuccess: ({ totalCount }) => handleTotalUpdate(totalCount),
    onError: error => handleApiErrors(error.response),
  });

  const userDefaultVehicle = useMemo(() => {
    if (userVehicles[userDetails._id]?.defaultVehicle) {
      return userVehicles[userDetails._id]?.defaultVehicle;
    }
  }, [userVehicles, userDetails]);

  useEffect(() => {
    dispatch(emitFetchUserVehicles(userDetails?._id));
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          {userDefaultVehicle && (
            <Box>
              <VehicleEmissionsDescriptions
                t={t}
                withExtraFields
                title={t('defaultVehicleInfo')}
                vehicle={userDefaultVehicle}
              />
            </Box>
          )}
        </Col>
        <Col xs={24}>
          <Box>
            <Text variant="h5">{t('emissionsInfo')}</Text>

            <DriverEmissionsDetailsTable
              t={t}
              asyncSort
              loading={userEmissionsQuery.isFetching}
              dataSource={userEmissionsQuery.data?.documents || []}
              pagination={{
                pageSize: paginationConfig.pageSize,
                total: paginationConfig.total,
                current: paginationConfig.current,
                onShowSizeChange: handlePageSizeChange,
              }}
              onChange={({ current }, filters, sorters) => {
                handlePageChange(current);
                handleTableSort(sorters?.columnKey, sorters?.order);
              }}
            />
          </Box>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(UserEmissionsDetailsView));
