import { get } from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const usePrevLocationSearch = () => {
  const history = useHistory();

  const [prevPageSearchParams] = useState(get(history, 'location.state.prevLocationSearch', ''));

  return prevPageSearchParams;
};

export default usePrevLocationSearch;
