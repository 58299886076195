import { List } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../enum';
import { getUserFullName, getUserInitials } from '../../utils/common';
import Avatar from '../Avatar';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import LinkText from '../Text/LinkText';
import classNames from './styles.module.scss';

const RECEIPT_TEXTS = {
  theFollowingItems: 'followingReceiptsContainTripsInMoreInfo',
  redirectLink: INTERNAL_LINKS.RECEIPTS,
  sureYouWantToApproveSelected: 'sureYouWantToApproveSelectedReceipts',
};

const REIMBURSEMENT_TEXTS = {
  theFollowingItems: 'followingReimbursementsContainTripsInMoreInfo',
  redirectLink: INTERNAL_LINKS.REIMBURSEMENT,
  sureYouWantToApproveSelected: 'sureYouWantToApproveSelectedReimbursements',
};

const ReceiptReimbursementMoreInfoTripsApprovalConfirmation = props => {
  const { t, type, items } = props;

  const TEXTS = type === 'receipt' ? RECEIPT_TEXTS : REIMBURSEMENT_TEXTS;

  return (
    <div>
      <Text color="secondary" variant="p" size="sm">
        <Trans i18nKey={TEXTS.theFollowingItems} components={[<b>bold</b>]} />
      </Text>

      <div className={classNames.listWrapper}>
        <List
          itemLayout="horizontal"
          dataSource={items}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    size="small"
                    src={item.submittedBy.profilePicture}
                    shape="circle"
                    alt={getUserFullName(item.submittedBy)}
                    initials={getUserInitials(item.submittedBy)}
                  />
                }
                title={
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`${TEXTS.redirectLink}/${item._id}`}
                  >
                    <LinkText variant="strong">{item.seqId}</LinkText>
                  </Link>
                }
                description={<FadedText size="sm">{getUserFullName(item.submittedBy)}</FadedText>}
              />
            </List.Item>
          )}
        />
      </div>

      <Text color="secondary" variant="p" size="sm">
        {t(TEXTS.sureYouWantToApproveSelected)}
      </Text>
    </div>
  );
};

export default ReceiptReimbursementMoreInfoTripsApprovalConfirmation;
