import React, { useMemo } from 'react';

import TABLE_COLUMNS from '../../../enum/TableColumns';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import { CustomTable } from '../..';
import AmountText from '../../Text/AmountText';

const AnomaliesInsuranceComplianceTable = props => {
  const { t, dataSource, ...rest } = props;

  const COLUMNS = useMemo(
    () => [
      TABLE_COLUMNS.USER_PROFILE_NAME({
        t,
        sorter: true,
        key: ['firstName', 'lastName'].join(','),
        defaultSortOrder: 'ascend',
      }),
      {
        key: 'startDate',
        title: t('startDate'),
        dataIndex: 'startDate',
      },
      TABLE_COLUMNS.STATUS_TAG({ t }),
      {
        key: 'policyExpirationDate',
        title: t('anomalies:policyExpirationDate'),
        dataIndex: 'policyExpirationDate',
      },
      {
        key: 'invalidCode',
        title: t('anomalies:invalidCode'),
        dataIndex: 'invalidCode',
      },
      {
        key: 'lastUpdate',
        title: t('anomalies:lastUpdate'),
        dataIndex: 'lastUpdate',
      },
      {
        key: 'withholding',
        title: t('anomalies:withholding'),
        dataIndex: 'withholding',
        render: withholding => (
          <AmountText variant="b" amount={formatCurrencyToCompanyLocale(withholding)} />
        ),
      },
    ],
    [t],
  );

  return (
    <CustomTable {...rest} columns={COLUMNS} dataSource={dataSource} showSearchInput={false} />
  );
};

export default AnomaliesInsuranceComplianceTable;
