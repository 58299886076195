import { isUndefined } from 'lodash';

import { GROUPS_API } from '../../api/group';
import useInfiniteQuery from './useInfiniteQuery';

/**
 * @param {string} groupId
 * @param {import('react-query').UseQueryOptions?} options
 */
const useInfinitePeriodByGroupQuery = (groupId, options = {}) => {
  const itemsPerPage = 10;

  return useInfiniteQuery(
    {
      ...options,
      staleTime: 1000 * 60 * 5, // 5 minutes
      enabled: !isUndefined(options.enabled) ? options.enabled && !!groupId : !!groupId,
      placeholderData: { pages: [] },
      queryKey: ['fetchPeriodsByGroup', groupId],
      queryFn: ({ pageParam = 1 }) =>
        GROUPS_API.fetchPeriodsByGroup(groupId, pageParam, itemsPerPage),
    },
    'count',
    'periods',
    itemsPerPage,
  );
};

export default useInfinitePeriodByGroupQuery;
