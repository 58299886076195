import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import Dotdotdot from 'react-dotdotdot';

import { STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, momentCompanyFormat } from '../../../utils/common';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import AnonymizedAsterisk from '../../AnonymizedAsterisk';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import LinkText from '../../Text/LinkText';
import CustomTable from '../index';

const DATE_FORMAT = 'MM/DD/YYYY';

/**
 * Table to display the Reimbursement data
 */
const ReimbursementsTable = props => {
  const { t, onReimbursementClick, dataSource, ...rest } = props;
  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(reimbursement => {
        const creationDate = momentCompanyFormat(reimbursement.created, DATE_FORMAT);

        return (
          checkIfStringContainsValue(reimbursement.seqId, searchTerm) ||
          checkIfStringContainsValue(reimbursement.userName, searchTerm) ||
          checkIfStringContainsValue(creationDate, searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const HAS_PAYMENT_PERIOD = dataSource.findIndex(data => data.endOfPeriod !== null) >= 0;

  const COLUMNS = [
    {
      width: 103,
      title: t('ID'),
      dataIndex: 'seqId',
      defaultSortOrder: 'descend',
      key: 'seqId',
      sorter: true,
      render: (actions, data) => (
        <LinkText
          variant="strong"
          size="sm"
          onClick={() => onReimbursementClick(data._id, data.tripReceiptId)}
        >
          {data.seqId || 'Reimbursement'}
        </LinkText>
      ),
    },
    {
      width: 150,
      title: t('name'),
      dataIndex: 'userName',
      key: 'userName',
      sorter: true,
      render: (actions, data) => (
        <ProfileNamePicture
          t={t}
          complianceStatus={get(data, 'complianceStatus')}
          user={{
            _id: data.userId,
            firstName: get(data, 'userName', '-').split(' ')[0],
            lastName: get(data, 'userName', '-').split(' ').slice(1).join(' ') || '',
            status: data.userStatus,
            isAnonymized: data.isUserAnonymized,
          }}
        />
      ),
    },
    {
      width: 150,
      title: t('createdDate'),
      key: 'created',
      dataIndex: 'created',
      render: date => momentCompanyFormat(date, DATE_FORMAT),
      sorter: true,
    },
    {
      width: 150,
      title: t('Group'),
      dataIndex: 'groupName',
      key: 'groupName',
      sorter: true,
      render: (actions, data) => <Dotdotdot clamp={2}>{data.groupName}</Dotdotdot>,
    },
    {
      width: 150,
      title: t('groupManager'),
      dataIndex: 'groupManager',
      key: 'groupManager',
      sorter: true,
      render: (actions, data) => (
        <Row gutter={5} align="middle">
          <Col>
            <Dotdotdot clamp={2}>{data.groupManager}</Dotdotdot>
          </Col>

          {data.isManagerAnonymized && (
            <Col>
              <AnonymizedAsterisk t={t} />
            </Col>
          )}
        </Row>
      ),
    },
    {
      width: 100,
      align: 'right',
      title: t('totalDistance'),
      dataIndex: 'totalDistance',
      key: 'totalDistance',
      sorter: true,
    },
    {
      width: 125,
      align: 'right',
      title: t('totalAmount'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: true,
      render: (actions, data) => {
        return formatNumberWithCurrency(data.totalAmount, data.currency);
      },
    },
    {
      width: 140,
      title: t('status'),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      sorter: true,
      render: (actions, data) => (
        <StatusTag
          status={
            data.paymentStatus === STATUS_LIST().Status.PENDING
              ? STATUS_LIST().Status.AWAITING_APPROVAL
              : data.paymentStatus
          }
        />
      ),
    },
    ...(HAS_PAYMENT_PERIOD
      ? [
          {
            width: 152,
            title: t('startOfPeriod'),
            dataIndex: 'startOfPeriod',
            key: 'startOfPeriod',
            sorter: false,
            render: startOfPeriod => momentCompanyFormat(startOfPeriod, DATE_FORMAT),
          },
        ]
      : []),
    ...(HAS_PAYMENT_PERIOD
      ? [
          {
            width: 152,
            title: t('endOfPeriod'),
            dataIndex: 'endOfPeriod',
            key: 'endOfPeriod',
            sorter: false,
            render: endOfPeriod => momentCompanyFormat(endOfPeriod, DATE_FORMAT),
          },
        ]
      : []),
  ];

  return (
    <CustomTable
      onSearchTermChange={setSearchTerm}
      scroll={{ x: 1300 }}
      {...rest}
      dataSource={filteredDataSource}
      rowKey={record => record._id}
      className="reimbursements-table"
      columns={COLUMNS}
      onRow={row => ({
        leftBorderColor: row.hasDeductedMiles ? 'danger' : '',
      })}
    />
  );
};

export default ReimbursementsTable;
