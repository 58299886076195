import React, { useMemo } from 'react';

import {
  formatNumberToLocale,
  formatNumberWithCurrency,
  formatNumberWithDistanceUnit,
} from '../../../utils/numbers';
import { sortColumnByNumber } from '../../../utils/tables';
import CustomTable from '../../Table';
import Text from '../../Text';
import classNames from './style.module.scss';

const TripCalculationTable = props => {
  const { t, dataSource, commute, distanceUnit, currency, ...rest } = props;

  const DATA = useMemo(() => {
    const rows = [...dataSource];
    if (commute?.commuteDistance) {
      rows.push({
        type: 'commute',
        rateAmount: 0,
        distance: parseFloat(commute.commuteDistance, 10),
      });
    }

    return rows;
  }, [dataSource, commute]);

  const COLUMNS = [
    {
      title: t('type'),
      render: (actions, data) => {
        if (data.type === 'commute') {
          return 'Commute';
        }

        if (typeof data.bandStart !== 'undefined' || typeof data.bandEnd !== 'undefined') {
          return t('tripTierToDistance', {
            bandStart: formatNumberToLocale(data.bandStart, data.bandStart === 0 ? 0 : undefined),
            bandEnd: formatNumberWithDistanceUnit(data.bandEnd, distanceUnit),
          });
        }

        return t('CPM');
      },
    },
    {
      align: 'right',
      title: t('Rate'),
      render: (actions, data) => <div>{formatNumberWithCurrency(data.rateAmount, currency)}</div>,
      ...sortColumnByNumber('rateAmount'),
    },
    {
      align: 'right',
      title: t('Distance'),
      render: (actions, data) => (
        <div>{formatNumberWithDistanceUnit(data.distance, distanceUnit)}</div>
      ),
      ...sortColumnByNumber('distance'),
    },
    {
      align: 'right',
      title: t('Amount'),
      sorter: (a, b) => {
        const amountA = a.distance * a.rateAmount;
        const amountB = b.distance * b.rateAmount;
        return parseFloat(amountA) - parseFloat(amountB);
      },
      render: (actions, data) => (
        <div>{formatNumberWithCurrency(data.distance * data.rateAmount, currency)}</div>
      ),
    },
  ];

  const TableSummary = useMemo(() => {
    const totalDistance = DATA.reduce((prev, current) => prev + current.distance, 0);
    const totalAmount = DATA.reduce((prev, current) => {
      const amount = current.rateAmount * current.distance;
      return amount + prev;
    }, 0);

    return () => (
      <React.Fragment>
        <tr>
          <td />
          <td>
            <Text renderAs="p" variant="strong" size="sm" textAlign="right">
              {t('Total')}:
            </Text>
          </td>
          <td>
            <Text renderAs="p" variant="strong" size="sm" textAlign="right">
              {formatNumberWithDistanceUnit(totalDistance, distanceUnit)}
            </Text>
          </td>
          <td>
            <Text renderAs="p" variant="strong" size="sm" textAlign="right">
              {formatNumberWithCurrency(totalAmount, currency)}
            </Text>
          </td>
        </tr>
      </React.Fragment>
    );
  }, [t, DATA, currency, distanceUnit]);

  return (
    <CustomTable
      {...rest}
      className={classNames.tripCalculationAmount}
      pagination={false}
      showSearchInput={false}
      dataSource={DATA}
      columns={COLUMNS}
      summary={TableSummary}
      rowKey={data => data.rateAmount}
    />
  );
};

TripCalculationTable.defaultProps = {
  dataSource: [],
};

export default TripCalculationTable;
