import USER_ROLE from './UserRoles';

const UserRight = {
  [USER_ROLE.USER]: {
    status: true,
    vacation: true,
    obd: true,
    'obd_connected': true,
    policies: false,
    address: true,
  },
  [USER_ROLE.COMPANY_ADMIN]: {
    status: true,
    vacation: true,
    obd: true,
    'obd_connected': true,
    policies: true,
    address: true,
  },
  [USER_ROLE.COMPANY_MANAGER]: {
    status: true,
    vacation: true,
    obd: false,
    'obd_connected': false,
    policies: false,
    address: true,
  },
  [USER_ROLE.SYSTEM_ADMIN]: {
    status: true,
    vacation: true,
    obd: true,
    'obd_connected': true,
    policies: true,
    address: true,
  },
}

export default UserRight;