import { find } from 'lodash';
import React, { useState } from 'react';

import { formatNumberWithCurrency } from '../../../utils/numbers';
import UserFavrInformationDetailsSection from '../../../views/userdetails/reimbursement-rate/UserFavrInformationDetailsSection';
import { CustomTable } from '../..';

const HistoricalFavrRatesTable = props => {
  const { t, dataSource, isStandardVehicleElectric, ...rest } = props;

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const toggleExpandedRow = row => {
    if (!expandedRowKeys.includes(row._id)) {
      setExpandedRowKeys([row._id]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const COLUMNS = [
    {
      key: '_id',
      title: t('date'),
      dataIndex: '_id',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: t('fixedRate'),
      render: data => {
        const value = find(data?.rates, { type: 'fixed' })?.value;
        const currency = find(data?.rates, { type: 'fixed' })?.currency || 'USD';
        if (typeof value === 'number') return formatNumberWithCurrency(value, currency);
        return '--';
      },
    },
    {
      title: t('variableRate'),
      render: data => {
        const value = find(data?.rates, { type: 'variable' })?.value;
        const currency = find(data?.rates, { type: 'variable' })?.currency;
        if (typeof value === 'number') return formatNumberWithCurrency(value, currency);
        return '--';
      },
    },
  ];

  return (
    <CustomTable
      {...rest}
      asyncSort
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource.map(data => ({
        ...data,
        expandable:
          !!find(data?.rates, { type: 'fixed' })?.details ||
          !!find(data?.rates, { type: 'variable' })?.details,
      }))}
      withExpandableRows
      expandedRowKeys={expandedRowKeys}
      onExpand={(expanded, record) => {
        toggleExpandedRow(record);
      }}
      onRow={row => ({
        onClick: e => {
          e.preventDefault();
          e.stopPropagation();
          toggleExpandedRow(row);
        },
      })}
      expandedRowRender={record => {
        const fixedRate = find(record?.rates, { type: 'fixed' });
        const variableRate = find(record?.rates, { type: 'variable' });
        return (
          <UserFavrInformationDetailsSection
            t={t}
            isStandardVehicleElectric={isStandardVehicleElectric}
            favrInformation={{
              fixedRateDetails: fixedRate?.details,
              fixedRateCurrency: fixedRate?.currency,
              variableRateDetails: variableRate?.details,
              variableRateCurrency: variableRate?.currency,
            }}
          />
        );
      }}
    />
  );
};

export default HistoricalFavrRatesTable;
