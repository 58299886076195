import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import ReactCalendar from 'react-calendar';

import Tooltip from '../Tooltip';
import classNames from './style.module.scss';

// helpers
const Calendar = props => {
  const { selectedDates } = props;

  // const availableColors = [classNames.green, classNames.blue, classNames.yellow, classNames.red];

  const [visibleTooltips, setVisibleTooltips] = useState({});

  const _convertDateIntoString = date => {
    return new Date(
      moment.parseZone(date).year(),
      moment.parseZone(date).month(),
      moment.parseZone(date).date(),
    );
  };

  const [datesArray] = useMemo(() => {
    const array = [];

    selectedDates.forEach(data => {
      array.push(_convertDateIntoString(data.date));
    });

    return [array];
  }, [selectedDates]);

  const getSelectedDate = date => {
    return selectedDates.find(data => {
      return moment(date).isSame(moment(_convertDateIntoString(data.date)), 'date');
    });
  };

  const isDateSelected = date => {
    return datesArray.some(d => moment(d).isSame(date, 'date'));
  };

  const tileClassName = ({ date }) => {
    const classes = [classNames.dayTile];
    // give active days a special class
    const dateIndex = datesArray.findIndex(d => moment(d).isSame(date, 'date'));
    if (dateIndex !== -1) {
      return [
        classNames.activeDay,
        classNames[selectedDates[dateIndex].color || 'green'],
        ...classes,
      ];
    }
    return classes;
  };

  return (
    <div className={classNames.calendarWrapper}>
      <ReactCalendar
        calendarType="US"
        tileClassName={tileClassName}
        defaultActiveStartDate={datesArray[0]}
        tileContent={({ date }) => {
          if (!isDateSelected(date)) return null;

          const dateData = getSelectedDate(date);

          if (!dateData?.description) return null;

          return (
            <Tooltip title={dateData.description} key={date} visible={visibleTooltips[date]}>
              <div
                className={classNames.dot}
                onMouseLeave={() => {
                  setVisibleTooltips(state => ({
                    ...state,
                    [date]: false,
                  }));
                }}
                onMouseEnter={() => {
                  setVisibleTooltips(state => ({
                    ...state,
                    [date]: true,
                  }));
                }}
              />
            </Tooltip>
          );
        }}
      />
    </div>
  );
};

Calendar.defaultProps = {
  selectedDates: [],
};

export default Calendar;
