import i18n from '../i18n';
import { hasCompanyManagerRole, hasUserRole } from './roles';
import { formatPaymentPeriodLabel } from './trip-receipts';

/**
 * Filter function for driver's list in Trips Page
 *
 * @param {object} group - Group data
 * @param {object} loggedInUserProfile Logged in user's profile data
 * @return {boolean} Should include in driver's list?
 */
export const filterGroupList = (group, loggedInUserProfile) => {
  if (hasCompanyManagerRole(loggedInUserProfile)) {
    if (!group.groupManager) return false;
    return (
      group.groupManager._id === loggedInUserProfile.profile._id ||
      group.additionalManagers?.includes(loggedInUserProfile.profile._id)
    );
  }

  return true;
};

/**
 * Formats the Groups List into an array of Options for the Select components
 * and, if requested, filters the array of according to the User's role.
 *
 * @param {object[]} groupList List of Groups from the Company
 * @param {object} userProfile Logged in User's data
 * @param {boolean} filterList Should this filter the list for Managers? It does by default.
 * @return {{ label: string, value: any }[]}
 */
export const convertGroupListIntoValidSelectOptions = (
  groupList,
  userProfile,
  filterList = true,
) => {
  let list = [...groupList];

  if (filterList) {
    list = list.filter(group => filterGroupList(group, userProfile));
  }

  return list
    .map(group => ({ label: group.name, value: group._id }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * Formats the Group's periods into an options array to be passed into a Select component.
 * Options are sorted newest to oldest.
 * @param {object[]} periods
 */
export const formatGroupPeriodsSelectOptions = periods => {
  const futurePeriod = [];
  const currenPeriod = [];
  const pastPeriods = [];

  periods.forEach(period => {
    if (period.start && period.end) {
      let periodType = '';

      switch (period.period) {
        case 'present':
          periodType = '(current)';
          break;
        case 'future':
          periodType = '(future)';
          break;
        case 'past':
        default:
          break;
      }

      let label = formatPaymentPeriodLabel(period);

      label += ` ${periodType}`;
      const value = JSON.stringify({ fromDate: period.start, endDate: period.end });

      const option = { label, value };

      if (period.period === 'present') {
        currenPeriod.push(option);
      } else if (period.period === 'future') {
        futurePeriod.push(option);
      } else {
        pastPeriods.push(option);
      }
    }
  });

  pastPeriods.sort((a, b) => {
    const parsedPeriodA = JSON.parse(a.value);
    const parsedPeriodB = JSON.parse(b.value);
    return parsedPeriodB.fromDate.localeCompare(parsedPeriodA.fromDate);
  });

  return [...futurePeriod, ...currenPeriod, ...pastPeriods];
};

export const formatGroupWithPeriodsToOptions = (groups, loggedInUser) => {
  if (!hasCompanyManagerRole({ profile: loggedInUser })) {
    return groups.map(group => ({
      label: group.name,
      value: group._id,
    }));
  }

  const groupManagerGroup = { label: i18n.t('groupManager'), options: [] };
  const additionalManagerGroup = { label: i18n.t('additionalManager'), options: [] };

  groups.forEach(group => {
    if (group.groupManager === loggedInUser._id) {
      groupManagerGroup.options.push({
        label: group.name,
        value: group._id,
      });
    } else if (group.additionalManagers?.includes(loggedInUser._id)) {
      additionalManagerGroup.options.push({
        label: group.name,
        value: group._id,
      });
    }
  });

  return [
    ...(groupManagerGroup.options.length ? [groupManagerGroup] : []),
    ...(additionalManagerGroup.options.length ? [additionalManagerGroup] : []),
  ];
};

export const setDefaultGroupOptionForUser = (groups = [], loggedInUser) => {
  if (!hasCompanyManagerRole({ profile: loggedInUser }) || hasUserRole({ profile: loggedInUser })) {
    if (groups.length) {
      return groups[0]._id;
    } else {
      return null;
    }
  }

  const groupsAsManager = [];
  const groupsAsAdditionalManager = [];

  groups.forEach(group => {
    if (group.groupManager === loggedInUser._id) {
      groupsAsManager.push(group);
    } else {
      groupsAsAdditionalManager.push(group);
    }
  });

  if (!!groupsAsManager.length) {
    return groupsAsManager[0]._id;
  } else if (!!groupsAsAdditionalManager.length) {
    return groupsAsAdditionalManager[0]._id;
  } else if (!!groups.length) {
    return groups[0]._id;
  }

  return null;
};
