import GeoViewport from '@mapbox/geo-viewport';
import polyline from '@mapbox/polyline';
import { maxBy, minBy } from 'lodash';
import simplifyGeoJson from 'simplify-geojson';

const GOOGLE_DIR_MAP_URL = 'https://www.google.com/maps/dir/?api=1&travelmode=driving';

/**
 * Returns a mapbox static image of a given size based on the provided coordinates
 *
 * @param {{points: { type: string, position: { lat: number, lng: number } }[] }[]} routes Coordinates
 * @param {[number, number]} journeyGeoJsonCooordinates Journey geoJSON coordinates
 * @param {string | number} width Width of map
 * @param {string | number} height Height of Map
 * @param {boolean} retina Should the image be retina compatible
 * @return {string} Mapbox static image
 */
export function getStaticMapImageURL(routes, geoJson, width = 1000, height = 600, retina = true) {
  const mapboxEndpoint = 'https://api.mapbox.com/styles/v1/mapbox/streets-v12/static';
  const retinaParam = retina ? '@2x' : '';
  const mapResolution = [width, height].join('x') + retinaParam;
  const pinCoordinates = [];

  routes.forEach(route => {
    route.points.forEach(p => {
      if (p.type === 'start' || p.type === 'end') {
        const letter = p.type === 'start' ? 'a' : 'b';
        const color = p.type === 'start' ? '16B296' : 'FF6161';
        pinCoordinates.push(`pin-s-${letter}+${color}(${p.position.lng},${p.position.lat})`);
      }
    });
  });

  const formatMapboxUrl = polylineString => {
    return `${mapboxEndpoint}/${pins},path-4+6b99f0-0.9(${polylineString})/auto/${mapResolution}?access_token=${process.env.REACT_APP_MAPBOX_API_TOKEN}`;
  };

  const pins = pinCoordinates.join(',');
  let encodedPolylines = encodeURIComponent(polyline.fromGeoJSON(geoJson));
  let finalUrl = formatMapboxUrl(encodedPolylines);

  if (finalUrl.length > 8192) {
    const simplifiedGeoJson = simplifyGeoJson(geoJson);
    encodedPolylines = encodeURIComponent(polyline.fromGeoJSON(simplifiedGeoJson));
    finalUrl = formatMapboxUrl(encodedPolylines);
  }

  return finalUrl;
}

const getMinOrMax = (markersObj, minOrMax, latOrLng) => {
  if (minOrMax === 'max') {
    return maxBy(markersObj, function (value) {
      return value[latOrLng];
    })[latOrLng];
  } else {
    return minBy(markersObj, function (value) {
      return value[latOrLng];
    })[latOrLng];
  }
};

/**
 * Calculates the mapbox boundaries based on the list of marker coordinates provided
 *
 * @param {{ lat: number, lng: number }[]} markerCoordinates Map markers coordinates array
 * @returns {[number[], number[]]} Min and Max coordinates
 */
export const getMapBoundaries = markerCoordinates => {
  if (
    !markerCoordinates ||
    !Array.isArray(markerCoordinates) ||
    (Array.isArray(markerCoordinates) && markerCoordinates.length <= 1)
  ) {
    return [];
  }

  var maxLat = getMinOrMax(markerCoordinates, 'max', 'lat');
  var minLat = getMinOrMax(markerCoordinates, 'min', 'lat');
  var maxLng = getMinOrMax(markerCoordinates, 'max', 'lng');
  var minLng = getMinOrMax(markerCoordinates, 'min', 'lng');

  var southWest = [minLng, minLat];
  var northEast = [maxLng, maxLat];
  return [southWest, northEast];
};

export const formatCoordinatesToGoogleMapsURL = (long, lat) => {
  return `${GOOGLE_DIR_MAP_URL}&destination=${lat},${long}`;
};

export const formatCoordinatesToGoogleMapsWaypointsURL = (coordinates = []) => {
  const origin = `${coordinates[0][1]},${coordinates[0][0]}`;

  const lasIndex = coordinates.length - 1;
  const destination = `${coordinates[lasIndex][1]},${coordinates[lasIndex][0]}`;

  const waypoints = coordinates.reduce((string, coord, i) => {
    if (i === 0 || i === lasIndex) return string;

    if (i === 1) return `${coord[1]},${coord[0]}`;

    return `${string}|${coord[1]},${coord[0]}`;
  }, '');

  return `${GOOGLE_DIR_MAP_URL}&origin=${origin}&destination=${destination}&waypoints=${waypoints}`;
};

export const getItineraryTripsWaypoints = (trips = []) => {
  const waypoints = [];

  trips.forEach((trip, i) => {
    if (i === 0) {
      waypoints.push(trip.tripStartLocationGeo);
      waypoints.push(trip.tripEndLocationGeo);
    } else {
      waypoints.push(trip.tripEndLocationGeo);
    }
  });

  return waypoints;
};

export function getCenterCoordinates(coordinates = [], dimensions = [500, 500]) {
  if (!coordinates.length) return { center: undefined };
  if (coordinates.length === 1) return { center: coordinates[0] };

  const boundaries = getMapBoundaries(
    coordinates.map(coord => ({
      lat: coord[1],
      lng: coord[0],
    })),
  );

  const centerViewPort = GeoViewport.viewport(
    [
      boundaries[0][0], // west
      boundaries[0][1], // south
      boundaries[1][0], // east
      boundaries[1][1], // north
    ],
    dimensions,
  );

  return {
    center: centerViewPort.center,
    zoom: Math.max(centerViewPort.zoom - 1, 1),
  };
}
