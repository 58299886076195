import { Col, Pagination, Row, Timeline } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  getUserFullName,
  getUserInitials,
  momentFormat,
  setFullscreenMaxHeight,
} from '../../utils/common';
import { selectStoreCurrentAuthUser } from '../../utils/storeSelectors';
import Avatar from '../Avatar';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import TextInput from '../TextInput';
import classNames from './styles.module.scss';

const TripsCommentsTimeline = props => {
  const {
    t,
    isModal,
    comments,
    canAddComment,
    isAddingComment,
    commentInputProps,
    paginationConfig,
    onPageChange,
  } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);

  const stopPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Row
      className={classNames.commentRow}
      style={{ paddingBottom: comments.length > 1 ? 5 : 0 }}
      onClick={stopPropagation}
    >
      <Col flex={1}>
        {paginationConfig && (
          <Row justify="end">
            <Col>
              <Pagination
                current={paginationConfig.current}
                total={paginationConfig.total}
                pageSize={paginationConfig.pageSize}
                onChange={onPageChange}
              />
            </Col>
          </Row>
        )}

        <div
          style={isModal ? { maxHeight: setFullscreenMaxHeight(400) } : undefined}
          className={classnames({
            [classNames.commentTimelineWrapper]: !!isModal,
            [classNames.topCommentTimeline]: !!isModal,
          })}
        >
          <Timeline>
            {comments.map(comment => (
              <Timeline.Item
                key={comment.created}
                style={{ paddingBottom: comments.length > 1 || canAddComment ? undefined : 0 }}
                dot={
                  <Avatar
                    src={comment?.userId?.profilePicture}
                    initials={comment.userId ? getUserInitials(comment.userId) : ''}
                  />
                }
              >
                <Row gutter={30}>
                  {comment.userId && (
                    <Col flex={'150px'} style={{ marginLeft: 10 }}>
                      <Text>{getUserFullName(comment.userId)}</Text>
                    </Col>
                  )}

                  <Col flex={1}>
                    <Text>{comment.comment || comment.note}</Text>
                    <FadedText size="xs" textAlign="right">
                      {momentFormat(comment.created || comment.timestamp, 'LL')}
                    </FadedText>
                  </Col>
                </Row>
              </Timeline.Item>
            ))}
          </Timeline>
        </div>

        {canAddComment && (
          <div className={classnames({ [classNames.commentTimelineWrapper]: !!isModal })}>
            <Timeline>
              <Timeline.Item
                style={{ paddingBottom: 0 }}
                dot={
                  <Avatar
                    src={authUser.profile.profilePicture}
                    initials={getUserInitials(authUser.profile)}
                  />
                }
              >
                <Row gutter={30}>
                  <Col flex={'150px'} style={{ marginLeft: 10 }}>
                    <Text>{getUserFullName(authUser.profile)}</Text>
                  </Col>

                  <Col flex={1}>
                    <TextInput
                      textArea
                      value={commentInputProps?.value}
                      onChange={commentInputProps?.onChange}
                      disabled={isAddingComment}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      placeholder={t('enterComment')}
                    />
                  </Col>
                </Row>
              </Timeline.Item>
            </Timeline>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default TripsCommentsTimeline;
