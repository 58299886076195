import React from 'react';
import PropTypes from 'prop-types';
import { PlusCircleOutlined } from '@ant-design/icons';
// the hoc
import { withNamespaces } from 'react-i18next';

import './style.scss';

class AddButton extends React.Component {
  render() {
    const { className, label, t, onClick } = this.props
    const getClassName = () => `add-button ${className}`

    return (
      <div className={getClassName()} onClick={onClick}>
        <PlusCircleOutlined className="add-button-icon" />
        <span>{`${t(label)}`}</span>
      </div>
    )
  }
}

AddButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
}

AddButton.defaultProps = {
  className: '',
  label: '',
  onClick: () => { }
}

export default withNamespaces()(AddButton);