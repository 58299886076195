import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CARS_API } from '../../api/cars';
import Select from '.';

const VehicleModelSelect = props => {
  const { t, year, make, disabled, ...rest } = props;

  const vehicleModelsQuery = useQuery(['fetchCarModels', year, make], {
    enabled: !!year && !!make && make !== 'other',
    staleTime: Infinity,
    queryFn: () => CARS_API.fetchCarModels(year, make),
  });

  const vehicleModelOptions = useMemo(() => {
    if (Array.isArray(vehicleModelsQuery.data)) {
      return [
        ...vehicleModelsQuery.data.map(model => ({ label: model, value: model })),
        { label: 'Other', value: 'other' },
      ];
    }

    return [{ label: 'Other', value: 'other' }];
  }, [vehicleModelsQuery.data]);

  return (
    <Select
      {...rest}
      showSearch
      disabled={
        disabled ||
        !make ||
        !year ||
        make === 'other' ||
        vehicleModelsQuery.isFetching ||
        !vehicleModelOptions?.length
      }
      loading={vehicleModelsQuery.isFetching}
      placeholder={t('selectModel')}
      options={vehicleModelOptions}
    />
  );
};

export default VehicleModelSelect;
