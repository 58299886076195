import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import './style.scss';

class Notification extends React.Component {

  render() {
    const { type } = this.props
    const className = `notification ${type}`
    return (
      <div className={className}>
        <Avatar className="notification-avatar">
          {type === 'warning' ? '!' : (<CloseOutlined />)}
        </Avatar>
        <span className="notification-text" dangerouslySetInnerHTML={{ __html: this.props.text }} />
        <CloseOutlined className="notification-close" onClick={this.props.onClose} />
      </div>
    )
  }
}

Notification.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  onClose: PropTypes.func,
}

Notification.defaultProps = {
  type: 'warning',
  text: '',
  onClose: () => { }
}

export default Notification;