import { Col, Collapse, Row } from 'antd';
import classnames from 'classnames';
import React, { useState } from 'react';

import StepCircle from '../../../../components/SectionStepWrapper/StepCircle';
import Text from '../../../../components/Text';
import { IMAGES } from '../../../../enum';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import classNames from './styles.module.scss';

const CollapsibleStepBox = props => {
  const { children, stepNumber, stepTitle, completed, onComplete, disabled } = props;

  const [expandedKeys, setExpandedKeys] = useState(completed ? [] : ['0']);

  useDidUpdateEffect(() => {
    if (completed) {
      setExpandedKeys([]);

      if (typeof onComplete === 'function') {
        onComplete();
      }
    }
  }, [completed]);

  return (
    <Collapse
      ghost
      activeKey={expandedKeys}
      onChange={key => setExpandedKeys(key)}
      className={classnames(classNames.collapsibleContainer, {
        [classNames.collapsibleContainerWithArrow]: !completed ? false : !disabled,
      })}
      expandIconPosition="left"
      collapsible={disabled || !completed ? 'disabled' : 'header'}
      expandIcon={({ showArrow, collapsible, isActive }) => {
        if (showArrow && collapsible) {
          return (
            <div
              className={
                isActive
                  ? classnames(classNames.expandArrow, classNames.expanded)
                  : classnames(classNames.expandArrow, classNames.collapsed)
              }
            >
              <img src={IMAGES.GREEN_CHEVRON_DOWN_ICON} alt="expand icon" />
            </div>
          );
        }

        return null;
      }}
    >
      <Collapse.Panel
        key="0"
        showArrow={!completed ? false : !disabled}
        header={
          <Row wrap={false} gutter={8} align="middle">
            <Col>
              {completed ? (
                <img
                  alt="Checkmark"
                  src={IMAGES.CIRCLE_CHECK_MARK_ICON}
                  width="24px"
                  className="scale-in-center"
                />
              ) : (
                <StepCircle step={stepNumber} />
              )}
            </Col>
            <Col flex={1}>
              {typeof stepTitle === 'string' ? (
                <Text variant="b" size="sm">
                  {stepTitle}
                </Text>
              ) : (
                stepTitle
              )}
            </Col>
          </Row>
        }
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapsibleStepBox;
