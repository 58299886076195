import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { handleApiErrors } from '../../../api/axiosInstance';
import { ConfirmModal } from '../../../components';
import Box from '../../../components/Box';
import SimpleEditPopoverForm from '../../../components/Forms/SimpleEditPopoverForm';
import TimezonesTable from '../../../components/Table/TimezonesTable';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';

const TimezonesSection = props => {
  const { t, timezones, onSettingsSave } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteCallback, setDeleteCallback] = useState();

  const handleTimezoneCreate = async (type, timezonesArray, value) => {
    const timezonesNameList = timezonesArray.map(v => v.name);

    if (timezonesNameList.includes(value)) {
      return Toast({
        type: 'error',
        message: t('timezoneAlreadyInList'),
      });
    }

    setIsCreating(true);

    try {
      await onSettingsSave({
        [type]: [...timezonesNameList, value],
      });

      Toast({
        type: 'open',
        message: t('timezoneCreateSuccess'),
      });

      document.querySelector('#hide-simple-form-popover').click();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('timezoneCreateError'),
        });
      });
    }

    setIsCreating(false);
  };

  const handleTimezoneUpdate = async (type, timezonesArray, prevValue, newValue) => {
    const timezonesNameList = timezonesArray.map(v => v.name);

    if (timezonesNameList.includes(newValue)) {
      return Toast({
        type: 'error',
        message: t('timezoneAlreadyInList'),
      });
    }

    setIsUpdating(true);

    try {
      await onSettingsSave({
        [type]: timezonesNameList.filter(v => v !== prevValue).concat([newValue]),
      });

      Toast({
        type: 'open',
        message: t('timezoneUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('timezoneUpdateError'),
        });
      });
    }

    setIsUpdating(false);
  };

  const handleTimezoneDelete = async (type, timezonesArray, value) => {
    setIsDeleting(true);

    const timezonesNameList = timezonesArray.map(v => v.name);

    try {
      await onSettingsSave({
        [type]: timezonesNameList.filter(v => v !== value),
      });
      Toast({
        type: 'open',
        message: t('timezoneDeleteSuccess'),
      });
      setDeleteCallback();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('timezoneDeleteError'),
        });
      });
    }

    setIsDeleting(false);
  };

  const renderAddNew = (title, onSubmit) => {
    return (
      <Row justify="end">
        <Col>
          <SimpleEditPopoverForm
            t={t}
            title={title}
            inputName="name"
            isSubmitting={isCreating}
            onSubmit={onSubmit}
            placement="topLeft"
          >
            <LinkText>{t('Add new')}</LinkText>
          </SimpleEditPopoverForm>
        </Col>
      </Row>
    );
  };

  return (
    <Row id="timezones">
      <Col flex={1}>
        <Box>
          <Text variant="h5">{t('timezones')}</Text>
          <br />
          <TimezonesTable
            t={t}
            isSubmitting={isUpdating}
            loading={!timezones}
            dataSource={timezones}
            popoverTitle={t('editTimezone')}
            onDelete={name =>
              setDeleteCallback({
                callback: () => handleTimezoneDelete('timezones', timezones, name),
              })
            }
            onEdit={({ prevValue, newValue }) =>
              handleTimezoneUpdate('timezones', timezones, prevValue, newValue)
            }
          />
          {renderAddNew(t('addTimezone'), ({ name }) =>
            handleTimezoneCreate('timezones', timezones, name),
          )}
        </Box>
      </Col>

      <ConfirmModal
        loading={isDeleting}
        visible={typeof deleteCallback?.callback === 'function'}
        message={t('sureYouWantToDeleteItem')}
        onCancel={() => setDeleteCallback()}
        okText={t('Delete')}
        onOk={deleteCallback?.callback}
      />
    </Row>
  );
};

export default TimezonesSection;
