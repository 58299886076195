import { Col, Row } from 'antd';
import { get } from 'lodash';
import React from 'react';

import FavrFixedPaymentPerMonthTable from '../../../../components/Table/FavrFixedPaymentPerMonthTable';
import FavrVariablePaymentPerMileTable from '../../../../components/Table/FavrVariablePaymentPerMileTable';
import Text from '../../../../components/Text';

const UserFavrInformationDetailsSection = props => {
  const { t, favrInformation, isStandardVehicleElectric } = props;

  return (
    <section>
      <Row gutter={[16, 16]} justify="space-between">
        {favrInformation?.fixedRateDetails && (
          <Col xs={24} md={12}>
            <Text variant="b">{t('fixedRate_perMonth')}</Text>

            <FavrFixedPaymentPerMonthTable
              t={t}
              useAllowance={favrInformation?.fixedRateUseAllowance}
              currency={favrInformation?.fixedRateCurrency}
              dataSource={[
                {
                  type: 'insurance',
                  amount: get(favrInformation.fixedRateDetails, 'insurance', 0),
                },
                {
                  type: 'depreciation',
                  amount: get(favrInformation.fixedRateDetails, 'depreciation', 0),
                },
                {
                  type: 'taxesAndFees',
                  amount: get(favrInformation.fixedRateDetails, 'taxesAndFees', 0),
                },
              ]}
            />
          </Col>
        )}

        {favrInformation?.variableRateDetails && (
          <Col xs={24} md={12}>
            <Text variant="b">{t('variableRate_perMile')}</Text>

            <FavrVariablePaymentPerMileTable
              t={t}
              useAllowance={favrInformation?.variableRateUseAllowance}
              currency={favrInformation?.variableRateCurrency}
              dataSource={[
                {
                  type: isStandardVehicleElectric ? 'kWh' : 'fuelPerMile',
                  amount: get(favrInformation.variableRateDetails, 'fuelPerMile', 0),
                  pricePerGallon: get(favrInformation.variableRateDetails, 'fuel', 0),
                },
                {
                  type: 'maintenancePerMile',
                  amount: get(favrInformation.variableRateDetails, 'maintenancePerMile', 0),
                },
                {
                  type: 'repairsPerMile',
                  amount: get(favrInformation.variableRateDetails, 'repairsPerMile', 0),
                },
              ]}
            />
          </Col>
        )}
      </Row>
    </section>
  );
};

export default UserFavrInformationDetailsSection;
