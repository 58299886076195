import { Col, Row, Space } from 'antd';
import React, { useCallback, useEffect } from 'react';

import { initializeMeasureOneLink, removeMeasureOneLinkListener } from '../../utils/measureOne';
import Box from '../Box';
import Text from '../Text';

const MeasureOneLinkSection = props => {
  const { dataRequestId, publicToken, onExit, onSuccess, onError, ...rest } = props;

  const onEventEmit = useCallback(
    event => {
      switch (event?.type) {
        case 'exitRequested':
          onExit();
          break;
        case 'datasourceConnected':
        case 'itemsCreated':
          onSuccess();
          break;
        default:
          onError(event);
          break;
      }
    },
    [onExit, onSuccess, onError],
  );

  useEffect(() => {
    if (publicToken) {
      initializeMeasureOneLink(dataRequestId, publicToken, onEventEmit);
    }
    return () => {
      removeMeasureOneLinkListener(onEventEmit);
    };
  }, [dataRequestId, publicToken, onEventEmit]);

  return (
    <div {...rest}>
      <Space direction="vertical" size="large">
        <Space direction="vertical" size="small">
          <Text variant="h4">
            Kliks has partnered with MeasureOne to easily retrieve / process your insurance
            information
          </Text>
          <Text>
            You can provide your insurance information by connecting to your insurance carrier or
            uploading your insurance declaration form. Our partner will then retrieve and send Kliks
            your insurance information.
          </Text>

          <Text>* Upload your insurance document if your insurance carrier is not listed</Text>
        </Space>

        <Row justify="center">
          <Col>
            <Box>
              <m1-link></m1-link>
            </Box>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default MeasureOneLinkSection;
