import { cloneDeep, set } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { handleApiErrors } from '../../api/axiosInstance';
import { USER_API } from '../../api/user';
import Toast from '../../components/Toast';
import i18n from '../../i18n';
import * as actions from '../actions/profile';

export function* skipHomeAddressVerification() {
  const authUser = yield select(state => state.profile);
  try {
    yield call(USER_API.confirmUserHomeAddress.bind(USER_API), authUser.profile._id, 'skip');

    const updatedUser = cloneDeep(authUser.profile);
    set(updatedUser, 'personalInfor.homeAddress.homeAddrUserConf', 'skip');
    yield put(actions.updateProfile(updatedUser));
  } catch (error) {
    handleApiErrors(error.response, () => {
      Toast({
        type: 'error',
        message: i18n.t('errorSkippingHomeAddressVerification'),
      });
    });
  }
}

/**
 * watcher saga
 */
export const profileSaga = [
  takeLatest(actions.SKIP_HOME_ADDRESS_VERIFICAITON, skipHomeAddressVerification),
];
