import { Col, Row } from 'antd';
import React from 'react';

import AbsoluteFooterBox from '../../../../components/AbsoluteFooterBox';
import Button from '../../../../components/Button';
import { canResubmitReceiptForApproval } from '../../receipt-permissions';

const ReceiptFooterUserActionBoxContent = props => {
  const { t, isLoading, loggedInUser, receipt, onResubmitForApprovalFixed } = props;

  const CAN_RESUBMIT_FIXED_RECEIPT_FOR_APPROVAL = canResubmitReceiptForApproval(
    loggedInUser,
    receipt || undefined,
  );

  if (!CAN_RESUBMIT_FIXED_RECEIPT_FOR_APPROVAL) return null;

  return (
    <AbsoluteFooterBox>
      <Row gutter={[16, 16]} justify="end" align="middle">
        <Col>
          <Button onClick={onResubmitForApprovalFixed} loading={isLoading} disabled={isLoading}>
            {t('submitApproval')}
          </Button>
        </Col>
      </Row>
    </AbsoluteFooterBox>
  );
};

export default ReceiptFooterUserActionBoxContent;
