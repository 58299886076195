import { Col, Row } from 'antd';
import _ from 'lodash';
import React from 'react';

import Button from '../Button';

const SubmitCancelButtonGroup = props => {
  const {
    loading,
    disabled,
    submitDisabled,
    justify,
    align,
    submitText,
    cancelText,
    onSubmit,
    onCancel,
    flexWrap,
    isForm,
    hideCancel,
    hideSubmit,
    style,
    size,
  } = props;

  return (
    <Row gutter={17} justify={justify} align={align} style={_.extend(style, { flexWrap })}>
      <Col style={{ overflowY: 'initial', marginBottom: 2 }}>
        {!hideCancel && (
          <Button size={size} disabled={loading || disabled} type="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
      </Col>

      {!hideSubmit && (
        <Col style={{ overflowY: 'initial', marginBottom: 2 }}>
          <Button
            size={size}
            disabled={loading || disabled || submitDisabled}
            loading={loading}
            htmlType={isForm && !submitDisabled ? 'submit' : 'button'}
            type="primary"
            onClick={!isForm ? onSubmit : undefined}
          >
            {submitText}
          </Button>
        </Col>
      )}
    </Row>
  );
};

SubmitCancelButtonGroup.defaultProps = {
  submitText: 'Submit',
  cancelText: 'Cancel',
  flexWrap: undefined,
  isForm: false,
  justify: 'end',
  align: 'middle',
  loading: false,
  hideCancel: false,
  hideSubmit: false,
};

export default SubmitCancelButtonGroup;
