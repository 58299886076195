import { createReducer } from '../../utils/storeCreators';
import { START_FETCHING_INVOICES, UPDATE_INVOICES } from '../actions/invoices';
import initialState from '../initialState';

const invoicesReducer = createReducer({
  initialState: initialState.invoices,
  reducer: (state, action) => {
    let invoices = state;

    switch (action.type) {
      case START_FETCHING_INVOICES:
        invoices.isLoadingInvoices = true;
        return { ...state, ...invoices };
      case UPDATE_INVOICES:
        invoices.listByYear[action.data.year] = action.data.invoices;
        invoices.isLoadingInvoices = false;
        return { ...state, ...invoices };
      default:
        return state;
    }
  },
});

export default invoicesReducer;
