import React from 'react';
import { withNamespaces } from 'react-i18next';

import KliksLayout from '../../layout';
import ShareItineraryDetailsView from '../../views-realtor/share-itinerary-details';

const PublicItineraryDetailsView = props => {
  const { location, ...rest } = props;

  return (
    <KliksLayout {...rest} location={location} withBasicHeader>
      <div style={{ height: '100%', overflow: 'auto' }}>
        <ShareItineraryDetailsView {...props} isPublic />
      </div>
    </KliksLayout>
  );
};

export default withNamespaces()(PublicItineraryDetailsView);
