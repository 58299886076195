import { Form } from 'antd';
import { last } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { EXTERNAL_LINKS } from '../../enum';
import useDebouncedState from '../../hooks/useDebouncedState';
import useFavrVehicleDataByYear from '../../hooks/useFavrVehicleDataByYear';
import useRateForm from '../../hooks/useRateForm';
import { emitUpdateWizardDirty } from '../../stores/actions/rateWizard';
import { ERROR_MESSAGE } from '../../utils/constants';
import { useStoreSelector } from '../../utils/storeSelectors';
import FormItem from '../Form/FormItem';
import Select from '../Select';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import LinkText from '../Text/LinkText';
import TextInput from '../TextInput';

const SelectStandarVehicleSection = props => {
  const { t, formInstance, onNextClick, onBackClick } = props;

  const dispatch = useDispatch();
  const rateWizard = useStoreSelector('rateWizard');
  const { handleFormValuesChange } = useRateForm(formInstance);

  const {
    isLoadingYears,
    isLoadingMakes,
    isLoadingModels,

    availableYears,

    vehicleMakeOptions,
    vehicleModelOptions,
  } = useFavrVehicleDataByYear(
    rateWizard.rateValues.vehicleYear,
    rateWizard.rateValues.vehicleMake,
  );

  const [retentionYears, setRetentionYears] = useDebouncedState(
    rateWizard.rateValues.retentionYears,
  );
  const [annualBusinessMiles, setAnnualBusinessMiles] = useDebouncedState(
    rateWizard.rateValues.annualBusinessMiles,
  );
  const [businessUse, setBusinessUse] = useDebouncedState(rateWizard.rateValues.businessUse);

  useEffect(() => {
    handleFormValuesChange({ retentionYears });
  }, [retentionYears, handleFormValuesChange]);

  useEffect(() => {
    handleFormValuesChange({ annualBusinessMiles });
  }, [annualBusinessMiles, handleFormValuesChange]);

  useEffect(() => {
    handleFormValuesChange({ businessUse });
  }, [businessUse, handleFormValuesChange]);

  return (
    <section>
      <Form
        form={formInstance}
        onValuesChange={() => {
          if (!rateWizard.isDirty) {
            dispatch(emitUpdateWizardDirty(true, true));
          }
        }}
      >
        <Text variant="h4">{t('selectAndConfigStandardVehicle')}</Text>

        <FormItem
          required
          name="vehicleYear"
          label={t('vehicleYear')}
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <Select
            placeholder={t('select')}
            loading={isLoadingYears}
            disabled={!availableYears.length || isLoadingYears || rateWizard.isSubmitting}
            options={availableYears.map(year => ({ label: year, value: year }))}
            onChange={vehicleYear =>
              handleFormValuesChange({
                vehicleYear,
                vehicleMake: undefined,
                vehicleModel: undefined,
                vehicleFuel: undefined,
              })
            }
          />
        </FormItem>

        <FormItem
          required
          name="vehicleMake"
          label={t('vehicleMake')}
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <Select
            placeholder={t('select')}
            loading={isLoadingMakes}
            disabled={!vehicleMakeOptions.length || isLoadingMakes || rateWizard.isSubmitting}
            options={vehicleMakeOptions}
            onChange={vehicleMake =>
              handleFormValuesChange({
                vehicleMake,
                vehicleModel: undefined,
                vehicleFuel: undefined,
              })
            }
          />
        </FormItem>

        <FormItem
          required
          name="vehicleModel"
          label={t('vehicleModel')}
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <Select
            placeholder={t('select')}
            loading={isLoadingModels}
            disabled={!vehicleModelOptions.length || isLoadingModels || rateWizard.isSubmitting}
            options={vehicleModelOptions}
            onChange={(vehicleModel, option) => {
              handleFormValuesChange({
                vehicleModel: vehicleModel,
                vehicleFuel: last(option.key.split('**')),
              });
            }}
          />
        </FormItem>

        <FormItem hidden name="vehicleFuel">
          <TextInput disabled />
        </FormItem>

        <br />

        <FormItem
          required
          name="retentionYears"
          label={t('retentionYears')}
          rules={[
            { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                if (value < 0) {
                  return Promise.reject(ERROR_MESSAGE().MIN_NUMBER(0));
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <TextInput
            disabled={rateWizard.isSubmitting}
            step="any"
            type="number"
            onChange={setRetentionYears}
          />
        </FormItem>

        <FormItem
          required
          name="annualBusinessMiles"
          label={t('expectedAnnualBusinessMiles')}
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <TextInput
            step="any"
            type="number"
            disabled={rateWizard.isSubmitting}
            onChange={setAnnualBusinessMiles}
          />
        </FormItem>

        <FormItem
          name="complianceMinimumAnnualBusinessMiles"
          label={t('complianceMinimumAnnualBusinessMiles')}
        >
          <TextInput disabled step="any" type="number" defaultValue="5000" />
        </FormItem>

        <FormItem
          required
          name="businessUse"
          label={t('businessUse')}
          rules={[
            { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();

                if (value < 45 || value > 75) {
                  return Promise.reject(ERROR_MESSAGE().NUMBER_BETWEEN(45, 75));
                }

                return Promise.resolve();
              },
            },
          ]}
          extra={
            <FadedText size="sm">
              {t('mustBeBetween45%and75%')}{' '}
              <LinkText
                size="sm"
                renderAs="a"
                href={EXTERNAL_LINKS.FRESHDESK_LIMIT_PERCENT_ON_FAVR_RATE}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('why?')}
              </LinkText>
            </FadedText>
          }
        >
          <TextInput
            suffix="%"
            step="any"
            type="number"
            max="75"
            disabled={rateWizard.isSubmitting}
            onChange={setBusinessUse}
          />
        </FormItem>

        <SubmitCancelButtonGroup
          cancelText={t('Back')}
          submitText={t('Next')}
          loading={rateWizard.isSubmitting}
          onCancel={onBackClick}
          onSubmit={onNextClick}
        />
      </Form>
    </section>
  );
};

export default SelectStandarVehicleSection;
