import { Form } from 'antd';
import React, { useEffect, useState } from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import Popover from '../../Popover';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import TextInput from '../../TextInput';
import classNames from './styles.module.scss';

const SimpleEditPopoverForm = props => {
  const {
    t,
    title,
    inputName,
    inputLabel,
    children,
    extendForm,
    initialValues,
    isSubmitting,
    onSubmit,
    ...rest
  } = props;

  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [isVisible]);

  return (
    <Popover
      {...rest}
      destroyTooltipOnHide
      visible={isVisible}
      overlayClassNames={classNames.simplePopoverForm}
      onVisibleChange={visible => setIsVisible(visible)}
      trigger="click"
      title={title}
      content={
        <Form
          form={form}
          labelCol={{ span: 24 }}
          initialValues={initialValues}
          onFinish={isVisible ? onSubmit : undefined}
        >
          <Form.Item
            required
            name={inputName}
            label={inputLabel}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput disabled={isSubmitting} autoComplete="off" />
          </Form.Item>

          {React.Children.map(extendForm, child =>
            React.cloneElement(child, { t, isVisible, form }),
          )}

          <SubmitCancelButtonGroup
            isForm
            size="sm"
            loading={isSubmitting}
            submitText={t('Save')}
            onCancel={() => setIsVisible(false)}
          />

          <button
            id="hide-simple-form-popover"
            style={{ position: 'absolute', visibility: 'hidden' }}
            onClick={() => setIsVisible(false)}
          >
            hide
          </button>
        </Form>
      }
    >
      <div onClick={() => setIsVisible(true)}>{children}</div>
    </Popover>
  );
};

export default SimpleEditPopoverForm;
