import { Upload } from 'antd';
import React, { useState } from 'react';

import { handleApiErrors } from '../../api/axiosInstance';
import { uploadCompanyLogo } from '../../api/company';
import { validateCompanyLogoImageSize } from '../../utils/validate';
import FadedText from '../Text/FadedText';
import Toast from '../Toast';
import classNames from './styles.module.scss';

const CompanyLogoUploader = props => {
  const { t, companyID, onUpload, defaultImage } = props;

  const [imageAddress, setImageAddress] = useState(defaultImage);

  const [uploadError, setUploadError] = useState();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleCompanyLogoPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const handleUpload = async options => {
    setUploadError();
    setUploadSuccess(false);
    setImageAddress();

    const { file } = options;

    validateCompanyLogoImageSize(
      file,
      async () => {
        setIsUploading(true);

        try {
          const response = await uploadCompanyLogo(companyID, file);
          setImageAddress(response.logoImage.filePath);
          setUploadSuccess(true);

          if (typeof onUpload === 'function') {
            onUpload(response.logoImage);
          }

          Toast({
            type: 'open',
            message: t('uploadCompanyLogoSuccess'),
          });
        } catch (error) {
          handleApiErrors(error.response, () => {
            Toast({
              type: 'error',
              message: t('uploadCompanyLogoError'),
            });
          });
          setUploadError(t('uploadCompanyLogoError'));
        }

        setIsUploading(false);
      },
      errorMessage => setUploadError(errorMessage),
    );
  };

  return (
    <Upload
      fileList={[]}
      maxCount={1}
      multiple={false}
      accept="image/*"
      listType="picture-card"
      disabled={isUploading}
      className={classNames.uploader}
      onPreview={handleCompanyLogoPreview}
      customRequest={handleUpload}
      progress={{
        showInfo: false,
        status: uploadError
          ? 'exception'
          : isUploading
          ? 'active'
          : uploadSuccess
          ? 'success'
          : 'normal',
      }}
    >
      {imageAddress ? <img src={imageAddress} alt="logo" /> : <FadedText>{t('upload')}</FadedText>}
    </Upload>
  );
};

export default CompanyLogoUploader;
