import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import { getUserFullName, getUserInitials, momentCompanyFormat } from '../../../utils/common';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import { parseUserComplianceStatus } from '../../../utils/user';
import { calculateTotalAdjustment } from '../../../views/payments/reimbursements/details/reimbursements-details-helpers';
import AnonymizedAsterisk from '../../AnonymizedAsterisk';
import Avatar from '../../Avatar';
import Box from '../../Box';
import OutOfComplianceIcon from '../../OutOfComplianceIcon';
import SummaryCardColumn from '../../shared-ui/SummaryCardColumn';
import Spinner from '../../Spinner';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';

/**
 * Reimbursement Summary card.
 * Display a summary of the reimbursement values and adjustments.
 */
const ReimbursementsSummaryCard = props => {
  const { t, summary, receipts, onAdjustmentView, hasAdjustments } = props;

  const [isOutOfCompliance, outOfComplianceComponents] = useMemo(
    () => parseUserComplianceStatus(get(summary, 'complianceStatus')),
    [summary],
  );

  if (!summary) {
    return (
      <Box hoverable={false}>
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      </Box>
    );
  }

  const DATE_FORMAT = 'MM/DD/YYYY';

  const periodStart = momentCompanyFormat(summary.startOfPeriod || undefined, DATE_FORMAT);
  const periodEnd = momentCompanyFormat(summary.endOfPeriod || undefined, DATE_FORMAT);

  const isReceiptApproved =
    Array.isArray(receipts) &&
    receipts[0] &&
    [STATUS_LIST().Status.APPROVED, STATUS_LIST().Status.PAID].includes(receipts[0].status);

  const totalAdjustment = calculateTotalAdjustment(receipts);
  const totalAmount = summary && isReceiptApproved ? summary.reimbursementAmount : 0;
  const adjustedAmount = parseFloat(totalAdjustment);

  const totalDistance = summary && isReceiptApproved ? summary.totalDistance : 0;

  return (
    <Box hoverable={false}>
      <Row gutter={[10, 10]} justify="space-between" style={{ marginTop: 5 }}>
        <Col xs={24} lg={8} xl={6}>
          <Row
            gutter={6}
            align="middle"
            justify="start"
            style={{ flexWrap: 'nowrap', width: '100%' }}
          >
            <Col flex="32px">
              {isOutOfCompliance ? (
                <OutOfComplianceIcon t={t} outOfComplianceComponents={outOfComplianceComponents} />
              ) : (
                <Avatar
                  size={32}
                  shape="circle"
                  src={summary.submittedBy.userProfilePicture}
                  alt={summary.submittedBy.name}
                  initials={getUserInitials({
                    firstName: summary.submittedBy.firstName.split(' ')[0],
                    lastName: summary.submittedBy.lastName.split(' ')[1],
                  })}
                />
              )}
            </Col>
            <Col>
              <Text
                color="secondary"
                title={summary.submittedBy.name}
                variant="strong"
                size="xl"
                renderAs="div"
              >
                {summary.submittedBy.name}
              </Text>
            </Col>
            {summary.submittedBy.isAnonymized && (
              <Col>
                <AnonymizedAsterisk t={t} />
              </Col>
            )}
            <Col flex="80px">
              <StatusTag size="small" status={summary.submittedBy.status} />
            </Col>
          </Row>

          <Row>
            <Col flex="39px" />
            <Col>
              <Text size="sm" variant="p" renderAs="div">
                {[
                  summary.submittedBy.group.name,
                  summary.submittedBy.group.groupManager
                    ? t('managedBy_name', {
                        name: getUserFullName(summary.submittedBy.group.groupManager),
                      })
                    : '',
                ]
                  .filter(Boolean)
                  .join(', ')}
              </Text>
            </Col>
          </Row>
        </Col>

        {summary.startOfPeriod && summary.endOfPeriod && (
          <Col flex="0 0 200px">
            <SummaryCardColumn title={t('period')} style={{ whiteSpace: 'nowrap' }}>
              {`${periodStart}  - ${periodEnd}`}
            </SummaryCardColumn>
          </Col>
        )}

        <Col>
          <SummaryCardColumn title={t('totalDistance')}>{totalDistance}</SummaryCardColumn>
        </Col>

        <Col>
          <SummaryCardColumn
            title={t('netAdjustments')}
            textClassName={adjustedAmount < 0 ? 'negative-currency' : undefined}
          >
            {formatNumberWithCurrency(
              adjustedAmount,
              summary.reimbursementCurrency,
              undefined,
              'code',
            )}
          </SummaryCardColumn>

          {hasAdjustments && (
            <LinkText
              renderAs="p"
              size="sm"
              style={{ marginTop: 10 }}
              onClick={() => onAdjustmentView(summary)}
            >
              {t('adjustmentHistory')}
            </LinkText>
          )}
        </Col>

        <Col>
          <SummaryCardColumn
            title={t('totalAmount')}
            textClassName={totalAmount < 0 ? 'negative-currency' : undefined}
          >
            {formatNumberWithCurrency(
              parseFloat(totalAmount),
              summary.reimbursementCurrency,
              undefined,
              'code',
            )}
          </SummaryCardColumn>
        </Col>
      </Row>
    </Box>
  );
};

export default ReimbursementsSummaryCard;
