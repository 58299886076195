import { get } from 'lodash';

import { USER_ROLES } from '../enum';

/**
 * Checks if the provided User has any of the roles provided.
 *
 * @param {object} userData User data containing the user's profile
 * @param {string | string[]} role
 * @return {boolean} has role?
 */
export const checkUserRole = (userData, role) => {
  if (!userData) return false;

  if (Array.isArray(role)) {
    return role.includes(get(userData, 'profile.role'));
  }

  return get(userData, 'profile.role') === role;
};

export const hasCompanyAdminRole = userData => {
  return checkUserRole(userData, USER_ROLES.COMPANY_ADMIN);
};

export const hasCompanyManagerRole = userData => {
  return checkUserRole(userData, USER_ROLES.COMPANY_MANAGER);
};

export const hasUserRole = userData => {
  return checkUserRole(userData, USER_ROLES.USER);
};

export const hasSystemAdminRole = userData => {
  return checkUserRole(userData, USER_ROLES.SYSTEM_ADMIN);
};

export const hasCompanyManagerOrAdminRole = userData => {
  return checkUserRole(userData, [USER_ROLES.COMPANY_MANAGER, USER_ROLES.COMPANY_ADMIN]);
};

export const hasBackOfficeUserRole = userData => {
  return checkUserRole(userData, USER_ROLES.BACK_OFFICE_USER);
};
