import React from 'react';
import { withNamespaces } from 'react-i18next';

import Select from '../Select';
import {
  BUSINESS_COMMUTE_TRIP_TYPE,
  BUSINESS_TRIP_TYPE,
  COMMUTE_TRIP_TYPE,
  PERSONAL_TRIP_TYPE,
} from './trip-type-select-constants';
import { canSelectTripType } from './trip-type-select-permissions';

const TripTypeSelect = props => {
  const { t, trip, value, onChange, ...rest } = props;

  return (
    <Select
      {...rest}
      placeholder={t('selectPurpose')}
      value={value}
      onChange={onChange}
      options={[
        {
          disabled: !canSelectTripType(BUSINESS_TRIP_TYPE, value, trip),
          value: BUSINESS_TRIP_TYPE,
          label: t('business'),
        },
        {
          disabled: !canSelectTripType(COMMUTE_TRIP_TYPE, value, trip),
          value: COMMUTE_TRIP_TYPE,
          label: t('commute'),
        },
        {
          disabled: !canSelectTripType(PERSONAL_TRIP_TYPE, value, trip),
          value: PERSONAL_TRIP_TYPE,
          label: t('personal'),
        },
        {
          disabled: true,
          value: BUSINESS_COMMUTE_TRIP_TYPE,
          label: t('business-commute'),
        },
      ]}
    />
  );
};

export default withNamespaces()(TripTypeSelect);
