import React from 'react';

import ConfirmModal from '../ConfirmModal';

const DeleteCountryRateConfirmModal = props => {
  const { t, visible, onCancel, onConfirm, ...rest } = props;

  return (
    <ConfirmModal
      {...rest}
      visible={visible}
      title={t('deleteCountryRate')}
      okText={t('yes')}
      onOk={onConfirm}
      onCancel={onCancel}
      message={
        <div>
          <span>{t('areYouSureDeleteRate')}</span>
          <br />
          <span>{t('notDeleteRateIfCountryWontHaveRate')}</span>
        </div>
      }
    />
  );
};

export default DeleteCountryRateConfirmModal;
