import classnames from 'classnames';
import React from 'react';

import Box from '.';
import classNames from './style.module.scss';

const StickyBox = props => {
  const { children, className, placement, ...rest } = props;

  return (
    <Box {...rest} className={classnames(className, classNames.stickyBox, classNames[placement])}>
      {children}
    </Box>
  );
};

StickyBox.defaultProps = {
  placement: 'bottom',
};

export default StickyBox;
