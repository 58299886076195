import { Col, Row } from 'antd';
import classnames from 'classnames';
import { last } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '../../components';
import StickyBox from '../../components/Box/StickyBox';
import ErrorBoundary from '../../components/ErrorBoundary';
import ItineraryMap from '../../components/ItineraryMap';
import MapLoader from '../../components/MapLoader';
import CreateItineraryModal from '../../components/Modal/CreateItineraryModal';
import OptimizedItinerarySwitch from '../../components/OptimizedItinerarySwitch';
import SpaceSpinner from '../../components/SpaceSpinner';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import PageContainer from '../../containers/PageContainer';
import { IMAGES, INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import { ReactComponent as EditSVG } from '../../images/pencil-write.svg';
import useLayout from '../../layout/useLayout';
import { formatPageTitle, getUserFullName, momentTimezone } from '../../utils/common';
import { getMomentDateWithoutTimezone } from '../../utils/datetime';
import { getItineraryClientData } from '../../utils/itinerary';
import classNames from './styles.module.scss';
import TimelineSection from './TimelineSection';
import useItineraryDetails from './useItineraryDetails';

const ItineraryDetails = props => {
  const { t, history, match } = props;

  const {
    isUpdatingItinerary,
    isOptimalRoute,
    isLoadingItinerary,
    isItineraryModalOpen,
    itineraryDetails,
    itineraryMarkers,
    optimizedItineraryMarkers,
    destinationLabelsChangedOnOptimization,

    openItineraryModal,
    closeItineraryModal,
    toggleOptimalRoute,
    handleUpdateItinerary,
  } = useItineraryDetails({ id: match.params.id });
  const { browserStore, layoutStore, pageMaxHeightStyle } = useLayout({ history });

  const IS_LOADING = isLoadingItinerary || !itineraryDetails;

  const CLIENT = !IS_LOADING ? getItineraryClientData(itineraryDetails?.clientDetails) : undefined;

  const ACTION_BUTTON_ROW = IS_LOADING ? null : (
    <Row align="middle" justify="end" gutter={[24, 32]}>
      <Col>
        <OptimizedItinerarySwitch
          t={t}
          isOptimalRoute={isOptimalRoute}
          itineraryMarkers={itineraryMarkers}
          optimizedItineraryMarkers={optimizedItineraryMarkers}
          onToggleChange={toggleOptimalRoute}
        />
      </Col>

      <Col>
        <Link to={INTERNAL_LINKS.SHARE_ITINERARY(match.params.id)}>
          <Button
            size="sm"
            type="secondary"
            icon={
              <img
                width="20px"
                alt="arrow"
                style={{ marginRight: 5 }}
                src={IMAGES.NAVIGATION_RIGHT_ARROW_ICON}
              />
            }
          >
            {t('Share')}
          </Button>
        </Link>
      </Col>
    </Row>
  );

  const PAGE_HEADER = IS_LOADING ? null : (
    <Row wrap={false} align="middle" justify="space-between" gutter={[8, 8]}>
      <Col>
        <Text variant="b" renderAs="p" style={{ marginBottom: 5 }}>
          {getMomentDateWithoutTimezone(itineraryDetails.dateFrom).format('dddd, MMM DD, YYYY')}
        </Text>
        <Text
          variant={browserStore.isMobile ? 'h3' : 'h2'}
          renderAs="p"
          style={{ marginBottom: 5 }}
        >
          {momentTimezone(itineraryDetails.dateFrom).format('h:mma')} -{' '}
          {momentTimezone(
            isOptimalRoute
              ? last(itineraryDetails.optimizedTrips).tripEndTime
              : last(itineraryDetails.trips).tripEndTime,
          ).format('h:mma z')}
        </Text>

        {CLIENT && (
          <Link to={INTERNAL_LINKS.CLIENT_DETAILS(CLIENT._id)}>
            <LinkText variant="strong" size="xl">
              {getUserFullName(CLIENT)}
            </LinkText>
          </Link>
        )}
      </Col>
      <Col style={{ marginBottom: 2 }}>
        <Button
          size="sm"
          type="secondary"
          icon={<EditSVG width="18px" alt="arrow" style={{ marginRight: 5 }} />}
          onClick={openItineraryModal}
        >
          {t('Edit')}
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <div
        className={classnames(
          classNames.pageRow,
          layoutStore.sidebarCollapsed ? undefined : classNames.globalSidebarOpen,
        )}
      >
        <Helmet>
          <title>{formatPageTitle('Itinerary Details')}</title>
        </Helmet>

        {browserStore.isMobile && !IS_LOADING && (
          <StickyBox placement="top">{PAGE_HEADER}</StickyBox>
        )}

        <div
          className={classNames.contentSection}
          style={browserStore.isMobile ? {} : { height: pageMaxHeightStyle }}
        >
          <PageContainer title={browserStore.isMobile ? null : PAGE_HEADER}>
            {IS_LOADING ? (
              <SpaceSpinner />
            ) : (
              <>
                <TimelineSection
                  t={t}
                  isOptimalRoute={isOptimalRoute}
                  destinationLabelsChangedOnOptimization={destinationLabelsChangedOnOptimization}
                  trips={isOptimalRoute ? optimizedItineraryMarkers : itineraryMarkers}
                />

                {!browserStore.isMobile && ACTION_BUTTON_ROW}
              </>
            )}
          </PageContainer>
        </div>
        <div className={classNames.mapSection}>
          {IS_LOADING ? (
            <MapLoader />
          ) : (
            <ErrorBoundary t={t} fallback={<MapLoader text={t('errorLoadingMap')} />}>
              <ItineraryMap
                t={t}
                isOptimalRoute={isOptimalRoute}
                markers={isOptimalRoute ? optimizedItineraryMarkers : itineraryMarkers}
                trips={isOptimalRoute ? itineraryDetails.trips : itineraryDetails.optimizedTrips}
                routes={
                  isOptimalRoute && Array.isArray(itineraryDetails.optimalRouteCoordinates)
                    ? itineraryDetails.optimalRouteCoordinates
                    : itineraryDetails.routeCoordinates
                }
              />
            </ErrorBoundary>
          )}
        </div>

        {browserStore.isMobile && !IS_LOADING && (
          <StickyBox placement="bottom">{ACTION_BUTTON_ROW}</StickyBox>
        )}
      </div>

      <CreateItineraryModal
        t={t}
        isUpdate
        isSavingItinerary={isUpdatingItinerary}
        initialValues={itineraryDetails}
        visible={isItineraryModalOpen}
        onCancel={closeItineraryModal}
        onSubmit={handleUpdateItinerary}
      />
    </>
  );
};

export default withNamespaces()(withAuthentication(ItineraryDetails));
