import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { handleApiErrors } from '../../../api/axiosInstance';
import Box from '../../../components/Box';
import GlobalPasswordSettingsForm from '../../../components/Forms/GlobalPasswordSettingsForm';
import Text from '../../../components/Text';
import FadedText from '../../../components/Text/FadedText';
import Toast from '../../../components/Toast';

const PasswordSettingSection = props => {
  const { t, globalSettings, onSettingsSave } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async values => {
    setIsSubmitting(true);
    try {
      await onSettingsSave(values);

      Toast({
        type: 'open',
        message: t('passwordSettingsUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('passwordSettingsUpdateError'),
        });
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Row id="passwordSettings">
      <Col flex={1}>
        <Box>
          <Text variant="h5">{t('passwordSettings')}</Text>
          <br />
          <FadedText>{t('preventPasswordReuse')}</FadedText>

          <GlobalPasswordSettingsForm
            t={t}
            isSubmitting={isSubmitting}
            initialValues={globalSettings}
            onSubmit={handleSubmit}
          />
        </Box>
      </Col>
    </Row>
  );
};

export default PasswordSettingSection;
