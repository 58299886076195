import { cloneDeep, merge } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../../api/axiosInstance';
import ReceiptAPI, {
  approveReceipt,
  fetchTripReceipt,
  rejectReceipt,
  rejectTripsInReceipt,
} from '../../../../api/receipt';
import {
  createReceiptReview,
  resubmitReceiptForApproval,
} from '../../../../api/trip-receipt-reviews';
import Toast from '../../../../components/Toast';
import { INTERNAL_LINKS, RECEIPT_TYPES, STATUS_LIST } from '../../../../enum';
import useModal from '../../../../hooks/useModal';
import { replaceValueInArrayByID } from '../../../../utils/common';
import {
  calculateSubmittedTripsTotal,
  calculateTripsSourcePercentage,
  normalizeTripsSchema,
} from '../../../../utils/trips';

const useReceiptDetailsState = ({ t, history, receiptID }) => {
  const companySettings = useSelector(store => store.common.currentCompany.companySettingId);

  const [isLoading, setIsLoading] = useState(false);

  const [receipt, setReceipt] = useState();

  const [receiptTrips, setReceiptTrips] = useState([]);
  const [receiptTripsWithMoreInfo, setReceiptTripsWithMoreInfo] = useState([]);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [receiptAdjustments, setReceiptAdjustments] = useState([]);
  const [tripIdToDeny, setTripIdToDeny] = useState();

  const [isTripDetailsVisible, setIsTripDetailsVisible] = useState(false);
  const [tripMapConfig, setTripMapConfig] = useState({ center: [0, 0], routes: [] });

  const [isAdjustmentHistVisible, setIsAdjustmentHistVisible] = useState(false);
  const [isStatusHistoryVisible, setIsStatusHistoryVisible] = useState(false);
  const [isPaymentStatusHistoryVisible, setIsPaymentStatusHistoryVisible] = useState(false);

  const [isRequestingMoreInfo, setIsRequestingMoreInfo] = useState(false);
  const [isProcessingMoreInfoRequest, setIsProcessingMoreInfoRequest] = useState(false);
  const [selectedTripsMoreInfo, setSelectedTripsMoreInfo] = useState([]);

  const [isApprovalConfirmationVisible, setIsApprovalConfirmationVisible] = useState(false);
  const [isApprovalWithMoreInfoVisible, setIsApprovalWithMoreInfoVisible] = useState(false);
  const [isRejectConfirmationVisible, setIsRejectConfirmationVisible] = useState(false);
  const [isTripRejectConfirmationVisible, setIsTripRejectConfirmationVisible] = useState(false);
  const [isResubmitConfirmationVisible, setIsResubmitConfirmationVisible] = useState(false);

  const [isApprovingReceipt, setIsApprovingReceipt] = useState(false);
  const [isRejectingReceipt, setIsRejectingReceipt] = useState(false);
  const [isRejectingTrip, setIsRejectingTrip] = useState(false);
  const [isResubmittingReceiptsForApproval, setIsResubmittingReceiptsForApproval] = useState(false);

  const [isDeleteReceiptModalVisible, openDeleteReceiptModal, closeDeleteReceiptModal] = useModal();

  useEffect(() => {
    setReceiptTripsWithMoreInfo(takeTripsWithMoreInfo());
    // eslint-disable-next-line
  }, [receiptTrips])

  const takeTripsWithMoreInfo = trips =>
    (trips || receiptTrips).filter(trip => trip.status === STATUS_LIST().Status.MORE_INFO);

  const openApprovalConfirmation = () => {
    if (takeTripsWithMoreInfo().length > 0) {
      setIsApprovalWithMoreInfoVisible(true);
    } else {
      setIsApprovalConfirmationVisible(true);
    }
  };

  const closeApprovalConfirmation = () => {
    setIsApprovalConfirmationVisible(false);
    setIsApprovalWithMoreInfoVisible(false);
  };

  const openRejectionConfirmation = () => setIsRejectConfirmationVisible(true);
  const closeRejectionConfirmation = () => setIsRejectConfirmationVisible(false);

  const openTripRejectionConfirmation = tripID => {
    setTripIdToDeny(tripID);
    setIsTripRejectConfirmationVisible(true);
  };

  const closeTripRejectionConfirmation = () => {
    setTripIdToDeny();
    setIsTripRejectConfirmationVisible(false);
  };

  const openResubmitConfirmation = () => setIsResubmitConfirmationVisible(true);
  const closeResubmitConfirmation = () => setIsResubmitConfirmationVisible(false);

  const handleTripSelection = selectedRowKeys => setSelectedTrips(selectedRowKeys);

  const toggleAdjustmentHistoryModal = () => {
    setIsAdjustmentHistVisible(!isAdjustmentHistVisible);
  };

  const toggleStatusHistoryModal = () => {
    setIsStatusHistoryVisible(!isStatusHistoryVisible);
  };

  const togglePaymentStatusHistoryModal = () => {
    setIsPaymentStatusHistoryVisible(!isPaymentStatusHistoryVisible);
  };

  const toggleRequestMoreInfo = () => {
    setSelectedTripsMoreInfo(isRequestingMoreInfo ? [] : selectedTripsMoreInfo);
    setIsRequestingMoreInfo(!isRequestingMoreInfo);
  };

  const removeTripFromReceipt = tripID => {
    // setReceiptAdjustments(
    //   Array.isArray(receiptData.adjustments) && !!receiptData.adjustments.length
    //     ? receiptData.adjustments.map(adj => ({
    //         ...adj,
    //         receiptSeqId: receiptData.seqId,
    //       }))
    //     : [],
    // );

    const updatedReceipt = cloneDeep(receipt);
    const filteredTrips = updatedReceipt.trips.filter(trip => trip._id !== tripID);
    const updatedTrips = handleTrips(filteredTrips, updatedReceipt.submittedBy);
    updatedReceipt.trips = updatedTrips;

    setReceipt({
      ...updatedReceipt,
      ...calculateSubmittedTripsTotal(updatedTrips),
      ...calculateTripsSourcePercentage(
        updatedTrips,
        [STATUS_LIST().Status.APPROVED, STATUS_LIST().Status.PAID].includes(updatedReceipt.status),
      ),
    });
    setReceiptTrips(updatedTrips);
  };

  const updateSelectedTripsForMoreInfo = tripIds => setSelectedTripsMoreInfo(tripIds);

  const _redirectOnFinish = () => {
    if (companySettings?.settings?.hideReceipts) {
      history.replace(INTERNAL_LINKS.REIMBURSEMENT);
    } else {
      history.replace(INTERNAL_LINKS.RECEIPTS);
    }
  };

  const handleMoreInfoRequest = async (tripReceiptId, tripsIds, comment) => {
    setIsProcessingMoreInfoRequest(true);

    try {
      await createReceiptReview(tripReceiptId, tripsIds, comment);
      Toast({
        type: 'success',
        message: t('moreInfoSubmitSuccess'),
      });
      _redirectOnFinish();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorRequestingMoreInfo'),
        });
      });
    }

    setIsProcessingMoreInfoRequest(false);
  };

  const handleResubmitForApproval = async tripReceiptId => {
    setIsResubmittingReceiptsForApproval(true);

    try {
      await resubmitReceiptForApproval([tripReceiptId]);
      await loadTripReceiptDetail(tripReceiptId);
      closeResubmitConfirmation();
      Toast({
        type: 'success',
        message: t('resubmitTripReceiptForApproval'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorResubmitTripReceiptForApproval'),
        });
      });
    }

    setIsResubmittingReceiptsForApproval(false);
  };

  const handleViewTripMapFullScreen = (trp, activeRoutes, mapCenter) => {
    setIsTripDetailsVisible(true);
    setTripMapConfig({
      tripId: trp._id,
      isManual: !trp.gpsVerified || trp.gpsVerified === 'No',
      toLoc: trp.to_loc,
      fromLoc: trp.from_loc,
      routes: activeRoutes,
      center: mapCenter,
    });
  };

  const handleTripMapClose = () => {
    setIsTripDetailsVisible(false);
    setTripMapConfig({ center: [0, 0], routes: [] });
  };

  const handleTrips = (trips, submittedBy) => {
    return trips.map(trp => {
      return normalizeTripsSchema(trp, submittedBy);
    });
  };

  const updateReceiptData = dataToUpdate => {
    setReceipt(currentReceipt => merge(currentReceipt, dataToUpdate));
  };

  const loadTripReceiptDetail = useCallback(async (id, displayLoader = true) => {
    if (displayLoader) setIsLoading(true);

    try {
      const receiptData = await fetchTripReceipt(id);

      const trips = handleTrips(receiptData.trips, receiptData.submittedBy);

      setReceiptAdjustments(
        Array.isArray(receiptData.adjustments) && !!receiptData.adjustments.length
          ? receiptData.adjustments.map(adj => ({
              ...adj,
              receiptSeqId: receiptData.seqId,
            }))
          : [],
      );

      setReceipt({
        ...receiptData,
        ...calculateSubmittedTripsTotal(trips),
        ...calculateTripsSourcePercentage(
          trips,
          [STATUS_LIST().Status.APPROVED, STATUS_LIST().Status.PAID].includes(receiptData.status),
        ),
        totalAmount: receiptData.totalAmount,
      });

      setReceiptTrips(trips);
    } catch (err) {
      handleApiErrors(err.response);
    }

    if (displayLoader) setIsLoading(false);
  }, []);

  const handleApprove = async (id, approveAction) => {
    setIsApprovingReceipt(true);

    const tripsAwaitingApproval = receiptTrips.filter(
      trip => trip.journeyStatus === STATUS_LIST().Status.SUBMITTED,
    );

    if (approveAction === 'approvePending' && !tripsAwaitingApproval.length) {
      return Toast({
        type: 'error',
        message: t('noTripsAwaitingForApproval'),
      });
    }

    try {
      await approveReceipt([id], approveAction);

      if (receipt.type === RECEIPT_TYPES.FIXED_TYPE) {
        Toast({
          type: 'success',
          message: t('receiptApprovedSuccesfully'),
        });
      } else if (approveAction === 'approveAll') {
        Toast({
          type: 'success',
          message: t('tripsWereApproved', { count: receiptTrips.length }),
        });
      } else {
        Toast({
          type: 'success',
          message: t('tripsWereApproved', {
            count: tripsAwaitingApproval.length,
          }),
        });
      }

      setSelectedTrips([]);
      loadTripReceiptDetail(receiptID);
      closeApprovalConfirmation();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('approveReceiptError'),
        });
      });
    }

    setIsApprovingReceipt(false);
  };

  const handleDeny = async id => {
    setIsRejectingReceipt(true);

    try {
      await rejectReceipt([id]);

      _redirectOnFinish();

      if (receipt.type === RECEIPT_TYPES.FIXED_TYPE) {
        Toast({
          type: 'success',
          message: t('receiptDeniedSuccesfully'),
        });
      } else {
        Toast({
          type: 'success',
          message: t('tripsWereRejected', { count: receiptTrips.length }),
        });
      }
      return;
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorWhileDenyingReceipt'),
        });
      });
    }

    setIsRejectingReceipt(false);
  };

  const handleTripDeny = async () => {
    setIsRejectingTrip(true);

    try {
      await rejectTripsInReceipt(receipt._id, [tripIdToDeny]);
      closeTripRejectionConfirmation();

      if (receiptTrips.length === 1) {
        // If there's only one trip, redirect to list page as it will be deleted
        _redirectOnFinish();
      } else {
        removeTripFromReceipt(tripIdToDeny);
        loadTripReceiptDetail(receipt._id, false);
      }

      Toast({
        type: 'success',
        message: t('tripsWereRejected', { count: 1 }),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorWhileDenyingReceipt'),
        });
      });
    }

    setIsRejectingTrip(false);
  };

  const updateTripLatestComment = useCallback(
    (tripId, commentData) => {
      const updatedTrips = replaceValueInArrayByID(receiptTrips, {
        _id: tripId,
        latestComment: commentData,
      });
      setReceiptTrips(updatedTrips);
    },
    [receiptTrips],
  );

  const deleteReceiptMutation = useMutation({
    mutationFn: () => new ReceiptAPI().deleteReceipt(receiptID),
    onSuccess: () => _redirectOnFinish,
    onError: error => handleApiErrors(error.response),
  });

  useEffect(() => {
    if (receiptID) {
      loadTripReceiptDetail(receiptID);
    }
  }, [loadTripReceiptDetail, receiptID]);

  return {
    // Booleans
    isLoading,
    isApprovingReceipt,
    isRejectingTrip,
    isRejectingReceipt,
    isTripDetailsVisible,
    isAdjustmentHistVisible,
    isStatusHistoryVisible,
    isPaymentStatusHistoryVisible,
    isRequestingMoreInfo,
    isProcessingMoreInfoRequest,
    isResubmittingReceiptsForApproval,
    isApprovalConfirmationVisible,
    isRejectConfirmationVisible,
    isTripRejectConfirmationVisible,
    isApprovalWithMoreInfoVisible,
    isResubmitConfirmationVisible,
    isDeleteReceiptModalVisible,

    // General variables
    receipt,
    receiptTrips,
    receiptTripsWithMoreInfo,
    selectedTrips,
    receiptAdjustments,
    tripMapConfig,
    selectedTripsMoreInfo,

    // Handlers & Togglers
    updateReceiptData,
    handleTripSelection,
    handleViewTripMapFullScreen,
    handleTripMapClose,
    handleMoreInfoRequest,
    handleResubmitForApproval,
    handleApprove,
    handleDeny,
    handleTripDeny,
    toggleAdjustmentHistoryModal,
    toggleStatusHistoryModal,
    togglePaymentStatusHistoryModal,
    toggleRequestMoreInfo,
    openApprovalConfirmation,
    closeApprovalConfirmation,
    openRejectionConfirmation,
    closeRejectionConfirmation,
    openTripRejectionConfirmation,
    closeTripRejectionConfirmation,
    openResubmitConfirmation,
    closeResubmitConfirmation,
    updateSelectedTripsForMoreInfo,
    updateTripLatestComment,
    openDeleteReceiptModal,
    closeDeleteReceiptModal,
    deleteReceiptMutation,
  };
};

export default useReceiptDetailsState;
