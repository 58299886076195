import { getMomentDateWithoutTimezone } from '../../../../utils/datetime';

/**
 * @param {object} values
 * @param {"business" | "personal" | "receive-only"} type
 */
export const formatVerifiedAccountValuesToBE = (values, type = 'business') => {
  return {
    // business and owner
    type,
    businessName: values.businessName,
    businessType: values.businessType,
    firstName: values.businessOwnerFirstName,
    lastName: values.businessOwnerLastName,
    email: values.businessOwnerEmail,
    ein: values.ein,
    businessClassification: values.businessClassification,
    ssn: values.ssn,
    // address
    address1: values.businessAddress1,
    address2: values.businessAddress2,
    city: values.businessCity,
    state: values.businessState,
    dateOfBirth: getMomentDateWithoutTimezone(values.businessDateOfBirth).format('YYYY-MM-DD'),
    postalCode: values.businessPostalCode,
    // controller
    controllerFirstName: values.controllerFirstName,
    controllerLastName: values.controllerLastName,
    controllerTitle: values.controllerTitle,
    controllerDateOfBirth: getMomentDateWithoutTimezone(values.controllerDateOfBirth).format(
      'YYYY-MM-DD',
    ),
    controllerSSN: values.controllerSSN,
    // address
    controllerAddress1: values.controllerAddress1,
    controllerAddress2: values.controllerAddress2,
    controllerCity: values.controllerCity,
    controllerStateProvinceRegion: values.controllerState,
    controllerPostalCode: values.controllerPostalCode,
    controllerCountry: values.controllerCountry || 'US',
  };
};

export const formatBenenificalOwnersToBE = values => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    dateOfBirth: getMomentDateWithoutTimezone(values.dateOfBirth).format('YYYY-MM-DD'),
    ssn: values.ssn,
    // address
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    stateProvinceRegion: values.stateProvinceRegion,
    country: values.country || 'US',
    postalCode: values.postalCode,
  };
};

export const formatControllerInitialValuesFromBE = controllerInfo => {
  return {
    businessName: controllerInfo?.businessName,
    businessType: controllerInfo?.businessType,
    businessClassification: controllerInfo?.businessClassification,
    ein: controllerInfo?.ein,
    businessAddress1: controllerInfo?.address1,
    businessAddress2: controllerInfo?.address2,
    businessState: controllerInfo?.state,
    businessCity: controllerInfo?.city,
    businessPostalCode: controllerInfo?.postalCode,
    businessOwnerFirstName: controllerInfo?.firstName,
    businessOwnerLastName: controllerInfo?.lastName,
    businessOwnerEmail: controllerInfo?.email,
    businessDateOfBirth: getMomentDateWithoutTimezone(controllerInfo?.dateOfBirth, 'YYYY-MM-DD'),
    controllerFirstName: controllerInfo?.controllerFirstName,
    controllerLastName: controllerInfo?.controllerLastName,
    controllerTitle: controllerInfo?.controllerTitle,
    controllerDateOfBirth: getMomentDateWithoutTimezone(
      controllerInfo?.controllerDateOfBirth,
      'YYYY-MM-DD',
    ),
    controllerAddress1: controllerInfo?.controllerAddress1,
    controllerAddress2: controllerInfo?.controllerAddress2,
    controllerState: controllerInfo?.controllerStateProvinceRegion,
    controllerCity: controllerInfo?.controllerCity,
    controllerPostalCode: controllerInfo?.controllerPostalCode,
    controllerCitizenship: controllerInfo?.controllerSSN?.length === 4 ? 'us' : 'non-us',
    controllerSSN: controllerInfo?.controllerSSN,
  };
};
