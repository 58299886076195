import { STATUS_LIST } from '.';

export const FAVR_COMPLIANCES_TYPES = {
  DRIVER_LICENSE: 'driver-license',
  INSURANCE: 'insurance',
};

export const FAVR_COMPLIANCE_STATUS_OPTIONS = () => [
  {
    value: STATUS_LIST().Status.VALID,
    label: STATUS_LIST().StatusTagColorConfig[STATUS_LIST().Status.VALID].label,
  },
  {
    value: STATUS_LIST().Status.INVALID,
    label: STATUS_LIST().StatusTagColorConfig[STATUS_LIST().Status.INVALID].label,
  },
];

export const FAVR_COMPLIANCES_REASONS = {
  CREATE_COMPLIANCE: 'create-compliance',
  UPDATE_DOCUMENT_INFORMATION: 'update-document-information',
  UPDATE_COMPLIANCE_STATUS: 'update-compliance-status',
};
