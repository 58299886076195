import { Col, DatePicker, Form, Row, Spin } from 'antd';
import classnames from 'classnames';
import { get, isNull, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Checkbox from '../../../components/Checkbox';
import HorizontalLineDivider from '../../../components/HorizontalLineDivider';
import Text from '../../../components/Text';
import FadedText from '../../../components/Text/FadedText';
import LinkText from '../../../components/Text/LinkText';
import UserGroupsLookupSelect from '../../../components/UserGroupsLookupSelect';
import { getUserFullName } from '../../../utils/common';
import { selectStoreCurrentAuthUser } from '../../../utils/storeSelectors';
import classNames from './styles.module.scss';

const KliksInsightsSideMenu = props => {
  const {
    t,
    visible,
    isLoading,
    isLoadingMore,
    userInsights,
    eventInsights,
    checkedUsers,
    checkedEvents,
    onUserCheckChange,
    onEventCheckChange,
    selectedGroup,
    filterFormValues,
    onFilterFormValuesChange,
    onGroupChange,
    onMapCenterChange,
  } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);

  const handleToggleSelectAllUsers = useCallback(
    () => onUserCheckChange(userInsights.map(user => user._id)),
    // eslint-disable-next-line
    [userInsights],
  );

  const handleToggleSelectAllEvents = useCallback(
    () => onEventCheckChange(eventInsights.map(event => event._id)),
    // eslint-disable-next-line
    [eventInsights],
  );

  const USERS_SECTION =
    userInsights.length > 0 ? (
      <div className={classNames.section}>
        <Row>
          <Col>
            <Text variant="h5">{t('Users')}</Text>
          </Col>
        </Row>

        <Row gutter={5} align="middle" className={classNames.checkboxRow}>
          <Col>
            <Checkbox
              value="all"
              disabled={isLoading}
              checked={checkedUsers.length === userInsights.length}
              onChange={handleToggleSelectAllUsers}
            />
          </Col>
          <Col>
            <FadedText renderAs="span" size="sm">
              {t('All')}
            </FadedText>
          </Col>
        </Row>

        {userInsights.map(user => {
          const lng = get(user, 'lastLocation.longitude', get(user, 'lastLocation.long'));
          const lat = get(user, 'lastLocation.latitude', get(user, 'lastLocation.lat'));

          return (
            <Row gutter={5} align="middle" key={user._id} className={classNames.subCheckboxRow}>
              <Col>
                <Checkbox
                  disabled={!user.lastLocation || isUndefined(lng) || isUndefined(lat) || isLoading}
                  value={user._id}
                  checked={checkedUsers.includes(user._id)}
                  onChange={() => onUserCheckChange(user._id)}
                />
              </Col>
              <Col>
                {!isLoading &&
                checkedUsers.includes(user._id) &&
                user.lastLocation &&
                !isUndefined(lng) &&
                !isUndefined(lat) ? (
                  <LinkText size="sm" onClick={() => onMapCenterChange([lng, lat])}>
                    {getUserFullName(user)}
                  </LinkText>
                ) : (
                  <Text renderAs="span" size="sm" color="faded">
                    {getUserFullName(user)}
                  </Text>
                )}
              </Col>
            </Row>
          );
        })}
      </div>
    ) : null;

  const EVENTS_SECTION =
    eventInsights.length > 0 ? (
      <div className={classNames.section}>
        <Row>
          <Col>
            <Text variant="h5">
              {t('crm')} / {t('others')}
            </Text>
          </Col>
        </Row>

        <Row gutter={5} align="middle" className={classNames.checkboxRow}>
          <Col>
            <Checkbox
              value="all"
              disabled={isLoading}
              checked={checkedEvents.length === eventInsights.length}
              onChange={handleToggleSelectAllEvents}
            />
          </Col>
          <Col>
            <FadedText size="sm">{t('All')}</FadedText>
          </Col>
        </Row>

        {eventInsights.map(event => (
          <Row gutter={5} align="middle" key={event._id} className={classNames.subCheckboxRow}>
            <Col>
              <Checkbox
                value={event._id}
                disabled={isLoading}
                checked={checkedEvents.includes(event._id)}
                onChange={() => onEventCheckChange(event._id)}
              />
            </Col>
            <Col>
              {!isLoading && checkedEvents.includes(event._id) && event.locationCoordinates ? (
                <LinkText
                  size="sm"
                  onClick={() =>
                    onMapCenterChange([
                      event.locationCoordinates.longitude,
                      event.locationCoordinates.latitude,
                    ])
                  }
                >
                  {event.subject}
                </LinkText>
              ) : (
                <Text size="sm" color={!event.locationCoordinates ? 'red' : 'faded'}>
                  {event.subject}
                </Text>
              )}
            </Col>
          </Row>
        ))}
      </div>
    ) : null;

  const SECTION_SPLITTER =
    userInsights.length > 0 && eventInsights.length > 0 ? (
      <HorizontalLineDivider marginTop={20} marginBottom={20} />
    ) : null;

  return (
    <div
      className={classnames(classNames.klikInsightSideMenu, {
        [classNames.hiddenContent]: !visible,
      })}
    >
      <Form labelCol={{ span: 24 }}>
        <Row>
          <Col>
            <Text variant="h5">{t('Filters')}</Text>
          </Col>
        </Row>

        <Form.Item label={t('date')}>
          <DatePicker
            disabled={isLoading}
            allowClear={false}
            defaultValue={moment(filterFormValues.date)}
            style={{ width: '100%' }}
            onChange={momentDate =>
              onFilterFormValuesChange({ date: momentDate.toISOString(true) })
            }
          />
        </Form.Item>

        <UserGroupsLookupSelect
          t={t}
          showAllOption
          allowClear={false}
          userId={authUser?.profile?._id}
          value={isNull(filterFormValues?.groupId) ? filterFormValues?.groupId : selectedGroup}
          defaultValue={filterFormValues?.groupId}
          disabled={isLoading}
          onChange={groupId => {
            if (groupId) {
              onGroupChange(groupId);
            }

            onFilterFormValuesChange({ groupId });
          }}
        />

        {USERS_SECTION}
        {SECTION_SPLITTER}
        {EVENTS_SECTION}
      </Form>

      {isLoadingMore && (
        <Row justify="center" align="middle" gutter={16} wrap={false}>
          <Col>
            <Spin size="default" />
          </Col>
          <Col>
            <FadedText>{t('loadingMore')}...</FadedText>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default KliksInsightsSideMenu;
