import React from 'react';

import Box from '../Box';
import classNames from './style.module.scss';

const AbsoluteFooterBox = props => {
  const { children, ...rest } = props;

  if (!children) return null;

  return (
    <Box className={classNames.stickyBox} padding="20px 74px" {...rest}>
      {children}
    </Box>
  );
};

export default AbsoluteFooterBox;
