import { Col, Row } from 'antd';
import React from 'react';

import OverdueReimbursementAlert from '../../components/Alert/OverdueReimbursementAlert';
import PageContainer from '../../containers/PageContainer';

const ReimbursementPageContainer = props => {
  const {
    t,
    canViewReimbursementAlert,
    companySettings,
    overdueCount,
    onOverdueAlertClick,
    children,
    ...rest
  } = props;

  return (
    <PageContainer
      {...rest}
      title={
        <Row gutter={[16, 16]} align="middle">
          <Col>
            {t(companySettings?.settings?.tripApprovalWorkflow ? 'Reimbursements' : 'taxVault')}
          </Col>

          {canViewReimbursementAlert && (
            <Col>
              <OverdueReimbursementAlert
                t={t}
                overdueCount={overdueCount}
                onClick={onOverdueAlertClick}
              />
            </Col>
          )}
        </Row>
      }
    >
      {children}
    </PageContainer>
  );
};

export default ReimbursementPageContainer;
