import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { handleApiErrors } from '../../api/axiosInstance';
import { deleteCompany } from '../../api/company';
import { Button } from '../../components';
import Box from '../../components/Box';
import CompanyDeleteConfirmationModal from '../../components/Modal/CompanyDeleteConfirmationModal';
import Toast from '../../components/Toast';
import PageContainerWithFadedLeftNavigation from '../../containers/PageContainerWithFadedLeftNavigation';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useModal from '../../hooks/useModal';
import { logoutUser } from '../../utils/auth';
import { hasPremiumPlan } from '../../utils/plans';
import {
  selectStoreCompanySettings,
  selectStoreCurrentCompany,
  selectStoreCurrentPlan,
} from '../../utils/storeSelectors';
import AchConfigView from './ach-config';
import AchTransactionsView from './ach-config/ach-transactions';
import AchVerificationView from './ach-config/ach-verification';
import BankAccountInformationView from './ach-config/bank-account-information';
import CompanyApiKeysView from './api-keys';
import CompanySettings from './company-settings';
import CompanyInfoPageForm from './info-form';
import CompanyPayrollFile from './payroll-file';

const Company = props => {
  const { t } = props;
  const planData = useSelector(selectStoreCurrentPlan);
  const companySettings = useSelector(selectStoreCompanySettings);
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const [isDeleteCompanyModalVisible, openDeleteCompanyModal, closeDeleteCompanyModal] = useModal();

  const deleteCompanyMutation = useMutation({
    mutationFn: async ({ text }) => deleteCompany(currentCompany._id, text),
    onSuccess: async () => {
      Toast({
        type: 'success',
        message: t('companyHasBeenDeleted', { name: currentCompany.name }),
      });
      await logoutUser();
    },
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('deleteCompanyError'),
        });
      });
    },
  });

  return (
    <PageContainerWithFadedLeftNavigation
      pageContainerProps={{ title: t('Company') }}
      navRoutes={[
        {
          to: INTERNAL_LINKS.COMPANY,
          label: t('companyInfo'),
        },
        {
          to: INTERNAL_LINKS.COMPANY_SETTINGS,
          label: t('Settings'),
        },
        {
          hidden: !hasPremiumPlan(planData),
          to: INTERNAL_LINKS.COMPANY_PAYROLL_FILE,
          label: t('payrollFile'),
        },
        {
          hidden: !hasPremiumPlan(planData),
          to: INTERNAL_LINKS.COMPANY_API_KEYS,
          label: t('apiKeys'),
        },
        {
          hidden: !companySettings.ach_enabled,
          to: INTERNAL_LINKS.ACH_CONFIG,
          label: t('achConfig'),
        },
      ]}
      sideActionComponent={
        <Button
          size="sm"
          disabled={deleteCompanyMutation.isLoading}
          loading={deleteCompanyMutation.isLoading}
          onClick={openDeleteCompanyModal}
        >
          {t('deleteCompany')}
        </Button>
      }
    >
      <Route
        exact
        path={INTERNAL_LINKS.COMPANY_SETTINGS}
        render={routeProps => (
          <Box>
            <CompanySettings {...routeProps} {...props} />
          </Box>
        )}
      />
      <Route
        exact
        path={INTERNAL_LINKS.COMPANY}
        render={routeProps => (
          <Box>
            <CompanyInfoPageForm {...routeProps} {...props} />
          </Box>
        )}
      />
      <Route
        exact
        path={INTERNAL_LINKS.COMPANY_PAYROLL_FILE}
        render={routeProps => (
          <Box>
            <CompanyPayrollFile {...routeProps} {...props} />
          </Box>
        )}
      />
      <Route
        exact
        path={INTERNAL_LINKS.COMPANY_API_KEYS}
        render={routeProps => (
          <Box>
            <CompanyApiKeysView {...routeProps} {...props} />
          </Box>
        )}
      />
      <Route exact path={INTERNAL_LINKS.ACH_CONFIG} component={AchConfigView} />
      <Route
        exact
        path={INTERNAL_LINKS.ACH_CUSTOMER_VERIFICATION}
        component={AchVerificationView}
      />
      <Route
        exact
        path={INTERNAL_LINKS.BANK_ACCOUNT_INFORMATION}
        component={BankAccountInformationView}
      />
      <Route exact path={INTERNAL_LINKS.ACH_TRANSACTIONS} component={AchTransactionsView} />

      <CompanyDeleteConfirmationModal
        t={t}
        visible={isDeleteCompanyModalVisible}
        isDeletingCompany={deleteCompanyMutation.isLoading}
        onDelete={deleteCompanyMutation.mutateAsync}
        onCancel={closeDeleteCompanyModal}
      />
    </PageContainerWithFadedLeftNavigation>
  );
};

export default withNamespaces()(withAuthentication(Company));
