import { Col, Form, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { AddressForm } from '../../../../components';
import PhoneTextInput from '../../../../components/PhoneTextInput';
import SubmitCancelButtonGroup from '../../../../components/SubmitCancelButtonGroup';
import Text from '../../../../components/Text';
import TextInput from '../../../../components/TextInput';
import { ERROR_MESSAGE } from '../../../../utils/constants';
import { selectStoreCurrentCompany, useStoreSelector } from '../../../../utils/storeSelectors';

const InvoiceMethodForm = props => {
  const { t, initialValues, isSubmitting, onSubmit, needsConfirmation } = props;

  const { supportedCountries } = useSelector(state => state.common);
  const currentCompany = useStoreSelector(selectStoreCurrentCompany);

  const [form] = Form.useForm();

  return (
    <div>
      <Form form={form} labelCol={{ span: 24 }} initialValues={initialValues}>
        <Row gutter={17}>
          <Col xs={24} md={12}>
            <Form.Item
              required
              name="firstName"
              label={t('firstName')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput autoComplete="given-name" disabled={isSubmitting} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              required
              name="lastName"
              label={t('lastName')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput autoComplete="family-name" disabled={isSubmitting} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              required
              name="email"
              label={t('email')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput type="email" autoComplete="email" disabled={isSubmitting} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              required
              name="phone"
              label={t('phone')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <PhoneTextInput
                disabled={isSubmitting}
                defaultValue={initialValues.phone}
                onChange={phone => form.setFieldsValue({ phone })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <AddressForm
        t={t}
        formInstance={form}
        formDisabled={isSubmitting}
        companyAddress={currentCompany?.address}
        countries={supportedCountries}
        onValueChange={() => undefined}
        values={initialValues}
        fieldNames={{
          streetOne: 'line1',
          streetTwo: 'line2',
          city: 'city',
          state: 'state',
          country: 'country',
          postalCode: 'postal_code',
        }}
      />

      <Row justify="end" align="middle" gutter={[16, 16]} wrap={false}>
        {needsConfirmation && (
          <Col>
            <Text variant="p" size="sm">
              {t('pleaseConfirmBillingInfo')}
            </Text>
          </Col>
        )}
        <Col>
          <SubmitCancelButtonGroup
            onSubmit={() => form.validateFields().then(onSubmit)}
            hideCancel={!!initialValues}
            loading={isSubmitting}
            submitText={t(needsConfirmation ? 'Confirm' : 'Update')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceMethodForm;
