import { Tabs } from 'antd';
import { useFlags } from 'flagsmith/react';
import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { handleApiErrors } from '../../../api/axiosInstance';
import {
  cancelUserDataRequest,
  createHardUserDataDeleteRequest,
  fetchUserDataActions,
} from '../../../api/userDataActions';
import Box from '../../../components/Box';
import TypeFullNameConfirmationModal from '../../../components/Modal/TypeFullNameConfirmationModal';
import TabsContainer from '../../../components/TabsContainer';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import { FLAGSMITH_KEYS } from '../../../enum/Flagsmith';
import { formatPageTitle } from '../../../utils/common';
import AnonymizeDataTab from './AnonymizeDataTab';
import DownloadDataTab from './DownloadDataTab';

const ANONYMIZE_VIEW = 'anonymize';
const DOWNLOAD_VIEW = 'download';

const UserProfileDataView = props => {
  const { t, userDetails, currentCompany, history, location } = props;

  const { activeTab } = queryString.parse(location.search.replace('?', ''));

  const [isLoadingRequests, setIsLoadingRequests] = useState(false);

  const [anonymizeRequests, setAnonymizeRequests] = useState();
  const [downloadRequets, setDownloadRequets] = useState();

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [activeView, setActiveView] = useState(activeTab || DOWNLOAD_VIEW);
  const featureFlags = useFlags([FLAGSMITH_KEYS.KI_3981_REMOVE_USER_ACCOUNT_REQUEST]);

  const handleTabChange = tabKey => {
    setActiveView(tabKey);
    history.replace({ search: `activeTab=${tabKey}` });
  };

  const loadAnonymizeRequests = async () => {
    setIsLoadingRequests(true);
    try {
      const data = await fetchUserDataActions({
        type: ['anonymize', 'download', 'soft-delete'],
        subtype: 'gdpr',
      });

      const anonymize = [];
      const downloads = [];

      data.forEach(r => {
        if (r.requestingUser._id === userDetails._id) {
          if (r.requestType === 'download') downloads.push(r);
          if (r.requestType === 'anonymize') anonymize.push(r);
        }
      });

      setAnonymizeRequests(anonymize);
      setDownloadRequets(downloads);
      setIsLoadingRequests(false);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorLoadingSubmittedRequests'),
        });
      });
    }
  };

  const handleAnonymizeRequest = async values => {
    try {
      await createHardUserDataDeleteRequest(
        values.firstName,
        values.lastName,
        [userDetails._id],
        false,
      );
      await loadAnonymizeRequests();
      history.push({ search: `activeTab=${ANONYMIZE_VIEW}&subTab=status` });
      Toast({
        type: 'success',
        message: t('anonymizeRequestInProgress'),
        description: t('requestSubmittedCompanyAdminNeedsToApproveRequest'),
        duration: null,
      });
      setIsConfirmationModalVisible(false);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorAnonymizeRequest'),
        });
      });
    }
  };

  const handleCancelRequest = async id => {
    setIsLoadingRequests(true);

    try {
      const data = await cancelUserDataRequest(id);

      const requestIndex = anonymizeRequests.findIndex(r => r._id === id);
      const updatedRequests = [...anonymizeRequests];
      updatedRequests[requestIndex] = { ...updatedRequests[requestIndex], ...data };
      setAnonymizeRequests(updatedRequests);

      Toast({
        type: 'success',
        message: t('requestCancelled'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorCancellingRequest'),
        });
      });
    }

    setIsLoadingRequests(false);
  };

  useEffect(() => {
    loadAnonymizeRequests();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{formatPageTitle('Your Data')}</title>
      </Helmet>

      <Box>
        <Text variant="h5">{t('accessYourInformation')}</Text>

        <Text size="sm">{t('hereIsListOfKliksInfoToDownload')}</Text>
        <br />
        <Text size="sm">{t('youMayAlsoRequestAllYourData')}</Text>
        <br />
        <Text size="sm">{t('allYourPersonalDataWillBeDeleted')}</Text>
        <br />

        <TabsContainer activeKey={activeView} onChange={handleTabChange}>
          <Tabs.TabPane key={DOWNLOAD_VIEW} tab={t('downloadData')}>
            <div style={{ margin: '0 2px' }}>
              <DownloadDataTab
                t={t}
                loggedInUserID={userDetails._id}
                location={location}
                history={history}
                downloadRequets={downloadRequets}
                onDownload={loadAnonymizeRequests}
                currentCompany={currentCompany}
              />
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane
            key={ANONYMIZE_VIEW}
            tab={t(
              featureFlags[FLAGSMITH_KEYS.KI_3981_REMOVE_USER_ACCOUNT_REQUEST]?.enabled
                ? 'anonymizeData/removeAccount'
                : 'anonymizeData',
            )}
          >
            <div style={{ margin: '0 2px' }}>
              <AnonymizeDataTab
                t={t}
                loggedInUserID={userDetails._id}
                loading={isLoadingRequests || !anonymizeRequests}
                anonymizeRequests={anonymizeRequests}
                location={location}
                history={history}
                showRemoveAccount={
                  featureFlags[FLAGSMITH_KEYS.KI_3981_REMOVE_USER_ACCOUNT_REQUEST]?.enabled
                }
                onCancelRequest={handleCancelRequest}
                onSubmitRequest={() => {
                  setIsConfirmationModalVisible(true);
                }}
              />
            </div>
          </Tabs.TabPane>
        </TabsContainer>
      </Box>

      <TypeFullNameConfirmationModal
        t={t}
        visible={isConfirmationModalVisible}
        onCancel={() => setIsConfirmationModalVisible(false)}
        onSubmit={handleAnonymizeRequest}
        firstNameToMatch={userDetails.firstName}
        lastNameToMatch={userDetails.lastName}
        additionalContent={<Text size="sm">{t('ifApprovedCannotUseKliks')}</Text>}
      />
    </React.Fragment>
  );
};

export default UserProfileDataView;
