import { Row } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS } from '../../enum';
import useRealtorContacts from '../../hooks/useRealtorContacts';
import { checkIfStringContainsValue } from '../../utils/common';
import Button from '../Button';
import Select from '../Select';

const RealtorContactLookupInput = props => {
  const {
    t,
    disabled,
    contactsToRemove,
    hideAddNewClient,
    onAddNewClient,
    allowMultiple,
    ...rest
  } = props;

  const { isFetchingContacts, contactOptions } = useRealtorContacts(contactsToRemove);

  const ADD_NEW_CLIENT_BUTTON = useMemo(() => {
    if (hideAddNewClient) return undefined;

    const linkProps =
      typeof onAddNewClient === 'function' ? { to: '#' } : { to: INTERNAL_LINKS.CLIENT };

    return (
      <Row style={{ padding: '8px 0' }}>
        <Link {...linkProps} style={{ display: 'block', width: '100%' }}>
          <Button
            block
            size="xs"
            type="secondary"
            onClick={onAddNewClient}
            icon={
              <img
                alt="plus"
                width="18px"
                src={IMAGES.GREEN_PLUS_ICON}
                style={{ marginRight: 5 }}
              />
            }
          >
            {t('addNewClient')}
          </Button>
        </Link>
      </Row>
    );
    // eslint-disable-next-line
  }, [onAddNewClient, hideAddNewClient]);

  return (
    <Select
      {...rest}
      fullWidth
      showSearch
      mode={allowMultiple ? 'multiple' : undefined}
      allowClear={false}
      disabled={disabled || isFetchingContacts}
      loading={isFetchingContacts}
      options={contactOptions}
      notFoundContent={ADD_NEW_CLIENT_BUTTON}
      placeholder={t('typeClientNameOrEmail')}
      filterOption={(input, option) => {
        const fullOption = contactOptions.find(contact => contact.value === option.value);
        return (
          checkIfStringContainsValue(fullOption?.email, input) ||
          checkIfStringContainsValue(fullOption.label, input)
        );
      }}
    />
  );
};

export default RealtorContactLookupInput;
