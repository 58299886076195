import flagsmith from 'flagsmith';
import { get } from 'lodash';
import storage from 'store';

import { REDUX_STORE } from '../App';
import Toast from '../components/Toast';
import { INTERNAL_LINKS } from '../enum';
import i18n from '../i18n';
import { emitNavigateTo } from '../stores/actions/router';
import { openRequestConfirmationEmailResendToast } from '../utils/auth';
import { PENDO_ANALYTICS } from '../utils/pendoAnalytics';
import { hasCompanyManagerOrAdminRole, hasUserRole } from '../utils/roles';
import { handleApiCalls, handleApiErrors } from './axiosInstance';
import { getCompanyInfo } from './company';
import { fetchCurrentUserDetails } from './user';

export const resendConfirmationEmail = async email => {
  const url = `${process.env.REACT_APP_HOST_API}auth/confirm-email-resend`;
  await handleApiCalls('post', url, { email });
};

/**
 * Performs an API call to resend the activation email to a given User
 *
 * @param {email} email Email of user to send the activation email
 * @param {Function=} onSuccess On Success callback
 * @param {Function=} onError On Error callback
 * @return {Promise<any>} API Result
 */
export const resendActivationEmail = async (email, onSuccess, onError) => {
  try {
    const result = await handleApiCalls(
      'post',
      `${process.env.REACT_APP_HOST_API}auth/resend-email`,
      { email },
    );

    if ((result.status === 200 || result.data.success) && typeof onSuccess === 'function') {
      onSuccess(Object.values(result.data.data));
    }
  } catch (error) {
    handleApiErrors(error.response, () => {
      Toast({
        type: 'error',
        message: i18n.t('resendActivationEmailError'),
      });
    });

    if (typeof onError === 'function') onError(error);
  }
};

/**
 * Performs an API call to the Signin endpoint
 *
 * @param {string} email User email
 * @param {string} password User password
 */
export const signInUser = async (email, password) => {
  try {
    let result = await handleApiCalls(
      'post',
      `${process.env.REACT_APP_HOST_API}auth/signin`,
      { email, password },
      { 'x-version': '2.0.0' },
    );

    storage.set('accessToken', result.data.accessToken);
    storage.set('refreshToken', result.data.refreshToken);

    const userData = await fetchCurrentUserDetails();

    const isAdminMangerOrUser =
      hasCompanyManagerOrAdminRole({ profile: userData }) || hasUserRole({ profile: userData });

    const company = isAdminMangerOrUser ? await getCompanyInfo(userData) : undefined;

    return {
      company,
      user: userData,
    };
  } catch (error) {
    if (error.response) {
      if (error.response.data && typeof error.response.data.message === 'string') {
        if (error.response.data.sendEmail) {
          return openRequestConfirmationEmailResendToast(email, resendConfirmationEmail);
        }

        if (/account_not_activated/gi.test(error.response.data.error_code)) {
          return openRequestConfirmationEmailResendToast(email, resendConfirmationEmail);
        } else if (/next attempt/gi.test(error.response.data.message)) {
          return { nextAttemptLock: true };
        } else if (/locked/gi.test(error.response.data.message)) {
          const errorMessage = error.response.data.message;
          return {
            accountLocked: true,
            message: errorMessage.replace(/Forgot Password./gi, ' '),
          };
        } else if (/invalid/gi.test(error.response.data.message)) {
          return Toast({
            type: 'error',
            message: i18n.t('loginError'),
            description: i18n.t('invalidCredentials'),
          });
        } else if (/inactivated/gi.test(error.response.data.message)) {
          return Toast({
            type: 'error',
            message: i18n.t('loginError'),
            description: i18n.t('inactiveAccountLoginError'),
          });
        } else if (/account_deactivated/gi.test(error.response.data.error_code)) {
          return Toast({
            type: 'error',
            message: i18n.t('loginError'),
            description: i18n.t('deactivatedLoginError'),
          });
        } else if (/account_deleted/gi.test(error.response.data.error_code)) {
          return Toast({
            type: 'error',
            message: i18n.t('loginError'),
            description: i18n.t('deletedAccountLoginError'),
          });
        }
      }
    }

    return Toast({
      type: 'error',
      message: i18n.t('loginError'),
      description: i18n.t('invalidCredentials'),
    });
  }
};

/**
 * Performs an API call to register a new user and company
 */

export const registerUser = async values => {
  await handleApiCalls('post', `${process.env.REACT_APP_HOST_API}auth/register`, {
    ...values,
    remember: true,
  });
};

/**
 * Fetches the user information based on the current Access Token
 */
export const getUserFromToken = async () => {
  return handleApiCalls('post', `${process.env.REACT_APP_HOST_API}auth/token/user`, {});
};

/**
 * Impersonates a given user by replacing the current Access Token with a new one representing the impersonated User.
 * The previous access token is also stored to allow users to return to their previous user when impersonation is stopped.
 *
 * @param {string} id ID of User to impersonate
 */
export const impersonateUser = async id => {
  const url = `${process.env.REACT_APP_HOST_API}auth/impersonate`;

  let result;

  try {
    result = await handleApiCalls('post', url, { id });

    storage.set('previousAccessToken', storage.get('accessToken'));
    storage.set('previousRefreshToken', storage.get('refreshToken'));

    storage.set('accessToken', result.data.accessToken);
    storage.set('refreshToken', result.data.refreshToken);
  } catch (error) {
    Toast({
      type: 'error',
      message: get(error, 'response.data.errors.id.msg', 'Unable to impersonate User'),
    });
  }

  return result;
};

/**
 * Stops impersonation and returns the previous access token to be the main one.
 * Also fetches the previous user data again
 */
export const stopImpersonatingUser = async () => {
  let userResult;
  storage.set('accessToken', storage.get('previousAccessToken'));
  storage.set('refreshToken', storage.get('previousRefreshToken'));

  try {
    userResult = await fetchCurrentUserDetails();
    PENDO_ANALYTICS.update(userResult);
  } catch (error) {
    REDUX_STORE.dispatch(emitNavigateTo(INTERNAL_LINKS.LOGIN));
    Toast({
      type: 'error',
      message: i18n.t('fetchPrevtUserDetailsError'),
    });
  }

  storage.remove('previousAccessToken');
  storage.remove('previousRefreshToken');

  if (typeof window.flagsmith?.logout === 'function') {
    setTimeout(() => {
      flagsmith.logout();
    }, 500);
  }

  return userResult;
};

/**
 * Check if an email address is available for usage on new accounts
 *
 * @param {string} email Email to check
 */
export const checkEmailAvailability = async email => {
  const result = await handleApiCalls(
    'get',
    `${process.env.REACT_APP_HOST_API}auth/checkEmail?email=${encodeURIComponent(email)}`,
  );

  return result.data.validEmail;
};

export const AUTH_API = {
  /**
   * @param {object} params
   * @param {string} params.currentPassword
   * @param {string} params.password
   * @param {string} params.confirmPassword
   * @param {string} params.userId
   */
  changePassword: async ({ currentPassword, password, confirmPassword, userId }) => {
    const url = `${process.env.REACT_APP_HOST_API}auth/change-password`;
    await handleApiCalls('post', url, {
      currentPassword,
      password,
      password_repeat: confirmPassword,
      userId,
    });
  },
  generateTempAuthToken: async userId => {
    const url = `${process.env.REACT_APP_HOST_API}auth/temporary-token`;
    const response = await handleApiCalls('post', url, { userId });
    return response.data;
  },
};
