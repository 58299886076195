import './style.scss';

import Toast from 'components/Toast';
import moment from 'moment-timezone';
import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import { handleApiCalls, handleApiErrors } from '../../api/axiosInstance';
import { fetchCompanyPayrollAudits } from '../../api/company';
import PayrollDownloadUserModal from '../../components/Modal/PayrollDownloadUserModal';
import CommonTableFilters from '../../components/shared-ui/CommonTableFilters';
import PayrollFileTable from '../../components/Table/PayrollFileTable';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle, momentFormat } from '../../utils/common';

const PayrollFileHistory = ({ t, currentCompany, ...props }) => {
  const { searchTerm, startDateRange, endDateRange } = queryString.parse(
    props.location.search.replace('?', ''),
  );

  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(
    startDateRange ? moment(startDateRange) : moment().subtract(1, 'month'),
  );
  const [toDate, setToDate] = useState(endDateRange ? moment(endDateRange) : moment());
  const [downloadHistory, setDownloadHistory] = useState([]);
  const [isPaid, setIsPaid] = useState(false);
  const [filterTable, setFilterTable] = useState(searchTerm || null);

  const [payrollDownloaders, setPayrollDownloaders] = useState([]);
  const [isPayrollModalVisible, setIsPayrollModalVisible] = useState(false);

  useEffect(() => {
    loadPaymentFileHistory();
    // eslint-disable-next-line
  }, [isPaid, fromDate, toDate, filterTable]);

  const loadPaymentFileHistory = async id => {
    setLoading(true);

    const params = {
      fromDate: momentFormat(fromDate, 'YYYY-MM-DD'),
      toDate: momentFormat(toDate, 'YYYY-MM-DD'),
      searchTerm: filterTable,
    };

    try {
      const payrolls = await fetchCompanyPayrollAudits(currentCompany._id, params);

      let resultWithKey = payrolls.map((res, index) => {
        res.key = index + 1;
        return res;
      });
      setDownloadHistory(resultWithKey);
    } catch (error) {
      handleApiErrors(error.response);
    }

    setLoading(false);
  };

  const onChangeDateRange = dates => {
    if (dates[0]) {
      setFromDate(dates[0]);
    }
    if (dates[1]) {
      setToDate(dates[1]);
    }
  };

  const handleDownload = async token => {
    setLoading(true);
    try {
      setLoading(true);
      const result = await handleApiCalls(
        'get',
        `${process.env.REACT_APP_HOST_API}payroll-audits/download/${token}`,
      );

      if (result.status === 200) {
        let data = result.data;
        let filename = result.headers['content-disposition'].split('filename=')[1];
        filename = String(filename.replace(/^"|"$/g, ''));

        await downloadPayrollFile(data, filename);
        await loadPaymentFileHistory();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const search = value => {
    const filteredData = downloadHistory.filter(o =>
      Object.keys(o).some(k => String(o[k]).toLowerCase().includes(value.toLowerCase())),
    );

    setFilterTable(filteredData);
  };

  const downloadPayrollFile = async (response, filename) => {
    let url = window.URL.createObjectURL(new Blob([response]));
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();

    Toast({
      type: 'info',
      message: t('downloadStarted'),
    });
  };

  return (
    <PageContainer title={'Payroll File'} className="payroll-file-history pfh">
      <Helmet>
        <title>{formatPageTitle('Payroll Files')}</title>
      </Helmet>

      <CommonTableFilters
        disabledFilters={loading}
        hiddenFilters={['user', 'group', 'type', 'status', 'crmMatch']}
        dateRangeValue={[fromDate, toDate]}
        onDateRangeChange={onChangeDateRange}
        statusOptions={[
          { label: t('Not Paid'), value: false },
          { label: t('Paid'), value: true },
        ]}
        onStatusChange={status => setIsPaid(status)}
        onSearchTermChange={term => search(term)}
      />

      <div className="pfh-container">
        <PayrollFileTable
          t={t}
          loading={loading}
          companyCountry={currentCompany.address.country}
          onDownload={handleDownload}
          dataSource={filterTable === null ? downloadHistory : filterTable}
          onViewDownloadHistory={downloadHistory => {
            setPayrollDownloaders(downloadHistory);
            setIsPayrollModalVisible(true);
          }}
        />
      </div>

      <PayrollDownloadUserModal
        t={t}
        title={t('downloadHistory')}
        onCancel={() => setIsPayrollModalVisible(false)}
        visible={isPayrollModalVisible}
        dataSource={payrollDownloaders}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentCompany: state.common.currentCompany,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(withAuthentication(PayrollFileHistory)));
