import { getMomentDateWithoutTimezone } from './datetime';

export const normalizeUserInsurancePolicy = policy => {
  if (!policy) return null;

  const { insuredAddress, insuranceCoverages } = policy;

  const companyNotInList =
    !!policy.insurancePolicyId &&
    !!policy.insurancePolicyName &&
    policy.insurancePolicyId === policy.insurancePolicyName;

  return {
    _id: policy._id,
    status: policy.status,
    outOfCompliance: policy.outOfCompliance,
    effectiveStartDate: getMomentDateWithoutTimezone(policy.effectiveStartDate),
    effectiveEndDate: getMomentDateWithoutTimezone(policy.effectiveEndDate),
    insurancePolicyName: companyNotInList ? 'other' : policy.insurancePolicyId,
    otherInsurancePolicyName: companyNotInList ? policy.insurancePolicyName : undefined,
    policyDocs: policy.policyDocs,
    // Address
    streetOne: insuredAddress?.streetOne,
    streetTwo: insuredAddress?.streetTwo,
    city: insuredAddress?.city,
    state: insuredAddress?.state,
    postalCode: insuredAddress?.postalCode,
    country: insuredAddress?.country || 'US',
    // Coverages
    bodilyInjuryToOthers_person: insuranceCoverages?.bodilyInjuryToOthers?.person,
    bodilyInjuryToOthers_accident: insuranceCoverages?.bodilyInjuryToOthers?.accident,
    damageToProperty_person: insuranceCoverages?.damageToProperty?.person,
    damageToProperty_accident: insuranceCoverages?.damageToProperty?.accident,
    personalInjuryProtection_person: insuranceCoverages?.personalInjuryProtection?.person,
    personalInjuryProtection_accident: insuranceCoverages?.personalInjuryProtection?.accident,
    bodilyInjuryByUninsuredAuto_person: insuranceCoverages?.bodilyInjuryByUninsuredAuto?.person,
    bodilyInjuryByUninsuredAuto_accident: insuranceCoverages?.bodilyInjuryByUninsuredAuto?.accident,
    optionalBodilyInjuryToOthers_person: insuranceCoverages?.optionalBodilyInjuryToOthers?.person,
    optionalBodilyInjuryToOthers_accident:
      insuranceCoverages?.optionalBodilyInjuryToOthers?.accident,
    medicalPayments_person: insuranceCoverages?.medicalPayments?.person,
    medicalPayments_accident: insuranceCoverages?.medicalPayments?.accident,
    collision_person: insuranceCoverages?.collision?.person,
    collision_accident: insuranceCoverages?.collision?.accident,
    comprehensive_person: insuranceCoverages?.comprehensive?.person,
    comprehensive_accident: insuranceCoverages?.comprehensive?.accident,
    bodilyInjuryByUnderinsuredAuto_person:
      insuranceCoverages?.bodilyInjuryByUnderinsuredAuto?.person,
    bodilyInjuryByUnderinsuredAuto_accident:
      insuranceCoverages?.bodilyInjuryByUnderinsuredAuto?.accident,
  };
};

export const setupPolicyDocumentsUploadList = list => {
  if (!Array.isArray(list)) return [];

  return list
    .map(image => ({
      uid: image.fileKey,
      name: image.filename,
      status: 'done',
      timestamp: image.timestamp,
    }))
    .sort((a, b) => {
      if (!a.timestamp || !b.timestamp) return 1;
      return new Date(b.timestamp) - new Date(a.timestamp);
    });
};
