import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { RECEIPT_TYPES } from '../../../enum';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import Box from '../../Box';
import SummaryCardColumn from '../../shared-ui/SummaryCardColumn';
import Spinner from '../../Spinner';
import LinkText from '../../Text/LinkText';
import TripSourcePercentSummaryBar from '../../TripSourcePercentSummaryBar';
import TripSourceTrackIconsRow from '../../TripSourceTrackIconsRow';
import ReceiptBankTransferSummary from '../ReceiptBankTransferSummary';
import ReceiptUserColumn from './ReceiptSummaryCardUserColumn';

/**
 * Display a summary of a Receipt
 */
const ReceiptSummaryCard = props => {
  const { t, loading, receipt, onAdjustmentView, onRetry, hasAdjustments } = props;

  if (!receipt || loading) {
    return (
      <Box hoverable={false}>
        <div style={{ textAlign: 'center' }}>
          <Spinner />
        </div>
      </Box>
    );
  }

  if (receipt.type === RECEIPT_TYPES.FIXED_TYPE) {
    return (
      <Box hoverable={false}>
        <Row gutter={[50, 20]} style={{ marginTop: 5 }}>
          <ReceiptUserColumn t={t} receipt={receipt} />

          <Col>
            <SummaryCardColumn title={t('totalAmount')}>
              {formatNumberWithCurrency(receipt.totalAmount, receipt.currency, undefined, 'code')}
            </SummaryCardColumn>
          </Col>

          <Col>
            <SummaryCardColumn title={t('paymentType')}>{t('fixed')}</SummaryCardColumn>
          </Col>
        </Row>

        <ReceiptBankTransferSummary
          t={t}
          receipt={receipt}
          onRetry={onRetry}
          style={{ marginTop: 20 }}
        />
      </Box>
    );
  }

  return (
    <Box hoverable={false}>
      <Row gutter={[10, 10]} justify="space-between" style={{ marginTop: 5 }}>
        <ReceiptUserColumn t={t} receipt={receipt} />

        <Col>
          <SummaryCardColumn title={t('totalAmount')}>
            {formatNumberWithCurrency(receipt.totalAmount, receipt.currency, undefined, 'code')}
          </SummaryCardColumn>

          {hasAdjustments && (
            <LinkText renderAs="p" size="sm" style={{ marginTop: 10 }} onClick={onAdjustmentView}>
              {t('adjustmentHistory')}
            </LinkText>
          )}
        </Col>

        <Col>
          <SummaryCardColumn title={t('trips')}>{receipt?.trips?.length || 0}</SummaryCardColumn>
        </Col>

        <Col>
          <SummaryCardColumn title={t('totalDistance')}>{receipt.totalDistance}</SummaryCardColumn>
        </Col>

        <Col flex="0 0 90px">
          <SummaryCardColumn title="GPS">
            {`${!receipt.gpsPercent ? 0 : receipt.gpsPercent}%`}
          </SummaryCardColumn>
        </Col>

        <Col flex="0 0 296px">
          <SummaryCardColumn title={t('source')}>
            <Row align="middle" style={{ flexWrap: 'nowrap' }}>
              <Col flex={1}>
                <TripSourcePercentSummaryBar
                  t={t}
                  mobilePercent={receipt.mobileTripsPercent}
                  vehicleDevicePercent={receipt.vehicleDeviceTripsPercent}
                  manualPercent={receipt.manualTripsPercent}
                />
              </Col>
              <Col>
                <TripSourceTrackIconsRow t={t} />
              </Col>
            </Row>
          </SummaryCardColumn>
        </Col>
      </Row>

      <ReceiptBankTransferSummary
        t={t}
        receipt={receipt}
        onRetry={onRetry}
        style={{ marginTop: 20 }}
      />
    </Box>
  );
};

ReceiptSummaryCard.propTypes = {
  loading: PropTypes.bool,
  showApproveAnRejectButtons: PropTypes.bool,
  receipt: PropTypes.shape({}),
};

ReceiptSummaryCard.defaultProps = {
  loading: false,
  receipt: undefined,
  showApproveAnRejectButtons: false,
};

export default ReceiptSummaryCard;
