import GeoViewport from '@mapbox/geo-viewport';
import { get, isUndefined } from 'lodash';
import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { withNamespaces } from 'react-i18next';
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';

import { IMAGES } from '../../enum';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { getMapBoundaries } from '../../utils/map';
import ErrorBoundary from '../ErrorBoundary';
import MapLoader from '../MapLoader';
import EventInsightMapPopup from '../MapPopups/EventInsightMapPopup';
import UserInsightMapPopup from '../MapPopups/UserInsightMapPopup';

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
});

const ActiveUsersMap = props => {
  const {
    t,
    mapInstance,
    mapCenter,
    mapZoom,
    onMapCenterChange,
    userList,
    visibleUserPings,
    eventList,
    visibleEventPings,
    onMapLoad,
  } = props;

  const [visibleUserMarkerPopup, setVisibleUserMarkerPopup] = React.useState([]);
  const [visibleEventMarkerPopup, setVisibleEventMarkerPopup] = React.useState([]);

  const closePopups = () => {
    setVisibleUserMarkerPopup([]);
    setVisibleEventMarkerPopup([]);
  };

  useDidUpdateEffect(() => {
    closePopups();
  }, [userList, eventList]);

  const openUserMarker = useCallback(
    key => {
      if (!visibleUserMarkerPopup.includes(key)) {
        setVisibleUserMarkerPopup([key]);
        setVisibleEventMarkerPopup([]);
      }
    },
    [visibleUserMarkerPopup],
  );

  const openEventMarker = useCallback(
    key => {
      if (!visibleEventMarkerPopup.includes(key)) {
        setVisibleEventMarkerPopup([key]);
        setVisibleUserMarkerPopup([]);
      }
    },
    [visibleEventMarkerPopup],
  );

  const USER_POPUPS = useMemo(() => {
    return visibleUserMarkerPopup.map(key => {
      const user = userList.find(u => u._id === key);
      const lng = get(user, 'lastLocation.longitude', get(user, 'lastLocation.long'));
      const lat = get(user, 'lastLocation.latitude', get(user, 'lastLocation.lat'));

      return (
        user?.lastLocation &&
        !isUndefined(lng) &&
        !isUndefined(lat) && (
          <UserInsightMapPopup t={t} key={key} user={user} coordinates={[lng, lat]} />
        )
      );
    });
    // eslint-disable-next-line
  }, [visibleUserMarkerPopup, userList]);

  const EVENT_POPUPS = useMemo(() => {
    return visibleEventMarkerPopup.map(key => {
      const event = eventList.find(e => e._id === key);

      return (
        event?.locationCoordinates && (
          <EventInsightMapPopup
            t={t}
            key={key}
            event={event}
            coordinates={[event.locationCoordinates.longitude, event.locationCoordinates.latitude]}
          />
        )
      );
    });
    // eslint-disable-next-line
  }, [visibleEventMarkerPopup, eventList]);

  const USER_MARKERS = useMemo(() => {
    return userList.map(user => {
      if (visibleUserPings.includes(user._id)) {
        if (!user.lastLocation) return null;

        const lng = get(user, 'lastLocation.longitude', get(user, 'lastLocation.long'));
        const lat = get(user, 'lastLocation.latitude', get(user, 'lastLocation.lat'));
        if (isUndefined(lng) || isUndefined(lat)) return null;

        return (
          <Marker
            key={'user_marker_' + user._id}
            coordinates={[lng, lat]}
            onClick={() => openUserMarker(user._id)}
          >
            <img
              src={IMAGES.ACTIVE_DRIVER_GREEN}
              style={{ width: '30px', cursor: 'pointer' }}
              alt="marker"
            />
          </Marker>
        );
      }

      return null;
    });
  }, [openUserMarker, userList, visibleUserPings]);

  const CRM_EVENT_MARKERS = useMemo(() => {
    return eventList.map(event => {
      if (visibleEventPings.includes(event._id)) {
        if (!event.locationCoordinates) return null;

        return (
          <Marker
            key={'event_marker_' + event._id}
            coordinates={[event.locationCoordinates.longitude, event.locationCoordinates.latitude]}
            onClick={() => openEventMarker(event._id)}
          >
            <img
              src={IMAGES.NEEDS_DRIVER_RED}
              style={{ width: '30px', cursor: 'pointer' }}
              alt="marker"
            />
          </Marker>
        );
      }

      return null;
    });
  }, [openEventMarker, eventList, visibleEventPings]);

  const MARKER_BOUNDS = useMemo(() => {
    if (mapInstance) {
      const bounds = [];

      userList.forEach(user => {
        const lng = get(user, 'lastLocation.longitude', get(user, 'lastLocation.long'));
        const lat = get(user, 'lastLocation.latitude', get(user, 'lastLocation.lat'));

        if (user.lastLocation && !isUndefined(lng) && !isUndefined(lat)) {
          bounds.push({ lng, lat });
        }
      });

      eventList.forEach(event => {
        if (event.locationCoordinates) {
          bounds.push({
            lng: event.locationCoordinates.longitude,
            lat: event.locationCoordinates.latitude,
          });
        }
      });

      const boundaries = getMapBoundaries(bounds);

      if (boundaries.length) {
        const geoCenter = GeoViewport.viewport(
          [
            boundaries[0][0], // west
            boundaries[0][1], // south
            boundaries[1][0], // east
            boundaries[1][1], // north
          ],
          [mapInstance.transform.width, mapInstance.transform.height],
        );

        onMapCenterChange(geoCenter.center, Math.max(geoCenter.zoom - 1, 1));
        return GeoViewport.bounds(geoCenter.center, geoCenter.zoom, [
          mapInstance.transform.width,
          mapInstance.transform.height,
        ]);
      }
    }
    return [];
  }, [userList, eventList, mapInstance, onMapCenterChange]);

  return (
    <ErrorBoundary t={t} fallback={<MapLoader text={t('errorLoadingMap')} />}>
      <Map
        fitBounds={MARKER_BOUNDS.length > 1 ? MARKER_BOUNDS : undefined}
        fitBoundsOptions={{ maxZoom: 20, padding: 150 }}
        center={mapCenter}
        zoom={mapZoom ? [mapZoom] : undefined}
        onClick={closePopups}
        onStyleLoad={onMapLoad}
        // eslint-disable-next-line
        style="mapbox://styles/mapbox/streets-v12"
        containerStyle={{
          height: 600,
          width: '100%',
        }}
      >
        <ZoomControl position="top-left" />

        {USER_MARKERS}
        {CRM_EVENT_MARKERS}

        {USER_POPUPS}
        {EVENT_POPUPS}
      </Map>
    </ErrorBoundary>
  );
};

export default withNamespaces()(ActiveUsersMap);
