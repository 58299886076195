import { Col, Row } from 'antd';
import React from 'react';

import FadedText from '../Text/FadedText';
import Switch from '.';

const HorizontalSwitch = props => {
  const { label, labelSize, gutter, wrap, ...rest } = props;

  return (
    <Row align="middle" gutter={gutter} wrap={wrap}>
      {label && (
        <Col>
          <FadedText size={labelSize}>{label}</FadedText>
        </Col>
      )}
      <Col>
        <Switch {...rest} />
      </Col>
    </Row>
  );
};

HorizontalSwitch.defaultProps = {
  gutter: 16,
  wrap: false,
  labelSize: 'xs',
};

export default HorizontalSwitch;
