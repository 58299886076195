import { Col, Row } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';

import { handleApiErrors } from '../../../api/axiosInstance';
import { ConfirmModal } from '../../../components';
import Box from '../../../components/Box';
import SimpleEditPopoverForm from '../../../components/Forms/SimpleEditPopoverForm';
import TripPurposeExtendedForm from '../../../components/Forms/TripPurposeExtendedForm';
import MileageValuesTable from '../../../components/Table/MileageValuesTable';
import TripPurposeTable from '../../../components/Table/TripPurposeTable';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';

const TripsSection = props => {
  const { t, businessPurposeList, mileageValues, onSettingsSave } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteCallback, setDeleteCallback] = useState();

  const handleValueCreate = async (type, valuesArray, value) => {
    const valuesNameList = valuesArray.map(v => v.name);

    if (valuesNameList.includes(value)) {
      return Toast({
        type: 'error',
        message: t('valueAlreadyInList'),
      });
    }

    setIsCreating(true);

    try {
      await onSettingsSave({
        [type]: [...valuesNameList, value],
      });

      Toast({
        type: 'open',
        message: t('valueCreateSuccess'),
      });

      document.querySelector('#hide-simple-form-popover').click();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('valueCreateError'),
        });
      });
    }

    setIsCreating(false);
  };

  const handleValueUpdate = async (type, valuesArray, prevValue, newValue) => {
    const valuesNameList = valuesArray.map(v => v.name);

    if (valuesNameList.includes(newValue)) {
      return Toast({
        type: 'error',
        message: t('valueAlreadyInList'),
      });
    }

    setIsUpdating(true);

    try {
      await onSettingsSave({
        [type]: valuesNameList.filter(v => v !== prevValue).concat([newValue]),
      });

      Toast({
        type: 'open',
        message: t('valueUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('valueUpdateError'),
        });
      });
    }

    setIsUpdating(false);
  };

  const handleValueDelete = async (type, valuesArray, value) => {
    setIsDeleting(true);

    const valuesNameList = valuesArray.map(v => v.name);

    try {
      await onSettingsSave({
        [type]: valuesNameList.filter(v => v !== value),
      });
      Toast({
        type: 'open',
        message: t('valueDeleteSuccess'),
      });
      setDeleteCallback();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('valueDeleteError'),
        });
      });
    }

    setIsDeleting(false);
  };

  const handleBusinessPurposeCreate = async ({ name, ...subListValues }) => {
    const valuesNameList = businessPurposeList.map(v => v.name);

    if (valuesNameList.includes(name)) {
      return Toast({
        type: 'error',
        message: t('valueAlreadyInList'),
      });
    }

    if (_.uniq(Object.values(subListValues)).length !== Object.values(subListValues).length) {
      return Toast({
        type: 'error',
        message: t('duplicateValuesInSubList'),
      });
    }

    setIsCreating(true);

    try {
      await onSettingsSave({
        businessPurposeList: [
          ...businessPurposeList,
          {
            name: name,
            value: _.camelCase(name),
            subList: Object.values(subListValues).map(value => ({
              name: value,
              value: _.camelCase(value),
            })),
          },
        ],
      });

      Toast({
        type: 'open',
        message: t('valueCreateSuccess'),
      });

      document.querySelector('#hide-simple-form-popover').click();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('valueCreateError'),
        });
      });
    }

    setIsCreating(false);
  };

  const handleBusinessPurposeUpdate = async (prevValue, newValue, subListValues) => {
    const valuesNameList = businessPurposeList.map(v => v.name);

    let updatedList = [...businessPurposeList];
    const itemIndex = updatedList.findIndex(i => i.name === prevValue);

    if (_.uniq(Object.values(subListValues)).length !== Object.values(subListValues).length) {
      return Toast({
        type: 'error',
        message: t('duplicateValuesInSubList'),
      });
    }

    if (
      valuesNameList.includes(newValue) &&
      _.isEqual(
        Object.values(subListValues),
        updatedList[itemIndex].subList.map(v => v.name),
      )
    ) {
      return Toast({
        type: 'error',
        message: t('valueAlreadyInList'),
      });
    }

    setIsUpdating(true);

    updatedList[itemIndex] = {
      ...updatedList[itemIndex],
      name: newValue,
      value: _.camelCase(newValue),
      subList: Object.values(subListValues).map(value => ({
        name: value,
        value: _.camelCase(value),
      })),
    };

    try {
      await onSettingsSave({
        businessPurposeList: updatedList,
      });

      Toast({
        type: 'open',
        message: t('valueUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('valueUpdateError'),
        });
      });
    }

    setIsUpdating(false);
  };

  const handleBusinessPurposeDelete = async value => {
    setIsDeleting(true);

    try {
      await onSettingsSave({
        businessPurposeList: businessPurposeList.filter(p => p.name !== value),
      });
      Toast({
        type: 'open',
        message: t('valueDeleteSuccess'),
      });
      setDeleteCallback();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('valueDeleteError'),
        });
      });
    }

    setIsDeleting(false);
  };

  return (
    <Row id="trips">
      <Col flex={1}>
        <Box>
          <Text variant="h5">{t('trips')}</Text>
          <br />
          <Text variant="strong" renderAs="p">
            {t('tripPurpose')}
          </Text>
          <br />
          <TripPurposeTable
            t={t}
            isSubmitting={isUpdating}
            loading={!businessPurposeList}
            dataSource={businessPurposeList}
            popoverTitle={t('editBusinessPurposeList')}
            onDelete={name =>
              setDeleteCallback({ callback: () => handleBusinessPurposeDelete(name) })
            }
            onEdit={({ prevValue, newValue, ...subListValues }) =>
              handleBusinessPurposeUpdate(prevValue, newValue, subListValues)
            }
          />

          <Row justify="end">
            <Col>
              <SimpleEditPopoverForm
                t={t}
                title={t('addBusinessPurpose')}
                inputName="name"
                isSubmitting={isCreating}
                onSubmit={handleBusinessPurposeCreate}
                placement="topLeft"
                extendForm={<TripPurposeExtendedForm subList={[]} />}
              >
                <LinkText>{t('Add new')}</LinkText>
              </SimpleEditPopoverForm>
            </Col>
          </Row>

          <br />

          <Text variant="strong" renderAs="p">
            {t('mileageValues')}
          </Text>
          <br />
          <MileageValuesTable
            t={t}
            isSubmitting={isUpdating}
            loading={!mileageValues}
            dataSource={mileageValues}
            popoverTitle={t('editMileageValues')}
            onDelete={name =>
              setDeleteCallback({
                callback: () => handleValueDelete('mileageValues', mileageValues, name),
              })
            }
            onEdit={({ prevValue, newValue }) =>
              handleValueUpdate('mileageValues', mileageValues, prevValue, newValue)
            }
          />

          <Row justify="end">
            <Col>
              <SimpleEditPopoverForm
                t={t}
                title={t('addMileageValues')}
                inputName="name"
                isSubmitting={isCreating}
                onSubmit={({ name }) => handleValueCreate('mileageValues', mileageValues, name)}
                placement="topLeft"
              >
                <LinkText>{t('Add new')}</LinkText>
              </SimpleEditPopoverForm>
            </Col>
          </Row>
        </Box>
      </Col>

      <ConfirmModal
        loading={isDeleting}
        visible={typeof deleteCallback?.callback === 'function'}
        message={t('sureYouWantToDeleteItem')}
        onCancel={() => setDeleteCallback()}
        okText={t('Delete')}
        onOk={deleteCallback?.callback}
      />
    </Row>
  );
};

export default TripsSection;
