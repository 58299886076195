import { formatQueryRange } from '../utils/common';
import { removeFalseyParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

export const fetchInsuranceCompanies = async (pageNumber, pageSize, filters = {}, sort) => {
  const range = formatQueryRange(pageNumber, pageSize);
  const params = removeFalseyParams({ ...filters, range, sort });
  const url = `${process.env.REACT_APP_HOST_API}insurance-companies`;
  const result = await handleApiCalls('get', url, { params, headers: { 'x-version': '2.0.0' } });

  return result.data;
};

export const createInsuranceCompany = async ({ companyId, ...data }) => {
  const url = `${process.env.REACT_APP_HOST_API}insurance-companies`;
  await handleApiCalls('post', url, data);
};

export const updateInsuranceCompany = async ({ companyId, ...data }) => {
  const url = `${process.env.REACT_APP_HOST_API}insurance-companies/${companyId}`;
  await handleApiCalls('put', url, data);
};

export const deleteInsuranceCompany = async companyId => {
  const url = `${process.env.REACT_APP_HOST_API}insurance-companies/${companyId}`;
  await handleApiCalls('delete', url);
};
