import { first } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { USER_API } from '../../api/user';
import SpaceSpinner from '../../components/SpaceSpinner';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle, getUserFullName } from '../../utils/common';
import { parseQueryParams } from '../../utils/queryParams';
import UserTaxReportPageContent from './UserTaxReportPageContent';

const PAGE_HEADER = (
  <Helmet>
    <title>{formatPageTitle('User Tax Report')}</title>
  </Helmet>
);

const TaxReportView = props => {
  const { t, match, location } = props;
  const queryParams = parseQueryParams(location.search);
  const [taxYear, setTaxYear] = useState(queryParams.year ? parseInt(queryParams.year) : undefined);

  const userTaxableYearsQuery = useQuery({
    enabled: !!match.params.id,
    placeholderData: [],
    queryKey: ['fetchUserTaxReportYears', match.params.id],
    queryFn: () => USER_API.fetchUserTaxReportYears(match.params.id),
    onError: error => handleApiErrors(error.response),
    onSuccess: taxableYears => {
      if (!taxYear) {
        setTaxYear(first(taxableYears));
      }
    },
  });

  const userTaxReportQuery = useQuery({
    enabled: !!taxYear,
    queryKey: ['fetchUserTaxReport', match.params.id, taxYear],
    queryFn: () => USER_API.fetchUserTaxReport(match.params.id, taxYear),
  });

  if (userTaxReportQuery.isFetching || userTaxableYearsQuery.isFetching) {
    return (
      <>
        {PAGE_HEADER}
        <SpaceSpinner />
      </>
    );
  }

  return (
    <PageContainer
      title={[t('userTaxReport'), getUserFullName(userTaxReportQuery.data)].join(' / ')}
    >
      {PAGE_HEADER}

      <UserTaxReportPageContent
        t={t}
        taxReportData={userTaxReportQuery.data}
        taxableYears={userTaxableYearsQuery.data}
        selectedtaxYear={taxYear}
        onYearChange={setTaxYear}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(TaxReportView));
