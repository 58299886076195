import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { get, omit } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import StripeAPI from '../../../api/stripe';
import { Button } from '../../../components';
import FormItem from '../../../components/Form/FormItem';
import SpaceSpinner from '../../../components/SpaceSpinner';
import FadedText from '../../../components/Text/FadedText';
import TextInput from '../../../components/TextInput';
import NumericInput from '../../../components/TextInput/NumericInput';
import { replaceValueInArrayByID } from '../../../utils/common';
import { hasSystemAdminRole } from '../../../utils/roles';
import SettingSectionWrapper from './SettingSectionWrapper';

const initialCustomFields = [{ id: +new Date(), name: '', value: '' }];

const StripeInvoiceSettingsFormItems = props => {
  const { t, authUser, stripeCustomerId, companySettingValues, handleSettingValueChange } = props;

  const [customFields, setCustomFields] = useState([...initialCustomFields]);

  const addIdsToCustomFields = useCallback(fields => {
    return fields.map((field, i) => {
      field.id = +new Date() + i;
      return field;
    });
  }, []);

  const customFieldsQuery = useQuery({
    queryKey: ['fetchCustomerInformation', stripeCustomerId],
    enabled: !!stripeCustomerId && hasSystemAdminRole(authUser),
    queryFn: () => new StripeAPI().fetchCustomerInformation(stripeCustomerId),
    onError: error => handleApiErrors(error.response),
    onSuccess: data => {
      const fields = get(data, 'customInvoiceFields') || [];

      if (!fields.length) {
        initialCustomFields[0].id = +new Date();
        setCustomFields([...initialCustomFields]);
      } else {
        setCustomFields(addIdsToCustomFields(fields));
      }
    },
  });

  const saveCustomFieldsMutation = useMutation(
    () => {
      const filteredFields = customFields.filter(item => !!item.name && !!item.value);
      return new StripeAPI().updateStripeCustomFields(stripeCustomerId, {
        invoiceSettings: {
          customFields: !filteredFields.length
            ? ''
            : filteredFields.map(item => omit(item, ['id'])),
        },
      });
    },
    {
      onError: error => handleApiErrors(error.response),
      onSuccess: data => {
        const fields = get(data, 'customInvoiceFields', []);

        if (!fields.length) {
          initialCustomFields[0].id = +new Date();
          setCustomFields([...initialCustomFields]);
        } else {
          setCustomFields(addIdsToCustomFields(fields));
        }
      },
    },
  );

  const addCustomField = useCallback(() => {
    setCustomFields(fields => {
      return [...fields, { id: +new Date(), name: '', value: '' }];
    });
  }, []);

  const removeCustomField = useCallback(index => {
    setCustomFields(fields => {
      const updatedArray = [...fields];
      updatedArray.splice(index, 1);
      if (!updatedArray.length) {
        initialCustomFields[0].id = +new Date();
        return [...initialCustomFields];
      }
      return updatedArray;
    });
  }, []);

  const updatedCustomField = useCallback((id, values = {}) => {
    setCustomFields(fields => {
      return replaceValueInArrayByID(fields, { ...values, id }, 'id');
    });
  }, []);

  const handleStripeValuesChange = useCallback(
    settings => {
      handleSettingValueChange({
        stripe: {
          ...(companySettingValues.stripe || {}),
          ...settings,
        },
      });
    },
    [handleSettingValueChange, companySettingValues],
  );

  if (!hasSystemAdminRole(authUser)) return null;
  if (customFieldsQuery.isFetching) return <SpaceSpinner />;

  return (
    <SettingSectionWrapper title={t('stripeInvoiceSettings')}>
      <Col flex="736px">
        <FormItem label={t('numberOfDaysUntilDue')} style={{ maxWidth: 180 }}>
          <NumericInput
            value={get(companySettingValues, 'stripe.invoiceDaysUntilDue')}
            onChange={days => handleStripeValuesChange({ invoiceDaysUntilDue: parseInt(days) })}
            onKeyPress={event => {
              if ([',', '.'].includes(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </FormItem>

        <FormItem label={t('customFields')} className="no-margin-bottom" hidden={!stripeCustomerId}>
          {customFields.map(({ id, name, value }, index) => {
            return (
              <Row key={id} gutter={[16, 16]} style={{ alignItems: 'baseline' }} wrap={false}>
                <Col flex={1}>
                  <FormItem>
                    <TextInput
                      defaultValue={name}
                      placeholder={t('name')}
                      onChange={e => updatedCustomField(id, { name: e.target.value })}
                      disabled={saveCustomFieldsMutation.isLoading}
                    />
                  </FormItem>
                </Col>
                <Col flex={1}>
                  <FormItem>
                    <TextInput
                      placeholder={t('value')}
                      defaultValue={value}
                      onChange={e => updatedCustomField(id, { value: e.target.value })}
                      disabled={saveCustomFieldsMutation.isLoading}
                    />
                  </FormItem>
                </Col>

                <Col>
                  {(name || value || customFields.length > 1) && (
                    <DeleteOutlined onClick={() => removeCustomField(index)} />
                  )}
                </Col>
              </Row>
            );
          })}

          <FormItem>
            <Space direction="vertical" size="small">
              <FadedText size="sm">
                {t('fieldsWithoutNameAndValueWillBeRemovedAfterSave')}
              </FadedText>

              <Space size="middle" direction="horizontal">
                <Button
                  size="xs"
                  type="secondary"
                  onClick={() => addCustomField()}
                  icon={<PlusOutlined />}
                  disabled={saveCustomFieldsMutation.isLoading}
                >
                  Add field
                </Button>

                <Button
                  size="xs"
                  onClick={saveCustomFieldsMutation.mutateAsync}
                  loading={saveCustomFieldsMutation.isLoading}
                  disabled={saveCustomFieldsMutation.isLoading}
                >
                  Save fields
                </Button>
              </Space>
            </Space>
          </FormItem>
        </FormItem>
      </Col>
    </SettingSectionWrapper>
  );
};

export default StripeInvoiceSettingsFormItems;
