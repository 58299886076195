import { INTERNAL_LINKS } from '../enum';
import DailyMileageLogView from './daily-mileage-log';
import ExportDataView from './export-data';
import UserOnboarding from './onboarding/accountsetup/UserOnboarding';
import HomeAddressOnboardingView from './onboarding/home-address';
import ReimbursementDetailsPageSwitcher from './payments/ReimbursementDetailsPageSwitcher';
import ReimbursementPageSwitcher from './payments/ReimbursementPageSwitcher';
import ReceiptPage from './receipt';
import ReceiptDetailPage from './receipt/details';
import TripsPage from './trips';
import ManageTripsViaCSV from './trips-via-csv';
import TripsUploadedViaCsvDetails from './trips-via-csv-details';
import UserDetails from './userdetails';

const UserViews = [
  {
    exact: true,
    path: `${INTERNAL_LINKS.USER_ONBOARDING}${INTERNAL_LINKS.HOME_ADDRESS}`,
    component: HomeAddressOnboardingView,
  },
  { exact: true, path: INTERNAL_LINKS.USER_ONBOARDING, component: UserOnboarding },
  { path: INTERNAL_LINKS.TRIPS, component: TripsPage },
  { path: `${INTERNAL_LINKS.RECEIPTS}/:id`, component: ReceiptDetailPage },
  { exact: true, path: INTERNAL_LINKS.RECEIPTS, component: ReceiptPage },
  { path: `${INTERNAL_LINKS.REIMBURSEMENT}/:id`, component: ReimbursementDetailsPageSwitcher },
  { exact: true, path: INTERNAL_LINKS.REIMBURSEMENT, component: ReimbursementPageSwitcher },
  { path: `${INTERNAL_LINKS.USER_DETAILS}/:id`, component: UserDetails },
  { path: INTERNAL_LINKS.DAILY_MILEAGE_LOG, component: DailyMileageLogView },
  { path: INTERNAL_LINKS.EXPORTS, component: ExportDataView },
  { exact: true, path: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS, component: ManageTripsViaCSV },
  { path: `${INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS}/:id`, component: TripsUploadedViaCsvDetails },
];

export default UserViews;
