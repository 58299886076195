import { Col, Form, Row, Space } from 'antd';
import { get, last } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { FAVR_COMPLIANCES_API } from '../../api/favr-compliances';
import { downloadInsuranceDocumentImage } from '../../api/insurance-policies';
import { Button } from '../../components';
import Box from '../../components/Box';
import TripsCommentsModal from '../../components/Modal/TripsCommentsModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import ComplianceAuditLogTable from '../../components/Table/ComplianceAuditLogTable';
import StatusTag from '../../components/Tag/StatusTag';
import Text from '../../components/Text';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useModal from '../../hooks/useModal';
import useTablePagination from '../../hooks/useTablePagination';
import { formatPageTitle, getUserFullName } from '../../utils/common';
import { getMomentDateWithoutTimezone } from '../../utils/datetime';
import { formatAddressIntoString } from '../../utils/trips';

const RegularInsuranceWorklistForm = React.lazy(() =>
  import('./components/RegularInsuranceWorklistForm'),
);

const MeasureOneInsuranceWorklistComparison = React.lazy(() =>
  import('./components/MeasureOneInsuranceWorklistComparison'),
);

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Compliance Worklist Details')}</title>
  </Helmet>
);

const ComplianceWorklistDetails = props => {
  const { t, history, match } = props;

  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState();
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const [vehicleData, setVehicleData] = useState({});
  const [
    isRemarksForUserModalVisible,
    openRemarksForUserModal,
    closeRemarksForUserModal,
  ] = useModal();
  const [
    isRemarksForKliksAdminModalVisible,
    openRemarksForKliksAdminModal,
    closeRemarksForKliksAdminModal,
  ] = useModal();

  const handleValuesChange = newValues =>
    setFormValues(currValues => ({ ...currValues, ...newValues }));

  const handleVehicleDataChange = updatedValues =>
    setVehicleData(state => ({ ...state, ...updatedValues }));

  const complianceDetailsQuery = useQuery({
    queryKey: ['fetchFavrComplianceDetails', match.params.id],
    queryFn: () => FAVR_COMPLIANCES_API.fetchFavrComplianceDetails(match.params.id),
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchFavrComplianceDetailsError'),
        });
      });

      history.push(INTERNAL_LINKS.COMPLIANCE_WORKLIST);
    },
  });

  const {
    paginationConfig,
    handleTotalUpdate,
    handlePageChange,
    handlePageSizeChange,
  } = useTablePagination();
  const userComplianceHistoryQuery = useQuery({
    enabled: !!complianceDetailsQuery?.data?.userId?._id,
    initialData: { documents: [], count: 0 },
    queryKey: [
      'fetchUserFavrComplianceHistory',
      complianceDetailsQuery?.data?.userId?._id,
      match.params.id,
      paginationConfig.current,
      paginationConfig.pageSize,
    ],
    queryFn: () =>
      FAVR_COMPLIANCES_API.fetchUserFavrComplianceHistory(
        complianceDetailsQuery?.data?.userId?._id,
        paginationConfig.current,
        paginationConfig.pageSize,
        { favrComplianceId: match.params.id },
      ),
    onSuccess: ({ count }) => handleTotalUpdate(count),
  });

  const policyDocs = useMemo(() => {
    let docs = [];

    if (Array.isArray(complianceDetailsQuery.data?.vehicleInsurancePolicyId?.policyDocs)) {
      const { vehicleInsurancePolicyId } = complianceDetailsQuery.data;
      docs = vehicleInsurancePolicyId?.policyDocs.map(doc => ({ ...doc, name: doc.filename }));
    }

    return docs;
  }, [complianceDetailsQuery]);

  const [isInsuranceCompliance, isBusinessVehicleCompliance] = useMemo(
    () => [
      complianceDetailsQuery.data?.type === 'insurance',
      complianceDetailsQuery.data?.type === 'business-vehicle',
    ],
    [complianceDetailsQuery],
  );

  useDidUpdateEffect(() => {
    if (!initialValuesSet && complianceDetailsQuery?.data?.userId) {
      setInitialValuesSet(true);

      const queryData = get(complianceDetailsQuery, 'data', {});
      const complianceComponentsValues = get(queryData, 'complianceComponentsValues', {});
      const complianceComponents = get(queryData, 'complianceComponents', {});
      const vehicleComplianceData = get(queryData, 'vehicleId') || {};
      let initialValues = {};

      if (queryData.type === 'business-vehicle') {
        setVehicleData(vehicleComplianceData);
        initialValues = {
          depreciationCompliance: false,
          electronicSignatureCompliance: false,
          msrpCompliance: false,
          odometerReading: false,
          vehicleYearCompliance: false,

          ...(complianceComponents || {}),
          vehicleYear: vehicleComplianceData.year,
          vehicleMake: vehicleComplianceData.make,
          vehicleModel: vehicleComplianceData.model,
          odometer: last(vehicleComplianceData.odometer || [])?.mileage,
        };
      } else if (queryData.type === 'insurance') {
        initialValues = {
          bodilyInjuryByUnderinsuredAuto: false,
          bodilyInjuryByUninsuredAuto: false,
          bodilyInjuryToOthers: false,
          collision: false,
          comprehensive: false,
          damageToProperty: false,
          insuranceCompany: false,
          insuranceHomeAddress: false,
          insuranceName: false,
          insurancePolicyEndDate: false,
          insurancePolicyStartDate: false,
          insuranceVehicle: false,
          medicalPayments: false,
          optionalBodilyInjuryToOthers: false,
          personalInjuryProtection: false,
          stateMinimum: false,

          ...(complianceComponentsValues || {}),
          ...(complianceComponents || {}),
          driverNameOnPolicy: getUserFullName(queryData.userId),
          driverHomeAddressOnPolicy: queryData.userId?.personalInfor?.homeAddress
            ? formatAddressIntoString(queryData.userId.personalInfor.homeAddress)
            : undefined,
          vehicleYearMakeModelPolicy: [
            vehicleComplianceData.year,
            vehicleComplianceData.make,
            vehicleComplianceData.model,
          ]
            .join(' ')
            .trim(),
          insurancePolicyStartDateValue: complianceComponentsValues?.insurancePolicyStartDateValue
            ? getMomentDateWithoutTimezone(
                complianceComponentsValues?.insurancePolicyStartDateValue,
              )
            : undefined,
          insurancePolicyEndDateValue: complianceComponentsValues?.insurancePolicyEndDateValue
            ? getMomentDateWithoutTimezone(complianceComponentsValues?.insurancePolicyEndDateValue)
            : undefined,
        };
      }
      console.log({ initialValues });
      setFormValues(initialValues);
    }
  }, [complianceDetailsQuery, initialValuesSet]);

  const updateFavrComplianceMutation = useMutation(
    values => FAVR_COMPLIANCES_API.updateFavrCompliance(match.params.id, values),
    {
      onSuccess: () => {
        Toast({
          type: 'open',
          message: t('updateFavrComplianceSuccess'),
        });
      },
    },
  );

  const overrideFavrComplianceMutation = useMutation(
    reason => FAVR_COMPLIANCES_API.overrideFavrComplianceStatus(match.params.id, reason),
    {
      onSuccess: () => {
        Toast({
          type: 'open',
          message: t('overrideFavrComplianceSuccess'),
        });
      },
    },
  );

  const _updateComplianceItem = (newValues = {}) => {
    const queryKey = [FAVR_COMPLIANCES_API.fetchFavrComplianceDetails.name, match.params.id];
    let currentQueryData = queryClient.getQueryData(queryKey);
    queryClient.setQueryData(queryKey, { ...currentQueryData, ...newValues });
  };

  const addRemarksForUser = useMutation(
    note => FAVR_COMPLIANCES_API.addNoteToFavrCompliance(match.params.id, note, false),
    {
      onSuccess: updatedNotes => _updateComplianceItem({ notesForUser: updatedNotes }),
      onError: error => handleApiErrors(error.response),
    },
  );

  const addRemarksForInternalAdmin = useMutation(
    note => FAVR_COMPLIANCES_API.addNoteToFavrCompliance(match.params.id, note, true),
    {
      onSuccess: updatedNotes => _updateComplianceItem({ notes: updatedNotes }),
      onError: error => handleApiErrors(error.response),
    },
  );

  const handleFileDownload = async file => {
    try {
      await downloadInsuranceDocumentImage(
        complianceDetailsQuery.data?.vehicleInsurancePolicyId?._id,
        file.fileKey,
        file.filename,
      );
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(async () => {
      const { kliksAdminOverride, reasonForOverride, ...values } = formValues;
      try {
        if (isInsuranceCompliance) {
          await updateFavrComplianceMutation.mutateAsync(values);
          complianceDetailsQuery.refetch();
        }

        if (isBusinessVehicleCompliance && kliksAdminOverride && reasonForOverride) {
          await overrideFavrComplianceMutation.mutateAsync(reasonForOverride);
          complianceDetailsQuery.refetch();
        }
      } catch (error) {
        handleApiErrors(error.response);
      }
    });
  };

  if (!initialValuesSet) {
    return <PageContainer loading>{<PageHelmet />}</PageContainer>;
  }

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={
            complianceDetailsQuery.isFetching
              ? []
              : [
                  {
                    label: t('compliance'),
                    onClick: () => history.push(INTERNAL_LINKS.COMPLIANCE_WORKLIST),
                  },
                  {
                    label: t(complianceDetailsQuery?.data?.type),
                  },
                  {
                    label: (
                      <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                        <Col>{getUserFullName(complianceDetailsQuery?.data?.userId)}</Col>
                        {complianceDetailsQuery?.data?.status && (
                          <Col>
                            <StatusTag status={complianceDetailsQuery?.data?.status} />
                          </Col>
                        )}
                      </Row>
                    ),
                  },
                ]
          }
        />
      }
      sideActionComponent={
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Button block size="sm" onClick={openRemarksForUserModal}>
              {t('remarksForUser')}
            </Button>
          </Col>
          <Col flex={1}>
            <Button block size="sm" onClick={openRemarksForKliksAdminModal}>
              {t('remarksForKliksInternalAdmin')}
            </Button>
          </Col>
        </Row>
      }
    >
      <PageHelmet />

      <Space direction="vertical" size="large">
        {!complianceDetailsQuery.isFetched && complianceDetailsQuery.isFetching && <SpaceSpinner />}

        {complianceDetailsQuery.isFetched &&
          !complianceDetailsQuery.data?.measureOneInformations && (
            <RegularInsuranceWorklistForm
              t={t}
              isLoading={complianceDetailsQuery.isFetching}
              formInstance={form}
              formValues={formValues}
              complianceStatus={complianceDetailsQuery?.data?.status}
              onValuesChange={handleValuesChange}
              isInsuranceCompliance={isInsuranceCompliance}
              isBusinessVehicleCompliance={isBusinessVehicleCompliance}
              policyDocs={policyDocs}
              vehicleData={vehicleData}
              onFileUpload={handleFileDownload}
              onVehicleDataChange={handleVehicleDataChange}
              onSubmit={handleSubmit}
              disabled={
                updateFavrComplianceMutation.isLoading || overrideFavrComplianceMutation.isLoading
              }
            />
          )}

        {complianceDetailsQuery.isFetched &&
          !!complianceDetailsQuery.data.measureOneInformations && (
            <MeasureOneInsuranceWorklistComparison
              t={t}
              user={get(complianceDetailsQuery.data, 'userId')}
              vehicle={get(complianceDetailsQuery.data, 'vehicleId')}
              measureOneInformations={get(complianceDetailsQuery.data, 'measureOneInformations')}
              complianceComponents={get(complianceDetailsQuery.data, 'complianceComponents')}
              complianceComparisonResults={get(
                complianceDetailsQuery.data,
                'complianceComparisonResults',
              )}
            />
          )}

        <Box>
          <Text variant="h5">{t('auditLog')}</Text>
          <ComplianceAuditLogTable
            t={t}
            showSearchInput={false}
            loading={userComplianceHistoryQuery.isFetching}
            dataSource={userComplianceHistoryQuery.data.documents}
            onChange={({ current }) => handlePageChange(current)}
            pagination={{
              pageSize: paginationConfig.pageSize,
              total: paginationConfig.total,
              current: paginationConfig.current,
              onShowSizeChange: handlePageSizeChange,
            }}
          />
        </Box>
      </Space>

      <TripsCommentsModal
        t={t}
        canAddComment
        title={t('remarksForUser')}
        visible={isRemarksForUserModalVisible}
        onCancel={closeRemarksForUserModal}
        comments={complianceDetailsQuery?.data?.notesForUser || []}
        isAddingComment={addRemarksForUser.isLoading}
        onAddComment={note => addRemarksForUser.mutateAsync(note)}
      />

      <TripsCommentsModal
        t={t}
        canAddComment
        title={t('remarksForKliksInternalAdmin')}
        visible={isRemarksForKliksAdminModalVisible}
        onCancel={closeRemarksForKliksAdminModal}
        comments={complianceDetailsQuery?.data?.notes || []}
        isAddingComment={addRemarksForInternalAdmin.isLoading}
        onAddComment={note => addRemarksForInternalAdmin.mutateAsync(note)}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(ComplianceWorklistDetails));
