import { get } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectStoreCompanyGroupByID, selectStoreCurrentAuthUser } from '../utils/storeSelectors';

const useAuthUser = () => {
  const authUser = useSelector(selectStoreCurrentAuthUser);

  const authUserGroup = useMemo(() => {
    const groupId = get(authUser, 'profile.group._id');

    if (groupId) {
      return selectStoreCompanyGroupByID(groupId);
    }

    return undefined;
  }, [authUser]);

  return { authUser, authUserGroup };
};

export default useAuthUser;
