import { call, put, select, takeLatest } from 'redux-saga/effects';

import { STANDARD_VEHICLES_API } from '../../api/cars';
import Toast from '../../components/Toast';
import i18n from '../../i18n';
import * as actions from '../actions/favrVehicles';

function* loadCarYears() {
  try {
    yield put(actions.toggleMakesLoader(true));

    const yearsData = yield call(STANDARD_VEHICLES_API.fetchFavrCarYears);
    yield put(actions.setYearData(yearsData));

    yield put(actions.toggleMakesLoader(false));
  } catch (error) {
    yield put(actions.toggleMakesLoader(false));
    Toast({
      type: 'error',
      message: i18n.t('loadCarYearsError'),
    });
  }
}

function* loadCarMakes(action) {
  try {
    yield put(actions.toggleMakesLoader(true));

    const makesData = yield call(STANDARD_VEHICLES_API.fetchFavrCarMakes, action.data.year);
    yield put(actions.updateVehicleMakes(action.data.year, makesData));

    yield put(actions.toggleMakesLoader(false));
  } catch (err) {
    yield put(actions.toggleMakesLoader(false));
    Toast({
      type: 'error',
      message: i18n.t('loadCarMakesError'),
    });
  }
}

function* loadCarModels(action) {
  try {
    yield put(actions.toggleModelsLoader(true));

    const modelsData = yield call(
      STANDARD_VEHICLES_API.fetchFavrCarModels,
      action.data.year,
      action.data.make,
    );
    yield put(actions.updateVehicleModels(action.data.year, action.data.make, modelsData));

    yield put(actions.toggleModelsLoader(false));
  } catch (err) {
    yield put(actions.toggleModelsLoader(false));
    Toast({
      type: 'error',
      message: i18n.t('loadCarModelsError'),
    });
  }
}

function* setupCarVehicles(action) {
  const availableYears = yield select(store => store.favrVehicles.availableYears);

  if (!availableYears.length) {
    yield loadCarYears();
  }

  if (action.data.year) {
    yield loadCarMakes(action);
  }

  if (action.data.year && action.data.make) {
    yield loadCarModels(action);
  }

  yield put(actions.completeFavrVehiclesSetup());
}

/**
 * watcher saga
 */
export const favrVehiclesSaga = [
  takeLatest(actions.SETUP_FAVR_VEHICLES, setupCarVehicles),
  takeLatest(actions.GET_FAVR_VEHICLE_YEARS, loadCarYears),
  takeLatest(actions.GET_FAVR_VEHICLE_MAKES, loadCarMakes),
  takeLatest(actions.GET_FAVR_VEHICLE_MODELS, loadCarModels),
];
