import { Switch as AntdSwitch } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import classNames from './style.module.scss';

/**
 * Custom Switch based on the Ant Design Switch
 * but following Kliks style guide and branding
 *
 * @param {object} props - Component props
 */
const Switch = props => {
  const { className, size, label, checked, onChange, defaultChecked, disabled, ...rest } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [isChecked, setIsChecked] = useState(checked || defaultChecked);
  const switchClassName = [classNames.customSwitch, `switch-${size}`, className]
    .filter(Boolean)
    .join(' ');

  const handleChange = () => setIsChecked(!isChecked);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setIsChecked(checked);
    }
    // eslint-disable-next-line
  }, [checked]);

  useEffect(() => {
    if (typeof onChange === 'function' && isMounted) onChange(isChecked);
    // eslint-disable-next-line
  }, [isChecked]);

  return (
    <div id="custom-switch" className={switchClassName}>
      <AntdSwitch
        {...rest}
        disabled={disabled}
        checked={isChecked}
        defaultChecked={isChecked}
        onChange={handleChange}
        className={className}
      />

      {label && (
        <span className={classNames.switchLabel} onClick={!disabled ? handleChange : undefined}>
          {label}
        </span>
      )}
    </div>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
};

Switch.defaultProps = {
  label: undefined,
  className: '',
  size: 'small',
  checked: false,
  defaultChecked: false,
};

export default Switch;
