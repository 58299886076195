import { Col, Form, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import useRateForm from '../../hooks/useRateForm';
import { emitUpdateWizardDirty } from '../../stores/actions/rateWizard';
import { ERROR_MESSAGE } from '../../utils/constants';
import { useStoreSelector } from '../../utils/storeSelectors';
import Checkbox from '../Checkbox';
import FormItem from '../Form/FormItem';
import Select from '../Select';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Text from '../Text';
import NumericInput from '../TextInput/NumericInput';
import { INSURANCE_COVERAGES_FIELDS } from './rate-form-wizard.contants';

const InsuranceAndDriverCompliance = props => {
  const { t, formInstance, onNextClick, onBackClick } = props;

  const dispatch = useDispatch();
  const rateWizard = useStoreSelector('rateWizard');
  const { handleFormValuesChange } = useRateForm(formInstance);

  const FREQUENCY_OPTIONS = [
    { label: t('annual'), value: 'annual' },
    { label: t('semiAnnual'), value: 'semi-annual' },
  ];

  const GRACE_PERIOD_OPTIONS = [
    { label: 30, value: 30 },
    { label: 60, value: 60 },
    // { label: 90, value: 90 },
  ];

  return (
    <Form
      form={formInstance}
      onValuesChange={() => {
        if (!rateWizard.isDirty) {
          dispatch(emitUpdateWizardDirty(true));
        }
      }}
    >
      <FormItem
        label={t('annualComplianceSubmission')}
        name="annualComplianceSubmission"
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <Select
          fullWidth
          disabled={rateWizard.isSubmitting}
          optionFilterProp="children"
          options={[
            { label: t('confirmationOnly'), value: 'confirm' },
            { label: t('resubmit'), value: 'resubmit' },
          ]}
          onChange={annualComplianceSubmission => {
            handleFormValuesChange({ annualComplianceSubmission });
          }}
        />
      </FormItem>

      <FormItem
        required={rateWizard.rateValues.insuranceCompliance}
        label={t('gracePeriod')}
        name="gracePeriod"
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <Select
          disabled={rateWizard.isSubmitting}
          options={GRACE_PERIOD_OPTIONS}
          onChange={gracePeriod => handleFormValuesChange({ gracePeriod })}
        />
      </FormItem>

      <section>
        <Text variant="h4">{t('insuranceCompliance')}</Text>
        <FormItem name="insuranceCompliance" valuePropName="checked">
          <Checkbox
            disabled={rateWizard.isSubmitting}
            onChange={e =>
              handleFormValuesChange({
                insuranceCompliance: e.target.checked,
                insuranceComplianceCheckFrequency: e.target.checked
                  ? FREQUENCY_OPTIONS[0].value
                  : undefined,
              })
            }
          >
            {t('enableInsuranceCompliance')}
          </Checkbox>
        </FormItem>
        <FormItem
          label={t('complianceCheckFrequency')}
          name="insuranceComplianceCheckFrequency"
          required={rateWizard.rateValues.insuranceCompliance}
          rules={[
            {
              required: rateWizard.rateValues.insuranceCompliance,
              message: ERROR_MESSAGE().BLANK_FIELD,
            },
          ]}
        >
          <Select
            options={FREQUENCY_OPTIONS}
            disabled={rateWizard.isSubmitting || !rateWizard.rateValues.insuranceCompliance}
            onChange={insuranceComplianceCheckFrequency =>
              handleFormValuesChange({ insuranceComplianceCheckFrequency })
            }
          />
        </FormItem>

        {rateWizard.rateValues.insuranceCompliance && (
          <Text variant="h4">{t('insuranceMinimums')}</Text>
        )}
        <Row gutter={[24, 0]}>
          {INSURANCE_COVERAGES_FIELDS.map(key => (
            <Col key={key} xs={24} sm={12} md={8}>
              <FormItem
                name={key}
                label={t(key)}
                hidden={!rateWizard.rateValues.insuranceCompliance}
              >
                <NumericInput disabled />
              </FormItem>
            </Col>
          ))}
        </Row>
      </section>

      <section>
        <Text variant="h4">{t('driverLicenceCompliance')}</Text>

        <FormItem name="vehicleCompliance" valuePropName="checked">
          <Checkbox
            disabled={rateWizard.isSubmitting}
            onChange={e =>
              handleFormValuesChange({
                vehicleCompliance: e.target.checked,
                vehicleComplianceCheckFrequency: e.target.checked
                  ? FREQUENCY_OPTIONS[0].value
                  : undefined,
              })
            }
          >
            {t('enableDriverLicense')}
          </Checkbox>
        </FormItem>

        <FormItem
          required={rateWizard.rateValues.insuranceCompliance}
          label={t('complianceCheckFrequency')}
          name="vehicleComplianceCheckFrequency"
          rules={[
            {
              required: rateWizard.rateValues.vehicleCompliance,
              message: ERROR_MESSAGE().BLANK_FIELD,
            },
          ]}
        >
          <Select
            disabled={rateWizard.isSubmitting || !rateWizard.rateValues.vehicleCompliance}
            options={FREQUENCY_OPTIONS}
            onChange={vehicleComplianceCheckFrequency =>
              handleFormValuesChange({ vehicleComplianceCheckFrequency })
            }
          />
        </FormItem>
      </section>

      <SubmitCancelButtonGroup
        cancelText={t('Back')}
        submitText={t('Next')}
        loading={rateWizard.isSubmitting}
        onCancel={onBackClick}
        onSubmit={onNextClick}
      />
    </Form>
  );
};

export default InsuranceAndDriverCompliance;
