import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { createSystemAdmin } from '../../api/systemAdmin';
import CreateSystemAdminModal from '../../components/Modal/CreateSystemAdminModal';
import SystemAdminUserManagerTable from '../../components/Table/SystemAdminUserManagerTable';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import { fetchSystemAdmins, updateSystemAdmins } from '../../stores/actions/systemAdmin';
import { formatPageTitle, getUserFullName } from '../../utils/common';

const ManageSystemAdminsView = props => {
  const { t } = props;

  const dispatch = useDispatch();
  const { isLoadingSystemAdmins, systemAdmins } = useSelector(store => store.systemAdmin);

  const [isCreateSysAdminModalVisible, setIsCreateSysAdminModalVisible] = useState(false);
  const [isCreatingSystemAdmin, setIsCreatingSystemAdmin] = useState(false);

  const handleSystemAdminCreation = async formValues => {
    setIsCreatingSystemAdmin(true);

    try {
      const newSystemAdmin = await createSystemAdmin({
        ...formValues,
        password_repeat: formValues.confirmPassword,
      });
      dispatch(
        updateSystemAdmins(
          [...systemAdmins, newSystemAdmin].sort((a, b) =>
            getUserFullName(a).toLowerCase().localeCompare(getUserFullName(b).toLowerCase()),
          ),
        ),
      );
      setIsCreateSysAdminModalVisible(false);
      Toast({
        type: 'open',
        message: t('systemAdminCreatedSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('systemAdminCreatedError'),
        });
      });
    }

    setIsCreatingSystemAdmin(false);
  };

  useState(() => {
    dispatch(fetchSystemAdmins());
  }, []);

  return (
    <PageContainer
      title={t('manageSystemAdminUsers')}
      pageActionText={t('addSystemAdmin')}
      onPageActionClick={() => setIsCreateSysAdminModalVisible(true)}
    >
      <Helmet>
        <title>{formatPageTitle('Manage System Admin Users')}</title>
      </Helmet>

      <SystemAdminUserManagerTable
        t={t}
        loading={isLoadingSystemAdmins}
        dataSource={systemAdmins}
      />

      <CreateSystemAdminModal
        t={t}
        isSubmitting={isCreatingSystemAdmin}
        visible={isCreateSysAdminModalVisible}
        onCancel={() => setIsCreateSysAdminModalVisible(false)}
        onSubmit={handleSystemAdminCreation}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(ManageSystemAdminsView));
