import './style.scss';

import { Col, Row } from 'antd';
import React from 'react';

import Text from '../../../components/Text';
import { IMAGES } from '../../../enum';

const PlanCard = ({ data, disabled, selected = false, onClick = () => {} }) => {
  const className = `plan-card ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`;

  return (
    <div className={className} onClick={disabled ? undefined : onClick}>
      <div className="plan-card-img">
        <img width="110px" src={IMAGES.PLAN_IMAGE} alt="plan-img" />
      </div>
      <div className="plan-card-content">
        <div style={{ marginBottom: 15 }}>
          <Text variant="h3" renderAs="span" className="plan-card-content-level">
            {`${data.level} - `}
          </Text>
          <Text
            color="primary"
            variant="h2"
            size="xl"
            renderAs="span"
            className="plan-card-content-price"
          >
            {data.price}
          </Text>
          <Text
            color="primary"
            variant="p"
            size="xl"
            renderAs="span"
            className="plan-card-content-unit"
          >
            {' '}
            {data.unit}
          </Text>
        </div>
        <div>
          <Col>
            <Row style={{ marginBottom: 10 }}>
              <Text variant="p" size="large">
                {data.descriptionLabel}
              </Text>
            </Row>

            {data.desc.map((d, index) => (
              <Row
                key={d}
                align="middle"
                gutter={10}
                style={{ marginBottom: 10, flexWrap: 'nowrap' }}
              >
                <Col>
                  <img width="26px" src={IMAGES.LONG_ARROW_RIGHT_ICON} alt="left-arrow" />
                </Col>
                <Col>
                  <Text variant="p" size="large">
                    {d}
                  </Text>
                </Col>
              </Row>
            ))}
          </Col>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
