import { first } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useQuery } from 'react-query';

import { USER_API } from '../../../api/user';
import SpaceSpinner from '../../../components/SpaceSpinner';
import { formatPageTitle } from '../../../utils/common';
import UserTaxReportPageContent from '../../user-tax-report/UserTaxReportPageContent';

const UserTaxReportView = props => {
  const { t, userDetails, taxableYears, isLoadingTaxableYears } = props;

  const [taxYear, setTaxYear] = useState(first(taxableYears));

  const userTaxReportQuery = useQuery({
    enabled: !!taxableYears?.length && !!taxYear,
    queryKey: ['fetchUserTaxReport', userDetails._id, taxYear],
    queryFn: () => USER_API.fetchUserTaxReport(userDetails._id, taxYear),
  });

  useEffect(() => {
    if (Array.isArray(taxableYears) && !!taxableYears.length && !taxYear) {
      setTaxYear(first(taxableYears));
    }
  }, [taxableYears, taxYear]);

  if (isLoadingTaxableYears || userTaxReportQuery.isFetching) {
    return <SpaceSpinner />;
  }

  if (!taxableYears?.length) {
    return 'BACK';
  }

  return (
    <div>
      <Helmet>
        <title>{formatPageTitle('Tax Report')}</title>
      </Helmet>

      <UserTaxReportPageContent
        t={t}
        taxReportData={userTaxReportQuery.data}
        taxableYears={taxableYears}
        selectedtaxYear={taxYear}
        onYearChange={setTaxYear}
      />
    </div>
  );
};

export default withNamespaces()(UserTaxReportView);
