import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CARS_API } from '../../api/cars';
import { getNumberRage } from '../../utils/common';
import Select from '.';

const VehicleYearSelect = props => {
  const { t, disabled, ...rest } = props;

  const vehicleYearsQuery = useQuery(['fetchCarYears'], {
    staleTime: Infinity,
    queryFn: async () => {
      const carYears = await CARS_API.fetchCarYears();
      return getNumberRage(carYears.min_year, carYears.max_year).reverse();
    },
  });

  const vehicleModelOptions = useMemo(() => {
    if (Array.isArray(vehicleYearsQuery.data)) {
      return vehicleYearsQuery.data.map(year => ({ label: year, value: year }));
    }

    return [];
  }, [vehicleYearsQuery.data]);

  return (
    <Select
      {...rest}
      showSearch
      disabled={vehicleYearsQuery.isFetching || !vehicleModelOptions?.length || disabled}
      loading={vehicleYearsQuery.isFetching}
      placeholder={t('selectYear')}
      options={vehicleModelOptions}
    />
  );
};

export default VehicleYearSelect;
