import React from 'react';

import Text from '../../Text';
import ConfirmModal from '../ConfirmModal';

const DisableAchFeaturesConfirmationModal = props => {
  const { t, visible, onCancel, onConfirm } = props;

  return (
    <ConfirmModal
      visible={visible}
      message={<Text variant="b">{t('sureYouWantToDisableAch')}</Text>}
      onCancel={onCancel}
      cancelText={t('No')}
      onOk={onConfirm}
      okText={t('Yes')}
    />
  );
};

export default DisableAchFeaturesConfirmationModal;
