import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

/**
 * Performs an API call to soft-delete a Rate (product)
 *
 * @param {string} rateId ID of Product / Rate to delete
 */
export const deleteRate = async rateId => {
  const url = `${process.env.REACT_APP_HOST_API}products/${rateId}`;
  const result = await handleApiCalls('delete', url);

  return result.data.message;
};

export const RATES_API = {
  fetchCompanyRates: async (
    companyId,
    filters = {},
    sort = JSON.stringify({ name: 1 }),
    pageNumber,
    pageSize = 25,
  ) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range, sort, ...filters });
    const url = `${process.env.REACT_APP_HOST_API}company/${companyId}/products?${queryParams}`;
    const result = await handleApiCalls('GET', url);

    return result.data;
  },
  /**
   * @param {string} rateId ID of Product / Rate
   */
  fetchUsersAssociatedToRate: async (rateId, filters = {}, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ range, ...filters });

    const url = `${process.env.REACT_APP_HOST_API}products/${rateId}/users?${queryParams}`;
    const result = await handleApiCalls('GET', url);

    return result.data;
  },
  /**
   * @param {object} params
   * @param {string} params.productId
   * @param {string[]} params.usersIds
   * @param {boolean} params.getFixedRate
   * @param {boolean} params.getVariableRate
   */
  retryFetchFavrValues: async params => {
    const { productId, usersIds = [], getFixedRate, getVariableRate } = params;
    const url = `${process.env.REACT_APP_HOST_API}products/favr-values/retry`;
    await handleApiCalls('post', url, { productId, usersIds, getFixedRate, getVariableRate });
  },
};
