import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { formatNumberToLocale } from '../../../utils/numbers';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import LinkText from '../../Text/LinkText';

const AnomaliesTripsOverAverageTable = props => {
  const { t, dataSource, hideReceipts, ...rest } = props;

  const COLUMNS = useMemo(
    () => [
      {
        key: 'user',
        title: t('user'),
        dataIndex: 'user',
        render: user => <ProfileNamePicture user={user} />,
      },
      {
        key: 'paymentPeriod',
        title: t('paymentPeriod'),
        render: ({ paymentPeriodStart, paymentPeriodEnd }, data) => {
          return (
            <Link
              disabled={!data.tripReceiptId}
              to={
                hideReceipts
                  ? `${INTERNAL_LINKS.REIMBURSEMENT}/${data.tripReceiptId}`
                  : `${INTERNAL_LINKS.RECEIPTS}/${data.tripReceiptId}`
              }
            >
              <LinkText variant="b">
                {[
                  getMomentDateWithoutTimezone(paymentPeriodStart).format('MM-DD-YYYY'),
                  getMomentDateWithoutTimezone(paymentPeriodEnd).format('MM-DD-YYYY'),
                ].join(' to ')}
              </LinkText>
            </Link>
          );
        },
      },
      {
        align: 'right',
        key: 'totalTrips',
        title: t('anomalies:totalTripCount'),
        dataIndex: 'totalTrips',
        sorter: true,
        render: totalTrips => formatNumberToLocale(totalTrips),
      },
      {
        align: 'right',
        key: 'previousAverage',
        title: t('anomalies:averageTripCountLast3Periods'),
        dataIndex: 'previousAverage',
        sorter: true,
        render: previousAverage => formatNumberToLocale(previousAverage),
      },
      {
        align: 'right',
        defaultSortOrder: 'descend',
        key: 'increasePercentage',
        title: t('anomalies:tripCountChange%'),
        dataIndex: 'increasePercentage',
        sorter: true,
        render: increasePercentage => formatNumberToLocale(increasePercentage),
      },
      {
        align: 'right',
        key: 'gpsPercentage',
        title: t('anomalies:gps%CurrentPeriod'),
        dataIndex: 'gpsPercentage',
        sorter: true,
        render: gpsPercentage => formatNumberToLocale(gpsPercentage),
      },
      {
        align: 'right',
        key: 'previousGpsPercentageAverage',
        title: t('anomalies:averageGps%Last3Periods'),
        dataIndex: 'previousGpsPercentageAverage',
        sorter: true,
        render: previousGpsPercentageAverage => formatNumberToLocale(previousGpsPercentageAverage),
      },
    ],
    [t, hideReceipts],
  );

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

export default AnomaliesTripsOverAverageTable;
