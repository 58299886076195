import React, { useState } from 'react';

import FormItem from '../../Form/FormItem';
import LinkText from '../../Text/LinkText';
import TimePicker from '../../TimePicker';
import classNames from './styles.module.scss';

const TogglableArrivalTimeFormItem = props => {
  const {
    t,
    required,
    name,
    label,
    value,
    availableMinutes,
    disabled,
    onSelect,
    visibleOnMount,
    onVisible,
  } = props;

  const [isVisible, setIsVisible] = useState(!!visibleOnMount);

  const handleToggleVisibility = () => {
    if (typeof onVisible === 'function') onVisible();
    setIsVisible(true);
  };

  if (!isVisible) {
    return (
      <div className={classNames.toggableAreaWrapper} onClick={handleToggleVisibility}>
        <div className={classNames.toggableTextWrapper}>
          <LinkText variant="b">+ {t('addOptionalArrivalTime')}</LinkText>
        </div>

        <div className={classNames.formItemWrapper}>
          <FormItem required={required} label={label} style={{ marginBottom: 5 }}>
            <TimePicker disabled value={value} />
          </FormItem>
        </div>
      </div>
    );
  }

  return (
    <FormItem required={required} name={name} label={label} style={{ marginBottom: 5 }}>
      <TimePicker
        disabled={disabled}
        value={value}
        onChange={onSelect}
        availableMinutes={availableMinutes}
      />
    </FormItem>
  );
};

export default TogglableArrivalTimeFormItem;
