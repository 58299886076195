import React from 'react';

import { selectStoreCountries, useStoreSelector } from '../../utils/storeSelectors';
import Select from '../Select';

const CountrySelect = props => {
  const supportedCountries = useStoreSelector(selectStoreCountries, []);

  return (
    <Select
      {...props}
      autoComplete="country"
      options={supportedCountries.map(c => ({
        label: c.name,
        value: c.code,
      }))}
    />
  );
};

export default CountrySelect;
