import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { withNamespaces } from 'react-i18next';
import './style.scss';

class RequiredInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dirty: props.dirty,
      value: props.defaultValue,
    }
  }

  componentDidUpdate(preProps) {
    if (!preProps.dirty && this.props.dirty) {
      this.setState({ dirty: true })
    }
  }

  getClassName = () => {
    const { dirty, value } = this.state
    let className = `required-input ${this.props.className}`;

    if (dirty && this.props.required && !value) {
      className = `${className} required`
    }

    return className
  }

  onChange = e => {
    this.setState({ dirty: true, value: e.target.value })
    this.props.onChange(e)
  }

  render() {
    return (
      <Input
        disabled={this.props.disabled}
        defaultValue={this.props.defaultValue}
        onChange={this.onChange}
        className={this.getClassName()}
      />
    )
  }
}

RequiredInput.propTypes = {
  required: PropTypes.bool,
  dirty: PropTypes.bool,
}

RequiredInput.defaultProps = {
  required: true,
  dirty: false,
}

export default withNamespaces()(RequiredInput);