import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';

import { ERROR_MESSAGE, NAME_MAX_CHARACTER } from '../../utils/constants';
import { USER_NAME_VALIDATION_REGEXP } from '../../utils/validate';
import TextInput from '../TextInput';

const MAX_LENGTH = NAME_MAX_CHARACTER + 1;

const NameInput = ({ t, name, disabled, ...rest }) => {
  return (
    <div>
      <Form.Item
        name={name}
        {...rest}
        validateTrigger={['onSubmit', 'onBlur']}
        rules={[
          {
            required: true,
            message:
              name === 'firstName'
                ? t('pleaseEnterFirstName')
                : name === 'lastName'
                ? t('pleaseEnterLastName')
                : ERROR_MESSAGE().BLANK_FIELD,
          },
          {
            min: 2,
            message: ERROR_MESSAGE().STRING_MIN_LENGTH(2),
          },
          {
            pattern: USER_NAME_VALIDATION_REGEXP,
            message: t('numberOrSpecialCharsNotAllowed'),
          },
          {
            max: NAME_MAX_CHARACTER,
            message: ERROR_MESSAGE().STRING_MAX_LENGTH(NAME_MAX_CHARACTER),
          },
        ]}
      >
        <TextInput
          disabled={disabled}
          maxLength={MAX_LENGTH}
          placeholder={
            !rest.label && name === 'firstName'
              ? t('firstName')
              : !rest.label && name === 'lastName'
              ? t('lastName')
              : null
          }
        />
      </Form.Item>
    </div>
  );
};

NameInput.propTypes = {
  disabled: PropTypes.bool,
};

NameInput.defaultProps = {
  disabled: false,
};

export default withNamespaces()(NameInput);
