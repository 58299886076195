import React from 'react';
import { Trans, withNamespaces } from 'react-i18next';

import { EXTERNAL_LINKS } from '../../enum';
import useSettingsLinksQuery from '../../hooks/queries/useSettingsLinksQuery';
import { ERROR_MESSAGE } from '../../utils/constants';
import Checkbox from '../Checkbox';
import FormItem from '../Form/FormItem';
import Text from '../Text';
import LinkText from '../Text/LinkText';

const RegistrationTerms = ({ t, isActivation }) => {
  const { data, isFetching } = useSettingsLinksQuery(t);

  return (
    <FormItem
      required
      name="approved"
      className="no-margin-bottom"
      valuePropName="checked"
      validateTrigger={['onSubmit']}
      rules={[
        () => ({
          validator(rule, checked) {
            if (!checked) {
              return Promise.reject(ERROR_MESSAGE().MUST_ACCEPT_TOS);
            }

            return Promise.resolve();
          },
        }),
      ]}
    >
      <Checkbox disabled={isFetching}>
        <Text size="sm">
          <Trans
            t={t}
            i18nKey={
              isActivation ? 'activatingAccountTermsAndPrivacy' : 'creatingAccountTermsAndPrivacy'
            }
            components={[
              <LinkText
                size="sm"
                renderAs="a"
                target="_blank"
                rel="noopener noreferrer"
                href={data?.termsOfService || '#'}
              >
                TOS
              </LinkText>,
              <LinkText
                size="sm"
                renderAs="a"
                target="_blank"
                rel="noopener noreferrer"
                href={data?.privacyPolicy || '#'}
              >
                Privacy
              </LinkText>,
              <LinkText
                size="sm"
                renderAs="a"
                target="_blank"
                rel="noopener noreferrer"
                href={EXTERNAL_LINKS.DWOLLA_TERMS_OF_SERVICE}
              >
                Dwolla TOS
              </LinkText>,
              <LinkText
                size="sm"
                renderAs="a"
                target="_blank"
                rel="noopener noreferrer"
                href={EXTERNAL_LINKS.DWOLLA_PRIVACY_POLICY}
              >
                Dwolla Privacy
              </LinkText>,
            ]}
          />
        </Text>
      </Checkbox>
    </FormItem>
  );
};

RegistrationTerms.propTypes = {};

export default withNamespaces()(RegistrationTerms);
