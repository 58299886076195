import './i18n';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
// import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    debug: process.env.NODE_ENV === 'development',
    enabled: process.env.NODE_ENV !== 'development',
    release: process.env.REACT_APP_VERSION,

    // This error can be safely ignored
    ignoreErrors: [
      /ResizeObserver/gi,
      /AxiosError/gi,
      /Request failed with status/gi,
      /ChunkLoad/gi,
    ],
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
