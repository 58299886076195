import { isBoolean } from 'lodash';

import { RATE_FORM_WIZARD_STEPS } from '../../enum/Rates';
import { createReducer } from '../../utils/storeCreators';
import * as actions from '../actions/rateWizard';
import initialState from '../initialState';

const rateWizardReducer = createReducer({
  initialState: initialState.rateWizard,
  reducer: (state, action, defaultState) => {
    switch (action.type) {
      case actions.SETUP_RATE_WIZARD:
        return { ...state, isSettingUpWizard: true };

      case actions.RATE_WIZARD_SET_CURRENT_STEP:
        return { ...state, currentStep: action.data.currentStep };

      case actions.RESET_RATE_WIZARD_VALUES:
        return {
          ...state,
          currentStep: RATE_FORM_WIZARD_STEPS.RATE_FORM,
          rateValues: state.initialValues,
        };

      case actions.UPDATE_WIZARD_RATE_VALUES:
        return {
          ...state,
          rateValues: { ...state.rateValues, ...action.data },
        };

      case actions.UPDATE_RATE_WIZARD_STORE:
        return { ...state, ...action.data };

      case actions.START_WIZARD_RATE_SUBMIT:
        return { ...state, isSubmitting: true };

      case actions.COMPLETE_WIZARD_RATE_SUBMIT:
        return { ...state, isSubmitted: true, isSubmitting: false };

      case actions.FAIL_WIZARD_RATE_SUBMIT:
        return { ...state, isSubmitting: false };

      case actions.UPDATE_WIZARD_DIRTY_STATE:
        return {
          ...state,
          isDirty: action.data.isDirty,
          isSelectedVehicleDirty: isBoolean(action.data.isSelectedVehicleDirty)
            ? action.data.isSelectedVehicleDirty
            : false,
        };

      case actions.RESET_RATE_WIZARD:
        return defaultState;
      default:
        return state;
    }
  },
});

export default rateWizardReducer;
