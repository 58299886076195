import { Col, Row } from 'antd';
import React from 'react';

import Switch from '../../../components/Switch';
import Text from '../../../components/Text';

const CommuteSettingsToggleRow = props => {
  const { t, checked, disabledForm, isSubmitting, onCommuteSettingsChange } = props;

  return (
    <Row gutter={[6, 6]} align="middle" style={{ marginBottom: 16 }}>
      <Col>
        <Text renderAs="p" variant="b" size="sm">
          {t('commuteSettings')}
        </Text>
      </Col>
      <Col>
        <Switch
          disabled={isSubmitting || disabledForm}
          checked={checked}
          onChange={checked => onCommuteSettingsChange({ enabled: checked })}
        />
      </Col>
    </Row>
  );
};

export default CommuteSettingsToggleRow;
