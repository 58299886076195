import { Col } from 'antd';
import classnames from 'classnames';
import React from 'react';

import { ReactComponent as DownloadIconSVG } from '../../images/download-icon.svg';
import Text from '../Text';
import classNames from './styles.module.scss';

const DownloadIcon = ({ t, onClick, loading, disabled, text, colWidth, ...rest }) => {
  return (
    <Col
      flex={colWidth}
      className={classnames(classNames.actionIcon, {
        [classNames.loading]: loading,
        [classNames.disabled]: disabled,
      })}
      tabIndex={loading || disabled ? undefined : 0}
      onClick={loading || disabled ? undefined : onClick}
      onKeyPress={event => {
        if (event.key === 'Enter' && typeof onClick === 'function' && !(loading || disabled)) {
          onClick();
        }
      }}
      {...rest}
    >
      <DownloadIconSVG />
      <Text size="sm">{text || t('Export')}</Text>
    </Col>
  );
};

DownloadIcon.defaultProps = {
  colWidth: '59px',
};

export default DownloadIcon;
