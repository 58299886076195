import { Col, DatePicker, Row } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import LocationsAPI from '../../../api/locations';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { removeAccentedCharactersFromString, setModalFullscreenWidth } from '../../../utils/common';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { getMinutesAsMilliseconds } from '../../../utils/numbers';
import { selectStoreCurrentAuthUser } from '../../../utils/storeSelectors';
import { formatAddressIntoString } from '../../../utils/trips';
import Checkbox from '../../Checkbox';
import CountrySelect from '../../CountrySelect';
import FormItem from '../../Form/FormItem';
import { Button, Modal } from '../../index';
import LocationInput from '../../LocationInput';
import Spinner from '../../Spinner';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';
import TextInput from '../../TextInput';
import TimePicker from '../../TimePicker';
import Tooltip from '../../Tooltip';
import TripTypeSelect from '../../TripTypeSelect';

/**
 * Modal with a form to create a new Trip
 */
const CreateTripModal = props => {
  const { t, visible, onOk, onCancel, isCreatingTrip } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);
  const { isMobile } = useSelector(state => state.browser);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const userRateCountry = useMemo(() => {
    return get(authUser, 'profile.group.productId.country', 'US');
  }, [authUser]);

  const [startCountry, setStartCountry] = useState(userRateCountry);
  const [endCountry, setEndCountry] = useState(userRateCountry);

  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [isUsingHomeAddress, setIsUsingHomeAddress] = useState(false);
  const [isKliksCalculatingEndTime, setIsKliksCalculatingEndTime] = useState(true);
  const [userHomeAddressString] = useState(
    removeAccentedCharactersFromString(
      formatAddressIntoString(authUser.profile.personalInfor.homeAddress),
    ),
  );

  const [inputStartLocation, setInputStartLocation] = useState();
  const [startLocation, setStartLocation] = useState();
  const [fullStartAddress, setFullStartAddress] = useState();
  const [isFetchingStartLocation, setIsFetchingStartLocation] = useState(false);
  const [enableStartLocationCoordinates, setEnableStartLocationCoordinates] = useState(false);
  const [startAddressCoordinates, setStartAddressCoordinates] = useDebouncedState(undefined, 500);
  const [startLocationError, setStartLocationError] = useState();

  const [inputEndLocation, setInputEndLocation] = useState();
  const [endLocation, setEndLocation] = useState();
  const [fullEndAddress, setFullEndAddress] = useState();
  const [isFetchingEndLocation, setIsFetchingEndLocation] = useState(false);
  const [enableEndLocationCoordinates, setEnableEndLocationCoordinates] = useState(false);
  const [endAddressCoordinates, setEndAddressCoordinates] = useDebouncedState(undefined, 500);
  const [endLocationError, setEndLocationError] = useState();

  const [purpose, setPurpose] = useState('business');

  const isLocationVerified = !!startLocation && !!endLocation;
  const isFormFilled =
    isLocationVerified & !!startDate &&
    !!startTime &&
    (isKliksCalculatingEndTime || (!!endDate && !!endTime));

  const userHomeCountry = useMemo(() => {
    if (!get(authUser, 'profile.personalInfor.homeAddress.country')) return 'US';
    return get(authUser, 'profile.personalInfor.homeAddress.country', 'US');
  }, [authUser]);

  const userHomeAddress = useMemo(() => {
    if (!get(authUser, 'profile.personalInfor.homeAddress.streetOne')) return '';

    return removeAccentedCharactersFromString(
      formatAddressIntoString(authUser.profile.personalInfor.homeAddress),
    );
  }, [authUser]);

  const homeAddressQuery = useQuery({
    enabled: isUsingHomeAddress,
    staleTime: getMinutesAsMilliseconds(10),
    queryKey: ['fetchAddressDetailsFromString', authUser.profile._id],
    queryFn: () => {
      return new LocationsAPI().fetchAddressDetailsFromString(
        userHomeAddressString,
        userHomeCountry,
      );
    },
    onSettled: () => {
      setIsUsingHomeAddress(false);
    },
  });

  useEffect(() => {
    if (!visible) {
      // Reset form on modal close
      setStartDate(undefined);
      setEndDate(undefined);
      setStartTime(undefined);
      setEndTime(undefined);

      setStartCountry(userRateCountry);
      setEndCountry(userRateCountry);

      setEnableStartLocationCoordinates(false);
      setInputStartLocation(undefined);
      setStartLocation(undefined);
      setStartAddressCoordinates(undefined);
      setStartLocationError(undefined);

      setEnableEndLocationCoordinates(false);
      setInputEndLocation(undefined);
      setEndLocation(undefined);
      setEndAddressCoordinates(undefined);
      setEndLocationError(undefined);
      setIsKliksCalculatingEndTime(true);

      setPurpose('business');
    }
    // eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    if (isKliksCalculatingEndTime) {
      setEndDate(startDate);
      setEndTime();
    }
  }, [startDate, isKliksCalculatingEndTime]);

  const toggleStartLocationCoordinates = () => {
    setEnableStartLocationCoordinates(state => !state);
    setInputStartLocation(undefined);
    setStartLocation(undefined);
    setStartAddressCoordinates(undefined);
    setStartLocationError(undefined);
    setFullStartAddress(undefined);
  };

  const toggleEndLocationCoordinates = () => {
    setEnableEndLocationCoordinates(state => !state);
    setInputEndLocation(undefined);
    setEndLocation(undefined);
    setEndAddressCoordinates(undefined);
    setEndLocationError(undefined);
    setFullEndAddress(undefined);
  };

  const setHomeStartLocation = () => {
    setEnableStartLocationCoordinates(false);
    setStartAddressCoordinates(undefined);
    setStartLocationError(undefined);
    setIsUsingHomeAddress(true);

    setInputStartLocation(userHomeAddress);
    setStartLocation(userHomeAddress);
    setStartCountry(userHomeCountry);
  };

  const setHomeEndLocation = () => {
    setEnableEndLocationCoordinates(false);
    setEndAddressCoordinates(undefined);
    setEndLocationError(undefined);
    setIsUsingHomeAddress(true);

    setInputEndLocation(userHomeAddress);
    setEndLocation(userHomeAddress);
    setEndCountry(userHomeCountry);
  };

  const handleStartAddressChange = useCallback(
    (addressString, fullAddress) => {
      setStartLocationError(undefined);
      setInputStartLocation(addressString);
      if (fullAddress) {
        setIsUsingHomeAddress(addressString === userHomeAddressString);
        setFullStartAddress(fullAddress);
      }
    },
    [userHomeAddressString],
  );

  const handleStartAddressSelect = useCallback(
    (addressString, fullAddress) => {
      setStartLocation(addressString);
      handleStartAddressChange(addressString, fullAddress);
    },
    [handleStartAddressChange],
  );

  const handleEndAddressChange = useCallback(
    (addressString, fullAddress) => {
      setEndLocationError(undefined);
      setInputEndLocation(addressString);
      if (fullAddress) {
        setIsUsingHomeAddress(addressString === userHomeAddressString);
        setFullEndAddress(fullAddress);
      }
    },
    [userHomeAddressString],
  );

  const handleEndAddressSelect = useCallback(
    (addressString, fullAddress) => {
      setEndLocation(addressString);
      handleEndAddressChange(addressString, fullAddress);
    },
    [handleEndAddressChange],
  );

  const fetchStartAddressFromCoordinates = useCallback(async (latitude, longitude) => {
    if (!latitude || !longitude) return;
    if (longitude === '-' || latitude === '-') return;

    setIsFetchingStartLocation(true);
    setStartLocation();
    setInputStartLocation();
    setStartLocationError();

    try {
      const address = await new LocationsAPI().fetchAddressFromCoordinates(latitude, longitude);
      if (address?.countryCode && address?.formattedAddress) {
        const addressWithoutAccents = removeAccentedCharactersFromString(address.formattedAddress);
        setStartLocation(addressWithoutAccents);
        setInputStartLocation(addressWithoutAccents);
        setFullStartAddress(address);
      } else {
        setStartLocationError(ERROR_MESSAGE().INVALID_GPS_COORDINATES);
      }
    } catch (error) {
      setStartLocationError(ERROR_MESSAGE().INVALID_GPS_COORDINATES);
    }

    setIsFetchingStartLocation(false);
  }, []);

  const fetchEndAddressFromCoordinates = useCallback(async (latitude, longitude) => {
    if (!latitude || !longitude) return;
    if (longitude === '-' || latitude === '-') return;

    setIsFetchingEndLocation(true);
    setEndLocation();
    setInputEndLocation();
    setEndLocationError();

    try {
      const address = await new LocationsAPI().fetchAddressFromCoordinates(latitude, longitude);
      if (address?.countryCode && address?.formattedAddress) {
        const addressWithoutAccents = removeAccentedCharactersFromString(address.formattedAddress);
        setEndLocation(addressWithoutAccents);
        setInputEndLocation(addressWithoutAccents);
        setFullEndAddress(address);
      } else {
        setEndLocationError(ERROR_MESSAGE().INVALID_GPS_COORDINATES);
      }
    } catch (error) {
      setEndLocationError(ERROR_MESSAGE().INVALID_GPS_COORDINATES);
    }

    setIsFetchingEndLocation(false);
  }, []);

  useDidUpdateEffect(() => {
    if (
      typeof startAddressCoordinates !== 'undefined' &&
      startAddressCoordinates.split(',').length === 2
    ) {
      const [latitude, longitude] = startAddressCoordinates.split(',');
      fetchStartAddressFromCoordinates(latitude.trim(), longitude.trim());
    }
  }, [startAddressCoordinates]);

  useDidUpdateEffect(() => {
    if (
      typeof endAddressCoordinates !== 'undefined' &&
      endAddressCoordinates.split(',').length === 2
    ) {
      const [latitude, longitude] = endAddressCoordinates.split(',');
      fetchEndAddressFromCoordinates(latitude.trim(), longitude.trim());
    }
  }, [endAddressCoordinates]);

  return (
    <Modal
      width={setModalFullscreenWidth(900)}
      centered
      destroyOnClose
      closable={!isCreatingTrip}
      className="trip-update-modal"
      title={<div className="trip-update-modal-title">{t('New Trip')}</div>}
      visible={visible || isCreatingTrip}
      onCancel={onCancel}
      footer={
        <Row justify="end">
          <Col>
            <Tooltip
              arrowPointAtCenter
              title={isFormFilled ? t('clickToCreateTrip') : t('fillFormToCreateTrip')}
              {...(isCreatingTrip ? { visible: false } : {})}
            >
              <Button
                disabled={!isFormFilled || isCreatingTrip}
                className={'btn-create-manual'}
                text={t('createManualTrip')}
                onClick={() => {
                  const startAddress =
                    startLocation === userHomeAddressString
                      ? homeAddressQuery.data
                      : fullStartAddress;

                  const endAddress =
                    endLocation === userHomeAddressString ? homeAddressQuery.data : fullEndAddress;

                  if (!startAddress || inputStartLocation !== startLocation) {
                    setStartLocationError(t('mustSelectValidStartAddress'));
                  }

                  if (!endAddress || inputEndLocation !== endLocation) {
                    setEndLocationError(t('mustSelectValidDestination'));
                  }

                  if (inputStartLocation === startLocation && inputEndLocation === endLocation) {
                    onOk({
                      startDate,
                      endDate,
                      startTime,
                      endTime,
                      startLocation,
                      endLocation,
                      purpose,
                      fullStartAddress:
                        startLocation === userHomeAddressString
                          ? homeAddressQuery.data
                          : fullStartAddress,
                      fullEndAddress:
                        endLocation === userHomeAddressString
                          ? homeAddressQuery.data
                          : fullEndAddress,
                      isFromAddressGPSCoordinates: enableStartLocationCoordinates,
                      isToAddressGPSCoordinates: enableEndLocationCoordinates,
                      isKliksCalculatingEndTime,
                    });
                  }
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      }
    >
      <Spinner spinning={isCreatingTrip}>
        <Row gutter={isMobile ? [8, 8] : [32, 32]}>
          <Col xs={24} md={12}>
            <FormItem label={t('purpose')}>
              <TripTypeSelect value={purpose} onChange={setPurpose} />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={isMobile ? [8, 8] : [32, 32]} wrap={true}>
          <Col
            xs={24}
            md={12}
            style={{
              borderRight: isMobile ? 'none' : '1px solid #EEEFF1',
            }}
          >
            <Row>
              <Text variant="h5">{t('departure')}</Text>
            </Row>

            {!enableStartLocationCoordinates && (
              <Row>
                <Col flex={1} className="input-container">
                  <FormItem label={t('country')}>
                    <CountrySelect
                      name="startCountry"
                      autoComplete="country"
                      value={startCountry}
                      onChange={setStartCountry}
                    />
                  </FormItem>
                </Col>
              </Row>
            )}

            {enableStartLocationCoordinates && (
              <Row justify="space-between" gutter={15} style={{ marginBottom: 5 }}>
                <Col flex={1} className="input-container">
                  <FormItem
                    className="no-margin-bottom"
                    validateStatus={startLocationError ? 'error' : 'success'}
                    label={
                      <Row gutter={6} wrap={false}>
                        <Col>{t('addressCoordinates')}</Col>
                        <Col>
                          <LinkText size="xs" onClick={toggleStartLocationCoordinates}>
                            ({t('useAddress')})
                          </LinkText>
                        </Col>
                      </Row>
                    }
                  >
                    <TextInput
                      placeholder="42.360084672090935, -71.05888403450204"
                      onChange={e => setStartAddressCoordinates(e.target.value)}
                    />
                  </FormItem>
                </Col>
              </Row>
            )}

            <Row justify="space-between" gutter={15}>
              <Col flex={1} className="input-container">
                <FormItem
                  label={
                    <Row gutter={6} wrap={false}>
                      <Col>{t('address')}</Col>
                      {!enableStartLocationCoordinates && (
                        <Col>
                          <LinkText size="xs" onClick={toggleStartLocationCoordinates}>
                            ({t('useGpsCoordinates')})
                          </LinkText>
                        </Col>
                      )}
                    </Row>
                  }
                  errorMessage={startLocationError}
                  validateStatus={startLocationError ? 'error' : 'success'}
                  extra={
                    !!userHomeAddress &&
                    inputStartLocation !== userHomeAddress && (
                      <Button size="xs" onClick={setHomeStartLocation} style={{ marginTop: 5 }}>
                        {t('useHomeAddress')}
                      </Button>
                    )
                  }
                >
                  {enableStartLocationCoordinates ? (
                    <TextInput
                      disabled
                      value={startLocation}
                      placeholder={isFetchingStartLocation ? 'Searching...' : undefined}
                    />
                  ) : (
                    <LocationInput
                      value={inputStartLocation}
                      defaultValue={startLocation}
                      onSelect={handleStartAddressSelect}
                      onChange={handleStartAddressChange}
                      limitedToCountryCode={startCountry}
                      onBlur={() => {
                        if (startLocation !== inputStartLocation) {
                          setInputStartLocation(startLocation);
                        }
                      }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Row>
              <Text variant="h5">{t('destination')}</Text>
            </Row>

            {!enableEndLocationCoordinates && (
              <Row>
                <Col flex={1} className="input-container">
                  <FormItem label={t('country')}>
                    <CountrySelect
                      name="endCountry"
                      autoComplete="country"
                      value={endCountry}
                      onChange={setEndCountry}
                    />
                  </FormItem>
                </Col>
              </Row>
            )}

            {enableEndLocationCoordinates && (
              <Row justify="space-between" gutter={15} style={{ marginBottom: 5 }}>
                <Col flex={1} className="input-container">
                  <FormItem
                    className="no-margin-bottom"
                    validateStatus={endLocationError ? 'error' : 'success'}
                    label={
                      <Row gutter={6} wrap={false}>
                        <Col>{t('addressCoordinates')}</Col>
                        <Col>
                          <LinkText size="xs" onClick={toggleEndLocationCoordinates}>
                            ({t('useAddress')})
                          </LinkText>
                        </Col>
                      </Row>
                    }
                  >
                    <TextInput
                      placeholder="42.360084672090935, -71.05888403450204"
                      onChange={e => setEndAddressCoordinates(e.target.value)}
                    />
                  </FormItem>
                </Col>
              </Row>
            )}

            <Row justify="space-between" gutter={15}>
              <Col flex={1} className="input-container">
                <FormItem
                  label={
                    <Row gutter={6} wrap={false}>
                      <Col>{t('address')}</Col>
                      {!enableEndLocationCoordinates && (
                        <Col>
                          <LinkText size="xs" onClick={toggleEndLocationCoordinates}>
                            ({t('useGpsCoordinates')})
                          </LinkText>
                        </Col>
                      )}
                    </Row>
                  }
                  errorMessage={endLocationError}
                  validateStatus={endLocationError ? 'error' : 'success'}
                  extra={
                    !!userHomeAddress &&
                    inputEndLocation !== userHomeAddress && (
                      <Button size="xs" onClick={setHomeEndLocation} style={{ marginTop: 5 }}>
                        {t('useHomeAddress')}
                      </Button>
                    )
                  }
                >
                  {enableEndLocationCoordinates ? (
                    <TextInput
                      disabled
                      value={endLocation}
                      placeholder={isFetchingEndLocation ? 'Searching...' : undefined}
                    />
                  ) : (
                    <LocationInput
                      value={inputEndLocation}
                      defaultValue={endLocation}
                      onSelect={handleEndAddressSelect}
                      onChange={handleEndAddressChange}
                      limitedToCountryCode={endCountry}
                      onBlur={() => {
                        if (endLocation !== inputEndLocation) {
                          setInputEndLocation(endLocation);
                        }
                      }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={isMobile ? [8, 8] : [32, 32]} wrap={true}>
          <Col
            xs={24}
            md={12}
            style={{
              borderRight: isMobile ? 'none' : '1px solid #EEEFF1',
            }}
          >
            <Row gutter={16} wrap={false} align="middle">
              <Col flex={1}>
                <FormItem label={t('startDate')}>
                  <DatePicker
                    allowClear
                    style={{ width: '100%' }}
                    value={startDate}
                    onChange={date => {
                      setStartDate(date);
                      if (!endDate) setEndDate(date);
                    }}
                  />
                </FormItem>
              </Col>

              <Col flex="242px">
                <FormItem label={t('startTime')}>
                  <TimePicker onChange={setStartTime} />
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12}>
            <Row gutter={16} wrap={false} align="middle">
              <Col flex={1}>
                <FormItem label={t('endDate')}>
                  <DatePicker
                    allowClear
                    style={{ width: '100%' }}
                    value={endDate}
                    onChange={setEndDate}
                    disabled={isKliksCalculatingEndTime}
                  />
                </FormItem>
              </Col>
              <Col flex="242px">
                <FormItem label={t('endTime')}>
                  <TimePicker onChange={setEndTime} disabled={isKliksCalculatingEndTime} />
                </FormItem>
              </Col>
            </Row>

            <Row>
              <FormItem>
                <Checkbox
                  checked={isKliksCalculatingEndTime}
                  onChange={e => setIsKliksCalculatingEndTime(e.target.checked)}
                >
                  {t('letKliksCalculateEnd')}
                </Checkbox>
              </FormItem>
            </Row>
          </Col>
        </Row>
      </Spinner>
    </Modal>
  );
};

CreateTripModal.propTypes = {
  isCreatingTrip: PropTypes.bool,
};

CreateTripModal.defaultProps = {
  isCreatingTrip: false,
};

export default CreateTripModal;
