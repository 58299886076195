import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { IMAGES } from '../../enum';
import StatusList from '../../enum/StatusList';
import { UserRoleSelectOptions } from '../../enum/UserRoles';
import { Button } from '..';
import Box from '../Box';
import Select from '../Select';
import Text from '../Text';
import TextInput from '../TextInput';
import Tooltip from '../Tooltip';
import classNames from './style.module.scss';

const ACTION_SET_ROLE = 'ACTION_SET_ROLE';
const ACTION_SET_STATUS = 'ACTION_SET_STATUS';
const ACTION_SET_GROUP = 'ACTION_SET_GROUP';

const ACTION_LIST = t => [
  {
    label: t('setRole'),
    value: ACTION_SET_ROLE,
  },
  {
    label: t('setStatus'),
    value: ACTION_SET_STATUS,
  },
  {
    label: t('setGroup'),
    value: ACTION_SET_GROUP,
  },
];

const STATUS_LIST = t => [
  {
    label: 'Active',
    value: StatusList(t).Status.ACTIVE,
  },
  {
    label: 'Inactive',
    value: StatusList(t).Status.INACTIVE,
  },
];

/**
 * White Actions box with a Search input and bulk actions used on the User Manager page
 *
 * @param {object} props  Component props
 */
const UserManagerTableActionsBox = props => {
  const {
    t,
    displayBulkActions,
    groupList,
    actionValue,
    subActionValue,
    onDownload,
    onActionSelect,
    onSubActionSelect,
    onSearch,
    onSave,
    disabled,
  } = props;

  return (
    <Box padding="6px 16px">
      <Row align="middle">
        <Col flex={1} style={{ marginRight: '20px' }}>
          {displayBulkActions ? (
            <Row gutter={15} align="middle">
              <Col flex="0 1 60px">
                <Tooltip title={t('downloadUsersData')}>
                  <img
                    className={classNames.downloadIcon}
                    src={IMAGES.DOWNLOAD_CIRCLE_ICON}
                    alt="dowload"
                    onClick={onDownload}
                  />
                </Tooltip>
              </Col>
              <Col flex={1}>
                <Row gutter={[15, 5]} align="middle" className={classNames.toolboxRow}>
                  <Col>
                    <Text variant="p" size="sm" renderAs="div">
                      {t('bulkActions')}
                    </Text>
                  </Col>
                  <Col flex="1">
                    <Select
                      value={actionValue}
                      placeholder={t('select')}
                      options={ACTION_LIST(t)}
                      style={{ width: '100%' }}
                      onChange={onActionSelect}
                      disabled={disabled}
                    />
                  </Col>
                  <Col flex="1">
                    <Select
                      style={{ width: '100%' }}
                      value={subActionValue}
                      onChange={onSubActionSelect}
                      disabled={
                        ![ACTION_SET_ROLE, ACTION_SET_GROUP, ACTION_SET_STATUS].includes(
                          actionValue,
                        ) || disabled
                      }
                      placeholder={t('select')}
                      options={[
                        ...(actionValue === ACTION_SET_ROLE ? UserRoleSelectOptions : []),
                        ...(actionValue === ACTION_SET_STATUS ? STATUS_LIST(t) : []),
                        ...(actionValue === ACTION_SET_GROUP
                          ? groupList.map(group => ({
                              value: group._id,
                              label: group.name,
                            }))
                          : []),
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <TextInput
              disabled={disabled}
              style={{ width: '100%', border: 'none' }}
              type="text"
              size="large"
              placeholder={t('search')}
              onChange={onSearch}
              prefix={
                <img
                  width="18px"
                  src={IMAGES.SEARCH_ICON}
                  alt="remove"
                  style={{ marginRight: '8px' }}
                />
              }
            />
          )}
        </Col>
        <Col
          className={[
            classNames.lastActionCol,
            displayBulkActions ? classNames.increasedHeight : undefined,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          <Button
            disabled={!displayBulkActions || !actionValue || !subActionValue || disabled}
            size="xs"
            className={classNames.saveButton}
            onClick={onSave}
          >
            {t('Save')}
          </Button>
        </Col>
      </Row>
    </Box>
  );
};

UserManagerTableActionsBox.propTypes = {
  displayBulkActions: PropTypes.bool.isRequired,
  groupList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actionValue: PropTypes.string,
  subActionValue: PropTypes.string,
  onActionSelect: PropTypes.func.isRequired,
  onSubActionSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UserManagerTableActionsBox;
