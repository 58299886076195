import { Popover as AntdPopover } from 'antd';
import React from 'react';

import Text from '../Text';
import classNames from './style.module.scss';

const Popover = props => {
  const { children, title, noPadding, overlayClassNames, ...rest } = props;

  return (
    <AntdPopover
      arrowPointAtCenter
      title={
        typeof title === 'string' ? (
          <Text variant="strong" size="sm">
            {title}
          </Text>
        ) : (
          title
        )
      }
      {...rest}
      overlayClassName={[
        classNames.popoverOverlay,
        noPadding ? classNames.noPadding : undefined,
        overlayClassNames,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <div>{children}</div>
    </AntdPopover>
  );
};

export default Popover;
