import { Col, Row } from 'antd';
import React, { useState } from 'react';

import { handleApiErrors } from '../../../api/axiosInstance';
import { ConfirmModal } from '../../../components';
import Box from '../../../components/Box';
import SimpleEditPopoverForm from '../../../components/Forms/SimpleEditPopoverForm';
import DomainsTable from '../../../components/Table/DomainsTable';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';

const DomainsSection = props => {
  const { t, allowedDomains, blockedDomains, onSettingsSave } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteCallback, setDeleteCallback] = useState();

  const handleDomainCreate = async (type, domainsArray, value) => {
    const domainsNameList = domainsArray.map(v => v.name);

    if (domainsNameList.includes(value)) {
      return Toast({
        type: 'error',
        message: t('domainAlreadyInList'),
      });
    }

    setIsCreating(true);

    try {
      await onSettingsSave({
        [type]: [...domainsNameList, value],
      });

      Toast({
        type: 'open',
        message: t('domainCreateSuccess'),
      });

      document.querySelector('#hide-simple-form-popover').click();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('domainCreateError'),
        });
      });
    }

    setIsCreating(false);
  };

  const handleDomainUpdate = async (type, domainsArray, prevValue, newValue) => {
    const domainsNameList = domainsArray.map(v => v.name);

    if (domainsNameList.includes(newValue)) {
      return Toast({
        type: 'error',
        message: t('domainAlreadyInList'),
      });
    }

    setIsUpdating(true);

    try {
      await onSettingsSave({
        [type]: domainsNameList.filter(v => v !== prevValue).concat([newValue]),
      });

      Toast({
        type: 'open',
        message: t('domainUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('domainUpdateError'),
        });
      });
    }

    setIsUpdating(false);
  };

  const handleDomainDelete = async (type, domainsArray, value) => {
    setIsDeleting(true);

    const domainsNameList = domainsArray.map(v => v.name);

    try {
      await onSettingsSave({
        [type]: domainsNameList.filter(v => v !== value),
      });
      Toast({
        type: 'open',
        message: t('domainDeleteSuccess'),
      });
      setDeleteCallback();
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('domainDeleteError'),
        });
      });
    }

    setIsDeleting(false);
  };

  const renderAddNew = (title, onSubmit) => {
    return (
      <Row justify="end">
        <Col>
          <SimpleEditPopoverForm
            t={t}
            title={title}
            inputName="name"
            isSubmitting={isCreating}
            onSubmit={onSubmit}
            placement="topLeft"
          >
            <LinkText>{t('Add new')}</LinkText>
          </SimpleEditPopoverForm>
        </Col>
      </Row>
    );
  };

  return (
    <Row id="domains">
      <Col flex={1}>
        <Box>
          <Text variant="h5">{t('domains')}</Text>
          <br />
          <Text variant="strong" renderAs="p">
            {t('allowedDomains')}
          </Text>
          <br />
          <DomainsTable
            t={t}
            isSubmitting={isUpdating}
            loading={!allowedDomains}
            dataSource={allowedDomains}
            popoverTitle={t('editAllowedDomain')}
            onDelete={name =>
              setDeleteCallback({
                callback: () => handleDomainDelete('allowedDomains', allowedDomains, name),
              })
            }
            onEdit={({ prevValue, newValue }) =>
              handleDomainUpdate('allowedDomains', allowedDomains, prevValue, newValue)
            }
          />
          {renderAddNew(t('addAllowedDomain'), ({ name }) =>
            handleDomainCreate('allowedDomains', allowedDomains, name),
          )}

          <br />

          <Text variant="strong" renderAs="p">
            {t('blockedDomains')}
          </Text>
          <br />
          <DomainsTable
            t={t}
            isSubmitting={isUpdating}
            loading={!blockedDomains}
            dataSource={blockedDomains}
            popoverTitle={t('editBlockedDomain')}
            onDelete={name =>
              setDeleteCallback({
                callback: () => handleDomainDelete('blockedDomains', blockedDomains, name),
              })
            }
            onEdit={({ prevValue, newValue }) =>
              handleDomainUpdate('blockedDomains', blockedDomains, prevValue, newValue)
            }
          />
          {renderAddNew(t('addBlockedDomain'), ({ name }) =>
            handleDomainCreate('blockedDomains', blockedDomains, name),
          )}
        </Box>
      </Col>

      <ConfirmModal
        loading={isDeleting}
        visible={typeof deleteCallback?.callback === 'function'}
        message={t('sureYouWantToDeleteItem')}
        onCancel={() => setDeleteCallback()}
        okText={t('Delete')}
        onOk={deleteCallback?.callback}
      />
    </Row>
  );
};

export default DomainsSection;
