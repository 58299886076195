import { useCallback, useEffect, useState } from 'react';

import { handleApiErrors } from '../../api/axiosInstance';
import { fetchItineraryDetails, shareItinerary, updateItinerary } from '../../api/itinerary';
import useDebouncedState from '../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useItineraryMap from '../../hooks/useItineraryMap';
import useModal from '../../hooks/useModal';
import { selectStoreItineraryClientByID } from '../../utils/storeSelectors';

const useItineraryDetails = ({ id, isPublic }) => {
  const [isLoadingItinerary, setIsLoadingItinerary] = useState(false);
  const [itineraryDetails, setItineraryDetails] = useState();
  const [isOptimalRoute, setIsOptimalRoute] = useState(false);
  const [isMounted, setIsMounted] = useDebouncedState(false, 700);
  const [debouncedIsOptimalRoute, setDebouncedIsOptimalRoute] = useDebouncedState(
    isOptimalRoute,
    700,
  );
  const [isUpdatingItinerary, setIsUpdatingItinerary] = useState(false);
  const [isSharingItinerary, setIsSharingItinerary] = useState(false);
  const [isNoteChanged, setIsNoteChanged] = useState(false);
  const [itineraryShareMessage, setItineraryShareMessage] = useDebouncedState('', 500);

  const [isItineraryModalOpen, openItineraryModal, closeItineraryModal] = useModal();
  const [isShareModalOpen, openShareModal, closeShareModal] = useModal();

  const {
    coordinatesCenter,
    itineraryMarkers,
    optimizedItineraryMarkers,
    destinationLabelsChangedOnOptimization,
  } = useItineraryMap(itineraryDetails);

  const handleItineraryShareMessageChange = useCallback(
    message => {
      setItineraryShareMessage(message);
    },
    [setItineraryShareMessage],
  );

  const loadItineraryDetails = useCallback(async () => {
    setIsLoadingItinerary(true);

    try {
      const data = await fetchItineraryDetails(id);
      setItineraryDetails(data);
      setIsOptimalRoute(!!data.useOptimizedTrips);
      setDebouncedIsOptimalRoute(!!data.useOptimizedTrips);
      handleItineraryShareMessageChange(data?.shareMessage);
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsLoadingItinerary(false);
    setIsMounted(true);
  }, [id, handleItineraryShareMessageChange, setDebouncedIsOptimalRoute, setIsMounted]);

  const handleUpdateItinerary = useCallback(
    async (values, updateState = true) => {
      setIsUpdatingItinerary(true);

      try {
        const data = await updateItinerary(itineraryDetails._id, {
          ...values,
          useOptimizedTrips: isOptimalRoute,
          tripOptimizationNeeded: true,
        });
        const client = selectStoreItineraryClientByID(data.clientDetails[0]?.clientId);

        if (updateState) {
          setItineraryDetails({
            ...data,
            useOptimizedTrips: isOptimalRoute,
            clientDetails: [client ? { ...client, clientId: client } : itineraryDetails[0]],
          });
        }
        closeItineraryModal();
      } catch (error) {
        handleApiErrors(error.response);
      }

      setIsUpdatingItinerary(false);
    },
    [itineraryDetails, closeItineraryModal, isOptimalRoute],
  );

  const handleUpdateTripNote = useCallback(async (tripIndex, note) => {
    setIsNoteChanged(true);
    setItineraryDetails(itinerary => {
      const updatedTrips = itinerary.trips;
      updatedTrips[tripIndex] = {
        ...updatedTrips[tripIndex],
        itineraryNotes: note,
      };

      return {
        ...itinerary,
        trips: updatedTrips,
      };
    });
  }, []);

  const handleSaveWithoutShare = useCallback(async () => {
    await handleUpdateItinerary(
      {
        useOptimizedTrips: isOptimalRoute,

        dateFrom: itineraryDetails.dateFrom,
        dateTo: itineraryDetails.dateTo,
        userId:
          typeof itineraryDetails.userId === 'string'
            ? itineraryDetails.userId
            : itineraryDetails.userId?._id,
        startAddress: itineraryDetails.trips[0]?.tripStartLocationAddress,

        clientDetails: itineraryDetails.clientDetails.map(client => ({
          clientId: typeof client.clientId === 'string' ? client.clientId : client.clientId?._id,
          clientAddress: client.clientAddress,
        })),

        destinationAddresses: itineraryDetails.trips.map(trip => ({
          address: trip.tripEndLocationAddress,
          reachingTime: trip.tripEndTime,
          itineraryNotes: trip.itineraryNotes,
        })),
      },
      false,
    );
  }, [isOptimalRoute, itineraryDetails, handleUpdateItinerary]);

  const handleShareItinerary = useCallback(async () => {
    setIsSharingItinerary(true);

    try {
      if (isNoteChanged) {
        await handleSaveWithoutShare();
      }

      await shareItinerary(id, itineraryShareMessage);
      openShareModal();
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsSharingItinerary(false);
  }, [id, isNoteChanged, itineraryShareMessage, openShareModal, handleSaveWithoutShare]);

  const toggleOptimalRoute = useCallback(() => {
    setIsOptimalRoute(state => {
      setDebouncedIsOptimalRoute(!state);
      return !state;
    });
  }, [setDebouncedIsOptimalRoute]);

  useEffect(() => {
    loadItineraryDetails();
    // eslint-disable-next-line
  }, []);

  useDidUpdateEffect(() => {
    if (!isPublic && itineraryDetails?._id && isMounted) {
      updateItinerary(itineraryDetails._id, {
        useOptimizedTrips: debouncedIsOptimalRoute,
      });
    }
  }, [isPublic, debouncedIsOptimalRoute]);

  return {
    isUpdatingItinerary,
    isOptimalRoute,
    isLoadingItinerary,
    isItineraryModalOpen,
    isShareModalOpen,
    isSharingItinerary,
    itineraryDetails,
    itineraryShareMessage,

    coordinatesCenter,
    itineraryMarkers,
    optimizedItineraryMarkers,
    destinationLabelsChangedOnOptimization,

    openItineraryModal,
    closeItineraryModal,
    openShareModal,
    closeShareModal,
    toggleOptimalRoute,
    handleUpdateItinerary,
    handleUpdateTripNote,
    handleShareItinerary,
    handleSaveWithoutShare,
    handleItineraryShareMessageChange,
  };
};

export default useItineraryDetails;
