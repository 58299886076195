import React from 'react';
import { Select } from 'antd';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import countryCodes from './phoneSource';
const { Option } = Select;

class CountryCodeSelect extends React.Component {
  onChange = e => {
    this.props.onChange(e);
  };

  render() {
    return (
      <Select {...this.props} style={{ width: 100 }} onChange={this.onChange}>
        {countryCodes.map(code => {
          return (
            <Option key={'key-phone-code' + code.short} value={code.key || code.phoneCode}>
              +{code.phoneCode} {code.short}
            </Option>
          );
        })}
      </Select>
    );
  }
}

CountryCodeSelect.propTypes = {
  onChange: PropTypes.func,
};

CountryCodeSelect.defaultProps = {
  onChange: () => {},
};

export default enhanceWithClickOutside(CountryCodeSelect);
