import React from 'react';

import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const VehicleManagementSettingsFormItems = props => {
  const { t, authUser, companySettings, onFeatureChange } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('vehicleManagement')}>
      <FeatureFormItem
        label={t('esgScope3')}
        value={companySettings.esgScope3}
        onChange={checked => onFeatureChange({ esgScope3: checked })}
      />

      <FeatureFormItem
        label={t('vinDecoder')}
        value={companySettings.vinDecoder}
        onChange={checked => onFeatureChange({ vinDecoder: checked })}
      />
    </SettingSectionWrapper>
  );
};

export default VehicleManagementSettingsFormItems;
