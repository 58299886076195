const MEASURE_ONE_EVENTS = [
  'datasourceConnected',
  'itemsCreated',
  'exitRequested',
  'datasourceNotSupported',
  'tokenExpired',
];

export const initializeMeasureOneLink = (dataRequestId, publicToken, listener) => {
  var config = {
    access_key: publicToken,
    host_name: process.env.REACT_APP_MEASURE_ONE_HOST_NAME,
    datarequest_id: dataRequestId,
    branding: {
      styles: {
        primary_dark: '#16b296',
        primary_light: '#129873',
        secondary_color: '#ffffff',
        min_height: '700px',
      },
    },
    options: {
      display_profile: false,
    },
  };

  // Take reference to widget
  const m1_widget = document.querySelector('m1-link');

  // Add configuration
  m1_widget.setAttribute('config', JSON.stringify(config));

  // Add event listeners
  MEASURE_ONE_EVENTS.forEach(event => {
    m1_widget.addEventListener(event, listener);
  });
};

export const removeMeasureOneLinkListener = listener => {
  // Take reference to widget
  const m1_widget = document.querySelector('m1-link');
  if (m1_widget) {
    // Add event listeners
    MEASURE_ONE_EVENTS.forEach(event => {
      m1_widget.removeEventListener(event, listener);
    });
  }
};
