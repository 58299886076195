import React, { useEffect, useState } from 'react';

import { handleApiErrors } from '../../../api/axiosInstance';
import { fetchCompanyUsersMobileSettings } from '../../../api/company';
import UserMobileSettingTable from '../../../components/Table/UserMobileSettingTable';
import Toast from '../../../components/Toast';

const UsersMobileReport = props => {
  const { t, currentCompany } = props;

  const [userMobileSettings, setUserMobileSettings] = useState([]);
  const [isSearchingSettings, setIsSearchingSettings] = useState(false);

  const loadUsersMobileSettings = async () => {
    try {
      setIsSearchingSettings(true);

      const settings = await fetchCompanyUsersMobileSettings(currentCompany._id);
      setUserMobileSettings(settings);

      setIsSearchingSettings(false);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('loadUsersMobileSettingsError'),
        });
      });
    }
  };

  useEffect(() => {
    loadUsersMobileSettings();
    // eslint-disable-next-line
  }, []);

  return (
    <UserMobileSettingTable t={t} loading={isSearchingSettings} dataSource={userMobileSettings} />
  );
};

export default UsersMobileReport;
