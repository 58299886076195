import { call, put, takeLatest } from 'redux-saga/effects';

import PlansAPI from '../../api/plans';
import { PricePlansApi } from '../../api/price-plans';
import Toast from '../../components/Toast';
import i18n from '../../i18n';
import * as actions from '../actions/prices';

/**
 * Populate the Price page
 */
export function* loadPriceData(action) {
  try {
    const priceData = yield call(PricePlansApi.fetchPlanPrices, action.data.companyId);
    yield put(actions.updatePricesData(priceData));
  } catch (err) {
    Toast({
      type: 'error',
      message: i18n.t('fetchingPricesError'),
    });
    yield put(actions.updatePricesData({}));
  }
}

function* loadCustomPriceData() {
  try {
    const priceData = yield call(PlansAPI.fetchCustomPlans);
    yield put(actions.updateCustomPricesData(priceData));
  } catch (err) {
    Toast({
      type: 'error',
      message: i18n.t('fetchingPricesError'),
    });
    yield put(actions.updatePricesData({}));
  }
}

/**
 * watcher saga
 */
export const priceSaga = [
  takeLatest(actions.LOAD_PRICE_DATA, loadPriceData),
  takeLatest(actions.LOAD_CUSTOM_PRICE_DATA, loadCustomPriceData),
];
