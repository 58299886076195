import { Space } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { formatNumberToLocale, formatNumberWithCurrency } from '../../../utils/numbers';
import { renderBooleanCheckmarks } from '../../../utils/tables';
import { Button, CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import LinkText from '../../Text/LinkText';

const AnomaliesMileageAdjustmentsTable = props => {
  const { t, dataSource, onRequestNewRate, ...rest } = props;

  const COLUMNS = useMemo(
    () => [
      {
        key: ['user.firstName', 'user.lastName'].join(','),
        title: t('user'),
        dataIndex: 'user',
        render: user => <ProfileNamePicture user={user} />,
      },
      {
        key: 'group',
        title: t('Group'),
        dataIndex: ['user', 'group'],
        render: group =>
          group ? (
            <Link to={`${INTERNAL_LINKS.GROUP}/${get(group, '_id')}`}>
              <LinkText variant="b">{get(group, 'name')}</LinkText>
            </Link>
          ) : (
            '-'
          ),
      },
      {
        align: 'right',
        key: 'expectedAnnualMileage',
        title: t('anomalies:expectedAnnualMileage'),
        dataIndex: 'expectedAnnualMileage',
        sorter: true,
        render: expectedAnnualMileage => formatNumberToLocale(expectedAnnualMileage),
      },
      {
        align: 'right',
        key: 'trendingAnnualMileage',
        title: t('anomalies:trendingAnnualMileage_last3Months'),
        dataIndex: 'trendingAnnualMileage',
        sorter: true,
        render: trendingAnnualMileage => formatNumberToLocale(trendingAnnualMileage),
      },
      {
        align: 'right',
        defaultSortOrder: 'descend',
        key: 'variancePercentage',
        title: t('anomalies:variance%'),
        dataIndex: 'variancePercentage',
        sorter: true,
        render: variancePercentage => formatNumberToLocale(variancePercentage),
      },
      {
        key: 'flag',
        title: t('anomalies:flag'),
        dataIndex: 'flag',
        sorter: true,
        render: flag => (flag ? t(`anomalies:${flag}`) : '-'),
      },
      {
        align: 'right',
        key: 'fixedValueChange',
        title: t('anomalies:fixedValueChange'),
        dataIndex: 'fixedValueChange',
        sorter: true,
        render: (fixedValueChange, data) =>
          formatNumberWithCurrency(fixedValueChange, data.fixedValueChangeCurrency) || '-',
      },
      {
        align: 'right',
        key: 'variableValueChange',
        title: t('anomalies:variableValueChange'),
        dataIndex: 'variableValueChange',
        sorter: true,
        render: (variableValueChange, data) =>
          formatNumberWithCurrency(variableValueChange, data.variableValueChangeCurrency) || '-',
      },
      {
        align: 'center',
        key: 'rateRequested',
        title: t('anomalies:newRateRequested'),
        sorter: true,
        ...renderBooleanCheckmarks('rateRequested'),
      },
      {
        key: 'actions',
        title: '',
        sorter: false,
        render: data => (
          <Space direction="horizontal">
            {typeof onRequestNewRate === 'function' && !data.rateRequested && (
              <Button size="xs" onClick={() => onRequestNewRate(data._id)}>
                {t('anomalies:requestNewRate')}
              </Button>
            )}
          </Space>
        ),
      },
    ],
    [t, onRequestNewRate],
  );

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

export default AnomaliesMileageAdjustmentsTable;
