import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';

import Button from '../../Button';
import SummaryCardColumn from '../../shared-ui/SummaryCardColumn';
import Text from '../../Text';

const ReceiptBankTransferSummary = props => {
  const { t, receipt, onRetry, ...rest } = props;

  const [isRetrying, setIsRetrying] = useState(false);
  const dwollaInformation = useMemo(() => get(receipt, 'dwollaCorrelationId'), [receipt]);

  const handleRetry = () => {
    setIsRetrying(true);
    onRetry().finally(() => setIsRetrying(false));
  };

  if (!dwollaInformation?._id) return null;

  return (
    <Row {...rest}>
      <Col>
        <SummaryCardColumn>
          <Row gutter={10} align="middle" style={{ marginBottom: 8 }}>
            <Col>
              <Text variant="b">{t('achPaymentTo')}:</Text>
            </Col>

            <Col>
              <Text>{dwollaInformation.bankInformation.accountNumber}</Text>
            </Col>
          </Row>

          {dwollaInformation.canRetry && (
            <Row gutter={10} align="middle">
              <Col>
                <Button size="xs" loading={isRetrying} disabled={isRetrying} onClick={handleRetry}>
                  {t('retry')}
                </Button>
              </Col>
            </Row>
          )}
        </SummaryCardColumn>
      </Col>
    </Row>
  );
};

export default ReceiptBankTransferSummary;
