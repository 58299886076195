import { isNull } from 'lodash';
import React, { useMemo } from 'react';

import { momentCompanyFormat, momentFormat } from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import { CustomTable } from '../..';
import StatusTag from '../../Tag/StatusTag';
import AmountText from '../../Text/AmountText';

const POSITIVE_AMOUNT_SUBTYPES = ['transfer-to-company-balance'];

const KliksAccountTransactionHistoryTable = props => {
  const { t, dataSource, ...rest } = props;

  const COLUMNS = useMemo(
    () => [
      {
        dataIndex: 'created',
        title: t('dwolla:initiated'),
        render: date => momentFormat(date, 'MMMM DD, YYYY - HH:mm a zz'),
      },
      {
        dataIndex: 'updated',
        title: t('lastUpdated'),
        render: date => momentFormat(date, 'MMMM DD, YYYY - HH:mm a zz'),
      },
      {
        dataIndex: 'subType',
        title: t('description'),
        render: (subType, data) => {
          if (isNull(subType) && data.period) {
            return t('dwolla:paymentPeriod_period', {
              period: [
                momentCompanyFormat(data.period.paymentPeriodStart, 'MMMM DD'),
                momentCompanyFormat(data.period.paymentPeriodEnd, 'MMMM DD'),
              ].join(' - '),
            });
          }

          return t(subType);
        },
      },
      {
        align: 'right',
        dataIndex: 'totalPaidAmount',
        title: t('Amount'),
        render: (amount, data) => (
          <AmountText
            textAlign="right"
            amount={`${
              POSITIVE_AMOUNT_SUBTYPES.includes(data.subType) ? '' : '-'
            }${formatCurrencyToCompanyLocale(amount)}`}
          />
        ),
      },
      {
        dataIndex: 'status',
        title: t('status'),
        render: status => (status ? <StatusTag status={status} /> : '-'),
      },
    ],
    [t],
  );

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

export default KliksAccountTransactionHistoryTable;
