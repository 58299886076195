import React from 'react';

import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const MiscellaneousSettingsFormItems = props => {
  const { t, authUser, companySettings, onFeatureChange, disabled } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('misc')}>
      <FeatureFormItem
        label={t('realtorFeatures')}
        value={companySettings.realtorFeatures}
        onChange={checked => onFeatureChange({ realtorFeatures: checked })}
        disabled={disabled}
      />
      <FeatureFormItem
        label={t('importLocations')}
        value={companySettings.importLocationsMethod}
        onChange={checked => onFeatureChange({ importLocationsMethod: checked })}
        disabled={disabled}
      />
    </SettingSectionWrapper>
  );
};

export default MiscellaneousSettingsFormItems;
