import { Form } from 'antd';
import { set } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { USER_API } from '../../../api/user';
import { AddressForm } from '../../../components';
import Box from '../../../components/Box';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../../enum';
import { updateProfile } from '../../../stores/actions/profile';
import { formatPageTitle } from '../../../utils/common';
import {
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import { normalizeUserSchema } from '../../../utils/users';
import className from './styles.module.scss';

const HomeAddressOnboardingView = props => {
  const { t, history } = props;

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const supportedCountries = useSelector(store => store.common.supportedCountries);

  const { homeAddress } = authUser?.profile?.personalInfor || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userAddress, setUserAddress] = useState(homeAddress || {});

  const handleUserAddressChange = (value = {}) => {
    setUserAddress({ ...userAddress, ...value });
  };

  /**
   * Update the userDetails state
   *
   * @param  {object} newUserData Updated User's data
   */
  const onUserUpdate = newUserData => {
    const updatedData = { ...authUser.profile, ...normalizeUserSchema(newUserData) };

    dispatch(updateProfile(updatedData));
    history.push(INTERNAL_LINKS.USER_ONBOARDING);
  };

  const handleAddressUpdate = () => {
    form.validateFields().then(async values => {
      setIsSubmitting(true);
      try {
        const updatedUser = await USER_API.updateUser(authUser.profile._id, {
          personalInfor: { homeAddress: values },
        });
        await USER_API.confirmUserHomeAddress(authUser.profile._id, 'yes');
        set(updatedUser, 'personalInfor.homeAddress.homeAddrUserConf', 'yes');
        onUserUpdate({ ...authUser.profile, ...updatedUser });

        Toast({
          type: 'open',
          message: t('confirmAddressSuccess'),
        });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('confirmAddressError'),
          });
        });
      }
      setIsSubmitting(false);
    });
  };

  return (
    <PageContainer title="" className={className.onboardingPage}>
      <Helmet>
        <title>{formatPageTitle('Home Address | User Onboarding')}</title>
      </Helmet>

      <Text variant="h2" textAlign="center" className="slide-down-title">
        {t('letsGetSetUpSoYouCanBeonCruiseControl')}
      </Text>

      <br />
      <br />

      <Box>
        <Text variant="h4">{t('homeAddress')}</Text>

        <Form form={form} initialValues={userAddress} onFinish={handleAddressUpdate}>
          <AddressForm
            t={t}
            values={userAddress}
            companyAddress={currentCompany?.address}
            formDisabled={isSubmitting}
            countries={supportedCountries}
            onValueChange={handleUserAddressChange}
            formInstance={form}
          />
        </Form>

        <SubmitCancelButtonGroup
          disabled={isSubmitting}
          loading={isSubmitting}
          submitText={t('saveAddress')}
          onSubmit={handleAddressUpdate}
          onCancel={() => history.push(INTERNAL_LINKS.USER_ONBOARDING)}
        />
      </Box>
    </PageContainer>
  );
};

export default withNamespaces()(HomeAddressOnboardingView);
