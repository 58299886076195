import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../api/axiosInstance';
import { createItinerary } from '../api/itinerary';
import { INTERNAL_LINKS } from '../enum';
import { emitFetchContacts } from '../stores/actions/realtor';
import { selectStoreCurrentAuthUser } from '../utils/storeSelectors';
import useModal from './useModal';

const useCreateItinerary = ({ history }) => {
  const dispatch = useDispatch();
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const [isCreatingItinerary, setIsCreatingItinerary] = useState(false);

  const [
    isItineraryCreateModalOpen,
    openItineraryCreateModal,
    closeItineraryCreateModal,
  ] = useModal();

  const handleCreateItinerary = async values => {
    setIsCreatingItinerary(true);

    try {
      const data = await createItinerary(values);
      if (!values.clientDetails[0]?.clientId) {
        dispatch(emitFetchContacts(true));
      }
      history.push(INTERNAL_LINKS.ITINERARY_DETAILS(data._id));
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsCreatingItinerary(false);
  };

  return {
    isCreatingItinerary,
    authUser,

    isItineraryCreateModalOpen,
    openItineraryCreateModal,
    closeItineraryCreateModal,

    handleCreateItinerary,
  };
};

export default useCreateItinerary;
