import { Col, Row, Space } from 'antd';
import classnames from 'classnames';
import { find, get } from 'lodash';
import React, { useCallback } from 'react';

import { getMomentDateWithoutTimezone } from '../../utils/datetime';
import { formatNumberWithCurrency } from '../../utils/numbers';
import { formatAddressIntoString } from '../../utils/trips';
import { INSURANCE_COVERAGES_MO_MAP } from '../RateFormWizard/rate-form-wizard.contants';
import Text from '../Text';
import className from './styles.module.scss';

const MeasureOneInsuranceInfo = props => {
  const { t, insurancePolicy } = props;

  const getInsuranceFieldValue = useCallback(item => {
    if (!item) return '-';

    if (item.valueType === 'TEXT') {
      return item.value;
    }

    return formatNumberWithCurrency(item.value.amount, item.value.currency);
  }, []);

  return (
    <Space className={className.measureOneDescriptionWrapper} direction="vertical" size="middle">
      <Row style={{ width: '100%' }}>
        <Col xs={24} className={classnames([className.label, className.topHeader])}>
          <Text variant="h5">{t('insuranceCompany')}</Text>
        </Col>
        <Col xs={24} className={className.content}>
          {get(insurancePolicy, 'insuranceProvider', '-')}
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col xs={24} className={classnames([className.label, className.topHeader])}>
          <Text variant="h5">{t('coveragePeriod')}</Text>
        </Col>
        <Col xs={24} sm={12}>
          <Row>
            <Col xs={12} className={className.label}>
              {t('startDate')}
            </Col>
            <Col xs={12} className={className.content}>
              {insurancePolicy
                ? getMomentDateWithoutTimezone(
                    get(insurancePolicy, 'coveragePeriod.startDate'),
                  ).format('MM-DD-YYYY')
                : '-'}
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={12}>
          <Row>
            <Col xs={12} className={className.label}>
              {t('endDate')}
            </Col>
            <Col xs={12} className={className.content}>
              {insurancePolicy
                ? getMomentDateWithoutTimezone(
                    get(insurancePolicy, 'coveragePeriod.endDate'),
                  ).format('MM-DD-YYYY')
                : '-'}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ width: '100%' }} className={className.twoColumnGrid}>
        <Col xs={24} className={classnames([className.label, className.topHeader])}>
          <Text variant="h5">{t('vehicleInfo')}</Text>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={12} className={className.label}>
              {t('year')}
            </Col>
            <Col xs={12} className={className.content}>
              {get(insurancePolicy, 'vehicleInfo.year', '-')}
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={12} className={className.label}>
              {t('vin')}
            </Col>
            <Col xs={12} className={className.content}>
              {get(insurancePolicy, 'vehicleInfo.vin', '-')}
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={12} className={className.label}>
              {t('make')}
            </Col>
            <Col xs={12} className={className.content}>
              {get(insurancePolicy, 'vehicleInfo.make', '-')}
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={12} className={className.label}>
              {t('model')}
            </Col>
            <Col xs={12} className={className.content}>
              {get(insurancePolicy, 'vehicleInfo.model', '-')}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ width: '100%', display: 'grid' }}>
        <Col xs={24} className={classnames([className.label, className.topHeader])}>
          <Text variant="h5">{t('policyHolders')}</Text>
        </Col>
        <Col xs={24}>
          {get(insurancePolicy, 'policyHolders', []).map(holder => (
            <Row
              style={{ width: '100%' }}
              className={className.twoColumnGrid}
              key={get(holder, 'name')}
            >
              <Col xs={24}>
                <Row>
                  <Col xs={12} className={className.label}>
                    {t('name')}
                  </Col>
                  <Col xs={12} className={className.content}>
                    {get(holder, 'name')}
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row>
                  <Col xs={12} className={className.label}>
                    {t('address')}
                  </Col>
                  <Col xs={12} className={className.content}>
                    {formatAddressIntoString(get(holder, 'address'))}
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>

      <Row style={{ width: '100%' }} className={className.twoColumnGrid}>
        <Col xs={24} className={classnames([className.label, className.topHeader])}>
          <Text variant="h5">{t('insuranceCoverages')}</Text>
        </Col>
        {Object.keys(INSURANCE_COVERAGES_MO_MAP).map(itemKey => {
          const coverageItem = INSURANCE_COVERAGES_MO_MAP[itemKey];

          if (coverageItem.type === 'amount') {
            const amountItem = find(insurancePolicy?.coverages, { type: itemKey });
            const amountItemLimit = find(insurancePolicy?.coverages, {
              type: itemKey.replace(/amount/gi, 'Limit'),
            });

            return (
              <Col xs={24} key={itemKey}>
                <Row>
                  <Col xs={24} className={className.label}>
                    {t(itemKey)}
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={24} className={className.subLabel}>
                        {t('limit')}
                      </Col>
                      <Col
                        xs={24}
                        className={classnames(className.content, {
                          [className.amountContent]: amountItemLimit?.valueType !== 'TEXT',
                        })}
                      >
                        {getInsuranceFieldValue(amountItemLimit)}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={24} className={className.subLabel}>
                        {t('deductible')}
                      </Col>
                      <Col
                        xs={24}
                        className={classnames(className.content, {
                          [className.amountContent]: amountItem?.valueType !== 'TEXT',
                        })}
                      >
                        {getInsuranceFieldValue(amountItem)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            );
          }

          const perPersonItem = find(insurancePolicy?.coverages, {
            type: `${itemKey}PerPerson`,
          });
          const perAccidentItem = find(insurancePolicy?.coverages, {
            type: `${itemKey}PerAccident`,
          });

          return (
            <Col xs={24} key={itemKey}>
              <Row>
                <Col xs={24} className={className.label}>
                  {t(itemKey)}
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={24} className={className.subLabel}>
                      {t('perPerson')}
                    </Col>
                    <Col
                      xs={24}
                      className={classnames(className.content, {
                        [className.amountContent]: perPersonItem?.valueType !== 'TEXT',
                      })}
                    >
                      {getInsuranceFieldValue(perPersonItem)}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={24} className={className.subLabel}>
                      {t('perAccident')}
                    </Col>
                    <Col
                      xs={24}
                      className={classnames(className.content, {
                        [className.amountContent]: perAccidentItem?.valueType !== 'TEXT',
                      })}
                    >
                      {getInsuranceFieldValue(perAccidentItem)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Space>
  );
};

export default MeasureOneInsuranceInfo;
