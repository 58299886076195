import { Card, Col, Row, Space } from 'antd';
import React from 'react';

import Box from '../../../components/Box';
import Text from '../../../components/Text';
import TextWithExternalLink from '../../../components/TextWithExternalLink';
import { EXTERNAL_LINKS } from '../../../enum';

const HolidaysSection = props => {
  const { t } = props;

  const holidayDescriptions = [
    {
      text: t('howBankHolidaysImpactACHPayments'),
      description: t('howBankHolidaysImpactACHPayments__description'),
    },
    {
      text: t('ifABankHolidayFallsOnAWeekday'),
      description: t('ifABankHolidayFallsOnAWeekday__description'),
    },
    {
      text: t('ifABankHolidayFallsOnAWeekend'),
      description: t('ifABankHolidayFallsOnAWeekend__description'),
    },
  ];

  return (
    <section>
      <Space direction="vertical" size="middle" wrap={false}>
        <TextWithExternalLink
          text={t('usBankHolidays')}
          textProps={{
            variant: 'h5',
            size: 'm',
            renderAs: 'a',
            color: 'primary',
            rel: 'noopener noreferrer',
            target: '_blank',
            href: EXTERNAL_LINKS.US_HOLIDAYS,
          }}
          linkProps={{
            href: EXTERNAL_LINKS.US_HOLIDAYS,
          }}
        />

        <Row wrap gutter={[16, 16]}>
          {holidayDescriptions.map(({ text, description }) => (
            <Col xs={24} sm={8}>
              <Box style={{ height: '100%' }}>
                <Card.Meta
                  title={
                    <Text variant="h5" style={{ textWrap: 'initial' }}>
                      {text}
                    </Text>
                  }
                  description={
                    <Space direction="vertical" size="small" wrap={false}>
                      {description.split('\n').map(line => (
                        <Text>{line}</Text>
                      ))}
                    </Space>
                  }
                />
              </Box>
            </Col>
          ))}
        </Row>
      </Space>
    </section>
  );
};

export default HolidaysSection;
