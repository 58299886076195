import { handleApiCalls } from './axiosInstance';

export const SENDGRID_API = {
  /**
   * Get email suppression groups
   */
  fetchEmailSuppressionGroups: async () => {
    const url = `${process.env.REACT_APP_HOST_API}sendgrid/email-suppression-groups`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },
  /**
   * Add email suppression group
   */
  addEmailSuppressionGroup: async groupId => {
    const url = `${process.env.REACT_APP_HOST_API}sendgrid/suppression`;
    return handleApiCalls('post', url, { groupId });
  },
  /**
   * Remove email suppression group
   * @param {string} groupId
   */
  removeEmailSuppressionGroup: async groupId => {
    const url = `${process.env.REACT_APP_HOST_API}sendgrid/${groupId}/suppression`;
    return handleApiCalls('delete', url);
  },
};
