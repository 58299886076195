import { Col, DatePicker, Form, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { handleApiErrors } from '../../../api/axiosInstance';
import { fetchCurrentUserTripActivity, fetchTripsYearlyStatistics } from '../../../api/trips';
import { fetchCurrentUserActivityHistory } from '../../../api/user';
import { HelpIcon } from '../../../components';
import ActivityTrendBlock from '../../../components/ActivityTrendBlock';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import DoughnutChart from '../../../components/DoughnutChart';
import Select from '../../../components/Select';
import Spinner from '../../../components/Spinner';
import MobileAppVersionsTable from '../../../components/Table/MobileAppVersionsTable';
import Text from '../../../components/Text';
import FadedText from '../../../components/Text/FadedText';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';
import UserActivitySummaryRow from '../../../components/UserActivitySummaryRow';
import { INTERNAL_LINKS } from '../../../enum';
import {
  formatPageTitle,
  getNumberRage,
  getUserFullName,
  momentTimezone,
} from '../../../utils/common';
import {
  formatNumberToLocale,
  formatNumberWithCurrency,
  parseTotalAmountsArray,
} from '../../../utils/numbers';
import { cleanQueryParams } from '../../../utils/queryParams';
import { StorageUtils } from '../../../utils/sessionStorage';
import { selectStoreCompanySettings } from '../../../utils/storeSelectors';
import rootClassNames from '../style.module.scss';

const renderDoughnutMobileOsLabel = (t, mobileOS, userID, appVersion) => {
  return (
    <Row gutter={5} align="middle" wrap={false}>
      <Col flex={1}>
        <Row gutter={5}>
          <Col>{mobileOS}</Col>
          <Col>
            <HelpIcon
              size="small"
              hint={<MobileAppVersionsTable t={t} mobileOS={mobileOS} userID={userID} />}
            />
          </Col>
        </Row>
        <FadedText size="xs">{appVersion || 'N/A'}</FadedText>
      </Col>
    </Row>
  );
};

/**
 * User Activity page
 */
const UserDetailsActivityView = props => {
  const { t, userDetails } = props;

  const companySettings = useSelector(selectStoreCompanySettings);

  const [userTripActivity, setUserTripActivity] = useState();
  const [userDevices, setUserDevices] = useState({});
  const [userYearlyStatistics, setUserYearlyStatistics] = useState();

  const [isSearchingActivityHistory, setIsSearchingActivityHistory] = useState(false);
  const [userActivityHistory, setUserActivityHistory] = useState();
  const [activityHistoryEndReached, setActivityHistoryEndReached] = useState(false);

  const [dateRange, setDateRange] = useState(() => [
    moment().startOf('month'),
    moment().endOf('month'),
  ]);

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const fillYearOptions = () => {
    const MINIMUM_YEAR = moment(userDetails.created).year();

    const YEAR_RANGE = getNumberRage(MINIMUM_YEAR, currentYear);

    const yearOptions = YEAR_RANGE.map(year => ({
      label: year === currentYear ? t('thisYear', { year: currentYear }) : year,
      value: year,
    }));

    return yearOptions;
  };

  const loadTripActivity = async () => {
    try {
      const data = await fetchCurrentUserTripActivity(userDetails._id, selectedYear);
      setUserTripActivity(data);

      const devices = {};
      data.devices
        .sort((a, b) => b.count - a.count)
        .slice(0, 5)
        .forEach(device => {
          devices[device._id] = device.count;
        });

      setUserDevices(devices);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchCurrentUserTripActivityError'),
        });
      });
    }
  };

  const loadUserStatistics = async () => {
    try {
      const data = await fetchTripsYearlyStatistics(userDetails._id, selectedYear);
      setUserYearlyStatistics(data);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchTripsYearlyStatisticsError'),
        });
      });
    }
  };

  const loadUserActivityHistory = async (skip = 0, amountToFetch) => {
    setIsSearchingActivityHistory(true);

    try {
      const data = await fetchCurrentUserActivityHistory(
        userDetails._id,
        dateRange[0].format('YYYY-MM-DD'),
        dateRange[1].format('YYYY-MM-DD'),
        skip,
        amountToFetch,
      );

      setUserActivityHistory([
        ...(Array.isArray(userActivityHistory) ? userActivityHistory : []),
        ...data,
      ]);

      // If no additional results were found, the endpoint has reached the oldest activity
      if (!data.length || data.length < amountToFetch) {
        setActivityHistoryEndReached(true);
      }
    } catch (error) {
      setUserActivityHistory([]);
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchCurrentUserActivityHistoryError'),
        });
      });
    }

    setIsSearchingActivityHistory(false);
  };

  useEffect(() => {
    loadTripActivity();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserYearlyStatistics();
    setUserTripActivity();

    loadUserStatistics();
    loadTripActivity();
    // eslint-disable-next-line
  }, [selectedYear]);

  useEffect(() => {
    // Reset activity history to trigger a refetch
    setUserActivityHistory();
  }, [dateRange]);

  useEffect(() => {
    if (!userActivityHistory && !isSearchingActivityHistory) {
      loadUserActivityHistory(0, 10);
    }
    // eslint-disable-next-line
  }, [userActivityHistory, isSearchingActivityHistory]);

  const HOURS_SINCE_LAST_TRIP = userTripActivity
    ? momentTimezone().diff(momentTimezone(userTripActivity.dateOfLastTrip || undefined), 'hours')
    : undefined;

  const handleSetStorageLastUserLookupSelection = () => {
    StorageUtils.lastUserLookupSelection.set({
      value: userDetails._id,
      children: getUserFullName(userDetails),
    });
  };

  const parsedYearlyStatisticsReimbursedAmount = useMemo(() => {
    return parseTotalAmountsArray(userYearlyStatistics?.reimbursedAmount);
  }, [userYearlyStatistics]);

  return (
    <Box>
      <Helmet>
        <title>{formatPageTitle('Activity')}</title>
      </Helmet>

      <Row justify="end">
        <Col flex="214px">
          <Form.Item>
            <Select
              placeholder={t('date')}
              defaultValue={currentYear}
              onChange={year => setSelectedYear(year)}
              options={fillYearOptions()}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={rootClassNames.rowWithBorder} gutter={[10, 33]} justify="center">
        {userYearlyStatistics ? (
          <>
            <Col sm={24} md={12} lg={8}>
              <ActivityTrendBlock
                isPositiveTrend={parseInt(userYearlyStatistics.approvedDistanceDifference) >= 0}
                amount={userYearlyStatistics.approvedDistance}
                percent={userYearlyStatistics.approvedDistanceDifference || 0}
                text={t('distanceApproved')}
              />
            </Col>
            <Col sm={24} md={12} lg={8}>
              <ActivityTrendBlock
                isPositiveTrend={userYearlyStatistics.submittedTripsDifference >= 0}
                amount={userYearlyStatistics.submittedTrips}
                percent={userYearlyStatistics.submittedTripsDifference || 0}
                text={t('tripsSubmitted')}
              />
            </Col>

            <Col sm={24} md={12} lg={8}>
              <ActivityTrendBlock
                isPositiveTrend={parsedYearlyStatisticsReimbursedAmount.total >= 0}
                amount={formatNumberWithCurrency(
                  parsedYearlyStatisticsReimbursedAmount.total,
                  parsedYearlyStatisticsReimbursedAmount.currency,
                )}
                percent={userYearlyStatistics.reimbursedDifference || 0}
                text={t('reimbursed')}
              />
            </Col>
            {/* <Col sm={24} md={12} lg={8}>
              <ActivityTrendBlock
                isPositiveTrend={false}
                amount={1}
                percent={100}
                text={t('FAVR')}
              />
            </Col>
            <Col sm={24} md={12} lg={8}>
              <ActivityTrendBlock
                isPositiveTrend={false}
                amount={1}
                percent={100}
                text={t('Safety')}
              />
            </Col>
            <Col sm={24} md={12} lg={8}>
              <ActivityTrendBlock
                isPositiveTrend={false}
                amount={1}
                percent={100}
                text={t('Policy')}
              />
            </Col> */}
          </>
        ) : (
          <Spinner />
        )}
      </Row>

      <Row
        className={rootClassNames.rowWithBorder}
        justify={userTripActivity ? undefined : 'center'}
      >
        {userTripActivity ? (
          <Col flex={1}>
            <Row gutter={[10, 33]} style={{ marginBottom: 56 }} justify="center">
              <Col sm={24} md={12} lg={!companySettings?.hideReceipts ? 6 : 8}>
                <ActivityTrendBlock
                  isPositiveTrend
                  amount={formatNumberToLocale(
                    HOURS_SINCE_LAST_TRIP >= 24
                      ? momentTimezone().diff(
                          momentTimezone(userTripActivity.dateOfLastTrip),
                          'days',
                        )
                      : HOURS_SINCE_LAST_TRIP,
                    0,
                  )}
                  text={
                    HOURS_SINCE_LAST_TRIP >= 24
                      ? t('daysSinceLastTripRecording')
                      : t('hoursSinceLastTripRecording')
                  }
                >
                  <Link
                    to={`${INTERNAL_LINKS.TRIPS}?user=${userDetails._id}`}
                    onClick={handleSetStorageLastUserLookupSelection}
                  >
                    <LinkText variant="strong" size="xs">
                      {t('view')}
                    </LinkText>
                  </Link>
                </ActivityTrendBlock>
              </Col>
              <Col sm={24} md={12} lg={!companySettings?.hideReceipts ? 6 : 8}>
                <ActivityTrendBlock
                  isPositiveTrend
                  amount={userTripActivity.daysSinceLastReimbursement}
                  text={t('daysSinceLastReimbursement')}
                >
                  <Link
                    onClick={handleSetStorageLastUserLookupSelection}
                    to={`${INTERNAL_LINKS.REIMBURSEMENT}?${cleanQueryParams({
                      user: userDetails._id,
                      userId: userDetails._id,
                    })}`}
                  >
                    <LinkText variant="strong" size="xs">
                      {t('view')}
                    </LinkText>
                  </Link>
                </ActivityTrendBlock>
              </Col>
              <Col sm={24} md={12} lg={!companySettings?.hideReceipts ? 6 : 8}>
                <ActivityTrendBlock
                  isPositiveTrend
                  amount={userTripActivity.daysUntilNextVacations}
                  text={t('daysUntilVacation')}
                >
                  <Link
                    to={INTERNAL_LINKS.USER_DETAILS + '/' + props.match.params.id + '/vacations'}
                  >
                    <LinkText variant="strong" size="xs">
                      {t('view')}
                    </LinkText>
                  </Link>
                </ActivityTrendBlock>
              </Col>

              {!companySettings?.hideReceipts && (
                <Col sm={24} md={12} lg={6}>
                  <ActivityTrendBlock
                    isPositiveTrend
                    amount={userTripActivity.countReceiptsWaitingForApproval}
                    text={t('rcWaitingForApproval')}
                  >
                    <Link
                      onClick={handleSetStorageLastUserLookupSelection}
                      to={`${INTERNAL_LINKS.RECEIPTS}?${cleanQueryParams({
                        user: userDetails._id,
                      })}`}
                    >
                      <LinkText variant="strong" size="xs">
                        {t('view')}
                      </LinkText>
                    </Link>
                  </ActivityTrendBlock>
                </Col>
              )}
            </Row>

            <Row gutter={[33, 33]} justify="center">
              {(userTripActivity.iosTripsCount > 0 ||
                userTripActivity.androidTripsCount > 0 ||
                userTripActivity.webTripsCount > 0) && (
                <Col flex="220px" style={{ justifyContent: 'center' }}>
                  <div style={{ width: 'max-content', margin: '0 auto' }}>
                    <DoughnutChart
                      textLabels={['iOS', 'Android', 'Web']}
                      labels={[
                        renderDoughnutMobileOsLabel(
                          t,
                          'iOS',
                          userDetails?._id,
                          userTripActivity.iosLatesAppVersion,
                        ),
                        renderDoughnutMobileOsLabel(
                          t,
                          'Android',
                          userDetails?._id,
                          userTripActivity.androidLatestAppVersion,
                        ),
                        'Web',
                      ]}
                      datasets={{
                        labels: t('osTripRecording'),
                        data: [
                          userTripActivity.iosTripsCount,
                          userTripActivity.androidTripsCount,
                          userTripActivity.webTripsCount,
                        ],
                        backgroundColor: ['#8862D9', '#16B296', '#62A7D9'],
                      }}
                    />
                  </div>
                </Col>
              )}
              {!!Object.keys(userDevices).length && (
                <Col flex="220px" style={{ justifyContent: 'center' }}>
                  <div style={{ width: 'max-content', margin: '0 auto' }}>
                    <DoughnutChart
                      textLabels={Object.keys(userDevices).length ? Object.keys(userDevices) : ['']}
                      labels={Object.keys(userDevices).length ? Object.keys(userDevices) : ['']}
                      datasets={{
                        labels: t('deviceForTripRecording'),
                        data: Object.values(userDevices).length ? Object.values(userDevices) : [1],
                        backgroundColor: ['#F09F55', '#62A7D9', '#8862D9', '#16B296', '#62A7D9'],
                      }}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        ) : (
          <Col>
            <Spinner />
          </Col>
        )}
      </Row>

      <Row className={rootClassNames.kliksRow}>
        <Col flex={1}>
          <Text variant="h5">{t('dailyActivity')}</Text>

          <Row gutter={16} style={{ marginTop: 35 }} justify="start">
            <Col xs={24} lg={12}>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                allowClear={false}
                defaultValue={dateRange}
                onChange={setDateRange}
              />
            </Col>
          </Row>

          <Row
            style={{ marginTop: 51 }}
            align="middle"
            justify={Array.isArray(userActivityHistory) ? undefined : 'center'}
          >
            {Array.isArray(userActivityHistory) && !!userActivityHistory.length && (
              <Col span={24}>
                {userActivityHistory.map((data, i) => (
                  <UserActivitySummaryRow key={i} t={t} activity={data} />
                ))}
              </Col>
            )}

            {Array.isArray(userActivityHistory) && !userActivityHistory.length && (
              <Col span={24}>
                <FadedText textAlign="center">{t('noEventsToDisplay')}</FadedText>
              </Col>
            )}

            {!userActivityHistory && (
              <Col>
                <Spinner />
              </Col>
            )}
          </Row>

          {!activityHistoryEndReached && userActivityHistory && (
            <Row justify="center" align="middle" style={{ marginTop: 51 }}>
              <Button
                type="secondary"
                onClick={() => loadUserActivityHistory(userActivityHistory.length, 5)}
                loading={isSearchingActivityHistory}
              >
                {t('seeMoreEvents')}
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </Box>
  );
};

export default UserDetailsActivityView;
