import { withNamespaces } from 'react-i18next';

import withAuthentication from './withAuthentication';

const withPageState = (WrappedComponent, authRequired = false, namespaces) => {
  if (authRequired) {
    return withNamespaces(namespaces)(withAuthentication(WrappedComponent));
  }

  return withNamespaces(namespaces)(WrappedComponent);
};

export default withPageState;
