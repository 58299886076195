import { Col, Row, Upload } from 'antd';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import storage from 'store';

import { Button } from '../../../components';
import Text from '../../../components/Text';
import FadedText from '../../../components/Text/FadedText';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';
import { IMAGES } from '../../../enum';
import { selectStoreCurrentAuthUser } from '../../../utils/storeSelectors';

const MAX_FILE_SIZE = 10;

const validateImage = file => {
  const isLt10M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
  if (!isLt10M) {
    Toast({
      type: 'error',
      message: `File must smaller than ${MAX_FILE_SIZE}MB`,
    });
  }

  return isLt10M;
};

const InsuranceDocumentsSection = props => {
  const {
    t,
    isUploadingFile,
    userDetails,
    insurancePolicy,

    documentList,
    onFileChange,

    documentsToCreateList,
    onFileToCreateChange,
  } = props;

  const accessToken = storage.get('accessToken');

  const currentAuthUser = useSelector(selectStoreCurrentAuthUser);

  const isSameUser = currentAuthUser.profile._id === userDetails._id;

  if (!isSameUser) return null;

  return (
    <section>
      <Text variant="h5">{t('insuranceDeclarationForm')}</Text>

      <Row align="middle" gutter={[10, 10]}>
        <Col>
          <FadedText size="sm">{t('whatsInsuranceDeclarationForm')}</FadedText>
        </Col>
        <Col>
          <Row align="middle" gutter={[5, 5]} wrap="false">
            <Col>
              <LinkText
                size="sm"
                renderAs="a"
                rel="noopener noreferrer"
                target="_blank"
                href={IMAGES.INSURANCE_FORM_SAMPLE_1}
              >
                {t('example__n', { number: 1 })}
              </LinkText>
            </Col>
            <Col>|</Col>
            <Col>
              <LinkText
                size="sm"
                renderAs="a"
                rel="noopener noreferrer"
                target="_blank"
                href={IMAGES.INSURANCE_FORM_SAMPLE_2}
              >
                {t('example__n', { number: 2 })}
              </LinkText>
            </Col>
          </Row>
        </Col>
      </Row>

      <Upload
        name="files"
        type={!isSameUser ? 'select' : 'drag'}
        disabled={!isSameUser || isUploadingFile}
        accept="application/pdf,image/*"
        listType="text"
        beforeUpload={validateImage}
        {...(insurancePolicy?._id
          ? {
              fileList: documentList,
              data: { id: insurancePolicy._id },
              headers: { Authorization: `Bearer ${accessToken}` },
              method: 'post',
              action: `${process.env.REACT_APP_HOST_API}vehicle-insurance-policies/upload-image`,
              showUploadList: false,
              onChange: onFileChange,
            }
          : {
              fileList: documentsToCreateList,
              onChange: onFileToCreateChange,
              customRequest: () => undefined,
              showUploadList: {
                showDownloadIcon: false,
                showPreviewIcon: false,
              },
            })}
      >
        <Row justify="center">
          <Col>
            <Button loading={isUploadingFile} type="secondary">
              Upload
            </Button>
          </Col>
        </Row>
      </Upload>

      <br />
    </section>
  );
};

export default withNamespaces()(InsuranceDocumentsSection);
