import { Col, Form, Radio, Row } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RATE_TYPES } from '../../enum/Rates';
import { selectStoreCountryByCode, selectStoreCurrentPlan } from '../../utils/storeSelectors';
import HelpIcon from '../HelpIcon';
import CustomRadio from '../Radio';
import SpaceSpinner from '../SpaceSpinner';
import Text from '../Text';
import FadedText from '../Text/FadedText';

const ReimbursementRateTypeFormSection = props => {
  const { t, rateType, countryRate, rateCountry, onRateTypeChange, isSubmitting } = props;

  const currentPlan = useSelector(selectStoreCurrentPlan);
  const country = useMemo(() => selectStoreCountryByCode(rateCountry), [rateCountry]);

  const IS_ADVANCED_PLAN = /advanced/i.test(currentPlan?.planId?.type || '');
  const IS_PREMIUM_PLAN = /premium/i.test(currentPlan?.planId?.type || '');
  const IS_CUSTOM_PLAN = /custom/i.test(currentPlan?.planId?.type || '');

  const canSelectTieredRate = IS_ADVANCED_PLAN || IS_PREMIUM_PLAN || IS_CUSTOM_PLAN;
  const canSelectFAVR = IS_PREMIUM_PLAN || IS_CUSTOM_PLAN;

  if (!countryRate) {
    return <SpaceSpinner height={140} />;
  }

  return (
    <Form.Item name="rateType">
      <Radio.Group
        value={rateType}
        disabled={isSubmitting}
        onChange={e => onRateTypeChange(e.target.value)}
      >
        {countryRate && (
          <CustomRadio value={RATE_TYPES.GOVT}>
            <Text size="sm">{t('governmentRate')}</Text>
            <FadedText size="sm">
              {`${countryRate.name}`}{' '}
              {countryRate.defaultRate
                ? `(${country.currencyCode} ${countryRate.defaultRate} / ${country.distanceShort})`
                : undefined}
            </FadedText>
          </CustomRadio>
        )}

        <CustomRadio value={RATE_TYPES.FLAT}>
          <Text size="sm">{t('customFlatRateCPM')}</Text>
        </CustomRadio>

        <CustomRadio disabled={!canSelectTieredRate} value={RATE_TYPES.TIERED}>
          <Row align="middle" wrap={false}>
            <Col>
              <Text size="sm" color={!canSelectTieredRate ? 'faded' : undefined}>
                {t('tieredtRateCPM')}
              </Text>
            </Col>
            {!canSelectTieredRate && (
              <Col>
                <HelpIcon
                  placement="top"
                  className="item-container-help"
                  hint={t('toUseTieredRateAdvancePlan')}
                />
              </Col>
            )}
          </Row>
        </CustomRadio>

        <CustomRadio disabled={!canSelectFAVR} value={RATE_TYPES.FAVR}>
          <Row align="middle" wrap={false}>
            <Col>
              <Text size="sm" color={!canSelectFAVR ? 'faded' : undefined}>
                {t('fixedRateFAVR')}
              </Text>
            </Col>
            {!canSelectFAVR && (
              <Col>
                <HelpIcon
                  placement="top"
                  className="item-container-help"
                  hint={t('toUseFavrPremiumPlan')}
                />
              </Col>
            )}
          </Row>
        </CustomRadio>
      </Radio.Group>
    </Form.Item>
  );
};

ReimbursementRateTypeFormSection.propTypes = {};

export default ReimbursementRateTypeFormSection;
