import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import StripeAPI from '../../api/stripe';
import { getMinutesAsMilliseconds } from '../../utils/numbers';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';

const useStripeCustomerQuery = () => {
  const currentCompany = useSelector(selectStoreCurrentCompany);

  return useQuery({
    staleTime: getMinutesAsMilliseconds(10),
    queryKey: ['fetchCustomerInformation', currentCompany.stripeCustomerId],
    queryFn: () => new StripeAPI().fetchCustomerInformation(currentCompany.stripeCustomerId),
    onError: error => handleApiErrors(error.response),
  });
};

export default useStripeCustomerQuery;
