import { get } from 'lodash';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { GROUPS_API } from '../../api/group';
import { RATES_API } from '../../api/rates';
import { RATE_TYPES } from '../../enum/Rates';
import useTablePagination from '../useTablePagination';

/**
 * @param {string} rateId
 * @param {string} rateType
 * @param {string[]} groups
 * @param {string?} searchTerm
 * @param {import('react-query').UseQueryOptions?} otherOptions
 */
const useUsersInRateQuery = (rateId, rateType, groups, searchTerm, otherOptions = {}) => {
  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  const usersInRateQuery = useQuery({
    ...otherOptions,
    enabled: typeof rateType !== 'undefined' && get(otherOptions, 'enabled', true),
    placeholderData: { documents: [], count: 0 },
    initialData: { documents: [], count: 0 },
    queryKey: [
      rateType,
      rateType === RATE_TYPES.FAVR ? 'fetchGroupsUsers' : 'fetchUsersAssociatedToRate',
      groups,
      searchTerm,
      paginationConfig.current,
      paginationConfig.pageSize,
    ],
    queryFn: () => {
      if (rateType !== RATE_TYPES.FAVR) {
        return RATES_API.fetchUsersAssociatedToRate(
          rateId,
          { searchTerm },
          paginationConfig.current,
          paginationConfig.pageSize,
        );
      } else if (rateType && Array.isArray(groups) && !!groups.length) {
        return GROUPS_API.fetchGroupsUsers(
          { groups, searchTerm },
          paginationConfig.current,
          paginationConfig.pageSize,
        );
      } else {
        return { documents: [], count: 0 };
      }
    },
    onSuccess: ({ count, documents }) => {
      handleTotalUpdate(count);
      if (otherOptions.onSuccess) otherOptions.onSuccess({ count, documents });
    },
    onError: error => {
      handleApiErrors(error.response);
    },
  });

  return { paginationConfig, handlePageChange, handlePageSizeChange, usersInRateQuery };
};

export default useUsersInRateQuery;
