import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { getUserFullName } from '../../utils/common';
import Box from '../Box';
import Button from '../Button';
import Text from '../Text';

const ImpersonationWarningBar = props => {
  const { t, impersonatedProfile, onStopImpersonating } = props;

  const [isStoppingImpersonation, setIsStoppingImpersonation] = useState(false);

  const handleStopImpersonation = async () => {
    setIsStoppingImpersonation(true);
    await onStopImpersonating();
    setIsStoppingImpersonation(false);
  };

  return (
    <Box noShadow padding={'12px 24px'} variant="gray" style={{ borderRadius: 0 }}>
      <Row gutter={17} justify="space-between" align="middle">
        <Col>
          <Text variant="p" size="m" color="red">
            <Trans
              t={t}
              i18nKey="youAreImpersonating_name"
              values={{ name: getUserFullName(impersonatedProfile) }}
              components={[
                <Text variant="strong" size="m" color="red">
                  Dummy
                </Text>,
              ]}
            />
            {' | '}
            <Text variant="strong" size="m" color="red">
              {impersonatedProfile.email}
            </Text>
          </Text>
        </Col>
        <Col>
          <Button
            size="sm"
            loading={isStoppingImpersonation}
            disabled={isStoppingImpersonation}
            onClick={handleStopImpersonation}
          >
            {t('stopImpersonation')}
          </Button>
        </Col>
      </Row>
    </Box>
  );
};

export default ImpersonationWarningBar;
