import { INTERNAL_LINKS } from '../enum';
import ClientDetailView from './client-details';
import ClientListView from './client-list';
import ItineraryDetails from './itinerary-details';
import ShareItineraryDetailsView from './share-itinerary-details';

const CompanyRealtorViews = [
  { exact: true, path: INTERNAL_LINKS.ITINERARIES_LIST, render: () => 'Itineraries List' },
  { exact: true, path: INTERNAL_LINKS.SHARE_ITINERARY(), component: ShareItineraryDetailsView },
  { exact: true, path: INTERNAL_LINKS.ITINERARY_DETAILS(), component: ItineraryDetails },
  { exact: true, path: INTERNAL_LINKS.CLIENTS_LIST, component: ClientListView },
  { exact: true, path: INTERNAL_LINKS.CLIENT_DETAILS(), component: ClientDetailView },
];

export default CompanyRealtorViews;
