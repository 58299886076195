import './style.scss';

import { Row } from 'antd';
import Toast from 'components/Toast';
import PageContainer from 'containers/PageContainer';
import { INTERNAL_LINKS, USER_REG_STATUS } from 'enum';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setRegStatus } from 'stores/actions/user';

import { handleApiCalls, handleApiErrors } from '../../../api/axiosInstance';
import { USER_API } from '../../../api/user';
import InviteMultipleUsersForm from '../../../components/Form/InviteMultipleUsersForm';
import Spinner from '../../../components/Spinner';
import { saveCompany } from '../../../stores/actions/common';
import { formatPageTitle, momentEST } from '../../../utils/common';
import { selectStoreCompanySettings } from '../../../utils/storeSelectors';

const InviteUsers = ({ t, location, ...props }) => {
  const currentCompany = useSelector(state => state.common.currentCompany);
  const companySettings = useSelector(selectStoreCompanySettings);
  const dispatch = useDispatch();

  const regStatus = useSelector(state => state.user.regStatus);
  const [loading, setLoading] = useState(false);
  const isOnboarding = useMemo(() => {
    return /onboarding/i.test(location.pathname.split('/')[1]);
  }, [location]);

  useEffect(() => {
    if (!regStatus || regStatus < 3) {
      props.history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  const sendUserInvites = async invitedUsers => {
    let success = true;

    const usersToInvite = invitedUsers.map(item => {
      return {
        email: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        role: item.role,
        groupId: item.groupId,
        department: item.department,
        ...(item.sendActivationEmailDate
          ? { sendActivationEmailDate: momentEST(item.sendActivationEmailDate) }
          : {}),
        ...(item.streetOne
          ? {
              homeAddress: {
                streetOne: item.streetOne,
                streetTwo: item.streetTwo,
                city: item.city,
                state: item.state,
                postalCode: item.postalCode,
                country: item.country,
              },
            }
          : {}),
        ...(companySettings?.requireEmployeeNumber ? { employeeId: item.employeeId } : {}),
      };
    });

    try {
      await USER_API.inviteUsers(usersToInvite, currentCompany._id);
    } catch (error) {
      success = false;
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('inviteUsersError'),
        });
      });
    }

    return success;
  };

  const onFinish = async usersToInvite => {
    setLoading(true);

    let success = false;

    if (!!usersToInvite.length) {
      success = await sendUserInvites(usersToInvite);
    } else {
      success = true;
    }

    if (success) {
      // if user has completed this step before
      // skip regStatus update
      if (regStatus < USER_REG_STATUS.SUCCESS) {
        try {
          const regStatusUpdateResult = await handleApiCalls(
            'patch',
            `${process.env.REACT_APP_HOST_API}company/${currentCompany._id}/update-registration-status`,
            {
              regStatus: USER_REG_STATUS.SUCCESS,
            },
          );

          if (regStatusUpdateResult.data.success) {
            await dispatch(setRegStatus(USER_REG_STATUS.SUCCESS));
            await dispatch(
              saveCompany({
                regStatus: regStatusUpdateResult.data.data.regStatus,
                regCompleted: regStatusUpdateResult.data.data.regCompleted,
              }),
            );

            if (isOnboarding) {
              props.history.push({ pathname: INTERNAL_LINKS.COMPANY_ONBOARDING });
            }
          }
        } catch (error) {
          handleApiErrors(error.response);
        }
      } else if (isOnboarding) {
        props.history.push({ pathname: INTERNAL_LINKS.COMPANY_ONBOARDING });
      } else {
        props.history.push({ pathname: INTERNAL_LINKS.USER_MANAGER });
      }

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <PageContainer title="" className={'onboarding'}>
      <Helmet>
        <title>{formatPageTitle('Invite Users')}</title>
      </Helmet>

      <Spinner spinning={loading}>
        <div style={{ maxWidth: 1220, margin: '0 auto', padding: '60px 10px 0' }}>
          <Row className="page-title">
            {t('inviteUsers')}{' '}
            {!currentCompany.regCompleted ? `(${t('optional')})`.toLowerCase() : ''}
          </Row>

          <InviteMultipleUsersForm
            optionalInvite={isOnboarding}
            isOnboarding={isOnboarding}
            requireEmployeeID={companySettings?.requireEmployeeNumber}
            existingUsers={[]}
            defaultValues={[]}
            onCancel={() => props.history.goBack()}
            onFinish={onFinish}
            companyAddress={currentCompany?.address}
          />
        </div>
      </Spinner>
    </PageContainer>
  );
};

export default withNamespaces()(InviteUsers);
