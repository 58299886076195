import React from 'react';

import { INTERNAL_LINKS } from '../enum';

const ReportsListView = React.lazy(() => import('./reports'));
const ReportsDetailsViewerSelector = React.lazy(() => import('./reports/details'));
const ManagedUsersView = React.lazy(() => import('./managed-users'));
const UnsubmittedTripsView = React.lazy(() => import('./anomalies-unsubmitted-trips'));
const UnsubmittedTripsEmailRemindersView = React.lazy(() =>
  import('./anomalies-unsubmitted-trips-email-reminders'),
);
const AnomaliesUserUnsubmittedTrips = React.lazy(() =>
  import('./anomalies-user-unsubmitted-trips'),
);
const AnomaliesDashboardView = React.lazy(() => import('./anomalies-dashboard'));

const CompanyManagerViews = [
  {
    exact: true,
    path: INTERNAL_LINKS.REPORTS,
    component: ReportsListView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.REPORTS_DETAILS(),
    component: ReportsDetailsViewerSelector,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.MANAGED_USERS,
    component: ManagedUsersView,
  },
  { exact: true, path: INTERNAL_LINKS.ANOMALIES_DASHBOARD, component: AnomaliesDashboardView },
  {
    exact: true,
    path: INTERNAL_LINKS.ANOMALIES_UNSBUBMITTED_TRIPS,
    component: UnsubmittedTripsView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.ANOMALIES_UNSBUBMITTED_TRIPS_EMAIL_REMINDER,
    component: UnsubmittedTripsEmailRemindersView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.ANOMALIES_USER_UNSBUBMITTED_TRIPS_DETAILS(),
    component: AnomaliesUserUnsubmittedTrips,
  },
];

export default CompanyManagerViews;
