import ContentDisposition from 'content-disposition';

import { formatQueryRange } from '../utils/common';
import { handleApiCalls } from './axiosInstance';

export const fetchBillingInformationUsers = async (billingInformationID, pageNumber, pageSize) => {
  const range = formatQueryRange(pageNumber, pageSize);

  const url = `${process.env.REACT_APP_HOST_API}billing-informations/${billingInformationID}/users?range=${range}`;
  const result = await handleApiCalls('get', url);

  return result?.data;
};

export const exportBillingInformationUsersCSV = async (billingInformationID, invoiceNumber) => {
  const url = `${process.env.REACT_APP_HOST_API}billing-informations/${billingInformationID}/users/export?number=${invoiceNumber}`;
  const result = await handleApiCalls('get', url);

  const disposition = ContentDisposition.parse(result.headers['content-disposition']);
  const { filename } = disposition.parameters || {};

  const objectURL = window.URL.createObjectURL(new Blob([result.data]));
  const link = document.createElement('a');
  link.href = objectURL;
  link.setAttribute('download', `${filename || invoiceNumber}.csv`);
  document.body.appendChild(link);
  link.click();
};
