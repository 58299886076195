import { INTERNAL_LINKS } from 'enum';
import React from 'react';
import { Helmet } from 'react-helmet';
// the hoc
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle } from '../../utils/common';
import GroupForm from './groupform';

class UserManagement extends React.Component {
  goToGroupDetails = group => {
    if (group) {
      // view / edit
      this.props.history.push(`${INTERNAL_LINKS.GROUP}/${group}`);
    } else {
      // create
      this.props.history.push(`${INTERNAL_LINKS.GROUP}`);
    }
  };

  render() {
    return (
      <PageContainer title={'All Groups'} className="user-management">
        <Helmet>
          <title>{formatPageTitle('All Groups')}</title>
        </Helmet>
        <GroupForm {...this.props} goToGroupDetails={this.goToGroupDetails} />
      </PageContainer>
    );
  }
}

UserManagement.propTypes = {};

UserManagement.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(withAuthentication(UserManagement)));
