export const LOCATIONS_CSV_TEMPLATE = [
  {
    label: 'label',
    fieldName: 'label',
  },
  {
    label: 'streetNumber',
    fieldName: 'streetNumber',
  },
  {
    label: 'streetOne',
    fieldName: 'streetOne',
  },
  {
    label: 'streetTwo',
    fieldName: 'streetTwo',
  },
  {
    label: 'city',
    fieldName: 'city',
  },
  {
    label: 'state',
    fieldName: 'state',
  },
  {
    label: 'postalCode',
    fieldName: 'postalCode',
  },
  {
    label: 'country',
    fieldName: 'country',
  },
  {
    label: 'latitude',
    fieldName: 'latitude',
  },
  {
    label: 'longitude',
    fieldName: 'longitude',
  },
];

export const LOCATIONS_CSV_EMPTY_DATA = [
  LOCATIONS_CSV_TEMPLATE.map(config => config.fieldName).reduce(
    (o, key) => Object.assign(o, { [key]: '' }),
    {},
  ),
];

export const LOCATIONS_CSV_HEADERS = LOCATIONS_CSV_TEMPLATE.map(config => {
  return {
    label: config.label,
    key: config.fieldName,
  };
});
