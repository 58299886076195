import { get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { selectStoreCurrentCompany, useStoreSelector } from '../../../utils/storeSelectors';
import { getDistanceUnitStringValue } from '../../../utils/trips';

const useCommuteFormState = ({ formInstance, initialCommuteSettings }) => {
  const currentCompany = useStoreSelector(selectStoreCurrentCompany);

  const [isCommuteSettingsDirty, setIsCommuteSettingsDirty] = useState(false);
  const [commuteSettings, setCommuteSettings] = useState(initialCommuteSettings);

  const {
    isCommuteSettingSingleLocation,
    isCommuteSettingNotHaveOffice,
    isCommuteSettingCustom,
    isNoOfficeMaxDeduction,
    isNoOfficeIgnoreFirstAndLast,
    isHomeAddressMaxCommuteDeduction,
    commuteDistanceUnit,
  } = useMemo(() => {
    return {
      isCommuteSettingSingleLocation: commuteSettings?.mainSetting === 'singleOffice',
      isCommuteSettingNotHaveOffice: commuteSettings?.mainSetting === 'noOffice',
      isCommuteSettingCustom: commuteSettings?.mainSetting === 'customBusinessRule',
      isNoOfficeMaxDeduction: commuteSettings?.secondarySetting === 'maxCommuteDeduction',
      isNoOfficeIgnoreFirstAndLast: commuteSettings?.secondarySetting === 'mileageRadius',
      isHomeAddressMaxCommuteDeduction:
        commuteSettings?.secondarySetting === 'homeAddressMaxCommuteDeduction',

      commuteDistanceUnit:
        commuteSettings?.secondarySettingValueUnit ||
        getDistanceUnitStringValue(currentCompany.country, true),
    };
  }, [commuteSettings, currentCompany.country]);

  const handleCommuteSettingChange = (setting = {}) => {
    if (setting?.enabled) {
      const updatedValues = {
        ...(get(currentCompany, 'companySettingId.commute') || {}),
        enabled: true,
      };
      setCommuteSettings(updatedValues);
      if (formInstance) {
        formInstance.setFieldsValue(updatedValues);
      }
    } else {
      const updatedValues = { ...commuteSettings, ...setting };
      setCommuteSettings(updatedValues);
      if (formInstance) {
        formInstance.setFieldsValue(updatedValues);
      }
    }
    setIsCommuteSettingsDirty(true);
  };

  const handleResetCommuteDirty = useCallback(() => {
    setIsCommuteSettingsDirty(false);
  }, []);

  const formattedCommuteSettings = useMemo(() => {
    if (!!Object.keys(commuteSettings).length) {
      const settings = {
        enabled: commuteSettings.enabled,
      };

      if (commuteSettings.enabled) {
        settings.level = commuteSettings.level;
        settings.mainSetting = commuteSettings.mainSetting;

        if (isCommuteSettingSingleLocation) {
          settings.secondarySetting = commuteSettings.secondarySetting;
        } else if (isCommuteSettingNotHaveOffice) {
          settings.secondarySetting = commuteSettings.secondarySetting;

          if (
            isNoOfficeMaxDeduction ||
            isHomeAddressMaxCommuteDeduction ||
            isNoOfficeIgnoreFirstAndLast
          ) {
            settings.secondarySettingValueUnit = commuteDistanceUnit;
            settings.secondarySettingValue = commuteSettings.secondarySettingValue;
          }
        } else if (isCommuteSettingCustom) {
          settings.secondarySettingValue = commuteSettings.secondarySettingValue;
        }
      }

      return settings;
    }
  }, [
    commuteSettings,
    isCommuteSettingSingleLocation,
    isNoOfficeMaxDeduction,
    isHomeAddressMaxCommuteDeduction,
    isNoOfficeIgnoreFirstAndLast,
    isCommuteSettingCustom,
    isCommuteSettingNotHaveOffice,
    commuteDistanceUnit,
  ]);

  return {
    commuteSettings,
    formattedCommuteSettings,
    isCommuteSettingsDirty,
    handleCommuteSettingChange,
    handleResetCommuteDirty,

    isCommuteSettingSingleLocation,
    isCommuteSettingNotHaveOffice,
    isCommuteSettingCustom,
    isNoOfficeMaxDeduction,
    isNoOfficeIgnoreFirstAndLast,
    isHomeAddressMaxCommuteDeduction,
    commuteDistanceUnit,
  };
};

export default useCommuteFormState;
