import moment from 'moment-timezone';

import Toast from '../../components/Toast';
import i18n from '../../i18n';
import { selectStoreCompanySettings } from '../../utils/storeSelectors';

// trip submission -> trip approval -> reimbursement report -> payment day

const validateTripSubmissionDeadlineWorkflow = (
  paymentDay,
  tripSubmissionDayAmount,
  tripApprovalDayAmount,
) => {
  let isValid = true;

  const PAYMENT_DAY = paymentDay;
  const TRIP_SUBMISSION_DAY = moment(paymentDay).subtract(tripSubmissionDayAmount, 'days');
  const TRIP_APPROVAL_DAY = moment(paymentDay).subtract(tripApprovalDayAmount, 'days');

  // =====================
  //  Payment Day DEADLINE
  // =====================
  if (moment(TRIP_SUBMISSION_DAY).isSameOrAfter(PAYMENT_DAY)) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('submissionDeadlineBeforePayment'),
    });
  } else if (moment(TRIP_APPROVAL_DAY).isAfter(PAYMENT_DAY)) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('approvalDeadlineBeforeSamePayment'),
    });
    // =======================
    //  Trip Approval DEADLINE
    // =======================
  } else if (moment(TRIP_SUBMISSION_DAY).isSameOrAfter(TRIP_APPROVAL_DAY)) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('submissionDeadlineBeforeApproval'),
    });
  }

  return isValid;
};

export const validateMonthlyScheduleParams = params => {
  let isValid = true;
  const companySettings = selectStoreCompanySettings();

  const {
    paymentPeriodStart,
    achTransferDayOfMonth,
    paymentDayOfMonth,
    tripsSubmissionDeadline,
    managerApprovalDeadline,
  } = params;

  if (!paymentPeriodStart) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('paymentPeriodStartDateMustBeSelected'),
    });
  } else if (
    !paymentDayOfMonth ||
    typeof tripsSubmissionDeadline !== 'number' ||
    typeof managerApprovalDeadline !== 'number'
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('mustSetMonthlySettings'),
    });
  } else if (companySettings.ach_enabled && achTransferDayOfMonth >= paymentDayOfMonth) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('achPaymentBeforeEndDate'),
    });
  } else if (
    !validateTripSubmissionDeadlineWorkflow(
      paymentDayOfMonth,
      tripsSubmissionDeadline,
      managerApprovalDeadline,
    )
  ) {
    isValid = false;
  }

  return isValid;
};

export const validateWeeklyScheduleParams = params => {
  let isValid = true;

  const {
    paymentPeriodStart,
    dayOfWeekForPayment,
    tripsSubmissionDeadline,
    managerApprovalDeadline,
  } = params;

  const paymentDay = moment()
    .startOf('month')
    .day(
      ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][
        dayOfWeekForPayment
      ],
    );

  const TRIP_SUBMISSION_DAY = moment(paymentDay).subtract(tripsSubmissionDeadline, 'days');
  const TRIP_APPROVAL_DAY = moment(paymentDay).subtract(managerApprovalDeadline, 'days');

  if (!paymentPeriodStart) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('paymentPeriodStartDateMustBeSelected'),
    });
  } else if (
    !dayOfWeekForPayment ||
    typeof tripsSubmissionDeadline !== 'number' ||
    typeof managerApprovalDeadline !== 'number'
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('mustSetWeeklySettings'),
    });
  }
  if (moment(TRIP_SUBMISSION_DAY).isAfter(paymentDay)) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('submissionDeadlineBeforeSamePayment'),
    });
  } else if (moment(TRIP_APPROVAL_DAY).isAfter(paymentDay)) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('approvalDeadlineBeforePayment'),
    });
    // =======================
    //  Trip Approval DEADLINE
    // =======================
  } else if (moment(TRIP_SUBMISSION_DAY).isAfter(TRIP_APPROVAL_DAY)) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('submissionDeadlineBeforeSameApproval'),
    });
  }

  return isValid;
};

export const validateCustomScheduleParams = params => {
  let isValid = true;

  const {
    paymentPeriodStart,
    dateForPayment,
    paymentRecurrenceNumber,
    paymentRecurrencePeriod,
    tripsSubmissionDeadline,
    managerApprovalDeadline,
  } = params;

  if (!paymentPeriodStart) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('paymentPeriodStartDateMustBeSelected'),
    });
  } else if (
    !dateForPayment ||
    !paymentRecurrenceNumber ||
    !paymentRecurrencePeriod ||
    typeof tripsSubmissionDeadline !== 'number' ||
    typeof managerApprovalDeadline !== 'number'
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: i18n.t('mustSetCustomSettings'),
    });
  } else if (
    !validateTripSubmissionDeadlineWorkflow(
      dateForPayment,
      tripsSubmissionDeadline,
      managerApprovalDeadline,
    )
  ) {
    isValid = false;
  }

  return isValid;
};
