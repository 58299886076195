import { Col, Row, Upload } from 'antd';
import React from 'react';
import storage from 'store';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';

const UploadTripsSection = props => {
  const { t, loading, disabled, csvLink, onUploadSuccess } = props;

  const accessToken = storage.get('accessToken');

  const handleCsvUpload = async uploadInfo => {
    const { status } = uploadInfo.file;

    if (status === 'uploading') {
    } else if (status === 'done') {
      onUploadSuccess();
      Toast({
        type: 'success',
        message: t('csvUploadSuccess'),
      });
    } else if (status === 'error') {
      Toast({
        type: 'error',
        message: t('csvImportError'),
      });
    }
  };

  return (
    <div>
      <Upload.Dragger
        accept=".csv"
        method="post"
        multiple={false}
        action={`${process.env.REACT_APP_HOST_API}trip/import`}
        onChange={handleCsvUpload}
        disabled={loading || disabled}
        headers={{
          Authorization: `Bearer ${accessToken}`,
        }}
      >
        <br />
        <br />
        <br />

        <Row justify="center">
          <Col>
            <Text textAlign="center">No Trip Data</Text>

            <br />

            <Text textAlign="center">Click or Drag a CSV file to this area to upload</Text>
            <Text textAlign="center" style={{ marginBottom: 5 }}>
              {t('-- or --')}
            </Text>
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <Button type="secondary" disabled={loading || disabled} loading={loading}>
              Upload
            </Button>
          </Col>
        </Row>

        <br />

        <Row justify="center">
          <Col
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {csvLink}
          </Col>
        </Row>
      </Upload.Dragger>
    </div>
  );
};

export default UploadTripsSection;
