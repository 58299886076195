import { Col, Row } from 'antd';
import classnames from 'classnames';
import React, { Component } from 'react';
import storage from 'store';

import { Button } from '../../components';
import SpaceSpinner from '../../components/SpaceSpinner';
import Text from '../../components/Text';
import classNames from './style.module.scss';

/**
 * Standard container for each each page of the Dashboard.
 * This can take a _title_ and a _caption_ as props.
 */
class PageContainer extends Component {
  render() {
    const {
      title,
      caption,
      className,
      sideActionComponent,
      pageActionText,
      onPageActionClick,
      pageActionProps,
      loading,
      stickyHeader,
      noSurroundingSpace,
      ...rest
    } = this.props;
    const CAN_DISPLAY_HEADER =
      title || caption || (pageActionText && typeof onPageActionClick === 'function');

    return (
      <div
        className={classnames(classNames.pageContainer, className, {
          [classNames.withImpersonationBanner]: !!storage.get('previousAccessToken'),
        })}
        {...rest}
      >
        {CAN_DISPLAY_HEADER && (
          <Row
            className={classnames(classNames.pageHeader, {
              [classNames.stickyHeader]: stickyHeader,
              [classNames.noPadding]: noSurroundingSpace,
            })}
          >
            <Col flex={1}>
              {typeof title === 'string' && (
                <Text variant="h3" className={classNames.title}>
                  {title}
                </Text>
              )}

              {title && typeof title !== 'string' && (
                <Text variant="h3" renderAs="div" className={classNames.title}>
                  {title}
                </Text>
              )}

              {caption && (
                <Text variant="h2" className={classNames.caption}>
                  {caption}
                </Text>
              )}
            </Col>

            {pageActionText && typeof onPageActionClick === 'function' && (
              <Button size="sm" type="primary" onClick={onPageActionClick} {...pageActionProps}>
                {pageActionText}
              </Button>
            )}

            {sideActionComponent && !onPageActionClick && <Col>{sideActionComponent}</Col>}
          </Row>
        )}

        {loading ? (
          <SpaceSpinner />
        ) : (
          <div
            className={classnames(classNames.pageContentWrapper, {
              [classNames.noMargin]: noSurroundingSpace,
            })}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

PageContainer.defaultProps = {
  title: ' ',
  pageActionProps: {},
  stickyHeader: false,
  noSurroundingSpace: false,
};

export default PageContainer;
