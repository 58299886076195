export const UPDATE_DEVICE_WIDTH = 'UPDATE_DEVICE_WIDTH';
export const WINDOW_SIZE_CHANGE = 'WINDOW_SIZE_CHANGE';

export const deviceWidthUpdate = () => {
  return {
    type: WINDOW_SIZE_CHANGE,
  };
};

export const updateBrowserStore = () => {
  return {
    type: UPDATE_DEVICE_WIDTH,
    data: {
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
      orientation: window.innerHeight > window.innerWidth ? 'portrait' : 'landscape',
      isMobile: window.innerWidth <= 480,
      isTablet: window.innerWidth > 480 && window.innerWidth <= 768,
      isDesktop: window.innerWidth > 768,
    },
  };
};
