import { Col, Progress, Row } from 'antd';
import moment from 'moment-timezone';
import React from 'react';

import Text from '../../../components/Text';
import Tooltip from '../../../components/Tooltip';
import { IMAGES } from '../../../enum';
import classNames from './style.module.scss';

const FreePlanRemainingDaysSection = props => {
  const { t, endDate, totalFreeTrialDays, planName, hasBillingMethod } = props;

  const CURRENT_DATE = moment(new Date());
  const END_DATE = moment(endDate);
  // const FIVE_DAYS_REMINDER = moment(new Date()).add(FREE_TRIAL_DURATION - 5, 'days');

  const FIVE_DAYS_REMINDER_PERCENT_POSITION = ((totalFreeTrialDays - 5) / totalFreeTrialDays) * 100;
  const CURRENT_DATE_PERCENT_POSITION =
    ((totalFreeTrialDays -
      Math.abs(CURRENT_DATE.clone().subtract(1, 'days').diff(END_DATE, 'days'))) /
      totalFreeTrialDays) *
    100;

  return (
    <section className={classNames.contentWrapper}>
      <Text variant="h4" textAlign="center">
        {t('youAreOnAFreeTrialOf_x_days', {
          totalFreeTrialDays,
          planName,
        })}
      </Text>

      {hasBillingMethod && (
        <Text variant="h2" textAlign="center">
          {t('yourMonthlyBillingWillBeginOnTrialEnd')}
        </Text>
      )}

      <div>
        <Row gutter={10} align="middle" style={{ flexWrap: 'nowrap' }}>
          <Col>
            <Text variant="p" size="sm">
              {t('free_x_daysTrial', { totalFreeTrialDays })}
            </Text>
          </Col>

          <Col flex={1} />

          <Col>
            <Text variant="p" size="sm">
              {END_DATE.format('MM/DD/YYYY')}
            </Text>
          </Col>
        </Row>

        <div className={classNames.progressBarWrapper}>
          <Tooltip title={`${t('currentDate')}: ${CURRENT_DATE.format('MM/DD/YYYY')}`}>
            <Progress
              strokeWidth={20}
              strokeColor="#16B396"
              trailColor="#D1D3D9"
              showInfo={false}
              strokeLinecap="square"
              percent={CURRENT_DATE_PERCENT_POSITION}
              style={{ borderRadius: 0 }}
            />
          </Tooltip>

          <Row className={classNames.reminderBar} gutter={10}>
            <Col
              className={classNames.emptyReminderProgress}
              style={{
                width: `calc(${FIVE_DAYS_REMINDER_PERCENT_POSITION}% + 153px)`,
              }}
            />

            <Col className={classNames.reminderText}>
              <Row gutter={5} align="middle" style={{ flexWrap: 'nowrap' }}>
                <Col>
                  <img height="24px" width="24px" src={IMAGES.EMAIL_ICON} alt="email" />
                </Col>

                <Col flex="120px">
                  <Text variant="p" size="sm">
                    {t('fiveDayReminder')}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

FreePlanRemainingDaysSection.defaultProps = {
  planName: '',
  startDate: new Date(),
  totalFreeTrialDays: 30,
};

export default FreePlanRemainingDaysSection;
