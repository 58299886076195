import { isActiveCompany } from '../../../utils/company';
import { hasCompanyAdminRole, hasCompanyManagerOrAdminRole } from '../../../utils/roles';
import {
  selectStoreCompanySettings,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';

export const canFilterByGroup = userProfile => {
  return hasCompanyManagerOrAdminRole(userProfile);
};

export const canFilterByUser = userProfile => {
  return canFilterByGroup(userProfile);
};

export const canMarkReimbursementAsPaid = userProfile => {
  const currentCompany = selectStoreCurrentCompany();
  const companySettings = selectStoreCompanySettings();

  return (
    hasCompanyAdminRole(userProfile) &&
    isActiveCompany(currentCompany) &&
    companySettings.ach_enabled
  );
};

export const canSaveAdjustments = receiptAdjustments => {
  return receiptAdjustments && !!Object.values(receiptAdjustments).filter(Boolean).length;
};

export const canApproveOrRejectReimbursements = userProfile => {
  return hasCompanyManagerOrAdminRole(userProfile);
};
