import { Card } from 'antd';
import React from 'react';

import { momentFormat } from '../../utils/common';

export const StartPopupContent = ({ point }) => {
  return (
    <Card bordered={false} bodyStyle={{ padding: 0, textAlign: 'left' }}>
      <strong>{point.formattedAddress}</strong>
      {point.journeyStartTs ? (
        <div>
          <strong>Start time:</strong> {momentFormat(point.journeyStartTs, 'YYYY-MM-DD hh:mm a zz')}
        </div>
      ) : null}
    </Card>
  );
};
