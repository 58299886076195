import { merge, pickBy, startsWith } from 'lodash';
import moment from 'moment-timezone';

import { momentTimezone } from './common';
import { getMomentDateWithoutTimezone } from './datetime';
import { selectStoreCurrentAuthUser, selectStoreItineraryClientByID } from './storeSelectors';
import { formatAddressIntoString } from './trips';

export const formatItineraryFormValuesToBE = values => {
  const authUser = selectStoreCurrentAuthUser();

  const destinationKeys = Object.keys(
    pickBy(values, (value, key) => {
      return startsWith(key, 'destination');
    }),
  );

  let formattedDateFrom = getMomentDateWithoutTimezone(values.dateFrom).format('YYYY-MM-DD');

  const destinationAddresses = [];

  destinationKeys.forEach(key => {
    // eslint-disable-next-line no-unused-vars
    let [_, paramName, index] = key.split('_');
    paramName = paramName === 'addr' ? 'address' : paramName;

    if (!destinationAddresses[index]) {
      destinationAddresses[index] = {};
    }

    destinationAddresses[index] = {
      ...destinationAddresses[index],
      [paramName]: moment.isMoment(values[key])
        ? moment(formattedDateFrom + ' ' + values[key].format('HH:mm:ss Z')).toISOString()
        : values[key],
    };
  });

  let clientDetails = [];

  if (values.createNewContact) {
    clientDetails.push({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      clientAddress: values.formattedAddress,
    });
  } else if (values.clientDetails) {
    const client = selectStoreItineraryClientByID(values.clientDetails);
    clientDetails.push({ clientId: client._id, clientAddress: client.formattedAddress });
  } else {
    clientDetails.push({
      firstName: authUser.profile.firstName,
      lastName: authUser.profile.lastName,
      email: authUser.profile.email,
      clientAddress: formatAddressIntoString(authUser.profile.personalInfor.homeAddress),
    });
  }

  return {
    dateFrom: destinationAddresses[0].reachingTime,
    dateTo: destinationAddresses[destinationAddresses.length - 1].reachingTime,
    userId: authUser.profile._id,
    startAddress: values.startAddress,

    clientDetails,

    destinationAddresses,
  };
};

export const formatItineraryBEToFormValues = values => {
  if (!Array.isArray(values.trips)) {
    return values;
  }

  const destinations = [];

  let formattedDestinations = {};

  values.trips.forEach((trip, i) => {
    destinations.push({
      id: String(i),
      address: trip.tripEndLocationAddress,
      reachingTime:
        typeof trip.tripEndTime === 'undefined'
          ? moment().startOf('hour').add(1, 'hour')
          : moment(trip.tripEndTime),
    });

    merge(formattedDestinations, {
      [`destination_addr_${i}`]: trip.tripEndLocationAddress,
      [`destination_reachingTime_${i}`]:
        typeof trip.tripEndTime === 'undefined'
          ? moment().startOf('hour').add(1, 'hour')
          : moment(trip.tripEndTime),
    });
  });

  let clientID = getItineraryClientData(values.clientDetails)?._id || '';

  return {
    clientDetails: clientID,
    dateFrom: getMomentDateWithoutTimezone(values.dateFrom),
    startAddress: values.trips[0].tripStartLocationAddress,
    destinations,
    ...formattedDestinations,
  };
};

export const calcItineraryTripsTotalDistance = trips => {
  return trips.reduce((total, trip) => parseFloat(total) + parseFloat(trip.tripDistanceOptimal), 0);
};

export const calcItineraryTripsTotalDrivingTime = trips => {
  const totalDrivingTime = trips.reduce((total, trip) => {
    // const startTime = momentTimezone(trip.tripStartTime, 'h:mma');
    // const endTime = momentTimezone(trip.tripEndTime, 'h:mma');
    // const positiveDifference = Math.abs(endTime.diff(startTime, 'minutes'));
    return total + trip.visitDuration;
  }, 0);

  return totalDrivingTime;
};

export const getItineraryClientData = (clientDetails = []) => {
  if (Array.isArray(clientDetails) && clientDetails[0]) {
    const client = clientDetails[0];
    if (typeof client?.clientId === 'string') {
      return { ...client, _id: client.clientId };
    } else if (typeof client?.clientId === 'object') {
      return { ...client.clientId };
    }
  }

  return clientDetails;
};

export const getUpcomingItinerary = (itineraries = []) => {
  return itineraries.find(itinerary => momentTimezone(itinerary.dateTo).isAfter(new Date()));
};

export const getLatestCompletedItinerary = (itineraries = []) => {
  return itineraries.find(itinerary => momentTimezone(new Date()).isAfter(itinerary.dateTo));
};
