export const TRIPS_CSV_TEMPLATE = [
  {
    label: 'employeeEmail',
    fieldName: 'employeeEmail',
  },
  {
    label: 'tripOriginAddress',
    fieldName: 'tripOriginAddress',
  },
  {
    label: 'tripDestinationAddress',
    fieldName: 'tripDestinationAddress',
  },
  {
    label: 'tripOriginCompanyLocation',
    fieldName: 'tripOriginCompanyLocation',
  },
  {
    label: 'tripDestinationCompanyLocation',
    fieldName: 'tripDestinationCompanyLocation',
  },
  {
    label: 'tripOriginStartDate',
    fieldName: 'tripOriginStartDate',
  },
  {
    label: 'tripOriginStartTime',
    fieldName: 'tripOriginStartTime',
  },
  {
    label: 'tripDestinationEndDate',
    fieldName: 'tripDestinationEndDate',
  },
  {
    label: 'tripDestinationEndTime',
    fieldName: 'tripDestinationEndTime',
  },
  {
    label: 'purpose',
    fieldName: 'purpose',
  },
  {
    label: 'useTollRoads',
    fieldName: 'useTollRoads',
  },
  {
    label: 'autoSubmit',
    fieldName: 'autoSubmit',
  },
];

export const TRIPS_CSV_EMPTY_DATA = [
  TRIPS_CSV_TEMPLATE.map(config => config.fieldName).reduce(
    (o, key) => Object.assign(o, { [key]: '' }),
    {},
  ),
];

export const TRIPS_CSV_HEADERS = TRIPS_CSV_TEMPLATE.map(config => {
  return {
    label: config.label,
    key: config.fieldName,
  };
});
