import { Row, Space } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import CompanyUsersLookupSelect from '../../components/CompanyUsersLookupSelect';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import PageFiltersRenderer from '../../components/shared-ui/PageFiltersRenderer';
import AnomaliesInsuranceComplianceTable from '../../components/Table/AnomaliesInsuranceComplianceTable';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import { FLAGSMITH_KEYS } from '../../enum/Flagsmith';
import withAuthentication from '../../hocs/withAuthentication';
import withFlagEnabled from '../../hocs/withFlagEnabled';
import usePaginatedAllCompanyUsersQuery from '../../hooks/queries/usePaginatedAllCompanyUsersQuery';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import usePaymentSchedulePeriods from '../../hooks/usePaymentSchedulePeriods';
import {
  formatPageTitle,
  momentCompanyTimezone,
  momentFormat,
  momentTimezone,
} from '../../utils/common';
import { cleanQueryParams } from '../../utils/queryParams';
import { selectStoreCurrentCompany, useStoreSelector } from '../../utils/storeSelectors';

const COMMON_RESPONSE_FILTER_SETTINGS = {
  xs: 24,
  md: 12,
  lg: 6,
};

const PageWrapper = ({ t, history, queryParams, children, loading }) => {
  return (
    <PageContainer
      loading={loading}
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('Dashboard'),
              onClick: () =>
                history.push({
                  pathname: INTERNAL_LINKS.ANOMALIES_DASHBOARD,
                  search: cleanQueryParams(queryParams),
                }),
            },
            { label: t('anomalies:userInsurancePolicyCompliance') },
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('User Insurance Policy Compliance')}</title>
      </Helmet>

      {children}
    </PageContainer>
  );
};

const AnomaliesInsuranceComplianceAlertView = props => {
  const { t, location, history } = props;
  const currentCompany = useStoreSelector(selectStoreCurrentCompany);
  const { paymentScheduleId, fromDate, endDate } = useLocationSearchQueryParser(location);

  const {
    isFetchingPaymentSchedules,
    selectedPaymentSchedule,
    selectedPaymentPeriod,
  } = usePaymentSchedulePeriods({ paymentScheduleId, fromDate, endDate });

  const [filters, setFilters] = useState({
    userId: null,
    status: null,
    searchTerm: '',
    fromDate: fromDate ? momentCompanyTimezone(fromDate) : null,
    toDate: endDate ? momentCompanyTimezone(endDate) : null,
  });

  const handleFilterChange = useCallback(values => {
    setFilters(currentFilters => ({ ...currentFilters, ...values }));
  }, []);

  const {
    allUsersTablePagination,
    allUsersQuery,
    allUsersHandleTableSort,
  } = usePaginatedAllCompanyUsersQuery(currentCompany?._id, {
    searchTerm: filters.searchTerm,
    status: [STATUS_LIST().Status.ACTIVE, STATUS_LIST().Status.PENDING],
  });

  const anomaliesParams = useMemo(
    () => ({
      type: 'high-manual-miles',
      paymentScheduleId: get(selectedPaymentSchedule, '_id'),
      paymentPeriodStart: selectedPaymentPeriod
        ? get(JSON.parse(selectedPaymentPeriod), 'fromDate')
        : '',
      paymentPeriodEnd: selectedPaymentPeriod
        ? get(JSON.parse(selectedPaymentPeriod), 'endDate')
        : '',
    }),
    [selectedPaymentSchedule, selectedPaymentPeriod],
  );

  if (isFetchingPaymentSchedules || !selectedPaymentSchedule) {
    return <PageWrapper t={t} history={history} loading />;
  }

  return (
    <PageWrapper
      t={t}
      history={history}
      queryParams={{
        paymentScheduleId: anomaliesParams.paymentScheduleId,
        fromDate: anomaliesParams.paymentPeriodStart,
        endDate: anomaliesParams.paymentPeriodEnd,
      }}
    >
      <Space direction="vertical" size="large">
        <PageFiltersRenderer
          t={t}
          loading={allUsersQuery.isFetching}
          search={{ defaultValue: filters.searchTerm }}
          onFiltersChange={handleFilterChange}
          filters={[
            {
              componentType: 'custom',
              label: t('user'),
              responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              defaultValue: filters.userId || null,
              value: filters.userId || null,
              render: ({ updateFilterValues, disabled, loading }) => (
                <CompanyUsersLookupSelect
                  t={t}
                  showAllOption
                  disabled={disabled || loading}
                  value={filters?.userId}
                  onChange={(userId, user) => {
                    updateFilterValues({ userId, searchTerm: user?.firstName });
                  }}
                />
              ),
            },
            {
              label: t('date'),
              allowClear: false,
              componentType: 'dateRange',
              name: ['fromDate', 'toDate'],
              value: filters.fromDate && filters.toDate ? [filters.fromDate, filters.toDate] : null,
              defaultValue:
                filters.fromDate && filters.toDate ? [filters.fromDate, filters.toDate] : null,
              responsive: COMMON_RESPONSE_FILTER_SETTINGS,
            },
            {
              componentType: 'select',
              label: t('status'),
              name: 'status',
              defaultValue: filters.status || null,
              value: filters.status,
              options: [
                { label: t('All'), value: null },
                { label: t(STATUS_LIST().Status.VALID), value: STATUS_LIST().Status.VALID },
                { label: t(STATUS_LIST().Status.INVALID), value: STATUS_LIST().Status.INVALID },
              ],
              responsive: {
                ...COMMON_RESPONSE_FILTER_SETTINGS,
                lg: 4,
              },
            },
          ]}
        />

        <Row>
          <AnomaliesInsuranceComplianceTable
            t={t}
            asyncSort
            dataSource={(allUsersQuery.data?.users || []).map((user, index) => {
              const isExpired =
                index === 0 && allUsersTablePagination.paginationConfig.current === 1;
              return {
                user,
                status: isExpired ? STATUS_LIST().Status.INVALID : STATUS_LIST().Status.VALID,
                startDate: isExpired
                  ? momentTimezone(fromDate).subtract(2, 'month').format('DD MMM, YYYY')
                  : momentFormat(fromDate, 'DD MMM, YYYY'),
                policyExpirationDate: isExpired
                  ? momentTimezone(endDate).subtract(2, 'month').format('DD MMM, YYYY')
                  : momentFormat(endDate, 'DD MMM, YYYY'),
                invalidCode: 'N/A',
                lastUpdate: isExpired
                  ? momentTimezone(new Date()).subtract(2, 'month').format('DD MMM, YYYY')
                  : momentFormat(new Date(), 'DD MMM, YYYY'),
                withholding: 0,
              };
            })}
            loading={allUsersQuery.isFetching}
            onChange={({ current }, filters, sorters) => {
              allUsersTablePagination.handlePageChange(current);
              allUsersHandleTableSort(sorters?.columnKey, sorters?.order);
            }}
            pagination={{
              pageSize: allUsersTablePagination.paginationConfig.pageSize,
              total: allUsersTablePagination.paginationConfig.total,
              current: allUsersTablePagination.paginationConfig.current,
              onShowSizeChange: allUsersTablePagination.handlePageSizeChange,
            }}
          />
        </Row>
      </Space>
    </PageWrapper>
  );
};

export default withNamespaces(['default', 'anomalies'])(
  withAuthentication(
    withFlagEnabled(
      AnomaliesInsuranceComplianceAlertView,
      [FLAGSMITH_KEYS.KI_3958_INSURANCE_COMPLIANCE],
      INTERNAL_LINKS.ANOMALIES_DASHBOARD,
    ),
  ),
);
