import React from 'react';

import classNames from './style.module.scss';

const StepCircle = props => {
  const { step, disabled } = props;
  return (
    <div className={[classNames.stepCircle, disabled ? classNames.fadedCircle : ''].join(' ')}>
      {step}
    </div>
  );
};

export default StepCircle;
