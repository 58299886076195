import useTablePagination from '../useTablePagination';
import useDwollaResourcesQuery from './useDwollaResourcesQuery';

/**
 * @param {"mass-payment" | "transfer"} type
 * @param {import('react-query').UseQueryOptions} queryConfig
 */
const usePaginatedDwollaResourcesQuery = (type, queryConfig = {}) => {
  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  return {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    query: useDwollaResourcesQuery(type, paginationConfig.current, paginationConfig.pageSize, {
      ...queryConfig,
      onSuccess: data => {
        handleTotalUpdate(data.count);
      },
    }),
  };
};

export default usePaginatedDwollaResourcesQuery;
