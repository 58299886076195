import { Table } from 'antd';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import { ADMIN_API } from '../../api/admin';
import { handleApiErrors } from '../../api/axiosInstance';
import FAVRCreatorAPI from '../../api/favr-creator';
import { Button, CustomTable } from '../../components';
import CompanyLookupSelect from '../../components/CompanyLookupSelect';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import PageFiltersRenderer from '../../components/shared-ui/PageFiltersRenderer';
import PageContainer from '../../containers/PageContainer';
import withPageState from '../../hocs/withPageState';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import { formatDistanceToLocale, formatNumberWithCurrency } from '../../utils/numbers';
import { replaceCurrentPageSearchQueryParams } from '../../utils/queryParams';
import { sortColumnByNumber, sortColumnByStringField } from '../../utils/tables';
import { scrollAppContainerToTop } from '../../utils/window';

const COMMON_RESPONSE_FILTER_SETTINGS = {
  xs: 24,
  md: 12,
  lg: 6,
};

const FAVRRatesView = props => {
  const { t, location, history } = props;

  const [favrRates, setFavrRates] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState();

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const initialFilters = useLocationSearchQueryParser(location, {
    companyId: null,
    searchTerm: '',
  });
  const [tableFilters, setTableFilters] = useState(initialFilters);

  const handleFiltersChange = useCallback(
    filters => {
      setTableFilters(filters);
      replaceCurrentPageSearchQueryParams(history, filters);
    },
    [history],
  );

  const isLastPage = useMemo(() => {
    return currentPage === favrRates.length / pageSize;
  }, [currentPage, pageSize, favrRates]);

  const normalizeRates = (items = []) => {
    return items.map(item => {
      let date = '-';

      if (item?.rates?.date) {
        const year = item.rates.date.substring(0, 4);
        const month = item.rates.date.substring(4, 6);
        const day = item.rates.date.substring(6, 8);
        date = [year, month, day].join('-');
      }

      return {
        ...item,
        date,
      };
    });
  };

  const favrRatesQuery = useMutation(
    lastKey => new FAVRCreatorAPI().fetchFavrRates(lastKey, tableFilters, pageSize),
    {
      onSuccess: data => {
        setFavrRates(rates => [...rates, ...normalizeRates(data.items)]);
        setLastEvaluatedKey(data.lastEvaluatedKey);
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const refreshRatesMutation = useMutation(() => ADMIN_API.clearCache(['favr-creator']), {
    onSuccess: () => {
      setFavrRates([]);
      setLastEvaluatedKey();
      favrRatesQuery.mutateAsync();
    },
    onError: error => handleApiErrors(error.response),
  });

  const resetSearchQuery = useCallback(() => {
    setFavrRates([]);
    setLastEvaluatedKey();
    setCurrentPage(1);
    favrRatesQuery.mutateAsync();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetSearchQuery();
    // eslint-disable-next-line
  }, [tableFilters, resetSearchQuery]);

  useDidUpdateEffect(() => {
    if (favrRates.length < pageSize) {
      resetSearchQuery();
    }
    // eslint-disable-next-line
  }, [pageSize, resetSearchQuery]);

  const COLUMNS = useMemo(
    () => [
      {
        dataIndex: 'uid',
        title: t('driverEmail'),
        ...sortColumnByStringField('uid'),
      },
      {
        dataIndex: ['address', 'state'],
        title: t('state'),
        render: state => state || '-',
        ...sortColumnByStringField('address.state'),
      },
      {
        dataIndex: ['address', 'zip'],
        title: t('zipCode'),
        ...sortColumnByNumber('address.zip'),
      },
      {
        dataIndex: 'make',
        title: t('make'),
        render: make => capitalize(make),
        ...sortColumnByStringField('make'),
      },
      {
        dataIndex: 'model',
        title: t('model'),
        render: model => capitalize(model),
        ...sortColumnByStringField('model'),
      },
      {
        align: 'right',
        dataIndex: 'year',
        title: t('year'),
        ...sortColumnByNumber('year'),
      },
      {
        align: 'right',
        dataIndex: 'retention_years',
        title: t('retentionYears'),
        ...sortColumnByNumber('retention_years'),
      },
      {
        align: 'right',
        dataIndex: 'retention_miles',
        title: t('retentionMiles'),
        render: miles => formatDistanceToLocale(miles) || '-',
        ...sortColumnByNumber('retention_miles'),
      },
      {
        dataIndex: 'date',
        title: t('rateDate'),
        ...sortColumnByStringField('date'),
      },
      {
        align: 'right',
        dataIndex: ['rates', 'fixed_rate'],
        title: t('fixedRate'),
        render: rate => formatNumberWithCurrency(rate, 'USD') || '-',
        ...sortColumnByNumber('rates.fixed_rate'),
      },
      {
        align: 'right',
        dataIndex: ['rates', 'variable_rate'],
        title: t('variableRate'),
        render: rate => formatNumberWithCurrency(rate, 'USD') || '-',
        ...sortColumnByNumber('rates.variable_rate'),
      },
    ],
    [t],
  );

  return (
    <PageContainer
      title={<PageBreadcrumbs items={[{ label: t('favrRates') }]} />}
      pageActionText={t('refreshRates')}
      onPageActionClick={refreshRatesMutation.mutateAsync}
      pageActionProps={{
        size: 'sm',
        disabled: refreshRatesMutation.isLoading || favrRatesQuery.isLoading,
        loading: refreshRatesMutation.isLoading || favrRatesQuery.isLoading,
      }}
    >
      <PageFiltersRenderer
        t={t}
        loading={favrRatesQuery.isLoading}
        onFiltersChange={handleFiltersChange}
        search={{
          placeholder: t('searchByEmail'),
          defaultValue: initialFilters.searchTerm,
        }}
        filters={[
          {
            componentType: 'custom',
            label: t('Company'),
            name: 'companyId',
            responsive: COMMON_RESPONSE_FILTER_SETTINGS,
            defaultValue: initialFilters.companyId || null,
            render: ({ updateFilterValues, disabled, loading }) => (
              <CompanyLookupSelect
                t={t}
                showAllOption
                disabled={disabled || loading}
                value={tableFilters?.companyId}
                defaultValue={initialFilters.companyId || null}
                onChange={companyId => updateFilterValues({ companyId })}
              />
            ),
          },
        ]}
      />

      <CustomTable
        rowKey={row =>
          JSON.stringify({ uid: row.uid, zip_model_yr_retention: row.zip_model_yr_retention })
        }
        pagination={{
          pageSize,
          current: currentPage,
          onShowSizeChange: (current, newSize) => setPageSize(newSize),
        }}
        onChange={({ current }) => {
          setCurrentPage(current);
        }}
        scroll={{ x: 1400 }}
        columns={COLUMNS}
        dataSource={favrRates}
        showSearchInput={false}
        loading={refreshRatesMutation.isLoading || favrRatesQuery.isLoading}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} />
            <Table.Summary.Cell index={1} />
            <Table.Summary.Cell index={2} />
            <Table.Summary.Cell index={3} colSpan={5}>
              {lastEvaluatedKey && isLastPage && (
                <Button
                  block
                  size="sm"
                  loading={refreshRatesMutation.isLoading || favrRatesQuery.isLoading}
                  disabled={refreshRatesMutation.isLoading || favrRatesQuery.isFetching}
                  onClick={() =>
                    favrRatesQuery.mutateAsync(lastEvaluatedKey).then(() => {
                      setCurrentPage(current => current + 1);
                      scrollAppContainerToTop();
                    })
                  }
                >
                  {t('loadMore')}
                </Button>
              )}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4} />
            <Table.Summary.Cell index={5} />
            <Table.Summary.Cell index={6} />
          </Table.Summary.Row>
        )}
      />
    </PageContainer>
  );
};

export default withPageState(FAVRRatesView, true);
