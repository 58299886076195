import { Row } from 'antd';
import { Col } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';

import { ERROR_MESSAGE } from '../../utils/constants';
import Checkbox from '../Checkbox';
import FormItem from '../Form/FormItem';
import Text from '../Text';
import TextInput from '../TextInput';
import { INSURANCE_FIELDS } from './InsurancePerPersonPerAccident.constants';

const INITIAL_VALUES = {};
INSURANCE_FIELDS.forEach(key => {
  INITIAL_VALUES[key] = false;
});

const InsurancePerPersonPerAccident = props => {
  const { t, isSubmitting, variant, formIntance, initialValues, onChange, disabled } = props;

  const IS_USER_VARIANT = variant === 'user';
  const IS_ADMIN_VARIANT = variant === 'admin';

  const [checkedInsuranceFields, setCheckedInsuranceFields] = useState(
    initialValues || INITIAL_VALUES,
  );

  const handleFieldCheck = updatedField => {
    setCheckedInsuranceFields(state => ({ ...state, ...updatedField }));
  };

  return (
    <Row gutter={16} className={classNames.perPersonPerAccidentRow}>
      <Col flex={1}>
        <Row gutter={16} align="middle" wrap={false}>
          <Col xs={8} />

          <Col xs={8}>
            <Text variant="h5">{t('perPerson')}</Text>
          </Col>

          <Col xs={8}>
            <Text variant="h5">{t('perAccident')}</Text>
          </Col>
        </Row>

        {INSURANCE_FIELDS.map(field => {
          const isFieldDisabled =
            isSubmitting || IS_ADMIN_VARIANT ? !checkedInsuranceFields[field] : false;
          const isFieldRequired = IS_ADMIN_VARIANT ? checkedInsuranceFields[field] : false;

          return (
            <Row key={field} gutter={16} align="middle" wrap={false}>
              <Col xs={8}>
                {IS_USER_VARIANT && <FormItem label={t(field)} />}
                {IS_ADMIN_VARIANT && (
                  <FormItem>
                    <Checkbox
                      disabled={disabled}
                      defaultChecked={checkedInsuranceFields[field]}
                      onChange={e => {
                        handleFieldCheck({ [field]: e.target.checked });
                        onChange(field, { enabled: e.target.checked });
                      }}
                    >
                      {t(field)}
                    </Checkbox>
                  </FormItem>
                )}
              </Col>

              <Col xs={8}>
                <FormItem
                  name={`${field}_person`}
                  rules={[{ required: isFieldRequired, message: ERROR_MESSAGE().BLANK_FIELD }]}
                >
                  <TextInput
                    type="number"
                    step="any"
                    disabled={isFieldDisabled || disabled}
                    onChange={value => {
                      onChange(field, { perPerson: value });
                      if (formIntance) {
                        formIntance.setFieldsValue({ [`${field}_person`]: value });
                      }
                    }}
                  />
                </FormItem>
              </Col>

              <Col xs={8}>
                <FormItem
                  name={`${field}_accident`}
                  rules={[{ required: isFieldRequired, message: ERROR_MESSAGE().BLANK_FIELD }]}
                >
                  <TextInput
                    type="number"
                    step="any"
                    disabled={isFieldDisabled || disabled}
                    onChange={value => {
                      onChange(field, { perAccident: value });
                      if (formIntance) {
                        formIntance.setFieldsValue({ [`${field}_accident`]: value });
                      }
                    }}
                  />
                </FormItem>
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};

InsurancePerPersonPerAccident.defaultProps = {
  variant: 'user',
  onChange: () => undefined,
};

export default InsurancePerPersonPerAccident;
