import { Col, Form, Row } from 'antd';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from '../../components';
import CreateItineraryModal from '../../components/Modal/CreateItineraryModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import Text from '../../components/Text';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useCreateItinerary from '../../hooks/useCreateitinerary';
import { ReactComponent as WhitePlusIconSVG } from '../../images/plus.svg';
import useLayout from '../../layout/useLayout';
import { formatPageTitle, getUserFullName } from '../../utils/common';
import { selectStoreCountryByCode, selectStoreCurrentCompany } from '../../utils/storeSelectors';
import classNames from './styles.module.scss';
import useClientDetails from './useClientDetails';

const ClientDetailsDesktopView = React.lazy(() => import('./ClientDetailsDesktopView'));
const ClientDetailsMobileView = React.lazy(() => import('./ClientDetailsMobileView'));

const ClientDetailsView = props => {
  const { t, match, history } = props;

  const [form] = Form.useForm();

  const {
    isEditMode,
    isProcessingRequest,

    contactDetails,

    handleContactFormSubmit,
  } = useClientDetails({ t, match, history });

  const {
    isCreatingItinerary,

    isItineraryCreateModalOpen,
    openItineraryCreateModal,
    closeItineraryCreateModal,

    handleCreateItinerary,
  } = useCreateItinerary({ history });

  const { browserStore } = useLayout({ history });

  const currentCompany = useSelector(selectStoreCurrentCompany);

  const countryConfig = useMemo(() => {
    return selectStoreCountryByCode(currentCompany?.address?.country);
  }, [currentCompany]);

  if (match.params.id && !contactDetails) {
    return <SpaceSpinner />;
  }

  return (
    <div className={classNames.pageWrapper}>
      <Helmet>
        <title>{formatPageTitle('Client Details')}</title>
      </Helmet>

      <Row align="middle" gutter={[16, 16]} className={classNames.pageHeader}>
        <Col>
          <Row align="middle" gutter={[16, 16]} style={{ display: 'inline-flex' }}>
            <Col flex={1}>
              <Text variant="h3" renderAs="p">
                {isEditMode ? (
                  <PageBreadcrumbs
                    items={[
                      {
                        label: 'Contacts',
                        onClick: () => history.push(INTERNAL_LINKS.CLIENTS_LIST),
                      },
                      { label: getUserFullName(contactDetails) },
                    ]}
                  />
                ) : (
                  t('createClient')
                )}
              </Text>
            </Col>
          </Row>
        </Col>
        {isEditMode && (
          <Col>
            <Button
              size={browserStore.isMobile ? 'sm' : 'medium'}
              onClick={openItineraryCreateModal}
              icon={<WhitePlusIconSVG width="18px" style={{ marginRight: 5 }} />}
            >
              {t('createItinerary')}
            </Button>
          </Col>
        )}
      </Row>

      <React.Suspense fallback={<SpaceSpinner />}>
        {browserStore.isTablet || browserStore.isMobile ? (
          <ClientDetailsMobileView
            t={t}
            match={match}
            history={history}
            formInstance={form}
            isEditMode={isEditMode}
            isProcessingRequest={isProcessingRequest}
            contactDetails={contactDetails}
            countryConfig={countryConfig}
            onSubmit={handleContactFormSubmit}
          />
        ) : (
          <ClientDetailsDesktopView
            t={t}
            match={match}
            history={history}
            formInstance={form}
            isEditMode={isEditMode}
            isProcessingRequest={isProcessingRequest}
            contactDetails={contactDetails}
            countryConfig={countryConfig}
            onSubmit={handleContactFormSubmit}
          />
        )}
      </React.Suspense>

      <CreateItineraryModal
        t={t}
        disabledClientChange
        isSavingItinerary={isCreatingItinerary}
        visible={isItineraryCreateModalOpen}
        onCancel={closeItineraryCreateModal}
        onSubmit={handleCreateItinerary}
        initialValues={{
          clientDetails: [{ clientId: match.params.id }],
          trips: [{ tripStartLocationAddress: contactDetails?.formattedAddress }],
        }}
      />
    </div>
  );
};

export default withNamespaces()(withAuthentication(ClientDetailsView));
