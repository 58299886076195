import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { IMAGES, INTERNAL_LINKS } from '../../../enum';
import classNames from './style.module.scss';

const FreeTrialExpiredSection = props => {
  const { t, selectedPlan, hasBillingMethod } = props;

  const ACTIVE_FEATURES = [
    {
      label: t('recordTrips'),
      isActive: true,
    },
    {
      label: t('approveTrips'),
      isActive: true,
    },
    {
      label: t('reimburseDrivers'),
      isActive: false,
    },
  ];

  return (
    <section className={classNames.contentWrapper}>
      <Text variant="h4" textAlign="center">
        {t('kliksFreeTrialHasEnded')}
      </Text>

      <Text variant="p" size="m" textAlign="center">
        {t('youCanStill')}
      </Text>

      <div className={classNames.featureSection}>
        {ACTIVE_FEATURES.map(feature => (
          <Row gutter={10} align="middle">
            <Col>
              <img
                className={classNames.activeMark}
                alt={feature.isActive ? 'Active' : 'Inactive'}
                src={feature.isActive ? IMAGES.CIRCLE_CHECK_MARK_ICON : IMAGES.CIRCLE_REMOVE_ICON}
              />
            </Col>

            <Col>
              <Text variant="p" size="m" textAlign="center">
                {feature.label}
              </Text>
            </Col>
          </Row>
        ))}
      </div>

      {!hasBillingMethod && (
        <>
          <Text variant="p" size="m" textAlign="center">
            {t('toContinueUsingKliksEnterBilling')}
          </Text>

          <Link
            to={{
              pathname: INTERNAL_LINKS.BILLING_METHOD,
              search: selectedPlan ? `?plan=${selectedPlan}` : '',
            }}
          >
            <Button className={classNames.button}>{t('addBillingInfo')}</Button>
          </Link>
        </>
      )}
    </section>
  );
};

export default FreeTrialExpiredSection;
