import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Text from '../Text';
import classNames from './style.module.scss';

class Tag extends Component {
  render() {
    const { children, variant, disabled, fullWidth, className, size, ...rest } = this.props;

    const textSizePerTagSize = {
      medium: 'sm',
      small: 'xs',
    };

    return (
      <div
        {...rest}
        className={classnames(className, classNames.tag, classNames[variant], classNames[size], {
          [classNames.disabled]: disabled,
          [classNames.fullWidth]: fullWidth,
        })}
      >
        <Text variant="strong" size={textSizePerTagSize[size]} renderAs={'span'}>
          {children}
        </Text>
      </div>
    );
  }
}

Tag.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'dark', 'orange', 'red', 'blue']),
  size: PropTypes.oneOf(['small', 'medium']),
};

Tag.defaultProps = {
  className: '',
  variant: 'secondary',
  fullWidth: false,
  disabled: false,
  size: 'medium',
};

export default Tag;
