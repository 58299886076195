import React, { useState } from 'react';

import { momentFormat } from '../../../utils/common';
import Checkbox from '../../Checkbox';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import CustomTable from '../../Table';

const LocationsTable = props => {
  const { t, dataSource, isDeleteTable, locationsToDelete, onDeleteLocation, ...rest } = props;

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const toggleExpandedRow = row => {
    if (!expandedRowKeys.includes(row.rowNumber)) {
      setExpandedRowKeys([row.rowNumber]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const COLUMNS = [
    ...(isDeleteTable
      ? [
          {
            width: 100,
            title: t('deleteFromKliks'),
            render: (action, data) => (
              <Checkbox
                checked={locationsToDelete.includes(data._id)}
                onChange={() => onDeleteLocation(data._id)}
              />
            ),
          },
        ]
      : []),
    {
      key: 'label',
      dataIndex: 'label',
      title: t('locationName'),
    },
    {
      key: 'street',
      title: t('address'),
      dataIndex: ['address', 'street'],
      render: (street, data) => data.label || street || '--',
    },
    {
      key: 'streetOne',
      dataIndex: ['address', 'streetOne'],
      title: t('streetOne'),
    },
    {
      key: 'streetNumber',
      title: t('streetNumber'),
      dataIndex: ['address', 'number'],
      render: (number, data) => data.streetNumber || number,
    },
    {
      key: 'streetTwo',
      dataIndex: ['address', 'streetTwo'],
      title: t('streetTwo'),
    },
    {
      key: 'city',
      dataIndex: ['address', 'city'],
      title: t('city'),
    },
    {
      key: 'state',
      dataIndex: ['address', 'state'],
      title: t('state'),
    },
    {
      key: 'postalCode',
      dataIndex: ['address', 'postalCode'],
      title: t('postalCode'),
    },
    {
      key: 'country',
      dataIndex: ['address', 'country'],
      title: t('Country'),
    },
    {
      key: 'latitude',
      dataIndex: ['address', 'latitude'],
      title: t('latitude'),
    },
    {
      key: 'longitude',
      dataIndex: ['address', 'longitude'],
      title: t('longitude'),
    },
    {
      key: 'dateAdded',
      dataIndex: 'created',
      title: t('dateAdded'),
      render: date => momentFormat(date, 'MM-DD-YYYY'),
    },
    {
      key: 'addedBy',
      dataIndex: 'userId',
      title: t('addedBy'),
      render: user => (user ? <ProfileNamePicture t={t} user={user} /> : '-'),
    },
  ];

  return (
    <CustomTable
      showSearchInput={false}
      // Table props
      {...rest}
      withExpandableRows
      rowKey={location => location.label}
      scroll={{ x: true }}
      columns={COLUMNS}
      dataSource={dataSource}
      onExpand={(expanded, record) => {
        toggleExpandedRow(record);
      }}
      expandedRowRender={data => {
        return (
          <ul style={{ margin: 0 }}>
            {Array.isArray(data.errors) &&
              data.errors.map(({ param, message }, i) => {
                return (
                  <li style={{ marginTop: i > 0 ? 12 : 0 }}>
                    <p>
                      <b>{t('item')}</b>: {param}
                    </p>
                    <p>
                      <b>{t('providedValue')}</b>: {data?.address?.[param]}
                    </p>
                    <p>
                      <b>{t('error')}</b>: {message}
                    </p>
                  </li>
                );
              })}
          </ul>
        );
      }}
    />
  );
};

LocationsTable.defaultProps = {
  dataSource: [],
  locationsToDelete: [],
};

export default LocationsTable;
