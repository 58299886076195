import { Col, Row } from 'antd';
import React from 'react';

import FormItem from '../../../components/Form/FormItem';
import Select from '../../../components/Select';
import { ERROR_MESSAGE } from '../../../utils/constants';
import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const TripsAndTripsRecordingSettingsFormItems = props => {
  const {
    t,
    authUser,
    companySettings,
    companySettingValues,
    onFeatureChange,
    onSettingValueChange,
    disabled,
  } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('trips&tripsRecording')}>
      <FeatureFormItem
        label={t('importTripsViaCsv ')}
        value={companySettings.importTripsViaCsv}
        onChange={checked => onFeatureChange({ importTripsViaCsv: checked })}
        helpText={t('importTripsViaCsv_SysAdminHelpText')}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('dailyMileageLog')}
        value={companySettings.dailyMileageLog}
        onChange={checked => onFeatureChange({ dailyMileageLog: checked })}
      />

      <FeatureFormItem
        label={t('enforceTripExpirationPolicy')}
        value={companySettings.enforceTripExpirationPolicy}
        onChange={checked => {
          onFeatureChange({ enforceTripExpirationPolicy: checked });
          if (checked && !companySettingValues?.enforceTripExpirationDays) {
            onSettingValueChange({ enforceTripExpirationDays: 30 });
          }
        }}
      />

      {companySettings.enforceTripExpirationPolicy && (
        <Row align="middle" gutter={[16, 16]} wrap={false}>
          <Col flex={1}>
            <FormItem
              required
              className="no-margin-bottom"
              label={t('enforceTripExpirationDays')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <Select
                fullWidth
                name="enforceTripExpirationDays"
                defaultValue={companySettingValues?.enforceTripExpirationDays}
                optionFilterProp="children"
                disabled={disabled}
                options={[
                  { label: '30', value: 30 },
                  { label: '60', value: 60 },
                  { label: '90', value: 90 },
                ]}
                placeholder={t('select')}
                onChange={enforceTripExpirationDays => {
                  onSettingValueChange({ enforceTripExpirationDays });
                }}
              />
            </FormItem>
          </Col>
        </Row>
      )}

      <FeatureFormItem
        label={t('gpsLitterValidation')}
        value={companySettings.gpsLitterValidation}
        onChange={checked => onFeatureChange({ gpsLitterValidation: checked })}
      />

      <FeatureFormItem
        label={t('gpsTripAdjustments')}
        value={companySettings.gpsTripAdjustments}
        onChange={checked => onFeatureChange({ gpsTripAdjustments: checked })}
      />

      <FeatureFormItem
        label={t('mileageCap')}
        value={companySettings.mileageCap}
        onChange={checked => onFeatureChange({ mileageCap: checked })}
      />

      <FeatureFormItem
        label={t('ObdForMileageCapture')}
        value={companySettings.obdDevice}
        onChange={checked => onFeatureChange({ obdDevice: checked })}
        disabled={disabled}
      />

      <FeatureFormItem
        label={t('useRadarForGeocoding')}
        value={companySettings.radarGeocoding}
        onChange={checked =>
          onFeatureChange({
            radarGeocoding: checked,
            gpsLitterValidation: checked ? false : companySettings.gpsLitterValidation,
            gpsTripAdjustments: checked ? false : companySettings.gpsTripAdjustments,
          })
        }
      />
    </SettingSectionWrapper>
  );
};

export default TripsAndTripsRecordingSettingsFormItems;
