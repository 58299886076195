export const validAndroidSettings = {
  battery: {
    wideBatteryOptimisation: false,
  },
  location: {
    locationAccess: true,
    locationPermission: true,
  },
  other: {
    runInBackground: true,
    sendNotifications: true,
  },
};

export const validAppleSettinngs = {
  battery: {
    lowPowerMode: false,
  },
  location: {
    locationService: true,
    preciseLocation: true,
  },
  other: {
    sendNotifications: true,
  },
};
