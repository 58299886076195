import { Col, Row, Slider } from 'antd';
import React, { useState } from 'react';

import Box from '../../../components/Box';
import Select from '../../../components/Select';
import Text from '../../../components/Text';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import classNames from './style.module.scss';

const PriceCalculator = props => {
  const { t, initialNumber, plans, currentPlan } = props;

  const [numUsers, setNumUsers] = useState(initialNumber || 1);
  const [selectedPlan, setSelectedPlan] = useState(
    currentPlan && currentPlan.planId ? currentPlan.planId.pricePlan : 1,
  );

  const FULL_PLAN = plans[selectedPlan - 1];
  const PRICE_PER_USER = FULL_PLAN ? FULL_PLAN.price : plans[0].price;

  return (
    <Box className={classNames.priceCalculatorBox}>
      <Row>
        <Col flex={1}>
          <Text variant="h4">{t('priceCalculator')}</Text>
        </Col>
      </Row>

      <Row justify="space-between" align="middle">
        <Col flex={1}>
          <Select
            style={{ minWidth: 200 }}
            value={selectedPlan}
            onChange={setSelectedPlan}
            options={plans.map(plan => ({
              value: plan.order,
              label: plan.level,
            }))}
          />

          <Text className={classNames.underText}>
            {t('pricePerDriver', { price: formatCurrencyToCompanyLocale(PRICE_PER_USER) })}
          </Text>
        </Col>

        <Col>
          <Text variant="h3" className={classNames.pricePerMonth}>
            {t('pricePerMonth', {
              price: formatCurrencyToCompanyLocale(PRICE_PER_USER * numUsers),
            })}
          </Text>
          <Text textAlign="right" className={classNames.underText}>
            {t('forNumDriver', { count: numUsers })}
          </Text>
        </Col>
      </Row>

      <Row>
        <Col flex={1}>
          <Slider
            included
            defaultValue={numUsers}
            onChange={setNumUsers}
            min={1}
            max={100}
            marks={{
              1: '1',
              20: '25',
              50: '50',
              70: '75',
              100: '100',
            }}
          />
        </Col>
      </Row>
    </Box>
  );
};

export default PriceCalculator;
