import { CaretDownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Layout, Row, Space } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import AppNotificationWrapper from '../../components/AppNotificationWrapper';
import Avatar from '../../components/Avatar';
import LinkText from '../../components/Text/LinkText';
import { EXTERNAL_LINKS, IMAGES, STATUS_LIST } from '../../enum';
import { ReactComponent as HelpSVG } from '../../images/help-regular.svg';
import { logoutUser } from '../../utils/auth';
import { getUserInitials } from '../../utils/common';
import { hasCompanyAdminRole } from '../../utils/roles';
import { selectStoreCurrentAuthUser, selectStoreCurrentCompany } from '../../utils/storeSelectors';
import FreeTrialBanner from '../FreeTrialBanner';
import GlobalSearch from '../GlobalSearch';
import classNames from '../style.module.scss';
import useLayout from '../useLayout';
import NavIcon from './NavIcon';
import ProfileDropdownMenu from './ProfileDropdownMenu';

const LayoutHeader = props => {
  const { t, history, basicHeader } = props;

  const {
    browserStore,
    isInOnboardingPage,
    handleToggleLayoutSidebar,
    handleGoToDashboard,
  } = useLayout({ history });

  const authUser = useSelector(selectStoreCurrentAuthUser);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const { profilePicture } = authUser?.profile || {};

  const MOBILE_MENU_TOGGLER = useMemo(() => {
    return (
      authUser &&
      browserStore.isMobile && (
        <Col onClick={handleToggleLayoutSidebar} style={{ paddingLeft: 0 }}>
          <img width="35px" src={IMAGES.HAMBURGER_MENU_ICON} alt="menu" />
        </Col>
      )
    );
  }, [browserStore.isMobile, authUser, handleToggleLayoutSidebar]);

  if (!authUser && !basicHeader) return null;

  if (basicHeader) {
    return (
      <Layout.Header
        className={`${classNames['site-layout-background']} ${classNames.fixedHeader} d-flex`}
      >
        <Col flex={1}>
          <Row gutter={10} className={classNames.headerContent} align="middle">
            {MOBILE_MENU_TOGGLER}

            <Col
              onClick={handleGoToDashboard}
              justify="flex-start"
              className="top-kliks-logo"
              style={{ display: basicHeader ? 'block' : undefined }}
            >
              <img width="24px" src={IMAGES.KLIKS_LOGO_SIDEBAR_SMALL_DARK} alt="Kliks" />
            </Col>

            <Col flex={1}></Col>

            <Col>
              <LinkText
                rel="noopener noreferrer"
                target="_blank"
                href={EXTERNAL_LINKS.FRESHDESK_SUPPORT}
                renderAs="a"
                title={t('help')}
              >
                <NavIcon Icon={HelpSVG} />
              </LinkText>
            </Col>
          </Row>
        </Col>
      </Layout.Header>
    );
  }

  return (
    <Layout.Header
      className={`${classNames['site-layout-background']} ${classNames.fixedHeader} d-flex`}
    >
      <Col flex={1}>
        <Row gutter={20} className={classNames.headerContent} align="middle">
          {MOBILE_MENU_TOGGLER}

          <Col justify="flex-start" className="top-kliks-logo" onClick={handleGoToDashboard}>
            <img width="24px" src={IMAGES.KLIKS_LOGO_SIDEBAR_SMALL_DARK} alt="Kliks" />
          </Col>

          <Col flex={1}>{!browserStore.isMobile && <GlobalSearch t={t} history={history} />}</Col>

          {currentCompany.status === STATUS_LIST().Status.ACTIVE_TRIAL &&
            currentCompany.freeTrialInfo &&
            hasCompanyAdminRole(authUser) &&
            !isInOnboardingPage && (
              <Col>
                <FreeTrialBanner freeTrial={currentCompany.freeTrialInfo} />
              </Col>
            )}

          {authUser.profile && !isInOnboardingPage && !browserStore.isMobile && (
            <Col>
              <AppNotificationWrapper t={t} user={authUser.profile} />
            </Col>
          )}

          {authUser.profile && (
            <Col>
              <LinkText
                rel="noopener noreferrer"
                target="_blank"
                href={EXTERNAL_LINKS.FRESHDESK_SUPPORT}
                renderAs="a"
                title={t('help')}
              >
                <NavIcon Icon={HelpSVG} />
              </LinkText>
            </Col>
          )}

          <Col>
            <div className={classNames.userAvatarWrapper}>
              <Dropdown
                className={classNames.avatarDropdown}
                menu={ProfileDropdownMenu({
                  t,
                  user: authUser,
                  regCompleted: currentCompany.regCompleted,
                  logoutUser,
                })}
                placement="bottomRight"
                trigger={['click', 'hover']}
                arrow
              >
                <Space direction="horizontal" wrap={false} size="small" align="center">
                  <Avatar
                    size="large"
                    alt="user-img"
                    src={profilePicture}
                    initials={authUser ? getUserInitials(authUser.profile) : ''}
                  />
                  <CaretDownOutlined />
                </Space>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Col>
    </Layout.Header>
  );
};

export default LayoutHeader;
