import React, { useMemo } from 'react';

import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue } from '../../../utils/common';
import { sortColumnByStringField } from '../../../utils/tables';
import { CustomTable } from '../..';

const InvoiceBilledUsersTable = props => {
  const { t, dataSource, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(invoice => {
        return (
          checkIfStringContainsValue(invoice.firstName, searchTerm) ||
          checkIfStringContainsValue(invoice.lastName, searchTerm) ||
          checkIfStringContainsValue(invoice.groupName, searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = useMemo(
    () => [
      {
        title: t('firstName'),
        key: 'firstName',
        dataIndex: 'firstName',
        ...sortColumnByStringField('firstName'),
      },
      {
        title: t('lastName'),
        key: 'lastName',
        dataIndex: 'lastName',
        ...sortColumnByStringField('lastName'),
      },
      {
        title: t('Group'),
        key: 'groupName',
        dataIndex: 'groupName',
        ...sortColumnByStringField('groupName'),
      },
    ],
    // eslint-disable-next-line
    [],
  );

  return (
    <CustomTable
      // Search props
      onSearchTermChange={setSearchTerm}
      // Table props
      rowKey={data => data._id}
      {...rest}
      dataSource={filteredDataSource}
      columns={COLUMNS}
    />
  );
};

export default InvoiceBilledUsersTable;
