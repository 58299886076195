import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class MetabaseAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'metabase' });
  }

  /**
   * Fetch Metabase report URL for iframe
   * @param {string} type
   * @param {object} params
   * @param {string?} params.companyId
   * @param {string?} params.userId
   */
  async fetchMetabaseReportURL(type, params = {}) {
    const cleanParams = cleanQueryParams({ ...params, type });
    const result = await handleApiCalls('get', `${this.baseUrl}/url?${cleanParams}`);
    return result.data;
  }
}

export default MetabaseAPI;
