import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { handleApiErrors } from '../api/axiosInstance';
import { DWOLLA_API, parseDwollaApiErrorMessage } from '../api/dwolla';
import Toast from '../components/Toast';
import { saveCompany } from '../stores/actions/common';
import useDwollaCompanyInfo from './useDwollaCompanyInfo';

const useDwollaBankAccount = (t, callbacks = {}) => {
  const {
    onSaveBankAccountSuccess,
    onDeleteBankAccountSuccess,
    onVerifyBankAccountMicroTransactionSuccess,
  } = callbacks;

  const dispatch = useDispatch();
  const { dwollaPaymentInfo, companyFundingSources } = useDwollaCompanyInfo();
  const [isSavingBankAccount, setIsSavingBankAccount] = useState(false);
  const [isVerifyingMicroTransaction, setIsVerifyingMicroTransaction] = useState(false);
  const [isDeletingBankAccount, setIsDeletingBankAccount] = useState(false);

  const saveUserBankAccount = async values => {
    setIsSavingBankAccount(true);

    try {
      let updatedUser;
      if (values.fundingSourceId) {
        updatedUser = await DWOLLA_API.updateFundingSourceForUser(values);
      } else {
        const response = await DWOLLA_API.addFundingSourceForUser(values);
        updatedUser = response.user;
      }
      Toast({
        type: 'open',
        message: t('bankAccountInformationSavedSuccessfully'),
      });
      if (typeof onSaveBankAccountSuccess === 'function') {
        onSaveBankAccountSuccess(updatedUser);
      }
      setIsSavingBankAccount(false);
      return updatedUser;
    } catch (error) {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('bankAccountInformationSaveError'),
        });
      });
    }

    setIsSavingBankAccount(false);
  };

  const deleteUserBankAccount = async fundingSourceId => {
    setIsDeletingBankAccount(true);

    try {
      const updatedUser = await DWOLLA_API.deleteFundingSourceForUser(fundingSourceId);

      Toast({
        type: 'open',
        message: t('deleteBankAccountSuccess'),
      });

      if (typeof onDeleteBankAccountSuccess === 'function') {
        onDeleteBankAccountSuccess();
      }

      setIsDeletingBankAccount(false);

      return updatedUser;
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('deleteBankAccountError'),
        });
      });
    }

    setIsDeletingBankAccount(false);
  };

  const saveCompanyBankAccount = async values => {
    setIsSavingBankAccount(true);

    try {
      let updatedCompany;
      if (values.fundingSourceId) {
        updatedCompany = await DWOLLA_API.updateFundingSourceForCompany(values);
      } else {
        updatedCompany = await DWOLLA_API.addFundingSourceForCompany(values);
        updatedCompany = await DWOLLA_API.initiateMicroDeposit(
          updatedCompany.fundingSourceDetails.id,
        );
      }
      dispatch(saveCompany({ dwollaPaymentInfo: updatedCompany.dwollaPaymentInfo }));
      Toast({
        type: 'open',
        message: t('bankAccountInformationSavedSuccessfully'),
      });
      if (typeof onSaveBankAccountSuccess === 'function') {
        onSaveBankAccountSuccess();
      }
    } catch (error) {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('bankAccountInformationSaveError'),
        });
      });
    }

    setIsSavingBankAccount(false);
  };

  const deleteCompanyBankAccount = async fundingSourceId => {
    setIsDeletingBankAccount(true);

    try {
      await DWOLLA_API.deleteFundingSourceForCompany(fundingSourceId);
      dispatch(
        saveCompany({
          dwollaPaymentInfo: {
            ...dwollaPaymentInfo,
            controllerInfo: {
              ...dwollaPaymentInfo.controllerInfo,
              fundingSources: companyFundingSources.filter(
                source => source.fundingSourceId !== fundingSourceId,
              ),
            },
          },
        }),
      );
      Toast({
        type: 'open',
        message: t('deleteBankAccountSuccess'),
      });
      if (typeof onDeleteBankAccountSuccess === 'function') {
        onDeleteBankAccountSuccess();
      }
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('deleteBankAccountError'),
        });
      });
    }

    setIsDeletingBankAccount(false);
  };

  const verifyBankAccountMicroTransaction = async values => {
    setIsVerifyingMicroTransaction(true);

    try {
      const updatedCompany = await DWOLLA_API.verifyMicroDeposit(
        values.fundingSourceId,
        values.amount1,
        values.amount2,
      );
      dispatch(saveCompany({ dwollaPaymentInfo: updatedCompany.dwollaPaymentInfo }));
      Toast({
        type: 'open',
        message: t('verifyBankAccountMicroTransactionSuccess'),
      });

      if (typeof onVerifyBankAccountMicroTransactionSuccess === 'function') {
        onVerifyBankAccountMicroTransactionSuccess(updatedCompany);
      }
    } catch (error) {
      parseDwollaApiErrorMessage(error.response, () => {
        Toast({
          type: 'error',
          message: t('verifyBankAccountMicroTransactionError'),
        });
      });
    }

    setIsVerifyingMicroTransaction(false);
  };

  return {
    isSavingBankAccount,
    isDeletingBankAccount,
    isVerifyingMicroTransaction,
    saveUserBankAccount,
    saveCompanyBankAccount,
    deleteUserBankAccount,
    deleteCompanyBankAccount,
    verifyBankAccountMicroTransaction,
  };
};

export default useDwollaBankAccount;
