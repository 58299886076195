import { Col, DatePicker, Form, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';

import FormItem from '../../../../components/Form/FormItem';
import LocationInput from '../../../../components/LocationInput';
import GenericModal from '../../../../components/Modal/GenericModal';
import Select from '../../../../components/Select';
import SubmitCancelButtonGroup from '../../../../components/SubmitCancelButtonGroup';
import TextInput from '../../../../components/TextInput';
import { analyseAddressAutoCompleteString } from '../../../../utils/address';
import {
  DATE_OF_BIRTH_FORM_RULE,
  ERROR_MESSAGE,
  FORMAT_FORM_RULE,
} from '../../../../utils/constants';
import { selectStoreCountryByCode } from '../../../../utils/storeSelectors';

const BeneficialOwnerInformationForm = props => {
  const { t, isUpdate, visible, onCancel, initialValues, isLoading, onSubmit } = props;

  const [form] = Form.useForm();
  const UNITED_STATES = useMemo(() => selectStoreCountryByCode('US'), []);

  const handleStreetOneSelect = address => {
    const analysedString = analyseAddressAutoCompleteString(address);
    const { streetOne, city, state, postalCode } = analysedString;

    if (form) {
      form.setFieldsValue({
        city,
        address1: streetOne,
        stateProvinceRegion: state,
        postalCode,
      });
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (initialValues) form.setFieldsValue(initialValues);
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <GenericModal
      centered
      destroyOnClose
      title={t('beneficialOwnerInformation')}
      visible={visible}
      onCancel={onCancel}
      closable={!isLoading}
      bodyStyle={{ overflowY: 'auto', paddingBottom: 12, paddingTop: 12 }}
      width={700}
      footer={
        <SubmitCancelButtonGroup
          loading={isLoading}
          disabled={isLoading}
          onCancel={onCancel}
          onSubmit={() => form.validateFields().then(onSubmit)}
          submitText={isUpdate ? t('update') : t('add')}
        />
      }
    >
      <Form form={form}>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <FormItem
              required
              name={'firstName'}
              label={t('firstName')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput disabled={isLoading} />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem
              required
              name={'lastName'}
              label={t('lastName')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput disabled={isLoading} />
            </FormItem>
          </Col>
        </Row>

        <FormItem
          required
          name={'dateOfBirth'}
          label={t('dateOfBirth')}
          rules={[
            { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
            ...DATE_OF_BIRTH_FORM_RULE(18),
          ]}
        >
          <DatePicker
            placeholder="YYYY-MM-DD"
            autoComplete="new-password"
            disabled={isLoading}
            style={{ width: '100%' }}
          />
        </FormItem>

        <FormItem
          required
          name={'address1'}
          label={t('streetAddress1')}
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <LocationInput
            disabled={isLoading}
            autoComplete="new-password"
            limitedToCountryCode="US"
            onSelect={handleStreetOneSelect}
          />
        </FormItem>

        <FormItem name={'address2'} label={t('streetAddress2')}>
          <TextInput disabled={isLoading} />
        </FormItem>

        <Row gutter={24}>
          <Col xs={24} md={8}>
            <FormItem
              required
              name={'stateProvinceRegion'}
              label={t('state')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <Select
                fullWidth
                showSearch
                disabled={isLoading}
                optionFilterProp="children"
                options={UNITED_STATES.states}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={8}>
            <FormItem
              required
              name={'city'}
              label={t('city')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput disabled={isLoading} />
            </FormItem>
          </Col>

          <Col xs={24} md={8}>
            <FormItem
              required
              name={'postalCode'}
              label={t('postalCode')}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput disabled={isLoading} />
            </FormItem>
          </Col>
        </Row>

        <FormItem
          required
          style={{ marginBottom: 8 }}
          name={'ssn'}
          label={t('ssnAllDigits')}
          rules={[
            { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
            { type: 'string', min: 9, message: ERROR_MESSAGE().STRING_MIN_LENGTH(9) },
            ...FORMAT_FORM_RULE(/[0-9]{3}-[0-9]{2}-[0-9]{4}/),
          ]}
        >
          <TextInput
            masked
            mask="000-00-0000"
            minLength={4}
            disabled={isLoading}
            placeholder="000-00-0000"
          />
        </FormItem>
      </Form>
    </GenericModal>
  );
};

export default BeneficialOwnerInformationForm;
