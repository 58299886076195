import React from 'react';
import { Trans } from 'react-i18next';

import Toast from '../../../components/Toast';
import i18n from '../../../i18n';

/**
 * Validate address form schema
 *
 * @param {object} addressData - Address data
 * @param {string} addressType - Home address or Office address
 */
export const validateAddress = (addressData = {}, addressType) => {
  let isValid = true;
  const errorTitle = addressType;

  if (!addressData) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: i18n.t('mustFillFormFirst'),
    });
  } else if (!addressData.streetOne) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: <Trans t={i18n.t} i18nKey="address1CannotBeBlank" components={[<b>dummy</b>]} />,
    });
  } else if (!addressData.country) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: <Trans t={i18n.t} i18nKey="countryCannotBeBlank" components={[<b>dummy</b>]} />,
    });
  } else if (!addressData.state) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: <Trans t={i18n.t} i18nKey="stateCannotBeBlank" components={[<b>dummy</b>]} />,
    });
  } else if (!addressData.city) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: <Trans t={i18n.t} i18nKey="cityCannotBeBlank" components={[<b>dummy</b>]} />,
    });
  } else if (!addressData.postalCode) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: <Trans t={i18n.t} i18nKey="zipCodeCannotBeBlank" components={[<b>dummy</b>]} />,
    });
  } else if (
    addressData.streetOne.length > 35 ||
    (addressData.streetTwo && addressData.streetTwo.length > 35)
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: errorTitle,
      description: (
        <Trans t={i18n.t} i18nKey="addressesCannotBeBlank" components={[<b>dummy</b>]} />
      ),
    });
  }

  return isValid;
};

/**
 * Validate the User profile schema
 *
 * @param {object} formData - Profile data
 */
export const validateUserProfile = (formData = {}) => {
  let isValid = true;

  if (!formData.firstName) {
    isValid = false;
    Toast({
      type: 'error',
      message: <Trans t={i18n.t} i18nKey="firstNameCannotBeBlank" components={[<b>dummy</b>]} />,
    });
  } else if (!formData.lastName) {
    isValid = false;
    Toast({
      type: 'error',
      message: <Trans t={i18n.t} i18nKey="lastNameCannotBeBlank" components={[<b>dummy</b>]} />,
    });
  } else if (!formData.email) {
    isValid = false;
    Toast({
      type: 'error',
      message: <Trans t={i18n.t} i18nKey="emailCannotBeBlank" components={[<b>dummy</b>]} />,
    });
  } else if (formData.phone) {
    if (formData.phone.length < 8) {
      isValid = false;
      Toast({
        type: 'error',
        message: <Trans t={i18n.t} i18nKey="phoneCannotBeBlank" components={[<b>dummy</b>]} />,
      });
    }
  }

  return isValid;
};
