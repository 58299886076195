import { USER_ROLES } from '../enum';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

export const getAllSystemAdmins = async () => {
  const queryParams = cleanQueryParams({
    role: USER_ROLES.SYSTEM_ADMIN,
    sort: JSON.stringify({ firstName: 1, lastName: 1 }),
  });
  const url = `${process.env.REACT_APP_HOST_API}user?${queryParams}`;
  const result = await handleApiCalls('get', url);

  return result.data;
};

export const createSystemAdmin = async data => {
  const url = `${process.env.REACT_APP_HOST_API}user/system-admin`;
  const result = await handleApiCalls('post', url, data);

  return result.data;
};

export const updateSystemAdmin = async (userID, data) => {
  const url = `${process.env.REACT_APP_HOST_API}user/${userID}/system-admin`;
  const result = await handleApiCalls('patch', url, data);

  return result.data;
};
