import { Space } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';

import { momentTimezone } from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import { CustomTable } from '../..';
import Button from '../../Button';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';
import ReimbursementsTable from '../ReimbursementsTable';

const AccountReimbursementHistoryByUserTable = props => {
  const { t, dataSource, onRetry, onReimbursementClick, ...rest } = props;

  const [processingIds, setProcessingIds] = useState([]);
  const [expandedRowsKeys, setExpandedRowsKeys] = useState([]);

  const toggleExpandedRow = row => {
    const rowKey = row?._id;
    if (rowKey && !expandedRowsKeys.includes(rowKey)) {
      setExpandedRowsKeys([rowKey]);
    } else {
      setExpandedRowsKeys([]);
    }
  };

  const addProcessingId = idToAdd => {
    setProcessingIds(ids => [...ids, idToAdd]);
  };

  const removeProcessingId = idToRemove => {
    setProcessingIds(ids => ids.filter(id => id !== idToRemove));
  };

  const handleRetry = data => {
    addProcessingId(data._id);
    onRetry(data._id).finally(() => {
      removeProcessingId(data._id);
    });
  };

  const expandableDataSource = useMemo(
    () =>
      dataSource.map(record => ({
        ...record,
        expandable: Array.isArray(record.reimbursements) && !!record.reimbursements.length,
      })),
    [dataSource],
  );

  const COLUMNS = [
    {
      key: 'name',
      title: t('name'),
      dataIndex: 'name',
    },
    {
      key: 'accountNumber',
      title: t('accountNumber'),
      dataIndex: 'accountNumber',
    },
    {
      align: 'right',
      key: 'reimbursementAmount',
      title: t('reimbursementAmount'),
      dataIndex: 'reimbursementAmount',
      render: reimbursementAmount => formatCurrencyToCompanyLocale(reimbursementAmount),
    },
    {
      align: 'right',
      key: 'previousCarryOver',
      title: t('previousUnpaidAmount'),
      dataIndex: 'previousCarryOver',
      render: previousCarryOver => formatCurrencyToCompanyLocale(previousCarryOver),
    },
    {
      align: 'right',
      key: 'Total',
      title: t('Total'),
      dataIndex: 'total',
      render: total => formatCurrencyToCompanyLocale(total),
    },
    {
      key: 'receivedByDwolla',
      title: t('receivedByDwolla'),
      dataIndex: 'massPaymentItemStatus',
      render: massPaymentItemStatus => <StatusTag status={massPaymentItemStatus} />,
    },
    {
      key: 'achStatus',
      title: t('achTransferStatus'),
      dataIndex: 'transferStatus',
      render: transferStatus => <StatusTag status={transferStatus} />,
    },
    {
      key: 'lastAchUpdateTimestamp',
      title: t('achLastUpdated'),
      dataIndex: 'lastAchUpdateTimestamp',
      render: lastAchUpdateTimestamp =>
        momentTimezone(lastAchUpdateTimestamp).format('MM/DD/YYYY, hh:mm A zz'),
    },
    {
      key: 'action',
      dataIndex: 'canRetry',
      render: (canRetry, data) =>
        canRetry && (
          <Button
            size="xs"
            loading={processingIds.includes(data._id)}
            disabled={processingIds.includes(data._id)}
            onClick={() => handleRetry(data)}
          >
            {t('retry')}
          </Button>
        ),
    },
  ];

  return (
    <CustomTable
      {...rest}
      withExpandableRows
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={expandableDataSource}
      expandedRowKeys={expandedRowsKeys}
      onExpand={(expanded, record) => toggleExpandedRow(record)}
      expandedRowRender={data => {
        const reimbursements = get(data, 'reimbursements', []);
        return (
          <Space direction="vertical" style={{ width: 400 }}>
            <Text variant="h5">{t('Reimbursement_plural')}</Text>

            <ReimbursementsTable
              t={t}
              showSearchInput={false}
              scroll={false}
              dataSource={reimbursements.map(rb => ({
                ...rb,
                totalAmount: rb.reimbursementAmount,
              }))}
              pagination={false}
              onReimbursementClick={onReimbursementClick}
              hiddenColumns={[
                'userName',
                'created',
                'groupName',
                'groupManager',
                'paymentStatus',
                'startOfPeriod',
                'endOfPeriod',
                'totalDistance',
              ]}
            />
          </Space>
        );
      }}
    />
  );
};

export default AccountReimbursementHistoryByUserTable;
