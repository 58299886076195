import React, { useMemo } from 'react';

import { setModalFullscreenWidth } from '../../../utils/common';
import ReceiptReimbursementMoreInfoTripsApprovalConfirmation from '../../ReceiptReimbursementMoreInfoTripsApprovalConfirmation';
import ConfirmModal from '../ConfirmModal';

const ReimbursementApproveConfirmation = props => {
  const {
    t,
    reimbursementCount,
    reimbursementWithMoreInfoTrips,
    isApproving,
    visible,
    onConfirm,
    onCancel,
  } = props;

  const reimbursementWithMoreInfoTripsMessage = useMemo(() => {
    if (
      !Array.isArray(reimbursementWithMoreInfoTrips) ||
      (Array.isArray(reimbursementWithMoreInfoTrips) && !reimbursementWithMoreInfoTrips.length)
    ) {
      return null;
    }

    return (
      <ReceiptReimbursementMoreInfoTripsApprovalConfirmation
        t={t}
        type="reimbursement"
        items={reimbursementWithMoreInfoTrips}
      />
    );
    // eslint-disable-next-line
  }, [reimbursementWithMoreInfoTrips]);

  return (
    <ConfirmModal
      width={setModalFullscreenWidth(700)}
      loading={isApproving}
      visible={visible}
      title={t('sureYouWantToApproveReimbursement', { count: reimbursementCount || 1 })}
      message={reimbursementWithMoreInfoTripsMessage}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={(reimbursementCount || 1) > 1 ? t('approveAllReimbursements') : t('yes')}
      cancelText={t('Cancel')}
    />
  );
};

export default ReimbursementApproveConfirmation;
