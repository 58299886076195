import { Space } from 'antd';
import React from 'react';

import { IMAGES } from '../../enum';
import ComplianceItemsList from '../ComplianceItemsList';
import Popover from '../Popover';
import Text from '../Text';

const OutOfComplianceIcon = props => {
  const { t, outOfComplianceComponents } = props;

  return (
    <Popover
      title={t('outOfCompliance')}
      content={
        <Space
          direction="vertical"
          size="middle"
          style={{ maxWidth: 368, maxHeight: 368, overflowY: 'auto', overflowX: 'hidden' }}
        >
          {outOfComplianceComponents.map(component => (
            <Space direction="vertical" size="small">
              <Text size="sm" variant="b">
                {t(component.type)}
              </Text>
              <ComplianceItemsList
                t={t}
                type={component.type}
                complianceComponents={component.complianceComponents}
              />
            </Space>
          ))}
        </Space>
      }
    >
      <img width="24px" heigh="24px" src={IMAGES.OOC_RED_ICON} alt="Out of Compliance" />
    </Popover>
  );
};

OutOfComplianceIcon.defaultProps = {
  OutOfComplianceIcon: [],
};

export default OutOfComplianceIcon;
