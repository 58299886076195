import moment from 'moment-timezone';

import { checkEmailAvailability } from '../api/auth';
import i18n from '../i18n';
import { hasInvalidCharactersOnEmail, isValidPassword } from './validate';

const NAME_MAX_CHARACTER = 30;
const ADDRESS_MAX_CHARACTER = 30;

const ERROR_MESSAGE = () => ({
  NUMBER_BETWEEN: (min, max) => i18n.t('numberBetween', { min, max }),
  MIN_NUMBER: minNumber => i18n.t('minNumber', { minNumber }),
  HIGHER_THAN: minNumber => i18n.t('mustBeHigher', { minNumber }),
  STRING_MIN_LENGTH: count => i18n.t('stringMinLength', { count }),
  STRING_MAX_LENGTH: count => i18n.t('stringMaxLength', { count }),
  CONFIRM_EMAIL: i18n.t('confirmEmail'),
  UPDATE_COMPANY: i18n.t('updateCompany'),
  BLANK_FIELD: i18n.t('blankField'),
  NUMBER_FIELD: i18n.t('numberField'),
  VALID_EMAIL: i18n.t('validEmail'),
  EMAIL_INVALID_CHARACTERS: i18n.t('emailInvalidCharacters'),
  INVALID_PASSWORD: i18n.t('invalidPassword'),
  EMAIL_ALREADY_USED: i18n.t('emailAlreadyUsed'),
  MUST_ACCEPT_TOS: i18n.t('mustAcceptTos'),
  MUST_BE_X_YEARS_OLD: count => i18n.t('mustBeXYearsOld', { count }),
  INVALID_GPS_COORDINATES: i18n.t('invalidGpsCoordinates'),
  ONLY_NUMBERS_ALLOWED: i18n.t('onlyNumbersAllowed'),
  BLOCKED_EMAIL_DOMAIN: i18n.t('blockedEmailDomain'),
  MAX_PASSWORD_LENGTH: i18n.t('maxPasswordLength', { count: NAME_MAX_CHARACTER }),
  PASSWORDS_DONT_MATCH: i18n.t('passwordsDontMatch'),
  TEXT_MATCH_DELETE_COMPANY: i18n.t('textMatchDeleteCompany'),
});

const EMAIL_UPDATE_FORM_RULES = () => [
  {
    validateTrigger: ['onChange', 'onBlur'],
    type: 'email',
    message: ERROR_MESSAGE().VALID_EMAIL,
  },
  {
    validateTrigger: ['onChange', 'onBlur'],
    required: true,
    message: ERROR_MESSAGE().VALID_EMAIL,
  },
];

export const EMPLOYEE_NUMBER_FORM_RULES = () => [
  {
    required: true,
    message: ERROR_MESSAGE().BLANK_FIELD,
  },
  {
    validateTrigger: 'onSubmit',
    type: 'string',
    min: 4,
    message: ERROR_MESSAGE().STRING_MIN_LENGTH(4),
  },
  {
    validateTrigger: 'onSubmit',
    type: 'string',
    max: 255,
    message: ERROR_MESSAGE().STRING_MAX_LENGTH(255),
  },
];

const EMAIL_VALIDATION_FORM_RULES = () => [
  ...EMAIL_UPDATE_FORM_RULES(),
  {
    validateTrigger: 'onSubmit',
    validator: async (rules, email = '', callback) => {
      if (!email) return;
      try {
        if (
          /@{2,}/.test(email) ||
          // eslint-disable-next-line no-useless-escape
          hasInvalidCharactersOnEmail(email.split('@')[0])
        ) {
          return Promise.reject(ERROR_MESSAGE().EMAIL_INVALID_CHARACTERS);
        }

        const isAvailable = await checkEmailAvailability(email);

        if (isAvailable) {
          return Promise.resolve(email);
        } else {
          return Promise.reject(ERROR_MESSAGE().EMAIL_ALREADY_USED);
        }
      } catch (error) {
        return Promise.reject(i18n.t('validateEmailError'));
      }
    },
  },
];

const FORMAT_FORM_RULE = formatRegExp => [
  {
    validateTrigger: ['onBlur', 'onSubmit'],
    validator: (_, value) => {
      if (!value) return Promise.resolve();
      if (!formatRegExp.test(value)) {
        return Promise.reject(i18n.t('invalidFormat'));
      }

      return Promise.resolve();
    },
  },
];

const DATE_OF_BIRTH_FORM_RULE = minAge => [
  {
    validator: (_, value) => {
      if (!value) return Promise.resolve();

      if (moment().diff(value, 'years') < minAge) {
        return Promise.reject(ERROR_MESSAGE().MUST_BE_X_YEARS_OLD(minAge));
      }

      return Promise.resolve();
    },
  },
];

const NEW_PASSWORD_FORM_RULE = () => [
  { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
  () => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();
      if (isValidPassword(value) > 0) {
        return Promise.reject(ERROR_MESSAGE().INVALID_PASSWORD);
      }
      if (value.length > 30) {
        return Promise.reject(ERROR_MESSAGE().MAX_PASSWORD_LENGTH);
      }
      return Promise.resolve();
    },
  }),
];

const CONFIRM_PASSWORD_FORM_RULE = formInstance => [
  { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
  () => ({
    validator(rule, value) {
      if (value !== formInstance.getFieldValue('password')) {
        return Promise.reject(ERROR_MESSAGE().PASSWORDS_DONT_MATCH);
      }
      return Promise.resolve();
    },
    validateTrigger: 'onBlur',
  }),
];

const MIN_NUMBER_FORM_RULE = (num = 0) => [
  () => ({
    validator: (_, value) => {
      if (!value) return Promise.resolve();

      if (value < num) {
        return Promise.reject(ERROR_MESSAGE().MIN_NUMBER(num));
      }

      return Promise.resolve();
    },
  }),
];

export {
  ADDRESS_MAX_CHARACTER,
  CONFIRM_PASSWORD_FORM_RULE,
  DATE_OF_BIRTH_FORM_RULE,
  EMAIL_UPDATE_FORM_RULES,
  EMAIL_VALIDATION_FORM_RULES,
  ERROR_MESSAGE,
  FORMAT_FORM_RULE,
  MIN_NUMBER_FORM_RULE,
  NAME_MAX_CHARACTER,
  NEW_PASSWORD_FORM_RULE,
};
