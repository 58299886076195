import React from 'react';

import { Modal } from '../..';
import PayrollDownloadHistoryUserTable from '../../Table/PayrollDownloadHistoryUserTable';

/**
 * Displays a modal with a table that contains a list of users that have downloaded a speific payroll file
 *
 * @param {object} props Component props
 */
const PayrollDownloadUserModal = props => {
  const { t, title, visible, onCancel, dataSource, loading } = props;

  return (
    <Modal title={title} width={650} visible={visible} footer={null} onCancel={onCancel}>
      <PayrollDownloadHistoryUserTable t={t} loading={loading} dataSource={dataSource} />
    </Modal>
  );
};

export default PayrollDownloadUserModal;
