import React from 'react';

import { hasSystemAdminRole } from '../../../utils/roles';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const UserManagementSettingsFormItems = props => {
  const { t, authUser, companySettings, onFeatureChange } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('User Management')}>
      <FeatureFormItem
        label={t('requireEmployeeID')}
        value={companySettings.requireEmployeeNumber}
        onChange={checked => onFeatureChange({ requireEmployeeNumber: checked })}
        helpText={t('requireEmployeeID_SysAdminHelpText')}
      />
    </SettingSectionWrapper>
  );
};

export default UserManagementSettingsFormItems;
