import { INTERNAL_LINKS } from '../enum';
import AccountBalancePage from './account-balance';
import AccountBalanceTransactionsHowItWorksView from './account-transactions-how-it-works';
import AnomaliesCommuteAlertView from './anomalies-commute-alert';
import AnomaliesHighManualMilesAlertView from './anomalies-high-manual-miles-alert';
import AnomaliesInsuranceComplianceAlertView from './anomalies-insurance-compliance-alert';
import AnomaliesMileageAdjustmentsAlertView from './anomalies-mileage-adjustments-alert';
import AnomaliesReceiptsOverAverageAlertView from './anomalies-receipts-over-average-alert';
import AnomaliesTripsOverAverageAlertView from './anomalies-trips-over-average-alert';
import BillingMethod from './billing/billing-method';
import Company from './company';
import CompanyTaxReportsView from './company-tax-reports';
import ConfirmPlanCancellationView from './confirm-plan-cancellation';
import CSVInvite from './csvinvite';
import DriverEmissionsView from './driver-emissions';
import DwollaMassPaymentDetailsView from './dwolla-mass-payment-details';
import GDPRPage from './gdpr';
import AnonymizeUserDataPage from './gdpr/AnonymizeUserDataPage';
import DownloadUserDataPage from './gdpr/DownloadUserDataPage';
import GroupManagement from './groupmanagement';
import GroupDetails from './groupmanagement/groupdetails';
import InvoiceBilledUserView from './invoice-billed-users';
import InvoicesView from './invoices';
import CompanyLocations from './locations';
import CompanyLocationsDetails from './locations-details';
import ManageAccountBalanceView from './manage-account-balance';
import CompanyOnboarding from './onboarding/accountsetup/CompanyOnboarding';
import CompanyInfo from './onboarding/companyinfo';
import InviteUsers from './onboarding/inviteusers';
import SelectPlanTrial from './onboarding/plan-trial';
import OnboardingRate from './onboarding/rates';
import PaymentScheduleDetails from './payment-schedule-details';
import PaymentSchedulesView from './payment-schedule-lists';
import PayrollFileHistory from './payrollfilehistory';
import PricePlans from './price-plans';
import Products from './productsettings/products';
import CreateRateView from './rate-create';
import RateDetailsView from './rate-details';
import RestorePlanView from './restore-plan';
import UserMobileAppSettingsReportView from './user-mobile-app-settings-report';
import UserProgramComplianceView from './user-program-compliance';
import UserSettingsView from './user-settings';
import UserTaxReportsView from './user-tax-report';
import UserManager from './usermanager';

const CompanyAdminViews = [
  {
    exact: true,
    path: `${INTERNAL_LINKS.COMPANY_ONBOARDING}${INTERNAL_LINKS.PLAN_TRIAL}`,
    component: SelectPlanTrial,
  },
  {
    exact: true,
    path: `${INTERNAL_LINKS.COMPANY_ONBOARDING}${INTERNAL_LINKS.COMPANY_INFO}`,
    component: CompanyInfo,
  },
  {
    exact: true,
    path: `${INTERNAL_LINKS.COMPANY_ONBOARDING}${INTERNAL_LINKS.REIMBURSEMENT_RATE}`,
    component: OnboardingRate,
  },
  { exact: true, path: INTERNAL_LINKS.INVITE_USERS, component: InviteUsers },
  {
    exact: true,
    path: `${INTERNAL_LINKS.COMPANY_ONBOARDING}${INTERNAL_LINKS.INVITE_USERS}`,
    component: InviteUsers,
  },
  { exact: true, path: INTERNAL_LINKS.COMPANY_ONBOARDING, component: CompanyOnboarding },
  { path: INTERNAL_LINKS.CREATE_RATE, component: CreateRateView },
  { path: INTERNAL_LINKS.RATES, component: Products },
  { path: `${INTERNAL_LINKS.RATE_DETAILS}/:id`, component: RateDetailsView },
  { path: INTERNAL_LINKS.USER_MANAGER, component: UserManager },
  { path: INTERNAL_LINKS.PAYMENT_SCHEDULES, component: PaymentSchedulesView },
  { path: `${INTERNAL_LINKS.PAYMENT_SCHEDULE_SETUP}/:id?`, component: PaymentScheduleDetails },
  { exact: true, path: INTERNAL_LINKS.INVOICE_BILLED_USERS(), component: InvoiceBilledUserView },
  { exact: true, path: INTERNAL_LINKS.INVOICES, component: InvoicesView },
  { path: INTERNAL_LINKS.BILLING_METHOD, component: BillingMethod },
  { exact: true, path: INTERNAL_LINKS.GDPR, component: GDPRPage },
  { exact: true, path: INTERNAL_LINKS.DOWNLOAD_USER_DATA, component: DownloadUserDataPage },
  { path: INTERNAL_LINKS.COMPANY, component: Company },
  { path: INTERNAL_LINKS.BULK_INVITE, component: CSVInvite },
  { path: INTERNAL_LINKS.LOCATIONS, component: CompanyLocations },
  { path: `${INTERNAL_LINKS.LOCATIONS_DETAILS}/:id`, component: CompanyLocationsDetails },
  { exact: true, path: INTERNAL_LINKS.ANONYMIZE_USER_DATA, component: AnonymizeUserDataPage },
  { path: INTERNAL_LINKS.CONFIRM_PLAN_CANCELLATION, component: ConfirmPlanCancellationView },
  { path: INTERNAL_LINKS.RESTORE_PLAN, component: RestorePlanView },
  { path: INTERNAL_LINKS.PRICE_PLANS, component: PricePlans },
  { path: INTERNAL_LINKS.GROUP_MANAGEMENT, component: GroupManagement },
  { path: `${INTERNAL_LINKS.GROUP}/:id`, component: GroupDetails },
  { exact: true, path: `${INTERNAL_LINKS.GROUP}`, component: GroupDetails },
  { path: INTERNAL_LINKS.PAYROLL_FILE_HISTORY, component: PayrollFileHistory },
  { exact: true, path: `${INTERNAL_LINKS.ACCOUNT_BALANCE}`, component: AccountBalancePage },
  {
    exact: true,
    path: `${INTERNAL_LINKS.MANAGE_ACCOUNT_BALANCE}`,
    component: ManageAccountBalanceView,
  },
  {
    exact: true,
    path: INTERNAL_LINKS.DWOLLA_MASS_PAYMENT_DETAILS(),
    component: DwollaMassPaymentDetailsView,
  },
  {
    exact: true,
    path: `${INTERNAL_LINKS.ACH_TRANSFERS_HOW_IT_WORKS}`,
    component: AccountBalanceTransactionsHowItWorksView,
  },
  { path: INTERNAL_LINKS.USER_PROGRAM_COMPLIANCE, component: UserProgramComplianceView },
  { path: INTERNAL_LINKS.ANOMALIES_COMMUTE_ALERT, component: AnomaliesCommuteAlertView },
  {
    path: INTERNAL_LINKS.ANOMALIES_TRIPS_OVER_AVERAGE_ALERT,
    component: AnomaliesTripsOverAverageAlertView,
  },
  {
    path: INTERNAL_LINKS.ANOMALIES_RECEIPTS_OVER_AVERAGE_ALERT,
    component: AnomaliesReceiptsOverAverageAlertView,
  },
  {
    path: INTERNAL_LINKS.ANOMALIES_HIGH_MANUAL_MILES_ALERT,
    component: AnomaliesHighManualMilesAlertView,
  },
  {
    path: INTERNAL_LINKS.ANOMALIES_INSURANCE_COMPLIANCE_ALERT,
    component: AnomaliesInsuranceComplianceAlertView,
  },
  {
    path: INTERNAL_LINKS.ANOMALIES_MILEAGE_ADJUSTMENTS_ALERT,
    component: AnomaliesMileageAdjustmentsAlertView,
  },
  {
    path: INTERNAL_LINKS.COMPANY_TAX_REPORT,
    component: CompanyTaxReportsView,
  },
  {
    path: INTERNAL_LINKS.USER_TAX_REPORT(),
    component: UserTaxReportsView,
  },
  {
    path: INTERNAL_LINKS.DRIVERS_EGS_EMISSIONS,
    component: DriverEmissionsView,
  },
  {
    path: INTERNAL_LINKS.USER_MOBILE_APP_SETTINGS_REPORT,
    component: UserMobileAppSettingsReportView,
  },
  {
    path: INTERNAL_LINKS.USER_SETTINGS,
    component: UserSettingsView,
  },
];

export default CompanyAdminViews;
