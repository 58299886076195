import { get, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import { fetchUserActivityInsights } from '../../../api/user';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { getCenterCoordinates } from '../../../utils/map';

const USER_PING_TYPE = 'user';
const EVENT_PING_TYPE = 'event';

const DEFAULT_COORDINATES = [-122.431297, 37.773972];

const NUMBER_OF_USER_INSIGHTS_PER_PAGE = 25;

const useKliksInsightsBoxState = ({ selectedGroup }) => {
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);

  const [userInsights, setUserInsights] = useState([]);
  const [eventInsights, setEventInsights] = useState([]);

  const [visibleUserPings, setVisibleUserPings] = useState([]);
  const [visibleEventPings, setVisibleEventPings] = useState([]);

  const [filterFormValues, setFilterFormValues] = useState({
    date: moment().toISOString(true),
    groupId: selectedGroup,
  });

  const [mapCenter, setMapCenter] = useState(DEFAULT_COORDINATES); // [long, lat] San Francisco CA, default
  const [mapZoom, setMapZoom] = useState();

  const _togglePing = (pingName, pingID) => {
    const pingIdGroupStateSetter =
      pingName === USER_PING_TYPE ? setVisibleUserPings : setVisibleEventPings;

    pingIdGroupStateSetter(state => {
      // replace all if array
      if (Array.isArray(pingID)) {
        if (state.length === pingID.length) {
          return [];
        } else {
          return pingID;
        }
      } else {
        // replace singles
        if (state.includes(pingID)) {
          // Remove
          const updatedPingIdGroup = state.filter(id => id !== pingID);
          return updatedPingIdGroup;
        } else {
          // Add
          return [...state, pingID];
        }
      }
    });
  };

  const handleCollapsedSidebar = collapsed => setIsSiderCollapsed(collapsed);

  const handleChangeMapCenter = useCallback((newCenter, newZoom) => {
    if (newZoom) {
      setMapZoom(newZoom);
    } else {
      setMapZoom();
    }
    setMapCenter(newCenter);
  }, []);

  const toggleUserPingVisiblity = userID => {
    _togglePing(USER_PING_TYPE, userID);
  };

  const toggleEventPingVisiblity = eventID => {
    _togglePing(EVENT_PING_TYPE, eventID);
  };

  const handleFilterFormValuesChange = useCallback((values = {}) => {
    setUserInsights([]);
    setEventInsights([]);
    setFilterFormValues(state => ({ ...state, ...values }));
  }, []);

  const kliksInsightsQuery = useInfiniteQuery({
    cacheTime: 0,
    placeholderData: { pages: [] },
    queryKey: ['fetchUserActivityInsights', ...Object.values(filterFormValues)],
    queryFn: ({ pageParam = 1 }) =>
      fetchUserActivityInsights(filterFormValues, pageParam, NUMBER_OF_USER_INSIGHTS_PER_PAGE),
    onError: error => handleApiErrors(error.response),
    getNextPageParam: (lastPage, pages) => {
      const { totalCount = 0 } = lastPage || {};
      const totalPages = Math.ceil(totalCount / NUMBER_OF_USER_INSIGHTS_PER_PAGE);
      if (pages.length < totalPages) return pages.length + 1;
    },
  });

  useDidUpdateEffect(() => {
    let users = [];
    let events = [];

    if (Array.isArray(kliksInsightsQuery?.data?.pages)) {
      kliksInsightsQuery.data.pages.forEach(data => {
        users = users.concat(data.documents || []);
        events = events.concat(data.events || []);
      });
    }

    setUserInsights(users);
    setEventInsights(events);
  }, [kliksInsightsQuery?.data?.pages]);

  useDidUpdateEffect(() => {
    const coordinates = [];
    const selectedUsers = [];
    userInsights.forEach((user, index) => {
      const lng = get(user, 'lastLocation.longitude', get(user, 'lastLocation.long'));
      const lat = get(user, 'lastLocation.latitude', get(user, 'lastLocation.lat'));

      if (user.lastLocation && !isUndefined(lng) && !isUndefined(lat)) {
        selectedUsers.push(user._id);

        if (index < NUMBER_OF_USER_INSIGHTS_PER_PAGE || !coordinates.length) {
          coordinates.push([lng, lat]);
        }
      }
    });
    setVisibleUserPings(selectedUsers);

    if (Array.isArray(eventInsights)) {
      const selectedEvents = [];
      eventInsights.forEach((event, index) => {
        if (event.locationCoordinates) {
          selectedEvents.push(event._id);

          if (index < NUMBER_OF_USER_INSIGHTS_PER_PAGE || !coordinates.length) {
            coordinates.push([
              event.locationCoordinates.longitude,
              event.locationCoordinates.latitude,
            ]);
          }
        }
      });
      setVisibleEventPings(selectedEvents);
    }
    if (coordinates.length) {
      const centerCoordinates = getCenterCoordinates(coordinates);
      setMapCenter(centerCoordinates.center);
    } else {
      setMapCenter(DEFAULT_COORDINATES);
    }
  }, [userInsights, eventInsights]);

  useDidUpdateEffect(() => {
    handleFilterFormValuesChange({ groupId: selectedGroup });
  }, [selectedGroup, handleFilterFormValuesChange]);

  return {
    // Booleans
    kliksInsightsQuery,
    isSiderCollapsed,
    // General
    userInsights,
    eventInsights,
    visibleUserPings,
    visibleEventPings,
    filterFormValues,
    mapCenter,
    mapZoom,
    // Handlers & Togglers
    handleCollapsedSidebar,
    toggleUserPingVisiblity,
    toggleEventPingVisiblity,
    handleFilterFormValuesChange,
    handleChangeMapCenter,
  };
};

export default useKliksInsightsBoxState;
