import React from 'react';
import { Helmet } from 'react-helmet';

import { COMPANY_API } from '../../api/company';
import CompanyUsersMobileAppSettings from '../../components/Table/CompanyUsersMobileAppSettings';
import PageContainer from '../../containers/PageContainer';
import withPageState from '../../hocs/withPageState';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import { formatPageTitle } from '../../utils/common';
import { getMinutesAsMilliseconds } from '../../utils/numbers';
import { selectStoreCurrentCompany, useStoreSelector } from '../../utils/storeSelectors';

const UserMobileAppSettingsReportView = props => {
  const { t } = props;

  const currentCompany = useStoreSelector(selectStoreCurrentCompany);

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    query: companyUsersMobileAppSettingsQuery,
  } = usePaginatedFiltersQuery({
    staleTime: getMinutesAsMilliseconds(5),
    queryKey: ['fetchCompanyUsersMobileAppSettings', currentCompany._id],
    placeholderData: { documents: [], totalCount: 0 },
    queryFn: () =>
      COMPANY_API.fetchCompanyUsersMobileAppSettings(
        currentCompany._id,
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
  });

  return (
    <PageContainer title={`${t('automaticTripTracking')} - ${t('deviceSettings')}`}>
      <Helmet>
        <title>{formatPageTitle('Automatic Trip Tracking - Device Settings')}</title>
      </Helmet>

      <div>
        <CompanyUsersMobileAppSettings
          t={t}
          loading={companyUsersMobileAppSettingsQuery.isFetching}
          dataSource={companyUsersMobileAppSettingsQuery.data.documents}
          onChange={({ current }) => handlePageChange(current)}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
        />
      </div>
    </PageContainer>
  );
};

export default withPageState(UserMobileAppSettingsReportView);
