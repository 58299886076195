import React, { useMemo } from 'react';

import ReceiptReimbursementMoreInfoTripsApprovalConfirmation from '../../ReceiptReimbursementMoreInfoTripsApprovalConfirmation';
import ConfirmModal from '../ConfirmModal';

const ReceiptApproveConfirmation = props => {
  const {
    t,
    receiptCount,
    receiptsWithMoreInfoTrips,
    isApproving,
    visible,
    onConfirm,
    onCancel,
  } = props;

  const receiptsWithMoreInfoTripsMessage = useMemo(() => {
    if (
      !Array.isArray(receiptsWithMoreInfoTrips) ||
      (Array.isArray(receiptsWithMoreInfoTrips) && !receiptsWithMoreInfoTrips.length)
    ) {
      return null;
    }

    return (
      <ReceiptReimbursementMoreInfoTripsApprovalConfirmation
        t={t}
        type="receipt"
        items={receiptsWithMoreInfoTrips}
      />
    );
    // eslint-disable-next-line
  }, [receiptsWithMoreInfoTrips]);

  return (
    <ConfirmModal
      loading={isApproving}
      visible={visible}
      title={t('sureYouWantToApproveReceipt', { count: receiptCount || 1 })}
      message={receiptsWithMoreInfoTripsMessage}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={(receiptCount || 1) > 1 ? t('approveAllReceipts') : t('yes')}
      cancelText={t('Cancel')}
    />
  );
};

export default ReceiptApproveConfirmation;
