import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { emitFetchInvoices } from '../stores/actions/invoices';
import { momentTimezone } from '../utils/common';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';

const useInvoices = () => {
  const dispatch = useDispatch();
  const { listByYear, isLoadingInvoices } = useSelector(store => store.invoices);

  const [selectedYear, setSelectedYear] = useState(momentTimezone());

  const currentCompany = useSelector(selectStoreCurrentCompany);

  const handleYearChange = useCallback(year => {
    setSelectedYear(year);
  }, []);

  const currentYearInvoicesList = listByYear[selectedYear.year()];

  const invoices = useMemo(() => {
    if (Array.isArray(currentYearInvoicesList)) {
      return currentYearInvoicesList;
    }

    return [];
  }, [currentYearInvoicesList]);

  const loadInvoices = useCallback(
    year => {
      dispatch(emitFetchInvoices(currentCompany._id, year));
    },
    // eslint-disable-next-line
    [currentCompany],
  );

  useEffect(() => {
    loadInvoices(selectedYear.year());
    // eslint-disable-next-line
  }, [selectedYear]);

  return {
    isLoadingInvoices,

    invoices,
    selectedYear,

    loadInvoices,
    handleYearChange,
  };
};

export default useInvoices;
