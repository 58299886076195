import { handleApiCalls, performApiCallIfCompanySubIsActiveOrCompanyAdmin } from './axiosInstance';

export const fetchRateDetails = async id => {
  const url = `${process.env.REACT_APP_HOST_API}products/${id}`;
  const response = await handleApiCalls('get', url);
  return response.data.data;
};

export const createRate = async values => {
  const url = `${process.env.REACT_APP_HOST_API}products`;
  const response = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('post', url, values);
  return response.data;
};

export const updateRate = async (id, values) => {
  const url = `${process.env.REACT_APP_HOST_API}products/${id}`;
  const response = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('put', url, values);
  return response.data;
};

/**
 * Performs an API call to update a FAVR rate's settings
 *
 * @param {string} productId
 *
 * @param {object} commonData
 * @param {boolean} commonData.amortizeIncludingWeekends - true/false
 * @param {boolean} commonData.leaveWithholding - true/false
 * @param {string} commonData.vehicleId - id of standard vehicle
 * @param {number} commonData.businessUse - number from 1 to 75
 * @param {number} commonData.annualExpectedBusinessMiles - number greater than 6250
 * @param {number} commonData.retentionYears - number
 * @param {"15-" | "15-99" | "100-499" | "500-1000" | "1000+"} commonData.numberOfDrivers - string
 *
 * @param {object} paymentSchedule
 * @param {boolean} paymentSchedule.fixedAdvancePayment - true/false
 * @param { 1 } paymentSchedule.fixedPaymentFrequency - 1
 * @param {1 | 2 | 3 | 4} paymentSchedule.variablePaymentFrequency - [1, 2, 3, 4]
 * @param {boolean} paymentSchedule.withholdFixedPayment - true/false
 * @param {number} paymentSchedule.withholdFixedPaymentPercentage - number from 1 to 100
 * @param {number} paymentSchedule.withholdFixedPaymentPeriods - number greater than 1
 * @param {boolean} paymentSchedule.withholdVariablePayment - true/false
 * @param {number} paymentSchedule.withholdVariablePaymentPercentage - number from 1 to 100
 * @param {number} paymentSchedule.withholdVariablePaymentPeriods - number greater than 1
 * @param {boolean} paymentSchedule.allowRetroactivePayments - true/false
 * @param {boolean} paymentSchedule.enableProratedLeavePayments - true/false
 * @param {number} paymentSchedule.proratedLeavePaymentsExpiryMonths - number greater than 1, can provide wpaymentSchedule.enableProratedLeavePayments` equals true
 * @param {number} paymentSchedule.mileageCapAmount - number
 * @param {number} paymentSchedule.mileageWarningThresholdAmount - number
 * @param {"daily" | "weekly" | "monthly"} paymentSchedule.mileageCapFrequency - string
 * @param {"daily" | "weekly" | "monthly"} paymentSchedule.mileageWarningThresholdPeriod - string
 *
 * @param {object} reporting
 * @param {"monthly" | "end_of_year"} reporting.taxReporting - string
 * @param {"auto" | "manual"} reporting.reimbursementReportApproval - string
 * @param {"ach" | "ems" | "checks" | "payroll"} reporting.paymentProcessing - string
 * @param {boolean} reporting.employeeEmails - true/false
 * @param {boolean} reporting.employeeNotifications - true/false
 * @param {"reuse" | "keep" | "adjust"} reporting.renewal.rates - string
 * @param {number} reporting.renewal.ratesAdjustmentPercentage - number from 1 to 100, can provide only when `reporting.renewal.rates` equals adjust
 * @param {"request" | "confirm"} data.reporting.renewal.policyCompliance - string
 */
export const updateProductFavrSettings = async (
  productId,
  commonData = {},
  paymentSchedule = {},
  reporting = {},
) => {
  const url = `${process.env.REACT_APP_HOST_API}products/${productId}/favr-settings`;
  const response = await handleApiCalls('put', url, {
    ...commonData,
    //
    amortizeIncludingWeekends:
      typeof commonData.amortizeIncludingWeekends === 'boolean'
        ? commonData.amortizeIncludingWeekends
        : true,
    leaveWithholding:
      typeof commonData.leaveWithholding === 'boolean' ? commonData.leaveWithholding : false,
    //
    paymentSchedule,
    reporting,
  });

  return response.data.favrSettings;
};
