import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { handleApiErrors } from '../../api/axiosInstance';
import { revertPlanCancellation } from '../../api/subscription';
import Button from '../../components/Button';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import { updateCompanyPlan } from '../../stores/actions/common';
import { formatPageTitle } from '../../utils/common';
import classNames from './style.module.scss';

const RestorePlanView = props => {
  const { t, currentPlan, currentCompany, updatePlan, history } = props;

  const [isRestoringPlan, setIsRestoringPlan] = useState(false);

  const handleRevertPlanCancellation = async () => {
    setIsRestoringPlan(true);
    try {
      const updatedPlan = await revertPlanCancellation(currentCompany._id);
      updatePlan(updatedPlan);
      history.push(INTERNAL_LINKS.PRICE_PLANS);
      Toast({
        type: 'sucess',
        message: t('planRestoreSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('planRestoreError'),
        });
      });
    }
    setIsRestoringPlan(false);
  };

  if (!currentPlan || (currentPlan && !(currentPlan.endDate || currentPlan.estimatedEndDate))) {
    return <Redirect to={INTERNAL_LINKS.PRICE_PLANS} />;
  }

  if (!currentPlan.planId) {
    Toast({
      type: 'error',
      message: t('invalidPlan'),
      description: t('subCancellationError'),
    });
    return <Redirect to={INTERNAL_LINKS.PRICE_PLANS} />;
  }

  return (
    <PageContainer title={t('continueUsingKliksSub')}>
      <Helmet>
        <title>{formatPageTitle('Restore Plan')}</title>
      </Helmet>

      <div className={classNames.contentWrapper}>
        <Text className={classNames.paragraph} variant="p" size="medium">
          <Trans
            t={t}
            i18nKey="kliksSubIsSetToCancelOn_date_at_time"
            values={{
              endDate: moment(currentPlan.endDate || currentPlan.estimatedEndDate).format(
                'MMMM Do, YYYY',
              ),
              endTime: moment(currentPlan.endDate || currentPlan.estimatedEndDate).format('h:mm A'),
            }}
            components={[
              <Text variant="strong" size="medium">
                dummy
              </Text>,
              <Text variant="strong" size="medium">
                dummy
              </Text>,
            ]}
          />
        </Text>
        <Text className={classNames.paragraph} variant="p" size="medium">
          <Trans
            t={t}
            i18nKey="confirmIfContinueKliksSub"
            values={{
              planName: currentPlan.planId.type,
            }}
            components={[
              <Text variant="strong" size="medium">
                dummy
              </Text>,
            ]}
          />
        </Text>

        <Row gutter={17} align="middle" justify="center">
          <Col>
            <Button
              disabled={isRestoringPlan}
              loading={isRestoringPlan}
              type="primary"
              text={t('doNotCancelKliksSub')}
              onClick={handleRevertPlanCancellation}
            />
          </Col>

          <Col>
            <Text variant="p" size="medium">
              {t('-- or --')}
            </Text>
          </Col>

          <Col>
            <Link to={isRestoringPlan ? '#' : INTERNAL_LINKS.PRICE_PLANS}>
              <LinkText size="large">{t('pleaseCancelKliksSub')}</LinkText>
            </Link>
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.profile,
    currentPlan: state.common.companyData.companyPlan,
    currentCompany: state.common.currentCompany,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updatePlan: newPlan => dispatch(updateCompanyPlan(newPlan)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces()(withAuthentication(RestorePlanView)));
