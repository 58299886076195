import { Col, Row } from 'antd';
import classnames from 'classnames';
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useDebouncedState from '../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { prependPlusSignIfNoneFound } from '../../utils/common';
import { selectStoreCountryByPhoneCode } from '../../utils/storeSelectors';
import Select from '../Select';
import TextInput from '../TextInput';

const _formatNumber = (code, number) => new AsYouType(code).input(number);

const PhoneTextInput = props => {
  const { formInstance, defaultCountryCode, defaultValue, onChange, disabled, className } = props;
  const { supportedCountries } = useSelector(state => state.common);

  const [countryCode, setCountryCode] = useState(defaultCountryCode);

  const [phoneNumber, setPhoneNumber] = useState();

  const [debouncedPhoneNumber, setDebouncedPhoneNumber] = useDebouncedState(phoneNumber);

  useEffect(() => {
    if (defaultValue) {
      const parsedPhone = parsePhoneNumber(prependPlusSignIfNoneFound(defaultValue));
      let country = parsedPhone?.country;
      if (!country && parsedPhone?.countryCallingCode) {
        country = selectStoreCountryByPhoneCode(parsedPhone.countryCallingCode)?.code;
      }
      setCountryCode(country);
      setPhoneNumber(parsedPhone?.formatNational());
    }
    // eslint-disable-next-line
  }, []);

  const fullPhoneNumber = useMemo(() => {
    if (!countryCode || !debouncedPhoneNumber) return '';
    const parsedPhone = parsePhoneNumber(debouncedPhoneNumber, countryCode);
    const number = parsedPhone?.number || '';
    return number.replace('+', '');
  }, [countryCode, debouncedPhoneNumber]);

  const handlePhoneChange = useCallback(
    e => {
      const cleanNumber = e.target.value.replace(/\D/g, '');
      const formattedPhone = _formatNumber(countryCode, cleanNumber);
      setPhoneNumber(formattedPhone);
    },
    [countryCode],
  );

  const handleCountryCodeSelect = code => {
    setCountryCode(code);
    if (phoneNumber) {
      const formattedPhone = _formatNumber(code, phoneNumber);
      setPhoneNumber(formattedPhone);
    }
  };

  const countryCodesOptions = useMemo(() => {
    if (!Array.isArray(supportedCountries)) return [];
    return supportedCountries.map(country => ({
      label: `${country.code} (+${country.countryPhoneCode})`,
      value: country.code,
    }));
  }, [supportedCountries]);

  useEffect(() => {
    setDebouncedPhoneNumber(phoneNumber);
    // eslint-disable-next-line
  }, [phoneNumber]);

  useDidUpdateEffect(() => {
    if (formInstance) {
      formInstance.setValues({ phone: fullPhoneNumber });
    }

    if (typeof onChange === 'function') {
      onChange(fullPhoneNumber);
    }
    // eslint-disable-next-line
  }, [fullPhoneNumber]);

  return (
    <Row gutter={5} wrap={false} className={classnames(className)}>
      <Col flex="100px">
        <Select
          value={countryCode}
          disabled={disabled || !supportedCountries.length}
          loading={!countryCodesOptions.length}
          options={countryCodesOptions}
          onChange={handleCountryCodeSelect}
        />
      </Col>
      <Col flex={1}>
        <TextInput
          disabled={disabled || !countryCode}
          value={phoneNumber}
          onChange={handlePhoneChange}
        />
      </Col>
    </Row>
  );
};

export default PhoneTextInput;
