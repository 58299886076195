import { INTERNAL_LINKS } from 'enum';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import { selectStoreCurrentAuthUser } from '../../utils/storeSelectors';

const PublicRoute = props => {
  const history = useHistory();
  const authUser = useSelector(selectStoreCurrentAuthUser);

  useEffect(() => {
    if (authUser) {
      history.push(INTERNAL_LINKS.DASHBOARD);
    }

    // eslint-disable-next-line
  }, []);

  if (authUser) return [];

  return <Route {...props} />;
};

export default PublicRoute;
