import i18n from '../i18n';

export const ADJUSTMENT_TYPES = {
  OVERPAYMENT: 'overpayment',
  UNDERPAYMENT: 'underpayment',
  MISC: 'misc',
};

export const ADJUSTMENT_LABELS = {
  OVERPAYMENT: i18n.t('overpaymentPreviousPayment'),
  UNDERPAYMENT: i18n.t('underpaymentPreviousPayment'),
  MISC: i18n.t('miscAdjustment'),
};

export const ADJUSTMENT_TYPE_OPTIONS = [
  {
    label: ADJUSTMENT_LABELS.OVERPAYMENT,
    value: ADJUSTMENT_TYPES.OVERPAYMENT,
  },
  {
    label: ADJUSTMENT_LABELS.UNDERPAYMENT,
    value: ADJUSTMENT_TYPES.UNDERPAYMENT,
  },
  {
    label: ADJUSTMENT_LABELS.MISC,
    value: ADJUSTMENT_TYPES.MISC,
  },
];
