import moment from 'moment-timezone';

import { cleanQueryParams } from '../utils/queryParams';
import IMAGES from './Images';
import INTERNAL_LINKS from './InternalLinks';
import USER_ROLES from './UserRoles';

const MenuList = [
  {
    key: INTERNAL_LINKS.DASHBOARD,
    title: 'dashboard',
    img: IMAGES.HOME_ICON,
    route: INTERNAL_LINKS.DASHBOARD,
    subnav: [],
    roles: [USER_ROLES.COMPANY_MANAGER, USER_ROLES.COMPANY_ADMIN, USER_ROLES.USER],
  },
  {
    key: INTERNAL_LINKS.TRIPS,
    title: 'Trips',
    img: IMAGES.CAR_RETRO_ICON,
    route: `${INTERNAL_LINKS.TRIPS}?${cleanQueryParams({
      startDateRange: moment().subtract(1, 'month').toISOString(),
      endDateRange: moment().toISOString(),
    })}`,
    subnav: [
      {
        title: 'viewTrips',
        key: INTERNAL_LINKS.TRIPS,
        route: `${INTERNAL_LINKS.TRIPS}?${cleanQueryParams({
          startDateRange: moment().subtract(1, 'month').toISOString(),
          endDateRange: moment().toISOString(),
        })}`,
        companySettings: {
          settingToCheck: 'importTripsViaCsv',
          hideIfFalse: true,
        },
      },
      {
        title: 'manageUploadedTrips',
        key: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
        route: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
        companySettings: {
          settingToCheck: 'importTripsViaCsv',
          hideIfFalse: true,
        },
      },
    ],
    roles: [USER_ROLES.USER, USER_ROLES.COMPANY_MANAGER],
  },
  {
    key: 'manageTrips',
    title: 'Trips',
    img: IMAGES.CAR_RETRO_ICON,
    route: INTERNAL_LINKS.TRIPS,
    subnav: [
      {
        title: 'viewTrips',
        key: INTERNAL_LINKS.TRIPS,
        route: INTERNAL_LINKS.TRIPS,
      },
      {
        title: 'manageUploadedTrips',
        key: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
        route: INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS,
        companySettings: {
          settingToCheck: 'importTripsViaCsv',
          hideIfFalse: true,
        },
      },
    ],
    roles: [USER_ROLES.COMPANY_ADMIN],
  },
  {
    key: 'payments',
    title: 'payments',
    img: IMAGES.MONEY_BAG_ICON,
    roles: [USER_ROLES.USER, USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER],
    subnav: [
      {
        key: INTERNAL_LINKS.RECEIPTS,
        title: 'Receipts',
        route: INTERNAL_LINKS.RECEIPTS,
        companySettings: {
          settingToCheck: 'hideReceipts',
          hideIfTrue: true,
        },
      },
      {
        key: INTERNAL_LINKS.REIMBURSEMENT,
        title: 'Reimbursements',
        route: INTERNAL_LINKS.REIMBURSEMENT,
        companySettings: {
          settingToCheck: 'tripApprovalWorkflow',
          renameIfFalse: 'Tax Vault',
        },
      },
      {
        key: INTERNAL_LINKS.PAYROLL_FILE_HISTORY,
        title: 'payrollFile',
        route: INTERNAL_LINKS.PAYROLL_FILE_HISTORY,
        roles: [USER_ROLES.COMPANY_ADMIN], // only visible in case of company admin
      },
      {
        key: INTERNAL_LINKS.ACCOUNT_BALANCE,
        title: 'accountBalance',
        route: INTERNAL_LINKS.ACCOUNT_BALANCE,
        roles: [USER_ROLES.COMPANY_ADMIN], // only visible in case of company admin
        companySettings: {
          settingToCheck: 'ach_enabled',
          hideIfFalse: true,
        },
      },
    ],
  },
  {
    key: 'user-management',
    title: 'usersAndGroups',
    img: IMAGES.MULTIPLE_USERS_ICON,
    roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER],
    subnav: [
      {
        key: INTERNAL_LINKS.GROUP_MANAGEMENT,
        title: 'manageGroups',
        route: INTERNAL_LINKS.GROUP_MANAGEMENT,
        roles: [USER_ROLES.COMPANY_ADMIN],
      },
      {
        key: INTERNAL_LINKS.USER_MANAGER,
        title: 'manageUsers',
        route: INTERNAL_LINKS.USER_MANAGER,
        roles: [USER_ROLES.COMPANY_ADMIN],
      },
      {
        key: INTERNAL_LINKS.MANAGED_USERS,
        title: 'managedUsers',
        route: INTERNAL_LINKS.MANAGED_USERS,
        roles: [USER_ROLES.COMPANY_MANAGER],
      },
      {
        key: INTERNAL_LINKS.CLIENTS_LIST,
        title: 'manageClients',
        route: INTERNAL_LINKS.CLIENTS_LIST,
        roles: [USER_ROLES.COMPANY_ADMIN],
        companySettings: {
          settingToCheck: 'realtorFeatures',
          hideIfFalse: true,
        },
      },
      {
        key: INTERNAL_LINKS.USER_SETTINGS,
        title: 'userSettings',
        route: INTERNAL_LINKS.USER_SETTINGS,
        roles: [USER_ROLES.COMPANY_ADMIN],
        companySettings: {
          settingToCheck: 'mileageCap',
          hideIfFalse: true,
        },
      },
    ],
  },
  {
    key: 'reports',
    title: 'reports',
    img: IMAGES.REPORT_GRAPH_ICON,
    roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER, USER_ROLES.USER],
    subnav: [
      {
        key: INTERNAL_LINKS.REPORTS,
        title: 'reports',
        route: INTERNAL_LINKS.REPORTS,
        roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER],
      },
      {
        key: INTERNAL_LINKS.COMPANY_TAX_REPORT,
        title: 'taxReport',
        route: INTERNAL_LINKS.COMPANY_TAX_REPORT,
        roles: [USER_ROLES.COMPANY_ADMIN],
      },
      {
        key: INTERNAL_LINKS.DRIVERS_EGS_EMISSIONS,
        title: 'driverEmissions',
        roles: [USER_ROLES.COMPANY_ADMIN],
        route: INTERNAL_LINKS.DRIVERS_EGS_EMISSIONS,
        companySettings: {
          settingToCheck: 'esgScope3',
          hideIfFalse: true,
        },
      },
      {
        key: INTERNAL_LINKS.USER_MOBILE_APP_SETTINGS_REPORT,
        title: 'userMobileAppSettings',
        roles: [USER_ROLES.COMPANY_ADMIN],
        route: INTERNAL_LINKS.USER_MOBILE_APP_SETTINGS_REPORT,
      },
      {
        key: INTERNAL_LINKS.ANOMALIES_DASHBOARD,
        title: 'anomaliesDashboard',
        route: INTERNAL_LINKS.ANOMALIES_DASHBOARD,
        roles: [USER_ROLES.COMPANY_ADMIN, USER_ROLES.COMPANY_MANAGER],
        companySettings: {
          settingToCheck: 'alertsDashboard',
          hideIfFalse: true,
        },
      },
      {
        key: INTERNAL_LINKS.EXPORTS,
        title: 'exports',
        route: INTERNAL_LINKS.EXPORTS,
      },
    ],
  },
  {
    key: INTERNAL_LINKS.SELECT_COMPANY,
    title: 'companies',
    img: IMAGES.BUILDING_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.SELECT_COMPANY,
    subnav: [],
  },
  {
    key: 'sys-admin-rates-menu',
    title: 'Rates',
    img: IMAGES.MONEY_BAG_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    subnav: [
      {
        key: INTERNAL_LINKS.COUNTRY_RATES,
        title: 'countryRates',
        route: INTERNAL_LINKS.COUNTRY_RATES,
      },
      {
        key: INTERNAL_LINKS.FAVR_RATES,
        title: 'favrRates',
        route: INTERNAL_LINKS.FAVR_RATES,
      },
    ],
  },
  {
    key: INTERNAL_LINKS.STANDARD_VEHICLES,
    title: 'standardVehicles',
    img: IMAGES.CAR_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
  },
  {
    key: INTERNAL_LINKS.INSURANCE_COMPANIES,
    title: 'insuranceCompanies',
    img: IMAGES.CAR_INSURANCE_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.INSURANCE_COMPANIES,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.CUSTOM_PLANS,
    title: 'customPlans',
    img: IMAGES.CREDIT_CARD_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.CUSTOM_PLANS,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.COMPLIANCE_WORKLIST,
    title: 'complianceWorklist',
    img: IMAGES.LIST_TO_DO_WHITE_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.COMPLIANCE_WORKLIST,
    subnav: [],
  },
  {
    key: INTERNAL_LINKS.SYSTEM_ADMINS,
    title: 'systemAdmins',
    img: IMAGES.MULTIPLE_USERS_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.SYSTEM_ADMINS,
    subnav: [],
  },
  {
    key: 'sys-admin-reports',
    title: 'reports',
    img: IMAGES.REPORT_GRAPH_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    subnav: [
      {
        key: INTERNAL_LINKS.TRIP_ANOMALIES,
        title: 'tripAnomalies',
        route: INTERNAL_LINKS.TRIP_ANOMALIES,
      },
    ],
  },
];

const BottomSideMenuList = [
  {
    key: 'settings',
    title: 'settings',
    img: IMAGES.SETTINGS_ICON,
    roles: [USER_ROLES.BACK_OFFICE_USER],
    subnav: [
      {
        key: INTERNAL_LINKS.INSURANCE_POLICY_VERIFICATION,
        title: 'insurancePolicyVerification',
        route: INTERNAL_LINKS.INSURANCE_POLICY_VERIFICATION,
      },
      {
        key: INTERNAL_LINKS.VEHICLE_POLICY_VERIFICATION,
        title: 'vehiclePolicyVerification',
        route: INTERNAL_LINKS.VEHICLE_POLICY_VERIFICATION,
      },
    ],
  },
  {
    key: 'services-settings',
    title: 'companySettings',
    img: IMAGES.SETTINGS_ICON,
    roles: [USER_ROLES.COMPANY_ADMIN],
    subnav: [
      {
        key: INTERNAL_LINKS.COMPANY,
        title: 'Company',
        route: INTERNAL_LINKS.COMPANY,
      },
      {
        key: INTERNAL_LINKS.LOCATIONS,
        title: 'locations',
        route: INTERNAL_LINKS.LOCATIONS,
        companySettings: {
          settingToCheck: 'importLocationsMethod',
          hideIfFalse: true,
        },
      },
      {
        key: INTERNAL_LINKS.RATES,
        title: 'Rates',
        route: INTERNAL_LINKS.RATES,
      },
      {
        key: INTERNAL_LINKS.USER_PROGRAM_COMPLIANCE,
        title: 'complianceStatus',
        route: INTERNAL_LINKS.USER_PROGRAM_COMPLIANCE,
        visible: ({ currentCompany }) => {
          return !!currentCompany?.hasFavrProducts;
        },
      },
      {
        key: INTERNAL_LINKS.PAYMENT_SCHEDULES,
        title: 'paymentSchedule',
        route: INTERNAL_LINKS.PAYMENT_SCHEDULES,
      },
      {
        key: 'gdpr',
        title: 'gdpr_ccpa',
        route: INTERNAL_LINKS.GDPR,
      },
    ],
  },
  {
    key: INTERNAL_LINKS.GLOBAL_SETTINGS,
    title: 'globalSettings',
    img: IMAGES.SETTINGS_ICON,
    roles: [USER_ROLES.SYSTEM_ADMIN],
    route: INTERNAL_LINKS.GLOBAL_SETTINGS,
    subnav: [],
  },
];

export default { BottomSideMenuList, MenuList };
