import { Empty } from 'antd';
import React from 'react';

import { getUserFullName, momentCompanyTimezone } from '../../../utils/common';
import {
  renderBooleanCheckmarks,
  sortColumnByBoolean,
  sortColumnByStringField,
} from '../../../utils/tables';
import { CustomTable } from '../..';
import TripPurposeChip from '../../Chip/TripPurposeChip';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import LinkText from '../../Text/LinkText';
import Tooltip from '../../Tooltip';

const TripsCsvTable = props => {
  const { t, dataSource, ...rest } = props;

  const COLUMNS = [
    {
      key: 'employeeEmail',
      title: t('employeeEmail'),
      dataIndex: ['importData', 'employeeEmail'],
      render: email => email || '-',
      sorter: (a, b) => {
        const emailA = a?.importData?.employeeEmail || '-';
        const emailB = b?.importData?.employeeEmail || '-';
        return emailA.localeCompare(emailB);
      },
    },
    {
      key: 'employeeName',
      title: t('employeeName'),
      dataIndex: ['fields', 'userId', 'name'],
      render: (name, data) => {
        if (!name) return '-';

        return <ProfileNamePicture t={t} user={data.fields.userId} />;
      },
      sorter: (a, b) => {
        const joinedNameA = a?.fields?.userId ? getUserFullName(a?.fields?.userId) : '-';
        const joinedNameB = b?.fields?.userId ? getUserFullName(b?.fields?.userId) : '-';
        return joinedNameA.localeCompare(joinedNameB);
      },
    },
    {
      key: 'originAddress',
      title: t('originAddress'),
      dataIndex: ['fields', 'fromAddress', 'formattedAddress'],
      render: (address, data) => {
        if (!address) return '-';

        if (data?.fields?.updateOrCreateOriginCompanyLocation === 'create') {
          return (
            <Tooltip title={data.fields.tripOriginAddress.formattedAddress}>
              <LinkText style={{ minWidth: 150 }}>
                {data.fields.tripOriginCompanyLocationName}
              </LinkText>
            </Tooltip>
          );
        }

        if (data?.fields?.updateOrCreateOriginCompanyLocation === 'update') {
          return (
            <Tooltip title={data.fields.tripOriginAddress.formattedAddress}>
              <LinkText style={{ minWidth: 150 }}>
                {data.fields.tripOriginCompanyLocation.label}
              </LinkText>
            </Tooltip>
          );
        }

        if (data?.fields?.updateOrCreateOriginCompanyLocation === 'match') {
          return (
            <Tooltip title={data.fields.tripOriginCompanyLocation.address.formattedAddress}>
              <LinkText style={{ minWidth: 150 }}>
                {data.fields.tripOriginCompanyLocation.label}
              </LinkText>
            </Tooltip>
          );
        }

        if (data?.fields?.fromAddress?.addressLabel) {
          return (
            <Tooltip title={address}>
              <LinkText style={{ minWidth: 150 }}>{data.fields.fromAddress.addressLabel}</LinkText>
            </Tooltip>
          );
        }

        return <p style={{ minWidth: 150 }}>{address}</p>;
      },
      sorter: (a, b) => {
        return sortColumnByStringField('address').sorter(
          { address: a?.fields?.fromAddress?.formattedAddress || '-' },
          { address: b?.fields?.fromAddress?.formattedAddress || '-' },
        );
      },
    },
    {
      key: 'destinationAddress',
      title: t('destinationAddress'),
      dataIndex: ['fields', 'toAddress', 'formattedAddress'],
      render: (address, data) => {
        if (!address) return '-';

        if (data?.fields?.updateOrCreateDestinationCompanyLocation === 'create') {
          return (
            <Tooltip title={data.fields.tripDestinationAddress.formattedAddress}>
              <LinkText style={{ minWidth: 150 }}>
                {data.fields.tripDestinationCompanyLocationName}
              </LinkText>
            </Tooltip>
          );
        }

        if (data?.fields?.updateOrCreateDestinationCompanyLocation === 'update') {
          return (
            <Tooltip title={data.fields.tripDestinationAddress.formattedAddress}>
              <LinkText style={{ minWidth: 150 }}>
                {data.fields.tripDestinationCompanyLocation.label}
              </LinkText>
            </Tooltip>
          );
        }

        if (data?.fields?.updateOrCreateDestinationCompanyLocation === 'match') {
          return (
            <Tooltip title={data.fields.tripDestinationCompanyLocation.address.formattedAddress}>
              <LinkText style={{ minWidth: 150 }}>
                {data.fields.tripDestinationCompanyLocation.label}
              </LinkText>
            </Tooltip>
          );
        }

        if (data?.fields?.toAddress?.addressLabel) {
          return (
            <Tooltip title={address}>
              <LinkText style={{ minWidth: 150 }}>{data.fields.toAddress.addressLabel}</LinkText>
            </Tooltip>
          );
        }

        return <p style={{ minWidth: 150 }}>{address}</p>;
      },
      sorter: (a, b) => {
        return sortColumnByStringField('address').sorter(
          { address: a?.fields?.toAddress?.formattedAddress || '-' },
          { address: b?.fields?.toAddress?.formattedAddress || '-' },
        );
      },
    },
    {
      width: 100,
      key: 'originDate',
      title: t('originDate'),
      dataIndex: ['fields', 'tripOriginTimestamp'],
      render: (timestamp, data) => (
        <p style={{ minWidth: 100, maxWidth: 'max-content' }}>
          {momentCompanyTimezone(timestamp).format('MM/DD/YYYY zz')}
        </p>
      ),
      sorter: (a, b) => {
        return sortColumnByStringField('timestamp').sorter(
          {
            timestamp: momentCompanyTimezone(a.fields.tripOriginTimestamp).format('MM/DD/YYYY zz'),
          },
          {
            timestamp: momentCompanyTimezone(b.fields.tripOriginTimestamp).format('MM/DD/YYYY zz'),
          },
        );
      },
    },
    {
      width: 140,
      key: 'originTime',
      title: t('originTime'),
      dataIndex: ['fields', 'tripOriginTimestamp'],
      render: (timestamp, data) => {
        return (
          <p style={{ minWidth: 140, maxWidth: 'max-content' }}>
            {momentCompanyTimezone(timestamp).format('hh:mm a')}
          </p>
        );
      },
      sorter: (a, b) => {
        return sortColumnByStringField('timestamp').sorter(
          {
            timestamp: a?.fields?.tripOriginTimestamp
              ? momentCompanyTimezone(a.fields.tripOriginTimestamp).format('hh:mm a')
              : '-',
          },
          {
            timestamp: b?.fields?.tripOriginTimestamp
              ? momentCompanyTimezone(b.fields.tripOriginTimestamp).format('hh:mm a')
              : '-',
          },
        );
      },
    },
    {
      width: 100,
      key: 'destinationDate',
      title: t('destinationDate'),
      dataIndex: ['fields', 'tripDestinationTimestamp'],
      render: (timestamp, data) => (
        <p style={{ minWidth: 100, maxWidth: 'max-content' }}>
          {timestamp ? momentCompanyTimezone(timestamp).format('MM/DD/YYYY zz') : '-'}
        </p>
      ),
      sorter: (a, b) => {
        return sortColumnByStringField('timestamp').sorter(
          {
            timestamp: a?.fields?.tripDestinationTimestamp
              ? momentCompanyTimezone(a.fields.tripDestinationTimestamp).format('MM/DD/YYYY zz')
              : '-',
          },
          {
            timestamp: b?.fields?.tripDestinationTimestamp
              ? momentCompanyTimezone(b.fields.tripDestinationTimestamp).format('MM/DD/YYYY zz')
              : '-',
          },
        );
      },
    },
    {
      width: 140,
      key: 'destinationTime',
      title: t('destinationTime'),
      dataIndex: ['fields', 'tripDestinationTimestamp'],
      render: (timestamp, data) => {
        return (
          <p style={{ minWidth: 140, maxWidth: 'max-content' }}>
            {timestamp ? momentCompanyTimezone(timestamp).format('hh:mm a') : '-'}
          </p>
        );
      },
      sorter: (a, b) => {
        return sortColumnByStringField('timestamp').sorter(
          {
            timestamp: a?.fields?.tripDestinationTimestamp
              ? momentCompanyTimezone(a.fields.tripDestinationTimestamp).format('hh:mm a')
              : '-',
          },
          {
            timestamp: b?.fields?.tripDestinationTimestamp
              ? momentCompanyTimezone(b.fields.tripDestinationTimestamp).format('hh:mm a')
              : '-',
          },
        );
      },
    },
    {
      width: 100,
      key: 'purpose',
      title: t('purpose'),
      dataIndex: ['importData', 'purpose'],
      render: purpose => (purpose ? <TripPurposeChip purpose={purpose.toLowerCase()} /> : '-'),
      sorter: (a, b) => {
        return sortColumnByStringField('purpose').sorter(
          { purpose: a?.importData?.purpose || '-' },
          { purpose: b?.importData?.purpose || '-' },
        );
      },
    },
    {
      key: 'useTollRoads',
      title: t('useTollRoads'),
      dataIndex: ['importData', 'useTollRoads'],
      render: useTollRoads => {
        return renderBooleanCheckmarks('useTollRoads').render({ useTollRoads });
      },
      sorter: (a, b) => {
        return sortColumnByBoolean('useTollRoads').sorter(
          { purpose: a?.importData?.useTollRoads },
          { purpose: b?.importData?.useTollRoads },
        );
      },
    },
    {
      key: 'autoSubmit',
      title: t('autoSubmit'),
      dataIndex: ['importData', 'autoSubmit'],
      render: autoSubmit => {
        return renderBooleanCheckmarks('autoSubmit').render({ autoSubmit });
      },
      sorter: (a, b) => {
        return sortColumnByBoolean('autoSubmit').sorter(
          { purpose: a?.importData?.autoSubmit },
          { purpose: b?.importData?.autoSubmit },
        );
      },
    },
  ];

  return (
    <CustomTable
      {...rest}
      rowKey={data => data._id}
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource}
      scroll={{ x: 970 }}
      locale={{
        emptyText: <Empty description={t('noValidTripsInFile')} />,
      }}
    />
  );
};

export default TripsCsvTable;
