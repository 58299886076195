import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { emitFetchUserVehicles } from '../stores/actions/userVehicles';
import { useStoreSelector } from '../utils/storeSelectors';

const useVehicleCompliance = userId => {
  const dispatch = useDispatch();
  const userVehicles = useStoreSelector(state => state.userVehicles);

  const userActiveVehicles = useMemo(() => {
    let vehicles = [];

    if (
      userVehicles &&
      userVehicles[userId] &&
      Array.isArray(userVehicles[userId].activeVehicles)
    ) {
      vehicles = [...vehicles, ...userVehicles[userId]?.activeVehicles];
    }

    return vehicles;
  }, [userVehicles, userId]);

  const isOutOfCompliance = useMemo(
    () => !!userActiveVehicles.filter(vehicle => vehicle.outOfCompliance).length,
    [userActiveVehicles],
  );

  useEffect(() => {
    dispatch(emitFetchUserVehicles(userId));
    // eslint-disable-next-line
  }, []);

  return isOutOfCompliance;
};

export default useVehicleCompliance;
