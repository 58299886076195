import { Col, Row } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../components';
import Box from '../../../components/Box';
import MetabaseReportsTable from '../../../components/Table/MetabaseReportsTable';
import Text from '../../../components/Text';
import { INTERNAL_LINKS } from '../../../enum';

const MetabaseReportsSection = props => {
  const { t, globalSettings, history } = props;

  const metabaseReportsList = useMemo(() => get(globalSettings, 'metabase.reports', []), [
    globalSettings,
  ]);

  const handleReportEdit = useCallback(
    id => {
      history.push(INTERNAL_LINKS.UPDATE_METABASE_REPORT(id));
    },
    [history],
  );

  return (
    <Row id="metabaseReports">
      <Col flex={1}>
        <Box>
          <Row align="middle" gutter={16}>
            <Col flex={1}>
              <Text variant="h5">{t('metabaseReports')}</Text>
            </Col>
            <Col>
              <Link to={INTERNAL_LINKS.CREATE_METABASE_REPORT}>
                <Button size="xs">{t('createReport')}</Button>
              </Link>
            </Col>
          </Row>
          <br />
          <MetabaseReportsTable
            t={t}
            dataSource={metabaseReportsList}
            onEditClick={handleReportEdit}
          />
        </Box>
      </Col>
    </Row>
  );
};

export default MetabaseReportsSection;
