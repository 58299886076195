import React from 'react';

import SystemAdminForm from '../../SystemAdminForm';
import GenericModal from '../GenericModal';

const CreateSystemAdminModal = props => {
  const { t, visible, isSubmitting, onCancel, onSubmit } = props;

  return (
    <GenericModal
      footer={null}
      title={t('addSystemAdministrator')}
      visible={visible}
      onCancel={onCancel}
    >
      <SystemAdminForm
        t={t}
        isSubmitting={isSubmitting}
        showProfilePicture={false}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </GenericModal>
  );
};

export default CreateSystemAdminModal;
