import { useSelector } from 'react-redux';

import { selectStoreCurrentAuthUser, selectStoreCurrentCompany } from '../../utils/storeSelectors';

const useRealtorDashboard = () => {
  const layoutStore = useSelector(store => store.layout);
  const { supportedCountries } = useSelector(store => store.common);
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  return {
    layoutStore,
    supportedCountries,
    authUser,
    currentCompany,
  };
};

export default useRealtorDashboard;
