export const scrollAppContainerToTop = () => {
  const appContainer =
    document.querySelector('.main-app-container') ||
    document.querySelector('.auth-main-app-container');
  appContainer.scrollTop = 0;
};

export const getEnvironmentName = () => {
  const hostname = window.location.hostname;

  const localhostEnvironmentName = 'local_dev';
  const isLocalhost = hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/);
  if (isLocalhost) return localhostEnvironmentName;

  switch (hostname) {
    case 'dev.kliks.io':
      return 'dev';
    case 'staging.kliks.io':
      return 'staging';
    case 'app.kliks.io':
      return 'prod';
    case '[::1]':
    case 'localhost':
    default:
      return localhostEnvironmentName;
  }
};
