import './style.scss';

import { Layout } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';

import withMobileAppRedirect from '../hocs/withMobileAppRedirect';
import LayoutContent from './LayoutContent';
import Sidebar from './Sidebar';
import classNames from './style.module.scss';
import useLayout from './useLayout';

const KliksLayout = props => {
  const { t, children, history, location, ...rest } = props;

  const { handleDeviceWidthUpdate } = useLayout({ history });

  useEffect(() => {
    window.addEventListener('resize', handleDeviceWidthUpdate);

    return () => {
      window.removeEventListener('resize', handleDeviceWidthUpdate);
    };
  }, [handleDeviceWidthUpdate]);

  return (
    <Layout className={classNames.layout}>
      {/* Sidebar */}
      <Sidebar t={t} history={history} location={location} />

      {/* content */}
      <LayoutContent t={t} history={history} location={location} {...rest}>
        {children}
      </LayoutContent>
    </Layout>
  );
};

export default withNamespaces()(withMobileAppRedirect(KliksLayout));
