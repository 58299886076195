import { connectRouter } from 'connected-react-router';
import { cloneDeep } from 'lodash';
import { combineReducers } from 'redux';

import { RESET_STORES } from '../actions/common';
import { USER_LOGOUT } from '../actions/profile';
import initialState from '../initialState';
import browserReducer from './browser';
import commonReducer from './common';
import favrVehiclesReducer from './favrVehicles';
import groupReducer from './group';
import invoicesReducer from './invoices';
import layoutReducer from './layout';
import pricesReducer from './prices';
import profileReducer from './profile';
import rateWizardReducer from './rateWizard';
import realtorReducer from './realtor';
import systemAdminReducer from './systemAdmin';
import userReducer from './user';
import userVehiclesReducer from './userVehicles';
import vehiclesReducer from './vehicles';

const createRootReducer = history => {
  const defaultState = cloneDeep(initialState);

  const appReducer = combineReducers({
    router: connectRouter(history),
    common: commonReducer,
    layout: layoutReducer,
    group: groupReducer,
    user: userReducer,
    profile: profileReducer,
    prices: pricesReducer,
    browser: browserReducer,
    invoices: invoicesReducer,
    systemAdmin: systemAdminReducer,
    vehicles: vehiclesReducer,
    userVehicles: userVehiclesReducer,
    favrVehicles: favrVehiclesReducer,
    realtor: realtorReducer,
    rateWizard: rateWizardReducer,
  });

  return (state, action) => {
    // state that should never reset
    const { browser, router, common, favrVehicles, vehicles } = state;

    // Clear all data in redux store to initial
    if (action.type === USER_LOGOUT) {
      state = {
        ...cloneDeep(defaultState),
        router,
        browser,
        favrVehicles,
        vehicles,
        common: {
          ...cloneDeep(defaultState.common),
          timezones: common.timezones,
          supportedCountries: common.supportedCountries,
        },
      };
    } else if (action.type === RESET_STORES) {
      // States to keep on reset
      const { profile, systemAdmin, layout } = state;
      state = {
        ...cloneDeep(defaultState),
        browser,
        router,
        layout,
        systemAdmin,
        favrVehicles,
        vehicles,
        common,
        profile: {
          ...cloneDeep(profile),
          vehicles: undefined,
        },
      };
    }

    return appReducer(state, action);
  };
};

export default createRootReducer;
