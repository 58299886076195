import { Col, Row } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HelpIcon } from '../../../components';
import Box from '../../../components/Box';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import { formatNumberWithCurrency, parseTotalAmountsArray } from '../../../utils/numbers';
import { cleanQueryParams } from '../../../utils/queryParams';
import {
  hasCompanyAdminRole,
  hasCompanyManagerOrAdminRole,
  hasUserRole,
} from '../../../utils/roles';
import classNames from '../style.module.scss';

const CardsRowSection = props => {
  const { t, isLoading, data, user, companySettings, timelineFilters } = props;

  const tripsParams = useMemo(() => {
    const params = {
      pathname: INTERNAL_LINKS.TRIPS,
    };

    if (!isLoading) {
      params.search = cleanQueryParams({
        status: STATUS_LIST().Status.PENDING,
        ...(hasUserRole(user)
          ? {
              startDateRange:
                get(data, 'oldestJourneyStartTs') ||
                get(timelineFilters, 'startDate') ||
                get(timelineFilters, 'date30DaysBefore'),
              endDateRange:
                get(timelineFilters, 'endDate') || moment().startOf('day').toISOString(),
            }
          : {}),
        ...(hasCompanyManagerOrAdminRole(user)
          ? {
              group: timelineFilters.groupID,
              startDateRange:
                get(timelineFilters, 'startDate') || get(timelineFilters, 'date30DaysBefore'),
              endDateRange:
                get(timelineFilters, 'endDate') || moment().startOf('day').toISOString(),
            }
          : {}),
      });
    }

    return params;
  }, [isLoading, timelineFilters, data, user]);

  const receiptsParams = useMemo(() => {
    const params = {
      pathname: INTERNAL_LINKS.RECEIPTS,
    };

    if (!isLoading) {
      params.search = cleanQueryParams({
        status: STATUS_LIST().Status.PENDING,
        ...(hasUserRole(user)
          ? {
              startDateRange:
                get(data, 'oldestPendingTripReceiptCreatedDate') ||
                get(timelineFilters, 'startDate') ||
                get(timelineFilters, 'date30DaysBefore'),
              endDateRange:
                get(timelineFilters, 'endDate') || moment().startOf('day').toISOString(),
            }
          : {}),
        ...(hasCompanyManagerOrAdminRole(user)
          ? {
              startDateRange:
                get(timelineFilters, 'startDate') || get(timelineFilters, 'date30DaysBefore'),
              endDateRange:
                get(timelineFilters, 'endDate') || moment().startOf('day').toISOString(),
            }
          : {}),
      });
    }

    return params;
  }, [isLoading, timelineFilters, data, user]);

  const reimbursementParams = useMemo(() => {
    const params = {
      pathname: INTERNAL_LINKS.REIMBURSEMENT,
      search: {
        [companySettings?.settings?.hideReceipts ? 'groupId' : 'group']: get(
          timelineFilters,
          'groupID',
        ),
      },
    };

    let pendingReimbursementParams = params;
    let paidReimbursementParams = params;

    if (!isLoading) {
      const userPeriodParams = hasUserRole(user)
        ? {
            fromDate:
              get(data, 'oldestPendingReimbursementCreatedDate') || get(data, 'date30DaysBefore'),
            toDate: moment().startOf('day').toISOString(),
          }
        : {};
      const adminManagersPeriodParams = hasCompanyManagerOrAdminRole(user)
        ? companySettings?.settings?.hideReceipts
          ? {
              period:
                timelineFilters.startDate && timelineFilters.endDate
                  ? JSON.stringify({
                      fromDate: timelineFilters.startDate,
                      endDate: timelineFilters.endDate,
                    })
                  : null,
            }
          : {
              fromDate: timelineFilters.startDate,
              toDate: timelineFilters.endDate,
            }
        : {};

      pendingReimbursementParams = {
        ...params,
        search: cleanQueryParams({
          ...params.search,
          ...userPeriodParams,
          ...adminManagersPeriodParams,
          status: companySettings?.settings?.hideReceipts
            ? STATUS_LIST().Status.PENDING
            : STATUS_LIST().Status.NOT_PAID,
        }),
      };

      paidReimbursementParams = {
        ...params,
        search: cleanQueryParams({
          ...params.search,
          ...userPeriodParams,
          ...adminManagersPeriodParams,
          status: STATUS_LIST().Status.PAID,
        }),
      };
    }

    return { pendingReimbursementParams, paidReimbursementParams };
  }, [isLoading, timelineFilters, companySettings, data, user]);

  const renderAmountsTotal = (amounts = []) => {
    const parsedTotal = parseTotalAmountsArray(amounts);

    return (
      <Row wrap={false} gutter={[8, 8]} align="middle">
        <Col>
          <Text variant="h2" style={{ margin: 0 }}>
            {formatNumberWithCurrency(parsedTotal.total, parsedTotal.currency)}
          </Text>
        </Col>

        {amounts.length > 1 && (
          <Col>
            <HelpIcon hint={parsedTotal.amounts} />
          </Col>
        )}
      </Row>
    );
  };

  const potentialReimbursementsAmount = useMemo(() => {
    const parsedAmount = parseTotalAmountsArray(data.potentialAmount);
    return formatNumberWithCurrency(parsedAmount.total, parsedAmount.currency);
  }, [data]);

  if (isLoading) {
    return (
      <Row style={{ marginBottom: 30 }} gutter={[30, 30]}>
        <Col xs={24} xl={6}>
          <LoadingSkeleton style={{ height: 200 }} />
        </Col>
        <Col xs={24} xl={6}>
          <LoadingSkeleton style={{ height: 200 }} />
        </Col>
        <Col xs={24} xl={6}>
          <LoadingSkeleton style={{ height: 200 }} />
        </Col>
        <Col xs={24} xl={6}>
          <LoadingSkeleton style={{ height: 200 }} />
        </Col>
      </Row>
    );
  }

  return (
    <Row style={{ marginBottom: 30 }} gutter={[30, 30]}>
      {hasCompanyAdminRole(user) && (
        <Col xs={24} xl={6}>
          <Box className={classNames.dataBox}>
            <div>
              <Text variant="h2" style={{ margin: 0 }}>
                {data.newActiveUsersCount || 0}
              </Text>
              <Text variant="h4">
                <Trans t={t} count={data.newActiveUsersCount || 0} defaults={'New Active User'} />{' '}
              </Text>
            </div>
            <Link to={INTERNAL_LINKS.USER_MANAGER} className={classNames.blockLinks}>
              <LinkText variant="strong" size="medium" renderAs="p">
                {t('viewInviteUsers')}
              </LinkText>
            </Link>
          </Box>
        </Col>
      )}

      <Col xs={24} xl={6}>
        <Box className={classNames.dataBox}>
          <div>
            <Text variant="h2" style={{ margin: 0 }}>
              {data.tripsCount || 0}
            </Text>
            <Text variant="h4">
              <Trans t={t} count={data.tripsCount || 0} defaults={'Trip Recorded'} />{' '}
            </Text>
            <Text>
              {t('potentialReimbursementsAmount', { amount: potentialReimbursementsAmount })}
            </Text>
          </div>
          <Link to={tripsParams} className={classNames.blockLinks}>
            <LinkText variant="strong" size="medium" renderAs="p">
              {t('viewTrips')}
            </LinkText>
          </Link>
        </Box>
      </Col>

      {!companySettings?.settings?.hideReceipts && (
        <Col xs={24} xl={6}>
          <Box className={classNames.dataBox}>
            <div>
              <Text variant="h2" style={{ margin: 0 }}>
                {data.receiptsCount || 0}
              </Text>
              <Text variant="h4">{t('Receipts')}</Text>
            </div>

            <Link to={receiptsParams} className={classNames.blockLinks}>
              <LinkText variant="strong" size="medium" renderAs="p">
                {t('viewReceipts')}
              </LinkText>
            </Link>
          </Box>
        </Col>
      )}

      <Col xs={24} xl={6}>
        <Box className={classNames.dataBox}>
          <div>
            {renderAmountsTotal(data.paidReimbursementsAmount)}
            <Text variant="h4">{t('paidReimbursements')}</Text>
          </div>
          <Link to={reimbursementParams.paidReimbursementParams} className={classNames.blockLinks}>
            <LinkText variant="strong" size="medium" renderAs="p">
              {t('viewPaidRBs')}
            </LinkText>
          </Link>
        </Box>
      </Col>

      <Col xs={24} xl={6}>
        <Box className={classNames.dataBox}>
          <div>
            {renderAmountsTotal(data.pendingReimbursementsAmount)}
            <Text variant="h4">{t('pendingRBs')}</Text>
          </div>
          <Link
            to={reimbursementParams.pendingReimbursementParams}
            className={classNames.blockLinks}
          >
            <LinkText variant="strong" size="medium" renderAs="p">
              {t('viewPendingRBs')}
            </LinkText>
          </Link>
        </Box>
      </Col>
    </Row>
  );
};

CardsRowSection.defaultProps = {
  data: {},
};

export default CardsRowSection;
