import { get } from 'lodash';

import { STATUS_LIST } from '../enum';
import { SCHEDULE_TYPES } from '../enum/ScheduleTypes';
import { momentCompanyFormat } from './common';
import { getMomentDateWithoutTimezone } from './datetime';

/**
 * Get the latest adjustment from a list in a receipt
 *
 * @param {string} receipt Receipt to fetch
 */
export const getLatestAdjustments = receipt => {
  if (Array.isArray(receipt.adjustments) && !!receipt.adjustments.length) {
    const latestAdjustment = receipt.adjustments[Math.max(receipt.adjustments.length - 1, 0)];
    return latestAdjustment;
  }

  return undefined;
};

/**
 * Returns the original amount from a receipt eeven from adjustments
 *
 * @param {string} receipt
 */
export const getReceiptOriginalAmount = receipt => {
  if (Array.isArray(receipt.adjustments) && !!receipt.adjustments.length) {
    return receipt.adjustments[0].previousAmount;
  }

  return receipt.totalAmount;
};

export const findReceiptsWithTripsInMoreInfoStatus = (receipts = [], selectedReceiptsIDs = []) => {
  return receipts.filter(receipt => {
    const baseCondition =
      Array.isArray(receipt.trips) &&
      receipt.trips.filter(trip => trip.journeyStatus === STATUS_LIST().Status.MORE_INFO).length >
        0;

    if (selectedReceiptsIDs.length) {
      return selectedReceiptsIDs.includes(receipt._id) && baseCondition;
    }

    return baseCondition;
  });
};

/**
 * Formats period object into readable label
 * i.e., monthly periods return "MMMM YYYY", any other periods return "MM-DD - MM-DD"
 */
export const formatPaymentPeriodLabel = (
  period,
  startLabel = 'start',
  endLabel = 'end',
  startFormat = 'MM-DD',
  endFormat = 'MM-DD',
  ignoreTimeZone = false,
) => {
  if (!period) return '';

  const monentCallback = ignoreTimeZone
    ? (date, format) => getMomentDateWithoutTimezone(date).format(format)
    : momentCompanyFormat;

  const start = get(period, startLabel);
  const end = get(period, endLabel);
  const formattedPeriodStart = monentCallback(start, startFormat);
  const formattedPeriodEnd = monentCallback(end, endFormat);

  let formattedPeriod = `${formattedPeriodStart} - ${formattedPeriodEnd}`;

  if (new RegExp(SCHEDULE_TYPES.monthly, 'gi').test(period.scheduleType || '')) {
    formattedPeriod = getMomentDateWithoutTimezone(start).format('MMMM YYYY');
  }

  return formattedPeriod;
};
