import { Descriptions, Drawer, Form, Space } from 'antd';
import { get, isNumber } from 'lodash';
import React, { useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import UserSettingsAPI from '../../api/user-settings';
import FormItem from '../../components/Form/FormItem';
import SubmitCancelButtonGroup from '../../components/SubmitCancelButtonGroup';
import UserSettingsTable from '../../components/Table/UserSettingsTable';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import useTableSort from '../../hooks/useTableSort';
import { getUserFullName, setModalFullscreenWidth } from '../../utils/common';
import { ERROR_MESSAGE } from '../../utils/constants';
import { convertDistance } from '../../utils/numbers';
import {
  selectStoreCompanyGroupByID,
  selectStoreCurrentCompany,
  useStoreSelector,
} from '../../utils/storeSelectors';
import { getDistanceUnitStringValue } from '../../utils/trips';

const UserSettingsView = props => {
  const { t } = props;

  const currentCompany = useStoreSelector(selectStoreCurrentCompany);
  const [userSettingToEdit, setUserSettingToEdit] = useState(null);
  const { stringTableSort, handleTableSort } = useTableSort({
    'user.firstName': 1,
    'user.lastName': 1,
  });
  const [countryDistanceUnit, countryDistanceUnitShort] = useMemo(() => {
    const countryCode = get(currentCompany, 'address.country', 'US');
    return [getDistanceUnitStringValue(countryCode, true), getDistanceUnitStringValue(countryCode)];
  }, [currentCompany]);

  const [userGroupName, userGroupMileageCap] = useMemo(() => {
    if (userSettingToEdit) {
      const group = selectStoreCompanyGroupByID(get(userSettingToEdit, 'user.group._id'));
      const mileageCapInMeters = get(group, 'mileageCapInMeters');
      return [get(group, 'name', ''), isNumber(mileageCapInMeters) ? mileageCapInMeters : 0];
    }

    return ['', 0];
  }, [userSettingToEdit]);

  const allUsersSettingsQueryKey = useMemo(
    () => ['fetchAllUsersSettings', currentCompany._id, stringTableSort],
    [currentCompany, stringTableSort],
  );

  const {
    query: allUsersSettingsQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery({
    queryKey: allUsersSettingsQueryKey,
    placeholderData: { documents: [], totalCount: 0 },
    queryFn: () =>
      new UserSettingsAPI().fetchAllUsersSettings(
        currentCompany._id,
        paginationConfig.current,
        paginationConfig.pageSize,
        stringTableSort,
      ),
  });

  const handleUserSettingsUpdateMutation = useMutation(
    values => {
      return new UserSettingsAPI().updateUserSettings(userSettingToEdit._id, {
        ...values,
        mileageCapInMeters: ['', '0', undefined].includes(values.mileageCapInMeters)
          ? null
          : convertDistance(values.mileageCapInMeters, countryDistanceUnit, 'meter'),
      });
    },
    {
      onSuccess: () => {
        Toast({
          type: 'open',
          message: t('updateUserSettingsSuccess_singular'),
        });
        setUserSettingToEdit(null);
        allUsersSettingsQuery.remove();
      },
      onError: error => {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('updateUserSettingsError'),
          });
        });
      },
    },
  );

  return (
    <PageContainer title={t('userSettings')}>
      <UserSettingsTable
        t={t}
        asyncSort
        hiddenColumns={['settings.tripTracking']}
        loading={allUsersSettingsQuery.isFetching}
        dataSource={get(allUsersSettingsQuery, 'data.documents', [])}
        onEditSettings={setUserSettingToEdit}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
          handleTableSort(sorters?.columnKey, sorters?.order);
        }}
      />

      <Drawer
        placement="right"
        open={!!userSettingToEdit}
        onClose={() => setUserSettingToEdit(null)}
        title={
          <Text variant="h5" renderAs="p">
            {`${getUserFullName(get(userSettingToEdit, 'user'))} | ${t('Settings')}`}
          </Text>
        }
        destroyOnClose
        width={setModalFullscreenWidth(500)}
      >
        <Space direction="vertical" size="middle">
          {userGroupName && (
            <Descriptions size="small" column={1}>
              <Descriptions.Item
                label={
                  <Text variant="b" size="sm">
                    {t('userGroup')}
                  </Text>
                }
              >
                <Text size="sm" style={{ display: 'contents' }}>
                  {userGroupName}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text variant="b" size="sm">
                    {t('groupMileageCap')}
                  </Text>
                }
              >
                <Text size="sm" style={{ display: 'contents' }}>
                  {`${convertDistance(
                    userGroupMileageCap,
                    'meter',
                    countryDistanceUnit,
                  )} ${countryDistanceUnitShort}`}
                </Text>
              </Descriptions.Item>
            </Descriptions>
          )}

          <Form
            key={get(userSettingToEdit, '_id')}
            initialValues={{
              ...get(userSettingToEdit, 'settings'),
              mileageCapInMeters: convertDistance(
                get(userSettingToEdit, 'settings.mileageCapInMeters'),
                'meter',
                countryDistanceUnit,
              ),
            }}
            disabled={handleUserSettingsUpdateMutation.isLoading}
            onFinish={handleUserSettingsUpdateMutation.mutateAsync}
          >
            <FormItem
              label={t('mileageCap')}
              name="mileageCapInMeters"
              extra={t('ifSetToZeroSameAsGroupMileageCap')}
              rules={[
                {
                  validateTrigger: 'onSubmit',
                  validator: (_, value) => {
                    if (!value) return Promise.resolve();
                    if (value < 0) return Promise.reject(ERROR_MESSAGE().MIN_NUMBER(0));
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <TextInput
                type="number"
                step="any"
                suffix={getDistanceUnitStringValue(get(currentCompany, 'address.country'))}
              />
            </FormItem>

            <SubmitCancelButtonGroup isForm size="sm" hideCancel submitText={t('update')} />
          </Form>
        </Space>
      </Drawer>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(UserSettingsView));
