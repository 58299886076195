import { push, replace } from 'connected-react-router';
import { put, select, takeLeading } from 'redux-saga/effects';

import { INTERNAL_LINKS } from '../../enum';
import * as actions from '../actions/router';

function* navigateTo(action) {
  const { pathname, useAboslute } = action.data;
  const { basename } = yield select(store => store.common);

  let fullPathname = !useAboslute && basename ? `/${basename}${pathname}` : pathname;

  if (useAboslute && pathname?.includes('profile-your-data')) {
    const user = yield select(store => store.profile);
    fullPathname = `/${basename}${INTERNAL_LINKS.USER_DETAILS_YOUR_DATA(user?.profile?._id)}`;
  }

  yield put(push(fullPathname));
}

function* replaceBasename(action) {
  const { basename } = action.data;

  let fullPathname = [
    `/${basename}`,
    ...window.location.pathname.split('/').filter(Boolean).slice(1),
  ]
    .filter(Boolean)
    .join('/');

  yield put(
    replace({
      pathname: fullPathname,
      hash: window.location.hash,
      search: window.location.search,
    }),
  );
}

export const routerSaga = [
  takeLeading(actions.NAVIGATE_TO, navigateTo),
  takeLeading(actions.UPDATE_BASENAME, replaceBasename),
];
