import { STATUS_LIST } from '.';

export default () => {
  const SelectOptions = [
    {
      label: 'Paid',
      value: STATUS_LIST().Status.PAID,
    },
    {
      label: 'Not Paid',
      value: STATUS_LIST().Status.NOT_PAID,
    },
  ];

  return { SelectOptions };
};
