import { get } from 'lodash';

import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls, handleApiFileDownloads } from './axiosInstance';

export const PERIOD_ANOMALIES_API = {
  /**
   * Get period anomalies
   * @param {object} params
   * @param {"commute-alerts" | "mileage-adjustments"} params.type
   * @param {string} params.paymentScheduleId
   * @param {Date | string} params.paymentPeriodStart
   * @param {Date | string} params.paymentPeriodEnd
   * @param {object} params.sort
   * @param {1 | -1} params.sort.commutePercentage
   * @param {number} pageNumber
   * @param {number} pageSize
   * @returns {{ documents: object[], count: number, totalCount: number }}
   */
  getPeriodAnomalies: async (params, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({
      ...params,
      range,
      sort: JSON.stringify(get(params, 'sort', {})),
    });
    const url = `${process.env.REACT_APP_HOST_API}period-anomalies?${queryParams}`;
    const response = await handleApiCalls('get', url);

    return get(response, 'data');
  },
  /**
   * Export period anomalies
   * @param {object} params
   * @param {string} params.paymentScheduleId
   * @param {Date | string} params.paymentPeriodStart
   * @param {Date | string} params.paymentPeriodEnd
   */
  exportPeriodAnomalies: async params => {
    const queryParams = cleanQueryParams(params);
    const url = `${process.env.REACT_APP_HOST_API}period-anomalies/export?${queryParams}`;
    await handleApiFileDownloads('get', url);
  },
};
