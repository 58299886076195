import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { COUNTRY_RATES_API } from '../../api/countryRates';
import { fetchRateAudit } from '../../api/rateAudits';
import { Button } from '../../components';
import Box from '../../components/Box';
import CountryRateForm from '../../components/Forms/CountryRateForm';
import CountryRateAuditHistoryModal from '../../components/Modal/CountryRateAuditHistoryModal';
import DeleteCountryRateConfirmModal from '../../components/Modal/DeleteCountryRateConfirmModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useModal from '../../hooks/useModal';
import { formatPageTitle } from '../../utils/common';
import { normalizeCountryRatesSchema } from '../../utils/countryRates';

const UpdateCountryRateView = props => {
  const { t, history, match } = props;

  const queryClient = useQueryClient();
  const { supportedCountries } = useSelector(store => store.common);

  const [rateAudits, setRateAudits] = useState([]);
  const [isLoadingRateAudits, setIsLoadingRateAudits] = useState(false);
  const [isRateAuditModalOpen, openRateAuditModal, closeRateAuditModal] = useModal();
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useModal();

  const countryRateDetailsQuery = useQuery({
    enabled: !!match.params.id,
    queryKey: ['fetchCountryRateDetails', match.params.id],
    select: countryRate => normalizeCountryRatesSchema(countryRate),
    queryFn: () => COUNTRY_RATES_API.fetchCountryRateDetails(match.params.id),
  });

  const countryRateUpdateMutation = useMutation(
    data => COUNTRY_RATES_API.updateCountryRate(match.params.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchCountryRates'], { exact: false });
        Toast({
          type: 'open',
          message: t('countryRateUpdateSuccess'),
        });
        history.push(INTERNAL_LINKS.COUNTRY_RATES);
      },
      onError: error => {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('countryRateUpdateError'),
          });
        });
      },
    },
  );

  const countryRateDeleteMutation = useMutation(
    () => COUNTRY_RATES_API.deleteCountryRate(match.params.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchCountryRates'], { exact: false });
        Toast({
          type: 'open',
          message: t('countryRateDeleteSuccess'),
        });
        history.push(INTERNAL_LINKS.COUNTRY_RATES);
      },
      onError: error => {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('countryRateDeleteError'),
          });
        });
      },
    },
  );

  const toggleRateHistoryModal = () => {
    if (!rateAudits.length) {
      loadRateAudits(match.params.id);
    }
    openRateAuditModal();
  };

  const loadRateAudits = async id => {
    setIsLoadingRateAudits(true);

    try {
      const rates = await fetchRateAudit(id);
      setRateAudits(rates);
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsLoadingRateAudits(false);
  };

  return (
    <PageContainer
      loading={!Array.isArray(supportedCountries)}
      sideActionComponent={
        <Button
          size="sm"
          disabled={countryRateDeleteMutation.isLoading}
          loading={countryRateDeleteMutation.isLoading}
          onClick={openDeleteModal}
        >
          {t('deleteRate')}
        </Button>
      }
      title={
        <PageBreadcrumbs
          items={[
            { label: t('countryRates'), onClick: () => history.push(INTERNAL_LINKS.COUNTRY_RATES) },
            ...(countryRateDetailsQuery.data
              ? [
                  {
                    label: (
                      <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                        <Col>{countryRateDetailsQuery.data.name}</Col>

                        <Col>
                          <LinkText onClick={toggleRateHistoryModal}>
                            {t('viewEditHistory')}
                          </LinkText>
                        </Col>
                      </Row>
                    ),
                  },
                ]
              : []),
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('Update Country Rate')}</title>
      </Helmet>

      <Row justify="center">
        <Col flex="832px">
          <Box>
            {countryRateDetailsQuery.isFetching ? (
              <SpaceSpinner />
            ) : (
              <CountryRateForm
                t={t}
                isUpdate
                initialValues={countryRateDetailsQuery.data}
                isSubmitting={
                  countryRateUpdateMutation.isLoading || countryRateDeleteMutation.isLoading
                }
                countries={supportedCountries}
                onSubmit={countryRateUpdateMutation.mutateAsync}
                onCancel={() => history.push(INTERNAL_LINKS.COUNTRY_RATES)}
              />
            )}
          </Box>
        </Col>
      </Row>

      <CountryRateAuditHistoryModal
        t={t}
        loading={isLoadingRateAudits}
        visible={isRateAuditModalOpen}
        dataSource={rateAudits}
        onCancel={closeRateAuditModal}
      />

      <DeleteCountryRateConfirmModal
        t={t}
        visible={isDeleteModalOpen}
        loading={countryRateDeleteMutation.isLoading}
        onConfirm={countryRateDeleteMutation.mutateAsync}
        onCancel={closeDeleteModal}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(UpdateCountryRateView));
