import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
// the hoc
import { withNamespaces } from 'react-i18next';
import { useTranslation } from 'react-i18next/hooks';
import { Button } from 'components';
import GenericModal from '../GenericModal';
import './style.scss';

/**
 * Modal to handle Form contents.
 */
class DataModal extends React.Component {
  render() {
    const { className, title, visible, cancelText, okText, loading, ...rest } = this.props;
    const newClassName = `data-modal ${className}`;

    const [t] = useTranslation();

    return (
      <GenericModal
        {...rest}
        className={newClassName}
        title={<div className="data-modal-title">{title}</div>}
        centered
        visible={visible}
        onOk={this.props.onOk}
        onCancel={this.props.onCancel}
        footer={null}
      >
        <div className="modal-message" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          {this.props.children}
        </div>

        <Row gutter={17} justify="end">
          <Col>
            <Button
              disabled={loading}
              className="data-modal-action"
              text={cancelText || t('Cancel')}
              type="secondary"
              onClick={this.props.onCancel}
            />
          </Col>
          <Col>
            <Button
              disabled={loading}
              loading={loading}
              className="data-modal-action"
              text={okText || t('OK')}
              onClick={this.props.onOk}
            />
          </Col>
        </Row>
      </GenericModal>
    );
  }
}

DataModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  className: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};

DataModal.defaultProps = {
  title: '',
  visible: false,
  className: '',
  okText: '',
  cancelText: '',
  onOk: () => {},
  onCancel: () => {},
};

export default withNamespaces()(DataModal);
