import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import Box from '../../../components/Box';
import SummaryCardColumn from '../../../components/shared-ui/SummaryCardColumn';
import Spinner from '../../../components/Spinner';
import Text from '../../../components/Text';
import { getUserFullName, momentFormat } from '../../../utils/common';

const ImportRequestSummaryCard = props => {
  const { t, importRequest } = props;

  const uniqueUsersCount = useMemo(() => {
    if (importRequest.type === 'trip') {
      const uniqueEmails = [];
      importRequest.validation.forEach(item => {
        if (!uniqueEmails.includes(item.importData.employeeEmail)) {
          uniqueEmails.push(item.importData.employeeEmail);
        }
      });
      return uniqueEmails.length;
    }

    return 0;
  }, [importRequest]);

  if (!importRequest) {
    return (
      <Box>
        <Row justify="center" align="middle">
          <Col>
            <Spinner />
          </Col>
        </Row>
      </Box>
    );
  }

  return (
    <Box>
      <Row gutter={[30, 10]} align="start" justify="start" style={{ width: '100%' }}>
        <Col xs={24} sm={12} md={6}>
          {importRequest.type === 'company-location' && (
            <SummaryCardColumn title={t('totalLocationsInFile')}>
              <Text color="secondary" variant="strong" size="xl" renderAs="div">
                {importRequest.validation.length}
              </Text>
            </SummaryCardColumn>
          )}

          {importRequest.type === 'trip' && (
            <SummaryCardColumn title={`${t('totalTrips')} / ${t('totalUsers')}`}>
              <Text color="secondary" variant="strong" size="xl" renderAs="div">
                {importRequest.validation.length} / {uniqueUsersCount}
              </Text>
            </SummaryCardColumn>
          )}
        </Col>

        <Col xs={24} sm={12} md={6}>
          <SummaryCardColumn title={t('fileUploadedBy')}>
            <Text color="secondary" variant="strong" size="xl" renderAs="div">
              {getUserFullName(importRequest.userId || {})}
            </Text>
          </SummaryCardColumn>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <SummaryCardColumn title={t('fileUpdatedOn')}>
            <Text color="secondary" variant="strong" size="xl" renderAs="div">
              <Trans
                t={t}
                i18nKey="dateAtTime"
                values={{
                  date: momentFormat(importRequest.created, 'LL'),
                  time: momentFormat(importRequest.created, 'hh:mm A'),
                }}
                components={[<span style={{ whiteSpace: 'nowrap' }}>DummyText</span>]}
              />
            </Text>
          </SummaryCardColumn>
        </Col>

        <Col xs={24} sm={12} md={6}>
          <SummaryCardColumn title={t('fileName')}>
            <Text
              color="secondary"
              variant="strong"
              size="xl"
              renderAs="div"
              style={{ wordBreak: 'break-all' }}
            >
              {importRequest.fileName}
            </Text>
          </SummaryCardColumn>
        </Col>
      </Row>
    </Box>
  );
};

export default ImportRequestSummaryCard;
