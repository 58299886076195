import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../api/axiosInstance';
import { fetchTripComments, postTripComment } from '../api/trips';
import useTablePagination from './useTablePagination';

const useTripsComments = ({ onAddTripComment }) => {
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [tripIdToViewComments, setTripIdToViewComments] = useState();

  const {
    paginationConfig: commentsPaginationConfig,
    handleTotalUpdate,
    handlePageChange: handleCommentsPageChange,
  } = useTablePagination({
    initialPageSize: 5,
  });
  const tripCommentsQuery = useQuery({
    enabled: !!tripIdToViewComments,
    queryKey: [
      'fetchTripComments',
      tripIdToViewComments,
      commentsPaginationConfig.current,
      commentsPaginationConfig.pageSize,
    ],
    queryFn: () => {
      return fetchTripComments(
        tripIdToViewComments,
        commentsPaginationConfig.current,
        commentsPaginationConfig.pageSize,
      );
    },
    placeholderData: {
      documents: [],
      totalCount: 0,
    },
    onSuccess: ({ totalCount }) => {
      handleTotalUpdate(totalCount);
    },
    onError: error => {
      handleApiErrors(error.response);
      closeTripCommentsModal();
    },
  });

  const addCommentToTrip = useCallback(
    async comment => {
      setIsAddingComment(true);
      try {
        const newComment = await postTripComment(tripIdToViewComments, comment);

        if (typeof onAddTripComment === 'function') {
          onAddTripComment(tripIdToViewComments, newComment);
        }

        tripCommentsQuery.refetch();
      } catch (error) {
        handleApiErrors(error.response);
      }
      setIsAddingComment(false);
    },
    [tripIdToViewComments, onAddTripComment, tripCommentsQuery],
  );

  const openTripCommentsModal = tripId => setTripIdToViewComments(tripId);

  const closeTripCommentsModal = useCallback(() => setTripIdToViewComments(), []);

  const isTripCommentsModalVisible = useMemo(() => !!tripIdToViewComments, [tripIdToViewComments]);

  return {
    tripCommentsQuery,
    commentsPaginationConfig,
    tripIdToViewComments,
    isAddingComment,
    isTripCommentsModalVisible,
    openTripCommentsModal,
    closeTripCommentsModal,
    addCommentToTrip,
    handleCommentsPageChange,
  };
};

export default useTripsComments;
