import { Form } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';

import useRateForm from '../../hooks/useRateForm';
import { emitUpdateWizardDirty } from '../../stores/actions/rateWizard';
import { ERROR_MESSAGE } from '../../utils/constants';
import { useStoreSelector } from '../../utils/storeSelectors';
import Checkbox from '../Checkbox';
import FormItem from '../Form/FormItem';
import Select from '../Select';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Text from '../Text';

const CPMInsuranceValidation = props => {
  const { t, formInstance, onNextClick, onBackClick } = props;

  const dispatch = useDispatch();
  const rateWizard = useStoreSelector('rateWizard');
  const { handleFormValuesChange } = useRateForm(formInstance);

  const FREQUENCY_OPTIONS = [
    { label: t('annual'), value: 'annual' },
    { label: t('semiAnnual'), value: 'semi-annual' },
  ];

  const GRACE_PERIOD_OPTIONS = [
    { label: 30, value: 30 },
    { label: 60, value: 60 },
    { label: 90, value: 90 },
  ];

  return (
    <Form
      form={formInstance}
      onValuesChange={() => {
        if (!rateWizard.isDirty) {
          dispatch(emitUpdateWizardDirty(true));
        }
      }}
    >
      <section>
        <Text variant="h4">{t('insuranceValidation')}</Text>
        <FormItem name="insuranceValidation" valuePropName="checked">
          <Checkbox
            disabled={rateWizard.isSubmitting}
            onChange={e =>
              handleFormValuesChange({
                insuranceValidation: e.target.checked,
                insuranceComplianceCheckFrequency: e.target.checked
                  ? FREQUENCY_OPTIONS[0].value
                  : undefined,
                annualComplianceSubmission: undefined,
                gracePeriod: undefined,
              })
            }
          >
            {t('enableInsuranceValidation')}
          </Checkbox>
        </FormItem>
        <FormItem
          label={t('validationCheckFrequency')}
          name="insuranceComplianceCheckFrequency"
          required={rateWizard.rateValues.insuranceValidation}
          rules={[
            {
              required: rateWizard.rateValues.insuranceCompliance,
              message: ERROR_MESSAGE().BLANK_FIELD,
            },
          ]}
        >
          <Select
            options={FREQUENCY_OPTIONS}
            disabled={rateWizard.isSubmitting || !rateWizard.rateValues.insuranceValidation}
            onChange={insuranceComplianceCheckFrequency =>
              handleFormValuesChange({ insuranceComplianceCheckFrequency })
            }
          />
        </FormItem>

        <FormItem
          label={t('annualComplianceSubmission')}
          name="annualComplianceSubmission"
          required={rateWizard.rateValues.insuranceValidation}
          rules={[
            {
              required: rateWizard.rateValues.insuranceValidation,
              message: ERROR_MESSAGE().BLANK_FIELD,
            },
          ]}
        >
          <Select
            fullWidth
            disabled={rateWizard.isSubmitting || !rateWizard.rateValues.insuranceValidation}
            optionFilterProp="children"
            options={[
              { label: t('confirmationOnly'), value: 'confirm' },
              { label: t('resubmit'), value: 'resubmit' },
            ]}
            onChange={annualComplianceSubmission => {
              handleFormValuesChange({ annualComplianceSubmission });
            }}
          />
        </FormItem>

        <FormItem
          required={rateWizard.rateValues.insuranceValidation}
          label={t('gracePeriod')}
          name="gracePeriod"
          rules={[
            {
              required: rateWizard.rateValues.insuranceValidation,
              message: ERROR_MESSAGE().BLANK_FIELD,
            },
          ]}
        >
          <Select
            disabled={rateWizard.isSubmitting || !rateWizard.rateValues.insuranceValidation}
            options={GRACE_PERIOD_OPTIONS}
            onChange={gracePeriod => handleFormValuesChange({ gracePeriod })}
          />
        </FormItem>
      </section>

      <SubmitCancelButtonGroup
        cancelText={t('Back')}
        submitText={t('Save')}
        loading={rateWizard.isSubmitting}
        onCancel={onBackClick}
        onSubmit={onNextClick}
      />
    </Form>
  );
};

export default CPMInsuranceValidation;
