import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import Radio from '../Radio';
import Select from '../Select';
import SortCaret from '../SortCaret/SortCaret';
import className from './style.module.scss';

const TimePicker = ({ onChange, value, disabled, availableMinutes }) => {
  const [hour, setHour] = useState(value ? moment(value).format('hh') : undefined);
  const [minute, setMinute] = useState(value ? moment(value).format('mm') : undefined);
  const [period, setPeriod] = useState(value ? moment(value).format('A') : undefined);

  const handleHour = evt => {
    setHour(evt);
  };

  const handleMinute = evt => {
    setMinute(evt);
  };

  useDidUpdateEffect(() => {
    if (
      typeof onChange === 'function' &&
      typeof hour !== 'undefined' &&
      typeof minute !== 'undefined' &&
      typeof period !== 'undefined'
    ) {
      const parsedHour = parseInt(hour);
      const parsedMinute = parseInt(minute).toString().padStart(2, '0');

      const isMidnight = period === 'AM' && parsedHour === 12;
      const isPmTime = period === 'PM' && parsedHour < 12;

      const hours = isMidnight ? 24 : isPmTime ? parsedHour + 12 : parsedHour;
      const formattedTime = moment(`${hours}:${parsedMinute}:00`, 'HH:mm:ss');

      onChange(formattedTime);
    }
    // eslint-disable-next-line
  }, [minute, hour, period]);

  const populateHours = () => {
    let hours = [];
    for (let i = 1; i <= 12; i++) {
      const timeFormat = i < 10 ? i.toString().padStart(2, '0') : i;
      hours.push({ label: timeFormat, value: timeFormat });
    }
    return hours;
  };

  const populateMinutes = () => {
    let minutes = [];
    if (Array.isArray(availableMinutes) && !!availableMinutes.length) {
      availableMinutes.forEach(min => {
        minutes.push({ label: min, value: min });
      });
    } else {
      for (let i = 0; i <= 59; i++) {
        const timeFormat = i < 10 ? i.toString().padStart(2, '0') : i;
        minutes.push({ label: timeFormat, value: timeFormat });
      }
    }
    return minutes;
  };

  return (
    <div className={className.wrapper}>
      <div className={className.selectHours}>
        <Select
          disabled={disabled}
          value={hour}
          onChange={time => {
            handleHour(time);
            if (!minute) handleMinute('00');
          }}
          placeholder="HH"
          suffixIcon={<SortCaret />}
          options={populateHours()}
        />
        {` : `}
        <Select
          disabled={disabled}
          value={minute}
          onChange={time => {
            handleMinute(time);
          }}
          placeholder="MM"
          suffixIcon={<SortCaret />}
          options={populateMinutes()}
        />
      </div>
      <div>
        <Radio.Group
          value={period}
          onChange={e => setPeriod(e.target.value)}
          buttonStyle="solid"
          disabled={disabled}
        >
          <Radio.Button value="AM">AM</Radio.Button>
          <Radio.Button value="PM">PM</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  onChange: PropTypes.func,
};

export default withNamespaces()(TimePicker);
