import React from 'react';

import classNames from './styles.module.scss';

const NavIcon = props => {
  const { Icon } = props;

  return <div className={classNames.navIcon}>{Icon && <Icon />}</div>;
};

export default NavIcon;
