import React from 'react';

import classNames from './styles.module.scss';

const GreenTopBorderHeader = props => {
  const { children } = props;

  return (
    <header className={classNames.header}>
      <div className={classNames.topGreenBar} />

      <div className={classNames.content}>{children}</div>
    </header>
  );
};

export default GreenTopBorderHeader;
