import './style.scss';

import { Card, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import StepCardIcon from '../StepCardIcon';
import Text from '../Text';

const StepsCard = ({ icon, heading, subheading, backgroundColor, image, children, isLocked }) => {
  return (
    <div className="step-card-wrapper">
      <Card className={`step-card ${isLocked ? 'locked' : ''}`}>
        <Row>
          <StepCardIcon color="primary" backgroundColor={backgroundColor || '#fff'} fontSize="24px">
            {typeof icon === 'number' ? (
              <>
                <div className={`number-icon ${isLocked ? 'locked' : ''}`}>{icon}</div>
              </>
            ) : (
              icon
            )}
          </StepCardIcon>
        </Row>
        <Row>
          <img src={image} alt={heading} />
        </Row>
        <Row>
          <Text variant="h4" className="step-name">
            {heading}
          </Text>
        </Row>
        <Row className="card-sub-head">{subheading}</Row>
      </Card>
    </div>
  );
};

StepsCard.prototypes = {
  background: PropTypes.any,
  icon: PropTypes.any,
  image: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

export default StepsCard;
