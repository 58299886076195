import React from 'react';

import ConfirmModal from '../ConfirmModal';

const SubmitReceiptsForApprovalConfirmation = props => {
  const { t, count, isSubmitting, visible, onConfirm, onCancel } = props;

  return (
    <ConfirmModal
      loading={isSubmitting}
      visible={visible}
      title={t('sureYouWantToResubmitReceiptForApproval', { count })}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={t('yes')}
      cancelText={t('no')}
    />
  );
};

export default SubmitReceiptsForApprovalConfirmation;
