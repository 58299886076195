import { Col, Form, Row } from 'antd';
import React from 'react';

import Radio from '../../../components/Radio';
import SectionStepWrapper from '../../../components/SectionStepWrapper';
import TextInput from '../../../components/TextInput';
import { ERROR_MESSAGE } from '../../../utils/constants';
import classNames from './styles.module.scss';

const CommuteDeductionSettingsSection = props => {
  const {
    t,
    disabledForm,
    hideStepNumber,
    hideContent,
    isSubmitting,
    commuteSettings,
    onCommuteSettingsChange,
    isCommuteSettingCustom,
    isCommuteSettingSingleLocation,
    isCommuteSettingNotHaveOffice,
    isNoOfficeMaxDeduction,
    isNoOfficeIgnoreFirstAndLast,
    isHomeAddressMaxCommuteDeduction,
    distanceUnit,
  } = props;

  return (
    <SectionStepWrapper
      step="2"
      hideStepNumber={hideStepNumber}
      title={hideContent ? null : t('chooseCommuteDeductionSetting')}
      titleProps={{ variant: 'b', size: 'sm', style: { marginTop: 2.5, marginBottom: 10 } }}
      showContent={!!commuteSettings?.enabled && !!commuteSettings?.level}
    >
      <Form.Item
        name="mainSetting"
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <Radio.Group
          name="mainSetting"
          className={classNames.topLevelRadioGroup}
          value={commuteSettings?.mainSetting}
          disabled={disabledForm || isSubmitting}
          onChange={e =>
            onCommuteSettingsChange({
              mainSetting: e.target.value,
              secondarySetting: null,
              secondarySettingValue: undefined,
            })
          }
        >
          <Row>
            <Col>
              <Row>
                <Radio
                  disabled={!commuteSettings.enabled || isSubmitting}
                  className={classNames.topLevelRadioLabel}
                  value="singleOffice"
                >
                  {t('employeeWorksAtSingleOfficeLocation')}
                </Radio>
              </Row>

              <Row>
                <Form.Item
                  rules={[
                    {
                      required: isCommuteSettingSingleLocation && commuteSettings.enabled,
                      message: ERROR_MESSAGE().BLANK_FIELD,
                    },
                  ]}
                  name={isCommuteSettingSingleLocation ? 'secondarySetting' : 'empty'}
                >
                  <Radio.Group
                    disabled={
                      !commuteSettings.enabled ||
                      !isCommuteSettingSingleLocation ||
                      isSubmitting ||
                      disabledForm
                    }
                    className={classNames.subRadioGroup}
                    onChange={e => onCommuteSettingsChange({ secondarySetting: e.target.value })}
                  >
                    <Radio value="deductDaily">
                      {t(
                        'deductDailyMileageEqualToDistanceBetweenEmployeesRoundTripHomeAndOfficeLocatiom',
                      )}
                    </Radio>
                    <Radio value="deductTripAndMileage">
                      {t('deductTripMileageIfFirstTripOfDayIsFromHomeLocationToOffice')}
                    </Radio>
                    <Radio value="officeVisit">
                      {t('ifEmployeeVisitsTheOfficeLocationAnyTimeDuringTheDay')}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row>
                <Radio
                  disabled={!commuteSettings.enabled || isSubmitting}
                  className={classNames.topLevelRadioLabel}
                  value="noOffice"
                >
                  {t('employeeDoesNotHaveAnOffice')}
                </Radio>
              </Row>

              <Row>
                <Form.Item
                  rules={[
                    {
                      required: isCommuteSettingNotHaveOffice && commuteSettings.enabled,
                      message: ERROR_MESSAGE().BLANK_FIELD,
                    },
                  ]}
                  name={isCommuteSettingNotHaveOffice ? 'secondarySetting' : 'empty'}
                >
                  <Radio.Group
                    className={classNames.subRadioGroup}
                    disabled={
                      !commuteSettings.enabled ||
                      !isCommuteSettingNotHaveOffice ||
                      isSubmitting ||
                      disabledForm
                    }
                    onChange={e => onCommuteSettingsChange({ secondarySetting: e.target.value })}
                  >
                    <Radio value="alwaysIgnore">{t('alwaysIgnoreFirstAndLastTripOfTheDay')}</Radio>
                    <Radio value="ignoreFirst">{t('alwaysIgnoreFirstTripOnly')}</Radio>
                    <Radio value="ignoreLast">{t('alwaysIgnoreLastTripOnly')}</Radio>

                    <Row className={classNames.topLevelRadioLabel}>
                      <Col flex={1}>
                        <Row>
                          <Radio
                            value="maxCommuteDeduction"
                            className={classNames.topLevelRadioLabel}
                          >
                            {t('maxCommuteDeductionValue')}
                          </Radio>
                        </Row>
                        <Row className={classNames.subRadioGroup}>
                          <Col flex="200px">
                            <Form.Item
                              className="no-margin-bottom"
                              name={
                                isCommuteSettingNotHaveOffice && isNoOfficeMaxDeduction
                                  ? 'secondarySettingValue'
                                  : 'empty'
                              }
                              rules={[
                                {
                                  required:
                                    isCommuteSettingNotHaveOffice &&
                                    isNoOfficeMaxDeduction &&
                                    commuteSettings.enabled,
                                  message: ERROR_MESSAGE().BLANK_FIELD,
                                },
                              ]}
                            >
                              <TextInput
                                disabled={
                                  !commuteSettings.enabled ||
                                  !(isCommuteSettingNotHaveOffice && isNoOfficeMaxDeduction) ||
                                  disabledForm
                                }
                                step="any"
                                type="number"
                                placeholder={t('maxDeduction')}
                                name="secondarySettingValue"
                                suffix={t(`${distanceUnit}_plural`)}
                                onChange={value =>
                                  onCommuteSettingsChange({
                                    secondarySettingValue: value,
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className={classNames.topLevelRadioLabel}>
                      <Col flex={1}>
                        <Row>
                          <Radio value="mileageRadius" className={classNames.topLevelRadioLabel}>
                            {t('ignoreFirstLastTripAndAssociatedMileage')}
                          </Radio>
                        </Row>
                        <Row className={classNames.subRadioGroup}>
                          <Col flex="200px">
                            <Form.Item
                              className="no-margin-bottom"
                              name={
                                isCommuteSettingNotHaveOffice && isNoOfficeIgnoreFirstAndLast
                                  ? 'secondarySettingValue'
                                  : 'empty'
                              }
                              rules={[
                                {
                                  required:
                                    isCommuteSettingNotHaveOffice &&
                                    isNoOfficeIgnoreFirstAndLast &&
                                    commuteSettings.enabled,
                                  message: ERROR_MESSAGE().BLANK_FIELD,
                                },
                              ]}
                            >
                              <TextInput
                                disabled={
                                  !commuteSettings.enabled ||
                                  !(
                                    isCommuteSettingNotHaveOffice && isNoOfficeIgnoreFirstAndLast
                                  ) ||
                                  disabledForm
                                }
                                step="any"
                                type="number"
                                placeholder={t('radius')}
                                name="secondarySettingValue"
                                suffix={t(`${distanceUnit}_plural`)}
                                onChange={value =>
                                  onCommuteSettingsChange({
                                    secondarySettingValue: value,
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className={classNames.topLevelRadioLabel}>
                      <Col flex={1}>
                        <Row>
                          <Radio
                            value="homeAddressMaxCommuteDeduction"
                            className={classNames.topLevelRadioLabel}
                          >
                            {t('homeAddressMaxCommuteDeduction')}
                          </Radio>
                        </Row>
                        <Row className={classNames.subRadioGroup}>
                          <Col flex="200px">
                            <Form.Item
                              className="no-margin-bottom"
                              name={
                                isCommuteSettingNotHaveOffice && isHomeAddressMaxCommuteDeduction
                                  ? 'secondarySettingValue'
                                  : 'empty'
                              }
                              rules={[
                                {
                                  required:
                                    isCommuteSettingNotHaveOffice &&
                                    isHomeAddressMaxCommuteDeduction &&
                                    commuteSettings.enabled,
                                  message: ERROR_MESSAGE().BLANK_FIELD,
                                },
                              ]}
                            >
                              <TextInput
                                disabled={
                                  !commuteSettings.enabled ||
                                  !(
                                    isCommuteSettingNotHaveOffice &&
                                    isHomeAddressMaxCommuteDeduction
                                  ) ||
                                  disabledForm
                                }
                                step="any"
                                type="number"
                                placeholder={t('maxDeduction')}
                                name="secondarySettingValue"
                                suffix={t(`${distanceUnit}_plural`)}
                                onChange={value =>
                                  onCommuteSettingsChange({
                                    secondarySettingValue: value,
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col flex={1}>
              <Row>
                <Radio
                  disabled={!commuteSettings.enabled || isSubmitting}
                  className={classNames.topLevelRadioLabel}
                  value="customBusinessRule"
                >
                  {t('customCommuteBusinessRule')}
                </Radio>
              </Row>

              <Row>
                <Col xs={24}>
                  <Form.Item
                    name={isCommuteSettingCustom ? 'secondarySettingValue' : 'empty'}
                    rules={[
                      {
                        required: isCommuteSettingCustom && commuteSettings.enabled,
                        message: ERROR_MESSAGE().BLANK_FIELD,
                      },
                    ]}
                  >
                    <TextInput
                      textArea
                      autoSize={{ minRows: 4, maxRows: 6 }}
                      placeholder={t('jsonRulesGoHere')}
                      disabled={!commuteSettings.enabled || !isCommuteSettingCustom || disabledForm}
                      onChange={e =>
                        onCommuteSettingsChange({
                          secondarySettingValue: e.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
    </SectionStepWrapper>
  );
};

export default CommuteDeductionSettingsSection;
