import './style.scss';

import { Col, Row } from 'antd';
import { Button } from 'components';
import StepsCard from 'components/StepsCard';
import { INTERNAL_LINKS, USER_REG_STATUS } from 'enum';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { COMPANY_API } from '../../../../api/company';
import PageContainer from '../../../../containers/PageContainer';
import { IMAGES } from '../../../../enum';
import { updateCompanyPlan } from '../../../../stores/actions/common';
import { SKIP_INVITE_STEP } from '../../../../stores/actions/company';
import { formatPageTitle } from '../../../../utils/common';

const mainColor = '#16b296';
const companyPlaceholder = '/images/icons/office-outdoors.svg';
const ratePlaceholder = '/images/icons/calendar-cash-1.svg';
const teamPlaceholder = '/images/icons/team-meeting.svg';
const successIcon = <i className="fa fa-check fa-2x"></i>;

const CompanyOnboarding = ({ t }) => {
  const dispatch = useDispatch();

  const profile = useSelector(state => state.profile);
  const history = useHistory();
  const currentCompany = useSelector(state => state.common.currentCompany);
  const user = useSelector(state => state.user);
  const [name, setName] = useState('');
  const [addressCheckIcon, setAddressCheckIcon] = useState(false);
  const [ratesCheckIcon, setRatesCheckIcon] = useState(false);
  const [inviteCheckIcon, setInviteCheckIcon] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (profile && profile.profile) {
      setName(`${profile.profile.firstName} ${profile.profile.lastName}`);
    }
  }, [profile]);

  useEffect(() => {
    if (user.regStatus > USER_REG_STATUS.COMPANY) {
      setAddressCheckIcon(true);
    }

    if (user.regStatus > USER_REG_STATUS.PRICES) {
      setRatesCheckIcon(true);
    }

    if (user.regStatus > USER_REG_STATUS.INVITE) {
      setInviteCheckIcon(true);
    }
    // eslint-disable-next-line
  }, [currentCompany]);

  return (
    <CSSTransition appear={true} in={isMounted} timeout={500} unmountOnExit>
      <PageContainer title="" className="accounts-page">
        <Helmet>
          <title>{formatPageTitle('Company Onboarding')}</title>
        </Helmet>

        <Row className="kliks-row cta-text slide-down-title" style={{ paddingTop: 50 }}>
          {t('welcome_name', { name })}!
        </Row>
        {user.regStatus <= USER_REG_STATUS.INVITE ? (
          <Row className="form-subhead">{t('letsGetStarted')}</Row>
        ) : (
          ''
        )}

        <br />

        <Row justify="center" align="center" gutter={[20, 20]}>
          <Col flex="320px">
            <div className="card">
              <NavLink to={`${INTERNAL_LINKS.COMPANY_ONBOARDING}${INTERNAL_LINKS.PLAN_TRIAL}`}>
                <StepsCard
                  backgroundColor={user.regStatus > USER_REG_STATUS.PLAN ? mainColor : null}
                  icon={user.regStatus > USER_REG_STATUS.PLAN ? successIcon : USER_REG_STATUS.PLAN}
                  image={IMAGES.LIST_TO_DO_ICON}
                  heading={t('selectPricePlan')}
                  subheading={t('selectAndStartTrial')}
                />
              </NavLink>
            </div>
          </Col>

          <Col flex="320px">
            <div className="card">
              <NavLink
                to={
                  user.regStatus >= USER_REG_STATUS.COMPANY
                    ? `${INTERNAL_LINKS.COMPANY_ONBOARDING}${INTERNAL_LINKS.COMPANY_INFO}`
                    : '#'
                }
              >
                <StepsCard
                  isLocked={
                    currentCompany && (!user.regStatus || user.regStatus < USER_REG_STATUS.COMPANY)
                  }
                  backgroundColor={addressCheckIcon ? mainColor : null}
                  icon={addressCheckIcon ? successIcon : USER_REG_STATUS.COMPANY}
                  image={companyPlaceholder}
                  heading={t('setUpYourCompany')}
                  subheading={t('justFewDetails')}
                />
              </NavLink>
            </div>
          </Col>

          <Col flex="320px">
            <div className="card">
              <NavLink
                to={
                  user.regStatus >= USER_REG_STATUS.PRICES
                    ? {
                        pathname: `${INTERNAL_LINKS.COMPANY_ONBOARDING}${INTERNAL_LINKS.REIMBURSEMENT_RATE}`,
                      }
                    : '#'
                }
              >
                <StepsCard
                  isLocked={
                    currentCompany && (!user.regStatus || user.regStatus < USER_REG_STATUS.PRICES)
                  }
                  backgroundColor={ratesCheckIcon ? mainColor : null}
                  icon={ratesCheckIcon ? successIcon : USER_REG_STATUS.PRICES}
                  image={ratePlaceholder}
                  heading={t('setRate')}
                  subheading={t('kliksWillTakeItFromHere')}
                />
              </NavLink>
            </div>
          </Col>

          <Col flex="320px">
            <div className="card">
              <NavLink
                to={
                  user.regStatus >= USER_REG_STATUS.INVITE
                    ? `${INTERNAL_LINKS.COMPANY_ONBOARDING}${INTERNAL_LINKS.INVITE_USERS}`
                    : '#'
                }
              >
                <StepsCard
                  isLocked={
                    currentCompany && (!user.regStatus || user.regStatus < USER_REG_STATUS.INVITE)
                  }
                  backgroundColor={inviteCheckIcon ? mainColor : null}
                  icon={inviteCheckIcon ? successIcon : USER_REG_STATUS.INVITE}
                  image={teamPlaceholder}
                  heading={t('inviteUsersOptional')}
                  subheading={t('onboardDriversKliksCanStart')}
                />
              </NavLink>
            </div>
          </Col>
        </Row>
        {user.regStatus > USER_REG_STATUS.PRICES && (
          <div className="dashboard-button">
            <br />
            <Row className="form-subhead">{t('youAreSetUp')}</Row>
            <Button
              onClick={() => {
                if (user.regStatus < USER_REG_STATUS.SUCCESS) {
                  dispatch({ type: SKIP_INVITE_STEP, data: { companyID: currentCompany._id } });
                }

                COMPANY_API.getActivePlan(currentCompany._id, plan => {
                  dispatch(updateCompanyPlan(plan));
                });

                history.push(INTERNAL_LINKS.DASHBOARD);
              }}
              size="large"
              text={t('takeMeToDashboard')}
              type="primary"
            />
          </div>
        )}
      </PageContainer>
    </CSSTransition>
  );
};

export default withNamespaces()(CompanyOnboarding);
