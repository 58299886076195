import React from 'react';

import BusinessMileageItemsList from './BusinessMileageItemsList';
import InsuranceComplianceItemsList from './InsuranceComplianceItemsList';
import VehicleComplianceItemsList from './VehicleComplianceItemsList';

const ComplianceItemsList = props => {
  const { t, type, complianceComponents } = props;

  if (type === 'business-mileage') {
    return <BusinessMileageItemsList t={t} complianceComponents={complianceComponents} />;
  }

  if (type === 'business-vehicle') {
    return <VehicleComplianceItemsList t={t} complianceComponents={complianceComponents} />;
  }

  if (type === 'insurance') {
    return <InsuranceComplianceItemsList t={t} complianceComponents={complianceComponents} />;
  }

  return '-';
};

export default ComplianceItemsList;
