import React from 'react';

import { momentTimezone } from '../../../utils/common';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import {
  sortColumnByMomentDate,
  sortColumnByMomentTime,
  sortColumnByNumber,
} from '../../../utils/tables';
import { CustomTable } from '../..';

const AdjustmentHistorySmallTable = props => {
  const { t, dataSource, currency } = props;

  const COLUMNS = [
    {
      key: 'date',
      title: t('date'),
      ...sortColumnByMomentDate('created', 'MM-DD-YYYY'),
      render: (actions, data) => momentTimezone(data.created, null).format('MM-DD-YYYY'),
    },
    {
      key: 'time',
      title: t('time'),
      ...sortColumnByMomentTime('created', 'hh:mm a'),
      render: (actions, data) => momentTimezone(data.created, null).format('hh:mm a'),
    },
    {
      align: 'right',
      key: 'previousAmount',
      title: t('previousAmount'),
      render: (actions, data) => (
        <span className={data.previousAmount < 0 ? 'negative-currency' : undefined}>
          {formatNumberWithCurrency(data.previousAmount, currency)}
        </span>
      ),
      ...sortColumnByNumber('previousAmount'),
    },
    {
      align: 'right',
      key: 'adjustmentAmount',
      title: t('adjAmount'),
      render: (actions, data) => (
        <span className={data.amount < 0 ? 'negative-currency' : undefined}>
          {formatNumberWithCurrency(data.amount, currency)}
        </span>
      ),
      ...sortColumnByNumber('amount'),
    },
    {
      align: 'right',
      key: 'finalAmount',
      title: t('finalAmount'),
      render: (actions, data) => (
        <span className={data.finalAmount < 0 ? 'negative-currency' : undefined}>
          {formatNumberWithCurrency(data.finalAmount, currency)}
        </span>
      ),
      ...sortColumnByNumber('finalAmount'),
    },
  ];

  return (
    <CustomTable
      scroll={{ x: 456 }}
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource}
    />
  );
};

AdjustmentHistorySmallTable.defaultProps = {
  dataSource: [],
};

export default AdjustmentHistorySmallTable;
