import React from 'react';
import PropTypes from 'prop-types';
// the hoc
import { withNamespaces } from 'react-i18next';

import './style.scss';

class StepTabs extends React.Component {

  render() {
    const { steps, className, current, onClick, t } = this.props
    const getClassName = index => `step-tabs-item ${className} ${current === index ? 'active' : ''}`

    return (
      <div className="step-tabs">
        {steps.map((s, index) => {
          return (
            <div className={getClassName(index)} key={index} onClick={() => onClick(index)}>
              {`Step ${index + 1} - ${t(s)}`}
            </div>
          )
        })}
      </div>
    )
  }
}

StepTabs.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.array,
  current: PropTypes.number,
  onClick: PropTypes.func,
}

StepTabs.defaultProps = {
  className: '',
  steps: [],
  current: 0,
  onClick: () => { }
}

export default withNamespaces()(StepTabs);