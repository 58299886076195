import getSymbolFromCurrency from 'currency-symbol-map';

import { STATUS_LIST } from '../enum';
import { selectStoreCurrentCompany } from './storeSelectors';

export const isActiveCompany = company => {
  return [STATUS_LIST().Status.ACTIVE, STATUS_LIST().Status.ACTIVE_TRIAL].includes(company.status);
};

export const isCurrentCompanySubActive = store => {
  const currentCompany = selectStoreCurrentCompany(store);
  if (!currentCompany?.status) return true;

  return isActiveCompany(currentCompany);
};

/**
 * @returns {{ currencyUnit: string, currencySymbol: string }}
 */
export const getCompanyCurrency = () => {
  const currentCompany = selectStoreCurrentCompany();
  const currencyUnit = currentCompany?.currencyUnit || 'USD';
  return {
    currencyUnit,
    currencySymbol: getSymbolFromCurrency(currencyUnit),
  };
};
