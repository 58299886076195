import { Col, Empty, Row } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { Button, HelpIcon } from '../../../../components';
import RowButton from '../../../../components/RowButton';
import StatusTag from '../../../../components/Tag/StatusTag';
import Text from '../../../../components/Text';
import FadedText from '../../../../components/Text/FadedText';
import { IMAGES, STATUS_LIST } from '../../../../enum';
import { getUserFullName, momentFormat } from '../../../../utils/common';

const RequestsFromUsersTab = props => {
  const {
    t,
    requests,
    loggedInUserID,
    onDeny,
    onApprove,
    denyingRequestID,
    approvingRequestID,
  } = props;

  if (!requests || (Array.isArray(requests) && !requests.length)) {
    return (
      <Empty
        description={
          <Text variant="strong" size="sm" textAlign="center">
            {t('noPendingRequests')}
          </Text>
        }
      />
    );
  }

  const renderHelpInfo = request => {
    const STATUS_TRANS_MAP = {
      [STATUS_LIST().Status.DENIED]: 'requestDeniedByOn',
      [STATUS_LIST().Status.APPROVED]: 'requestApprovedByOn',
      [STATUS_LIST().Status.CANCELLED]: 'requestCancelledByOn',
      [STATUS_LIST().Status.AWAITING_ANONYMIZATION]: 'requestAwaitingForAnonymizeOn',
    };

    if (!STATUS_TRANS_MAP[request.status]) return null;

    if (!request?.statusChangedBy?._id) return null;

    let date;
    let time;

    if (request.status === STATUS_LIST().Status.AWAITING_ANONYMIZATION) {
      date = momentFormat(request.deleteDate, 'LL');
      time = momentFormat(request.deleteDate, 'hh:mm: A');
    } else {
      date = momentFormat(request.statusChangeTimestamp, 'LL');
      time = momentFormat(request.statusChangeTimestamp, 'hh:mm: A');
    }

    return (
      <HelpIcon
        hint={
          <Text size="sm" style={{ maxWidth: 400, display: 'block' }} renderAs="span">
            <Trans
              t={t}
              i18nKey={STATUS_TRANS_MAP[request.status]}
              values={{ name: getUserFullName(request?.statusChangedBy), date, time }}
              components={[
                <span style={{ whiteSpace: 'nowrap' }}>DummyText</span>,
                <span style={{ display: 'block', marginTop: 3 }}>DummyText</span>,
              ]}
            />
          </Text>
        }
      />
    );
  };

  return (
    <div>
      {requests.map(request => (
        <RowButton
          key={request._id}
          clickable={false}
          icon={
            request.requestType === 'download' ? IMAGES.TEXT_FILE_REMOVE_ICON : IMAGES.CSV_FILE_ICON
          }
          avatar={{
            photo: request.requestingUser.profilePicture,
            firstName: request.requestingUser.firstName,
            lastName: request.requestingUser.lastName,
          }}
          content={
            <>
              {request.requestType === 'anonymize' && (
                <>
                  <Text variant="strong" size="sm">
                    {request.requestingUser.name}
                  </Text>
                  <Text size="sm">{t('requestedToAnonymizeData')}</Text>
                </>
              )}

              {request.requestType === 'download' && (
                <Text variant="strong" size="sm">
                  {t('downloadUserData')}
                </Text>
              )}

              <FadedText size="sm">
                <Trans
                  t={t}
                  i18nKey="requestOnDate"
                  values={{
                    date: momentFormat(new Date(request.created), 'LL'),
                    time: momentFormat(new Date(request.created), 'hh:mm A'),
                  }}
                  components={[<span style={{ whiteSpace: 'nowrap' }}>DummyText</span>]}
                />
              </FadedText>
            </>
          }
          sideContent={
            <Row justify="start" align="middle" gutter={17}>
              <Col>
                <Row gutter={5} style={{ flexWrap: 'nowrap' }}>
                  <Col>
                    <StatusTag status={request.status} />
                  </Col>

                  <Col>
                    {renderHelpInfo(request)}

                    {STATUS_LIST().Status.AWAITING_APPROVAL === request.status &&
                      loggedInUserID === request.requestingUser._id && (
                        <HelpIcon
                          hint={
                            <Text style={{ width: 250 }}>
                              {t('onlyAnotherAdminCanChangeRequestStatus')}
                            </Text>
                          }
                        />
                      )}
                  </Col>
                </Row>
              </Col>
              {[STATUS_LIST().Status.AWAITING_APPROVAL].includes(request.status) && (
                <>
                  {loggedInUserID !== request.requestingUser._id && (
                    <>
                      <Col>
                        <Row gutter={[8, 8]} style={{ flexWrap: 'wrap-reverse' }}>
                          <Col flex={1}>
                            <Button
                              block
                              size="sm"
                              disabled={!!approvingRequestID || !!denyingRequestID}
                              loading={denyingRequestID === request._id}
                              type={'secondary'}
                              onClick={() => onDeny(request._id)}
                            >
                              {t('deny')}
                            </Button>
                          </Col>
                          <Col flex={1}>
                            <Button
                              block
                              size="sm"
                              disabled={!!approvingRequestID || !!denyingRequestID}
                              loading={approvingRequestID === request._id}
                              onClick={() => onApprove(request._id)}
                            >
                              {t('approve')}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
          }
        />
      ))}
    </div>
  );
};

export default RequestsFromUsersTab;
