import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../../components';
import Box from '../../../../components/Box';
import Text from '../../../../components/Text';
import LinkText from '../../../../components/Text/LinkText';
import { IMAGES, INTERNAL_LINKS } from '../../../../enum';
import className from './styles.module.scss';

const ConfirmHomeAddressBox = props => {
  const { t, homeAddress, isConfirming, onConfirm } = props;

  return (
    <Box className={className.card}>
      <Row>
        <img width="60px" src={IMAGES.HOME_PIN_ICON} alt="Home pin" />
      </Row>

      <br />

      <Text variant="h4">{t('Confirm your home address')}</Text>

      <Text>{`${homeAddress.streetOne} ${homeAddress.streetTwo || ''}`}</Text>
      <Text>{`${homeAddress.city}, ${homeAddress.state} ${homeAddress.country} ${homeAddress.postalCode}`}</Text>

      <br />

      <Row align="middle" justify="space-between">
        <Col>
          <Link
            to={
              isConfirming ? '#' : `${INTERNAL_LINKS.USER_ONBOARDING}${INTERNAL_LINKS.HOME_ADDRESS}`
            }
          >
            <LinkText variant="strong">{t('editAddress')}</LinkText>
          </Link>
        </Col>

        <Col>
          <Button loading={isConfirming} disabled={isConfirming} onClick={onConfirm}>
            {t('yesUseThisAddress')}
          </Button>
        </Col>
      </Row>
    </Box>
  );
};

export default ConfirmHomeAddressBox;
