import { Col, Row } from 'antd';
import { get, range } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { COMPANY_API } from '../../api/company';
import DownloadIcon from '../../components/DownloadIcon';
import FormItem from '../../components/Form/FormItem';
import Select from '../../components/Select';
import CompanyTaxReportTable from '../../components/Table/CompanyTaxReportTable';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import useTableSort from '../../hooks/useTableSort';
import { formatPageTitle } from '../../utils/common';
import { parseQueryParams } from '../../utils/queryParams';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';

const CompanyTaxReportsView = props => {
  const { t, location, history } = props;
  const queryParams = parseQueryParams(location.search);
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const [taxYear, setTaxYear] = useState(queryParams.year || new Date().getFullYear());
  const { stringTableSort, handleTableSort } = useTableSort({
    firstName: 1,
    lastName: 1,
  });

  const {
    query: fetchCompanyTaxReportQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      enabled: !!currentCompany?._id,
      queryKey: ['fetchCompanyTaxReport', currentCompany?._id, taxYear, stringTableSort],
      queryFn: () =>
        COMPANY_API.fetchCompanyTaxReport(
          currentCompany._id,
          taxYear,
          paginationConfig.current,
          paginationConfig.pageSize,
          stringTableSort,
        ),
      onError: error => handleApiErrors(error.response),
    },
    { resetPageQueryKey: [taxYear] },
    'totalCount',
  );

  const exportAllUsersTaxReport = useMutation(
    () => COMPANY_API.exportAllCompanyUsersTaxReport(currentCompany._id, taxYear, stringTableSort),
    {
      onError: error => handleApiErrors(error.response),
      onSuccess: () => {
        Toast({
          type: 'open',
          duration: 10, // seconds
          message: (
            <Trans
              t={t}
              i18nKey="exportBeingProcessed"
              components={[
                <LinkText variant="b" onClick={() => history.push(INTERNAL_LINKS.EXPORTS)}>
                  Dummy
                </LinkText>,
              ]}
            />
          ),
        });
      },
    },
  );

  return (
    <PageContainer title={t('taxReportSummary')}>
      <Helmet>
        <title>{formatPageTitle('Tax Report Summary')}</title>
      </Helmet>

      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col>
          <FormItem label={t('taxYear')}>
            <Select
              style={{ minWidth: 200 }}
              value={taxYear}
              disabled={fetchCompanyTaxReportQuery.isFetching}
              options={range(
                new Date().getFullYear(),
                new Date(currentCompany.created).getFullYear() - 1,
              ).map(year => ({
                label: year === new Date().getFullYear() ? t('currentYearSuffix', { year }) : year,
                value: year,
              }))}
              onChange={year => setTaxYear(year)}
            />
          </FormItem>
        </Col>

        <DownloadIcon
          colWidth="65px"
          text={t('exportToCSV')}
          loading={exportAllUsersTaxReport.isLoading}
          onClick={exportAllUsersTaxReport.mutateAsync}
          disabled={
            fetchCompanyTaxReportQuery.isFetching ||
            !paginationConfig.total ||
            paginationConfig.total === 0
          }
        />
      </Row>

      <CompanyTaxReportTable
        t={t}
        asynSort
        taxYear={taxYear}
        loading={fetchCompanyTaxReportQuery.isFetching}
        dataSource={get(fetchCompanyTaxReportQuery.data, 'documents')}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
          handleTableSort(sorters?.columnKey, sorters?.order);
        }}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(CompanyTaxReportsView));
