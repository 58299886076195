import { Col, Layout, Row } from 'antd';
import React, { useState } from 'react';

import ActiveUsersMap from '../../../components/ActiveUsersMap';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { checkIfElementIsScrolledToBottom } from '../../../utils/common';
import KliksInsightsSideMenu from './KliksInsightsSideMenu';
import classNames from './styles.module.scss';
import useKliksInsightsBoxState from './useKliksInsightsBoxState';

const KliksInsightsBox = props => {
  const { t, selectedGroup, onGroupChange } = props;

  const [mapInstance, setMapInstance] = useState();

  const {
    // Booleans
    isSiderCollapsed,
    // General
    kliksInsightsQuery,
    userInsights,
    eventInsights,
    visibleUserPings,
    visibleEventPings,
    filterFormValues,
    mapCenter,
    mapZoom,
    // Handlers & Togglers
    handleCollapsedSidebar,
    toggleUserPingVisiblity,
    toggleEventPingVisiblity,
    handleFilterFormValuesChange,
    handleChangeMapCenter,
  } = useKliksInsightsBoxState({ selectedGroup });

  const handleSidebarScroll = e => {
    const bottom = checkIfElementIsScrolledToBottom(e.target);

    if (bottom && !kliksInsightsQuery.isFetchingNextPage && kliksInsightsQuery.hasNextPage) {
      kliksInsightsQuery.fetchNextPage();
    }
  };

  useDidUpdateEffect(() => {
    if (mapInstance) {
      setTimeout(() => {
        mapInstance.resize();
      }, [200]);
    }
  }, [mapInstance, isSiderCollapsed]);

  return (
    <Box padding="0" className={classNames.kliksInsightsBox}>
      <Row justify="start" gutter={5}>
        <Col>
          <Text variant="h4" style={{ margin: 0, padding: 24 }}>
            {t('kliksInsights')} - {t('activeUsers')}
          </Text>
        </Col>
      </Row>

      <Row justify="start" style={{ height: 600, flexWrap: 'nowrap' }}>
        <Col flex={1}>
          <Layout style={{ height: '100%' }}>
            <Layout>
              <Layout.Content>
                <ActiveUsersMap
                  t={t}
                  mapInstance={mapInstance}
                  onMapLoad={setMapInstance}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  onMapCenterChange={handleChangeMapCenter}
                  userList={userInsights}
                  eventList={eventInsights}
                  visibleUserPings={visibleUserPings}
                  visibleEventPings={visibleEventPings}
                />
              </Layout.Content>
            </Layout>

            <Layout.Sider
              collapsible
              collapsed={isSiderCollapsed}
              onCollapse={handleCollapsedSidebar}
              style={{ position: 'initial' }}
              width={300}
              collapsedWidth={40}
              onScroll={handleSidebarScroll}
            >
              <KliksInsightsSideMenu
                t={t}
                isLoading={!kliksInsightsQuery.isFetched}
                isLoadingMore={kliksInsightsQuery.isFetchingNextPage}
                visible={!isSiderCollapsed}
                userInsights={userInsights}
                eventInsights={eventInsights}
                checkedUsers={visibleUserPings}
                checkedEvents={visibleEventPings}
                onUserCheckChange={toggleUserPingVisiblity}
                onEventCheckChange={toggleEventPingVisiblity}
                selectedGroup={selectedGroup}
                onGroupChange={onGroupChange}
                filterFormValues={filterFormValues}
                onFilterFormValuesChange={handleFilterFormValuesChange}
                onMapCenterChange={handleChangeMapCenter}
              />
            </Layout.Sider>
          </Layout>
        </Col>
      </Row>
    </Box>
  );
};

export default KliksInsightsBox;
