import _ from 'lodash';
import React, { useMemo } from 'react';

import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { checkIfStringContainsValue, getUserFullName, momentTimezone } from '../../../utils/common';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import {
  sortColumnByMomentDate,
  sortColumnByMomentTime,
  sortColumnByNumber,
  sortColumnByStringField,
} from '../../../utils/tables';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import CustomTable from '../../Table';

const AdjustmentHistoryTable = props => {
  const { t, dataSource, defaultSearchTerm, currency } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState(defaultSearchTerm);

  useDidUpdateEffect(() => {
    setSearchTerm(defaultSearchTerm);
  }, [defaultSearchTerm]);

  const filteredTable = useMemo(() => {
    let array = _.orderBy(dataSource, adjustment => new Date(adjustment.created), 'desc');

    if (debouncedSearchTerm) {
      array = array.filter(adj => {
        return (
          checkIfStringContainsValue(adj.receiptSeqId, debouncedSearchTerm) ||
          checkIfStringContainsValue(adj.note, debouncedSearchTerm) ||
          checkIfStringContainsValue(`${adj.amount}`, debouncedSearchTerm) ||
          checkIfStringContainsValue(`${adj.finalAmount}`, debouncedSearchTerm) ||
          checkIfStringContainsValue(`${adj.previousAmount}`, debouncedSearchTerm) ||
          checkIfStringContainsValue(getUserFullName(adj.userId), debouncedSearchTerm) ||
          checkIfStringContainsValue(adj.adjustmentType, debouncedSearchTerm) ||
          checkIfStringContainsValue(
            momentTimezone(adj.created, null).format('hh:mm a'),
            debouncedSearchTerm,
          ) ||
          checkIfStringContainsValue(
            momentTimezone(adj.created, null).format('MM-DD-YYYY'),
            debouncedSearchTerm,
          )
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  const COLUMNS = [
    {
      width: 120,
      key: 'date',
      title: t('date'),
      ...sortColumnByMomentDate('created', 'MM-DD-YYYY'),
      render: (actions, data) => momentTimezone(data.created, null).format('MM-DD-YYYY'),
    },
    {
      width: 100,
      key: 'time',
      title: t('time'),
      ...sortColumnByMomentTime('created', 'hh:mm a'),
      render: (actions, data) => momentTimezone(data.created, null).format('hh:mm a'),
    },
    {
      width: 200,
      key: 'user',
      title: t('user'),
      render: (actions, data) => (
        <ProfileNamePicture
          t={t}
          user={{
            ...data.userId,
            _id: data.userId._id,
          }}
        />
      ),
    },
    {
      key: 'adjustmentType',
      title: t('adjustmentType'),
      render: (actions, data) => _.capitalize(data.adjustmentType),
      ...sortColumnByStringField('adjustmentType'),
    },
    {
      align: 'right',
      key: 'previousAmount',
      title: t('previousAmount'),
      render: (actions, data) => (
        <span className={data.previousAmount < 0 ? 'negative-currency' : undefined}>
          {formatNumberWithCurrency(data.previousAmount, currency)}
        </span>
      ),
      ...sortColumnByNumber('previousAmount'),
    },
    {
      align: 'right',
      key: 'adjustmentAmount',
      title: t('adjAmount'),
      render: (actions, data) => (
        <span className={data.amount < 0 ? 'negative-currency' : undefined}>
          {formatNumberWithCurrency(data.amount, currency)}
        </span>
      ),
      ...sortColumnByNumber('amount'),
    },
    {
      align: 'right',
      key: 'finalAmount',
      title: t('finalAmount'),
      render: (actions, data) => (
        <span className={data.finalAmount < 0 ? 'negative-currency' : undefined}>
          {formatNumberWithCurrency(data.finalAmount, currency)}
        </span>
      ),
      ...sortColumnByNumber('finalAmount'),
    },
    {
      width: 200,
      key: 'note',
      title: t('note'),
      dataIndex: 'note',
      ...sortColumnByStringField('note'),
    },
    {
      key: 'receiptID',
      title: t('receiptID'),
      dataIndex: 'receiptSeqId',
      ...sortColumnByStringField('receiptSeqId'),
    },
  ];

  return (
    <CustomTable
      scroll={{ x: 900 }}
      columns={COLUMNS}
      dataSource={filteredTable}
      onSearchTermChange={setSearchTerm}
      defaultSearchTerm={debouncedSearchTerm}
    />
  );
};

export default AdjustmentHistoryTable;
