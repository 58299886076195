import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { COUNTRY_RATES_API } from '../../api/countryRates';
import { fetchRateAudit } from '../../api/rateAudits';
import CountryRateAuditHistoryModal from '../../components/Modal/CountryRateAuditHistoryModal';
import DeleteCountryRateConfirmModal from '../../components/Modal/DeleteCountryRateConfirmModal';
import CountryRatesTable from '../../components/Table/CountryRatesTable';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import { formatPageTitle } from '../../utils/common';
import { normalizeCountryRatesSchema } from '../../utils/countryRates';

const CountryRatesView = props => {
  const { t, history } = props;

  const queryClient = useQueryClient();
  const [rateToAudit, setRateToAudit] = useState();
  const [rateAudits, setRateAudits] = useState([]);
  const [isLoadingRateAudits, setIsLoadingRateAudits] = useState(false);
  const [isRateAuditModalOpen, setIsRateAuditModalOpen] = useState(false);
  const [rateToDelete, setRateToDelete] = useState();

  const loadRateAudits = async id => {
    setIsLoadingRateAudits(true);

    try {
      const rates = await fetchRateAudit(id);
      setRateAudits(rates);
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsLoadingRateAudits(false);
  };

  const {
    query: countryRatesQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery({
    staleTime: Infinity,
    placeholderData: {
      totalCount: 0,
      documents: [],
    },
    queryKey: ['fetchCountryRates'],
    queryFn: () =>
      COUNTRY_RATES_API.fetchCountryRates(
        undefined,
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    select: ({ documents, totalCount }) => ({
      documents: documents.map(rate => normalizeCountryRatesSchema(rate)),
      totalCount,
    }),
  });

  const countryRateDeleteMutation = useMutation(
    rateId => COUNTRY_RATES_API.deleteCountryRate(rateId),
    {
      onSuccess: () => {
        setRateToDelete();
        queryClient.invalidateQueries(['fetchCountryRates'], { exact: false });
        Toast({
          type: 'open',
          message: t('countryRateDeleteSuccess'),
        });
        history.push(INTERNAL_LINKS.COUNTRY_RATES);
      },
      onError: error => {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('countryRateDeleteError'),
          });
        });
      },
    },
  );

  useEffect(() => {
    if (rateToAudit) {
      loadRateAudits(rateToAudit);
    }
  }, [rateToAudit]);

  return (
    <PageContainer
      title={t('supportedCountryRates')}
      pageActionText={t('createNewRate')}
      onPageActionClick={() => history.push(INTERNAL_LINKS.CREATE_COUNTRY_RATES)}
    >
      <Helmet>
        <title>{formatPageTitle('Supported Country Rates')}</title>
      </Helmet>

      <CountryRatesTable
        t={t}
        loading={
          countryRatesQuery.isFetching || countryRateDeleteMutation.isLoading || isLoadingRateAudits
        }
        dataSource={countryRatesQuery.data.documents || []}
        onEditRateClick={id => history.push(`${INTERNAL_LINKS.UPDATE_COUNTRY_RATES}/${id}`)}
        onDeleteRateClick={id => setRateToDelete(id)}
        onRateHistoryView={id => {
          if (id !== rateToAudit) {
            setRateAudits([]);
            setRateToAudit(id);
          }
          setIsRateAuditModalOpen(true);
        }}
        onChange={({ current }) => handlePageChange(current)}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />

      <CountryRateAuditHistoryModal
        t={t}
        visible={isRateAuditModalOpen}
        dataSource={rateAudits}
        onCancel={() => setIsRateAuditModalOpen(false)}
      />

      <DeleteCountryRateConfirmModal
        t={t}
        visible={!!rateToDelete}
        loading={countryRateDeleteMutation.isLoading}
        onConfirm={() => countryRateDeleteMutation.mutateAsync(rateToDelete)}
        onCancel={() => setRateToDelete()}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(CountryRatesView));
