import React, { useCallback, useEffect, useState } from 'react';

import { handleApiErrors } from '../../../api/axiosInstance';
import { fetchUserTripsAppVersion } from '../../../api/user';
import { momentFormat } from '../../../utils/common';
import { CustomTable } from '../..';

const MobileAppVersionsTable = props => {
  const { t, userID, mobileOS, ...rest } = props;

  const [dataSource, setDataSource] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const loadMobileAppHistory = useCallback(async () => {
    setIsLoadingData(true);

    try {
      const appVersions = await fetchUserTripsAppVersion(userID, mobileOS);
      setDataSource(appVersions);
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsLoadingData(false);
  }, [userID, mobileOS]);

  useEffect(() => {
    loadMobileAppHistory();
  }, [loadMobileAppHistory]);

  const COLUMNS = [
    {
      title: t('mobileAppVersions'),
      dataIndex: 'mobileAppVersion',
    },
    {
      title: t('lastTripRecorded'),
      dataIndex: 'lastUsed',
      render: lastUsed => momentFormat(lastUsed, 'MMMM DD, YYYY'),
    },
  ];

  return (
    <CustomTable
      {...rest}
      loading={isLoadingData}
      showSearchInput={false}
      dataSource={dataSource}
      columns={COLUMNS}
      pagination={{ defaultPageSize: 5, showSizeChanger: false }}
    />
  );
};

export default MobileAppVersionsTable;
