import React from 'react';
import PropTypes from 'prop-types';
// the hoc
import { withNamespaces } from 'react-i18next';
import { CloseCircleFilled } from '@ant-design/icons';

import './style.scss';

class TagItem extends React.Component {

  render() {
    const { text, t, onClose } = this.props

    return (
      <div className="tag-item">
        <span className="tag-item-text">{t(text)}</span>
        <div onClick={onClose}>
          <CloseCircleFilled className="tag-item-close" />
        </div>
      </div>
    )
  }
}

TagItem.propTypes = {
  text: PropTypes.string,
  onClose: PropTypes.func,
}

TagItem.defaultProps = {
  text: 'tag',
  onClose: () => { }
}

export default withNamespaces()(TagItem);