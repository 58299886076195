import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from '../../components';
import { INTERNAL_LINKS } from '../../enum';
import { formatPageTitle } from '../../utils/common';
import ComplianceStatusView from './compliance-status';
import ComplianceStatusDetailsView from './compliance-status-details';

const UserProgramComplianceView = () => {
  return (
    <>
      <Helmet>
        <title>{formatPageTitle('User Program Compliance')}</title>
      </Helmet>

      <Switch>
        <PrivateRoute
          exact
          path={INTERNAL_LINKS.COMPLIANCE_DETAILS()}
          component={ComplianceStatusDetailsView}
        />
        <PrivateRoute component={ComplianceStatusView} />
      </Switch>
    </>
  );
};

export default UserProgramComplianceView;
