export const FETCH_SYSTEM_ADMINS = 'FETCH_SYSTEM_ADMINS';
export const START_SYSTEM_ADMINS_LOADER = 'START_SYSTEM_ADMINS_LOADER';
export const STOP_SYSTEM_ADMINS_LOADER = 'STOP_SYSTEM_ADMINS_LOADER';
export const UPDATE_SYSTEM_ADMINS = 'UPDATE_SYSTEM_ADMINS';

export const FETCH_COUNTRY_RATES = 'FETCH_ADMIN_COUNTRY_RATES';
export const START_COUNTRY_RATES_LOADER = 'START_ADMIN_COUNTRY_RATES_LOADER';
export const UPDATE_COUNTRY_RATES = 'UPDATE_ADMIN_COUNTRY_RATES';
export const STOP_COUNTRY_RATES_LOADER = 'STOP_ADMIN_COUNTRY_RATES_LOADER';

export const FETCH_COUNTRY_CODE_MAPPINGS = 'FETCH_COUNTRY_CODE_MAPPINGS';
export const UPDATE_COUNTRY_CODE_MAPPINGS = 'UPDATE_COUNTRY_CODE_MAPPINGS';
export const UPDATE_COUNTRY_CODE_MAPPINGS_BY_ID = 'UPDATE_COUNTRY_CODE_MAPPINGS_BY_ID';

export const FETCH_GLOBAL_SETTINGS = 'FETCH_GLOBAL_SETTINGS';
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS';

export function fetchSystemAdmins() {
  return {
    type: FETCH_SYSTEM_ADMINS,
  };
}

export function startSystemAdminsLoader() {
  return {
    type: START_SYSTEM_ADMINS_LOADER,
  };
}

export function stopSystemAdminsLoader() {
  return {
    type: STOP_SYSTEM_ADMINS_LOADER,
  };
}

export function updateSystemAdmins(systemAdmins) {
  return {
    type: UPDATE_SYSTEM_ADMINS,
    data: { systemAdmins },
  };
}

export function emitFetchCountryCodeMapping() {
  return {
    type: FETCH_COUNTRY_CODE_MAPPINGS,
  };
}

export function updateCountryCodeMapping(countryCodeMappings) {
  return {
    type: UPDATE_COUNTRY_CODE_MAPPINGS,
    data: { countryCodeMappings: countryCodeMappings.sort((a, b) => a.name.localeCompare(b.name)) },
  };
}

export function updateCountryCodeMappingByID(id, countryCodeMapping) {
  return {
    type: UPDATE_COUNTRY_CODE_MAPPINGS_BY_ID,
    data: { id, countryCodeMapping },
  };
}

export function emitFetchGlobalSettings() {
  return {
    type: FETCH_GLOBAL_SETTINGS,
  };
}

export function emitUpdateGlobalSettings(globalSettings) {
  return {
    type: UPDATE_GLOBAL_SETTINGS,
    data: { globalSettings },
  };
}
