import { Col, Form, Radio as AntdRadio, Row } from 'antd';
import React from 'react';

import Radio from '../../../components/Radio';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';

const DefaultMappingSettingForm = props => {
  const { t } = props;
  return (
    <Form>
      <Form.Item>
        <AntdRadio.Group value="mapbox">
          <Row gutter={[0, 8]}>
            <Col flex="100%">
              <Radio value="mapbox">Mapbox</Radio>
            </Col>
          </Row>

          <Row gutter={[16]}>
            <Col flex="100%">
              <Radio value="leaflet" disabled>
                Leaflet
              </Radio>
            </Col>
          </Row>
        </AntdRadio.Group>
      </Form.Item>

      <SubmitCancelButtonGroup isForm hideCancel submitText={t('Save')} />
    </Form>
  );
};

export default DefaultMappingSettingForm;
