import i18n from '../../i18n';

const csvLabels = [
  {
    label: i18n.t('firstName'),
    fieldName: 'firstName',
  },
  {
    label: i18n.t('lastName'),
    fieldName: 'lastName',
  },
  {
    label: i18n.t('email'),
    fieldName: 'email',
  },
  {
    label: i18n.t('phone'),
    fieldName: 'phone',
  },
  {
    label: i18n.t('status'),
    fieldName: 'status',
  },
  {
    label: i18n.t('role'),
    fieldName: 'role',
  },
  {
    label: i18n.t('Group'),
    fieldName: 'group',
  },
  {
    label: i18n.t('startAddress'),
    fieldName: 'startAddress',
  },
  {
    label: i18n.t('fromAddress'),
    fieldName: 'fromAddress',
  },
  {
    label: i18n.t('journeyStart'),
    fieldName: 'journeyStartTs',
  },
  {
    label: i18n.t('journeyEnd'),
    fieldName: 'journeyEndTs',
  },
  {
    label: i18n.t('reimbursementAmount'),
    fieldName: 'reimbursementAmount',
  },
  {
    label: i18n.t('reimbursementCurrency'),
    fieldName: 'reimbursementCurrency',
  },
  {
    label: i18n.t('receiptStatus'),
    fieldName: 'receiptStatus',
  },
  {
    label: i18n.t('totalAmount'),
    fieldName: 'totalAmount',
  },
  {
    label: i18n.t('currency'),
    fieldName: 'currency',
  },
];

export const userImportCsvHeaders = csvLabels.map(config => {
  return {
    label: config.label,
    key: config.fieldName,
  };
});
