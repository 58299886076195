import { Col, notification, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Text from '../Text';
import classNames from './style.module.scss';

const ValidToastTypes = ['open', 'error', 'success', 'info', 'warning'];

const Toast = (props = {}) => {
  const { type, key, icon: IconComponent, ...rest } = props;

  const textClassNames = !!IconComponent ? classNames.contentWithIcon : '';
  const notificationKey = key || `notification-${Date.now()}`;

  /**
   * Handler to close the Toast based on its unique Id/Key
   */
  const handleNotificationClose = () => {
    notification.close(notificationKey);
  };

  let CTA = null;

  if (type === 'success') {
    CTA = (
      <Text
        variant={'strong'}
        color={'primary'}
        renderAs={'p'}
        size={'sm'}
        onClick={handleNotificationClose}
        className={[classNames.cta, classNames.successCTA].join(' ')}
      >
        OK
      </Text>
    );
  }

  const closeBtn = !CTA ? (
    <img
      key={notificationKey}
      src={'/images/icons/close.svg'}
      alt={'Close toast'}
      width={'12px'}
      height={'12px'}
      className={classNames.closeIcon}
      onClick={handleNotificationClose}
    />
  ) : null;

  const parseMessageContent = content => {
    if (typeof content === 'string') {
      return content.split('\n');
    } else {
      return content;
    }
  };

  const messages = parseMessageContent(props.message);
  const descriptions = parseMessageContent(props.description);

  const renderTitleMessage = msg => {
    if (!msg) return null;
    return (
      <Text
        variant={'strong'}
        renderAs={'p'}
        size={'sm'}
        className={[textClassNames, classNames.title].join(' ')}
      >
        {msg}
      </Text>
    );
  };

  const renderDescriptionMessage = msg => {
    if (!msg) return null;
    return (
      <Text
        variant={'p'}
        size={'sm'}
        className={[textClassNames, classNames.description].join(' ')}
      >
        {msg}
      </Text>
    );
  };

  notification.open({
    ...rest,
    key: notificationKey,
    icon: IconComponent ? React.cloneElement(IconComponent, { className: classNames.icon }) : [],
    btn: CTA,
    message: (
      <Row>
        <Col flex={1}>
          {Array.isArray(messages) ? (
            <Row gutter={[0, 8]}>
              {messages.map((msg, i) => (
                <Col flex="100%" key={i}>
                  {renderTitleMessage(msg)}
                </Col>
              ))}
            </Row>
          ) : (
            renderTitleMessage(props.message)
          )}
        </Col>

        {closeBtn}
      </Row>
    ),
    description: Array.isArray(descriptions) ? (
      <Row>
        {descriptions.map((msg, i) => (
          <Col flex="100%" key={i}>
            {renderDescriptionMessage(msg)}
          </Col>
        ))}
      </Row>
    ) : (
      renderDescriptionMessage(props.description)
    ),
    className: [classNames.notification, classNames[type]].filter(Boolean).join(' '),
    closeIcon: [null],
  });
};

Toast.defaultProps = {
  type: PropTypes.oneOf(ValidToastTypes).isRequired,
};

export default Toast;
