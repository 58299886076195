import { filter, first } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { RATES_API } from '../../api/rates';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';

const useDefaultRate = () => {
  const manualRefechAttempts = useRef(0);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const selectDefaultRate = useCallback(
    ({ documents }) => first(filter(documents, { active: true })),
    [],
  );

  const defaultRateQuery = useQuery({
    staleTime: Infinity,
    placeholderData: {},
    enabled: !!currentCompany?._id,
    queryKey: ['fetchCompanyRates', currentCompany?._id, null, '', '', '_defaultRate_'],
    queryFn: () => RATES_API.fetchCompanyRates(currentCompany._id, { defaultRate: true }),
    select: selectDefaultRate,
  });

  useEffect(() => {
    if (defaultRateQuery.isFetched) {
      if (!defaultRateQuery.data && manualRefechAttempts.current <= 3) {
        defaultRateQuery.refetch();
        manualRefechAttempts.current += 1;
      } else if (!!defaultRateQuery.data) {
        manualRefechAttempts.current = 0;
      }
    }
    // eslint-disable-next-line
  }, [defaultRateQuery.isFetched, defaultRateQuery.data]);

  return defaultRateQuery;
};

export default useDefaultRate;
