import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { emitFetchContacts } from '../stores/actions/realtor';
import { getUserFullName } from '../utils/common';
import { sortColumnByStringField } from '../utils/tables';

const useRealtorContacts = (contactsToRemove = []) => {
  const dispatch = useDispatch();
  const { isFetchingContacts, contacts } = useSelector(store => store.realtor);

  const contactOptions = useMemo(() => {
    return (contacts || [])
      .filter(contact => !contactsToRemove.includes(contact._id))
      .map(contact => ({
        value: contact._id,
        email: contact.email,
        label: getUserFullName(contact),
      }))
      .sort(sortColumnByStringField('label').sorter);
  }, [contacts, contactsToRemove]);

  useEffect(() => {
    dispatch(emitFetchContacts());
    // eslint-disable-next-line
  }, []);

  return { isFetchingContacts, contacts, contactOptions };
};

export default useRealtorContacts;
