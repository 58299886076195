import { Col, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { COMPANY_API } from '../../api/company';
import useInfiniteQuery from '../../hooks/queries/useInfiniteQuery';
import useDebouncedState from '../../hooks/useDebouncedState';
import { StorageUtils } from '../../utils/sessionStorage';
import Select from '../Select';

const CompanyLookupSelect = props => {
  const { t, showAllOption, onChange, notFoundContent, value, disabled, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState(null, 500);
  const [initialCount, setInitialCount] = useState();
  const [isLookingForMoreOptions, setIsLookingForMoreOptions] = useState(true);

  const { query: companiesQuery, flatPages } = useInfiniteQuery(
    {
      queryKey: ['getAllCompanies', searchTerm],
      queryFn: ({ pageParam = 1 }) =>
        COMPANY_API.getAllCompanies({ searchTerm, pageNumber: pageParam, pageSize: 25 }),
      onSuccess: ({ pages }) => {
        if (
          Array.isArray(pages) &&
          typeof pages[0]?.totalCount !== 'undefined' &&
          typeof initialCount === 'undefined'
        ) {
          setInitialCount(pages[0]?.totalCount);
        }
      },
    },
    'totalCount',
    'documents',
  );

  const options = useMemo(() => {
    let optionsArray = flatPages.map(company => ({
      value: company._id,
      label: company.name,
    }));

    if (showAllOption) {
      optionsArray = [{ value: null, label: t('All') }, ...optionsArray];
    }

    return optionsArray;
  }, [t, showAllOption, flatPages]);

  const handleChange = useCallback(
    (val, option) => {
      if (typeof onChange === 'function') {
        onChange(
          val,
          flatPages.find(company => company._id === val),
        );
        if (typeof option === 'undefined') {
          StorageUtils.lastCompanyLookupSelection.remove();
        } else {
          StorageUtils.lastCompanyLookupSelection.set(option);
        }
      }
      setSearchTerm();
    },
    [setSearchTerm, flatPages, onChange],
  );

  const handleOptionsScroll = useCallback(
    e => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

      if (bottom && !companiesQuery.isFetchingNextPage && companiesQuery.hasNextPage) {
        companiesQuery.fetchNextPage();
      }
    },
    [companiesQuery],
  );

  const optionsWithoutNullValues = useMemo(() => {
    return options.filter(opt => !!opt.value);
  }, [options]);

  useEffect(() => {
    if (value) {
      if (!!options?.length) {
        const valueInOptions = !!options.find(option => option.value === value);
        if (!valueInOptions && !companiesQuery.isFetching && companiesQuery.hasNextPage) {
          setIsLookingForMoreOptions(true);
          companiesQuery.fetchNextPage();
        } else if (!companiesQuery.isFetching && (!companiesQuery.hasNextPage || valueInOptions)) {
          setIsLookingForMoreOptions(false);
        }
      }
    } else {
      setIsLookingForMoreOptions(false);
    }
  }, [value, options, companiesQuery]);

  return (
    <Select
      {...rest}
      resetOnNoMatch={false}
      onChange={handleChange}
      value={
        (value && !optionsWithoutNullValues.length) || isLookingForMoreOptions ? undefined : value
      }
      fullWidth
      showSearch
      allowClear={false}
      disabled={disabled || isLookingForMoreOptions}
      loading={companiesQuery.isFetchingNextPage}
      options={options}
      onSearch={setSearchTerm}
      placeholder={isLookingForMoreOptions ? t('searching...') : t('companyName')}
      onPopupScroll={handleOptionsScroll}
      notFoundContent={
        companiesQuery.isFetching ? (
          <Row justify="center" align="middle">
            <Col>
              <Spin size="default" />
            </Col>
          </Row>
        ) : initialCount === 0 ? (
          /* message to display only if the initial search has not results,
           hence any other search term queries will be return the same */
          notFoundContent
        ) : undefined
      }
    />
  );
};

export default CompanyLookupSelect;
