export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE_ADD_DATA = 'CLEAR_PROFILE_ADD_DATA';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SKIP_HOME_ADDRESS_VERIFICAITON = 'SKIP_HOME_ADDRESS_VERIFICAITON';

export function updateProfile(profile) {
  return {
    type: UPDATE_PROFILE,
    data: { profile },
  };
}

export function emitClearProfileAdditionalData() {
  return { type: CLEAR_PROFILE_ADD_DATA };
}

export function clearProfileState() {
  return {
    type: USER_LOGOUT,
  };
}

export const emitSkipHomeAddressVerification = () => {
  return { type: SKIP_HOME_ADDRESS_VERIFICAITON };
};
