import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Tag from '../../components/Tag';

import { INTERNAL_LINKS } from '../../enum';

const FreeTrialBanner = props => {
  const { t, freeTrial } = props;
  const { trialEndDate } = freeTrial;

  const remainingDays = moment(new Date(trialEndDate)).diff(
    moment(new Date()).subtract(1, 'days'),
    'days',
  );

  if (remainingDays <= 0) {
    return (
      <Link to={INTERNAL_LINKS.PRICE_PLANS}>
        <Tag variant="red">{t('FreeTrialEnded')}</Tag>
      </Link>
    );
  }

  if (remainingDays <= 5) {
    return (
      <Link to={INTERNAL_LINKS.PRICE_PLANS}>
        <Tag variant="red">{t('RemainingFreeTrial', { count: remainingDays })}</Tag>
      </Link>
    );
  }

  return null;
};

export default withNamespaces()(FreeTrialBanner);
