import i18n from '../i18n';
import { STATUS_LIST } from './index';

export default () => {
  const TripActions = [
    {
      text: i18n.t('approve'),
      value: 'approve',
    },
    {
      text: i18n.t('reject'),
      value: 'reject',
    },
  ];

  const SelectOptions = [
    {
      label: i18n.t('awaitingApproval'),
      value: STATUS_LIST().Status.SUBMITTED,
    },
    {
      label: i18n.t('approved'),
      value: STATUS_LIST().Status.APPROVED,
    },
    // {
    //   label: 'In Progress',
    //   value: STATUS_LIST().Status.IN_PROGRESS,
    // },
    {
      label: i18n.t('pending'),
      value: STATUS_LIST().Status.PENDING,
    },
    {
      label: i18n.t('moreInfo'),
      value: STATUS_LIST().Status.MORE_INFO,
    },
    {
      label: i18n.t('denied'),
      value: STATUS_LIST().Status.DENIED,
    },
    {
      label: i18n.t('Paid'),
      value: STATUS_LIST().Status.PAID,
    },
  ];

  const SelectionOptionsWithACH = [
    ...SelectOptions,
    {
      label: STATUS_LIST().StatusTagColorConfig[STATUS_LIST().Status.ACH_PENDING].label,
      value: STATUS_LIST().Status.ACH_PENDING,
    },
    {
      label: STATUS_LIST().StatusTagColorConfig[STATUS_LIST().Status.ACH_FAILED].label,
      value: STATUS_LIST().Status.ACH_FAILED,
    },
    {
      label: STATUS_LIST().StatusTagColorConfig[STATUS_LIST().Status.ACH_CANCELLED].label,
      value: STATUS_LIST().Status.ACH_CANCELLED,
    },
  ];

  return {
    TripActions,
    SelectOptions,
    SelectionOptionsWithACH,
  };
};
