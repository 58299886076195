import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import AchAddFundsModal from '../../components/Modal/AchAddFundsModal';
import AchWithdrawFundsModal from '../../components/Modal/AchWithdrawFundsModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import KliksAccountTransactionHistoryTable from '../../components/Table/KliksAccountTransactionHistoryTable';
import Text from '../../components/Text';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import usePaginatedDwollaResourcesQuery from '../../hooks/queries/usePaginatedDwollaResourcesQuery';
import useDwollaAccountBalance from '../../hooks/useDwollaAccountBalance';
import useDwollaCompanyInfo from '../../hooks/useDwollaCompanyInfo';
import useModal from '../../hooks/useModal';
import { formatPageTitle } from '../../utils/common';
import AccountBalanceCard from './components/AccountBalanceCard';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Manage Account Balance')}</title>
  </Helmet>
);

const ManageAccountBalanceView = props => {
  const { t, history } = props;

  const [isAddFundsModalVisible, openAddFundsModal, closeAddFundsModal] = useModal();
  const [isWithdrawFundsModalVisible, openWithdrawFundsModal, closeWithdrawFundsModal] = useModal();

  const {
    isLoadingAccountBalance,
    pendingAccountBalance,
    dwollaAccountBalance,
    addAmountToBalance,
    withdrawAmountFromBalance,
  } = useDwollaAccountBalance(t, {
    onAddAmountSuccess: closeAddFundsModal,
    onWithdrawAmountSuccess: closeWithdrawFundsModal,
  });
  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    query: { isFetching: isLoadingTransactionHistory, data: dwollaTransactionHistory },
  } = usePaginatedDwollaResourcesQuery(['transfer', 'mass-payment']);
  const { companyOtherFundingSource } = useDwollaCompanyInfo();

  if (isLoadingAccountBalance) {
    return (
      <>
        <PageHelmet />
        <SpaceSpinner />
      </>
    );
  }

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('kliksAccountBalance'),
              onClick: () => history.push(INTERNAL_LINKS.ACCOUNT_BALANCE),
            },
            { label: t('manageYourKliksAccountBalance') },
          ]}
        />
      }
    >
      <PageHelmet />

      <AccountBalanceCard
        t={t}
        pendingBalanceAmount={pendingAccountBalance}
        balanceAmount={dwollaAccountBalance?.balance?.value || 0}
        onAddFunds={openAddFundsModal}
        onWithdrawFunds={openWithdrawFundsModal}
        fundingSource={companyOtherFundingSource}
      />

      <br />

      <Text variant="h4">{t('accountTransactionHistory')}</Text>
      <KliksAccountTransactionHistoryTable
        t={t}
        loading={isLoadingTransactionHistory}
        dataSource={dwollaTransactionHistory.documents}
        onChange={({ current }) => handlePageChange(current)}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />

      <AchAddFundsModal
        t={t}
        visible={isAddFundsModalVisible}
        isSubmitting={addAmountToBalance.isLoading}
        currentBalance={dwollaAccountBalance?.balance?.value}
        onCancel={closeAddFundsModal}
        onSubmit={({ amount }) =>
          addAmountToBalance.mutate({
            fundingSourceId: companyOtherFundingSource.fundingSourceId,
            amount,
          })
        }
      />

      <AchWithdrawFundsModal
        t={t}
        visible={isWithdrawFundsModalVisible}
        isSubmitting={withdrawAmountFromBalance.isLoading}
        currentBalance={dwollaAccountBalance?.balance?.value}
        onCancel={closeWithdrawFundsModal}
        onSubmit={({ amount }) =>
          withdrawAmountFromBalance.mutate({
            fundingSourceId: companyOtherFundingSource.fundingSourceId,
            amount,
          })
        }
      />
    </PageContainer>
  );
};

export default withNamespaces(['default', 'dwolla'])(ManageAccountBalanceView);
