import PropTypes from 'prop-types';
import React from 'react';

import { IMAGES } from '../../enum';
import i18n from '../../i18n';
import Text from '../Text';
import classNames from './style.module.scss';

const MapLoader = props => {
  const { text, textProps } = props;

  return (
    <div className={classNames.loaderWrapper}>
      <img src={IMAGES.MAP_PIN_ICON} alt="Map loader" />

      <Text variant="strong" color="secondary" size="xl" renderAs="p" {...textProps}>
        {text}
      </Text>
    </div>
  );
};

MapLoader.propTypes = {
  text: PropTypes.string,
  textProps: PropTypes.shape({}),
};

MapLoader.defaultProps = {
  text: i18n.t('loadingMap...'),
  textProps: {},
};

export default MapLoader;
