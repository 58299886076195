import React, { useMemo } from 'react';

import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { checkIfStringContainsValue } from '../../../utils/common';
import { sortColumnByDateString, sortColumnByStatus } from '../../../utils/tables';
import CustomTable from '../../Table';
import StatusTag from '../../Tag/StatusTag';
import LinkText from '../../Text/LinkText';

const CountriesTable = props => {
  const { t, dataSource, defaultSearchTerm, onEdit, ...rest } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState(defaultSearchTerm);

  useDidUpdateEffect(() => {
    setSearchTerm(defaultSearchTerm);
  }, [defaultSearchTerm]);

  const filteredTable = useMemo(() => {
    let array = dataSource;

    if (debouncedSearchTerm) {
      array = dataSource.filter(company => {
        return (
          checkIfStringContainsValue(company.name, debouncedSearchTerm) ||
          checkIfStringContainsValue(company.currency, debouncedSearchTerm) ||
          checkIfStringContainsValue(company.distance, debouncedSearchTerm) ||
          checkIfStringContainsValue(company.fuel, debouncedSearchTerm)
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  const COLUMNS = [
    {
      key: 'name',
      dataIndex: 'name',
      title: t('Name'),
      ...sortColumnByDateString('name'),
    },
    {
      key: 'currency',
      dataIndex: 'currencyCode',
      title: t('currency'),
      ...sortColumnByDateString('currencyCode'),
    },
    {
      key: 'distance',
      dataIndex: 'distanceLong',
      title: t('Distance'),
      ...sortColumnByDateString('distanceLong'),
    },
    {
      key: 'fuel',
      dataIndex: 'fuel',
      title: t('fuel'),
      ...sortColumnByDateString('fuel'),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('status'),
      render: status => <StatusTag status={status} />,
      ...sortColumnByStatus('status'),
    },
    {
      width: 150,
      key: 'actions',
      title: t('actions'),
      render: (data, actions) => <LinkText onClick={() => onEdit(data._id)}>{t('Edit')}</LinkText>,
    },
  ];

  return (
    <CustomTable
      {...rest}
      rowKey={data => data._id}
      scroll={{ x: 900 }}
      columns={COLUMNS}
      showSearchInput={false}
      dataSource={filteredTable}
      onSearchTermChange={setSearchTerm}
      defaultSearchTerm={debouncedSearchTerm}
    />
  );
};

export default CountriesTable;
