import { Col, Row } from 'antd';
import React from 'react';
import { Switch } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import SpaceSpinner from '../../components/SpaceSpinner';
import Spinner from '../../components/Spinner';
import PageContainer from '../PageContainer';
import classNames from './style.module.scss';

const PageContainerWithFadedLeftNavigation = props => {
  const {
    pageContainerProps,
    isPageLoading,
    navRoutes,
    children,
    fullWidthPage,
    footer,
    orientation,
    ...rest
  } = props;

  if (isPageLoading) {
    return (
      <PageContainer {...pageContainerProps} {...rest}>
        <SpaceSpinner />
      </PageContainer>
    );
  }

  return (
    <>
      <PageContainer {...pageContainerProps} {...rest}>
        <Row
          className={[
            classNames.contentWrapper,
            orientation ? classNames[`${orientation}Page`] : undefined,
          ]
            .filter(Boolean)
            .join(' ')}
        >
          <Col>
            <nav className={classNames.sideNav}>
              {navRoutes.map(
                ({ to, hidden, label, onClick, ...routeRest }) =>
                  !hidden && (
                    <NavHashLink
                      exact
                      key={to}
                      className={classNames.link}
                      to={to}
                      {...routeRest}
                      onClick={() => {
                        const mainContainer = document.querySelector('.main-app-container');
                        if (mainContainer) mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
                        if (typeof onClick === 'function') onClick();
                      }}
                    >
                      {label}
                    </NavHashLink>
                  ),
              )}
            </nav>
          </Col>

          <Col flex="1" style={{ maxWidth: fullWidthPage ? '100%' : 832 }}>
            <Switch>
              <React.Suspense
                fallback={
                  <Row justify="center" align="middle" style={{ minHeight: 300 }}>
                    <Col>
                      <Spinner />
                    </Col>
                  </Row>
                }
              >
                {children}
              </React.Suspense>
            </Switch>
          </Col>
        </Row>
      </PageContainer>

      {footer && <Row className={classNames.pageFooter}>{footer}</Row>}
    </>
  );
};

PageContainerWithFadedLeftNavigation.defaultProps = {
  isPageLoading: false,
  fullWidthPage: false,
  pageContainerProps: {},
  navRoutes: [],
  footer: null,
};

export default PageContainerWithFadedLeftNavigation;
