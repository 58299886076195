import { Form } from 'antd';
import React from 'react';

import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { setModalFullscreenWidth } from '../../../utils/common';
import {
  CONFIRM_PASSWORD_FORM_RULE,
  ERROR_MESSAGE,
  NEW_PASSWORD_FORM_RULE,
} from '../../../utils/constants';
import FormItem from '../../Form/FormItem';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import TextInput from '../../TextInput';
import GenericModal from '../GenericModal';

const ChangePasswordModal = props => {
  const { t, visible, onCancel, onSave, requireCurrentPassword, isChangingPassword } = props;

  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(onSave);
  };

  useDidUpdateEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <GenericModal
      centered
      visible={visible}
      onCancel={onCancel}
      title={t('changePassword')}
      closable={!isChangingPassword}
      width={setModalFullscreenWidth(500)}
      footer={
        <SubmitCancelButtonGroup
          disabled={isChangingPassword}
          loading={isChangingPassword}
          onCancel={onCancel}
          onSubmit={handleSubmit}
          submitText={t('Save')}
        />
      }
    >
      <Form form={form} disabled={isChangingPassword}>
        <FormItem
          name="currentPassword"
          label={t('currentPassword')}
          validateTrigger={['onSubmit']}
          hidden={!requireCurrentPassword}
          rules={[{ required: requireCurrentPassword, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <TextInput password autoComplete="current-password" />
        </FormItem>

        <FormItem
          name="password"
          label={t('newPassword')}
          validateTrigger={['onSubmit']}
          rules={NEW_PASSWORD_FORM_RULE()}
        >
          <TextInput password autoComplete="new-password" />
        </FormItem>

        <FormItem
          name="confirmPassword"
          label={t('confirmPassword')}
          validateTrigger={['onSubmit']}
          rules={CONFIRM_PASSWORD_FORM_RULE(form)}
          extra={ERROR_MESSAGE().INVALID_PASSWORD}
        >
          <TextInput password autoComplete="new-password" />
        </FormItem>
      </Form>
    </GenericModal>
  );
};

export default ChangePasswordModal;
