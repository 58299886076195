import React from 'react';
import PropTypes from 'prop-types';

import Text from './index';

import classNames from './style.module.scss';

/**
 * Variant of the Text component with CSS animations to behave as Links
 *
 * @param {object} props - Component props.
 */
const LinkText = props => {
  const { icon, className, children } = props;

  return (
    <Text
      renderAs="span"
      {...props}
      color="primary"
      className={[classNames.linkText, icon ? classNames.linkWithIcon : undefined, className]
        .filter(Boolean)
        .join(' ')}
    >
      {icon && <span className={classNames.icon}>{icon}</span>} {children}
    </Text>
  );
};

LinkText.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default LinkText;
