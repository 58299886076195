export const SETUP_FAVR_VEHICLES = 'SETUP_FAVR_VEHICLES';
export const COMPLETE_FAVR_VEHICLES_SETUP = 'COMPLETE_FAVR_VEHICLES_SETUP';

export const TOGGLE_FAVR_YEARS_LOADER = 'TOGGLE_FAVR_YEARS_LOADER';
export const TOGGLE_FAVR_MAKES_LOADER = 'TOGGLE_FAVR_MAKES_LOADER';
export const TOGGLE_FAVR_MODELS_LOADER = 'TOGGLE_FAVR_MODELS_LOADER';

export const GET_FAVR_VEHICLE_YEARS = 'GET_FAVR_VEHICLE_YEARS';
export const UPDATE_FAVR_VEHICLE_YEARS = 'UPDATE_FAVR_VEHICLE_YEARS';

export const GET_FAVR_VEHICLE_MAKES = 'GET_FAVR_VEHICLE_MAKES';
export const UPDATE_FAVR_VEHICLE_MAKES = 'UPDATE_FAVR_VEHICLE_MAKES';

export const GET_FAVR_VEHICLE_MODELS = 'GET_FAVR_VEHICLE_MODELS';
export const UPDATE_FAVR_VEHICLE_MODELS = 'UPDATE_FAVR_VEHICLE_MODELS';

export const toggleYearsLoader = loaderState => {
  return {
    type: TOGGLE_FAVR_YEARS_LOADER,
    data: loaderState,
  };
};

export const toggleMakesLoader = loaderState => {
  return {
    type: TOGGLE_FAVR_MAKES_LOADER,
    data: loaderState,
  };
};

export const toggleModelsLoader = loaderState => {
  return {
    type: TOGGLE_FAVR_MODELS_LOADER,
    data: loaderState,
  };
};

export const setupFavrVehicles = (year, make) => {
  return {
    type: SETUP_FAVR_VEHICLES,
    data: { year, make },
  };
};

export const completeFavrVehiclesSetup = () => {
  return { type: COMPLETE_FAVR_VEHICLES_SETUP };
};

export const emitGetVehicleYears = () => {
  return { type: GET_FAVR_VEHICLE_YEARS };
};

export const setYearData = (yearsRange = []) => {
  return {
    type: UPDATE_FAVR_VEHICLE_YEARS,
    data: { yearsRange },
  };
};

export const emitGetVehicleMakes = year => {
  return { type: GET_FAVR_VEHICLE_MAKES, data: { year } };
};

export const updateVehicleMakes = (year, makesData) => {
  return { type: UPDATE_FAVR_VEHICLE_MAKES, data: { year, makesData } };
};

export const emitGetVehicleModels = (year, make) => {
  return { type: GET_FAVR_VEHICLE_MODELS, data: { year, make } };
};

export const updateVehicleModels = (year, make, modelsData) => {
  return { type: UPDATE_FAVR_VEHICLE_MODELS, data: { year, make, modelsData } };
};
