import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { updateIntegrationCredentials } from '../../../api/company';
import { updateCompanySettings } from '../../../api/company-settings';
import { SETTINGS_API } from '../../../api/settings';
import { Button } from '../../../components';
import Switch from '../../../components/Switch';
import Text from '../../../components/Text';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { saveCompany, updateCompanySettingsStore } from '../../../stores/actions/common';
import { formatPageTitle } from '../../../utils/common';
import {
  selectStoreCompanySettings,
  selectStoreCurrentCompany,
} from '../../../utils/storeSelectors';
import SftpForm from './SftpForm';

const TESTING_CONNECTION = 'testing';
const SUCCESSFUL_CONNECTION = 'success';
const FAILED_CONNECTION = 'failed';

const CompanyPayrollFile = props => {
  const { t } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const companySettings = useSelector(selectStoreCompanySettings);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [testingConnectionStatus, setTestingConnectionStatus] = useState();
  const [isSftpEnabled, setIsSftpEnabled] = useState(companySettings.sendPayrollFileViaSftp);

  const handleSftpSettingsUpdate = async sendPayrollFileViaSftp => {
    setIsSubmitting(true);

    try {
      const updatedSettings = await updateCompanySettings(currentCompany._id, {
        settings: [{ name: 'sendPayrollFileViaSftp', value: sendPayrollFileViaSftp }],
      });
      dispatch(updateCompanySettingsStore(updatedSettings));
    } catch (error) {
      handleApiErrors(error.response);
      setIsSftpEnabled(false);
    }

    setIsSubmitting(false);
  };

  const handleUpdateSftpConfig = async values => {
    setIsSubmitting(true);

    try {
      await updateIntegrationCredentials(currentCompany._id, values);
      dispatch(saveCompany({ sftp: values }));
      Toast({
        type: 'success',
        message: t('updateSftpConfigSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsSubmitting(false);
  };

  const handleSftSettingTest = async () => {
    form.validateFields().then(async sftpConfig => {
      setTestingConnectionStatus(TESTING_CONNECTION);

      try {
        await SETTINGS_API.testSftpConnection({
          hostName: sftpConfig.host,
          username: sftpConfig.username,
          password: sftpConfig.password,
          port: sftpConfig.port,
        });
        setTestingConnectionStatus(SUCCESSFUL_CONNECTION);
      } catch (error) {
        if (error?.response?.status !== 503) handleApiErrors(error.response);

        setTestingConnectionStatus(FAILED_CONNECTION);
      }
    });
  };

  useEffect(() => {
    if (companySettings.sendPayrollFileViaSftp !== isSftpEnabled) {
      handleSftpSettingsUpdate(isSftpEnabled);
    }
    // eslint-disable-next-line
  }, [companySettings.sendPayrollFileViaSftp, isSftpEnabled]);

  return (
    <PageContainer
      noSurroundingSpace
      title={
        <Text renderAs="p" variant="b" size="sm" style={{ marginBottom: 16 }}>
          {t('payrollFile')}
        </Text>
      }
    >
      <Helmet>
        <title>{formatPageTitle('Payroll File')}</title>
      </Helmet>

      <Row align="middle" gutter={20}>
        <Col>
          <Text>{t('sendPayrollFileViaSftp')}</Text>
        </Col>

        <Col>
          <Switch
            disabled={isSubmitting || testingConnectionStatus === TESTING_CONNECTION}
            defaultChecked={isSftpEnabled}
            onChange={checked => setIsSftpEnabled(checked)}
          />
        </Col>
      </Row>

      <br />

      <SftpForm
        t={t}
        formInstance={form}
        initialValues={currentCompany.sftp}
        disabledForm={!isSftpEnabled || testingConnectionStatus === TESTING_CONNECTION}
        isSubmitting={isSubmitting}
        onSubmit={handleUpdateSftpConfig}
      />

      {(isSftpEnabled || currentCompany.sftp) && (
        <Row align="middle" gutter={[20, 20]} style={{ marginTop: 'auto' }}>
          <Col>
            <Button
              disabled={!isSftpEnabled || isSubmitting}
              loading={testingConnectionStatus === TESTING_CONNECTION}
              onClick={handleSftSettingTest}
            >
              {t('testSftpConnection')}
            </Button>
          </Col>

          <Col>
            {testingConnectionStatus === TESTING_CONNECTION && (
              <Text>{t('testingConnection')}</Text>
            )}
            {testingConnectionStatus === SUCCESSFUL_CONNECTION && (
              <Text color="primary">{t('connectionSuccessful')}</Text>
            )}
            {testingConnectionStatus === FAILED_CONNECTION && (
              <Text color="red">{t('connectionFailed')}</Text>
            )}
          </Col>
        </Row>
      )}
    </PageContainer>
  );
};

export default CompanyPayrollFile;
