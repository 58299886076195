import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import PropTypes from 'prop-types';

import './style.scss';

/**
 * Custom Checkbox component based on the Ant Design Checkbox
 * but following Kliks style guide and branding
 *
 * @param {object} props - Component props
 */
const Checkbox = props => {
  const { children, size, className, onChange, ...rest } = props;

  const newClassName = ['custom-checkbox', `checkbox-${size}`, className].filter(Boolean).join(' ');

  const handleChange = e => {
    e.stopPropagation();
    if (typeof onChange === 'function') onChange(e);
  };

  return (
    <AntdCheckbox className={newClassName} onChange={handleChange} {...rest} intermediate>
      {children}
    </AntdCheckbox>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  className: '',
  size: 'small',
  onChange: () => undefined,
};

export default Checkbox;
