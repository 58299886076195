import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue } from '../../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../../utils/numbers';
import { sortColumnByStringField } from '../../../utils/tables';
import { hasTripsWithDeductedCommuteMiles } from '../../../utils/trips';
import { CustomTable } from '../../index';
import LinkText from '../../Text/LinkText';

/**
 * A Table to display the Receipts data
 */
const MonthlyTaxReportReceiptsDetails = props => {
  const { t, dataSource, receiptsHidden, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(receipt => {
        return (
          checkIfStringContainsValue(receipt.seqId, searchTerm) ||
          checkIfStringContainsValue(receipt.type, searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      width: 50,
      title: t('ID'),
      dataIndex: receiptsHidden ? ['reimbursement', 'seqId'] : 'seqId',
      key: 'seqId',
      render: (seqId, data) => (
        <Link
          to={`${receiptsHidden ? INTERNAL_LINKS.REIMBURSEMENT : INTERNAL_LINKS.RECEIPTS}/${
            data._id
          }`}
        >
          <LinkText variant="strong" size="sm">
            {seqId || 'Receipt'}
          </LinkText>
        </Link>
      ),
      ...sortColumnByStringField('seqId'),
    },
    {
      width: 75,
      title: t('rbId'),
      key: 'reimbursementID',
      dataIndex: ['reimbursement', 'seqId'],
      ...sortColumnByStringField('reimbursementId'),
    },
    {
      width: 100,
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      render: type => capitalize(type),
      ...sortColumnByStringField('type'),
    },
    {
      width: 100,
      align: 'right',
      title: t('totalAmount'),
      dataIndex: 'totalAmount',
      key: 'amount',
      sorter: (a, b) => {
        const adjustmentA = a.adjustment ? a.adjustment.amount : 0;
        const adjustmentB = b.adjustment ? b.adjustment.amount : 0;

        const totalA = parseFloat(a.totalAmount) + parseFloat(adjustmentA);
        const totalB = parseFloat(b.totalAmount) + parseFloat(adjustmentB);

        return totalA - totalB;
      },
      render: (actions, data) => {
        const adjustment = data.adjustment ? data.adjustment.amount : 0;

        return formatCurrencyToCompanyLocale(
          parseFloat(adjustment) + parseFloat(data.totalAmount),
          2,
        );
      },
    },
  ];

  return (
    <CustomTable
      onSearchTermChange={setSearchTerm}
      {...rest}
      onRow={row => ({
        leftBorderColor: hasTripsWithDeductedCommuteMiles(row.trips) ? 'danger' : '',
      })}
      dataSource={filteredDataSource}
      rowKey={data => data._id}
      columns={COLUMNS}
      scroll={{ x: 970 }}
    />
  );
};

MonthlyTaxReportReceiptsDetails.defaultProps = {
  receiptsHidden: false,
};

MonthlyTaxReportReceiptsDetails.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  loggedInUserProfile: PropTypes.shape({}).isRequired,
  companyGroups: PropTypes.arrayOf(PropTypes.shape({})),
  receiptsHidden: PropTypes.bool,
};

export default MonthlyTaxReportReceiptsDetails;
