import { Col, Row, Space, Tabs } from 'antd';
import { kebabCase } from 'lodash';
import queryString from 'querystring';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { impersonateUser, stopImpersonatingUser } from '../../api/auth';
import { handleApiErrors } from '../../api/axiosInstance';
import { COMPANY_API, deleteCompany, getCompanyByID } from '../../api/company';
import { fetchAppConfigLog, fetchCurrentUserDetails, sendAppConfigToLog } from '../../api/user';
import { Button } from '../../components';
import Box from '../../components/Box';
import CompanyDeleteConfirmationModal from '../../components/Modal/CompanyDeleteConfirmationModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import DashboardUsersTable from '../../components/Table/DashboardUsersTable';
import TabsContainer from '../../components/TabsContainer';
import StatusTag from '../../components/Tag/StatusTag';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedAllCompanyUsersQuery from '../../hooks/queries/usePaginatedAllCompanyUsersQuery';
import { emitUpdateGlobalBasename, removeCompany, saveCompany } from '../../stores/actions/common';
import { emitClearProfileAdditionalData, updateProfile } from '../../stores/actions/profile';
import { emitNavigateTo } from '../../stores/actions/router';
import { formatPageTitle, getUserFullName } from '../../utils/common';
import { selectStoreCompanySettings } from '../../utils/storeSelectors';
import { normalizeUserSchema } from '../../utils/users';
import CompanySettings from '../../views/company/company-settings';
import CompanyInfoPageForm from '../../views/company/info-form';
import FavrMileageAdjustmentReport from '../../views/reports/kliks-reports/favr-mileage-adjustment';
import CompanyDetailsSummary from './CompanyDetailsSummary';
import classNames from './style.module.scss';
import UserSettingsSection from './UserSettingsSection';
import UsersMobileReport from './UsersMobileReport';

const CompanyDetailsView = props => {
  const { t, location, history, match } = props;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentCompany } = useSelector(store => store.common);
  const companySettings = useSelector(selectStoreCompanySettings);
  const [usersMobileAppConfigs, setUsersMobileAppConfigs] = useState();

  const DEFAULT_TAB_KEY = 'companyInfo';

  const { activeTab } = queryString.parse(location.search.replace('?', ''));
  const [activePane, setActivePane] = useState(activeTab || DEFAULT_TAB_KEY);
  const [totalUsersCount, setTotalUsersCount] = useState();

  const [isRequestingImpersonation, setIsRequestingImpersonation] = useState(false);
  const [isDeleteCompanyModalVisible, setIsDeleteCompanyModalVisible] = useState(false);
  const [isDeletingCompany, setIsDeletingCompany] = useState(false);
  const [isGettingMobileDeviceConfig, setIsGettingMobileDeviceConfig] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  const handleTabChange = tabKey => {
    setActivePane(tabKey);
    history.replace({ search: `activeTab=${tabKey}` });
  };

  const loadCompanyDetails = async () => {
    const { id } = match.params;
    try {
      const company = await getCompanyByID(id);
      dispatch(saveCompany(company));
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const {
    allUsersQuery,
    allUsersTablePagination,
    allUsersHandleTableSort,
  } = usePaginatedAllCompanyUsersQuery(
    match.params.id,
    { searchTerm },
    {
      onSuccess: ({ totalCount }) => {
        if (typeof totalUsersCount === 'undefined') {
          setTotalUsersCount(totalCount);
        }
      },
    },
  );

  const userCountByStatusQuery = useQuery({
    queryKey: ['fetchUserCountByStatus', currentCompany._id],
    queryFn: () => COMPANY_API.fetchUserCountByStatus(currentCompany._id),
    placeholderData: [],
  });

  const handleStopImpersonation = async () => {
    setIsRequestingImpersonation(true);

    const previousUserResult = await stopImpersonatingUser();
    const normalizedUserSchema = normalizeUserSchema(previousUserResult);
    dispatch(updateProfile(normalizedUserSchema));
    dispatch(emitClearProfileAdditionalData());

    setIsRequestingImpersonation(false);
  };

  const handleImpersonation = async userID => {
    setIsRequestingImpersonation(true);

    const result = await impersonateUser(userID);

    if (result) {
      try {
        const userResult = await fetchCurrentUserDetails();
        const normalizedUserSchema = normalizeUserSchema(userResult);
        dispatch(updateProfile(normalizedUserSchema));
        dispatch(emitUpdateGlobalBasename(kebabCase(currentCompany.name)));
        dispatch(emitNavigateTo(INTERNAL_LINKS.DASHBOARD));

        Toast({
          type: 'warning',
          message: (
            <Trans
              t={t}
              i18nKey="youAreImpersonating_name"
              values={{ name: getUserFullName(normalizedUserSchema) }}
              components={[<b>Dummy</b>]}
            />
          ),
        });
      } catch (error) {
        handleApiErrors(error.response);
        handleStopImpersonation();
      }
    }

    setIsRequestingImpersonation(false);
  };

  const handleSendMobileDeviceConfigPing = async userId => {
    setIsGettingMobileDeviceConfig(true);

    try {
      await sendAppConfigToLog(userId);
      await handleFetchMobileDeviceConfig(currentCompany._id);
      Toast({
        type: 'success',
        message: t('pushNotificationToAppSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsGettingMobileDeviceConfig(false);
  };

  const handleFetchMobileDeviceConfig = async companyID => {
    setIsGettingMobileDeviceConfig(true);

    try {
      const data = await fetchAppConfigLog(companyID);
      const usersWithAppConfigPings = {};
      if (Array.isArray(data)) {
        data.forEach(({ userId, data }) => {
          usersWithAppConfigPings[userId] = data;
        });
      }
      setUsersMobileAppConfigs(usersWithAppConfigPings);
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsGettingMobileDeviceConfig(false);
  };

  const handleCompanyDelete = async ({ text }) => {
    setIsDeletingCompany(true);

    try {
      await deleteCompany(currentCompany._id, text);
      queryClient.removeQueries({ queryKey: ['getAllCompanies'], exact: false });
      history.push(INTERNAL_LINKS.SELECT_COMPANY);
      setIsDeleteCompanyModalVisible(false);
      Toast({
        type: 'success',
        message: t('companyHasBeenDeleted', { name: currentCompany.name }),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('deleteCompanyError'),
        });
      });
    }

    setIsDeletingCompany(false);
  };

  useEffect(() => {
    loadCompanyDetails();

    if (!activeTab) {
      handleTabChange(DEFAULT_TAB_KEY);
    }

    return () => {
      dispatch(removeCompany());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentCompany?._id) {
      handleFetchMobileDeviceConfig(currentCompany._id);
    }
    // eslint-disable-next-line
  }, [currentCompany?._id]);

  const handlSearchTermChange = useCallback(term => {
    allUsersTablePagination.handlePageChange(1);
    setSearchTerm(term);
    // eslint-disable-next-line
  }, []);

  if (!currentCompany?.name || !usersMobileAppConfigs) {
    return (
      <PageContainer>
        <SpaceSpinner />
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            { label: t('companies'), onClick: () => history.push(INTERNAL_LINKS.SELECT_COMPANY) },
            ...(currentCompany?.name
              ? [
                  {
                    label: (
                      <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                        <Col>{currentCompany?.name}</Col>

                        <Col>
                          <StatusTag status={currentCompany.status} />
                        </Col>
                      </Row>
                    ),
                  },
                ]
              : []),
          ]}
        />
      }
      sideActionComponent={
        <Button
          size="sm"
          disabled={isDeletingCompany}
          loading={isDeletingCompany}
          onClick={() => setIsDeleteCompanyModalVisible(true)}
        >
          {t('deleteCompany')}
        </Button>
      }
    >
      <Helmet>
        <title>{formatPageTitle('Company Details')}</title>
      </Helmet>

      <Space direction="vertical" size="middle" wrap={false}>
        <CompanyDetailsSummary
          t={t}
          currentCompany={currentCompany}
          totalUsersCount={totalUsersCount || 0}
          usersCountByStatus={userCountByStatusQuery.data}
          isRequestingImpersonation={isRequestingImpersonation}
          onImpersonate={handleImpersonation}
        />

        <Box>
          <TabsContainer activeKey={activePane} onChange={handleTabChange}>
            <Tabs.TabPane tab={t('companyInfo')} key="companyInfo">
              <div className={classNames.formWrapper}>
                <CompanyInfoPageForm t={t} history={history} />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('companySettings')} key="companySettings">
              <div>
                <CompanySettings t={t} />
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('Users')} key="Users">
              <DashboardUsersTable
                t={t}
                asyncSort
                loading={isDeletingCompany || allUsersQuery.isFetching}
                dataSource={allUsersQuery?.data?.users || []}
                hiddenColumns={['lastInviteSent', 'sendInviteAction']}
                onImpersonate={handleImpersonation}
                isImpersonating={isRequestingImpersonation}
                onGetMobileConfig={handleSendMobileDeviceConfigPing}
                isGettingMobileConfig={isGettingMobileDeviceConfig}
                usersMobileAppConfigs={usersMobileAppConfigs}
                onSearchTermChange={handlSearchTermChange}
                onChange={({ current }, _, sorters) => {
                  allUsersTablePagination.handlePageChange(current);
                  allUsersHandleTableSort(sorters?.columnKey, sorters?.order);
                }}
                pagination={{
                  pageSize: allUsersTablePagination.paginationConfig.pageSize,
                  total: allUsersTablePagination.paginationConfig.total,
                  current: allUsersTablePagination.paginationConfig.current,
                  onShowSizeChange: allUsersTablePagination.handlePageSizeChange,
                }}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('userSettings')} key="userSettings">
              <UserSettingsSection t={t} currentCompany={currentCompany} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('mobileDeviceReportByUser')} key="reports">
              <UsersMobileReport t={t} currentCompany={currentCompany} />
            </Tabs.TabPane>

            {currentCompany.hasFavrProducts && companySettings.favrMileageAdjustment && (
              <Tabs.TabPane tab={t('favr-mileage-adjustment')} key="favrMileageAdjustment">
                <FavrMileageAdjustmentReport t={t} />
              </Tabs.TabPane>
            )}
          </TabsContainer>
        </Box>
      </Space>

      <CompanyDeleteConfirmationModal
        t={t}
        visible={isDeletingCompany || isDeleteCompanyModalVisible}
        isDeletingCompany={isDeletingCompany}
        onDelete={handleCompanyDelete}
        onCancel={() => setIsDeleteCompanyModalVisible(false)}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(CompanyDetailsView));
