import { replaceValueInArrayByID } from '../../utils/common';
import { createReducer } from '../../utils/storeCreators';
import {
  ADD_USER_VEHICLE,
  UPDATE_USER_VEHICLE_BY_ID,
  UPDATE_USER_VEHICLES,
} from '../actions/userVehicles';
import initialState from '../initialState';

const userVehiclesReducer = createReducer({
  initialState: initialState.userVehicles,
  reducer: (state, action) => {
    let userVehicles = state;
    switch (action.type) {
      case UPDATE_USER_VEHICLES:
        userVehicles[action.data.userID] = action.data.vehicles;
        return { ...state, ...userVehicles };

      case UPDATE_USER_VEHICLE_BY_ID:
        const updatedVehicle = action.data.vehicle;

        if (updatedVehicle.isDefault) {
          // If updating the same "default vehicle"
          if (userVehicles[action.data.userID].defaultVehicle?._id === action.data.vehicleID) {
            // replace "default vehicle" with the updated one
            userVehicles[action.data.userID].defaultVehicle = updatedVehicle;
            // if not updating the same "default vehicle"
          } else if (userVehicles[action.data.userID].defaultVehicle) {
            // move the current "default vehicle" to "active vehicles" and set isDefault to false
            userVehicles[action.data.userID].activeVehicles = [
              ...userVehicles[action.data.userID].activeVehicles,
              {
                ...userVehicles[action.data.userID].defaultVehicle,
                isDefault: false,
              },
            ]
              // remove updated vehicle from array if present
              .filter(vehicle => vehicle._id !== updatedVehicle._id);

            // replace default vehicle with new updated vehicle
            userVehicles[action.data.userID].defaultVehicle = updatedVehicle;
            // if there is no default vehicle
          } else {
            // set new vehicle as default
            userVehicles[action.data.userID].defaultVehicle = updatedVehicle;
            // remove vehicle from "active vehicles" if present
            userVehicles[action.data.userID].activeVehicles = userVehicles[
              action.data.userID
            ].activeVehicles.filter(vehicle => vehicle._id !== updatedVehicle._id);
          }
        } else {
          // if updating an "active vehicle"
          if (
            !!userVehicles[action.data.userID].activeVehicles.find(
              vehicle => vehicle._id === action.data.vehicleID,
            )
          ) {
            // replace active vehicle with updated one
            userVehicles[action.data.userID].activeVehicles = replaceValueInArrayByID(
              userVehicles[action.data.userID].activeVehicles,
              updatedVehicle,
              '_id',
            );
            // if updating a non existent "active vehicle"
          } else {
            // add it to the array of active vehicles
            userVehicles[action.data.userID].activeVehicles = [
              ...userVehicles[action.data.userID].activeVehicles,
              updatedVehicle,
            ];
          }

          if (userVehicles[action.data.userID].defaultVehicle?._id === action.data.vehicleID) {
            userVehicles[action.data.userID].defaultVehicle = null;
          }
        }

        return { ...state, ...userVehicles };

      case ADD_USER_VEHICLE:
        let activeVehicles = [];
        let defaultVehicle = null;

        action.data.vehicles.forEach(vehicle => {
          if (vehicle.isDefault) {
            defaultVehicle = vehicle;
          } else {
            activeVehicles.push(vehicle);
          }
        });

        userVehicles[action.data.userID].activeVehicles = activeVehicles;
        userVehicles[action.data.userID].defaultVehicle = defaultVehicle;

        return { ...state, ...userVehicles };
      default:
        return state;
    }
  },
});

export default userVehiclesReducer;
