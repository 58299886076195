import { all } from 'redux-saga/effects';

import { browserSaga } from './browserSaga';
import { commonSaga } from './commonSaga';
import { companySaga } from './companySaga';
import { favrVehiclesSaga } from './favrVehiclesSaga';
import { invoicesSaga } from './invoices';
import { priceSaga } from './pricesSaga';
import { profileSaga } from './profileSaga';
import { rateWizardSaga } from './rateWizardSaga';
import { realtorSaga } from './realtorSaga';
import { routerSaga } from './routerSagas';
import { systemAdminSaga } from './systemAdminSaga';
import { userVehiclesSaga } from './userVehicles';
import { vehiclesSaga } from './vehiclesSaga';

function* appSaga() {
  yield all([
    ...routerSaga,
    ...commonSaga,
    ...priceSaga,
    ...browserSaga,
    ...companySaga,
    ...invoicesSaga,
    ...systemAdminSaga,
    ...vehiclesSaga,
    ...profileSaga,
    ...userVehiclesSaga,
    ...rateWizardSaga,
    ...realtorSaga,
    ...favrVehiclesSaga,
  ]);
}

export default appSaga;
