import React, { useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue, momentTimezone } from '../../../utils/common';
import { sortColumnByStatus, sortColumnByStringField } from '../../../utils/tables';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';

const SystemAdminUserManagerTable = props => {
  const { t, dataSource, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(user => {
        return (
          checkIfStringContainsValue(user.name, searchTerm) ||
          checkIfStringContainsValue(user.email, searchTerm) ||
          checkIfStringContainsValue(
            STATUS_LIST().StatusTagColorConfig[user.status].label,
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            momentTimezone(user.created, null).format('MM-DD-YYYY'),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            user.lastLogin ? momentTimezone(user.lastLogin, null).format('MM-DD-YYYY') : '-',
            searchTerm,
          )
        );
      });
    }

    return array;
  }, [searchTerm, dataSource]);

  const COLUMNS = [
    {
      defaultSortOrder: 'ascend',
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      ...sortColumnByStringField('name'),
      render: (actions, data) => {
        return (
          <ProfileNamePicture
            t={t}
            isSystemAdmin
            showStatus={false}
            user={{
              ...data,
              _id: data.key || data._id,
            }}
          />
        );
      },
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      ...sortColumnByStringField('email'),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: status => <StatusTag status={status.toLowerCase()} />,
      ...sortColumnByStatus('status'),
    },
    {
      title: t('createdDate'),
      dataIndex: 'created',
      render: createdDate => momentTimezone(createdDate, null).format('MM-DD-YYYY'),
    },
    {
      title: t('lastLogin'),
      dataIndex: 'lastLogin',
      render: lastLoginDate =>
        lastLoginDate ? momentTimezone(lastLoginDate, null).format('MM-DD-YYYY') : '-',
    },
  ];

  return (
    <CustomTable
      {...rest}
      onSearchTermChange={setSearchTerm}
      dataSource={filteredDataSource}
      columns={COLUMNS}
      scroll={{ x: 970 }}
    />
  );
};

export default SystemAdminUserManagerTable;
