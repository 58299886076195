import { Col, Row } from 'antd';
import { get } from 'lodash';
import React from 'react';

import { IMAGES } from '../../enum';
import StatusTag from '../Tag/StatusTag';
import Text from '../Text';

const BANK_ACCOUNT_DETAILS_PATH_MAP = [
  { label: 'accountName', path: 'accountDetails.accountName' },
  { label: 'accountType', path: 'accountDetails.accountType' },
  { label: 'accountNumber', path: 'accountDetails.accountNumber' },
  { label: 'routingNumber', path: 'accountDetails.routingNumber' },
];

const BankAccountSummary = props => {
  const { t, fundingSource, microDepositStatus, showStatus, ...rest } = props;

  return (
    <Row justify="center" {...rest}>
      <Col>
        <Row justify="center">
          <Col>
            <img width="70px" src={IMAGES.BANK_ACCOUNT_ICON} alt="bank" />
          </Col>
        </Row>

        <br />

        {showStatus && fundingSource?.fundingSourceStatus && (
          <Row justify="center" style={{ marginBottom: 15 }}>
            <Col>
              <StatusTag status={microDepositStatus || fundingSource.fundingSourceStatus} />
            </Col>
          </Row>
        )}

        <Row>
          {fundingSource ? (
            <Col flex={1}>
              {BANK_ACCOUNT_DETAILS_PATH_MAP.map(({ label, path }) => {
                return (
                  <Row key={label} wrap={false} gutter={8}>
                    <Col flex="150px">
                      <Text variant="b">{t(label)}:</Text>
                    </Col>
                    <Col flex={1}>
                      <Text>{get(fundingSource, path, '-')}</Text>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          ) : (
            <Text>{t('noBankAccountSetUp')}</Text>
          )}
        </Row>
      </Col>
    </Row>
  );
};

BankAccountSummary.defaultProps = {
  showStatus: true,
};

export default BankAccountSummary;
