import React from 'react';

import WarningAlert from './WarningAlert.js';

const BankAccountRemovedAlert = props => {
  const { t, removalReason } = props;

  return (
    <WarningAlert
      closable={false}
      title={t('bankAccountRemovedBySystem')}
      message={[removalReason.code, removalReason.description].join(' - ')}
      style={{ marginBottom: 20, maxWidth: '100%' }}
    />
  );
};

export default BankAccountRemovedAlert;
