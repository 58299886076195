import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class StripeAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'stripe' });
  }

  /**
   * @typedef {Object} StripeCard
   * @property {string} id
   * @property {string} brand
   * @property {number} expMonth
   * @property {number} expYear
   * @property {string} last4
   * @property {string} name
   */

  /**
   * Fetch a paginated set of cards
   * @param {string} startingAfter A cursor for use in pagination. ending_before is an object ID that defines your place in the list. For instance, if you make a list request and receive 100 objects, starting with obj_bar, your subsequent call can include ending_before=obj_bar in order to fetch the previous page of the list.
   * @param {string} endingBefore A cursor for use in pagination. ending_before is an object ID that defines your place in the list. For instance, if you make a list request and receive 100 objects, starting with obj_bar, your subsequent call can include ending_before=obj_bar in order to fetch the previous page of the list.
   * @param {number} limit A limit on the number of objects to be returned. Limit can range between 1 and 100, and the default is 10.
   * @returns {{ data: StripeCard[], hasMore: boolean }}
   */
  fetchCards = async (startingAfter, endingBefore, limit = 10) => {
    const params = cleanQueryParams({ startingAfter, endingBefore, limit });
    const response = await handleApiCalls('get', this.baseUrl + `/cards?${params}`);
    return response.data;
  };

  /**
   * Create Stripe card
   * @param {object} params
   * @param {number} params.cardNumber
   * @param {string} params.expMonth
   * @param {string} params.expYear
   * @param {string} params.cvc
   * @param {string} params.name
   * @param {boolean} params.isDefault
   */
  addCard = async params => {
    const response = await handleApiCalls('post', this.baseUrl + '/cards', params);
    return response.data;
  };

  /**
   * Change default Stripe card
   * @param {string} cardId
   */
  seDefaultCard = async cardId => {
    const response = await handleApiCalls('patch', this.baseUrl + `/cards/${cardId}/default`);
    return response.data;
  };

  /**
   * Update Stripe card
   * @param {string} cardId
   * @param {object} cardData
   * @param {object} cardData.expMonth
   * @param {object} cardData.expYear
   * @param {object} cardData.name
   */
  updateCard = async (cardId, cardData) => {
    const response = await handleApiCalls('patch', this.baseUrl + `/cards/${cardId}`, cardData);
    return response.data;
  };

  /**
   * Delete Stripe card
   * @param {string} cardId
   */
  deleteCard = async cardId => {
    const response = await handleApiCalls('delete', this.baseUrl + `/cards/${cardId}`);
    return response.data;
  };

  /**
   * @typedef {Object} StripeAddress
   * @property {string} city
   * @property {string} country
   * @property {string} line1
   * @property {string?} line2
   * @property {string} postal_code
   * @property {string} state
   */

  /**
   * @typedef {Object} StripeCustomer
   * @property {string} email
   * @property {string} name
   * @property {string} phone
   * @property {StripeAddress} address
   * @property {string?} defaultSource
   */

  /**
   * Fetch customer information set on Stripe
   * @param {string} customerId
   * @returns {StripeCustomer}
   */
  fetchCustomerInformation = async customerId => {
    const response = await handleApiCalls(
      'get',
      this.customBaseUrl('company-stripe-customers') + '/' + customerId,
    );
    return response.data;
  };

  /**
   * Update Stripe Customer invoice settings & custom fields.
   *
   * Send empty string on `customFields` in order to delete all fields.
   * @param {string} customerId
   * @param {object} data
   * @param {object} data.invoiceSettings
   * @param {string | { name: string, value: string }[]} data.invoiceSettings.customFields
   */
  updateStripeCustomFields = async (customerId, data) => {
    const response = await handleApiCalls(
      'patch',
      this.customBaseUrl('company-stripe-customers') + '/' + customerId,
      data,
    );
    return response.data;
  };

  /**
   * Update stripe customer information
   * @param {object} invoiceData
   * @param {string} invoiceData.firstName
   * @param {string} invoiceData.lastName
   * @param {string} invoiceData.email
   * @param {string} invoiceData.phone
   * @param {string} invoiceData.street1
   * @param {string} invoiceData.country
   * @param {string} invoiceData.state
   * @param {string} invoiceData.city
   * @param {string} invoiceData.postalCode
   * @returns {StripeCustomer}
   */
  updateCustomer = async invoiceData => {
    const response = await handleApiCalls('patch', this.baseUrl + '/customers', invoiceData);
    return response.data;
  };
}

export default StripeAPI;
