import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import MetabaseAPI from '../../api/metabase';
import Spinner from '../Spinner';

const MetabaseMonthlyMileageChart = props => {
  const { userId, companyId } = props;
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  const monthlyMileageReportUrlQuery = useQuery({
    enabled: !!userId,
    queryKey: ['fetchMetabaseReportURL', 'last-12-months-mileage', userId],
    queryFn: () =>
      new MetabaseAPI().fetchMetabaseReportURL('last-12-months-mileage', { userId, companyId }),
    onError: error => handleApiErrors(error.response),
  });

  return (
    <Spinner spinning={isLoadingIframe || monthlyMileageReportUrlQuery.isFetching}>
      <iframe
        title="monthly mileage chart"
        src={monthlyMileageReportUrlQuery.data}
        frameBorder={0}
        width={800}
        height={450}
        allowTransparency
        onLoad={() => setIsLoadingIframe(false)}
      />
    </Spinner>
  );
};

export default MetabaseMonthlyMileageChart;
