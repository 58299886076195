import { removeFalseyParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';
import { COMPANY_API } from './company';

/**
 * Performs an API request to cancel a company's Stripe subscription at the end of the billing period
 */
export const cancelPlanAtPeriodEnd = async companyID => {
  const url = `${process.env.REACT_APP_HOST_API}subscriptions/cancel-at-period-end`;
  const result = await handleApiCalls('post', url);

  if (result.status === 200 || result.data.success) {
    return COMPANY_API.getActivePlan(companyID);
  }
};

/**
 * Performs an API request to revert the cancellation of a company's Stripe subscription
 */
export const revertPlanCancellation = async companyID => {
  const url = `${process.env.REACT_APP_HOST_API}subscriptions/un-cancel`;
  const result = await handleApiCalls('post', url);

  if (result.status === 200 || result.data.success) {
    return COMPANY_API.getActivePlan(companyID);
  }
};

/**
 * @deprecated
 * Performs an API call in order to fetch a Company's credit card info
 *
 * @param {string} companyID ID of Company
 */
export const getSubscriptionCreditCardMethod = async companyID => {
  const url = `${process.env.REACT_APP_HOST_API}subscriptions/getCompanyPaymentCardDetails/${companyID}`;

  const result = await handleApiCalls('get', url);

  if (result.status === 200 || result.data.success) {
    if (result.data) return result.data.data;
    return null;
  }
};

/**
 * Performs an API call to update a Stripe Customer's Credit Card
 *
 * @param {object} data Form values
 */
export const updateCustomerCard = async data => {};

/**
 * Performs an API call to change the Customer's default Credit Card on Stripe
 *
 * @param {string} token Stripe Card Token
 */
export const changeDefaultCardForCustomer = async token => {};

/**
 * Performs an API call to change the active plan for a given user
 *
 * @param {number} planType Plan number
 * @param {string} companyId Company ID
 */
export const changeActivePlan = async (planType, companyId) => {
  const url = `${process.env.REACT_APP_HOST_API}subscriptions/change-plan`;

  const result = await handleApiCalls('post', url, { planType, companyId });

  return result.data.data;
};

/**
 * Performs an API call to subscribe the company to a given plan
 *
 * @param {number} planType Plan number
 * @param {string} companyId Company ID
 */
export const subscribeToPlan = async (planType, companyId) => {
  const url = `${process.env.REACT_APP_HOST_API}subscriptions`;

  const result = await handleApiCalls('post', url, { planType, companyId });

  return result.data.data;
};

/**
 *
 * @param {object} data
 * @param {string} data.firstName
 * @param {string} data.lastName
 * @param {string} data.phone
 * @param {string} data.email
 * @param {string} data.street1
 * @param {string} data.country
 * @param {string} data.state
 * @param {string} data.city
 * @param {string} data.postalCode
 */
export const addCompanyInvoiceDetails = async data => {};

/**
 *
 * @param {object} params
 * @param {string} params.planId
 * @param {string} params.companyId
 * @param {string} params.endDate
 */
export const createCompanyFreeTrial = async params => {
  const url = `${process.env.REACT_APP_HOST_API}subscriptions/create-trial`;
  const result = await handleApiCalls('post', url, removeFalseyParams(params));
  return result.data;
};

/**
 *
 * @param {object} params
 * @param {string} params.companyId
 * @param {string} params.endDate
 */
export const extendCompanyFreeTrial = async params => {
  const url = `${process.env.REACT_APP_HOST_API}subscriptions/extend-trial`;
  const result = await handleApiCalls('post', url, removeFalseyParams(params));
  return result.data;
};

class SubscriptionsAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'subscriptions' });
  }

  /**
   * @typedef {"send_invoice" | "charge_automatically"} CollectionMethod
   */

  /**
   * @typedef {Object} Subscription
   * @property {boolean} isCancelled
   * @property {CollectionMethod} collectionMethod
   * @property {object[]} planLogs
   * @property {string} _id
   * @property {string} status
   * @property {string} startDate
   * @property {string} stripe_subscriptionId
   * @property {string} stripeSubscriptionItemId
   * @property {string} company
   * @property {boolean} isPaidPlanActive
   * @property {boolean} isTrialActive
   * @property {string} planId
   * @property {string} created
   * @property {string} updated
   */

  /**
   * Update collection method
   * @param {CollectionMethod} collectionMethod
   * @returns {Subscription}
   */
  updateCollectionMethod = async (subscriptionId, collectionMethod) => {
    const result = await handleApiCalls(
      'patch',
      `${this.baseUrl}/${subscriptionId}/collection-method`,
      {
        collectionMethod,
      },
    );
    return result.data;
  };
}

export default SubscriptionsAPI;
