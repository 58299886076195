import './style.scss';

import { Col, Row } from 'antd';
import AuthContainer from 'containers/Auth';
import { INTERNAL_LINKS, USER_REG_STATUS } from 'enum';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import storage from 'store';
import {
  saveEmailForConfirm,
  savePhoneForPin,
  setAccountCreateStatus,
  setEmailConfirmed,
} from 'stores/actions/common';
import { setRegStatus } from 'stores/actions/user';

import { handleApiCalls, handleApiErrors } from '../../../api/axiosInstance';
import FullLogo from '../../../components/FullLogo';
import FadedText from '../../../components/Text/FadedText';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';
import { formatPageTitle } from '../../../utils/common';

const ConfirmEmail = props => {
  const { t } = props;

  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [showError, setShowError] = useState(false);
  const [errorType, setErrorType] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleVerification();
    // eslint-disable-next-line
  }, []);

  const removeSignupStorage = () => {
    dispatch(setAccountCreateStatus(''));
    dispatch(savePhoneForPin(''));
    dispatch(saveEmailForConfirm(''));
  };

  const handleVerification = async () => {
    setLoading(true);
    try {
      let token = props.match.params.token;
      let result = await handleApiCalls(
        'post',
        `${process.env.REACT_APP_HOST_API}auth/confirm-email/${token}`,
      );
      if (result.status === 200) {
        storage.set('accessToken', result.data.accessToken);
        storage.set('refreshToken', result.data.refreshToken);
        storage.set('profileId', result.data.profileId);
        removeSignupStorage();
        dispatch(setEmailConfirmed(true));
        // set the user registration status to company
        dispatch(setRegStatus(USER_REG_STATUS.COMPANY));
        props.history.push(INTERNAL_LINKS.COMPANY_ONBOARDING, { noRemoveToken: true });
      } else if (result.status === 204) {
        setShowError(true);
        setErrorType(1);
      }
    } catch (err) {
      setShowError(true);
      const IS_TOKEN_EXPIRED =
        err?.response?.data?.message && /expired/gi.test(err.response.data.message);
      const IS_TOKEN_INVALID =
        err?.response?.data?.message && /invalid/gi.test(err.response.data.message);
      const IS_ACTIVATED =
        err?.response?.data?.message && /already activated/gi.test(err.response.data.message);

      if (err.response && (IS_TOKEN_EXPIRED || IS_TOKEN_INVALID)) {
        setErrorType(1);
      } else if (IS_ACTIVATED) {
        setErrorType(3);
      } else {
        setErrorType(0);
      }
    }
    setLoading(false);
  };

  const resendEmail = async () => {
    setShowError(false);
    setLoading(true);

    try {
      await handleApiCalls('post', `${process.env.REACT_APP_HOST_API}auth/resend-activate-email`, {
        token: props.match.params.token,
      });
      setShowError(false);
      Toast({
        type: 'success',
        message: props.t('activationEmailSendSuccessful'),
      });
    } catch (e) {
      handleApiErrors(e.response, () => {
        Toast({
          type: 'error',
          message: props.t('resendingEmailFailed'),
        });
      });
    }

    setLoading(false);
    setShowError(true);
  };

  return (
    <div className="user-activate">
      <Helmet>
        <title>{formatPageTitle('Confirm Email')}</title>
      </Helmet>

      <div className="user-activate-container">
        <Row justify="center" style={{ marginBottom: 30 }}>
          <Col>
            <Link to={INTERNAL_LINKS.LOGIN}>
              <FullLogo />
            </Link>
          </Col>
        </Row>

        {loading && (
          <div className="d-flex-center">
            <FadedText>{t('loading...')}</FadedText>
          </div>
        )}

        {showError && errorType === 1 && (
          <div className="d-flex-center f-dir-column">
            <FadedText>
              {`This email verification link has expired. Please request a new one by clicking `}
              <LinkText variant="strong" onClick={resendEmail}>
                here
              </LinkText>
              .
            </FadedText>
          </div>
        )}

        {showError && errorType === 3 && (
          <div className="d-flex-center f-dir-column">
            <FadedText>This account has already been activated.</FadedText>
            <br />
            <Link to={INTERNAL_LINKS.LOGIN}>
              <LinkText variant="strong">Log in to Kliks</LinkText>
            </Link>
          </div>
        )}

        {showError && errorType === 0 && (
          <div className="d-flex-center">
            <FadedText>{t('unexpectedErrorContactAdmin')}</FadedText>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthContainer(withNamespaces()(ConfirmEmail));
