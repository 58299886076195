import { Col, Form, Row } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import { handleApiErrors } from '../../../api/axiosInstance';
import { updateUserVacationsApiCall } from '../../../api/user';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';
import VacationForm from '../../../components/VacationForm';
import { IMAGES } from '../../../enum';
import { formatPageTitle } from '../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { hasCompanyAdminRole } from '../../../utils/roles';
import { selectStoreCurrentAuthUser, useStoreSelector } from '../../../utils/storeSelectors';
import EmptyVacationsSection from './EmptyVacationsSection';
import { validateUserVacations, validateUserVacationsCollision } from './vacations-validations';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Disability / Leave Dates')}</title>
  </Helmet>
);

/**
 * User Vacation page
 */
const UserDetailsVacationsView = props => {
  const { t, userDetails, onUserUpdate, isUserDeleted } = props;

  const authUser = useStoreSelector(selectStoreCurrentAuthUser);
  const [vacationsWithErrors, setVacationsWithErrors] = useState([]);
  const [isUpdatingVacations, setIsUpdatingVacations] = useState(false);
  const [expiredVacations, setExpiredVacations] = useState([]);
  const [futureVacations, setFutureVacations] = useState([]);
  const vacationsFromDB = useMemo(() => {
    if (Array.isArray(get(userDetails, 'businessInfor.disabilityAndLeaveDates'))) {
      return get(userDetails, 'businessInfor.disabilityAndLeaveDates', []).map((vacation, i) => ({
        id: +new Date() + i,
        fromDB: true,
        startDate: getMomentDateWithoutTimezone(vacation.startDate),
        endDate: getMomentDateWithoutTimezone(vacation.endDate),
      }));
    }

    return [];
  }, [userDetails]);

  useEffect(() => {
    const past = [];
    const future = [];
    vacationsFromDB.forEach(vacation => {
      const isDateInPast =
        !!vacation.startDate &&
        !!vacation.endDate &&
        (moment(vacation.startDate).isSameOrBefore(moment(), 'date') ||
          moment(vacation.endDate).isSameOrBefore(moment(), 'date'));

      if (vacation.fromDB && isDateInPast) {
        past.push(vacation);
      } else {
        future.push(vacation);
      }
    });

    setExpiredVacations(past);
    setFutureVacations(future);
  }, [vacationsFromDB]);

  /**
   * Removes the IDs from the Arrays of Vacations
   */
  const cleanVacationsArray = useCallback(() => {
    let vacationsDataWithoutIDs = [];

    [...expiredVacations, ...futureVacations].forEach(({ id, ...vacation }) => {
      if (vacation.startDate && vacation.endDate) {
        vacationsDataWithoutIDs.push({
          // Dates are `moment` instances, or strings when coming directly from Backend
          startDate: getMomentDateWithoutTimezone(vacation.startDate)
            .startOf('day')
            .utc(true)
            .toISOString(false),
          endDate: getMomentDateWithoutTimezone(vacation.endDate)
            .endOf('day')
            .utc(true)
            .toISOString(false),
        });
      }
    });

    return vacationsDataWithoutIDs;
  }, [expiredVacations, futureVacations]);

  /**
   * Update the user's vacation dates if the does not contain errors
   */
  const handleUpdateUserVacations = async () => {
    if (!vacationsWithErrors.length) {
      const cleanVacations = cleanVacationsArray();

      if (!validateUserVacationsCollision(cleanVacations)) {
        return Toast({
          type: 'error',
          message: t('vacationDatesCollidingWithOthers'),
        });
      }

      setIsUpdatingVacations(true);

      try {
        const responseData = await updateUserVacationsApiCall({
          userID: userDetails._id,
          disabilityAndLeaveDates: cleanVacations,
        });
        onUserUpdate(responseData);
        Toast({
          type: 'open',
          message: t('vacationUpdateSuccess'),
        });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('vacationUpdateError'),
          });
        });
      }

      setIsUpdatingVacations(false);
    } else {
      Toast({
        type: 'error',
        message: t('oneOrMoreItemsHaveErrors'),
      });
    }
  };

  /**
   * Adds another row to the form to set a new Vacation period
   */
  const addAnotherVacation = () =>
    setFutureVacations(currentVacations => [...currentVacations, { id: +new Date() }]);

  const SAVE_BUTTON = (
    <Row align="middle" justify="end" gutter={[16, 16]}>
      <Col>
        <Button
          type="primary"
          loading={isUpdatingVacations}
          disabled={isUserDeleted}
          onClick={handleUpdateUserVacations}
        >
          {t('Save')}
        </Button>
      </Col>
    </Row>
  );

  const handleDeleteExpiredVacation = useCallback(vacation => {
    setExpiredVacations(currentVacations => {
      const newVacations = [...currentVacations];
      const vacationIndex = newVacations.findIndex(v => v.id === vacation.id);
      newVacations.splice(vacationIndex, 1);
      return [...newVacations];
    });
  }, []);

  const handleDeleteFutureVacation = useCallback(vacation => {
    setFutureVacations(currentVacations => {
      const newVacations = [...currentVacations];
      const vacationIndex = newVacations.findIndex(v => v.id === vacation.id);
      setVacationsWithErrors(currentErrors => [
        ...currentErrors.filter(index => index !== vacationIndex),
      ]);
      newVacations.splice(vacationIndex, 1);
      return [...newVacations];
    });
  }, []);

  const handleDateChange = useCallback((date, i) => {
    setFutureVacations(currentVacations => {
      const newVacations = [...currentVacations];
      newVacations[i] = date;

      if (date.startDate && date.endDate) {
        setVacationsWithErrors(currentErrors => {
          if (!validateUserVacations(date.startDate, date.endDate)) {
            return [...currentErrors.filter(index => index !== i), i];
          } else if (currentErrors.includes(i)) {
            return [...currentErrors.filter(index => index !== i)];
          }
          return currentErrors;
        });
      }

      return [...newVacations];
    });
  }, []);

  return (
    <div>
      <PageHelmet />

      <Row gutter={[0, 32]}>
        <Col span={24}>
          <Box>
            <Row>
              <Col span={24}>
                <Row gutter={[0, 17]}>
                  <Text variant="h5">{t('disabilityLeaveDates')}</Text>
                </Row>

                {(!vacationsFromDB.length && !futureVacations.length) ||
                (!expiredVacations.length && !futureVacations.length) ? (
                  <>
                    <EmptyVacationsSection
                      t={t}
                      disabled={isUserDeleted}
                      addAnotherVacation={addAnotherVacation}
                    />

                    {SAVE_BUTTON}
                  </>
                ) : (
                  <Row>
                    <Col span={24}>
                      <Form labelCol={{ span: 24 }} autoComplete="off">
                        {expiredVacations.map(vacation => (
                          <VacationForm
                            t={t}
                            key={vacation.id}
                            vacation={vacation}
                            canUpdate={false}
                            canDelete={hasCompanyAdminRole(authUser)}
                            onDelete={() => handleDeleteExpiredVacation(vacation)}
                          />
                        ))}

                        {futureVacations.map((vacation, i) => {
                          return (
                            <VacationForm
                              t={t}
                              key={vacation.id}
                              vacation={vacation}
                              canDelete
                              canUpdate
                              disabled={isUpdatingVacations || isUserDeleted}
                              hasError={vacationsWithErrors.includes(i)}
                              onDateChange={dates => handleDateChange(dates, i)}
                              onDelete={() => handleDeleteFutureVacation(vacation)}
                            />
                          );
                        })}

                        {!isUpdatingVacations && !isUserDeleted && (
                          <Row align="middle" justify="center" style={{ marginBottom: 30 }}>
                            <LinkText onClick={addAnotherVacation}>
                              <Row align="middle" gutter={5} wrap={false}>
                                <Col>
                                  <img
                                    alt="plus"
                                    src={IMAGES.GREEN_PLUS_ICON}
                                    width="16px"
                                    style={{ display: 'block' }}
                                  />
                                </Col>
                                <Col>{t('addAnotherPeriod')}</Col>
                              </Row>
                            </LinkText>
                          </Row>
                        )}

                        {SAVE_BUTTON}
                      </Form>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </div>
  );
};

export default UserDetailsVacationsView;
