import { orderBy } from 'lodash';

import { STATUS_LIST } from '../enum';

/**
 * Parses the compliance components to read if there is any component OOC and return them.
 * @param {object[]} complianceStatus Compliance status components
 * @returns {[boolean, object[]]} [isOutOfCompliance, componentsOutOfCompliance]
 */
export const parseUserComplianceStatus = (complianceStatus = []) => {
  if (!Array.isArray(complianceStatus)) return [false, []];
  if (!complianceStatus.length) return [false, []];

  const oocComponents = orderBy(complianceStatus, 'type', 'asc').filter(compliance =>
    [
      STATUS_LIST().Status.DENIED,
      STATUS_LIST().Status.NON_COMPLIANT,
      STATUS_LIST().Status.PENDING,
    ].includes(compliance.status),
  );

  return [!!oocComponents.length, oocComponents];
};
