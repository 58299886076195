import { find, merge } from 'lodash';
import queryString from 'querystring';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import ReceiptAPI, {
  approveReceipt,
  fetchReceiptsOnReimburesementView,
  RECEIPT_API,
  rejectReceipt,
} from '../../../api/receipt';
import { resubmitReceiptForApproval } from '../../../api/trip-receipt-reviews';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';
import { INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import useInfinitePeriodByGroupQuery from '../../../hooks/queries/useInfinitePeriodByGroupQuery';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useDebounce from '../../../hooks/useDebounce';
import useModal from '../../../hooks/useModal';
import useTableSort from '../../../hooks/useTableSort';
import { momentTimezone } from '../../../utils/common';
import { hasCompanyManagerOrAdminRole, hasUserRole } from '../../../utils/roles';
import { findReceiptsWithTripsInMoreInfoStatus } from '../../../utils/trip-receipts';
import { canResubmitReceiptForApproval } from '../../receipt/receipt-permissions';

const useLimitOneReceiptReimbursementsState = ({ t, history, initialFilters }) => {
  const authProfile = useSelector(store => store.profile);

  const [isInsightsEnabled, setIsInsightsEnabled] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [overdueReceiptsCount, setOverdueReceiptsCount] = useState();

  const [reimbursementFilters, setReimbursementFilters] = useState(initialFilters);
  const debuncedReimbursementFilters = useDebounce(reimbursementFilters);

  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [selectedReceiptsWithMoreInfoTrips, setSelectedReceiptsWithMoreInfoTrips] = useState([]);

  const [isApprovalConfirmationVisible, setIsApprovalConfirmationVisible] = useState(false);
  const [isRejectConfirmationVisible, setIsRejectConfirmationVisible] = useState(false);
  const [isResubmitConfirmationVisible, setIsResubmitConfirmationVisible] = useState(false);

  const [isApprovingReceipt, setIsApprovingReceipt] = useState(false);
  const [isRejectingReceipt, setIsRejectingReceipt] = useState(false);
  const [isResubmittingReceiptsForApproval, setIsResubmittingReceiptsForApproval] = useState(false);
  const openApprovalConfirmation = () => setIsApprovalConfirmationVisible(true);
  const closeApprovalConfirmation = () => setIsApprovalConfirmationVisible(false);

  const openRejectionConfirmation = () => setIsRejectConfirmationVisible(true);
  const closeRejectionConfirmation = () => setIsRejectConfirmationVisible(false);

  const openResubmitConfirmation = () => setIsResubmitConfirmationVisible(true);
  const closeResubmitConfirmation = () => setIsResubmitConfirmationVisible(false);

  const [receiptIdToDelete, setReceiptIdToDelete] = useState();
  const [isDeleteReceiptModalVisible, openDeleteReceiptModal, closeDeleteReceiptModal] = useModal();

  const handleReceiptSelection = selectedRowKeys => {
    const receiptsWithMoreInfoTrips = findReceiptsWithTripsInMoreInfoStatus(
      receipts,
      selectedRowKeys,
    );

    setSelectedReceipts(selectedRowKeys);
    setSelectedReceiptsWithMoreInfoTrips(receiptsWithMoreInfoTrips);
  };

  const { stringTableSort, handleTableSort } = useTableSort({ created: -1 });

  const userReceiptQueryParams = useMemo(
    () =>
      debuncedReimbursementFilters
        ? {
            ...debuncedReimbursementFilters,
            fromDate: debuncedReimbursementFilters.fromDate
              ? momentTimezone(debuncedReimbursementFilters.fromDate).startOf('day').toISOString()
              : undefined,
            toDate: debuncedReimbursementFilters.toDate
              ? momentTimezone(debuncedReimbursementFilters.toDate).endOf('day').toISOString()
              : undefined,
            groupId:
              typeof debuncedReimbursementFilters.groupId === 'undefined' ||
              hasUserRole(authProfile)
                ? null
                : debuncedReimbursementFilters.groupId,
            status:
              typeof debuncedReimbursementFilters.status === 'undefined'
                ? null
                : debuncedReimbursementFilters.status,
          }
        : {},
    [debuncedReimbursementFilters, authProfile],
  );

  const { query: groupPeriodsQuery } = useInfinitePeriodByGroupQuery(reimbursementFilters.groupId, {
    enabled: hasCompanyManagerOrAdminRole(authProfile),
  });

  const {
    query: userReceiptsQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      enabled:
        hasCompanyManagerOrAdminRole(authProfile) && reimbursementFilters.groupId
          ? groupPeriodsQuery.isFetched && !groupPeriodsQuery.isFetching
          : !groupPeriodsQuery.isFetching,
      queryKey: [
        'fetchReceiptsOnReimburesementView',
        ...Object.values(userReceiptQueryParams),
        stringTableSort,
      ],
      queryFn: () =>
        fetchReceiptsOnReimburesementView(
          { ...userReceiptQueryParams, sort: stringTableSort },
          paginationConfig.current,
          paginationConfig.pageSize,
        ),
      onSuccess: response => {
        setReceipts(response.data);
        setOverdueReceiptsCount(response.overdueCount);
      },
      onError: error => handleApiErrors(error.response),
    },
    {
      resetPageQueryKey: Object.values(userReceiptQueryParams),
      initialPage: initialFilters?.page,
      initialPageSize: initialFilters?.pageSize,
      useQueryParams: true,
    },
    'count',
  );

  const handleReimbursementPageSizeChange = useCallback(handlePageSizeChange, [
    handlePageSizeChange,
  ]);

  const handleReimbursementPageChange = useCallback(currentPage => handlePageChange(currentPage), [
    handlePageChange,
  ]);

  const _checkIfUserIsAllowedToResubmitSelectedReceiptsForApproval = (receiptIDs = []) => {
    let canUpdate = true;

    for (let i = 0; i < receiptIDs.length; i++) {
      const id = receiptIDs[i];
      const receipt = receipts.find(r => r._id === id);
      if (receipt) {
        if (!canResubmitReceiptForApproval(authProfile, receipt)) {
          canUpdate = false;
          break;
        }
      }
    }

    if (!canUpdate) {
      Toast({
        type: 'error',
        message: t('reimbursementAlreadySubmittedForApproval'),
        description: t('selectedReimbursementAlreadySubmittedForApproval'),
      });
    }

    return canUpdate;
  };

  const handleApprove = async id => {
    const receiptsToUpdate = id ? [id] : selectedReceipts;

    setIsApprovingReceipt(true);

    try {
      await approveReceipt(receiptsToUpdate, 'approveAll');
      await userReceiptsQuery.refetch();
      handleReceiptSelection([]);
      closeApprovalConfirmation();
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsApprovingReceipt(false);
  };

  const handleDeny = async id => {
    setIsRejectingReceipt(true);

    try {
      await rejectReceipt(id ? [id] : selectedReceipts);
      userReceiptsQuery.refetch();
      handleReceiptSelection([]);
      closeRejectionConfirmation();
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsRejectingReceipt(false);
  };

  const handleResubmitForApproval = async () => {
    if (_checkIfUserIsAllowedToResubmitSelectedReceiptsForApproval(selectedReceipts)) {
      setIsResubmittingReceiptsForApproval(true);

      try {
        await resubmitReceiptForApproval(selectedReceipts);
        userReceiptsQuery.refetch();
        handleReceiptSelection([]);
        closeResubmitConfirmation();
      } catch (error) {
        handleApiErrors(error.response);
      }

      setIsResubmittingReceiptsForApproval(false);
    }
  };

  const handleFiltersChange = useCallback(
    filters => {
      setReimbursementFilters(state => {
        const updatedState = {
          ...state,
          ...filters,
          status: typeof filters.status === 'undefined' ? null : filters.status,
          period: state.groupId === filters.groupId ? filters.period : undefined,
          fromDate:
            hasUserRole(authProfile) || state.groupId === filters.groupId ? filters.fromDate : null,
          toDate:
            hasUserRole(authProfile) || state.groupId === filters.groupId ? filters.toDate : null,
        };

        if (!hasUserRole(authProfile)) {
          updatedState.groupId = typeof filters.groupId === 'undefined' ? null : filters.groupId;
        }

        if (updatedState.fromDate || updatedState.toDate) {
          updatedState.period = undefined;
        }

        history.replace({
          search: queryString.stringify({
            ...updatedState,
            fromDate: updatedState?.fromDate
              ? updatedState?.fromDate?.toISOString()
              : updatedState.fromDate,
            toDate: updatedState?.toDate
              ? updatedState?.toDate?.toISOString()
              : updatedState.toDate,
          }),
        });
        return updatedState;
      });
      // eslint-disable-next-line
  }, [authProfile]);

  const exportReceiptMutation = useMutation(
    () =>
      RECEIPT_API.exportTripReceipt({
        ...userReceiptQueryParams,
        hideReceipts: true,
        sort: stringTableSort,
      }),
    {
      onSuccess: () => {
        Toast({
          type: 'open',
          duration: 10, // seconds
          message: (
            <Trans
              t={t}
              i18nKey="exportBeingProcessed"
              components={[
                <LinkText variant="b" onClick={() => history.push(INTERNAL_LINKS.EXPORTS)}>
                  Dummy
                </LinkText>,
              ]}
            />
          ),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const markReceiptAsPaidMutation = useMutation(
    () => RECEIPT_API.markReceiptAsPaid(selectedReceipts),
    {
      onSuccess: async () => {
        await userReceiptsQuery.refetch();
        Toast({
          type: 'open',
          message: t('reimbursementsMarkAsPaidSucesss'),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const handleOverdueAlertClick = useCallback(() => {
    handleFiltersChange({
      ...reimbursementFilters,
      period: null,
      status: STATUS_LIST().Status.OVERDUE,
    });
  }, [handleFiltersChange, reimbursementFilters]);

  const toggleReceiptsInsights = () => setIsInsightsEnabled(state => !state);

  const reimbursementInsightsQuery = useQuery({
    retry: false,
    enabled: isInsightsEnabled && !userReceiptsQuery.isFetching && !!receipts.length,
    queryKey: [
      'fetchReimbursementInsights',
      paginationConfig.pageSize,
      paginationConfig.current,
      receipts.map(receipt => receipt._id),
    ],
    queryFn: () => RECEIPT_API.fetchReimbursementInsights(receipts.map(receipt => receipt._id)),
    onSuccess: receiptsInsights => {
      setReceipts(currentReceipts => {
        return currentReceipts.map(rc => merge(rc, find(receiptsInsights, { _id: rc._id })));
      });
    },
    onError: error => {
      setIsInsightsEnabled(false);
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchReimbursementInsightsError'),
        });
      });
    },
  });

  const deleteReceiptMutation = useMutation({
    mutationFn: () => new ReceiptAPI().deleteReceipt(receiptIdToDelete),
    onSuccess: () => {
      userReceiptsQuery.refetch();
      setReceiptIdToDelete();
      closeDeleteReceiptModal();
    },
    onError: error => handleApiErrors(error.response),
  });

  return {
    // Booleans
    isLoadingData:
      userReceiptsQuery.isFetching ||
      reimbursementInsightsQuery.isLoading ||
      groupPeriodsQuery.isFetching,
    isApprovalConfirmationVisible,
    isRejectConfirmationVisible,
    isResubmitConfirmationVisible,
    isApprovingReceipt,
    isRejectingReceipt,
    isResubmittingReceiptsForApproval,
    isInsightsEnabled,
    isDeleteReceiptModalVisible,
    // General
    receipts,
    selectedReceipts,
    selectedReceiptsWithMoreInfoTrips,
    overdueReceiptsCount,
    reimbursementPaginationConfig: paginationConfig,
    reimbursementFilters,
    // Handlers
    handleTableSort,
    handleReceiptSelection,
    handleApprove,
    handleDeny,
    handleResubmitForApproval,
    handleFiltersChange,
    exportReceiptMutation,
    markReceiptAsPaidMutation,
    openApprovalConfirmation,
    closeApprovalConfirmation,
    openRejectionConfirmation,
    closeRejectionConfirmation,
    openResubmitConfirmation,
    closeResubmitConfirmation,
    handleReimbursementPageChange,
    handleReimbursementPageSizeChange,
    handleOverdueAlertClick,
    toggleReceiptsInsights,
    setReceiptIdToDelete,
    openDeleteReceiptModal,
    closeDeleteReceiptModal,
    deleteReceiptMutation,
  };
};

export default useLimitOneReceiptReimbursementsState;
