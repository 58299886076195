import { getNumberRage } from '../../utils/common';
import { createReducer } from '../../utils/storeCreators';
import {
  COMPLETE_SETUP_USER_VEHICLES,
  SETUP_VEHICLES,
  TOGGLE_MAKES_LOADER,
  TOGGLE_MODELS_LOADER,
  TOGGLE_YEARS_LOADER,
  UPDATE_VEHICLE_MAKES,
  UPDATE_VEHICLE_MODELS,
  UPDATE_VEHICLE_YEARS,
} from '../actions/vehicles';
import initialState from '../initialState';

const vehiclesReducer = createReducer({
  initialState: initialState.vehicles,
  reducer: (state, action) => {
    let vehicles = state;

    switch (action.type) {
      case TOGGLE_YEARS_LOADER: {
        vehicles.isLoadingYears = action.data;
        return { ...state, ...vehicles };
      }

      case SETUP_VEHICLES:
        return { ...state, isSetup: false };

      case COMPLETE_SETUP_USER_VEHICLES:
        return { ...state, isSetup: true };

      case UPDATE_VEHICLE_YEARS: {
        const { max_year, min_year } = action.data;

        const yearsRange = getNumberRage(min_year, max_year);

        vehicles.availableYears = yearsRange.reverse();

        yearsRange.forEach(year => {
          vehicles.byYearData[year] = {
            availableMakes: [],
            modelsByMake: {},
          };
        });

        return { ...state, ...vehicles };
      }

      case TOGGLE_MAKES_LOADER: {
        vehicles.isLoadingMakes = action.data;
        return { ...state, ...vehicles };
      }

      case UPDATE_VEHICLE_MAKES: {
        const { year, makesData } = action.data;

        vehicles.byYearData[year].availableMakes = makesData;
        makesData.forEach(make => {
          vehicles.byYearData[year].modelsByMake[make?.toUpperCase()] = [];
        });

        return { ...state, ...vehicles };
      }

      case TOGGLE_MODELS_LOADER: {
        vehicles.isLoadingModels = action.data;
        return { ...state, ...vehicles };
      }

      case UPDATE_VEHICLE_MODELS: {
        const { year, make, modelsData } = action.data;

        vehicles.byYearData[year].modelsByMake[make] = modelsData;

        return { ...state, ...vehicles };
      }

      default:
        return vehicles;
    }
  },
});

export default vehiclesReducer;
