import {
  BUSINESS_COMMUTE_TRIP_TYPE,
  BUSINESS_TRIP_TYPE,
  COMMUTE_TRIP_TYPE,
  PERSONAL_TRIP_TYPE,
} from './trip-type-select-constants';

export const canSelectTripType = (type, currentType, trip) => {
  const reason = trip?.pricing?.commute?.reason;

  if (reason === 'settings') {
    if (currentType === BUSINESS_COMMUTE_TRIP_TYPE) {
      return [COMMUTE_TRIP_TYPE, PERSONAL_TRIP_TYPE, BUSINESS_COMMUTE_TRIP_TYPE].includes(type);
    }

    if (currentType === COMMUTE_TRIP_TYPE || currentType === PERSONAL_TRIP_TYPE) {
      return [PERSONAL_TRIP_TYPE, COMMUTE_TRIP_TYPE].includes(type);
    }
  } else if (reason === 'purpose') {
    if (currentType === COMMUTE_TRIP_TYPE || currentType === PERSONAL_TRIP_TYPE) {
      return [BUSINESS_TRIP_TYPE, COMMUTE_TRIP_TYPE, PERSONAL_TRIP_TYPE].includes(type);
    }
  }

  return [BUSINESS_TRIP_TYPE, COMMUTE_TRIP_TYPE, PERSONAL_TRIP_TYPE].includes(type);
};
