import { Col, DatePicker, Form, Row } from 'antd';
import React from 'react';

import { STATUS_LIST } from '../../enum';
import { ERROR_MESSAGE } from '../../utils/constants';
import InsuranceDocumentsSection from '../../views/userdetails/insurance-info/UserInsuranceDocumentsContent';
import Button from '../Button';
import InsuranceCompanySelect from '../InsuranceCompaniesSelect';
import InsurancePerPersonPerAccident from '../InsurancePerPersonPerAccident';
import { INSURANCE_FIELDS } from '../InsurancePerPersonPerAccident/InsurancePerPersonPerAccident.constants';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import FadedText from '../Text/FadedText';
import TextInput from '../TextInput';

const UserInsuranceForm = props => {
  const {
    t,
    disabled,
    isSubmitting,
    form,
    user,
    userComplianceDetails,
    insurancePolicy,
    onSubmit,
    hideInsuranceDocUpload,

    isUploadingFile,
    documentList,
    documentsToCreateList,
    onFileChange,
    onFileToCreateChange,
    onConfirmInfoIsUnchanged,
  } = props;

  const INITIAL_VALUES = insurancePolicy || {
    streetOne: user.personalInfor?.homeAddress?.streetOne,
    streetTwo: '',
    city: user.personalInfor?.homeAddress?.city,
    state: user.personalInfor?.homeAddress?.state,
    postalCode: user.personalInfor?.homeAddress?.postalCode,
    country: user.personalInfor?.homeAddress?.country || 'US',
    effectiveStartDate: '',
    effectiveEndDate: '',
    ...INSURANCE_FIELDS.reduce((obj, field) => {
      obj[`${field}_person`] = 0;
      obj[`${field}_accident`] = 0;
      return obj;
    }, {}),
  };

  const currentInsurancePolicyName = Form.useWatch('insurancePolicyName', form);

  return (
    <Form form={form} labelCol={{ span: 24 }} initialValues={INITIAL_VALUES}>
      {/* <Text variant="h5">Insured Address</Text>
        <AddressForm
          t={t}
          allFieldsOptional
          formInstance={form}
          formDisabled={isSubmitting || disabled}
          countries={supportedCountries}
          values={userAddress}
          onValueChange={newValues => {
            setUserAddress(state => ({ ...state, ...newValues }));
            form.setFieldsValue(newValues);
          }}
        /> */}

      <Row align="middle" gutter={16}>
        <Col xs={24}>
          <Form.Item
            label={t('insuranceCompany')}
            name="insurancePolicyName"
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <InsuranceCompanySelect
              t={t}
              fullWidth
              showOtherOption
              disabled={isSubmitting || disabled}
              onChange={value => {
                form.setFieldsValue({ insurancePolicyName: value, otherInsurancePolicyName: '' });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {currentInsurancePolicyName === 'other' && (
        <Row align="middle" gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="otherInsurancePolicyName"
              rules={[
                {
                  required: currentInsurancePolicyName === 'other',
                  message: ERROR_MESSAGE().BLANK_FIELD,
                },
              ]}
            >
              <TextInput
                placeholder={t('typeNameOfInsuranceCompany')}
                disabled={isSubmitting || disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row align="middle" gutter={16}>
        <Col xs={12}>
          <Form.Item
            label={t('policyStartDate')}
            name="effectiveStartDate"
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <DatePicker style={{ width: '100%' }} disabled={isSubmitting || disabled} />
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item
            label={t('policyEndDate')}
            name="effectiveEndDate"
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <DatePicker style={{ width: '100%' }} disabled={isSubmitting || disabled} />
          </Form.Item>
        </Col>
      </Row>

      <InsurancePerPersonPerAccident
        t={t}
        variant="user"
        formIntance={form}
        disabled
        isSubmitting={isSubmitting}
      />

      {!hideInsuranceDocUpload && (
        <InsuranceDocumentsSection
          isUploadingFile={isUploadingFile}
          userDetails={user}
          insurancePolicy={insurancePolicy}
          documentList={documentList}
          documentsToCreateList={documentsToCreateList}
          onFileChange={onFileChange}
          onFileToCreateChange={onFileToCreateChange}
        />
      )}

      {!disabled && (
        <Row justify="end" align="middle" wrap={false} gutter={10}>
          {userComplianceDetails?.insuranceComplianceStatus === STATUS_LIST().Status.APPROVED && (
            <Col>
              <FadedText>{t('modifyingInsuranceDriverLicenseWarning')}</FadedText>
            </Col>
          )}

          {userComplianceDetails?.insuranceRequireConfirmation && (
            <Col>
              <Button disabled={isSubmitting || disabled} onClick={onConfirmInfoIsUnchanged}>
                {t('confirmInfoIsUnchanged')}
              </Button>
            </Col>
          )}

          <Col>
            <SubmitCancelButtonGroup
              hideCancel
              submitText={t('Save')}
              loading={isSubmitting}
              disabled={isSubmitting || disabled}
              onSubmit={() => {
                form.validateFields().then(values => {
                  onSubmit({
                    ...values,
                    insurancePolicyName:
                      values.otherInsurancePolicyName || values.insurancePolicyName,
                    insuredFirstName: user.firstName,
                    insuredLastName: user.lastName,
                    ...(!insurancePolicy ? { files: documentsToCreateList } : {}),
                  });
                });
              }}
            />
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default UserInsuranceForm;
