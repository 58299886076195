import { Col, Row } from 'antd';
import { capitalize, get } from 'lodash';
import React, { useMemo } from 'react';

import { getUserFullName } from '../../utils/common';
import Box from '../Box';
import Text from '../Text';
import classNames from './style.module.scss';

const UserProfileDetailsSummary = props => {
  const { t, userDetails, groupDetails } = props;

  const groupProduct = useMemo(() => get(userDetails, 'group.productId') || {}, [userDetails]);

  const bottomBoxContent = [
    {
      label: t('Group'),
      value: groupDetails?.name ? groupDetails.name : null,
    },
    {
      label: t('groupManager'),
      value: groupDetails?.groupManager ? getUserFullName(groupDetails.groupManager) : null,
    },
    {
      label: t('Products'),
      value: groupProduct?.name ? groupProduct.name : null,
    },
    {
      label: t('Payment Frequency'),
      value: groupDetails?.paymentScheduleId
        ? capitalize(t(get(groupDetails, 'paymentScheduleId.paymentInterval')))
        : null,
    },
    {
      label: t('paymentApproval'),
      value: groupProduct?.approvalConfig
        ? t(`${groupProduct.approvalConfig}ApprovalConfig_short`)
        : null,
    },
    {
      label: t('mileageCapture'),
      value: t('kliksMobileApp'),
    },
  ];

  return (
    <Box>
      <Row>
        <Col xs={24} lg={12}>
          {bottomBoxContent.map(content => (
            <Row key={content.label} className={classNames.bottomBoxRow} justify="space-between">
              <Col>
                <Text variant="strong" size="sm" renderAs="div">
                  {content.label}
                </Text>
              </Col>
              <Col>
                <Text variant="p" size="sm" renderAs="div">
                  {content.value || '-'}
                </Text>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Box>
  );
};

export default UserProfileDetailsSummary;
