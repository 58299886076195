import { BellOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import NavIcon from '../../layout/LayoutHeader/NavIcon';
import classNames from './style.module.scss';

const NotificationBell = props => {
  const { count } = props;

  return (
    <div className={classNames.bellWrapper}>
      <Badge count={count} size="small">
        <NavIcon Icon={() => <BellOutlined style={{ fontSize: 24 }} />} />
      </Badge>
    </div>
  );
};

NotificationBell.defaultProps = {
  count: 0,
};

export default NotificationBell;
