import { Col, Row } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { ESG_SCOPE_EMISSIONS_API } from '../../api/esg-scope-emissions';
import { PAYMENT_SCHEDULES_API } from '../../api/paymentSchedule';
import DownloadIcon from '../../components/DownloadIcon';
import PageFiltersRenderer from '../../components/shared-ui/PageFiltersRenderer';
import DriversEmissionsTable from '../../components/Table/DriversEmissionsTable';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedFiltersQuery from '../../hooks/queries/usePaginatedFiltersQuery';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import usePaymentSchedulePeriods from '../../hooks/usePaymentSchedulePeriods';
import useTableSort from '../../hooks/useTableSort';

const DriverEmissionsView = props => {
  const { t, location } = props;

  const { paymentScheduleId, fromDate, endDate } = useLocationSearchQueryParser(location);

  const {
    isFetchingPaymentSchedules,
    paymentScheduleOptions,
    selectedPaymentSchedule,
    selectedPaymentSchedulePeriods,
    selectedPaymentPeriod,
    handlePaymentScheduleSelect,
    handlePeriodSelect,
  } = usePaymentSchedulePeriods({ paymentScheduleId, fromDate, endDate });

  const { stringTableSort, handleTableSort } = useTableSort({
    'userId.firstName': 1,
    'userId.lastName': 1,
  });
  const {
    query: paymentScheduleEmissionsQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      enabled: !!selectedPaymentSchedule?._id && !!selectedPaymentPeriod,
      queryKey: [
        'fetchPaymentScheduleEmissions',
        selectedPaymentSchedule?._id,
        selectedPaymentPeriod,
        stringTableSort,
      ],
      placeholderData: { documents: [], totalCount: 0 },
      queryFn: () =>
        PAYMENT_SCHEDULES_API.fetchPaymentScheduleEmissions(
          selectedPaymentSchedule?._id,
          {
            paymentPeriodStart: selectedPaymentPeriod
              ? get(JSON.parse(selectedPaymentPeriod), 'fromDate')
              : '',
            paymentPeriodEnd: selectedPaymentPeriod
              ? get(JSON.parse(selectedPaymentPeriod), 'endDate')
              : '',
          },
          stringTableSort,
          paginationConfig.current,
          paginationConfig.pageSize,
        ),
      onError: error => handleApiErrors(error.response),
    },
    {
      resetPageQueryKey: [selectedPaymentSchedule?._id, selectedPaymentPeriod],
    },
  );

  const emissionsExportMutation = useMutation(
    () =>
      ESG_SCOPE_EMISSIONS_API.exportEmissionsToCSV({
        paymentScheduleId: selectedPaymentSchedule?._id,
        paymentPeriodStart: selectedPaymentPeriod
          ? get(JSON.parse(selectedPaymentPeriod), 'fromDate')
          : '',
        paymentPeriodEnd: selectedPaymentPeriod
          ? get(JSON.parse(selectedPaymentPeriod), 'endDate')
          : '',

        sort: stringTableSort,
      }),
    {
      onError: error => handleApiErrors(error.response),
    },
  );

  return (
    <PageContainer title={t('ghg/emissionsScores')}>
      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col flex={1}>
          <PageFiltersRenderer
            loading={paymentScheduleEmissionsQuery.isFetching}
            onFiltersChange={data => {
              if (data.paymentScheduleId) handlePaymentScheduleSelect(data.paymentScheduleId);
              if (data.paymentPeriod) handlePeriodSelect(data.paymentPeriod);
            }}
            filters={[
              {
                hidden: isFetchingPaymentSchedules,
                componentType: 'select',
                label: t('paymentSchedules'),
                name: 'paymentScheduleId',
                defaultValue: selectedPaymentSchedule?._id || paymentScheduleId || null,
                value: selectedPaymentSchedule?._id,
                options: paymentScheduleOptions || [],
                responsive: { xs: 24, md: 12, lg: 8 },
              },
              {
                hidden:
                  isFetchingPaymentSchedules ||
                  !selectedPaymentSchedulePeriods?.length ||
                  !selectedPaymentSchedule?._id,
                componentType: 'select',
                label: t('paymentPeriod'),
                name: 'paymentPeriod',
                defaultValue: selectedPaymentPeriod || null,
                value: selectedPaymentPeriod,
                options: selectedPaymentSchedulePeriods || [],
                responsive: { xs: 24, md: 12, lg: 6 },
                placeholder: t('selectPeriod'),
              },
            ]}
          />
        </Col>

        <DownloadIcon
          t={t}
          colWidth="65px"
          text={t('exportToCSV')}
          loading={emissionsExportMutation.isLoading || paymentScheduleEmissionsQuery.isFetching}
          onClick={emissionsExportMutation.mutateAsync}
          disabled={!paginationConfig.total || paginationConfig.total === 0}
        />
      </Row>

      <DriversEmissionsTable
        t={t}
        asyncSort
        loading={paymentScheduleEmissionsQuery.isFetching || isFetchingPaymentSchedules}
        dataSource={get(paymentScheduleEmissionsQuery.data, 'documents')}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
          handleTableSort(sorters?.columnKey, sorters?.order);
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(DriverEmissionsView));
