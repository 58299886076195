import React, { useEffect, useState } from 'react';

import { setModalFullscreenWidth } from '../../../utils/common';
import SpaceSpinner from '../../SpaceSpinner';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import TripsCommentsTimeline from '../../TripsCommentsTimeline';
import GenericModal from '../GenericModal';

const TripsCommentsModal = props => {
  const {
    t,
    visible,
    title,
    loading,
    isAddingComment,
    paginationConfig,
    onPageChange,
    onCancel,
    comments,
    onAddComment,
    canAddComment,
  } = props;

  const [comment, setComment] = useState('');

  useEffect(() => {
    if (!visible) {
      setComment('');
    }
  }, [visible]);

  return (
    <GenericModal
      width={setModalFullscreenWidth(800)}
      title={title || t('comments')}
      visible={visible}
      closable={isAddingComment || loading ? false : true}
      onCancel={onCancel}
      footer={
        <SubmitCancelButtonGroup
          disabled={loading}
          loading={isAddingComment}
          cancelText={!canAddComment ? t('Close') : undefined}
          onCancel={onCancel}
          submitText={t('addComment')}
          onSubmit={() =>
            onAddComment(comment).then(() => {
              setComment('');
            })
          }
          hideSubmit={!canAddComment}
        />
      }
    >
      {!Array.isArray(comments) || loading ? (
        <SpaceSpinner height={150} />
      ) : (
        <TripsCommentsTimeline
          t={t}
          isModal
          canAddComment={canAddComment}
          paginationConfig={paginationConfig}
          onPageChange={onPageChange}
          comments={comments}
          commentInputProps={{
            value: comment,
            onChange: e => setComment(e.target.value),
          }}
        />
      )}
    </GenericModal>
  );
};

export default TripsCommentsModal;
