import './style.scss';

import { Form } from 'antd';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
// the hoc
import { withNamespaces } from 'react-i18next';

import { ERROR_MESSAGE } from '../../utils/constants';
import TextInput from '../TextInput';

const ThreeInput = props => {
  const { t, showLabel, custom, fieldOneProps, fieldTwoProps, fieldThreeProps } = props;

  return (
    <div className="three-input">
      <Form.Item
        {...fieldOneProps}
        required
        labelCol={{ span: 24 }}
        label={showLabel ? t('Start') : ''}
        className="three-input-label"
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput {...fieldOneProps} disabled required />
      </Form.Item>

      <Form.Item
        {...omit(fieldTwoProps, ['onChange'])}
        required
        labelCol={{ span: 24 }}
        label={showLabel ? t('End') : undefined}
        className="three-input-label"
        rules={[
          {
            validator: (_, value) => {
              if (!value && value !== 0) {
                return Promise.reject(ERROR_MESSAGE().BLANK_FIELD);
              }

              if (parseFloat(value) > parseFloat(fieldOneProps.value)) {
                return Promise.resolve();
              }

              return Promise.reject(ERROR_MESSAGE().HIGHER_THAN(parseFloat(fieldOneProps.value)));
            },
          },
        ]}
      >
        <TextInput {...fieldTwoProps} />
      </Form.Item>

      {custom && (
        <Form.Item
          {...omit(fieldThreeProps, ['onChange'])}
          required
          labelCol={{ span: 24 }}
          label={showLabel ? t('Rate') : undefined}
          className="three-input-label"
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <TextInput {...fieldThreeProps} />
        </Form.Item>
      )}

      {!custom && (
        <Form.Item
          {...omit(fieldThreeProps, ['onChange'])}
          required
          labelCol={{ span: 24 }}
          label={showLabel ? t('Rate') : undefined}
          className="three-input-label"
          rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
        >
          <TextInput {...fieldThreeProps} />
        </Form.Item>
      )}
    </div>
  );
};

ThreeInput.propTypes = {
  value1: PropTypes.string,
  value2: PropTypes.string,
  value3: PropTypes.string,
  showLabel: PropTypes.bool,
  custom: PropTypes.bool,
  country: PropTypes.string,
  onChange1: PropTypes.func,
  onChange2: PropTypes.func,
  onChange3: PropTypes.func,
};

ThreeInput.defaultProps = {
  value1: '',
  value2: '',
  value3: '',
  showLabel: false,
  custom: false,
  country: 'None',
  onChange1: () => {},
  onChange2: () => {},
  onChange3: () => {},
  fieldOneProps: {},
  fieldTwoProps: {},
  fieldThreeProps: {},
};

export default withNamespaces()(ThreeInput);
