export const START_FETCHING_INVOICES = 'START_FETCHING_INVOICES';
export const FETCH_INVOICES = 'FETCH_INVOICES';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';

export function emitStartFetchingInvoices() {
  return {
    type: START_FETCHING_INVOICES,
  };
}

export function emitFetchInvoices(companyID, year) {
  return {
    type: FETCH_INVOICES,
    data: { companyID, year },
  };
}

export function emitUpdateInvoices(year, invoices) {
  return {
    type: UPDATE_INVOICES,
    data: { year, invoices },
  };
}
