import { Col, Row } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { handleApiErrors } from '../../api/axiosInstance';
import { COMPANY_API, getCompanyInfo } from '../../api/company';
import { changeActivePlan, subscribeToPlan } from '../../api/subscription';
import Box from '../../components/Box';
import Button from '../../components/Button';
import PlanListSection from '../../components/PlansListSection';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS, STATUS_LIST } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useStripeCustomerQuery from '../../hooks/queries/useStripeCustomerQuery';
import useAuthUser from '../../hooks/useAuthUser';
import usePricePlans from '../../hooks/usePricePlans';
import { saveCompany, updateCompanyPlan } from '../../stores/actions/common';
import { formatPageTitle } from '../../utils/common';
import { isActiveCompany } from '../../utils/company';
import { formatCurrencyToCompanyLocale } from '../../utils/numbers';
import FreeTrialExpiredSection from './FreeTrialExpiredSection';
import FreePlanRemainingDaysSection from './FreeTrialRemainingDaysSection';
import PriceCalculator from './PriceCalculator';

const PricePlans = props => {
  const { t } = props;

  const dispatch = useDispatch();

  const { pricesStore } = usePricePlans(t);
  const { userList } = useSelector(store => store.user);
  const { authUser } = useAuthUser();
  const { currentCompany, companyData } = useSelector(store => store.common);

  const { isLoadingData } = pricesStore;
  const { companyPlan } = companyData;

  const [plan, setPlan] = useState(companyPlan?.planId?.pricePlan);
  const [isUpdatingPlan, setIsUpdatingPlan] = useState(false);

  const stripeCustomer = useStripeCustomerQuery();

  const updateCompanyWithPlan = useCallback(() => {
    getCompanyInfo(authUser.profile).then(company => dispatch(saveCompany(company)));
  }, [dispatch, authUser]);

  const handleSubscribeToPlan = async planType => {
    setIsUpdatingPlan(true);

    try {
      await subscribeToPlan(planType, currentCompany._id);
      await COMPANY_API.getActivePlan(currentCompany._id, plan => {
        dispatch(updateCompanyPlan(plan));
        updateCompanyWithPlan();
      });
      Toast({
        type: 'success',
        message: t('planUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('subscribeToPlanError'),
        });
      });
    }

    setIsUpdatingPlan(false);
  };

  const changePlan = async planType => {
    setIsUpdatingPlan(true);

    try {
      await changeActivePlan(planType, currentCompany._id);

      await COMPANY_API.getActivePlan(currentCompany._id, plan => {
        dispatch(updateCompanyPlan(plan));
        updateCompanyWithPlan();
      });
      Toast({
        type: 'success',
        message: t('planUpdateSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('changePlanError'),
        });
      });
    }

    setIsUpdatingPlan(false);
  };

  const handleNext = async () => {
    if (!companyPlan || (companyPlan && companyPlan.status === STATUS_LIST().Status.DEACTIVATED)) {
      // Subscribe to plan
      await handleSubscribeToPlan(plan);
    } else {
      // Update plan
      await changePlan(plan);
    }
  };

  const HAS_FREE_TRIAL = !!currentCompany.freeTrialInfo && companyPlan && companyPlan.isTrialActive;

  const FREE_TRIAL_EXPIRED = HAS_FREE_TRIAL
    ? moment().isAfter(moment(currentCompany.freeTrialInfo.trialEndDate)) ||
      currentCompany.status === STATUS_LIST().Status.TRIAL_END
    : false;

  const HAS_CANCELLED_PAID_PLAN = !!(
    companyPlan &&
    companyPlan.isCancelled &&
    (companyPlan.endDate || companyPlan.estimatedEndDate)
  );

  const HAS_CUSTOM_PLAN = companyPlan?.planId?.pricePlan > 3;
  const IS_COMPANY_CANCELLED = currentCompany.status === STATUS_LIST().Status.CANCELLED;

  return (
    <PageContainer
      title={t('Update your Pricing Plan')}
      sideActionComponent={
        <Helmet>
          <title>{formatPageTitle('Update Pricing Plan')}</title>
        </Helmet>
      }
      loading={isLoadingData || stripeCustomer.isFetching}
    >
      {!HAS_FREE_TRIAL &&
        HAS_CANCELLED_PAID_PLAN &&
        !!get(stripeCustomer, 'data.defaultSource') &&
        !IS_COMPANY_CANCELLED && (
          <Row style={{ marginBottom: 30 }}>
            <Col>
              <Text variant="p" size="medium">
                <Trans
                  t={t}
                  i18nKey="subCancellationWillTakeEffectOn"
                  values={{
                    endDate: moment(companyPlan.endDate || companyPlan.estimatedEndDate).format(
                      'MMMM Do, YYYY',
                    ),
                    endTime: moment(companyPlan.endDate || companyPlan.estimatedEndDate).format(
                      'hh:mm A',
                    ),
                  }}
                  components={[
                    <Text variant="strong" size="medium">
                      DummyText
                    </Text>,
                  ]}
                />
              </Text>

              <Text variant="p" size="medium">
                <Trans
                  t={t}
                  i18nKey="ifYouDontWantToCancel"
                  components={[
                    <Link
                      to={
                        !companyPlan?.isPaidPlanActive &&
                        companyPlan?.isTrialActive &&
                        companyPlan?.isCancelled
                          ? INTERNAL_LINKS.BILLING_METHOD
                          : INTERNAL_LINKS.RESTORE_PLAN
                      }
                    >
                      <LinkText variant="strong" size="medium">
                        DummyText
                      </LinkText>
                    </Link>,
                  ]}
                />
              </Text>
            </Col>
          </Row>
        )}

      {HAS_FREE_TRIAL && (
        <>
          {FREE_TRIAL_EXPIRED ? (
            <FreeTrialExpiredSection
              t={t}
              selectedPlan={plan}
              hasBillingMethod={!!get(stripeCustomer, 'data.defaultSource')}
            />
          ) : (
            <FreePlanRemainingDaysSection
              t={t}
              planName={companyPlan?.planId?.type}
              startDate={currentCompany.freeTrialInfo.trialStartDate}
              endDate={currentCompany.freeTrialInfo.trialEndDate}
              totalFreeTrialDays={currentCompany.freeTrialInfo.freeTrialDays}
              hasBillingMethod={
                currentCompany.status === STATUS_LIST().Status.ACTIVE ||
                !!get(stripeCustomer, 'data.defaultSource')
              }
            />
          )}
        </>
      )}

      {HAS_CUSTOM_PLAN && (
        <Row justify="center" style={{ marginBottom: 20 }}>
          <Col flex="643px">
            <Box>
              <Text textAlign="center" variant="h4" renderAs="p">
                {typeof companyPlan?.planId?.price === 'number'
                  ? t('youAreOnKliksCustomPlan_withAmount', {
                      amount: formatCurrencyToCompanyLocale((companyPlan.planId.price || 0) / 100),
                    })
                  : t('youAreOnKliksCustomPlan')}
              </Text>
            </Box>
          </Col>
        </Row>
      )}

      {pricesStore.plans && !HAS_CUSTOM_PLAN && (
        <Row justify="center">
          <Col flex="643px">
            <PriceCalculator
              t={t}
              currentPlan={companyPlan}
              plans={pricesStore.plans}
              initialNumber={
                isActiveCompany(currentCompany)
                  ? userList.filter(u => u.status === STATUS_LIST().Status.ACTIVE).length
                  : 0
              }
            />
          </Col>
        </Row>
      )}

      <PlanListSection
        selectedPlan={plan}
        plans={pricesStore.plans}
        disabled={HAS_CUSTOM_PLAN}
        onCardClick={setPlan}
      />

      <Row className="price-form-actions" gutter={17} align="middle" justify="center">
        {!HAS_CUSTOM_PLAN && (
          <Col>
            <Button
              loading={isUpdatingPlan}
              disabled={!plan || companyPlan?.planId?.pricePlan === plan || isUpdatingPlan}
              text={
                !companyPlan ||
                (companyPlan && companyPlan.status === STATUS_LIST().Status.DEACTIVATED)
                  ? 'create'
                  : 'update'
              }
              type="primary"
              onClick={() => handleNext()}
            />
          </Col>
        )}

        {companyPlan &&
          !companyPlan.isCancelled &&
          !!get(stripeCustomer, 'data.defaultSource') &&
          !IS_COMPANY_CANCELLED &&
          !isUpdatingPlan && (
            <>
              {!HAS_CUSTOM_PLAN && (
                <Col>
                  <Text variant="p" size="medium">
                    {t('-- or --')}
                  </Text>
                </Col>
              )}

              <Col>
                <Link to={INTERNAL_LINKS.CONFIRM_PLAN_CANCELLATION}>
                  <LinkText size="large">{t('cancelSubscription')}</LinkText>
                </Link>
              </Col>
            </>
          )}
      </Row>
    </PageContainer>
  );
};

PricePlans.defaultProps = {
  data: {},
  readOnly: false,
  onChange: () => {},
};

export default withNamespaces()(withAuthentication(PricePlans));
