import React from 'react';

import usePaymentSchedules from '../../hooks/usePaymentSchedules';
import Select from '../Select';

const PaymentScheduleSelect = props => {
  const { disabled, ...rest } = props;

  const { isFetchingPaymentSchedules, paymentScheduleOptions } = usePaymentSchedules();

  return (
    <Select
      {...rest}
      fullWidth
      showSearch
      allowClear={false}
      disabled={disabled || isFetchingPaymentSchedules}
      loading={isFetchingPaymentSchedules}
      options={paymentScheduleOptions}
      optionFilterProp="children"
    />
  );
};

export default PaymentScheduleSelect;
