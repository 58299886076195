import { Form } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { setModalFullscreenWidth } from '../../../utils/common';
import FormItem from '../../Form/FormItem';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import HorizontalSwitch from '../../Switch/HorizontalSwitch';
import GenericModal from '../GenericModal';

const initialFormValues = { tripTracking: false };

const UserSettingsBulkUpdateModal = props => {
  const { t, selectedUsersCount, loading, visible, closable, onCancel, onSubmit } = props;

  const [form] = Form.useForm();

  const modalFooter = useMemo(() => {
    return (
      <SubmitCancelButtonGroup
        size="sm"
        loading={loading}
        disabled={loading}
        onCancel={onCancel}
        submitText={t('update')}
        onSubmit={() => {
          form.validateFields().then(values => {
            onSubmit({
              ...values,
              tripTracking: values.tripTracking ? 'radarSDK' : 'kliksCustom',
            });
          });
        }}
      />
    );
  }, [t, form, loading, onCancel, onSubmit]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <GenericModal
      centered
      width={setModalFullscreenWidth(500)}
      title={t('updateUserSettingsForUsers__count', { count: selectedUsersCount })}
      visible={visible}
      closable={closable}
      onCancel={onCancel}
      footer={modalFooter}
    >
      <Form name="settings" form={form} initialValues={initialFormValues}>
        <div>
          <FormItem name="tripTracking">
            <HorizontalSwitch label={t('useRadarSdk')} defaultChecked={false} />
          </FormItem>
        </div>
      </Form>
    </GenericModal>
  );
};

export default UserSettingsBulkUpdateModal;
