import { Col, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

import TabsContainer from '../TabsContainer';
import Text from '../Text';
import LinkText from '../Text/LinkText';
import TripsCommentsTimeline from '../TripsCommentsTimeline';

const COMMENTS_TAB = 'comments';
const NOTE_TAB = 'note';

const TripNotesAndCommentsSection = props => {
  const { t, trip, onViewMoreComments } = props;

  const [activeTab, setActiveTab] = useState();

  const HAS_COMMENTS = !!trip.latestComment;

  useEffect(() => {
    if (HAS_COMMENTS) {
      setActiveTab(COMMENTS_TAB);
    } else if (trip.notes) {
      setActiveTab(NOTE_TAB);
    } else {
      setActiveTab();
    }
    // eslint-disable-next-line
  }, []);

  if (!activeTab) {
    return null;
  }

  return (
    <TabsContainer activeKey={activeTab} onChange={setActiveTab}>
      <Tabs.TabPane key={NOTE_TAB} tab={t('note')} disabled={!trip.notes}>
        {/* Note */}
        <Text>{trip.notes}</Text>
      </Tabs.TabPane>

      <Tabs.TabPane key={COMMENTS_TAB} tab={t('comments')} disabled={!HAS_COMMENTS}>
        {/* Comment */}
        <TripsCommentsTimeline
          t={t}
          comments={HAS_COMMENTS ? [trip.latestComment] : []}
          canAddComment={false}
        />

        {HAS_COMMENTS && typeof onViewMoreComments === 'function' && (
          <Row justify="center">
            <Col>
              <LinkText onClick={() => onViewMoreComments(trip._id)}>{t('viewMore')}</LinkText>
            </Col>
          </Row>
        )}
      </Tabs.TabPane>
    </TabsContainer>
  );
};

export default TripNotesAndCommentsSection;
