import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import CrmContactsAPI from '../../api/crmContacts';
import Toast from '../../components/Toast';
import { INTERNAL_LINKS } from '../../enum';
import { emitAddNewContact, emitUpdateContactByID } from '../../stores/actions/realtor';
import {
  formatCrmContactBEToFormValues,
  formatCrmContactFormValuesToBE,
} from '../../utils/crmContacts';

const useClientDetails = ({ t, match, history }) => {
  const dispatch = useDispatch();

  const [isEditMode, setIsEditMode] = useState(!!match.params.id);

  const [contactDetails, setContactDetails] = useState();
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  useEffect(() => {
    setIsEditMode(!!match.params.id);
  }, [match.params.id]);

  const loadCrmContacts = async contactID => {
    try {
      const data = await CrmContactsAPI.fetchCrmContactDetails(contactID);
      setContactDetails(data);
    } catch (error) {
      handleApiErrors(error.response);
    }
  };

  const _handleCreateCrmContact = async values => {
    setIsProcessingRequest(true);

    try {
      const formattedValues = formatCrmContactFormValuesToBE(values);
      const data = await CrmContactsAPI.createCrmContacts(formattedValues);

      setContactDetails(data);
      dispatch(emitAddNewContact(data));

      Toast({
        type: 'success',
        message: t('clientCreate_success'),
      });

      history.replace(INTERNAL_LINKS.CLIENT_DETAILS(data._id));
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsProcessingRequest(false);
  };

  const _handleUpdateCrmContact = async values => {
    setIsProcessingRequest(true);

    try {
      const formattedValues = formatCrmContactFormValuesToBE(values);

      const data = await CrmContactsAPI.updateCrmContacts(match.params.id, formattedValues);

      const formattedContact = formatCrmContactBEToFormValues(data);
      setContactDetails(formattedContact);
      dispatch(emitUpdateContactByID(data));

      Toast({
        type: 'success',
        message: t('clientUpdate_success'),
      });
    } catch (error) {
      handleApiErrors(error.response);
    }

    setIsProcessingRequest(false);
  };

  const handleContactFormSubmit = async values => {
    if (isEditMode) {
      return _handleUpdateCrmContact(values);
    }

    return _handleCreateCrmContact(values);
  };

  useEffect(() => {
    if (match.params.id) {
      loadCrmContacts(match.params.id);
    }
  }, [match.params.id]);

  return {
    isEditMode,
    isProcessingRequest,

    contactDetails,

    handleContactFormSubmit,
  };
};

export default useClientDetails;
