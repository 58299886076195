import Toast from '../../../components/Toast';

export const validateDownloadDataRequest = (data, t) => {
  const {
    trips,
    receipts,
    reimbursements,
    profile,
    entireDateRange,
    fromDate,
    toDate,
    userIds,
  } = data;

  let isValid = true;
  const selectedFieldsToDownload = [trips, receipts, reimbursements, profile].filter(Boolean);

  if (selectedFieldsToDownload.length < 1) {
    isValid = false;
    Toast({
      type: 'error',
      message: t('mustSelectOneDataSetToDownload'),
    });
  } else if (!entireDateRange && (!fromDate || !toDate)) {
    isValid = false;
    Toast({
      type: 'error',
      message: t('youMustSelectDateRange'),
    });
  } else if (!userIds.length) {
    isValid = false;
    Toast({
      type: 'error',
      message: t('mustSelectAtLeastOneUser'),
    });
  }

  return isValid;
};
