import { Col, Row, Space } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { handleApiErrors } from '../../../api/axiosInstance';
import UserSettingsAPI from '../../../api/user-settings';
import { Button } from '../../../components';
import UserSettingsBulkUpdateModal from '../../../components/Modal/UserSettingsBulkUpdateModal';
import UserSettingsTable from '../../../components/Table/UserSettingsTable';
import Toast from '../../../components/Toast';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useModal from '../../../hooks/useModal';
import useTableSort from '../../../hooks/useTableSort';

const UserSettingsSection = props => {
  const { t, currentCompany } = props;

  const queryClient = useQueryClient();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isBulkUpdateModalOpen, openBulkUpdateModal, closeBulkUpdateModal] = useModal();

  const { stringTableSort, handleTableSort } = useTableSort({
    'user.firstName': 1,
    'user.lastName': 1,
  });

  const allUsersSettingsQueryKey = useMemo(
    () => ['fetchAllUsersSettings', currentCompany._id, stringTableSort],
    [currentCompany, stringTableSort],
  );

  const {
    query: allUsersSettingsQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery({
    queryKey: allUsersSettingsQueryKey,
    placeholderData: { documents: [], totalCount: 0 },
    queryFn: () =>
      new UserSettingsAPI().fetchAllUsersSettings(
        currentCompany._id,
        paginationConfig.current,
        paginationConfig.pageSize,
        stringTableSort,
      ),
  });

  const updateUserSettingsMutation = useMutation(new UserSettingsAPI().updateUserSettingsInBulk, {
    onError: error =>
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('updateUserSettingsError'),
        });
      }),
    onSuccess: (_, variables) => {
      if (isBulkUpdateModalOpen) closeBulkUpdateModal();

      Toast({
        type: 'open',
        message: t('updateUserSettingsSuccess'),
      });
    },
  });

  const onSingleUserSettingUpdate = useCallback(
    (userId, settings) => {
      updateUserSettingsMutation.mutateAsync([{ _id: userId, settings }]);
    },
    [updateUserSettingsMutation],
  );

  const onMultipleUserSettingUpdate = useCallback(
    settings => {
      updateUserSettingsMutation
        .mutateAsync(selectedUserIds.map(_id => ({ _id, settings })))
        .then(() => {
          queryClient.invalidateQueries({ exact: false, queryKey: allUsersSettingsQueryKey });
        });
    },
    [updateUserSettingsMutation, selectedUserIds, queryClient, allUsersSettingsQueryKey],
  );

  return (
    <Space direction="vertical" size="middle" wrap={false} style={{ maxWidth: 1100 }}>
      <Row wrap gutter={16} justify="end" align="middle">
        <Col>
          <Button
            size="sm"
            type="primary"
            onClick={openBulkUpdateModal}
            disabled={
              allUsersSettingsQuery.isFetching ||
              updateUserSettingsMutation.isLoading ||
              !selectedUserIds.length
            }
          >
            {t('updateSelectedUsers')}
          </Button>
        </Col>
      </Row>

      <div>
        <UserSettingsTable
          t={t}
          asyncSort
          hiddenColumns={['action']}
          key={allUsersSettingsQuery.dataUpdatedAt}
          loading={allUsersSettingsQuery.isFetching || updateUserSettingsMutation.isLoading}
          dataSource={get(allUsersSettingsQuery, 'data.documents', [])}
          onUserSettingUpdate={onSingleUserSettingUpdate}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
          rowSelection={{
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedUserIds,
            onChange: selectedRowKeys => setSelectedUserIds(selectedRowKeys),
          }}
          onChange={({ current }, filters, sorters) => {
            handlePageChange(current);
            handleTableSort(sorters?.columnKey, sorters?.order);
          }}
        />
      </div>

      <UserSettingsBulkUpdateModal
        t={t}
        loading={updateUserSettingsMutation.isLoading}
        closable={!(allUsersSettingsQuery.isFetching || updateUserSettingsMutation.isLoading)}
        selectedUsersCount={selectedUserIds.length}
        visible={isBulkUpdateModalOpen}
        onCancel={closeBulkUpdateModal}
        onSubmit={onMultipleUserSettingUpdate}
      />
    </Space>
  );
};

export default UserSettingsSection;
