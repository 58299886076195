import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';

import { MapboxMap } from '../../../views/map/MapboxMapsProvider';
import ErrorBoundary from '../../ErrorBoundary';
import MapLoader from '../../MapLoader';
import Text from '../../Text';
import GenericModal from '../GenericModal';
import classNames from './style.module.scss';

/**
 * Modal to display the Trip's data on Full Screen
 */
const FullScreenTripModal = props => {
  const { t, mapConfig, visible, onCancel, ...rest } = props;

  const [currentRequest, setCurrentRequest] = useState();
  const [isLoadingMapDetails, setIsLoadingMapDetails] = useState(false);
  const [detailedMapConfig, setDetailedMapConfig] = useState();

  const resetState = () => {
    setCurrentRequest();
    setIsLoadingMapDetails(false);
    setDetailedMapConfig();
  };

  useEffect(() => {
    if (visible) {
      setDetailedMapConfig(mapConfig);
      setIsLoadingMapDetails(false);
    } else if (isLoadingMapDetails && currentRequest) {
      currentRequest.cancel();
      resetState();
    } else {
      resetState();
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <GenericModal
      {...rest}
      centered
      destroyOnClose
      footer={null}
      width={'100vw'}
      className={classNames.tripsModal}
      visible={visible}
      onCancel={onCancel}
    >
      <div className={classNames.mapHeader}>
        {mapConfig.date && (
          <Text variant="p" size="sm">
            <b>Date:</b> {mapConfig.date}
          </Text>
        )}

        {mapConfig.toLoc && (
          <Text variant="p" size="sm">
            <b>From:</b> {mapConfig.fromLoc}
          </Text>
        )}

        {mapConfig.fromLoc && (
          <Text variant="p" size="sm">
            <b>To:</b> {mapConfig.toLoc}
          </Text>
        )}
      </div>

      <div className={classNames.mapWrapper}>
        {isLoadingMapDetails || !detailedMapConfig ? (
          <MapLoader />
        ) : (
          <ErrorBoundary t={t} fallback={<MapLoader text={t('errorLoadingMap')} />}>
            <MapboxMap
              key={mapConfig.tripId}
              config={{ zoom: 10, center: detailedMapConfig.center }}
              routes={detailedMapConfig.routes}
            />
          </ErrorBoundary>
        )}
      </div>
    </GenericModal>
  );
};

FullScreenTripModal.propTypes = {
  mapConfig: PropTypes.shape({}).isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withNamespaces()(FullScreenTripModal);
