import { Col, Row } from 'antd';
import React from 'react';

import { STATUS_LIST } from '../../enum';
import { renderCheckmarkFromBoolean } from '../../utils/tables';
import Text from '../Text';

const BusinessMileageItemsList = props => {
  const { t, complianceComponents } = props;

  return (
    <Row gutter={[0, 10]}>
      <Col flex="100%">
        <Row align="middle" gutter={5} wrap={false}>
          <Col>
            {renderCheckmarkFromBoolean(
              complianceComponents?.status === STATUS_LIST().Status.VALID,
            )}
          </Col>
          <Col flex={1}>
            <Text size="sm">{t('businessMileage')}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BusinessMileageItemsList;
