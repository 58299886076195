import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { SCHEDULE_TYPES } from '../../../enum/ScheduleTypes';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { checkIfStringContainsValue } from '../../../utils/common';
import { formatNumberToLocale } from '../../../utils/numbers';
import CustomTable from '../../Table';
import LinkText from '../../Text/LinkText';

const PaymentSchedulesTable = props => {
  const { t, dataSource, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(schedule => {
        return (
          // Group Name
          checkIfStringContainsValue(schedule.name, searchTerm) ||
          // users
          checkIfStringContainsValue(`${schedule.numberOfUsers}`, searchTerm) ||
          // type
          checkIfStringContainsValue(SCHEDULE_TYPES[schedule.scheduleType], searchTerm) ||
          // total trips
          checkIfStringContainsValue(`${schedule.numberOfTrips}`, searchTerm) ||
          // total distance
          checkIfStringContainsValue(`${schedule.totalDistance}`, searchTerm)
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      key: 'groupName',
      title: t('name'),
      render: (actions, data) => (
        <Link to={INTERNAL_LINKS.PAYMENT_SCHEDULE_SETUP + '/' + data._id}>
          <LinkText variant="strong" size="sm">
            {data?.name || '-'}
          </LinkText>
        </Link>
      ),
    },
    {
      key: 'type',
      title: t('type'),
      render: (actions, data) => SCHEDULE_TYPES[data.scheduleType],
    },
    {
      key: 'users',
      title: t('numberOfUsers'),
      render: (actions, data) => formatNumberToLocale(data.numberOfUsers, 0),
    },
  ];

  return (
    <CustomTable
      // Search props
      onSearchTermChange={setSearchTerm}
      // Table props
      {...rest}
      rowKey={schedule => schedule._id}
      dataSource={filteredDataSource}
      columns={COLUMNS}
    />
  );
};

export default PaymentSchedulesTable;
