export const KLIKS_SYSTEM_BASENAME = 'kliks-system';
export const BACK_OFFICE_BASENAME = 'back-office';

export default {
  HOME: '/',

  NOT_FOUND: '/404',

  LOGIN: '/signin',
  SIGNUP: '/signup',
  CONFIRMEMAIL: '/confirm-email',

  // User onboarding
  ACCOUNT_SETUP: '/account-setup',
  COMPANY_ONBOARDING: '/company-onboarding',
  USER_ONBOARDING: '/user-onboarding',
  HOME_ADDRESS: '/home-address',

  MYTASK_SETUP: '/mytask-setup',
  DASHBOARD: '/dashboard',
  GROUP_MANAGEMENT: '/group-management',
  PAYMENT_SETTINGS: '/payment-settings',
  PAYMENT_SCHEDULES: '/payment-schedules',
  PAYMENT_SCHEDULE_SETUP: '/payment-schedule-setup',
  POLICY_COMPLIANCE: '/policy-compliance',
  TRIPS: '/trips',
  MANAGE_UPLOADED_TRIPS: '/manage-uploaded-trips',
  SAFETY: '/safety',
  FLEET_SERVICES: '/fleet-services',
  VEHICLES: '/vehicles',
  VEHICLE_DETAILS: '/vehicle-details',
  INVOICE_DETAILS: '/invoice-details',
  ADD_VEHICLE: '/add-vehicle',
  EDIT_VEHICLE: '/edit-vehicle',
  REALTIME_TRACKING: '/realtime-tracking',
  TRACKING_LIST: '/tracking-list',
  CARBON_OFFEST_PROGRAM: '/carbon-offset-program',
  REPORTS: '/reports',
  REPORTS_DETAILS: (id, type) => (id ? `/reports/${id}?type=${type}` : '/reports/:id'),
  USER_MOBILE_APP_SETTINGS_REPORT: '/users-mobile-app-settings',
  EXPORTS: '/exports',
  ANOMALIES_DASHBOARD: '/anomalies/dashboard',
  ANOMALIES_COMMUTE_ALERT: '/anomalies/commute-alert',
  ANOMALIES_TRIPS_OVER_AVERAGE_ALERT: '/anomalies/trips-over-average-alert',
  ANOMALIES_RECEIPTS_OVER_AVERAGE_ALERT: '/anomalies/receipts-over-average-alert',
  ANOMALIES_HIGH_MANUAL_MILES_ALERT: '/anomalies/high-manual-miles-alert',
  ANOMALIES_MILEAGE_ADJUSTMENTS_ALERT: '/anomalies/mileage-adjustments-alert',
  ANOMALIES_INSURANCE_COMPLIANCE_ALERT: '/anomalies/insurance-compliance-alert',
  ANOMALIES_UNSBUBMITTED_TRIPS: '/anomalies/unsubmitted-trips',
  ANOMALIES_USER_UNSBUBMITTED_TRIPS_DETAILS: userId =>
    userId
      ? `/anomalies/unsubmitted-trips/user/${userId}`
      : '/anomalies/unsubmitted-trips/user/:id',
  ANOMALIES_UNSBUBMITTED_TRIPS_EMAIL_REMINDER: '/anomalies/unsubmitted-trips/email-reminder',
  CREATE_RATE: '/create-rate',
  INVOICES: '/invoices',
  INVOICE_BILLED_USERS: (id, year) =>
    id ? `/invoices/${id}/billed-users?year=${year}` : '/invoices/:id/billed-users',
  BILLING_METHOD: '/billing-method',
  GDPR: '/gdpr',
  DOWNLOAD_USER_DATA: '/gdpr/download-user-data',
  ANONYMIZE_USER_DATA: '/gdpr/anonymize-user-data',
  PRICE_PLANS: '/price-plans',
  CONFIRM_PLAN_CANCELLATION: '/cancel-plan',
  RESTORE_PLAN: '/restore-plan',
  GDPR_OPERATION: '/gdpr-operation',
  USER_DETAILS: '/user-details',
  USER_DETAILS_PROFILE: userId =>
    userId ? `/user-details/${userId}/profile` : '/user-details/:id/profile',
  USER_DETAILS_YOUR_DATA: userId =>
    userId ? `/user-details/${userId}/your-data` : '/user-details/:id/your-data',

  APPROVAL_SETTINGS: '/approval-settings',

  MAP: '/map',
  DRIVERS: '/drivers',
  RECEIPTS: '/receipts',
  REIMBURSEMENT: '/reimbursement',
  FINANCIAL: '/financial',
  CONFIGURATION_SUMMARY: '/configuration-summary',
  PROGRAM_MANAGEMENT: '/program-management',
  PLATFORM_SETTINGS: '/platform-settings',
  RATES: '/rates',
  RATE_DETAILS: '/rate-details',
  MANAGE_FAVR: '/manage-favr',
  SELECT_VEHICLE_MODEL: '/select-vehicle-model',
  POLICY_CONFIGURATION: '/policy-configuration',
  USER_TRIP: '/user-trip',
  DAILY_TRIP: '/daily-trip',
  USER_MANAGER: '/user-manager',
  MANAGED_USERS: '/managed-users',
  FLEET_REALTIME_TRACKING: '/fleet-realtime-tracking',
  USER_ACCOUNT_ACTIVATE: '/activate-account',
  PROFILE_WORKFLOW: '/account-setup',
  FORGOT_PASSWORD: '/forgot-password',
  GROUP: '/group',
  RESET_PASSWORD: '/reset-password',
  PAYROLL_FILE: '/payroll-file',
  PAYROLL_FILE_HISTORY: '/payroll-file-history',
  SIGNUP_SUCCESS: '/signup-success',
  COMPANY_INFO: '/company-info',
  PLAN_TRIAL: '/plan-trial',
  COMPANY: '/company',
  COMPANY_SETTINGS: '/company/settings',
  ACH_CONFIG: '/company/ach-config',
  ACH_CUSTOMER_VERIFICATION: '/company/ach-config/customer-verification',
  BANK_ACCOUNT_INFORMATION: '/company/ach-config/bank-account-information',
  ACH_TRANSACTIONS: '/company/ach-config/ach-transactions',
  ACCOUNT_BALANCE: '/account-balance',
  ACH_TRANSFERS_HOW_IT_WORKS: '/ach-transfers/how-it-works',
  MANAGE_ACCOUNT_BALANCE: '/manage-account-balance',
  DWOLLA_MASS_PAYMENT_DETAILS: id => `/mass-payment/${id || ':id'}`,
  REIMBURSEMENT_RATE: '/default-rate-setup',
  INVITE_USERS: '/invite-users',
  BULK_INVITE: '/csv-bulk-invite',
  USER_PROGRAM_COMPLIANCE: '/user-program-compliance',
  COMPLIANCE_DETAILS: id =>
    id ? `/user-program-compliance/${id}` : '/user-program-compliance/:id',
  USER_TAX_REPORT: id => (id ? `/user-tax-report/${id}` : '/user-tax-report/:id'),
  COMPANY_TAX_REPORT: '/company-tax-report',
  COMPLIANCE_SETTINGS: '/compliance-settings',
  COMPANY_API_KEYS: '/company/api-keys',
  COMPANY_PAYROLL_FILE: '/company/payroll-file',

  LOCATIONS: '/locations',
  LOCATIONS_DETAILS: '/locations-details',
  DAILY_MILEAGE_LOG: '/daily-mileage-log',

  DRIVERS_EGS_EMISSIONS: '/driver-emissions',
  EMISSIONS_DETAILS: '/emissions-details',

  UPDATE_METABASE_REPORT: id => `/update/metabase/${id || ':id'}`,
  CREATE_METABASE_REPORT: '/create/metabase',

  USER_SETTINGS: '/user-settings',

  // User details route
  USER_REIMBURSEMENT_RATE: '/reimbursement-rate',
  USER_BUSINESS_VEHICLE: '/business-vehicle',
  USER_DRIVERS_LICENSE: 'driver-license',
  USER_INSURANCE_INFO: 'insurance-info',
  USER_BANK_INFO: 'bank-info',
  USER_MOBILE_APP_SETTINGS: 'mobile-app-settings',
  USER_DETAILS_MOBILE_APP_SETTINGS: userId =>
    userId
      ? `/user-details/${userId}/mobile-app-settings`
      : '/user-details/:id/mobile-app-settings',
  USER_PROFILE_BANK_INFO: userId =>
    userId ? `/profile/${userId}/bank-info` : '/profile/:id/bank-info',

  // System Admin Routes
  KLIKS_SYSTEM: `/${KLIKS_SYSTEM_BASENAME}`,
  SELECT_COMPANY: '/select-company',
  GLOBAL_SETTINGS: '/global-settings',
  COUNTRY_RATES: '/country-rates',
  CREATE_COUNTRY_RATES: '/create/country-rates',
  UPDATE_COUNTRY_RATES: '/update/country-rates',
  CREATE_COUNTRY: '/create/country',
  UPDATE_COUNTRY: '/update/country',
  SYSTEM_ADMINS: '/system-admins',
  INSURANCE_COMPANIES: '/insurance-companies',
  UPDATE_SYSTEM_ADMINS: '/update/system-admins',
  COMPLIANCE_WORKLIST: '/compliance-worklist',
  TRIP_ANOMALIES: '/trip-anomalies',
  COMPLIANCE_WORKLIST_DETAILS: id =>
    id ? `/compliance-worklist-details/${id}` : '/compliance-worklist-details/:id',

  STANDARD_VEHICLES: '/standard-vehicles',
  CUSTOM_PLANS: '/custom-plans',
  FAVR_RATES: '/favr-rates',

  // Realtor Module
  ITINERARIES_LIST: '/itineraries',
  ITINERARY_DETAILS: id => (id ? `/itinerary/${id}` : '/itinerary/:id'),
  CLIENT: '/client',
  CLIENTS_LIST: '/clients',
  CLIENT_DETAILS: id => (id ? `/client/${id}` : '/client/:id?'),
  SHARE_ITINERARY: id => (id ? `/itinerary/${id}/share` : '/itinerary/:id/share'),
  PUBLIC_ITINERARY_DETAILS: id =>
    id ? `/itinerary/${id}/share-public` : '/itinerary/:id/share-public',

  // Back Office
  BACK_OFFICE_HOME: `/${BACK_OFFICE_BASENAME}`,
  VEHICLE_POLICY_VERIFICATION: '/vehicle-policy-verification',
  INSURANCE_POLICY_VERIFICATION: '/insurance-policy-verification',
};
