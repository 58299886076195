import { handleApiCalls } from './axiosInstance';

export const fetchCountryCodeMapping = async () => {
  const url = `${process.env.REACT_APP_HOST_API}country-code-mappings`;
  const response = await handleApiCalls('get', url);

  return response.data;
};

export const fetchCountryCodeMappingByID = async id => {
  const url = `${process.env.REACT_APP_HOST_API}country-code-mappings/${id}`;
  const response = await handleApiCalls('get', url);

  return response.data;
};

export const createCountryCodeMapping = async data => {
  const url = `${process.env.REACT_APP_HOST_API}country-code-mappings`;
  const response = await handleApiCalls('post', url, data);

  return response.data;
};

export const updateCountryCodeMapping = async (id, data) => {
  const url = `${process.env.REACT_APP_HOST_API}country-code-mappings/${id}`;
  const response = await handleApiCalls('put', url, data);

  return response.data;
};

export const deleteCountryCodeMapping = async id => {
  const url = `${process.env.REACT_APP_HOST_API}country-code-mappings/${id}`;
  const response = await handleApiCalls('delete', url);

  return response.data;
};
