import React from 'react';
import PropTypes from 'prop-types';

import classNames from './style.module.scss';

/**
 * Renders a Horizontal line to separate content
 */
const HorizontalLineDivider = props => {
  const { thickness, marginTop, marginBottom, color, ...rest } = props;

  return (
    <div
      style={{ marginTop, marginBottom, backgroundColor: color, height: thickness }}
      {...rest}
      className={classNames.line}
    />
  );
};

HorizontalLineDivider.propTypes = {
  thickness: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

HorizontalLineDivider.defaultProps = {
  // Height in pixels
  thickness: 1,
  marginTop: undefined,
  marginBottom: undefined,
  color: undefined,
};

export default HorizontalLineDivider;
