import React, { useMemo } from 'react';

import { checkIfMobileAppSettingIsValid, momentTimezone } from '../../../utils/common';
import { CustomTable } from '../..';
import Text from '../../Text';

const MobileAppSettingHistoryTable = props => {
  const { t, type, dataSource, ...rest } = props;

  const columns = useMemo(
    () => [
      {
        key: 'created',
        title: t('date'),
        dataIndex: 'created',
        render: created => momentTimezone(created).format('LL [-] hh:mm a zz'),
      },
      {
        key: 'location',
        title: t('locationSettings'),
        dataIndex: 'location',
        render: location => {
          const isSettingValid = checkIfMobileAppSettingIsValid(type, 'location', location);
          return (
            <Text color={isSettingValid ? 'primary' : 'red'}>
              {t(isSettingValid ? 'valid' : 'invalid')}
            </Text>
          );
        },
      },
      {
        key: 'battery',
        title: t('powerSettings'),
        dataIndex: 'battery',
        render: battery => {
          const isSettingValid = checkIfMobileAppSettingIsValid(type, 'battery', battery);
          return (
            <Text color={isSettingValid ? 'primary' : 'red'}>
              {t(isSettingValid ? 'valid' : 'invalid')}
            </Text>
          );
        },
      },
      {
        key: 'other',
        title: t('notificationsSettings'),
        dataIndex: 'other',
        render: other => {
          const isSettingValid = checkIfMobileAppSettingIsValid(type, 'other', other);
          return (
            <Text color={isSettingValid ? 'primary' : 'red'}>
              {t(isSettingValid ? 'valid' : 'invalid')}
            </Text>
          );
        },
      },
    ],
    [t, type],
  );

  return (
    <CustomTable {...rest} columns={columns} dataSource={dataSource} showSearchInput={false} />
  );
};

export default MobileAppSettingHistoryTable;
