import React from 'react';

import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { formatNumberToLocale } from '../../../utils/numbers';
import { CustomTable } from '../..';

const DriverEmissionsDetailsTable = props => {
  const { t, dataSource, ...rest } = props;

  const COLUMNS = [
    {
      title: t('period'),
      dataIndex: 'paymentPeriodStart',
      key: 'paymentPeriodStart',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (paymentPeriodStart, data) =>
        [
          getMomentDateWithoutTimezone(paymentPeriodStart).format('MMM Do, YYYY'),
          getMomentDateWithoutTimezone(data.paymentPeriodEnd).format('MMM Do, YYYY'),
        ].join(' - '),
    },
    {
      title: t('co2e'),
      dataIndex: 'co2Emission',
      key: 'co2Emission',
      sorter: true,
      render: co2Emission => formatNumberToLocale(co2Emission),
    },
    {
      title: t('businessMileage'),
      dataIndex: 'mileage',
      key: 'mileage',
      sorter: true,
      render: mileage => formatNumberToLocale(mileage),
    },
    {
      title: t('vehicle'),
      dataIndex: 'vehicle',
      key: ['vehicle.make', 'vehicle.model', 'vehicle.year'].join(','),
      sorter: true,
      render: vehicle => (vehicle ? `${vehicle.model} ${vehicle.make}, ${vehicle.year}` : '-'),
    },
    {
      title: t('ghgScore'),
      dataIndex: 'vehicle',
      key: 'vehicle.ghgScore',
      sorter: true,
      render: vehicle => (vehicle ? vehicle.ghgScore : '-'),
    },
    {
      title: t('co2eGms'),
      dataIndex: 'vehicle',
      key: 'vehicle.co2TailpipeGpm',
      sorter: true,
      render: vehicle => (vehicle ? vehicle.co2TailpipeGpm : '-'),
    },
  ];

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

export default DriverEmissionsDetailsTable;
