import { Col, DatePicker, Form, Row } from 'antd';
import { omit } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { EXTERNAL_LINKS } from '../../../enum';
import useDebounce from '../../../hooks/useDebounce';
import { canFilterByDriver, canFilterByGroup } from '../../../utils/permissions';
import { replaceCurrentPageSearchQueryParams } from '../../../utils/queryParams';
import CompanyUsersLookupSelect from '../../CompanyUsersLookupSelect';
import FormItem from '../../Form/FormItem';
import Select from '../../Select';
import TextInput from '../../TextInput';
import TextWithExternalLink from '../../TextWithExternalLink';
import classNames from './style.module.scss';

/**
 * Row of filters used on most pages with tables.
 * Includes the filters for Users, Groups, Status, DateRange and Search Term.
 *
 * This can be toggled on or off.
 * The Groups and User filters have also display conditionally
 * based on the `canFilterByDriver` and `canFilterByGroup` permissions on `~/utils/permissions`
 *
 * NOTE: The `onSearchTermChange` callback is delayed by 300ms
 * to prevent triggering the callback on every typed character.
 */
const CommonTableFilters = props => {
  const {
    t,
    useQueryParams,

    loggedInUserProfile,
    disabledFilters,

    userValue,
    onUserChange,

    dateRangeValue,
    onDateRangeChange,

    groupOptions,
    groupValue,
    selectedGroupId,
    onGroupChange,

    statusHelp,
    statusOptions,
    statusValue,
    onStatusChange,

    typeValue,
    typeOptions,
    onTypeChange,

    searchTermValue,
    searchInputPlaceholder,
    onSearchTermChange,

    crmMatchValue,
    onCrmMatchChange,

    hiddenFilters,
    justify,
  } = props;

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState(searchTermValue);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (useQueryParams) {
      replaceCurrentPageSearchQueryParams(
        history,
        omit(
          {
            group: groupValue,
            status: statusValue,
            searchTerm: searchTermValue,
            type: typeValue,
            startDateRange:
              Array.isArray(dateRangeValue) && dateRangeValue[0]
                ? dateRangeValue[0].toISOString()
                : undefined,
            endDateRange:
              Array.isArray(dateRangeValue) && dateRangeValue[1]
                ? dateRangeValue[1].toISOString()
                : undefined,
            user: userValue,
          },
          hiddenFilters,
        ),
      );
    }
    // eslint-disable-next-line
  }, [userValue]);

  useEffect(() => {
    if (typeof onSearchTermChange === 'function' && typeof debouncedSearchTerm !== 'undefined') {
      handleValueChange(debouncedSearchTerm, onSearchTermChange, {
        searchTerm: debouncedSearchTerm,
      });
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  /**
   * Sort the provided options by their labels
   *
   * @param {{label: string}[]} arr Array of options to sort
   */
  const sortByLabel = arr => {
    if (Array.isArray(arr)) {
      return arr.sort((a, b) => a.label.localeCompare(b.label));
    }

    return [];
  };

  const handleValueChange = (e, onChangeHandler, data, option) => {
    if (useQueryParams && !!data) {
      onChangeHandler(e, option);
      replaceCurrentPageSearchQueryParams(
        history,
        omit(
          {
            group: groupValue,
            status: statusValue,
            searchTerm: searchTermValue,
            type: typeValue,
            startDateRange:
              Array.isArray(dateRangeValue) && dateRangeValue[0]
                ? dateRangeValue[0].toISOString()
                : undefined,
            endDateRange:
              Array.isArray(dateRangeValue) && dateRangeValue[1]
                ? dateRangeValue[1].toISOString()
                : undefined,
            user: userValue,
          },
          hiddenFilters,
        ),
      );
    } else {
      onChangeHandler(e, option);
    }
  };

  return (
    <Row gutter={17} justify={justify} style={{ alignItems: 'flex-end' }}>
      {!hiddenFilters.includes('user') && canFilterByDriver(loggedInUserProfile) && (
        <Col xs={24} md={12} lg={4}>
          <Form.Item label={t('User')} labelCol={{ span: 24 }}>
            <CompanyUsersLookupSelect
              t={t}
              showAllOption
              groupId={selectedGroupId}
              value={userValue}
              disabled={disabledFilters}
              onChange={(e, option) => handleValueChange(e, onUserChange, { user: e }, option)}
            />
          </Form.Item>
        </Col>
      )}

      {!hiddenFilters.includes('dateRange') && (
        <Col xs={24} md={12} lg={6}>
          <Form.Item label={t('Date range')} labelCol={{ span: 24 }}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              allowClear={false}
              value={dateRangeValue}
              disabled={disabledFilters}
              onChange={(dates, dateStrings) =>
                handleValueChange(dates, () => onDateRangeChange(dates, dateStrings), {
                  startDateRange: Array.isArray(dates) && dates[0]?.toISOString(),
                  endDateRange: Array.isArray(dates) && dates[1]?.toISOString(),
                })
              }
            />
          </Form.Item>
        </Col>
      )}

      {!hiddenFilters.includes('group') && canFilterByGroup(loggedInUserProfile) && (
        <Col xs={24} md={12} lg={4}>
          <Form.Item label={t('Group')} labelCol={{ span: 24 }}>
            <Select
              placeholder={t('Group')}
              value={groupValue}
              disabled={disabledFilters}
              onChange={(e, option) => {
                handleValueChange(e, onGroupChange, undefined, option);
                handleValueChange(null, onUserChange, { user: null, group: e }, option);
              }}
              options={[{ label: 'All', value: null }, ...sortByLabel(groupOptions)]}
            />
          </Form.Item>
        </Col>
      )}

      {!hiddenFilters.includes('type') && (
        <Col xs={24} md={12} lg={4} className={classNames.formColumn}>
          <Form.Item label={t('Type')} labelCol={{ span: 24 }}>
            <Select
              placeholder={t('Type')}
              value={typeValue}
              disabled={disabledFilters}
              options={[{ label: 'All', value: null }, ...typeOptions]}
              onChange={(e, option) => handleValueChange(e, onTypeChange, { type: e }, option)}
            />
          </Form.Item>
        </Col>
      )}

      {!hiddenFilters.includes('status') && (
        <Col xs={24} md={12} lg={4} className={classNames.formColumn}>
          <FormItem label={t('Status')} labelCol={{ span: 24 }} help={statusHelp}>
            <Select
              placeholder={t('Status')}
              value={statusValue}
              disabled={disabledFilters}
              options={[{ label: 'All', value: null }, ...statusOptions]}
              onChange={(e, option) => handleValueChange(e, onStatusChange, { status: e }, option)}
            />
          </FormItem>
        </Col>
      )}

      {!hiddenFilters.includes('crmMatch') && (
        <Col xs={24} md={12} lg={4} className={classNames.formColumn}>
          <FormItem
            label={t('crmMatch')}
            labelCol={{ span: 24 }}
            help={
              <TextWithExternalLink
                text={t('seeOurFAQsArticleForCrmMatch')}
                linkProps={{ href: EXTERNAL_LINKS.FRESHDESK_FAQS_CRM_MATCH }}
              />
            }
          >
            <Select
              value={crmMatchValue}
              disabled={disabledFilters}
              options={[
                { label: 'All', value: null },
                { label: 'True', value: true },
                { label: 'False', value: false },
              ]}
              onChange={(e, option) =>
                handleValueChange(e, onCrmMatchChange, { crmMatch: e }, option)
              }
            />
          </FormItem>
        </Col>
      )}

      {!hiddenFilters.includes('search') && (
        <Col flex={1}>
          <Form.Item label={t('Search')} labelCol={{ span: 24 }}>
            <TextInput
              allowClear
              value={searchTerm}
              placeholder={searchInputPlaceholder || t('Search')}
              onChange={e => setSearchTerm(e.target.value)}
              disabled={disabledFilters}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

CommonTableFilters.propTypes = {
  loggedInUserProfile: PropTypes.shape({}),
  disabledFilters: PropTypes.bool,

  userOptions: PropTypes.arrayOf(PropTypes.any),
  userValue: PropTypes.any,
  onUserChange: PropTypes.func,

  dateRangeValue: PropTypes.any,
  onDateRangeChange: PropTypes.func,

  groupOptions: PropTypes.arrayOf(PropTypes.any),
  groupValue: PropTypes.any,
  onGroupChange: PropTypes.func,

  statusOptions: PropTypes.arrayOf(PropTypes.any),
  statusValue: PropTypes.any,
  onStatusChange: PropTypes.func,

  typeValue: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  typeOptions: PropTypes.any,
  onTypeChange: PropTypes.func,

  searchTermValue: PropTypes.any,
  onSearchTermChange: PropTypes.func,

  justify: PropTypes.oneOf(['start', 'end', 'center', 'space-around', 'space-between', undefined]),

  hiddenFilters: PropTypes.arrayOf(
    PropTypes.oneOf(['user', 'group', 'type', 'status', 'dateRange', 'search', 'crmMatch']),
  ),
};

CommonTableFilters.defaultProps = {
  justify: 'start',
  loggedInUserProfile: undefined,
  userOptions: [],
  groupOptions: [],
  statusOptions: [],
  typeOptions: [],
  userValue: undefined,
  dateRangeValue: [moment().subtract(30, 'days').startOf('day'), moment()],
  groupValue: undefined,
  statusValue: undefined,
  typeValue: null,
  hiddenFilters: ['crmMatch'],
  onUserChange: () => undefined,
  onDateRangeChange: () => undefined,
  onGroupChange: () => undefined,
  onStatusChange: () => undefined,
  onTypeChange: () => undefined,
  onSearchTermChange: () => undefined,

  useQueryParams: true,
};

export default withNamespaces()(CommonTableFilters);
