import './style.scss';

import { Modal } from 'antd';
import React from 'react';
// the hoc
import { withNamespaces } from 'react-i18next';

import Text from '../../Text';

/**
 * Generic modal with the base styling.
 * To be used as base for Modals with more specific use-cases.
 */
class GenericModal extends React.Component {
  render() {
    const className = `generic-modal ${this.props.className}`;
    const { title, modalProps, visible, ...rest } = this.props;

    return (
      <Modal
        {...modalProps}
        {...rest}
        open={visible}
        closeIcon={<img width="22px" src="/images/icons/close.svg" alt="close this modal" />}
        className={className}
        title={
          title && (
            <Text className="modal-title" variant="strong" size="large" renderAs="div">
              {title}
            </Text>
          )
        }
      >
        {this.props.children}
      </Modal>
    );
  }
}

GenericModal.defaultProps = {
  modalProps: {},
};

export default withNamespaces()(GenericModal);
