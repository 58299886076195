import { useQuery } from 'react-query';

import { DWOLLA_RESOURCES_API } from '../../api/dwolla-resources';
import useTablePagination from '../useTablePagination';

/**
 * @param {"mass-payment" | "transfer"} type
 */
const usePaginatedDwollaMassPaymentDetails = massPaymentId => {
  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  return {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    query: useQuery({
      enabled: !!massPaymentId,
      queryKey: [
        'mass-payment',
        massPaymentId,
        paginationConfig.current,
        paginationConfig.pageSize,
      ],
      initialData: { count: 0, documents: [] },
      queryFn: () =>
        DWOLLA_RESOURCES_API.fetchMassPaymentDetails(
          massPaymentId,
          paginationConfig.current,
          paginationConfig.pageSize,
        ),
      onSuccess: data => {
        handleTotalUpdate(data.count);
      },
    }),
  };
};

export default usePaginatedDwollaMassPaymentDetails;
