import classnames from 'classnames';
import React, { Component } from 'react';

import classNames from './style.module.scss';

/**
 * Box container
 */
class Box extends Component {
  render() {
    const {
      children,
      variant,
      hoverable,
      noShadow,
      maxWidth,
      padding,
      className,
      ...rest
    } = this.props;

    const extraClasses = [];
    const styles = {
      maxWidth,
      padding: padding || 24,
      ...(this.props.style ? this.props.style : {}),
    };

    if (variant === 'gray') extraClasses.push(classNames.gray);
    if (variant === 'dark') extraClasses.push(classNames.dark);
    if (variant === 'white') extraClasses.push(classNames.white);
    if (variant === 'primary') extraClasses.push(classNames.primary);

    if (hoverable) extraClasses.push(classNames.hoverable);

    if (noShadow) extraClasses.push(classNames.noShadow);

    return (
      <div
        {...rest}
        className={classnames('box', classNames.box, extraClasses, className)}
        style={styles}
      >
        {children}
      </div>
    );
  }
}

Box.defaultProps = {
  hoverable: false,
  style: {},
};

export default Box;
