import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { COMPANY_API } from '../../api/company';
import Toast from '../../components/Toast';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';
import { convertUserListIntoValidSelectOptions } from '../../utils/users';

/**
 *
 * @param {import('i18next').TFunction} t
 * @param {import('react-query').UseQueryOptions} otherOptions
 */
const useCompanyFavrComplianceUsersQuery = (t, otherOptions = {}) => {
  const currentCompany = useSelector(selectStoreCurrentCompany);

  return useQuery({
    ...otherOptions,
    placeholderData: [],
    staleTime: 1000 * 60 * 10, // 10 minutes
    queryKey: ['getCompanyFavrComplianceUsers', currentCompany._id],
    queryFn: () => COMPANY_API.getCompanyFavrComplianceUsers(currentCompany._id),
    select: userList => convertUserListIntoValidSelectOptions(userList),
    onError: error =>
      handleApiErrors(error.response, () => {
        return Toast({
          type: 'error',
          message: t('getCompanyFavrComplianceUsersError'),
        });
      }),
  });
};

export default useCompanyFavrComplianceUsersQuery;
