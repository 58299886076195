import { INTERNAL_LINKS } from '.';

const USER_LABEL = 'User';
const MANAGER_LABEL = 'Manager';
const ADMIN_LABEL = 'Admin';
const SYSTEM_ADMIN_LABEL = 'System Admin';
const BACK_OFFICE_USER_LABEL = 'Back Office User';

const UserRole = {
  USER: 'user',
  COMPANY_ADMIN: 'company-admin',
  SYSTEM_ADMIN: 'system-admin',
  COMPANY_MANAGER: 'company-manager',
  BACK_OFFICE_USER: 'back-office',
};

const UserRoleSelectOptions = [
  {
    label: USER_LABEL,
    value: UserRole.USER,
  },
  {
    label: ADMIN_LABEL,
    value: UserRole.COMPANY_ADMIN,
  },
  {
    label: MANAGER_LABEL,
    value: UserRole.COMPANY_MANAGER,
  },
];

const UserRoleMapping = {
  [UserRole.USER]: USER_LABEL,
  [UserRole.COMPANY_ADMIN]: ADMIN_LABEL,
  [UserRole.COMPANY_MANAGER]: MANAGER_LABEL,
  [UserRole.SYSTEM_ADMIN]: SYSTEM_ADMIN_LABEL,
  [UserRole.BACK_OFFICE_USER]: BACK_OFFICE_USER_LABEL,
};

const UserRoleHomePageRoute = {
  [UserRole.USER]: INTERNAL_LINKS.DASHBOARD,
  [UserRole.COMPANY_MANAGER]: INTERNAL_LINKS.DASHBOARD,
  [UserRole.COMPANY_ADMIN]: INTERNAL_LINKS.DASHBOARD,
  [UserRole.SYSTEM_ADMIN]: INTERNAL_LINKS.SELECT_COMPANY,
  [UserRole.BACK_OFFICE_USER]: INTERNAL_LINKS.BACK_OFFICE_HOME,
};

export { UserRole, UserRoleHomePageRoute, UserRoleMapping, UserRoleSelectOptions };

export default UserRole;
