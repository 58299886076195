import { Doughnut } from '@reactchartjs/react-chart.js';
import PropTypes from 'prop-types';
import React from 'react';

import Text from '../Text';
import classNames from './style.module.scss';

/**
 * Doughnut Chart.js graph
 */
const DoughnutChart = props => {
  const { labels, textLabels, datasets, chartWidth, ...rest } = props;

  const data = {
    labels: textLabels,
    datasets: [
      {
        ...datasets,
      },
    ],
  };

  return (
    <div className={classNames.doughnutChart}>
      <div className={classNames.chart} style={{ width: chartWidth }}>
        <div>
          <Doughnut
            options={{
              legend: { display: false },
              maintainAspectRatio: false,
              tooltips: {
                enabled: Array.isArray(textLabels) && !!textLabels.filter(Boolean).length,
              },
            }}
            {...rest}
            data={data}
          />
        </div>

        <Text variant="p" size="sm" renderAs="div" className={classNames.description}>
          {datasets.labels}
        </Text>
      </div>
      <div>
        {labels.map(
          (label, i) =>
            label && (
              <div key={label} className={classNames.label}>
                <div
                  className={classNames.labelCircle}
                  style={{
                    backgroundColor: Array.isArray(datasets.backgroundColor)
                      ? datasets.backgroundColor[i]
                      : '#A8ADB8', // $gray-40
                  }}
                />
                <Text variant="p" size="sm" renderAs="span" className={classNames.labelText}>
                  {label}
                </Text>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

DoughnutChart.propTypes = {
  chartWidth: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  textLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.shape({
    label: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.any),
    backgroundColor: PropTypes.arrayOf(PropTypes.string),
    borderColor: PropTypes.arrayOf(PropTypes.string),
    borderWidth: PropTypes.number,
  }),
};

DoughnutChart.defaultProps = {
  chartWidth: '157px',
};

export default DoughnutChart;
