import { Col, Row } from 'antd';
import React from 'react';

import { STATUS_LIST } from '../../../enum';
import { momentCompanyFormat, momentFormat } from '../../../utils/common';
import { getUserRoleLabel } from '../../../utils/users';
import { CustomTable, HelpIcon } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';

const UserManagerTable = props => {
  const { t, dataSource, loading, onRowSelect, ...rest } = props;

  const COLUMNS = [
    {
      defaultSortOrder: 'ascend',
      title: t('name'),
      key: ['firstName', 'lastName'].join(','),
      sorter: true,
      render: (actions, data) => {
        const isTwoDaysOld =
          new Date(data.created).getTime() + 2 * 24 * 60 * 60 * 1000 > new Date().getTime();

        return (
          <ProfileNamePicture
            t={t}
            showStatus={isTwoDaysOld || !data.created}
            user={{
              ...data,
              status: isTwoDaysOld || !data.created ? STATUS_LIST().Status.NEW : data.status,
            }}
          />
        );
      },
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: t('Group'),
      dataIndex: ['group', 'name'],
      key: 'group.name',
      sorter: true,
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      render: role => getUserRoleLabel(role),
      sorter: true,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status, data) => (
        <Row gutter={16} wrap={false} align="middle">
          <Col>
            <StatusTag status={status.toLowerCase()} />
          </Col>
          {data.deleteDate && (
            <Col>
              <HelpIcon
                hint={
                  <span style={{ width: 200 }}>
                    {t('userWillBeHardDeletedOnDate', {
                      date: momentFormat(data.deleteDate, 'MMM DD, YYYY @ hh:mma zz'),
                    })}
                  </span>
                }
              />
            </Col>
          )}
        </Row>
      ),
    },
    {
      title: t('lastInviteSent'),
      dataIndex: 'lastInvite',
      key: 'lastInvite',
      render: lastInvite =>
        lastInvite ? (
          <span style={{ whiteSpace: 'pre' }}>
            {momentCompanyFormat(lastInvite, 'MM/DD/YYYY[\n]hh:mm a zz')}
          </span>
        ) : (
          '-'
        ),
      sorter: true,
    },
    {
      title: t('accountActivationDate'),
      dataIndex: 'activationDate',
      key: 'activationDate',
      render: activationDate =>
        activationDate ? (
          <span style={{ whiteSpace: 'pre' }}>
            {momentCompanyFormat(activationDate, 'MM/DD/YYYY[\n]hh:mm a zz')}
          </span>
        ) : (
          '-'
        ),
      sorter: true,
    },
  ];

  return (
    <CustomTable
      {...rest}
      showSearchInput={false}
      rowSelection={{
        type: 'checkbox',
        onChange: onRowSelect,
      }}
      loading={loading}
      dataSource={dataSource}
      columns={COLUMNS}
      scroll={{ x: 1400 }}
    />
  );
};

export default UserManagerTable;
