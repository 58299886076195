import './style.scss';

import { Col, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS } from '../../enum';
import { DataModal } from '..';
import Checkbox from '../Checkbox';
import Popover from '../Popover';
import Text from '../Text';
import LinkText from '../Text/LinkText';

const CustomCheckboxGroup = ({
  t,
  options,
  type = 'primary',
  style = 'block',
  value,
  showPopover,
  groupsWithRates,
  currentRateName,
  onChange,
  companyGroups,
  disabled,
  ...rest
}) => {
  const newClassName = `custom-checkbox-group ${type} ${style}`;

  const [groupName, setGroupName] = useState('');
  const [groupId, setGroupId] = useState('');
  const [groupManager, setGroupManager] = useState('');
  const [totalGroupMembers, setTotalGroupMembers] = useState(0);
  const [loading] = useState(false);
  const [groupIdWithRate, setGroupIdWithRate] = useState();
  const [modalConfig, setModalConfig] = useState({
    isModalVisible: false,
    groupExistingRate: undefined,
  });

  const checkIfGroupHasRate = e => {
    if (e.target.checked) {
      const groupId = e.target.value;

      let groupAlradyHasRate = false;
      const chosenGroup = companyGroups.find(g => g._id === groupId);
      const groupNames = Object.keys(groupsWithRates);
      if (groupNames.includes(chosenGroup?.name)) {
        setGroupIdWithRate(groupId);
        groupAlradyHasRate = chosenGroup.productId;
      }

      if (!!groupAlradyHasRate) {
        setModalConfig({
          isModalVisible: true,
          groupExistingRate: groupAlradyHasRate,
        });
      } else {
        onChange([...value, groupId]);
      }
    } else {
      onChange([...value].filter(id => id !== groupId));
    }
  };

  const closeModal = () => {
    setModalConfig({
      isModalVisible: false,
      groupExistingRate: undefined,
    });
    setGroupIdWithRate(undefined);
  };

  const handleModalOk = () => {
    onChange([...value, groupIdWithRate]);
    closeModal();
  };

  const content = (
    <Spin spinning={loading}>
      <div>
        <Text variant="p" size="sm">
          {`${t('groupManager')}: `}
          <Text variant="strong" size="sm">
            {groupManager}
          </Text>
        </Text>

        <Text variant="p" size="sm">
          {`${t('totalMembers')}: `}
          <Text variant="strong" size="sm">
            {totalGroupMembers}
          </Text>
        </Text>

        <Link to={`/group/${groupId}`} rel="noopener noreferrer" target="_blank">
          <div className="d-flex align-items-center">
            <LinkText size="sm">
              {t('view_groupName_page', { groupName })}

              <img
                width="14px"
                src={IMAGES.EXPAND_WINDOW_ICON}
                alt="open in new window"
                style={{ marginLeft: 5 }}
              />
            </LinkText>
          </div>
        </Link>
      </div>
    </Spin>
  );

  const handleMouseOver = async id => {
    const group = companyGroups.find(g => g._id === id);

    setGroupManager(group.groupManager ? group.groupManager.name : 'None');
    setGroupId(group._id);
    setTotalGroupMembers(group.users.length);
    setGroupName(group.name);
  };

  return (
    !!options.length && (
      <div className="custom-ant-checkbox-group" {...rest}>
        <Row className={newClassName}>
          {options.map(option => {
            return !showPopover ? (
              <Col key={option.value} flex="50%" style={{ marginBottom: 10 }}>
                <Checkbox
                  disabled={disabled}
                  checked={value.includes(option.value)}
                  value={option.value}
                  size="small"
                  style={{ marginBottom: 10 }}
                  onChange={checkIfGroupHasRate}
                >
                  {option.label}
                </Checkbox>
              </Col>
            ) : (
              <Col key={option.value} flex="50%" style={{ marginBottom: 10 }}>
                <Popover content={content}>
                  <Checkbox
                    disabled={disabled}
                    value={option.value}
                    checked={value.includes(option.value)}
                    onChange={checkIfGroupHasRate}
                    onMouseEnter={() => handleMouseOver(option.value)}
                  >
                    {option.label}
                  </Checkbox>
                </Popover>
              </Col>
            );
          })}
        </Row>

        <DataModal visible={modalConfig.isModalVisible} onOk={handleModalOk} onCancel={closeModal}>
          {modalConfig.groupExistingRate && (
            <div style={{ marginBottom: 20 }}>
              <Text variant="p" size="sm" style={{ marginBottom: 5 }}>
                {t('groupAlreadyAssociatedToRate')}:{' '}
                <Link
                  to={`${INTERNAL_LINKS.RATE_DETAILS}/${modalConfig.groupExistingRate._id}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <LinkText variant="strong">{modalConfig.groupExistingRate.name}</LinkText>
                </Link>
              </Text>

              <Text variant="p" size="sm">
                <Trans
                  t={t}
                  i18nKey="doYouWantToReplaceRateWith"
                  values={{ rateName: currentRateName }}
                  components={[
                    <Text variant="strong" size="sm">
                      dummy
                    </Text>,
                  ]}
                />
              </Text>
            </div>
          )}
        </DataModal>
      </div>
    )
  );
};

export default withNamespaces()(CustomCheckboxGroup);
