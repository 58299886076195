import { get, pick } from 'lodash';
import React from 'react';

import Text from '../components/Text';
import Toast from '../components/Toast';
import { getCompanyCurrency } from '../utils/company';
import { handleApiCalls, handleApiErrors } from './axiosInstance';

export const DWOLLA_API = {
  fetchDwollaBusinessClassificationList: async () => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/getDwollaBusinessClassificationList`;
    const response = await handleApiCalls('get', url);
    return response.data.data;
  },
  /**
   * @param {object} params
   * @param {string} params.firstName
   * @param {string} params.lastName
   * @param {string} params.email
   */
  createUnverifiedCustomer: async (params = {}) => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/createUnverifiedCustomer`;
    const response = await handleApiCalls('post', url, { ...params, type: 'personal' });
    return response.data.data;
  },
  /**
   *
   * @param {object} params
   * @param {string} params.email
   * @param {string} params.address1
   * @param {string} params.address2
   * @param {string} params.city
   * @param {string} params.state
   * @param {string} params.postalCode
   * @param {boolean} isRetry
   */
  updateCustomer: async (params = {}, isRetry) => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/customers`;
    const response = await handleApiCalls(
      'patch',
      url,
      isRetry
        ? params
        : pick(params, ['email', 'address1', 'address2', 'city', 'state', 'postalCode']),
    );
    return response.data;
  },
  /**
   * @param {object} params
   * @param {string} params.routingNumber
   * @param {string} params.accountNumber
   * @param {string} params.type
   * @param {string} params.name
   * @returns {{ user: object, fundingSourceDetails: object }}
   */
  addFundingSourceForUser: async params => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/addFundingSource`;
    const response = await handleApiCalls('post', url, params);
    return { user: response.data.data, fundingSourceDetails: response.data.fundingSourceDetails };
  },
  /**
   * @param {object} params
   * @param {string} params.fundingSourceId
   * @param {string} params.routingNumber
   * @param {string} params.accountNumber
   * @param {string} params.type
   * @param {string} params.name
   */
  updateFundingSourceForUser: async params => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/updateFundingSource`;
    const response = await handleApiCalls('put', url, params);
    return response.data.data;
  },
  deleteFundingSourceForUser: async fundingSourceId => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/deleteFundingSourceForUser?fundingSourceId=${fundingSourceId}`;
    const response = await handleApiCalls('delete', url);
    return response.data.data;
  },
  /**
   * @param {object} params
   * @param {string} params.routingNumber
   * @param {string} params.accountNumber
   * @param {string} params.type
   * @param {string} params.name
   * @returns {{ company: object, fundingSourceDetails: object }}
   */
  addFundingSourceForCompany: async params => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/addFundingSourceForCompany`;
    const response = await handleApiCalls('post', url, params);
    return {
      company: response.data.data,
      fundingSourceDetails: response.data.fundingSourceDetails,
    };
  },
  /**
   * @param {string} toUserId
   * @param {number} amount
   */
  /**
   * @param {object} params
   * @param {string} params.fundingSourceId
   * @param {string} params.routingNumber
   * @param {string} params.accountNumber
   * @param {string} params.type
   * @param {string} params.name
   */
  updateFundingSourceForCompany: async params => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/updateFundingSourceForCompany`;
    const response = await handleApiCalls('put', url, params);
    return response.data.data;
  },
  deleteFundingSourceForCompany: async fundingSourceId => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/deleteFundingSourceForCompany?fundingSourceId=${fundingSourceId}`;
    const response = await handleApiCalls('delete', url);
    return response.data;
  },
  /**
   * @param {string} toUserId
   * @param {number} amount
   */
  transferMoney: async (toUserId, amount) => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/transferMoney`;
    const response = await handleApiCalls('post', url, { toUserId, amount });
    return response.data.data;
  },
  /**
   * @param {object} params
   * Business Info
   * @param {string} params.firstName
   * @param {string} params.lastName
   * @param {string} params.email
   * @param {string} params.type
   * @param {string} params.dateOfBirth
   * @param {string} params.address1
   * @param {string} params.address2
   * @param {string} params.city
   * @param {string} params.state
   * @param {string} params.postalCode
   * @param {string} params.businessClassification
   * @param {string} params.businessType
   * @param {string} params.businessName
   * @param {string} params.ein
   * Controller Info
   * @param {string} params.controllerFirstName
   * @param {string} params.controllerLastName
   * @param {string} params.controllerTitle
   * @param {string} params.controllerDateOfBirth
   * @param {string} params.controllerSSN
   * @param {string} params.controllerAddress1
   * @param {string} params.controllerAddress2
   * @param {string} params.controllerCity
   * @param {string} params.controllerStateProvinceRegion
   * @param {string} params.controllerPostalCode
   * @param {string} params.controllerCountry
   */
  createVerifiedCustomer: async params => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/createVerifiedCustomer`;
    const response = await handleApiCalls('post', url, params);
    return response.data.data;
  },
  /**
   * @param {object} params
   * @param {string} params.firstName
   * @param {string} params.lastName
   * @param {string} params.dateOfBirth
   * @param {string} params.address1
   * @param {string} params.address2
   * @param {string} params.city
   * @param {string} params.stateProvinceRegion
   * @param {string} params.country
   * @param {string} params.postalCode
   * @param {string} params.ssn
   */
  addBeneficialOwner: async params => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/addBeneficialOwner`;
    const response = await handleApiCalls('post', url, params);
    return response.data.data;
  },
  /**
   * @returns {object} User's profile
   */
  certifyBusinessOwner: async () => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/certifyBusinessOwner`;
    const response = await handleApiCalls('post', url);
    return response.data.data;
  },
  /**
   * @param {number} amount
   */
  transferMoneyToMaster: async amount => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/transferMoneyToMaster`;
    const response = await handleApiCalls('post', url, { amount });
    return response.data.data;
  },
  /**
   *
   * @param {Blob | Binary} file
   * @param {"license" | "idCard" | "passport" | "other"} documentType
   */
  uploadDocuments: async (file, documentType = 'other', additionalData = {}) => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/uploadDocuments`;

    var formData = new FormData();
    formData.set('documentType', documentType);
    Object.keys(additionalData).forEach(key => {
      if (additionalData[key]) {
        formData.set(key, additionalData[key]);
      }
    });
    formData.append('file', file);

    const result = await handleApiCalls('post', url, formData, {
      'Content-Type': 'multipart/form-data',
    });

    return result.data.data;
  },
  /**
   * @returns {object}
   */
  getFundingSourceBalance: async () => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/getFundingSourceBalance`;
    const response = await handleApiCalls('get', url);
    return response.data.data;
  },
  /**
   * @param {object} params
   * @param {string} params.fundingSourceId
   * @param {number} params.amount
   * @returns {object}
   */
  addFundsToCompanyBalance: async ({ fundingSourceId, amount }) => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/transfer-money`;
    const response = await handleApiCalls('post', url, { fundingSourceId, amount, action: 'add' });
    return response.data.data;
  },
  /**
   * @param {object} params
   * @param {string} params.fundingSourceId
   * @param {number} params.amount
   * @returns {object}
   */
  withdrawFundsFromCompanyBalance: async ({ fundingSourceId, amount }) => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/transfer-money`;
    const response = await handleApiCalls('post', url, {
      fundingSourceId,
      amount,
      action: 'withdraw',
    });
    return response.data.data;
  },
  /**
   *
   * @param {string} fundingSourceId
   * @returns {{ microDepositDetails: object, user: object }}
   */
  initiateMicroDeposit: async fundingSourceId => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/initiateMicroDeposit`;
    const response = await handleApiCalls('post', url, { fundingSourceId });
    return response.data.data;
  },
  /**
   * @param {string} fundingSourceId
   * @param {string} amount1
   * @param {string} amount2
   * @returns {object}
   */
  verifyMicroDeposit: async (fundingSourceId, amount1, amount2) => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/verifyMicroDeposit`;
    const response = await handleApiCalls('post', url, {
      fundingSourceId,
      amount1,
      amount1Currency: getCompanyCurrency().currencyUnit,
      amount2,
      amount2Currency: getCompanyCurrency().currencyUnit,
    });
    return response.data.data;
  },
  /**
   * @param {string} dwollaCorrelationId
   */
  retryMassPaymentItemTransfer: async dwollaCorrelationId => {
    const url = `${process.env.REACT_APP_HOST_API}dwolla/mass-payment-item/retry-transfer`;
    const response = await handleApiCalls('post', url, { dwollaCorrelationId });
    return response.data.data;
  },
};

export const parseDwollaApiErrorMessage = (response, onError = () => undefined) => {
  if (typeof response?.data?.message === 'string') {
    try {
      const parsedMessage = JSON.parse(response.data.message);
      const embeddedErrors = get(parsedMessage, '_embedded.errors', []);
      Toast({
        type: 'error',
        message: parsedMessage.message
          ?.replace('See embedded errors list for more details.', '')
          .trim(),
        description: embeddedErrors.map((error, i) => (
          <Text key={error.path || error.message}>
            {i + 1}.- {error.message}
          </Text>
        )),
      });
    } catch (error) {
      handleApiErrors(response, onError);
    }
  } else if (typeof onError === 'function') {
    onError();
  }
};
