import { Col, Row } from 'antd';
import React, { useMemo } from 'react';

import { STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { ReactComponent as EditSVG } from '../../../images/pencil-write.svg';
import { checkIfStringContainsValue, dateCompare, momentFormat } from '../../../utils/common';
import {
  formatNumberToLocale,
  formatNumberWithCurrency,
  formatNumberWithDistanceUnit,
} from '../../../utils/numbers';
import {
  sortColumnByNumber,
  sortColumnByStatus,
  sortColumnByStringField,
} from '../../../utils/tables';
import { getReceiptOriginalAmount } from '../../../utils/trip-receipts';
import { calculateTotalAdjustment } from '../../../views/payments/reimbursements/details/reimbursements-details-helpers';
import { CustomTable } from '../../index';
import Switch from '../../Switch';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';
import Tooltip from '../../Tooltip';
import classNames from './style.module.scss';

/**
 * Table to display the reimbursement Receipts
 */
const ReimbursementReceiptsTable = props => {
  const {
    t,
    dataSource,
    onReceiptIDClick,
    addAdjustment,
    summary,
    updateStatus,
    canUpdateStatus,
    canAdjustReceipt,
    ...rest
  } = props;
  const [searchTerm, setSearchTerm] = useDebouncedState();

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(receipt => {
        return checkIfStringContainsValue(receipt.seqId, searchTerm);
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    {
      width: 61,
      title: t('ID'),
      dataIndex: 'seqId',
      defaultSortOrder: 'descend',
      key: 'seqId',
      ...sortColumnByStringField('seqId'),
      render: (actions, data) => (
        <LinkText variant="strong" size="sm" onClick={() => onReceiptIDClick(data._id)}>
          {data.seqId || '-'}
        </LinkText>
      ),
    },
    {
      width: 100,
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => dateCompare(a.created, b.created, null),
      render: (actions, data) => momentFormat(data.created, 'MM/DD/YYYY'),
    },
    {
      width: canUpdateStatus ? 160 : undefined,
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      ...sortColumnByStatus('status'),
      render: (actions, data) => <StatusTag status={data.status} />,
    },
    {
      width: 140,
      align: 'right',
      title: t('totalDistance'),
      dataIndex: 'totalDistance',
      key: 'totalDistance',
      ...sortColumnByNumber('totalDistance'),
      render: totalDistance =>
        formatNumberWithDistanceUnit(totalDistance, summary.totalDistance.split(' ')[1]),
    },
    {
      width: 190,
      align: 'right',
      title: t('totalAmount'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: (a, b) => {
        const totalA = getReceiptOriginalAmount(a);
        const totalB = getReceiptOriginalAmount(b);

        return parseFloat(totalA) - parseFloat(totalB);
      },
      render: (actions, data) => {
        return formatNumberWithCurrency(getReceiptOriginalAmount(data), data.currency);
      },
    },
    {
      align: 'right',
      width: 170,
      title: t('adjustmentPlusMinus'),
      data: 'adjustedAmount',
      key: 'adjustedAmount',
      sorter: (a, b) => {
        if (a.adjustment && b.adjustment) {
          return (a.adjustment.amount || 0) - (b.adjustment.amount || 0);
        }

        return 0;
      },
      render: (actions, data) => {
        const IS_APPROVED_NO_ADJUSTMENT =
          data.status === STATUS_LIST().Status.APPROVED && !data.adjustments;

        let latestAdjustement = undefined;

        if (Array.isArray(data.adjustments) && !!data.adjustments.length) {
          latestAdjustement = data.adjustments[Math.max(data.adjustments.length - 1, 0)];

          if (latestAdjustement && latestAdjustement.actionType === 'delete') {
            latestAdjustement = undefined;
          }
        }

        if ((IS_APPROVED_NO_ADJUSTMENT || !latestAdjustement) && canAdjustReceipt) {
          return (
            <LinkText size="sm" onClick={() => addAdjustment(data._id)}>
              {t('addAdjustment')}
            </LinkText>
          );
        }

        if (latestAdjustement) {
          return (
            <Tooltip
              title={
                <Row gutter={10} style={{ maxWidth: 260, flexWrap: 'nowrap' }}>
                  {latestAdjustement.note && <Col flex={1}>{latestAdjustement.note}</Col>}
                </Row>
              }
            >
              <span className={latestAdjustement.amount < 0 ? 'negative-currency' : undefined}>
                {latestAdjustement.amount}
                <span>
                  <EditSVG
                    width="14"
                    alt="Edit"
                    style={{ marginLeft: 10, cursor: 'pointer' }}
                    onClick={() => addAdjustment(data._id, latestAdjustement)}
                  />
                </span>
              </span>
            </Tooltip>
          );
        }

        return <span>{formatNumberToLocale('0.00')}</span>;
      },
    },
    {
      width: 170,
      align: 'right',
      title: t('finalAmount'),
      render: (actions, data) => {
        return (
          <span className={data.totalAmount < 0 ? 'negative-currency' : undefined}>
            {formatNumberWithCurrency(data.totalAmount, data.currency)}
          </span>
        );
      },
    },
    ...(canUpdateStatus
      ? [
          {
            width: 100,
            title: t('approve'),
            key: 'approve',
            render: (actions, data) =>
              ![STATUS_LIST().Status.PAID].includes(data.status) && (
                <Switch
                  size="small"
                  defaultChecked={data.status && data.status === 'approved'}
                  onChange={() =>
                    updateStatus(
                      data._id,
                      data.status && data.status === 'approved' ? 'denied' : 'approved',
                    )
                  }
                />
              ),
          },
        ]
      : []),
  ];

  const totalAdjustments = calculateTotalAdjustment(dataSource);

  return (
    <CustomTable
      onSearchTermChange={setSearchTerm}
      {...rest}
      rowKey={record => record._id}
      dataSource={filteredDataSource}
      columns={COLUMNS}
      rowClassName={data =>
        ![STATUS_LIST().Status.APPROVED, STATUS_LIST().Status.PAID].includes(data.status)
          ? classNames.fadedRow
          : ''
      }
      summary={() => {
        return (
          summary && (
            <React.Fragment>
              <tr>
                <td colSpan="6" />
                <td>
                  <Text
                    className={classNames.summaryTotalLabel}
                    variant="strong"
                    size="sm"
                    textAlign="right"
                    renderAs="div"
                  >
                    {t('netAdjustments')}
                  </Text>
                </td>
                <td>
                  <Text
                    variant="p"
                    size="sm"
                    textAlign="right"
                    renderAs="div"
                    className={totalAdjustments < 0 ? 'negative-currency' : undefined}
                  >
                    {formatNumberToLocale(totalAdjustments)}
                  </Text>
                </td>
                {canUpdateStatus && <td />}
              </tr>
              <tr>
                <td colSpan="6" />
                <td>
                  <Text
                    className={classNames.summaryTotalLabel}
                    variant="strong"
                    size="sm"
                    textAlign="right"
                    renderAs="div"
                  >
                    {t('totalAmount')}
                  </Text>
                </td>
                <td>
                  <Text
                    variant="p"
                    size="sm"
                    textAlign="right"
                    renderAs="div"
                    className={summary.reimbursementAmount < 0 ? 'negative-currency' : undefined}
                  >
                    {formatNumberToLocale(
                      summary.reimbursementAmount,
                      summary.reimbursementCurrency,
                    )}
                  </Text>
                </td>
                {canUpdateStatus && <td />}
              </tr>
            </React.Fragment>
          )
        );
      }}
    />
  );
};

ReimbursementReceiptsTable.propTypes = {};

export default ReimbursementReceiptsTable;
