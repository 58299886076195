import { Col, Row } from 'antd';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from '../../components';
import AddUserModal from '../../components/AddUserModal';
import Box from '../../components/Box';
import GroupPeriodLookupSelect from '../../components/GroupPeriodLookupSelect';
import HorizontalLineDivider from '../../components/HorizontalLineDivider';
import DashboardUsersTable from '../../components/Table/DashboardUsersTable';
import Text from '../../components/Text';
import UserGroupsLookupSelect from '../../components/UserGroupsLookupSelect';
import VisibleWhenOnViewport from '../../components/VisibleWhenOnViewport';
import PageContainer from '../../containers/PageContainer';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedAllCompanyUsersQuery from '../../hooks/queries/usePaginatedAllCompanyUsersQuery';
import { ReactComponent as WhitePlusIconSVG } from '../../images/plus.svg';
import {
  hasCompanyAdminRole,
  hasCompanyManagerOrAdminRole,
  hasCompanyManagerRole,
} from '../../utils/roles';
import CardsRowSection from './Cards/CardsRowSection';
import DashboardEventTimeline from './DashboardEventTimeline';
import useDashboardEventTimelineState from './DashboardEventTimeline/useDashboardEventTimelineState';
import KliksInsightsBox from './KliksInsightsBox';
import ManagerBlurredContent from './ManagerBlurredContent';
import classNames from './style.module.scss';
import useDashboardState from './useDashboardState';

const DashboardPage = props => {
  const { t } = props;

  const currentCompany = useSelector(store => store.common.currentCompany);
  const groupList = useSelector(store => (store.group ? store.group.groupList : []));
  const myProfile = useSelector(store => store.profile);
  const companySettings = useSelector(store => store.common.currentCompany.companySettingId);

  const {
    allUsersQuery,
    allUsersTablePagination,
    allUsersHandleTableSort,
  } = usePaginatedAllCompanyUsersQuery(
    currentCompany._id,
    {},
    { enabled: hasCompanyAdminRole(myProfile) },
  );

  const {
    timeline,
    isLoading: isLoadingTimeline,
    timelineFilters,
    handleTimelineFilters,
    handleGroupChange,
  } = useDashboardEventTimelineState();

  const {
    isSendingEmail,
    addUsersModalVisibility,
    dashboardDataQuery,
    handleResendActivationEmail,
    handleVisibility,
    handleSendAppLink,
  } = useDashboardState({ myProfile, timelineFilters });

  const hasNonAdHocPaymentSchedules =
    timelineFilters?.groupID && !timelineFilters?.startDate && !timelineFilters?.endDate;

  const SECION_HEADER = (
    <Row
      gutter={[16, 16]}
      justify="space-between"
      style={{ display: hasCompanyManagerOrAdminRole(myProfile) ? 'flex' : 'none' }}
    >
      <Col flex="600px">
        <Row gutter={16}>
          <Col flex="300px">
            <UserGroupsLookupSelect
              t={t}
              allowClear={false}
              userId={myProfile?.profile?._id}
              value={timelineFilters.groupID}
              onChange={groupID => {
                handleGroupChange(groupID);
              }}
            />
          </Col>

          <Col flex="250px">
            <GroupPeriodLookupSelect
              t={t}
              hideOnEmpty
              groupId={timelineFilters.groupID}
              allowClear={false}
              value={
                timelineFilters.startDate && timelineFilters.endDate
                  ? JSON.stringify({
                      fromDate: timelineFilters.startDate,
                      endDate: timelineFilters.endDate,
                    })
                  : null
              }
              onChange={period => {
                const parsedPeriod = JSON.parse(period);
                handleTimelineFilters({
                  groupID: timelineFilters.groupID,
                  startDate: parsedPeriod.fromDate,
                  endDate: parsedPeriod.endDate,
                });
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <PageContainer title={t('Dashboard')} className={classNames.dashboardPage}>
      {SECION_HEADER}

      <CardsRowSection
        t={t}
        user={myProfile}
        isLoading={!dashboardDataQuery.isFetched || dashboardDataQuery.isFetching}
        data={dashboardDataQuery.data}
        companySettings={companySettings}
        timelineFilters={timelineFilters}
      />

      {hasCompanyManagerOrAdminRole(myProfile) && timelineFilters?.groupID && (
        <Row style={{ marginBottom: 30 }}>
          <Col flex={1}>
            <DashboardEventTimeline
              t={t}
              timeline={timeline}
              isLoading={isLoadingTimeline}
              timelineFilters={timelineFilters}
            />
          </Col>
        </Row>
      )}

      <HorizontalLineDivider
        marginTop={
          hasCompanyManagerOrAdminRole(myProfile) && hasNonAdHocPaymentSchedules ? '0px' : '30px'
        }
        marginBottom="30px"
      />

      {hasCompanyManagerRole(myProfile) && !timelineFilters?.groupID && (
        <ManagerBlurredContent t={t} />
      )}

      {hasCompanyManagerOrAdminRole(myProfile) && timelineFilters.groupID && (
        <Row style={{ marginBottom: 30 }}>
          <Col flex={1}>
            <VisibleWhenOnViewport height={672}>
              <KliksInsightsBox
                t={t}
                selectedGroup={timelineFilters.groupID}
                onGroupChange={handleGroupChange}
              />
            </VisibleWhenOnViewport>
          </Col>
        </Row>
      )}

      {hasCompanyAdminRole(myProfile) && (
        <Row justify="end" style={{ marginBottom: 20 }}>
          <Col>
            <Button
              onClick={() => handleVisibility(true)}
              icon={<WhitePlusIconSVG width="18px" style={{ marginRight: 5 }} />}
            >
              {t('Add User')}
            </Button>
          </Col>
        </Row>
      )}

      {hasCompanyAdminRole(myProfile) && (
        <Row>
          <Col flex={1}>
            <VisibleWhenOnViewport height={300}>
              <Box>
                <Row justify="start" gutter={5}>
                  <Col>
                    <Text variant="h4">{t('userActivity')}</Text>
                  </Col>
                </Row>

                <Row>
                  <DashboardUsersTable
                    t={t}
                    asyncSort
                    loading={allUsersQuery.isFetching}
                    dataSource={allUsersQuery.data.users}
                    isSendingEmail={isSendingEmail}
                    resendActivationEmail={handleResendActivationEmail}
                    sendAppLink={handleSendAppLink}
                    hiddenColumns={['role', 'actions']}
                    onChange={({ current }, _, sorters) => {
                      allUsersTablePagination.handlePageChange(current);
                      allUsersHandleTableSort(sorters?.columnKey, sorters?.order);
                    }}
                    pagination={{
                      pageSize: allUsersTablePagination.paginationConfig.pageSize,
                      total: allUsersTablePagination.paginationConfig.total,
                      current: allUsersTablePagination.paginationConfig.current,
                      onShowSizeChange: allUsersTablePagination.handlePageSizeChange,
                    }}
                  />
                </Row>
              </Box>
            </VisibleWhenOnViewport>
          </Col>
        </Row>
      )}
      <AddUserModal
        companyId={currentCompany && currentCompany._id}
        groups={groupList}
        modalVisibility={addUsersModalVisibility}
        visible={handleVisibility}
        requireEmployeeID={companySettings?.settings?.requireEmployeeNumber}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(DashboardPage));
