import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS } from '../../../enum';
import { momentFormat } from '../../../utils/common';
import Tag from '../../Tag';
import Text from '../../Text';
import LinkText from '../../Text/LinkText';
import classNames from './styles.module.scss';

const ItineraryColumnContent = props => {
  const { t, itinerary, isUpcoming } = props;

  return (
    <Row className={classNames.itineraryColumnContent}>
      <Col>
        <Row align="middle" gutter={16} wrap={false}>
          <Col>
            <Link to={INTERNAL_LINKS.ITINERARY_DETAILS(itinerary._id)}>
              <LinkText variant="b">
                {momentFormat(itinerary.dateFrom, 'dddd, MMMM DD, YYYY')} (
                {t('nStops', { count: itinerary.trips.length })})
              </LinkText>
            </Link>
          </Col>

          {isUpcoming && (
            <Col>
              <Tag>
                <Row gutter={5} wrap={false}>
                  <Col>
                    <img src={IMAGES.CALENDAR_TIMER_ICON} alt="calendar" />
                  </Col>
                  <Col>{t('upcoming')}</Col>
                </Row>
              </Tag>
            </Col>
          )}
        </Row>

        {!isUpcoming && (
          <ol className={classNames.itineraryList}>
            {itinerary.trips.map(trip => {
              return (
                <li key={trip.tripEndLocationAddress}>
                  <Text size="sm">{trip.tripEndLocationAddress}</Text>
                </li>
              );
            })}
          </ol>
        )}
      </Col>
    </Row>
  );
};

export default ItineraryColumnContent;
