import moment from 'moment-timezone';

/**
 * Check if the vacations date range is valid
 *
 * @param {string} startDate Vacation Start date
 * @param {string} endDate Vacation End date
 * @return {boolean} Is Valid?
 */
export const validateUserVacations = (startDate, endDate) => {
  if (!startDate || !endDate) return false;

  return moment(endDate).isSameOrAfter(startDate);
};

/**
 * Checks if any vacation period collides with other vacation periods
 *
 * @param {object} vacations Vacations array
 * @returns {boolean} Is Valid?
 */
export const validateUserVacationsCollision = (vacations = []) => {
  let valid = true;

  for (let i = 0; i < vacations.length; i++) {
    if (!valid) break;

    const { startDate, endDate } = vacations[i];

    for (let f = 0; f < vacations.length; f++) {
      if (i !== f) {
        const vacation = vacations[f];

        const collidesWithStartDate = moment(startDate).isBetween(
          vacation.startDate,
          vacation.endDate,
          'date',
        );

        const collidesWithEndDate = moment(endDate).isBetween(
          vacation.startDate,
          vacation.endDate,
          'date',
        );

        const isSameStartDate = moment(startDate).isSame(vacation.startDate, 'date');
        const isSameEndDate = moment(endDate).isSame(vacation.endDate, 'date');

        if (collidesWithStartDate || collidesWithEndDate || isSameStartDate || isSameEndDate) {
          valid = false;
          break;
        }
      }
    }
  }

  return valid;
};
