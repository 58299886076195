import { filter, find, first, get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { fetchPaymentScheduleDetails } from '../api/paymentSchedule';
import { AD_HOC_SCHEDULE_TYPE } from '../enum/ScheduleTypes';
import { formatGroupPeriodsSelectOptions } from '../utils/groups';
import { replaceCurrentPageSearchQueryParams } from '../utils/queryParams';
import usePaymentSchedules from './usePaymentSchedules';

const usePaymentSchedulePeriods = (
  { paymentScheduleId, fromDate, endDate },
  includeAdHoc = false,
  replaceHistory = true,
) => {
  const history = useHistory();
  const [paymentPeriodsBySchedule, setPaymentPeriodsBySchedule] = useState({});
  const [selectedPaymentPeriod, setSelectedPaymentPeriod] = useState(
    fromDate && endDate ? JSON.stringify({ fromDate, endDate }) : undefined,
  );

  const {
    isFetchingPaymentSchedules,
    selectedPaymentSchedule,
    selectPaymentSchedule,
    paymentSchedules,
    paymentScheduleOptions,
  } = usePaymentSchedules(paymentScheduleId, includeAdHoc);

  const selectedPaymentSchedulePeriods = useMemo(() => {
    const selectedPeriods = paymentPeriodsBySchedule[get(selectedPaymentSchedule, '_id')];
    if (Array.isArray(selectedPeriods)) {
      return selectedPeriods;
    }
    return null;
  }, [selectedPaymentSchedule, paymentPeriodsBySchedule]);

  const handleReplaceHistory = useCallback(
    (historyParams = {}) => {
      if (replaceHistory) {
        replaceCurrentPageSearchQueryParams(history, historyParams);
      }
    },
    [replaceHistory, history],
  );

  const handlePeriodSelect = useCallback(
    period => {
      setSelectedPaymentPeriod(period);

      handleReplaceHistory({
        paymentScheduleId: get(selectedPaymentSchedule, '_id'),
        fromDate: period ? get(JSON.parse(period), 'fromDate') : '',
        endDate: period ? get(JSON.parse(period), 'endDate') : '',
      });
    },
    [selectedPaymentSchedule, handleReplaceHistory],
  );

  const handlePaymentScheduleSelect = useCallback(
    scheduleId => {
      setSelectedPaymentPeriod();
      selectPaymentSchedule(find(paymentSchedules, { _id: scheduleId }));

      handleReplaceHistory({ paymentScheduleId: scheduleId });
    },
    [paymentSchedules, selectPaymentSchedule, handleReplaceHistory],
  );

  const handlePaymentPeriodFormat = useCallback(({ _id, currentPeriod, previousPeriods }) => {
    let periodsArray = [];

    if (currentPeriod && currentPeriod.scheduleType !== AD_HOC_SCHEDULE_TYPE) {
      periodsArray.push({ ...currentPeriod, period: 'present' });
    }

    if (Array.isArray(previousPeriods)) {
      periodsArray = periodsArray.concat(
        filter(previousPeriods, ({ scheduleType }) => scheduleType !== AD_HOC_SCHEDULE_TYPE),
      );
    }

    if (periodsArray) {
      periodsArray = periodsArray.map(
        ({ paymentPeriodStart, paymentPeriodEnd, scheduleType, period }) => ({
          period: period || 'past',
          start: paymentPeriodStart,
          end: paymentPeriodEnd,
          scheduleType,
        }),
      );
      periodsArray = formatGroupPeriodsSelectOptions(periodsArray);
    }

    setPaymentPeriodsBySchedule(state => ({
      ...state,
      [_id]: periodsArray,
    }));
  }, []);

  const paymentScheduleDetailsQuery = useQuery({
    staleTime: 1000 * 60 * 10, // 10 minutes
    enabled:
      !!get(selectedPaymentSchedule, '_id') &&
      !Array.isArray(paymentPeriodsBySchedule[get(selectedPaymentSchedule, '_id')]),
    queryKey: ['fetchPaymentScheduleDetails', get(selectedPaymentSchedule, '_id')],
    queryFn: () => fetchPaymentScheduleDetails(get(selectedPaymentSchedule, '_id')),
  });

  useEffect(() => {
    if (selectedPaymentSchedule) {
      const selectedScheduleId = get(selectedPaymentSchedule, '_id');
      const schedulePeriods = paymentPeriodsBySchedule[selectedScheduleId];
      const firstOption = first(schedulePeriods);

      if (selectedPaymentSchedule && !selectedPaymentPeriod && schedulePeriods) {
        handlePeriodSelect(firstOption?.value);
      }
    }
  }, [
    selectedPaymentSchedule,
    selectedPaymentPeriod,
    paymentPeriodsBySchedule,
    handlePeriodSelect,
  ]);

  useEffect(() => {
    if (paymentScheduleDetailsQuery.data) {
      handlePaymentPeriodFormat(paymentScheduleDetailsQuery.data);
    }
  }, [handlePaymentPeriodFormat, paymentScheduleDetailsQuery.data]);

  return {
    isFetchingPaymentSchedules,
    selectedPaymentSchedule,
    selectedPaymentPeriod,
    selectedPaymentSchedulePeriods,
    handlePaymentScheduleSelect,
    handlePeriodSelect,

    paymentScheduleOptions,
  };
};

export default usePaymentSchedulePeriods;
