import { Col, Row } from 'antd';
import React from 'react';

import { EXTERNAL_LINKS } from '../../../enum';
import { getUserInitials } from '../../../utils/common';
import { convertMillisecondsIntoReadableTime } from '../../../utils/datetime';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import { HelpIcon } from '../..';
import AnonymizedAsterisk from '../../AnonymizedAsterisk';
import Avatar from '../../Avatar';
import Box from '../../Box';
import SummaryCardColumn from '../../shared-ui/SummaryCardColumn';
import SpaceSpinner from '../../SpaceSpinner';
import Text from '../../Text';
import TextWithExternalLink from '../../TextWithExternalLink';

const OneReceiptPerPeriodSummary = props => {
  const { t, receipt } = props;

  if (!receipt) {
    return (
      <Box>
        <SpaceSpinner height={100} />
      </Box>
    );
  }

  return (
    <Box>
      <Row gutter={[10, 10]} justify="space-between" style={{ marginTop: 5 }}>
        {receipt?.submittedBy && (
          <Col xs={24} lg={12} xl={6}>
            <Row
              gutter={6}
              align="start"
              justify="start"
              style={{ flexWrap: 'nowrap', width: '100%' }}
            >
              <Col flex="32px">
                <Avatar
                  size="small"
                  shape="circle"
                  src={receipt.submittedBy.profilePicture}
                  alt={'userName'}
                  initials={getUserInitials(receipt.submittedBy)}
                />
              </Col>
              <Col>
                <SummaryCardColumn title={receipt.submittedBy.group.name}>
                  <Text
                    color="secondary"
                    title={receipt.submittedBy.isAnonymized ? t('anonymizedUser') : 'userName'}
                    variant="strong"
                    size="xl"
                    renderAs="div"
                  >
                    {receipt.submittedBy.name}{' '}
                    {receipt.submittedBy.isAnonymized && <AnonymizedAsterisk t={t} />}
                  </Text>
                </SummaryCardColumn>
              </Col>
            </Row>
          </Col>
        )}

        <Col>
          <SummaryCardColumn title={t('Trips')}>{receipt?.trips?.length || 0}</SummaryCardColumn>
        </Col>

        <Col>
          <SummaryCardColumn title={t('totalDistance')}>{receipt.totalDistance}</SummaryCardColumn>
        </Col>

        <Col>
          <SummaryCardColumn title={t('totalAmount')}>
            {formatNumberWithCurrency(receipt.totalAmount, receipt.currency, undefined, 'code')}
          </SummaryCardColumn>
        </Col>

        <Col>
          <SummaryCardColumn
            title={
              <Row wrap={false} gutter={8} align="middle">
                <Col>
                  <span>{t('stopDurationAverage')}</span>
                </Col>

                <Col>
                  <HelpIcon
                    hint={
                      <TextWithExternalLink
                        text={t('howIsThisCalculatedViewFAQ')}
                        linkProps={{
                          href: EXTERNAL_LINKS.FRESHDESK_FAQS_STOP_DURATION_CALCULATION,
                        }}
                      />
                    }
                  />
                </Col>
              </Row>
            }
          >
            {`${
              !receipt.stopDurationAverage
                ? convertMillisecondsIntoReadableTime(0)
                : convertMillisecondsIntoReadableTime(receipt.stopDurationAverage)
            }`}
          </SummaryCardColumn>
        </Col>

        <Col flex="0 0 90px">
          <SummaryCardColumn title="CRM">
            {`${typeof receipt.crmPercent !== 'number' ? 0 : receipt.crmPercent}%`}
          </SummaryCardColumn>
        </Col>
      </Row>
    </Box>
  );
};

export default OneReceiptPerPeriodSummary;
