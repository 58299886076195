import { Col, Row } from 'antd';
import classnames from 'classnames';
import React from 'react';

import Text from '../Text';
import classNames from './styles.module.scss';

const RadioCard = props => {
  const { children, checked, title, onClick, ...rest } = props;

  return (
    <div
      {...rest}
      style={rest.style ?? !children ? { display: 'grid', alignItems: 'center' } : undefined}
      className={classnames(classNames.radioCard, 'radioCard', {
        [classNames.checked]: checked,
      })}
      onClick={e => {
        const targetClassName = e.target.className || '';
        if (
          targetClassName.includes &&
          targetClassName.includes('radioCard') &&
          typeof onClick === 'function'
        ) {
          onClick();
        }
      }}
    >
      <Row gutter={[16, 16]} wrap={false} className="radioCardRow" align="middle">
        <Col className="radioCardCol">
          <div className="radioCardIconWrapper">
            {checked ? (
              <i className="fa fa-check-circle fa-2x radioCardIcon"></i>
            ) : (
              <i className="fa fa-circle-thin fa-2x radioCardIcon" aria-hidden="true"></i>
            )}
          </div>
        </Col>
        <Col flex={1} className="radioCardCol">
          <Row className="radioCardRow">
            <Col xs={24} lg={children ? 6 : 24} className="radioCardCol">
              <Text variant="h5" className="radioCardTitle">
                {title}
              </Text>
            </Col>

            {checked && children && (
              <Col xs={24} lg={18} className="radioCardCol">
                <div className="radioCardBodyWrapper">{children}</div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default RadioCard;
