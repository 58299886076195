/**
 * Returns the company ID from the user's profile data
 *
 * @param {object} profileData User's Profile data
 * @return {null|string} Company ID
 */
export const getCompanyID = profileData => {
  const { profile } = profileData;

  if (
    profile &&
    profile.businessInfor &&
    profile.businessInfor.companies &&
    profile.businessInfor.companies[0] &&
    profile.businessInfor.companies[0].companyID
  ) {
    return profile.businessInfor.companies[0].companyID;
  }

  return null;
};

/**
 * Retrieve the start and end coordinates from the trip's journey data
 *
 * @param {object} trip Trip data
 */
export const getActiveRoutes = trip => {
  const activeRoutes = [];

  if (trip.journeyGeoJson) {
    const polyline = trip.journeyGeoJson.geometry.coordinates.map(point => {
      if (Array.isArray(point)) {
        return {
          lat: point[1],
          lng: point[0],
        };
      } else if (typeof point === 'string') {
        let coor = JSON.parse(point);
        return {
          lat: coor[0],
          lng: coor[1],
        };
      } else {
        return {
          lat: point.xcoor,
          lng: point.ycoor,
        };
      }
    });
    activeRoutes.push({
      key: trip._id,
      polyline,
      points: [
        {
          type: 'start',
          key: 'start',
          position: polyline[0],
          formattedAddress: trip.tripFromAddressLabel,
          journeyStartTs: trip.journeyStartTs,
        },
        {
          type: 'end',
          key: 'end',
          position: polyline[polyline.length - 1],
          formattedAddress: trip.tripToAddressLabel,
          journeyStartTs: trip.journeyStartTs,
          journeyEndTs: trip.journeyEndTs,
          journeyDistance: trip.journeyDistance,
          journeyHardAccel: trip.journeyHardAccel,
          journeyHardStart: trip.journeyHardStart,
          journeyOverSpeed: trip.journeyOverSpeed,
          journeyHardDeccel: trip.journeyHardDeccel,
          journeyHardStop: trip.journeyHardStop,
          journeyHardTurn: trip.journeyHardTurn,
          journeyFuelComs: trip.journeyFuelComs,
          journeyIdleTime: trip.journeyIdleTime,
        },
      ],
    });
  }

  return activeRoutes;
};
