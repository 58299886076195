import { Form } from 'antd';
import React from 'react';

import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import CreditCardForm from '../../../views/billing/billing-method/CreditCardForm';
import SubmitCancelButtonGroup from '../../SubmitCancelButtonGroup';
import GenericModal from '../GenericModal';

const CreditCardFormModal = props => {
  const { t, visible, initialValues, onCancel, onSubmit, isLoading, isUpdate } = props;

  const [form] = Form.useForm();

  useDidUpdateEffect(() => {
    if (visible && initialValues && isUpdate) {
      form.setFieldsValue({
        name: initialValues.name,
        cardNumber: `XXXX XXXX XXXX ${initialValues.last4}`,
        expirationDate: `${initialValues.expMonth
          .toString()
          .padStart(2, '0')} / ${initialValues.expYear.toString().slice(2, 4)}`,
      });
    } else {
      form.setFieldsValue({
        name: undefined,
        cardNumber: undefined,
        expirationDate: undefined,
        cvc: undefined,
        isDefault: true,
      });
    }
    // eslint-disable-next-line
  }, [visible, isUpdate, initialValues]);

  return (
    <GenericModal
      centered
      destroyOnClose
      title={t(isUpdate ? 'updateCreditCard' : 'addCreditCard')}
      visible={visible}
      onCancel={onCancel}
      closable={!isLoading}
      bodyStyle={{ overflowY: 'auto' }}
      width={700}
      footer={
        <SubmitCancelButtonGroup
          loading={isLoading}
          disabled={isLoading}
          onCancel={onCancel}
          onSubmit={() => form.validateFields().then(onSubmit)}
          submitText={isUpdate ? t('update') : t('add')}
        />
      }
    >
      <CreditCardForm
        t={t}
        isUpdate={isUpdate}
        formInstance={form}
        initialValues={initialValues}
        isSubmitting={isLoading}
      />
    </GenericModal>
  );
};

export default CreditCardFormModal;
