import { removeFalseyParams } from '../../../utils/queryParams';

export const formatInvoiceFormValues = values => {
  return removeFalseyParams({
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone,
    email: values.email,
    street1: values.line1,
    street2: values.line2,
    country: values.country,
    state: values.state,
    city: values.city,
    postalCode: values.postal_code,
  });
};
