import { Col, Row } from 'antd';
import Spinner from 'components/Spinner';
import AuthContainer from 'containers/Auth';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ADMIN_API } from '../../../api/admin';
import { registerUser } from '../../../api/auth';
import { handleApiCalls, handleApiErrors } from '../../../api/axiosInstance';
import FullLogo from '../../../components/FullLogo';
import CRMTicketModal from '../../../components/Modal/CRMTicketModal';
import RegistrationForm from '../../../components/RegistrationForm';
import Toast from '../../../components/Toast';
import { INTERNAL_LINKS } from '../../../enum';
import { formatPageTitle } from '../../../utils/common';
import { companyRegistration } from '../../../utils/config';
import classNames from './style.module.scss';

const NewSignUp = ({ t, location, ...props }) => {
  // CRM Ticket state
  const [isSubmittingTicket, setIsSubmittingTicket] = useState(false);
  const [requestAccessVisible, setRequestAccessVisible] = useState(false);
  const [requesterData, setRequesterData] = useState({});

  // to store companies from db
  const [existingCompanies, setExistingCompanies] = useState([]);

  // spinner visibility
  const [loading, setLoading] = useState(false);

  const fetchCompanies = async () => {
    try {
      const result = await handleApiCalls(
        'get',
        `${process.env.REACT_APP_HOST_API}company/companyNames`,
      );
      setExistingCompanies(result.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (companyRegistration.showExistingCompanies) {
      fetchCompanies();
    }
    // eslint-disable-next-line
  }, []);

  const redirectSuccess = email => {
    props.history.push({ pathname: INTERNAL_LINKS.SIGNUP_SUCCESS, state: { email } });
  };

  const closeRequestAccessModal = () => {
    setRequestAccessVisible(false);
    setRequesterData({});
  };

  const onFinish = async values => {
    setLoading(true);

    const { price_plan } = queryString.parse(location.search.replace('?', ''));

    try {
      await registerUser({ ...values, planType: price_plan });
      redirectSuccess(values.email);
    } catch (error) {
      setLoading(false);
      if (
        !!values.companyName &&
        !error?.response?.data?.errors?.email &&
        /company name already exists/gi.test(error?.response?.data?.errors?.companyName?.msg)
      ) {
        setRequesterData(values);
        setRequestAccessVisible(true);
        return;
      }

      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('signupError'),
        });
      });
    }

    setLoading(false);
  };

  const handleTicketSubmit = async formValues => {
    setIsSubmittingTicket(true);

    try {
      await ADMIN_API.createTicket(requesterData.email, formValues);

      closeRequestAccessModal();
      Toast({
        type: 'success',
        message: t('submitRequestSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('submitRequestError'),
        });
      });
    }

    setIsSubmittingTicket(false);
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle('Signup')}</title>
      </Helmet>

      <Spinner spinning={loading}>
        <Row justify="center" align="middle" className={classNames.signupView}>
          <Col flex={1}>
            <Row justify="center" style={{ marginBottom: 30 }}>
              <Col>
                <Link to={INTERNAL_LINKS.LOGIN}>
                  <FullLogo />
                </Link>
              </Col>
            </Row>

            <RegistrationForm
              companies={
                companyRegistration.showExistingCompanies && existingCompanies
                  ? existingCompanies
                  : undefined
              }
              fieldValues={onFinish}
              t={t}
              props={props}
            />
          </Col>
        </Row>

        <CRMTicketModal
          t={t}
          loading={isSubmittingTicket}
          visible={requestAccessVisible}
          defaultValues={{
            ...requesterData,
            description: t('iRequestingAccessToCompany__name', { name: requesterData.companyName }),
            subject: t('accessRequestFor__name', { name: requesterData.companyName }),
          }}
          onSubmit={handleTicketSubmit}
          onCancel={closeRequestAccessModal}
        />
      </Spinner>
    </>
  );
};

export default AuthContainer(withNamespaces()(NewSignUp));
