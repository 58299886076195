import { useCallback, useEffect, useRef } from 'react';

/**
 * Hooks that create a hidden Input node to use when selecting files
 *
 * @param {Function} callback
 * @param {object} opts
 * @param {string} opts.accept
 * @param {boolean} opts.multiple
 */
const useFileInput = (callback, opts) => {
  const input = useRef();

  useEffect(() => {
    input.current = document.createElement('input');
    input.current.type = 'file';

    return () => {
      if (input.current && input.current.parentNode) {
        input.current.parentNode.removeChild(input.current);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (input.current) {
      if (opts) {
        input.current.accept = opts.accept || '';
        input.current.multiple = !!opts.multiple;
      } else {
        input.current.accept = '';
        input.current.multiple = false;
      }
    }
    // eslint-disable-next-line
  }, [opts && opts.accept, opts && opts.multiple]);

  useEffect(() => {
    if (input.current) {
      input.current.onchange = () => {
        if (input.current && input.current.files) {
          callback(input.current.files);
        }
      };
    }
    // eslint-disable-next-line
  }, [callback]);

  return useCallback(() => {
    if (input.current) {
      input.current.value = '';
      input.current.click();
    }
    // eslint-disable-next-line
  }, [input.current]);
};

export default useFileInput;
