import { Col, Form, Row } from 'antd';
import React from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import Text from '../../Text';
import TextInput from '../../TextInput';

const RateFormSegmentGB = props => {
  const { t, isSubmitting } = props;

  return (
    <>
      <Text>{t('revenueCustomsStandardKilometerRateHM')}</Text>

      <br />

      <Text variant="h5">{t('carsAndVans')}</Text>

      <Row gutter={16} style={{ marginLeft: 30 }}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="cars-and-vans-first10kMiles"
            label={t('first10000Km')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginLeft: 30 }}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="cars-and-vans-over10kMiles"
            label={t('after10000Km')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Text variant="h5">{t('motorcycles')}</Text>

      <Row gutter={16} style={{ marginLeft: 30 }}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="motorcycle"
            label={t('anyDistance')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Text variant="h5">{t('bicycles')}</Text>

      <Row gutter={16} style={{ marginLeft: 30 }}>
        <Col xs={24} md={12}>
          <Form.Item
            required
            name="bicycles"
            label={t('anyDistance')}
            rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
          >
            <TextInput
              disabled={isSubmitting}
              step="any"
              type="number"
              placeholder={t('enterAmountPerKilometer')}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default RateFormSegmentGB;
