import React from 'react';

import { hasSystemAdminRole } from '../../../utils/roles';
import { TAX_RETURN_PURPOSE } from './company-settings-constants';
import FeatureFormItem from './FeatureFormItem';
import SettingSectionWrapper from './SettingSectionWrapper';

const ApprovalWorkflowSettingsFormItems = props => {
  const { t, authUser, companySettings, companySettingValues, onFeatureChange, disabled } = props;

  if (!hasSystemAdminRole(authUser)) return null;

  return (
    <SettingSectionWrapper title={t('approvalWorkflow')}>
      <FeatureFormItem
        label={t('approveReceipt')}
        value={companySettings.approveReceipt}
        onChange={checked => onFeatureChange({ approveReceipt: checked })}
        helpText={t('approveReceipt_SysAdminHelpText')}
        disabled={
          !companySettings.tripApprovalWorkflow ||
          companySettingValues?.mileagePurpose === TAX_RETURN_PURPOSE ||
          disabled
        }
      />

      <FeatureFormItem
        label={t('showSubmitTripsForApprovalButton')}
        value={companySettings.createReceipt}
        onChange={checked => onFeatureChange({ createReceipt: checked })}
        helpText={t('showSubmitTripsForApprovalButton_SysAdminHelpText')}
        disabled={companySettingValues?.mileagePurpose === TAX_RETURN_PURPOSE || disabled}
      />

      <FeatureFormItem
        label={t('tripApprovalWorkflow')}
        helpText={t('tripApprovalWorkflowDisableWarning')}
        value={companySettings.tripApprovalWorkflow}
        disabled={companySettingValues?.mileagePurpose === TAX_RETURN_PURPOSE || disabled}
        onChange={checked =>
          onFeatureChange({
            tripApprovalWorkflow: checked,
            ...(!checked ? { approveReceipt: false } : {}),
          })
        }
      />
    </SettingSectionWrapper>
  );
};

export default ApprovalWorkflowSettingsFormItems;
