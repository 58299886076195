import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { RATE_TYPES } from '../../enum/Rates';
import { getDistanceUnitStringValue } from '../../utils/trips';
import ThreeInput from '../ThreeInput';

const TieredConfigurationFormSection = props => {
  const {
    t,
    bands,
    rateType,
    onBandEndChange,
    onBandRateChange,
    onBandRemoval,
    onBandAdd,
    rateCountry,
    isSubmitting,
  } = props;

  const { isMobile } = useSelector(state => state.browser);
  const { currentCompany } = useSelector(state => state.common);

  return (
    <Form.Item>
      {Array.isArray(bands) &&
        bands.map((band, index) => {
          return (
            <div className="item-container" key={`band-${index}`}>
              <ThreeInput
                key={band.bandStart}
                showLabel={isMobile || index === 0}
                custom={true}
                fieldOneProps={{
                  type: 'number',
                  name: 'bandStart-' + index,
                  value: band.bandStart,
                  defaultValue: band.bandStart,
                  suffix: getDistanceUnitStringValue(rateCountry),
                  disabled: isSubmitting || rateType === RATE_TYPES.GOVT,
                }}
                fieldTwoProps={{
                  type: 'number',
                  name: 'bandEnd-' + index,
                  value: band.bandEnd,
                  defaultValue: band.bandEnd,
                  onChange: val => onBandEndChange(val, index),
                  suffix: getDistanceUnitStringValue(rateCountry),
                  disabled: isSubmitting || rateType === RATE_TYPES.GOVT,
                }}
                fieldThreeProps={{
                  type: 'number',
                  name: 'bandRate-' + index,
                  value: band.bandRate,
                  defaultValue: band.bandRate,
                  onChange: val => onBandRateChange(val, index),
                  suffix: currentCompany.currencyUnit,
                  disabled: isSubmitting || rateType === RATE_TYPES.GOVT,
                }}
              />
              {index !== 0 && index === bands.length - 1 && rateType !== RATE_TYPES.GOVT && (
                <DeleteFilled className="item-container-trash" onClick={onBandRemoval} />
              )}
            </div>
          );
        })}

      {rateType !== RATE_TYPES.GOVT && (
        <Row style={{ marginTop: 5 }} justify="end">
          <Col>
            <div className="item-add" onClick={onBandAdd}>
              <PlusCircleOutlined />
              <span style={{ marginLeft: 5 }}>{t(`Add new band`)}</span>
            </div>
          </Col>
        </Row>
      )}
    </Form.Item>
  );
};

TieredConfigurationFormSection.propTypes = {};

export default TieredConfigurationFormSection;
