import { useQuery } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { SETTINGS_API } from '../../api/settings';
import Toast from '../../components/Toast';

const useSettingsLinksQuery = t => {
  return useQuery({
    queryKey: ['settings/links'],
    staleTime: 1000 * 60 * 10, // 10 minutes
    queryFn: SETTINGS_API.fetchLinks,
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchLinksError'),
        });
      });
    },
  });
};

export default useSettingsLinksQuery;
