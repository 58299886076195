import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { useState } from 'react';

import FormItem from '../../Form/FormItem';
import HorizontalLineDivider from '../../HorizontalLineDivider';
import LocationInput from '../../LocationInput';
import DraggableItineraryDestinationItem from './DraggableItineraryDestinationItem';

const DraggableItineraryDestinationsSection = props => {
  const { t, destinations, isSubmitting, onDestinationChange, onDestinationRemoval } = props;

  const [activeId, setActiveId] = useState(null);

  const handleDragStart = useCallback(event => {
    setActiveId(event.active.id);
  }, []);

  const moveDestination = useCallback(
    event => {
      setActiveId(null);

      const { active, over } = event;
      if (active && over && active.id !== over.id) {
        const oldIndex = destinations.findIndex(dest => dest.id === active.id);
        const newIndex = destinations.findIndex(dest => dest.id === over.id);

        const updatedDestinations = arrayMove([...destinations], oldIndex, newIndex);
        updatedDestinations.forEach((dest, i) => {
          onDestinationChange(i, { address: dest?.address || '' });
        });
      }
    },
    [destinations, onDestinationChange],
  );

  const handleTimeVisibilityToggle = useCallback(
    index => {
      if (Array.isArray(destinations)) {
        let lastSetTime;

        destinations.forEach((destination, i) => {
          if (i < index && destination?.reachingTime) {
            lastSetTime = destination?.reachingTime;
          }
        });

        const timeToSet = lastSetTime
          ? moment(lastSetTime).add(30, 'minutes')
          : moment().startOf('hour').add(30, 'minutes');

        onDestinationChange(index, { reachingTime: timeToSet });
      }
    },
    [destinations, onDestinationChange],
  );

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={moveDestination}
    >
      <SortableContext items={destinations} strategy={verticalListSortingStrategy}>
        {destinations.map((destination, i) => {
          const addressLabel = `destination_addr_${i}`;
          const timeLabel = `destination_reachingTime_${i}`;

          return (
            <div key={destination.id}>
              <DraggableItineraryDestinationItem
                t={t}
                order={i}
                id={destination.id}
                timeLabel={timeLabel}
                addressLabel={addressLabel}
                isSubmitting={isSubmitting}
                destination={destination}
                onDestinationChange={onDestinationChange}
                onDestinationRemoval={onDestinationRemoval}
                onTimeVisibilityToggle={() => handleTimeVisibilityToggle(i)}
              />

              <HorizontalLineDivider marginTop="10px" marginBottom="10px" />
            </div>
          );
        })}

        <DragOverlay>
          {activeId ? (
            <FormItem
              required
              name={`destination_addr_${destinations.findIndex(dest => dest.id === activeId)}`}
              style={{ touchAction: 'none', width: '100%', maxWidth: 336, marginBottom: 5 }}
              label={t('destinationPropertyAddress')}
            >
              <LocationInput
                defaultValue={destinations.find(dest => dest.id === activeId)?.address}
                placeholder={t('destinationPropertyAddress_placeholder')}
              />
            </FormItem>
          ) : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
};

export default DraggableItineraryDestinationsSection;
