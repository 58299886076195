import { Layout, Menu } from 'antd';
import classnames from 'classnames';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Tooltip from '../../components/Tooltip';
import { MENU_LIST } from '../../enum';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { hasBackOfficeUserRole, hasSystemAdminRole } from '../../utils/roles';
import {
  selectStoreCompanySettings,
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from '../../utils/storeSelectors';
import classNames from '../style.module.scss';
import useLayout from '../useLayout';

const Sidebar = props => {
  const { t } = props;
  const history = useHistory();
  const location = useLocation();

  const {
    layoutStore,
    browserStore,
    isInOnboardingPage,
    handleToggleLayoutSidebar,
    closeLayoutSidebar,
    handleGoToDashboard,
  } = useLayout({ history });
  const sidebarRef = useRef();
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const currentCompany = useSelector(selectStoreCurrentCompany);
  const companySettings = useSelector(selectStoreCompanySettings);

  const selectedMenuKeys = useMemo(() => [location.pathname], [location.pathname]);
  const isRegCompleted = useMemo(() => currentCompany?.regCompleted, [currentCompany]);
  const authUserRole = useMemo(() => get(authUser, 'profile.role'), [authUser]);

  useDidUpdateEffect(() => {
    if (browserStore.isMobile) {
      closeLayoutSidebar();
    }
  }, [location.pathname, browserStore.isMobile]);

  const renderSubMenuTitle = (img, title) => {
    return (
      <div className="site-sidebar-submenu">
        <div className="site-sidebar-submenu-img">
          <img src={img} alt="sub-menu-img" />
        </div>
        {!layoutStore.sidebarCollapsed && (
          <span className="site-sidebar-submenu-title">{title}</span>
        )}
      </div>
    );
  };

  const canDisplayMenuItem = useCallback(
    item => !item.roles || (Array.isArray(item.roles) && item.roles.includes(authUserRole)),
    [authUserRole],
  );

  const canDisplaySubMenuItem = useCallback(
    menuItem => {
      let visibleBySetting = true;
      const menuSetting = get(menuItem, 'companySettings');
      if (menuSetting) {
        const isEnabled = get(companySettings, menuSetting.settingToCheck, false);
        if ((!isEnabled && !!menuSetting.hideIfFalse) || (isEnabled && !!menuSetting.hideIfTrue)) {
          visibleBySetting = false;
        }
      }

      return visibleBySetting;
    },
    [companySettings],
  );

  const getMenuItems = useCallback(
    menuList => {
      const items = [];

      menuList.filter(canDisplayMenuItem).forEach(menuItem => {
        if (typeof menuItem?.visible === 'function' && !menuItem.visible({ currentCompany })) {
          return;
        }

        const subnav = get(menuItem, 'subnav', []).filter(canDisplaySubMenuItem);

        if (subnav.length < 1) {
          const settings = menuItem.companySettings;
          let label = t(menuItem.title);

          if (settings) {
            const isEnabled = get(companySettings, settings.settingToCheck, false);

            if (!isEnabled && !!settings.renameIfFalse) {
              label = t(settings.renameIfFalse);
            }
          }

          items.push({
            label,
            key: menuItem.route || menuItem.key,
            icon: menuItem.img ? <img src={menuItem.img} alt="sub-menu-img" /> : null,
          });
        } else {
          items.push({
            key: menuItem.route || menuItem.key,
            label: t(menuItem.title),
            icon: menuItem.img ? <img src={menuItem.img} alt="sub-menu-img" /> : null,
            children: getMenuItems(subnav),
          });
        }
      });

      return items;
    },
    [t, companySettings, currentCompany, canDisplayMenuItem, canDisplaySubMenuItem],
  );

  const handleMenuItemClick = useCallback(
    item => {
      history.push(item.key);
    },
    [history],
  );

  const sideMenuItems = useMemo(() => {
    const items = { top: [], bottom: [] };

    if (
      !(
        !!isRegCompleted ||
        hasSystemAdminRole({ profile: { role: authUserRole } }) ||
        hasBackOfficeUserRole({ profile: { role: authUserRole } })
      ) ||
      isInOnboardingPage
    ) {
      return items;
    }

    items.top = getMenuItems(MENU_LIST.MenuList);
    items.bottom = getMenuItems(MENU_LIST.BottomSideMenuList);

    return items;
  }, [isRegCompleted, isInOnboardingPage, authUserRole, getMenuItems]);

  const handleClickOutside = useCallback(
    event => {
      // Is outside click
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Is mobile
        if (browserStore.isMobile && !layoutStore.sidebarCollapsed) {
          closeLayoutSidebar();
        }
      }
    },
    [closeLayoutSidebar, browserStore.isMobile, layoutStore.sidebarCollapsed],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  if (!authUser) return null;

  return (
    <div ref={sidebarRef} className={classNames.sidebarWrapper}>
      <Layout.Sider
        className={classnames(classNames.siteSider, {
          collapsed: layoutStore.sidebarCollapsed,
        })}
        trigger={null}
        collapsible
        collapsed={layoutStore.sidebarCollapsed}
        width="250"
        // collapsedWidth="80"
      >
        <div
          onClick={handleGoToDashboard}
          className={`${classNames['logo-wrapper']} ${
            layoutStore.sidebarCollapsed ? classNames['logo-small'] : classNames['logo-expanded']
          }`}
        >
          <img
            src={
              layoutStore.sidebarCollapsed
                ? '/images/logo/logo-sidebar-small-v2.svg'
                : '/images/logo/logo-sidebar-v2.svg'
            }
            alt="sidebar-img"
          />
        </div>

        <Menu
          theme="dark"
          mode="inline"
          items={sideMenuItems.top}
          selectedKeys={selectedMenuKeys}
          onClick={handleMenuItemClick}
        />

        <div className={classNames.siderFooter}>
          <Menu
            theme="dark"
            mode="inline"
            items={sideMenuItems.bottom}
            selectedKeys={selectedMenuKeys}
            onClick={handleMenuItemClick}
          />

          <Tooltip
            placement="right"
            title={t('Expand sidebar')}
            {...(!layoutStore.sidebarCollapsed ? { visible: false } : {})}
          >
            <div className={`sidebar-footer-item logout`} onClick={handleToggleLayoutSidebar}>
              {renderSubMenuTitle(`/images/icons/indent-decrease.svg`, t('Minimize sidebar'))}
            </div>
          </Tooltip>
        </div>

        {/* <div className={classNames.sidebarInnerMenuWrapper}>


          <div className={classNames.siderFooter}>

          </div>
        </div> */}
      </Layout.Sider>
    </div>
  );
};

export default Sidebar;
