import { Row, Space } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { PERIOD_ANOMALIES_API } from '../../api/periodAnomalies';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import AnomaliesTripsOverAverageTable from '../../components/Table/AnomaliesTripsOverAverageTable';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import usePaymentSchedulePeriods from '../../hooks/usePaymentSchedulePeriods';
import useTablePagination from '../../hooks/useTablePagination';
import useTableSort from '../../hooks/useTableSort';
import { formatPageTitle } from '../../utils/common';
import { cleanQueryParams, parseQueryParams } from '../../utils/queryParams';
import { selectStoreCompanySettings, selectStoreCurrentCompany } from '../../utils/storeSelectors';
import AnomalyPagesFilterHeader from '../anomalies-dashboard/components/AnomalyPagesFilterHeader';

const PageWrapper = ({ t, history, children, queryParams, loading }) => {
  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('Dashboard'),
              onClick: () =>
                history.push({
                  pathname: INTERNAL_LINKS.ANOMALIES_DASHBOARD,
                  search: cleanQueryParams(queryParams),
                }),
            },
            { label: t('anomalies:highTripCount') },
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('High Trip Count')}</title>
      </Helmet>

      {children}
    </PageContainer>
  );
};

const AnomaliesTripsOverAverageAlertView = props => {
  const { t, location, history } = props;

  const { paymentScheduleId, fromDate, endDate } = parseQueryParams(location.search);
  const companySettings = useSelector(selectStoreCompanySettings);
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const {
    isFetchingPaymentSchedules,
    paymentScheduleOptions,
    selectedPaymentSchedule,
    selectedPaymentSchedulePeriods,
    selectedPaymentPeriod,
    handlePaymentScheduleSelect,
    handlePeriodSelect,
  } = usePaymentSchedulePeriods({ paymentScheduleId, fromDate, endDate });

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination();

  const { tableSort, handleTableSort } = useTableSort({ increasePercentage: -1 });

  const anomaliesParams = useMemo(
    () => ({
      type: 'trips-over-average',
      companyId: currentCompany._id,
      paymentScheduleId: get(selectedPaymentSchedule, '_id'),
      paymentPeriodStart: selectedPaymentPeriod
        ? get(JSON.parse(selectedPaymentPeriod), 'fromDate')
        : '',
      paymentPeriodEnd: selectedPaymentPeriod
        ? get(JSON.parse(selectedPaymentPeriod), 'endDate')
        : '',
    }),
    [selectedPaymentSchedule, selectedPaymentPeriod, currentCompany],
  );

  const commuteAnomaliesQuery = useQuery({
    enabled: !!get(selectedPaymentSchedule, '_id') && !!selectedPaymentPeriod,
    queryKey: [
      'getPeriodAnomalies',
      anomaliesParams.type,
      anomaliesParams.paymentScheduleId,
      anomaliesParams.paymentPeriodStart,
      anomaliesParams.paymentPeriodEnd,
      paginationConfig.current,
      paginationConfig.pageSize,
      JSON.stringify(tableSort),
    ],
    queryFn: () =>
      PERIOD_ANOMALIES_API.getPeriodAnomalies(
        {
          sort: tableSort,
          ...anomaliesParams,
        },
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    onSuccess: ({ count }) => handleTotalUpdate(count),
  });

  const exportTripsOverAverageAnomaliesMutation = useMutation(() =>
    PERIOD_ANOMALIES_API.exportPeriodAnomalies(anomaliesParams),
  );

  if (isFetchingPaymentSchedules || !selectedPaymentSchedule) {
    return <PageWrapper t={t} history={history} loading />;
  }

  return (
    <PageWrapper
      t={t}
      history={history}
      queryParams={{
        paymentScheduleId: anomaliesParams.paymentScheduleId,
        fromDate: anomaliesParams.paymentPeriodStart,
        endDate: anomaliesParams.paymentPeriodEnd,
      }}
    >
      <Space direction="vertical" size="large">
        <AnomalyPagesFilterHeader
          t={t}
          selectedPaymentPeriod={selectedPaymentPeriod}
          selectedPaymentSchedulePeriods={selectedPaymentSchedulePeriods}
          selectedPaymentSchedule={selectedPaymentSchedule}
          paymentScheduleOptions={paymentScheduleOptions}
          onPaymentScheduleSelect={handlePaymentScheduleSelect}
          onPeriodSelect={handlePeriodSelect}
          isExportingData={exportTripsOverAverageAnomaliesMutation.isLoading}
          onDataExport={exportTripsOverAverageAnomaliesMutation.mutateAsync}
        />

        <Row>
          <AnomaliesTripsOverAverageTable
            t={t}
            asyncSort
            hideReceipts={companySettings?.hideReceipts}
            loading={commuteAnomaliesQuery.isFetching}
            dataSource={get(commuteAnomaliesQuery, 'data.documents', [])}
            onChange={({ current }, filters, sorters) => {
              handlePageChange(current);
              handleTableSort(sorters?.columnKey, sorters?.order);
            }}
            pagination={{
              pageSize: paginationConfig.pageSize,
              total: paginationConfig.total,
              current: paginationConfig.current,
              onShowSizeChange: handlePageSizeChange,
            }}
          />
        </Row>
      </Space>
    </PageWrapper>
  );
};

export default withNamespaces(['default', 'anomalies'])(
  withAuthentication(AnomaliesTripsOverAverageAlertView),
);
