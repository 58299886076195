import i18n from '../i18n';

export default () => {
  const StatusList = [
    {
      label: i18n.t('active'),
      value: 'active',
    },
    {
      label: i18n.t('inactive'),
      value: 'inactive',
    },
    {
      label: i18n.t('pending'),
      value: 'pending',
    },
  ];

  const Status = {
    // General
    IN_PROGRESS: 'in-progress',
    IN_PROGRESS_measureOne: 'in_progress',
    PENDING: 'pending',
    APPROVED: 'approved',
    DENIED: 'denied',
    SUBMITTED: 'submitted',
    UNSUBMITTED: 'unsubmitted',
    REQUEST: 'request',
    PAID: 'paid',
    NOT_PAID: 'not-paid',
    COMPLETED_WITH_ERRORS: 'completed-with-errors',

    // User-specific
    ACTIVE: 'active',
    ACTIVE_TRIAL: 'active-trial',
    TRIAL_END: 'trial-ended',
    INACTIVE: 'inactive',
    ON_LEAVE: 'on_leave',
    DELETED: 'deleted',
    ACCOUNT_LOCKED: 'account_locked',

    // Receipt specific
    MORE_INFO: 'more-info',
    NA: 'n/a',
    NA_UPPER: 'N/A',

    // Trip-specific
    OVERDUE: 'overdue',

    // Frontend specific
    NEW: 'new',

    // Anonymize
    AWAITING_APPROVAL: 'awaiting-approval',
    AWAITING_ANONYMIZATION: 'awaiting-anonymization',
    ANONYMIZED: 'anonymized',

    // Download Data request
    GENERATING_FILE: 'generating',

    // Company specific
    CANCELLED: 'cancelled',
    PROCESSING_DELETE: 'processing_delete',

    // Plan specific
    DEACTIVATED: 'deactivated',

    // Import location specific
    COMPLETED: 'completed',
    PROCESSING: 'processing',
    AWAITING_CONFIRMATION: 'awaiting-confirmation',

    // FAVR
    FIX: 'fix',

    // Stripe invoice status
    DRAFT_INVOICE: 'draft',
    OPEN_INVOICE: 'open',
    VOID_INVOICE: 'void',
    UNCOLLECTIBLE: 'uncollectible',

    // Compliance Status
    NOT_AVAILABLE: 'not-available',
    VALID: 'valid',
    INVALID: 'invalid',
    IN_COMPLIANCE: 'inCompliance',
    OUT_OF_COMPLIANCE: 'outOfCompliance',
    NON_COMPLIANT: 'non-compliant',

    // ACH Dwolla
    NOT_STARTED: 'not-started',
    VERIFIED: 'verified',
    UNVERIFIED: 'unverified',
    RETRY: 'retry',
    DOCUMENT: 'document',
    SUSPENDED: 'suspended',
    INCOMPLETE: 'incomplete',
    NOT_INITIATED: 'not-initiated',
    BENEFICIAL_REQUIRES_CERTIFICATION: 'beneficial-requires-certification',
    PENDING_VERIFICATION: 'pending-verification',
    PROCESSED: 'processed',
    FAILED: 'failed',
    SUCCESS: 'success',
    ERROR: 'error',
    COMPLETE: 'complete',
    ACH_PENDING: 'ach-pending',
    ACH_CANCELLED: 'ach-cancelled',
    ACH_FAILED: 'ach-failed',
    INSUFFICIENT_FUNDS: 'insufficient-funds',
    UNPROCESSABLE: 'unprocessable',

    // Insurance MO
    ITEM_UNAVAILABLE: 'item_unavailable',
    REFRESH_FAILED: 'refresh_failed',
  };

  const StatusTagColorConfig = {
    [Status.DENIED]: {
      variant: 'red',
      label: i18n.t('denied'),
    },
    [Status.SUBMITTED]: {
      variant: 'orange',
      label: i18n.t('awaitingApproval'),
    },
    [Status.UNSUBMITTED]: {
      variant: 'secondary',
      label: i18n.t('unsubmitted'),
    },
    [Status.REQUEST]: {
      variant: 'orange',
      label: i18n.t('request'),
    },
    [Status.IN_PROGRESS]: {
      variant: 'blue',
      label: i18n.t('inProgress'),
    },
    [Status.IN_PROGRESS_measureOne]: {
      variant: 'blue',
      label: i18n.t('inProgress'),
    },
    [Status.PENDING]: {
      variant: 'orange',
      label: i18n.t('pending'),
    },
    [Status.APPROVED]: {
      variant: 'primary',
      label: i18n.t('approved'),
    },
    [Status.PAID]: {
      variant: 'primary',
      label: i18n.t('Paid'),
    },
    [Status.NOT_PAID]: {
      variant: 'red',
      label: i18n.t('notPaid'),
    },
    [Status.OVERDUE]: {
      variant: 'red',
      label: i18n.t('overdue'),
    },
    [Status.ACTIVE]: {
      variant: 'primary',
      label: i18n.t('active'),
    },
    [Status.ACTIVE_TRIAL]: {
      variant: 'primary',
      label: i18n.t('activeTrial'),
    },
    [Status.TRIAL_END]: {
      variant: 'red',
      label: i18n.t('trialEnded'),
    },
    [Status.INACTIVE]: {
      variant: 'secondary',
      label: i18n.t('inactive'),
    },
    [Status.ON_LEAVE]: {
      variant: 'orange',
      label: i18n.t('onLeave'),
    },
    [Status.DELETED]: {
      variant: 'red',
      label: i18n.t('deleted'),
    },
    [Status.NEW]: {
      variant: 'blue',
      label: i18n.t('new'),
    },
    [Status.AWAITING_ANONYMIZATION]: {
      variant: 'orange',
      label: i18n.t('awaitingAnon'),
    },
    [Status.AWAITING_APPROVAL]: {
      variant: 'orange',
      label: i18n.t('awaitingApproval'),
    },
    [Status.ANONYMIZED]: {
      variant: 'primary',
      label: i18n.t('anonymized'),
    },
    [Status.CANCELLED]: {
      variant: 'red',
      label: i18n.t('cancelled'),
    },
    [Status.DEACTIVATED]: {
      variant: 'red',
      label: i18n.t('deactivated'),
    },
    [Status.GENERATING_FILE]: {
      variant: 'blue',
      label: i18n.t('generatingFile'),
    },
    [Status.MORE_INFO]: {
      variant: 'orange',
      label: i18n.t('moreInfo'),
    },
    [Status.COMPLETED]: {
      variant: 'primary',
      label: i18n.t('completed'),
    },
    [Status.AWAITING_CONFIRMATION]: {
      variant: 'blue',
      label: i18n.t('awaitingConfirmation'),
    },
    [Status.COMPLETED_WITH_ERRORS]: {
      variant: 'red',
      label: i18n.t('completedWithErrors'),
    },
    [Status.PROCESSING]: {
      variant: 'orange',
      label: i18n.t('processing'),
    },
    [Status.PROCESSING_DELETE]: {
      variant: 'red',
      label: i18n.t('deletingInProcess'),
    },
    [Status.FIX]: {
      variant: 'red',
      label: i18n.t('fix'),
    },
    [Status.DRAFT_INVOICE]: {
      variant: 'orange',
      label: i18n.t('draft'),
    },
    [Status.OPEN_INVOICE]: {
      variant: 'orange',
      label: i18n.t('notPaid'),
    },
    [Status.VOID_INVOICE]: {
      variant: 'red',
      label: i18n.t('voidInvoice'),
    },
    [Status.UNCOLLECTIBLE]: {
      variant: 'red',
      label: i18n.t('uncollectible'),
    },
    [Status.NA]: {
      variant: 'red',
      label: i18n.t('na'),
    },
    [Status.NA_UPPER]: {
      variant: 'red',
      label: i18n.t('na'),
    },
    [Status.NOT_STARTED]: {
      variant: 'red',
      label: i18n.t('notStarted'),
    },
    [Status.NOT_INITIATED]: {
      variant: 'red',
      label: i18n.t('requiresConfirmation'),
    },
    [Status.VERIFIED]: {
      variant: 'primary',
      label: i18n.t('verified'),
    },
    [Status.UNVERIFIED]: {
      variant: 'red',
      label: i18n.t('unverified'),
    },
    [Status.INCOMPLETE]: {
      variant: 'red',
      label: i18n.t('incomplete'),
    },
    [Status.RETRY]: {
      variant: 'red',
      label: i18n.t('retry'),
    },
    [Status.DOCUMENT]: {
      variant: 'blue',
      label: i18n.t('requiresDocuments'),
    },
    [Status.SUSPENDED]: {
      variant: 'red',
      label: i18n.t('suspended'),
    },
    [Status.BENEFICIAL_REQUIRES_CERTIFICATION]: {
      variant: 'blue',
      label: i18n.t('beneficialOwnerRequiresCert'),
    },
    [Status.PENDING_VERIFICATION]: {
      variant: 'orange',
      label: i18n.t('pendingVerification'),
    },
    [Status.PROCESSED]: {
      variant: 'primary',
      label: i18n.t('processed'),
    },
    [Status.FAILED]: {
      variant: 'red',
      label: i18n.t('failed'),
    },
    [Status.SUCCESS]: {
      variant: 'primary',
      label: i18n.t('success'),
    },
    [Status.ERROR]: {
      variant: 'red',
      label: i18n.t('error'),
    },
    [Status.COMPLETE]: {
      variant: 'primary',
      label: i18n.t('completed'),
    },
    [Status.NOT_AVAILABLE]: {
      variant: 'red',
      label: i18n.t('notAvailable'),
    },
    [Status.VALID]: {
      variant: 'primary',
      label: i18n.t('valid'),
    },
    [Status.INVALID]: {
      variant: 'red',
      label: i18n.t('invalid'),
    },
    [Status.IN_COMPLIANCE]: {
      variant: 'primary',
      label: i18n.t('inCompliance'),
    },
    [Status.OUT_OF_COMPLIANCE]: {
      variant: 'red',
      label: i18n.t('outOfCompliance'),
    },
    [Status.NON_COMPLIANT]: {
      variant: 'red',
      label: i18n.t('nonCompliant'),
    },
    [Status.ACH_PENDING]: {
      variant: 'orange',
      label: `${i18n.t('ach')} - ${i18n.t('pending')}`,
    },
    [Status.ACH_FAILED]: {
      variant: 'red',
      label: `${i18n.t('ach')} - ${i18n.t('failed')}`,
    },
    [Status.ACH_CANCELLED]: {
      variant: 'red',
      label: `${i18n.t('ach')} - ${i18n.t('cancelled')}`,
    },
    [Status.ACCOUNT_LOCKED]: {
      variant: 'red',
      label: i18n.t('accountLocked'),
    },
    [Status.INSUFFICIENT_FUNDS]: {
      variant: 'red',
      label: i18n.t('insufficientFunds'),
    },
    [Status.UNPROCESSABLE]: {
      variant: 'red',
      label: i18n.t('unprocessable'),
    },
    [Status.ITEM_UNAVAILABLE]: {
      variant: 'red',
      label: i18n.t('itemUnavailable'),
    },
    [Status.REFRESH_FAILED]: {
      variant: 'red',
      label: i18n.t('refreshFailed'),
    },
  };

  return { Status, StatusList, StatusTagColorConfig };
};
