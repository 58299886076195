const countryCodes = [
    {
        "zh": "加拿大",
        "en": "Canada",
        "short": "CA",
        "emoji": "🇨🇦",
        "phoneCode": "1",
        "key": 'canada-1',
    },
    {
        "zh": "美国",
        "en": "United States",
        "short": "US",
        "emoji": "🇺🇸",
        "phoneCode": "1",
        "key": 'usa-1',
    },
    {
        "zh": "巴哈马",
        "en": "Bahamas",
        "short": "BS",
        "emoji": "🇧🇸",
        "phoneCode": "1242"
    },
    {
        "zh": "巴巴多斯",
        "en": "Barbados",
        "short": "BB",
        "emoji": "🇧🇧",
        "phoneCode": "1246"
    },
    {
        "zh": "安圭拉",
        "en": "Anguilla",
        "short": "AI",
        "emoji": "🇦🇮",
        "phoneCode": "1264"
    },
    {
        "zh": "安提瓜和巴布达",
        "en": "Antigua and Barbuda",
        "short": "AG",
        "emoji": "🇦🇬",
        "phoneCode": "1268"
    },
    {
        "zh": "美属维尔京群岛",
        "en": "Virgin Islands, US",
        "short": "VI",
        "phoneCode": "1284",
        "emoji": "🇻🇮"
    },
    {
        "zh": "英属处女群岛",
        "en": "Virgin Islands, British",
        "short": "VG",
        "emoji": "🇻🇬",
        "phoneCode": "1340"
    },
    {
        "zh": "开曼群岛",
        "en": "Cayman Islands",
        "short": "KY",
        "emoji": "🇰🇾",
        "phoneCode": "1345"
    },
    {
        "zh": "百慕大群岛",
        "en": "Bermuda",
        "short": "BM",
        "emoji": "🇧🇲",
        "phoneCode": "1441"
    },
    {
        "zh": "格林纳达",
        "en": "Grenada",
        "short": "GD",
        "emoji": "🇬🇩",
        "phoneCode": "1473"
    },
    {
        "zh": "特克斯和凯科斯群岛",
        "en": "Turks and Caicos Islands",
        "short": "TC",
        "emoji": "🇹🇨",
        "phoneCode": "1649"
    },
    {
        "zh": "蒙特塞拉特岛",
        "en": "Montserrat",
        "short": "MS",
        "emoji": "🇲🇸",
        "phoneCode": "1664"
    },
    {
        "zh": "关岛",
        "en": "Guam",
        "short": "GU",
        "emoji": "🇬🇺",
        "phoneCode": "1671"
    },
    {
        "zh": "美属萨摩亚",
        "en": "American Samoa",
        "short": "AS",
        "emoji": "🇦🇸",
        "phoneCode": "1684"
    },
    {
        "zh": "圣马丁岛（荷兰部分）",
        "en": "Saint Maarten (Dutch Part)",
        "short": "SX",
        "emoji": "🇳🇱",
        "phoneCode": "1721"
    },
    {
        "zh": "圣露西亚",
        "en": "Saint Lucia",
        "short": "LC",
        "emoji": "🇱🇨",
        "phoneCode": "1758"
    },
    {
        "zh": "多米尼加",
        "en": "Dominica",
        "short": "DM",
        "emoji": "🇩🇲",
        "phoneCode": "1767"
    },
    {
        "zh": "圣文森特和格林纳丁斯",
        "en": "Saint Vincent and The Grenadines",
        "short": "VC",
        "emoji": "🇻🇨",
        "phoneCode": "1784"
    },
    {
        "zh": "波多黎各",
        "en": "Puerto Rico",
        "short": "PR",
        "emoji": "🇵🇷",
        "phoneCode": "1787"
    },
    {
        "zh": "多米尼加共和国",
        "en": "Dominican Republic",
        "short": "DO",
        "emoji": "🇩🇴",
        "phoneCode": "1809"
    },
    {
        "zh": "特立尼达和多巴哥",
        "en": "Trinidad and Tobago",
        "short": "TT",
        "emoji": "🇹🇹",
        "phoneCode": "1868"
    },
    {
        "zh": "圣基茨和尼维斯",
        "en": "Saint Kitts and Nevis",
        "short": "KN",
        "emoji": "🇰🇳",
        "phoneCode": "1869"
    },
    {
        "zh": "牙买加",
        "en": "Jamaica",
        "short": "JM",
        "emoji": "🇯🇲",
        "phoneCode": "1876"
    },
    {
        "zh": "埃及",
        "en": "Egypt",
        "short": "EG",
        "emoji": "🇪🇬",
        "phoneCode": "20"
    },
    {
        "zh": "摩洛哥",
        "en": "Morocco",
        "short": "MA",
        "emoji": "🇲🇦",
        "phoneCode": "212"
    },
    {
        "zh": "阿尔及利亚",
        "en": "Algeria",
        "short": "DZ",
        "emoji": "🇩🇿",
        "phoneCode": "213"
    },
    {
        "zh": "突尼斯",
        "en": "Tunisia",
        "short": "TN",
        "emoji": "🇹🇳",
        "phoneCode": "216"
    },
    {
        "zh": "利比亚",
        "en": "Libya",
        "short": "LY",
        "emoji": "🇱🇾",
        "phoneCode": "218"
    },
    {
        "zh": "冈比亚",
        "en": "Gambia",
        "short": "GM",
        "emoji": "🇬🇲",
        "phoneCode": "220"
    },
    {
        "zh": "塞内加尔",
        "en": "Senegal",
        "short": "SN",
        "emoji": "🇸🇳",
        "phoneCode": "221"
    },
    {
        "zh": "毛里塔尼亚",
        "en": "Mauritania",
        "short": "MR",
        "emoji": "🇲🇷",
        "phoneCode": "222"
    },
    {
        "zh": "马里",
        "en": "Mali",
        "short": "ML",
        "emoji": "🇲🇱",
        "phoneCode": "223"
    },
    {
        "zh": "几内亚",
        "en": "Guinea",
        "short": "GN",
        "emoji": "🇬🇳",
        "phoneCode": "224"
    },
    {
        "zh": "象牙海岸",
        "en": "Ivory Coast",
        "short": "CI",
        "phoneCode": "225",
        "emoji": "🇨🇮"
    },
    {
        "zh": "布基纳法索",
        "en": "Burkina Faso",
        "short": "BF",
        "emoji": "🇧🇫",
        "phoneCode": "226"
    },
    {
        "zh": "尼日尔",
        "en": "Niger",
        "short": "NE",
        "emoji": "🇳🇪",
        "phoneCode": "227"
    },
    {
        "zh": "多哥",
        "en": "Togo",
        "short": "TG",
        "emoji": "🇹🇬",
        "phoneCode": "228"
    },
    {
        "zh": "贝宁",
        "en": "Benin",
        "short": "BJ",
        "emoji": "🇧🇯",
        "phoneCode": "229"
    },
    {
        "zh": "毛里求斯",
        "en": "Mauritius",
        "short": "MU",
        "emoji": "🇲🇺",
        "phoneCode": "230"
    },
    {
        "zh": "利比里亚",
        "en": "Liberia",
        "short": "LR",
        "emoji": "🇱🇷",
        "phoneCode": "231"
    },
    {
        "zh": "塞拉利昂",
        "en": "Sierra Leone",
        "short": "SL",
        "emoji": "🇸🇱",
        "phoneCode": "232"
    },
    {
        "zh": "加纳",
        "en": "Ghana",
        "short": "GH",
        "emoji": "🇬🇭",
        "phoneCode": "233"
    },
    {
        "zh": "尼日利亚",
        "en": "Nigeria",
        "short": "NG",
        "emoji": "🇳🇬",
        "phoneCode": "234"
    },
    {
        "zh": "乍得",
        "en": "Chad",
        "short": "TD",
        "emoji": "🇹🇩",
        "phoneCode": "235"
    },
    {
        "zh": "中非共和国",
        "en": "Central African Republic",
        "short": "CF",
        "emoji": "🇨🇫",
        "phoneCode": "236"
    },
    {
        "zh": "喀麦隆",
        "en": "Cameroon",
        "short": "CM",
        "emoji": "🇨🇲",
        "phoneCode": "237"
    },
    {
        "zh": "开普",
        "en": "Cape Verde",
        "short": "CV",
        "emoji": "🇨🇻",
        "phoneCode": "238"
    },
    {
        "zh": "圣多美和普林西比",
        "en": "Sao Tome and Principe",
        "short": "ST",
        "emoji": "🇸🇹",
        "phoneCode": "239"
    },
    {
        "zh": "赤道几内亚",
        "en": "Equatorial Guinea",
        "short": "GQ",
        "emoji": "🇬🇶",
        "phoneCode": "240"
    },
    {
        "zh": "加蓬",
        "en": "Gabon",
        "short": "GA",
        "emoji": "🇬🇦",
        "phoneCode": "241"
    },
    {
        "zh": "刚果共和国",
        "en": "Republic Of The Congo",
        "short": "CG",
        "phoneCode": "242",
        "emoji": "🇨🇬"
    },
    {
        "zh": "刚果民主共和国",
        "en": "Democratic Republic of the Congo",
        "short": "CD",
        "phoneCode": "243",
        "emoji": "🇨🇩"
    },
    {
        "zh": "安哥拉",
        "en": "Angola",
        "short": "AO",
        "emoji": "🇦🇴",
        "phoneCode": "244"
    },
    {
        "zh": "几内亚比绍共和国",
        "en": "Guinea-Bissau",
        "short": "GW",
        "emoji": "🇬🇼",
        "phoneCode": "245"
    },
    {
        "zh": "塞舌尔",
        "en": "Seychelles",
        "short": "SC",
        "emoji": "🇸🇨",
        "phoneCode": "248"
    },
    {
        "zh": "苏丹",
        "en": "Sudan",
        "short": "SD",
        "emoji": "🇸🇩",
        "phoneCode": "249"
    },
    {
        "zh": "卢旺达",
        "en": "Rwanda",
        "short": "RW",
        "emoji": "🇷🇼",
        "phoneCode": "250"
    },
    {
        "zh": "埃塞俄比亚",
        "en": "Ethiopia",
        "short": "ET",
        "emoji": "🇪🇹",
        "phoneCode": "251"
    },
    {
        "zh": "索马里",
        "en": "Somalia",
        "short": "SO",
        "emoji": "🇸🇴",
        "phoneCode": "252"
    },
    {
        "zh": "吉布提",
        "en": "Djibouti",
        "short": "DJ",
        "emoji": "🇩🇯",
        "phoneCode": "253"
    },
    {
        "zh": "肯尼亚",
        "en": "Kenya",
        "short": "KE",
        "emoji": "🇰🇪",
        "phoneCode": "254"
    },
    {
        "zh": "坦桑尼亚",
        "en": "Tanzania",
        "short": "TZ",
        "emoji": "🇹🇿",
        "phoneCode": "255"
    },
    {
        "zh": "乌干达",
        "en": "Uganda",
        "short": "UG",
        "emoji": "🇺🇬",
        "phoneCode": "256"
    },
    {
        "zh": "布隆迪",
        "en": "Burundi",
        "short": "BI",
        "emoji": "🇧🇮",
        "phoneCode": "257"
    },
    {
        "zh": "莫桑比克",
        "en": "Mozambique",
        "short": "MZ",
        "emoji": "🇲🇿",
        "phoneCode": "258"
    },
    {
        "zh": "赞比亚",
        "en": "Zambia",
        "short": "ZM",
        "emoji": "🇿🇲",
        "phoneCode": "260"
    },
    {
        "zh": "马达加斯加",
        "en": "Madagascar",
        "short": "MG",
        "emoji": "🇲🇬",
        "phoneCode": "261"
    },
    {
        "zh": "留尼汪",
        "en": "Réunion Island",
        "short": "RE",
        "phoneCode": "262",
        "emoji": "🇷🇪"
    },
    {
        "zh": "津巴布韦",
        "en": "Zimbabwe",
        "short": "ZW",
        "emoji": "🇿🇼",
        "phoneCode": "263"
    },
    {
        "zh": "纳米比亚",
        "en": "Namibia",
        "short": "NA",
        "emoji": "🇳🇦",
        "phoneCode": "264"
    },
    {
        "zh": "马拉维",
        "en": "Malawi",
        "short": "MW",
        "emoji": "🇲🇼",
        "phoneCode": "265"
    },
    {
        "zh": "莱索托",
        "en": "Lesotho",
        "short": "LS",
        "emoji": "🇱🇸",
        "phoneCode": "266"
    },
    {
        "zh": "博茨瓦纳",
        "en": "Botswana",
        "short": "BW",
        "emoji": "🇧🇼",
        "phoneCode": "267"
    },
    {
        "zh": "斯威士兰",
        "en": "Swaziland",
        "short": "SZ",
        "emoji": "🇸🇿",
        "phoneCode": "268"
    },
    {
        "zh": "科摩罗",
        "en": "Comoros",
        "short": "KM",
        "emoji": "🇰🇲",
        "phoneCode": "269"
    },
    {
        "zh": "马约特",
        "en": "Mayotte",
        "short": "YT",
        "emoji": "🇾🇹",
        "phoneCode": "269"
    },
    {
        "zh": "南非",
        "en": "South Africa",
        "short": "ZA",
        "emoji": "🇿🇦",
        "phoneCode": "27"
    },
    {
        "zh": "厄立特里亚",
        "en": "Eritrea",
        "short": "ER",
        "emoji": "🇪🇷",
        "phoneCode": "291"
    },
    {
        "zh": "阿鲁巴",
        "en": "Aruba",
        "short": "AW",
        "emoji": "🇦🇼",
        "phoneCode": "297"
    },
    {
        "zh": "法罗群岛",
        "en": "Faroe Islands",
        "short": "FO",
        "emoji": "🇫🇴",
        "phoneCode": "298"
    },
    {
        "zh": "格陵兰岛",
        "en": "Greenland",
        "short": "GL",
        "emoji": "🇬🇱",
        "phoneCode": "299"
    },
    {
        "zh": "希腊",
        "en": "Greece",
        "short": "GR",
        "emoji": "🇬🇷",
        "phoneCode": "30"
    },
    {
        "zh": "荷兰",
        "en": "Netherlands",
        "short": "NL",
        "emoji": "🇳🇱",
        "phoneCode": "31"
    },
    {
        "zh": "比利时",
        "en": "Belgium",
        "short": "BE",
        "emoji": "🇧🇪",
        "phoneCode": "32"
    },
    {
        "zh": "法国",
        "en": "France",
        "short": "FR",
        "emoji": "🇫🇷",
        "phoneCode": "33"
    },
    {
        "zh": "西班牙",
        "en": "Spain",
        "short": "ES",
        "emoji": "🇪🇸",
        "phoneCode": "34"
    },
    {
        "zh": "直布罗陀",
        "en": "Gibraltar",
        "short": "GI",
        "emoji": "🇬🇮",
        "phoneCode": "350"
    },
    {
        "zh": "葡萄牙",
        "en": "Portugal",
        "short": "PT",
        "emoji": "🇵🇹",
        "phoneCode": "351"
    },
    {
        "zh": "卢森堡",
        "en": "Luxembourg",
        "short": "LU",
        "emoji": "🇱🇺",
        "phoneCode": "352"
    },
    {
        "zh": "爱尔兰",
        "en": "Ireland",
        "short": "IE",
        "emoji": "🇮🇪",
        "phoneCode": "353"
    },
    {
        "zh": "冰岛",
        "en": "Iceland",
        "short": "IS",
        "emoji": "🇮🇸",
        "phoneCode": "354"
    },
    {
        "zh": "阿尔巴尼亚",
        "en": "Albania",
        "short": "AL",
        "emoji": "🇦🇱",
        "phoneCode": "355"
    },
    {
        "zh": "马耳他",
        "en": "Malta",
        "short": "MT",
        "emoji": "🇲🇹",
        "phoneCode": "356"
    },
    {
        "zh": "塞浦路斯",
        "en": "Cyprus",
        "short": "CY",
        "emoji": "🇨🇾",
        "phoneCode": "357"
    },
    {
        "zh": "芬兰",
        "en": "Finland",
        "short": "FI",
        "emoji": "🇫🇮",
        "phoneCode": "358"
    },
    {
        "zh": "保加利亚",
        "en": "Bulgaria",
        "short": "BG",
        "emoji": "🇧🇬",
        "phoneCode": "359"
    },
    {
        "zh": "匈牙利",
        "en": "Hungary",
        "short": "HU",
        "emoji": "🇭🇺",
        "phoneCode": "36"
    },
    {
        "zh": "立陶宛",
        "en": "Lithuania",
        "short": "LT",
        "emoji": "🇱🇹",
        "phoneCode": "370"
    },
    {
        "zh": "拉脱维亚",
        "en": "Latvia",
        "short": "LV",
        "emoji": "🇱🇻",
        "phoneCode": "371"
    },
    {
        "zh": "爱沙尼亚",
        "en": "Estonia",
        "short": "EE",
        "emoji": "🇪🇪",
        "phoneCode": "372"
    },
    {
        "zh": "摩尔多瓦",
        "en": "Moldova",
        "short": "MD",
        "emoji": "🇲🇩",
        "phoneCode": "373"
    },
    {
        "zh": "亚美尼亚",
        "en": "Armenia",
        "short": "AM",
        "emoji": "🇦🇲",
        "phoneCode": "374"
    },
    {
        "zh": "白俄罗斯",
        "en": "Belarus",
        "short": "BY",
        "emoji": "🇧🇾",
        "phoneCode": "375"
    },
    {
        "zh": "安道尔",
        "en": "Andorra",
        "short": "AD",
        "emoji": "🇦🇩",
        "phoneCode": "376"
    },
    {
        "zh": "摩纳哥",
        "en": "Monaco",
        "short": "MC",
        "emoji": "🇲🇨",
        "phoneCode": "377"
    },
    {
        "zh": "圣马力诺",
        "en": "San Marino",
        "short": "SM",
        "emoji": "🇸🇲",
        "phoneCode": "378"
    },
    {
        "zh": "乌克兰",
        "en": "Ukraine",
        "short": "UA",
        "emoji": "🇺🇦",
        "phoneCode": "380"
    },
    {
        "zh": "塞尔维亚",
        "en": "Serbia",
        "short": "RS",
        "emoji": "🇷🇸",
        "phoneCode": "381"
    },
    {
        "zh": "黑山",
        "en": "Montenegro",
        "short": "ME",
        "emoji": "🇲🇪",
        "phoneCode": "382"
    },
    {
        "zh": "克罗地亚",
        "en": "Croatia",
        "short": "HR",
        "emoji": "🇭🇷",
        "phoneCode": "385"
    },
    {
        "zh": "斯洛文尼亚",
        "en": "Slovenia",
        "short": "SI",
        "emoji": "🇸🇮",
        "phoneCode": "386"
    },
    {
        "zh": "波斯尼亚和黑塞哥维那",
        "en": "Bosnia and Herzegovina",
        "short": "BA",
        "emoji": "🇧🇦",
        "phoneCode": "387"
    },
    {
        "zh": "马其顿",
        "en": "Macedonia",
        "short": "MK",
        "emoji": "🇲🇰",
        "phoneCode": "389"
    },
    {
        "zh": "意大利",
        "en": "Italy",
        "short": "IT",
        "emoji": "🇮🇹",
        "phoneCode": "39"
    },
    {
        "zh": "罗马尼亚",
        "en": "Romania",
        "short": "RO",
        "emoji": "🇷🇴",
        "phoneCode": "40"
    },
    {
        "zh": "瑞士",
        "en": "Switzerland",
        "short": "CH",
        "emoji": "🇨🇭",
        "phoneCode": "41"
    },
    {
        "zh": "捷克",
        "en": "Czech",
        "short": "CZ",
        "emoji": "🇨🇿",
        "phoneCode": "420"
    },
    {
        "zh": "斯洛伐克",
        "en": "Slovakia",
        "short": "SK",
        "emoji": "🇸🇰",
        "phoneCode": "421"
    },
    {
        "zh": "列支敦士登",
        "en": "Liechtenstein",
        "short": "LI",
        "emoji": "🇱🇮",
        "phoneCode": "423"
    },
    {
        "zh": "奥地利",
        "en": "Austria",
        "short": "AT",
        "emoji": "🇦🇹",
        "phoneCode": "43"
    },
    {
        "zh": "英国",
        "en": "United Kingdom",
        "short": "GB",
        "emoji": "🇬🇧",
        "phoneCode": "44"
    },
    {
        "zh": "丹麦",
        "en": "Denmark",
        "short": "DK",
        "emoji": "🇩🇰",
        "phoneCode": "45"
    },
    {
        "zh": "瑞典",
        "en": "Sweden",
        "short": "SE",
        "emoji": "🇸🇪",
        "phoneCode": "46"
    },
    {
        "zh": "挪威",
        "en": "Norway",
        "short": "NO",
        "emoji": "🇳🇴",
        "phoneCode": "47"
    },
    {
        "zh": "波兰",
        "en": "Poland",
        "short": "PL",
        "emoji": "🇵🇱",
        "phoneCode": "48"
    },
    {
        "zh": "德国",
        "en": "Germany",
        "short": "DE",
        "emoji": "🇩🇪",
        "phoneCode": "49"
    },
    {
        "zh": "伯利兹",
        "en": "Belize",
        "short": "BZ",
        "emoji": "🇧🇿",
        "phoneCode": "501"
    },
    {
        "zh": "瓜地马拉",
        "en": "Guatemala",
        "short": "GT",
        "emoji": "🇬🇹",
        "phoneCode": "502"
    },
    {
        "zh": "萨尔瓦多",
        "en": "El Salvador",
        "short": "SV",
        "emoji": "🇸🇻",
        "phoneCode": "503"
    },
    {
        "zh": "洪都拉斯",
        "en": "Honduras",
        "short": "HN",
        "emoji": "🇭🇳",
        "phoneCode": "504"
    },
    {
        "zh": "尼加拉瓜",
        "en": "Nicaragua",
        "short": "NI",
        "emoji": "🇳🇮",
        "phoneCode": "505"
    },
    {
        "zh": "哥斯达黎加",
        "en": "Costa Rica",
        "short": "CR",
        "emoji": "🇨🇷",
        "phoneCode": "506"
    },
    {
        "zh": "巴拿马",
        "en": "Panama",
        "short": "PA",
        "emoji": "🇵🇦",
        "phoneCode": "507"
    },
    {
        "zh": "圣彼埃尔和密克隆岛",
        "en": "Saint Pierre and Miquelon",
        "short": "PM",
        "emoji": "🇵🇲",
        "phoneCode": "508"
    },
    {
        "zh": "海地",
        "en": "Haiti",
        "short": "HT",
        "emoji": "🇭🇹",
        "phoneCode": "509"
    },
    {
        "zh": "秘鲁",
        "en": "Peru",
        "short": "PE",
        "emoji": "🇵🇪",
        "phoneCode": "51"
    },
    {
        "zh": "墨西哥",
        "en": "Mexico",
        "short": "MX",
        "emoji": "🇲🇽",
        "phoneCode": "52"
    },
    {
        "zh": "古巴",
        "en": "Cuba",
        "short": "CU",
        "emoji": "🇨🇺",
        "phoneCode": "53"
    },
    {
        "zh": "阿根廷",
        "en": "Argentina",
        "short": "AR",
        "emoji": "🇦🇷",
        "phoneCode": "54"
    },
    {
        "zh": "巴西",
        "en": "Brazil",
        "short": "BR",
        "emoji": "🇧🇷",
        "phoneCode": "55"
    },
    {
        "zh": "智利",
        "en": "Chile",
        "short": "CL",
        "emoji": "🇨🇱",
        "phoneCode": "56"
    },
    {
        "zh": "哥伦比亚",
        "en": "Colombia",
        "short": "CO",
        "emoji": "🇨🇴",
        "phoneCode": "57"
    },
    {
        "zh": "委内瑞拉",
        "en": "Venezuela",
        "short": "VE",
        "emoji": "🇻🇪",
        "phoneCode": "58"
    },
    {
        "zh": "瓜德罗普岛",
        "en": "Guadeloupe",
        "short": "GP",
        "emoji": "🇬🇵",
        "phoneCode": "590"
    },
    {
        "zh": "玻利维亚",
        "en": "Bolivia",
        "short": "BO",
        "emoji": "🇧🇴",
        "phoneCode": "591"
    },
    {
        "zh": "圭亚那",
        "en": "Guyana",
        "short": "GY",
        "emoji": "🇬🇾",
        "phoneCode": "592"
    },
    {
        "zh": "厄瓜多尔",
        "en": "Ecuador",
        "short": "EC",
        "emoji": "🇪🇨",
        "phoneCode": "593"
    },
    {
        "zh": "法属圭亚那",
        "en": "French Guiana",
        "short": "GF",
        "emoji": "🇬🇫",
        "phoneCode": "594"
    },
    {
        "zh": "巴拉圭",
        "en": "Paraguay",
        "short": "PY",
        "emoji": "🇵🇾",
        "phoneCode": "595"
    },
    {
        "zh": "马提尼克",
        "en": "Martinique",
        "short": "MQ",
        "emoji": "🇲🇶",
        "phoneCode": "596"
    },
    {
        "zh": "苏里南",
        "en": "Suriname",
        "short": "SR",
        "emoji": "🇸🇷",
        "phoneCode": "597"
    },
    {
        "zh": "乌拉圭",
        "en": "Uruguay",
        "short": "UY",
        "emoji": "🇺🇾",
        "phoneCode": "598"
    },
    {
        "zh": "库拉索",
        "en": "Curacao",
        "short": "CW",
        "phoneCode": "599",
        "emoji": "🇨🇼"
    },
    {
        "zh": "马来西亚",
        "en": "Malaysia",
        "short": "MY",
        "emoji": "🇲🇾",
        "phoneCode": "60"
    },
    {
        "zh": "澳大利亚",
        "en": "Australia",
        "short": "AU",
        "emoji": "🇦🇺",
        "phoneCode": "61"
    },
    {
        "zh": "印度尼西亚",
        "en": "Indonesia",
        "short": "ID",
        "emoji": "🇮🇩",
        "phoneCode": "62"
    },
    {
        "zh": "菲律宾",
        "en": "Philippines",
        "short": "PH",
        "emoji": "🇵🇭",
        "phoneCode": "63"
    },
    {
        "zh": "新西兰",
        "en": "New Zealand",
        "short": "NZ",
        "emoji": "🇳🇿",
        "phoneCode": "64"
    },
    {
        "zh": "新加坡",
        "en": "Singapore",
        "short": "SG",
        "emoji": "🇸🇬",
        "phoneCode": "65"
    },
    {
        "zh": "泰国",
        "en": "Thailand",
        "short": "TH",
        "emoji": "🇹🇭",
        "phoneCode": "66"
    },
    {
        "zh": "东帝汶",
        "en": "Timor-Leste",
        "short": "TL",
        "emoji": "🇹🇱",
        "phoneCode": "670"
    },
    {
        "zh": "文莱",
        "en": "Brunei",
        "short": "BN",
        "emoji": "🇧🇳",
        "phoneCode": "673"
    },
    {
        "zh": "巴布亚新几内亚",
        "en": "Papua New Guinea",
        "short": "PG",
        "emoji": "🇵🇬",
        "phoneCode": "675"
    },
    {
        "zh": "汤加",
        "en": "Tonga",
        "short": "TO",
        "emoji": "🇹🇴",
        "phoneCode": "676"
    },
    {
        "zh": "所罗门群岛",
        "en": "Solomon Islands",
        "short": "SB",
        "emoji": "🇸🇧",
        "phoneCode": "677"
    },
    {
        "zh": "瓦努阿图",
        "en": "Vanuatu",
        "short": "VU",
        "emoji": "🇻🇺",
        "phoneCode": "678"
    },
    {
        "zh": "斐济",
        "en": "Fiji",
        "short": "FJ",
        "emoji": "🇫🇯",
        "phoneCode": "679"
    },
    {
        "zh": "帕劳",
        "en": "Palau",
        "short": "PW",
        "emoji": "🇵🇼",
        "phoneCode": "680"
    },
    {
        "zh": "库克群岛",
        "en": "Cook Islands",
        "short": "CK",
        "emoji": "🇨🇰",
        "phoneCode": "682"
    },
    {
        "zh": "萨摩亚",
        "en": "Samoa",
        "short": "WS",
        "emoji": "🇼🇸",
        "phoneCode": "685"
    },
    {
        "zh": "基里巴斯",
        "en": "Kiribati",
        "short": "KI",
        "emoji": "🇰🇮",
        "phoneCode": "686"
    },
    {
        "zh": "新喀里多尼亚",
        "en": "New Caledonia",
        "short": "NC",
        "emoji": "🇳🇨",
        "phoneCode": "687"
    },
    {
        "zh": "法属波利尼西亚",
        "en": "French Polynesia",
        "short": "PF",
        "emoji": "🇵🇫",
        "phoneCode": "689"
    },
    {
        "zh": "哈萨克斯坦",
        "en": "Kazakhstan",
        "short": "KZ",
        "emoji": "🇰🇿",
        "phoneCode": "7"
    },
    {
        "zh": "俄罗斯",
        "en": "Russia",
        "short": "RU",
        "emoji": "🇷🇺",
        "phoneCode": "7"
    },
    {
        "zh": "日本",
        "en": "Japan",
        "short": "JP",
        "emoji": "🇯🇵",
        "phoneCode": "81"
    },
    {
        "zh": "韩国",
        "en": "South Korea",
        "short": "KR",
        "emoji": "🇰🇷",
        "phoneCode": "82"
    },
    {
        "zh": "越南",
        "en": "Vietnam",
        "short": "VN",
        "emoji": "🇻🇳",
        "phoneCode": "84"
    },
    {
        "zh": "中国香港",
        "en": "Hong Kong",
        "short": "HK",
        "emoji": "🇭🇰",
        "phoneCode": "852"
    },
    {
        "zh": "中国澳门",
        "en": "Macau",
        "short": "MO",
        "phoneCode": "853",
        "emoji": "🇲🇴"
    },
    {
        "zh": "柬埔寨",
        "en": "Cambodia",
        "short": "KH",
        "emoji": "🇰🇭",
        "phoneCode": "855"
    },
    {
        "zh": "老挝",
        "en": "Laos",
        "short": "LA",
        "phoneCode": "856",
        "emoji": "🇱🇦"
    },
    {
        "zh": "中国",
        "en": "China",
        "short": "CN",
        "emoji": "🇨🇳",
        "phoneCode": "86"
    },
    {
        "zh": "孟加拉国",
        "en": "Bangladesh",
        "short": "BD",
        "emoji": "🇧🇩",
        "phoneCode": "880"
    },
    {
        "zh": "中国台湾",
        "en": "Taiwan",
        "short": "TW",
        "emoji": "🇨🇳",
        "phoneCode": "886"
    },
    {
        "zh": "土耳其",
        "en": "Turkey",
        "short": "TR",
        "emoji": "🇹🇷",
        "phoneCode": "90"
    },
    {
        "zh": "印度",
        "en": "India",
        "short": "IN",
        "emoji": "🇮🇳",
        "phoneCode": "91"
    },
    {
        "zh": "巴基斯坦",
        "en": "Pakistan",
        "short": "PK",
        "emoji": "🇵🇰",
        "phoneCode": "92"
    },
    {
        "zh": "阿富汗",
        "en": "Afghanistan",
        "short": "AF",
        "emoji": "🇦🇫",
        "phoneCode": "93"
    },
    {
        "zh": "斯里兰卡",
        "en": "Sri Lanka",
        "short": "LK",
        "emoji": "🇱🇰",
        "phoneCode": "94"
    },
    {
        "zh": "缅甸",
        "en": "Myanmar",
        "short": "MM",
        "emoji": "🇲🇲",
        "phoneCode": "95"
    },
    {
        "zh": "马尔代夫",
        "en": "Maldives",
        "short": "MV",
        "emoji": "🇲🇻",
        "phoneCode": "960"
    },
    {
        "zh": "黎巴嫩",
        "en": "Lebanon",
        "short": "LB",
        "emoji": "🇱🇧",
        "phoneCode": "961"
    },
    {
        "zh": "约旦",
        "en": "Jordan",
        "short": "JO",
        "emoji": "🇯🇴",
        "phoneCode": "962"
    },
    {
        "zh": "叙利亚",
        "en": "Syria",
        "short": "SY",
        "emoji": "🇸🇾",
        "phoneCode": "963"
    },
    {
        "zh": "伊拉克",
        "en": "Iraq",
        "short": "IQ",
        "emoji": "🇮🇶",
        "phoneCode": "964"
    },
    {
        "zh": "科威特",
        "en": "Kuwait",
        "short": "KW",
        "emoji": "🇰🇼",
        "phoneCode": "965"
    },
    {
        "zh": "沙特阿拉伯",
        "en": "Saudi Arabia",
        "short": "SA",
        "emoji": "🇸🇦",
        "phoneCode": "966"
    },
    {
        "zh": "也门",
        "en": "Yemen",
        "short": "YE",
        "emoji": "🇾🇪",
        "phoneCode": "967"
    },
    {
        "zh": "阿曼",
        "en": "Oman",
        "short": "OM",
        "emoji": "🇴🇲",
        "phoneCode": "968"
    },
    {
        "zh": "巴勒斯坦",
        "en": "Palestine",
        "short": "BL",
        "emoji": "🇵🇸",
        "phoneCode": "970"
    },
    {
        "zh": "阿拉伯联合酋长国",
        "en": "United Arab Emirates",
        "short": "AE",
        "emoji": "🇦🇪",
        "phoneCode": "971"
    },
    {
        "zh": "以色列",
        "en": "Israel",
        "short": "IL",
        "emoji": "🇮🇱",
        "phoneCode": "972"
    },
    {
        "zh": "巴林",
        "en": "Bahrain",
        "short": "BH",
        "emoji": "🇧🇭",
        "phoneCode": "973"
    },
    {
        "zh": "卡塔尔",
        "en": "Qatar",
        "short": "QA",
        "emoji": "🇶🇦",
        "phoneCode": "974"
    },
    {
        "zh": "不丹",
        "en": "Bhutan",
        "short": "BT",
        "emoji": "🇧🇹",
        "phoneCode": "975"
    },
    {
        "zh": "蒙古",
        "en": "Mongolia",
        "short": "MN",
        "emoji": "🇲🇳",
        "phoneCode": "976"
    },
    {
        "zh": "尼泊尔",
        "en": "Nepal",
        "short": "NP",
        "emoji": "🇳🇵",
        "phoneCode": "977"
    },
    {
        "zh": "伊朗",
        "en": "Iran",
        "short": "IR",
        "emoji": "🇮🇷",
        "phoneCode": "98"
    },
    {
        "zh": "塔吉克斯坦",
        "en": "Tajikistan",
        "short": "TJ",
        "emoji": "🇹🇯",
        "phoneCode": "992"
    },
    {
        "zh": "土库曼斯坦",
        "en": "Turkmenistan",
        "short": "TM",
        "emoji": "🇹🇲",
        "phoneCode": "993"
    },
    {
        "zh": "阿塞拜疆",
        "en": "Azerbaijan",
        "short": "AZ",
        "emoji": "🇦🇿",
        "phoneCode": "994"
    },
    {
        "zh": "格鲁吉亚",
        "en": "Georgia",
        "short": "GE",
        "emoji": "🇬🇪",
        "phoneCode": "995"
    },
    {
        "zh": "吉尔吉斯斯坦",
        "en": "Kyrgyzstan",
        "short": "KG",
        "emoji": "🇰🇬",
        "phoneCode": "996"
    },
    {
        "zh": "乌兹别克斯坦",
        "en": "Uzbekistan",
        "short": "UZ",
        "emoji": "🇺🇿",
        "phoneCode": "998"
    }
];

export default countryCodes;
