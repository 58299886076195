import { Col, Row } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { RECEIPT_API } from '../../../api/receipt';
import { REIMBURSEMENT_API } from '../../../api/reimbursement';
import Button from '../../../components/Button';
import CompanyUsersLookupSelect from '../../../components/CompanyUsersLookupSelect';
import DownloadIcon from '../../../components/DownloadIcon';
import PageFiltersRenderer from '../../../components/shared-ui/PageFiltersRenderer';
import ReimbursementsTable from '../../../components/Table/ReimbursementsTable';
import LinkText from '../../../components/Text/LinkText';
import Toast from '../../../components/Toast';
import UserGroupsLookupSelect from '../../../components/UserGroupsLookupSelect';
import { INTERNAL_LINKS, PAYMENT_CONF, USER_ROLES } from '../../../enum';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useUserGroupsQuery from '../../../hooks/queries/useUserGroupsQuery';
import useDebounce from '../../../hooks/useDebounce';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useLocationSearchQueryParser from '../../../hooks/useLocationSearchQueryParser';
import useTableSort from '../../../hooks/useTableSort';
import { formatQueryRange, momentFormat } from '../../../utils/common';
import { cleanQueryParams } from '../../../utils/queryParams';
import { hasCompanyManagerOrAdminRole, hasUserRole } from '../../../utils/roles';
import { selectStoreCurrentAuthUser } from '../../../utils/storeSelectors';
import ReimbursementPageContainer from '../ReimbursementPageContainer';
import { canMarkReimbursementAsPaid } from './reimbursements-permissions';

const COMMON_RESPONSE_FILTER_SETTINGS = {
  xs: 24,
  md: 12,
  lg: 4,
};

const UserReimbursements = props => {
  const { t, history, location } = props;

  const initialFilters = useLocationSearchQueryParser(location);

  const myProfile = useSelector(selectStoreCurrentAuthUser);
  const companySettings = useSelector(store => store.common.currentCompany.companySettingId);
  const userGroupsQuery = useUserGroupsQuery(myProfile?.profile?._id);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedTripReceipts, setSelectedTripReceipts] = useState([]);
  const [reimbursementData, setReimbursementData] = useState();
  const [filters, setFilters] = useState({
    canBeUpdatedManually: initialFilters.canBeUpdatedManually,
    driverID:
      myProfile.profile.role === USER_ROLES.USER
        ? myProfile.profile._id
        : initialFilters.user || null,
    groupID:
      typeof initialFilters.group !== 'undefined' && initialFilters.group !== ''
        ? initialFilters.group
        : Array.isArray(userGroupsQuery.data)
        ? get(userGroupsQuery, 'data[0]._id', null)
        : null,
    status: initialFilters.status || null,
    searchTerm: initialFilters.searchTerm || '',
    fromDate: initialFilters.fromDate
      ? moment(initialFilters.fromDate)
      : moment().subtract(30, 'days').startOf('day'),
    toDate:
      initialFilters.toDate || initialFilters.endDateRange
        ? moment(initialFilters.toDate || initialFilters.endDateRange)
        : moment(),
  });

  const debuncedReimbursementFilters = useDebounce(filters);

  useDidUpdateEffect(() => {
    if (myProfile.profile.role === USER_ROLES.USER && filters.driverID !== myProfile.profile._id) {
      updateFilters({ driverID: myProfile.profile._id });
    } else {
      reimbursementsQuery.refetch();
    }
  }, [myProfile.profile.role]);

  /**
   * Handle table filter state update
   */
  const updateFilters = (newFilters = {}) => {
    setFilters(currentFilters => {
      const updatedFilters = { ...currentFilters, ...newFilters };

      history.replace({
        search: cleanQueryParams({
          group: updatedFilters.groupID,
          status: updatedFilters.status,
          fromDate: updatedFilters?.fromDate?.toISOString(),
          toDate: updatedFilters?.toDate?.toISOString(),
          user: updatedFilters.driverID,
          searchTerm: updatedFilters.searchTerm,
          canBeUpdatedManually: updatedFilters.canBeUpdatedManually,
        }),
      });

      return updatedFilters;
    });
  };

  const { stringTableSort, handleTableSort } = useTableSort({ seqId: -1 });

  const {
    query: reimbursementsQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      queryKey: ['reimbursements', ...Object.values(debuncedReimbursementFilters), stringTableSort],
      queryFn: () => {
        const { fromDate, toDate } = debuncedReimbursementFilters;
        return REIMBURSEMENT_API.fetchReimbursements({
          fromDate: momentFormat(fromDate, 'YYYY-MM-DD'),
          toDate: momentFormat(toDate, 'YYYY-MM-DD'),
          status: debuncedReimbursementFilters.status,
          userId: debuncedReimbursementFilters.driverID,
          groupId: hasUserRole(myProfile) ? null : filters.groupID,
          searchTerm: debuncedReimbursementFilters.searchTerm,
          canBeUpdatedManually: debuncedReimbursementFilters.canBeUpdatedManually,
          range: formatQueryRange(paginationConfig.current, paginationConfig.pageSize),
          sort: stringTableSort,
        });
      },
      onSuccess: result => {
        handleLoadReimbursement(result);
      },
      onError: () => {
        Toast({
          type: 'error',
          message: t('dailyTripDownloadError'),
        });
        setSelectedRowKeys([]);
        setSelectedTripReceipts([]);
      },
    },
    {
      resetPageQueryKey: Object.values(debuncedReimbursementFilters),
      initialPage: initialFilters.page,
      initialPageSize: initialFilters.pageSize,
      useQueryParams: true,
    },
  );

  /**
   * Updates the state with the newly fetched reimbursements
   */
  const handleLoadReimbursement = result => {
    setSelectedRowKeys([]);
    setSelectedTripReceipts([]);
    setReimbursementData(result.reimbursements);
  };

  /**
   * Handler for table row selection
   */
  const onSelectChange = (rowKeys, selectedRowsData) => {
    setSelectedRowKeys(rowKeys);

    const tripReceiptIds = selectedRowsData.map(data => get(data, 'tripReceiptId')).filter(Boolean);
    setSelectedTripReceipts(tripReceiptIds);
  };

  const onReimbursementClick = id => {
    props.history.push(`${INTERNAL_LINKS.REIMBURSEMENT}/${id}`, {
      goReimbursementsQueryParams: window.location.search,
    });
  };

  const exportReimbursementMutation = useMutation(
    () => {
      const { fromDate, toDate } = filters;

      return REIMBURSEMENT_API.exportReimbursement({
        userId: filters.driverID,
        status: filters.status,
        groupId: hasUserRole(myProfile) ? null : filters.groupID,
        fromDate: momentFormat(fromDate, 'YYYY-MM-DD'),
        toDate: momentFormat(toDate, 'YYYY-MM-DD'),
        sort: stringTableSort,
        range: formatQueryRange(paginationConfig.current, paginationConfig.pageSize),
      });
    },
    {
      onSuccess: () => {
        Toast({
          type: 'open',
          duration: 10, // seconds
          message: (
            <Trans
              t={t}
              i18nKey="exportBeingProcessed"
              components={[
                <LinkText variant="b" onClick={() => props.history.push(INTERNAL_LINKS.EXPORTS)}>
                  Dummy
                </LinkText>,
              ]}
            />
          ),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const markReceiptAsPaidMutation = useMutation(
    () => RECEIPT_API.markReceiptAsPaid(selectedTripReceipts),
    {
      onSuccess: async () => {
        await reimbursementsQuery.refetch();
        Toast({
          type: 'open',
          message: t('reimbursementsMarkAsPaidSucesss'),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  return (
    <ReimbursementPageContainer t={t} companySettings={companySettings}>
      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col flex={1}>
          <PageFiltersRenderer
            t={t}
            loading={reimbursementsQuery.isFetching}
            onFiltersChange={updateFilters}
            filters={[
              {
                hidden: !hasCompanyManagerOrAdminRole(myProfile),
                componentType: 'custom',
                label: t('user'),
                name: 'driverID',
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
                defaultValue: initialFilters.user || null,
                render: ({ updateFilterValues, disabled, loading }) => (
                  <CompanyUsersLookupSelect
                    t={t}
                    showAllOption
                    disabled={disabled || loading}
                    value={filters?.driverID}
                    defaultValue={initialFilters.user || null}
                    onChange={driverID => updateFilterValues({ driverID })}
                  />
                ),
              },
              {
                hidden: !hasCompanyManagerOrAdminRole(myProfile),
                componentType: 'custom',
                label: t('Group'),
                name: 'groupID',
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
                defaultValue: filters.group,
                render: ({ updateFilterValues, disabled, loading }) => (
                  <UserGroupsLookupSelect
                    t={t}
                    showAllOption
                    hideFormItem
                    userId={myProfile.profile._id}
                    disabled={disabled || loading}
                    value={filters?.groupID}
                    onChange={groupID => updateFilterValues({ groupID })}
                    defaultValue={filters.group}
                  />
                ),
              },
              {
                label: t('date'),
                allowClear: false,
                componentType: 'dateRange',
                name: ['fromDate', 'toDate'],
                defaultValue:
                  filters.fromDate && filters.toDate ? [filters.fromDate, filters.toDate] : null,
                value:
                  filters.fromDate && filters.toDate ? [filters.fromDate, filters.toDate] : null,
                responsive: {
                  xs: 24,
                  md: 12,
                  lg: 6,
                },
              },
              {
                componentType: 'select',
                label: t('status'),
                name: 'status',
                defaultValue: initialFilters.status || null,
                value: filters.status,
                options: [{ label: t('All'), value: null }, ...PAYMENT_CONF().SelectOptions],
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              },
              {
                componentType: 'select',
                label: t('payableViaACH'),
                name: 'canBeUpdatedManually',
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
                defaultValue: initialFilters.canBeUpdatedManually || null,
                value: filters.canBeUpdatedManually,
                hidden: !companySettings.settings.ach_enabled,
                options: [
                  { label: t('All'), value: null },
                  { label: t('yes'), value: false },
                  { label: t('no'), value: true },
                ],
              },
            ]}
            search={{
              defaultValue: initialFilters.searchTerm,
            }}
          />
        </Col>

        <DownloadIcon
          t={t}
          colWidth="65px"
          text={t('exportToCSV')}
          loading={exportReimbursementMutation.isLoading || reimbursementsQuery.isFetching}
          onClick={exportReimbursementMutation.mutateAsync}
          disabled={
            !paginationConfig.total ||
            paginationConfig.total === 0 ||
            exportReimbursementMutation.isLoading ||
            reimbursementsQuery.isFetching
          }
        />
      </Row>

      <Row style={{ marginBottom: 24 }}>
        <ReimbursementsTable
          t={t}
          asyncSort
          showSearchInput={false}
          onReimbursementClick={onReimbursementClick}
          loading={reimbursementsQuery.isFetching}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
            // getCheckboxProps: item => ({
            //   disabled: !item.canBeUpdatedManually,
            // }),
          }}
          dataSource={Array.isArray(reimbursementData) ? reimbursementData : []}
          pagination={{
            pageSize: paginationConfig.pageSize,
            total: paginationConfig.total,
            current: paginationConfig.current,
            onShowSizeChange: handlePageSizeChange,
          }}
          onChange={({ current }, filters, sorters) => {
            handlePageChange(current);
            handleTableSort(sorters?.columnKey, sorters?.order);
          }}
        />
      </Row>

      {canMarkReimbursementAsPaid(myProfile) && (
        <Row gutter={17} align="middle">
          <Col>
            <Button
              disabled={!selectedRowKeys.length || markReceiptAsPaidMutation.isLoading}
              loading={markReceiptAsPaidMutation.isLoading}
              type="primary"
              onClick={markReceiptAsPaidMutation.mutateAsync}
            >
              {t(!!selectedRowKeys.length ? 'markAsPaid__count' : 'markAsPaid', {
                count: selectedRowKeys.length,
              })}
            </Button>
          </Col>
        </Row>
      )}
    </ReimbursementPageContainer>
  );
};

UserReimbursements.propTypes = {};

UserReimbursements.defaultProps = {};

export default withNamespaces()(UserReimbursements);
