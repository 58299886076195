import { Avatar as AntdAvatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Text from '../Text';
import classNames from './style.module.scss';

/**
 * User Avatar component
 */
const Avatar = props => {
  const { size, src, initials, className } = props;

  const SIZES = {
    small: 32,
    large: 40,
  };

  return (
    <AntdAvatar
      src={src}
      shape="circle"
      size={typeof size === 'string' ? SIZES[size] : size}
      children={
        initials ? (
          <Text color="secondary" variant="strong" size="medium">
            {initials}
          </Text>
        ) : null
      }
      className={[classNames.avatar, classNames[size], className].filter(Boolean).join(' ')}
    />
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'large']), PropTypes.number]),
  src: PropTypes.string,
  className: PropTypes.string,
  initials: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
  src: undefined,
  size: 'small',
  className: undefined,
};

export default Avatar;
