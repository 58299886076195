import { useCallback } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import storage from 'store';

import { stopImpersonatingUser } from '../api/auth';
import { INTERNAL_LINKS } from '../enum';
import { KLIKS_SYSTEM_BASENAME } from '../enum/InternalLinks';
import { deviceWidthUpdate } from '../stores/actions/browser';
import { emitResetStores, emitUpdateGlobalBasename } from '../stores/actions/common';
import { emitCollapseLayoutSidebar, emitExpandLayoutSidebar } from '../stores/actions/layout';
import { updateProfile } from '../stores/actions/profile';
import { emitNavigateTo } from '../stores/actions/router';
import { isCurrentCompanySubActive } from '../utils/company';
import { hasBackOfficeUserRole, hasSystemAdminRole } from '../utils/roles';
import { selectStoreCurrentAuthUser } from '../utils/storeSelectors';
import { normalizeUserSchema } from '../utils/users';

const onBoarding = [INTERNAL_LINKS.COMPANY_ONBOARDING, INTERNAL_LINKS.USER_ONBOARDING];

const configPages = [
  INTERNAL_LINKS.PLAN_TRIAL,
  INTERNAL_LINKS.COMPANY_INFO,
  INTERNAL_LINKS.INVITE_USERS,
  INTERNAL_LINKS.REIMBURSEMENT_RATE,
];

const useLayout = ({ history } = {}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const layoutStore = useSelector(store => store.layout);
  const browserStore = useSelector(store => store.browser);
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const isCompanyActive = useSelector(isCurrentCompanySubActive);

  const isInOnboardingPage = useMemo(() => {
    return (
      onBoarding.some(string => new RegExp(string).test(location.pathname)) ||
      configPages.some(string => new RegExp(string).test(location.pathname))
    );
  }, [location]);

  const pageMaxHeightStyle = useMemo(() => {
    let height = 'calc(100vh - 64px';
    const IS_IMPERSONATING = !!storage.get('previousAccessToken');

    if (IS_IMPERSONATING) {
      height += ' - 64px';
    }

    if (!isCompanyActive && !hasSystemAdminRole(authUser)) {
      height += ' - 64px';
    }

    height += ')';
    return height;
  }, [isCompanyActive, authUser]);

  const handleDeviceWidthUpdate = useCallback(
    () => dispatch(deviceWidthUpdate()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Stops impersonation and returns to the previous User
   */
  const handleStopImpersonation = useCallback(async () => {
    const previousUserResult = await stopImpersonatingUser();
    if (previousUserResult) {
      const normalizedUserSchema = normalizeUserSchema(previousUserResult);
      dispatch(emitResetStores());
      dispatch(updateProfile(normalizedUserSchema));

      if (hasSystemAdminRole({ profile: previousUserResult })) {
        dispatch(emitUpdateGlobalBasename(KLIKS_SYSTEM_BASENAME));
        dispatch(emitNavigateTo(INTERNAL_LINKS.SELECT_COMPANY));
      }
    } else {
      history.push({ pathname: INTERNAL_LINKS.LOGIN });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleToggleLayoutSidebar = useCallback(() => {
    if (layoutStore.sidebarCollapsed) {
      dispatch(emitExpandLayoutSidebar());
    } else {
      dispatch(emitCollapseLayoutSidebar());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutStore.sidebarCollapsed]);

  const closeLayoutSidebar = useCallback(() => {
    dispatch(emitCollapseLayoutSidebar());
    // eslint-disable-next-line
  }, []);

  const handleGoToDashboard = useCallback(async () => {
    if (!isInOnboardingPage) {
      if (hasSystemAdminRole(authUser)) {
        history.push({ pathname: INTERNAL_LINKS.SELECT_COMPANY });
      } else if (hasBackOfficeUserRole(authUser)) {
        history.push(INTERNAL_LINKS.HOME);
      } else if (authUser) {
        history.push({ pathname: INTERNAL_LINKS.DASHBOARD });
      } else {
        history.push({ pathname: INTERNAL_LINKS.LOGIN });
      }

      if (browserStore.isMobile) {
        closeLayoutSidebar();
      }
    }
  }, [authUser, history, isInOnboardingPage, closeLayoutSidebar, browserStore.isMobile]);

  return {
    layoutStore,
    browserStore,
    pageMaxHeightStyle,
    isInOnboardingPage,
    handleGoToDashboard,
    handleStopImpersonation,
    handleToggleLayoutSidebar,
    closeLayoutSidebar,
    handleDeviceWidthUpdate,
  };
};

export default useLayout;
