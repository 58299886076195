import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls, handleApiFileDownloads } from './axiosInstance';

export const fetchImportRequests = async (type, companyID, pageNumber, pageSize) => {
  const queryParams = cleanQueryParams({ type, range: formatQueryRange(pageNumber, pageSize) });
  const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/import-requests?${queryParams}`;
  const result = await handleApiCalls('get', url);

  return result.data;
};

/**
 *
 * @param {string} companyID ID of Company
 * @param {number} pageNumber
 * @param {number} pageSize
 */
export const fetchTripsImportRequests = async (companyID, pageNumber, pageSize) => {
  return fetchImportRequests('trip', companyID, pageNumber, pageSize);
};

/**
 * Performs an API call fetch the location import requests of a Company
 *
 * @param {string} companyID ID of Company
 * @param {number} pageNumber
 * @param {number} pageSize
 */
export const fetchLocationImportRequests = async (companyID, pageNumber, pageSize) => {
  return fetchImportRequests('company-location', companyID, pageNumber, pageSize);
};

/**
 * Performs an API call to upload a CSV file with locations of a Company
 *
 * @param {File} file
 */
export const uploadLocationsCsvFile = async file => {
  const url = `${process.env.REACT_APP_HOST_API}company-locations/import`;
  var formData = new FormData();
  formData.append('file', file);
  const result = await handleApiCalls('post', url, formData, {
    'Content-Type': 'multipart/form-data',
  });
  return result.data;
};

/**
 * Performs an API call to fetch a specific location import request
 *
 * @param {string} requestID ID of Import Request
 */
export const fetchImportRequestByID = async requestID => {
  const url = `${process.env.REACT_APP_HOST_API}import-requests/${requestID}`;
  const result = await handleApiCalls('get', url);
  return result.data;
};

/**
 * Performs an API call to download a the location import request CSV
 *
 * @param {string} requestID ID of Import Request
 */
export const downloadImportRequestFile = async (requestID, fileName) => {
  const url = `${process.env.REACT_APP_HOST_API}import-requests/${requestID}/download`;
  return handleApiFileDownloads('get', url, undefined, fileName);
};

/**
 * Performs an API call to cancel a locations import request
 *
 * @param {string} requestID ID of Import Request
 */
export const cancelImportRequest = async requestID => {
  const url = `${process.env.REACT_APP_HOST_API}import-requests/${requestID}/status`;
  const result = await handleApiCalls('patch', url, { status: 'cancel' });
  return result.data;
};

/**
 * Performs an API call to confirm an import request
 *
 * @param {string} requestID ID of Import Request
 * @param {string[]} missingLocationsToDeleteIds IDs of locations to delete
 */
export const confirmImportRequest = async (requestID, missingLocationsToDeleteIds = []) => {
  const url = `${process.env.REACT_APP_HOST_API}import-requests/${requestID}/status`;
  const result = await handleApiCalls('patch', url, {
    status: 'confirm',
    missingLocationsToDeleteIds,
  });
  return result.data;
};

/**
 * Performs an API call to retry an import request
 *
 * @param {string} importRequestId ID of Import Request
 */
export const retryImportRequest = async importRequestId => {
  const url = `${process.env.REACT_APP_HOST_API}import-requests/retry`;
  await handleApiCalls('post', url, { importRequestId });
};

export const IMPORT_REQUEST_API = {
  fetchImportRequestMissingLocations: async (requestID, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const url = `${process.env.REACT_APP_HOST_API}import-requests/${requestID}/missing-locations?range=${range}`;
    const result = await handleApiCalls('get', url);
    return result.data;
  },
};
