import { Col, Row } from 'antd';
import React from 'react';

import Text from '../../Text';
import FadedText from '../../Text/FadedText';
import classNames from './style.module.scss';

/**
 * Data Column for the SummaryCards
 */
const SummaryCardColumn = props => {
  const { children, title, textClassName, sideComponent, ...rest } = props;
  return (
    <div className={classNames.summaryColumn} {...rest}>
      <div style={{ marginBottom: 8 }}>
        {['string', 'number'].includes(typeof children) ? (
          <Text
            className={[classNames.columnText, textClassName].filter(Boolean).join(' ')}
            color="secondary"
            size="xl"
            variant="strong"
            textAlign="left"
            renderAs="div"
          >
            {children}
          </Text>
        ) : (
          children
        )}
      </div>

      <Row align="middle" gutter={8}>
        {title && (
          <Col>
            <FadedText variant="strong" size="sm" textAlign="left" renderAs="div">
              {title}
            </FadedText>
          </Col>
        )}

        {sideComponent && <Col>{sideComponent}</Col>}
      </Row>
    </div>
  );
};

export default SummaryCardColumn;
