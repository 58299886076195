import { Col, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

import { Button } from '../../components';
import Box from '../../components/Box';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import SpaceSpinner from '../../components/SpaceSpinner';
import InvoiceBilledUsersTable from '../../components/Table/InvoiceBilledUsersTable';
import Text from '../../components/Text';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useInvoiceBilledUsers from '../../hooks/useInvoiceBilledUsers';
import { formatPageTitle, momentFormat } from '../../utils/common';

const formatBillingPeriod = invoice =>
  [
    momentFormat(new Date(invoice.periodStart * 1000), 'MMM DD'),
    momentFormat(new Date(invoice.periodEnd * 1000), 'MMM DD, YYYY'),
  ].join(' - ');

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Invoice Details')}</title>
  </Helmet>
);

const InvoiceBilledUserView = props => {
  const { t, history, match, location } = props;
  const {
    isExportingToCSV,
    isLoadingBillingInformation,

    invoice,
    billedUsers,
    paginationConfig,

    handlePageChange,
    handlePageSizeChange,
    handleExportUsersToCSV,
  } = useInvoiceBilledUsers({ t, match, location });

  if (!invoice) {
    return (
      <>
        <PageHelmet />
        <SpaceSpinner />
      </>
    );
  }

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('Invoices'),
              onClick: () => history.push(INTERNAL_LINKS.INVOICES),
            },
            invoice ? { label: invoice.number } : undefined,
          ]}
        />
      }
    >
      <PageHelmet />

      {invoice && (
        <Row align="bottom" justify="space-between" gutter={16} wrap={false}>
          <Col>
            <Box maxWidth="max-content">
              <Row>
                <Text variant="strong">{t('billedUsersForInvoice')}:</Text>
                &nbsp;
                <Text>{invoice.number}</Text>
              </Row>
              <Row>
                <Text variant="strong">{t('billingPeriod')}:</Text>
                &nbsp;
                <Text>{formatBillingPeriod(invoice)}</Text>
              </Row>
            </Box>
          </Col>

          <Col>
            <Button
              loading={isExportingToCSV}
              disabled={isExportingToCSV || isLoadingBillingInformation}
              onClick={handleExportUsersToCSV}
            >
              {t('exportToCSV')}
            </Button>
          </Col>
        </Row>
      )}

      <br />

      <InvoiceBilledUsersTable
        t={t}
        loading={isLoadingBillingInformation}
        dataSource={billedUsers}
        onChange={({ current }) => handlePageChange(current)}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(InvoiceBilledUserView));
