import { useMemo } from 'react';

import { momentTimezone } from '../utils/common';
import { getCenterCoordinates } from '../utils/map';

const useItineraryMap = itineraryDetails => {
  const coordinatesCenter = useMemo(() => {
    if (itineraryDetails) {
      return getCenterCoordinates(itineraryDetails.routeCoordinates).center;
    }
  }, [itineraryDetails]);

  const _createMarkers = trips => {
    const markers = [];
    const ALPHABET_ARRAY = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    const mappedLocationsToLetters = {};
    const mappedLocationsCoordinates = {};

    trips.forEach((trip, i) => {
      if (i === 0) {
        const letter = ALPHABET_ARRAY.shift();
        mappedLocationsCoordinates[JSON.stringify(trip.tripStartLocationGeo)] = letter;

        markers.push({
          ...trip,
          _id: trip.tripEndLocationGeo.toString(),
          label: letter,
          joinedLabel: letter,
          address: trip.tripStartLocationAddress,
          coordinates: trip.tripStartLocationGeo,
          tripStartTime: momentTimezone(trip.tripStartTime).format('h:mma'),
          tripEndTime: momentTimezone(trip.tripEndTime).format('h:mma'),
        });
      }

      const startKey = trip.tripEndLocationGeo.toString();

      let startLetter = mappedLocationsToLetters[startKey];

      if (!startLetter) {
        startLetter = ALPHABET_ARRAY.shift();
        let joinedLetter = startLetter;
        mappedLocationsToLetters[startKey] = startLetter;

        if (mappedLocationsCoordinates[JSON.stringify(trip.tripEndLocationGeo)]) {
          joinedLetter = [
            mappedLocationsCoordinates[JSON.stringify(trip.tripEndLocationGeo)],
            startLetter,
          ].join(',');
          mappedLocationsCoordinates[JSON.stringify(trip.tripEndLocationGeo)] = joinedLetter;
        } else {
          mappedLocationsCoordinates[JSON.stringify(trip.tripEndLocationGeo)] = joinedLetter;
        }

        markers.push({
          ...trip,
          _id: startKey,
          label: startLetter,
          joinedLabel: joinedLetter,
          address: trip.tripEndLocationAddress,
          coordinates: trip.tripEndLocationGeo,
          tripStartTime: momentTimezone(trip.tripStartTime).format('h:mma'),
          tripEndTime: momentTimezone(trip.tripEndTime).format('h:mma'),
        });
      }
    });

    return markers;
  };

  const [itineraryMarkers, optimizedItineraryMarkers] = useMemo(() => {
    if (itineraryDetails) {
      const tripMarkers = _createMarkers(itineraryDetails.trips);
      const optimizedMarkers = Array.isArray(itineraryDetails.optimizedTrips)
        ? _createMarkers(itineraryDetails.optimizedTrips)
        : tripMarkers;

      return [tripMarkers, !optimizedMarkers.length ? tripMarkers : optimizedMarkers];
    }

    return [[], []];
  }, [itineraryDetails]);

  const destinationLabelsChangedOnOptimization = useMemo(() => {
    const changedLabels = [];

    if (Array.isArray(itineraryMarkers) && Array.isArray(optimizedItineraryMarkers)) {
      optimizedItineraryMarkers.forEach((destination, i) => {
        if (destination.address !== itineraryMarkers[i].address) {
          changedLabels.push(destination.label);
        }
      });
    }

    return changedLabels;
  }, [itineraryMarkers, optimizedItineraryMarkers]);

  return {
    coordinatesCenter,
    itineraryMarkers,
    optimizedItineraryMarkers,
    destinationLabelsChangedOnOptimization,
  };
};

export default useItineraryMap;
