import PropTypes from 'prop-types';
import React from 'react';

import { IMAGES } from '../../enum';
import Text from '../Text';
import classNames from './style.module.scss';

/**
 * Content block for the User Activity page
 * that displays a positive or negative trend depending on the provided props.
 */
const ActivityTrendBlock = props => {
  const { amount, percent, text, isPositiveTrend } = props;

  return (
    <div className={classNames.activityTrend}>
      <Text color="secondary" className={classNames.amount} variant="h2">
        {amount}
      </Text>
      <Text className={classNames.text} variant="p" size="m" color="secondary">
        {text}
      </Text>
      <div className={classNames.trendData}>
        {typeof percent !== 'undefined' && (
          <>
            <img
              alt="Trend arrow"
              src={isPositiveTrend ? IMAGES.ARROW_POINT_UP : IMAGES.ARROW_POINT_DOWN}
            />
            <Text variant="strong" size="xs" color={isPositiveTrend ? 'primary' : 'red'}>
              {percent}% vs last year
            </Text>
          </>
        )}
        {props.children}
      </div>
    </div>
  );
};

ActivityTrendBlock.propTypes = {
  children: PropTypes.node,
  percent: PropTypes.number,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isPositiveTrend: PropTypes.bool,
};

ActivityTrendBlock.defaultProps = {
  percent: undefined,
  ActivityTrendBlock: false,
  children: null,
};

export default ActivityTrendBlock;
