import {
  handleApiFileDownloads,
  performApiCallIfCompanySubIsActiveOrCompanyAdmin,
} from './axiosInstance';

/**
 *
 * @param {object} data Policy data
 * @param {string} data.insurancePolicyName
 * @param {string} data.insuredFirstName
 * @param {string} data.insuredLastName
 * @param {string} data.streetOne
 * @param {string} data.streetTwo
 * @param {string} data.city
 * @param {string} data.state
 * @param {number} data.postalCode
 * @param {string} data.country
 * @param {string} data.effectiveStartDate
 * @param {string} data.effectiveEndDate
 * @param {number} data.driverBodilyInjury
 * @param {number} data.accidentBodilyInjury
 * @param {number} data.driverUninsured
 * @param {number} data.accidentUninsured
 * @param {number} data.propertyDamage
 * @param {number} data.collision
 */
export const createInsurancePolicy = async data => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle-insurance-policies`;
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('post', url, data, {
    'Content-Type': 'multipart/form-data',
  });
  return result.data.data;
};

// export const activateInsurancePolicy = async policyID => {
//   const url = `${process.env.REACT_APP_HOST_API}insurance-policies/${policyID}/activate`;
//   const result = await handleApiCalls('patch', url);
//   return result.data;
// };

// export const deactivateInsurancePolicy = async policyID => {
//   const url = `${process.env.REACT_APP_HOST_API}insurance-policies/${policyID}/deactivate`;
//   const result = await handleApiCalls('patch', url);
//   return result.data;
// };

/**
 *
 * @param {string} policyID Insurance Policy ID
 * @param {object} data Policy data
 * @param {string} data.insurancePolicyName
 * @param {string} data.insuredFirstName
 * @param {string} data.insuredLastName
 * @param {string} data.streetOne
 * @param {string} data.streetTwo
 * @param {string} data.city
 * @param {string} data.state
 * @param {number} data.postalCode
 * @param {string} data.country
 * @param {string} data.effectiveStartDate
 * @param {string} data.effectiveEndDate
 * @param {number} data.driverBodilyInjury
 * @param {number} data.accidentBodilyInjury
 * @param {number} data.driverUninsured
 * @param {number} data.accidentUninsured
 * @param {number} data.propertyDamage
 * @param {number} data.collision
 */
export const updateInsurancePolicy = async (policyID, data) => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle-insurance-policies/${policyID}`;
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('put', url, data);
  return result.data.data;
};

export const deleteInsurancePolicy = async policyID => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle-insurance-policies/${policyID}`;
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('delete', url);
  return result.data;
};

export const deleteInsuranceDocumentImage = async (insuranceId, fileKey) => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle-insurance-policies/${insuranceId}/${fileKey}`;
  const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin('delete', url);
  return result.data;
};

export const downloadInsuranceDocumentImage = async (insuranceId, fileKey, filename) => {
  const url = `${process.env.REACT_APP_HOST_API}vehicle-insurance-policies/${insuranceId}/download-image`;
  await handleApiFileDownloads('post', url, { fileKey }, filename);
};
