import { isNil, isNull, mapValues, omitBy } from 'lodash';
import queryString from 'query-string';

export const removeFalseyParams = (params = {}) =>
  omitBy(params, param => param === '' || isNil(param));

export const cleanQueryParams = (params = {}) =>
  new URLSearchParams(removeFalseyParams(params)).toString();

export const parseQueryParams = string => {
  return mapValues(queryString.parse(string), char => {
    if (char === 'true') return true;
    if (char === 'false') return false;
    if (isNull(char)) return null;
    return char;
  });
};

export const replaceCurrentPageSearchQueryParams = (history, newParams = {}) => {
  history.replace({
    hash: window.location.hash,
    search: queryString.stringify({
      ...parseQueryParams(window.location.search),
      ...newParams,
    }),
  });
};
