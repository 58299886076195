export const SETUP_RATE_WIZARD = 'SETUP_RATE_WIZARD';
export const UPDATE_RATE_WIZARD_STORE = 'UPDATE_RATE_WIZARD_STORE';
export const RESET_RATE_WIZARD = 'RESET_RATE_WIZARD';
export const RESET_RATE_WIZARD_VALUES = 'RESET_RATE_WIZARD_VALUES';
export const SET_GROUP_STATE = 'SET_GROUP_STATE';
export const RATE_WIZARD_GO_TO_STEP = 'RATE_WIZARD_GO_TO_STEP';
export const RATE_WIZARD_SET_CURRENT_STEP = 'RATE_WIZARD_SET_CURRENT_STEP';
export const RATE_WIZARD_SAVE_RATE = 'RATE_WIZARD_SAVE_RATE';
export const UPDATE_WIZARD_RATE_VALUES = 'UPDATE_WIZARD_RATE_VALUES';
export const START_WIZARD_RATE_SUBMIT = 'START_WIZARD_RATE_SUBMIT';
export const COMPLETE_WIZARD_RATE_SUBMIT = 'COMPLETE_WIZARD_RATE_SUBMIT';
export const FAIL_WIZARD_RATE_SUBMIT = 'FAIL_WIZARD_RATE_SUBMIT';
export const UPDATE_WIZARD_DIRTY_STATE = 'UPDATE_WIZARD_DIRTY_STATE';

export const emitSetupRateWizard = (rateId, instance) => {
  return { type: SETUP_RATE_WIZARD, data: { rateId, instance } };
};

export const emitUpdateWizardStore = (data = {}) => {
  return { type: UPDATE_RATE_WIZARD_STORE, data };
};

export const emitUpdateWizardDirty = (isDirty, isSelectedVehicleDirty) => {
  return { type: UPDATE_WIZARD_DIRTY_STATE, data: { isDirty, isSelectedVehicleDirty } };
};

export const emitResetRateWizard = () => {
  return { type: RESET_RATE_WIZARD };
};

export const emitResetRateWizardValues = () => {
  return { type: RESET_RATE_WIZARD_VALUES };
};

export const emitGoToStep = stepToSet => {
  return { type: RATE_WIZARD_GO_TO_STEP, data: { stepToSet } };
};

export const emitSetWizardStep = currentStep => {
  return { type: RATE_WIZARD_SET_CURRENT_STEP, data: { currentStep } };
};

export const emitSaveRate = () => {
  return { type: RATE_WIZARD_SAVE_RATE };
};

export const emitUpdateRateValues = newValues => {
  return { type: UPDATE_WIZARD_RATE_VALUES, data: newValues };
};

export const emitStartWizardSubmission = () => {
  return { type: START_WIZARD_RATE_SUBMIT };
};

export const emitCompleteWizardSubmission = () => {
  return { type: COMPLETE_WIZARD_RATE_SUBMIT };
};

export const emitFailedWizardSubmission = () => {
  return { type: FAIL_WIZARD_RATE_SUBMIT };
};
