import { useMutation } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { DWOLLA_API } from '../../api/dwolla';
import Toast from '../../components/Toast';

/**
 * @param {import("i18next").TFunction} t
 * @param {import("react-query").UseMutationOptions} mutationOptions
 */
const useRetryMassTransactionMutation = (t, mutationOptions = {}) => {
  return useMutation(DWOLLA_API.retryMassPaymentItemTransfer, {
    onSuccess: async (data, variables) => {
      if (typeof mutationOptions.onSuccess === 'function') {
        await mutationOptions.onSuccess(data, variables);
      }
      Toast({
        type: 'open',
        message: t('retryTransactionMutationSuccess'),
      });
    },
    onError: async error => {
      if (typeof mutationOptions.onError === 'function') {
        await mutationOptions.onError(error);
      }

      handleApiErrors(error, () => {
        Toast({
          type: 'error',
          message: t('retryTransactionMutationError'),
        });
      });
    },
  });
};
export default useRetryMassTransactionMutation;
