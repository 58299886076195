import { find, get } from 'lodash';
import { useSelector } from 'react-redux';

import { REDUX_STORE } from '../App';

const getStoreState = store => (store ? store : REDUX_STORE.getState());

export const useStoreSelector = (path, defaultValue) =>
  useSelector(store => {
    if (typeof path === 'function') return path(store);
    return get(store, path, defaultValue);
  });

export const selectStoreLoggedInUser = () => {
  const storeState = REDUX_STORE.getState();

  if (storeState.profile) {
    return storeState.profile.profile;
  }
};

export const selectStoreCurrentCompany = store => {
  const storeState = getStoreState(store);
  return storeState.common.currentCompany;
};

export const selectStoreCurrentAuthUser = store => {
  const storeState = getStoreState(store);
  return storeState.profile;
};

export const selectStoreCompanyGroups = () => {
  const storeState = REDUX_STORE.getState();

  return storeState.group.groupList;
};

export const selectStoreGroupWhereMember = userID => {
  const storeGroups = selectStoreCompanyGroups();
  const group = storeGroups.find(group => !!find(get(group, 'users', []), { _id: userID }));
  return group;
};

export const selectStoreCompanyGroupByID = id => {
  return selectStoreCompanyGroups().find(group => group._id === id);
};

export const selectStoreCountries = store => {
  const storeState = getStoreState(store);

  if (storeState.common && Array.isArray(storeState.common.supportedCountries)) {
    return storeState.common.supportedCountries;
  }

  return [];
};

export const selectStoreCountryByCode = (countryCode = 'US') => {
  const storeState = REDUX_STORE.getState();

  if (storeState.common && Array.isArray(storeState.common.supportedCountries)) {
    return storeState.common.supportedCountries.find(
      country => country.code.toLowerCase() === countryCode.toLowerCase(),
    );
  }

  return undefined;
};

export const selectStoreCountryByName = (name = 'United States') => {
  const storeState = REDUX_STORE.getState();

  if (storeState.common && Array.isArray(storeState.common.supportedCountries)) {
    return storeState.common.supportedCountries.find(
      country => country.name.toLowerCase() === name.toLowerCase(),
    );
  }

  return undefined;
};

export const selectStoreCountryByPhoneCode = countryPhoneCode => {
  const storeState = REDUX_STORE.getState();

  if (storeState.common && Array.isArray(storeState.common.supportedCountries)) {
    return storeState.common.supportedCountries.find(
      country => country.countryPhoneCode === countryPhoneCode,
    );
  }

  return undefined;
};

export const selectStoreCountriesUsingMiles = () => {
  const storeState = REDUX_STORE.getState();

  if (storeState.common && Array.isArray(storeState.common.supportedCountries)) {
    const countryCodeList = [];
    storeState.common.supportedCountries.forEach(country => {
      if (country.distanceLong === 'mile') {
        countryCodeList.push(country.code);
      }
    });

    return countryCodeList;
  }

  return [];
};

export const selectStoreUniqueCountryCurrencies = () => {
  const countries = selectStoreCountries();

  const currencyCodeList = [];
  const uniqueCurrencies = [];

  countries.forEach(country => {
    if (!currencyCodeList.includes(country.currencyCode)) {
      currencyCodeList.push(country.currencyCode);
      if (country.currencyCode === 'USD') {
        uniqueCurrencies.unshift({
          label: country.currencyName,
          value: country.currencyCode,
        });
      } else {
        uniqueCurrencies.push({
          label: country.currencyName,
          value: country.currencyCode,
        });
      }
    }
  });

  return uniqueCurrencies;
};

export const selectStoreUserByID = userID => {
  const storeState = REDUX_STORE.getState();
  const { userList } = storeState.user;

  const user = userList.find(user => user._id === userID);
  return user;
};

export const selectStoreCompanyLocationsByID = locationID => {
  const storeState = REDUX_STORE.getState();
  const { locations } = storeState.locations;

  if (!Array.isArray(locations)) return null;

  const location = locations.find(loc => loc._id === locationID);
  return location;
};

export const selectStoreCompanySettings = store => {
  const currentCompany = selectStoreCurrentCompany();

  if (currentCompany) {
    const companySettings = currentCompany.companySettingId || {};

    if (companySettings) {
      return companySettings.settings || {};
    }
  }

  return {};
};

export const selectStoreCompanyCommuteSettings = store => {
  const currentCompany = selectStoreCurrentCompany(store);

  if (currentCompany) {
    const companySettings = currentCompany.companySettingId || {};

    if (companySettings) {
      return companySettings.commute || {};
    }
  }

  return {};
};

export const selectStoreCompanyValuesSettings = store => {
  const currentCompany = selectStoreCurrentCompany(store);
  return get(currentCompany, 'companySettingId.values') || {};
};

export const selectStoreCurrentPlan = store => {
  let storeState = getStoreState(store);

  const { companyData } = storeState.common;

  if (companyData) {
    return companyData.companyPlan;
  }

  return undefined;
};

export const selectStorePlanType = store => {
  const currentPlan = selectStoreCurrentPlan(store);

  return {
    isCustomOrPremium: /premium|custom/gi.test(currentPlan?.planId?.type || ''),
    isCustom: /custom/gi.test(currentPlan?.planId?.type || ''),
    isPremium: /premium/gi.test(currentPlan?.planId?.type || ''),
  };
};

export const selectStoreDefaultGroup = store => {
  let storeState = getStoreState(store);

  const { groupList } = storeState.group;
  const defaultGroup = groupList.find(group => group.defaultGroup);
  return defaultGroup;
};

export const selectStoreBrowserState = store => {
  let storeState = getStoreState(store);
  return storeState.browser;
};

export const selectStoreItineraryClientByID = clientID => {
  let storeState = getStoreState();
  return get(storeState, 'realtor.contacts', []).find(contact => contact._id === clientID);
};

export const selectStoreInsuranceCompany = insuranceCompanyName => {
  const insuranceCompaniesQuery = window.queryClient.getQueryData([
    'infinite',
    'fetchInsuranceCompanies',
    '',
  ]);
  if (Array.isArray(insuranceCompaniesQuery?.pages)) {
    let insuranceCompanies = [];
    insuranceCompaniesQuery.pages.forEach(({ documents }) => {
      insuranceCompanies = insuranceCompanies.concat(documents);
    });

    const insuranceCompany = insuranceCompanies.find(
      company => company._id === insuranceCompanyName,
    );

    return insuranceCompany;
  }

  return null;
};
