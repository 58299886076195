import { get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchPaymentScheduleTimeline } from '../../../api/paymentSchedule';
import { hasCompanyManagerOrAdminRole } from '../../../utils/roles';

const useDashboardEventTimelineState = () => {
  const [timeline, setTimeline] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [timelineFilters, setTimelineFilters] = useState({
    groupID: '',
    startDate: '',
    endDate: '',
  });

  const handleGroupChange = useCallback(groupID => {
    setTimelineFilters({ groupID, startDate: '', endDate: '' });
  }, []);

  const authUser = useSelector(store => store.profile);
  const currentUserId = useMemo(() => get(authUser, 'profile._id'), [authUser]);

  const handleTimelineFilters = filters => setTimelineFilters(filters);

  const loadTimeline = async (groupID, startDate, endDate) => {
    setIsLoading(true);

    const data = await fetchPaymentScheduleTimeline(groupID, startDate, endDate);
    setTimeline(data);

    setIsLoading(false);
  };

  useEffect(() => {
    if (
      hasCompanyManagerOrAdminRole(authUser) &&
      timelineFilters.groupID &&
      timelineFilters.startDate &&
      timelineFilters.endDate
    ) {
      loadTimeline(timelineFilters.groupID, timelineFilters.startDate, timelineFilters.endDate);
    } else {
      setTimeline([]);
    }
    // eslint-disable-next-line
  }, [currentUserId, timelineFilters.groupID, timelineFilters.startDate, timelineFilters.endDate]);

  return {
    authUser,
    timeline,
    isLoading,
    timelineFilters,
    handleTimelineFilters,
    handleGroupChange,
  };
};

export default useDashboardEventTimelineState;
