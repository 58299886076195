import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CARS_API } from '../../api/cars';
import Select from '.';

const VehicleMakeSelect = props => {
  const { t, year, disabled, ...rest } = props;

  const vehicleMakesQuery = useQuery(['fetchCarMakes', year], {
    enabled: !!year,
    staleTime: Infinity,
    queryFn: () => CARS_API.fetchCarMakes(year),
  });

  const vehicleMakeOptions = useMemo(() => {
    if (Array.isArray(vehicleMakesQuery.data)) {
      return [
        ...vehicleMakesQuery.data.map(make => ({ label: make, value: make?.toUpperCase() })),
        { label: 'Other', value: 'other' },
      ];
    }

    return [];
  }, [vehicleMakesQuery.data]);

  return (
    <Select
      {...rest}
      showSearch
      disabled={disabled || !year || vehicleMakesQuery.isFetching || !vehicleMakeOptions?.length}
      loading={vehicleMakesQuery.isFetching}
      placeholder={t('selectMake')}
      options={vehicleMakeOptions}
    />
  );
};

export default VehicleMakeSelect;
