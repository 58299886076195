import classnames from 'classnames';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import storage from 'store';

import { PrivateRoute } from '../components';
import SpaceSpinner from '../components/SpaceSpinner';
import AuthContainer from '../containers/Auth';
import PageContainer from '../containers/PageContainer';
import { INTERNAL_LINKS } from '../enum';
import {
  hasBackOfficeUserRole,
  hasCompanyAdminRole,
  hasCompanyManagerOrAdminRole,
  hasSystemAdminRole,
  hasUserRole,
} from '../utils/roles';
import { selectStoreCompanySettings, selectStoreCurrentAuthUser } from '../utils/storeSelectors';
import SystemAdminViews from '../views-admin';
import BackOfficeViews from '../views-back-office';
import CompanyRealtorViews from '../views-realtor';
import CompanyAdminViews from './CompanyAdminViews';
import CompanyManagerViews from './CompanyManagerViews';
import DashboardPage from './dashboard';
import HomeRedirect from './HomeRedirect';
import UserViews from './UserViews';

const onBoardingAndConfigPages = [
  INTERNAL_LINKS.COMPANY_ONBOARDING,
  INTERNAL_LINKS.USER_ONBOARDING,
];

const MainApp = props => {
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const companySettings = useSelector(selectStoreCompanySettings);

  const realtorFeatures = useMemo(() => !!companySettings?.realtorFeatures, [companySettings]);
  const userRole = useMemo(() => get(authUser, 'profile.role'), [authUser]);

  const routes = useMemo(() => {
    const authUserProfile = { profile: { role: userRole } };

    let routesArray = [
      {
        path: INTERNAL_LINKS.DASHBOARD,
        component: DashboardPage,
      },
    ];

    if (hasSystemAdminRole(authUserProfile)) {
      routesArray = routesArray.concat(SystemAdminViews);
    }

    if (hasBackOfficeUserRole(authUserProfile)) {
      routesArray = routesArray.concat(BackOfficeViews);
    }

    if (hasCompanyAdminRole(authUserProfile)) {
      routesArray = routesArray.concat(CompanyAdminViews);
    }

    if (hasCompanyManagerOrAdminRole(authUserProfile)) {
      routesArray = routesArray.concat(CompanyManagerViews);
    }

    if (hasCompanyManagerOrAdminRole(authUserProfile) || hasUserRole(authUserProfile)) {
      routesArray = routesArray.concat(UserViews);
    }

    if (realtorFeatures) {
      routesArray = routesArray.concat(CompanyRealtorViews);
    }

    return routesArray.map(routeParams => <PrivateRoute key={routeParams.path} {...routeParams} />);
  }, [userRole, realtorFeatures]);

  return (
    <div
      className={classnames('main-app-container', {
        'auth-main-app-container': !onBoardingAndConfigPages.includes(props.location.pathname),
        'with-impersonation': !!storage.get('previousAccessToken'),
      })}
    >
      <React.Suspense
        fallback={
          <PageContainer>
            <SpaceSpinner />
          </PageContainer>
        }
      >
        <Switch>
          {routes}

          <PrivateRoute component={HomeRedirect} />
        </Switch>
      </React.Suspense>
    </div>
  );
};

export default withNamespaces()(AuthContainer(MainApp));
