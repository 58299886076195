import { get, last } from 'lodash';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import StripeAPI from '../../api/stripe';
import { getMinutesAsMilliseconds } from '../../utils/numbers';
import { selectStoreCurrentCompany } from '../../utils/storeSelectors';

const useInfiniteStripeCardsQuery = ({ enabled }) => {
  const currentCompany = useSelector(selectStoreCurrentCompany);

  return useInfiniteQuery({
    staleTime: getMinutesAsMilliseconds(10),
    enabled: enabled,
    queryKey: ['infinite', 'fetchCards', currentCompany._id],
    queryFn: ({ pageParam }) => new StripeAPI().fetchCards(pageParam),
    onError: error => handleApiErrors(error.response),
    getNextPageParam: lastPage =>
      get(lastPage, 'hasMore', false) ? get(last(lastPage.data), 'id') : undefined,
  });
};

export default useInfiniteStripeCardsQuery;
