import React, { useMemo } from 'react';

import { INTERNAL_LINKS } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { checkIfStringContainsValue, getUserFullName, momentFormat } from '../../../utils/common';
import { sortColumnByStatus } from '../../../utils/tables';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import CustomTable from '../../Table';
import StatusTag from '../../Tag/StatusTag';

const UserInRateTable = props => {
  const { t, dataSource, onSearch, ...rest } = props;

  const [searchTerm, setSearchTerm] = useDebouncedState();

  useDidUpdateEffect(() => {
    if (typeof onSearch === 'function') {
      onSearch(searchTerm);
    }
  }, [searchTerm]);

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(user => {
        return (
          checkIfStringContainsValue(getUserFullName(user), searchTerm) ||
          checkIfStringContainsValue(
            user.dateAddedToGroup ? momentFormat(user.dateAddedToGroup, 'YYYY-MM-DD') : '-',
            searchTerm,
          )
        );
      });
    }

    return array;
  }, [searchTerm, dataSource]);

  const COLUMNS = [
    {
      title: t('name'),
      key: 'name',
      render: (actions, data) => (
        <ProfileNamePicture
          t={t}
          showStatus={false}
          user={data}
          redirectTo={`${INTERNAL_LINKS.USER_DETAILS_PROFILE(data._id)}`}
        />
      ),
      sorter: (a, b) => {
        const aFullName = getUserFullName(a);
        const bFullName = getUserFullName(b);

        return aFullName.toLowerCase().localeCompare(bFullName.toLowerCase());
      },
    },
    {
      key: 'status',
      title: t('Status'),
      render: (actions, data) => <StatusTag status={data.status} />,
      ...sortColumnByStatus('status'),
    },
    {
      title: t('effectiveDate'),
      dataIndex: 'additionDate',
      render: additionDate => (additionDate ? momentFormat(additionDate, 'YYYY-MM-DD') : '-'),
    },
  ];

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      dataSource={filteredDataSource}
      onSearchTermChange={setSearchTerm}
    />
  );
};

export default UserInRateTable;
