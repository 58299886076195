import React from 'react';

import { Modal } from '../..';
import CountryRateAuditHistoryTable from '../../Table/CountryRateAuditHistoryTable';

const CountryRateAuditHistoryModal = props => {
  const { t, loading, dataSource, visible, onCancel } = props;

  const WINDOW_WIDTH = window.innerWidth - 50;
  const MODAL_WIDTH = WINDOW_WIDTH > 1100 ? 1100 : WINDOW_WIDTH;

  return (
    <Modal
      title={t('countryRateHistory')}
      width={MODAL_WIDTH}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <CountryRateAuditHistoryTable t={t} loading={loading} dataSource={dataSource} />
    </Modal>
  );
};

export default CountryRateAuditHistoryModal;
