import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { checkIfStringContainsValue, momentTimezone } from '../../../utils/common';
import {
  sortColumnByDateString,
  sortColumnByStatus,
  sortColumnByStringField,
} from '../../../utils/tables';
import { CustomTable } from '../..';
import StatusTag from '../../Tag/StatusTag';
import LinkText from '../../Text/LinkText';

const CompaniesTable = props => {
  const { t, dataSource, defaultSearchTerm, ...rest } = props;

  const [debouncedSearchTerm, setSearchTerm] = useDebouncedState(defaultSearchTerm);

  useDidUpdateEffect(() => {
    setSearchTerm(defaultSearchTerm);
  }, [defaultSearchTerm]);

  const filteredTable = useMemo(() => {
    let array = Array.isArray(dataSource) ? dataSource : [];

    if (debouncedSearchTerm) {
      array = dataSource.filter(company => {
        return (
          checkIfStringContainsValue(company.name, debouncedSearchTerm) ||
          checkIfStringContainsValue(company?.address?.country || '-', debouncedSearchTerm) ||
          checkIfStringContainsValue(
            momentTimezone(company.created, null).format('MM-DD-YYYY'),
            debouncedSearchTerm,
          )
        );
      });
    }

    return array;
  }, [debouncedSearchTerm, dataSource]);

  const COLUMNS = [
    {
      title: t('companyName'),
      ...sortColumnByStringField('name'),
      render: data => (
        <Link to={`${INTERNAL_LINKS.SELECT_COMPANY}/${data._id}`}>
          <LinkText variant="strong">{data.name || '-'}</LinkText>
        </Link>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      render: status => <StatusTag status={status} />,
      ...sortColumnByStatus('status'),
    },
    {
      title: t('createdDate'),
      dataIndex: 'created',
      render: createdDate => momentTimezone(createdDate, null).format('MM-DD-YYYY'),
      ...sortColumnByDateString('created'),
    },
    {
      title: t('country'),
      ...sortColumnByStringField('name'),
      render: data => data?.address?.country || '-',
    },
  ];

  return (
    <CustomTable
      {...rest}
      scroll={{ x: 900 }}
      columns={COLUMNS}
      rowKey={data => data._id}
      dataSource={filteredTable}
      onSearchTermChange={setSearchTerm}
      defaultSearchTerm={debouncedSearchTerm}
    />
  );
};

export default CompaniesTable;
