export const normalizeGlobalSettings = settings => {
  return {
    ...settings,
    allowedDomains: settings.allowedDomains.map(domain => ({
      _id: domain,
      name: domain,
    })),
    blockedDomains: settings.blockedDomains.map(domain => ({
      _id: domain,
      name: domain,
    })),
    timezones: settings.timezones.map(timezone => ({
      _id: timezone,
      name: timezone,
    })),
    mileageValues: settings.mileageValues.map(value => ({
      _id: value,
      name: value,
    })),
  };
};
