import { createReducer } from '../../utils/storeCreators';
import { CLEAR_PROFILE_ADD_DATA, UPDATE_PROFILE } from '../actions/profile';
import initialState from '../initialState';

const profileReducer = createReducer({
  initialState: initialState.profile,
  reducer: (state, action) => {
    let profile = state;
    switch (action.type) {
      case UPDATE_PROFILE:
        profile = action.data.profile;
        return { ...state, profile };
      case CLEAR_PROFILE_ADD_DATA: {
        profile.vehicles = undefined;
        return { ...state, ...profile };
      }
      default:
        return state;
    }
  },
});

export default profileReducer;
