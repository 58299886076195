import { Col, DatePicker, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { createUserDataDownloadRequest } from '../../../api/userDataActions';
import CompanyUsersLookupSelect from '../../../components/CompanyUsersLookupSelect';
import PageBreadcrumbs from '../../../components/PageBreadcrumbs';
import CustomRadio from '../../../components/Radio';
import SectionStepWrapper from '../../../components/SectionStepWrapper';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import DownloadUserDataUsersTable from '../../../components/Table/DownloadUserDataUsersTable';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../../enum';
import withAuthentication from '../../../hocs/withAuthentication';
import { formatPageTitle, momentFormat } from '../../../utils/common';
import { selectStoreCurrentCompany } from '../../../utils/storeSelectors';
import AnonymizeDataOptions from '../components/AnonymizeDataOptions';
import { validateDownloadDataRequest } from './download-user-data-permissions';

const DownloadUserDataPage = props => {
  const { t } = props;

  const currentCompany = useSelector(selectStoreCurrentCompany);

  const [isDownloadingData, setIsDownloadingData] = useState(false);

  const [trips, setTrips] = useState(false);
  const [receipts, setReceipts] = useState(false);
  const [reimbursements, setReimbursements] = useState(false);
  const [profileInfo, setProfileInfo] = useState(false);

  const [dateRange, setDateRange] = useState([]);

  const [selectUserMode, setSelectUserMode] = useState('all');
  const [usersOnTable, setUsersOnTable] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([].map(user => user._id));

  useEffect(() => {
    if (selectUserMode === 'all') {
      setSelectedUsers([].map(user => user._id));
    } else {
      setSelectedUsers([]);
    }
  }, [selectUserMode]);

  const handleDownloadDataRequest = async () => {
    const data = {
      trips,
      receipts,
      reimbursements,
      profile: profileInfo,
      entireDateRange: false,
      fromDate: dateRange[0] ? momentFormat(dateRange[0], 'YYYY-MM-DD') : undefined,
      toDate: dateRange[1] ? momentFormat(dateRange[1], 'YYYY-MM-DD') : undefined,
      userIds: selectedUsers,
    };

    setIsDownloadingData(true);

    if (validateDownloadDataRequest(data, t)) {
      try {
        await createUserDataDownloadRequest(data);
        props.history.push({
          pathname: INTERNAL_LINKS.GDPR,
          search: '?activeTab=status',
        });
        Toast({
          type: 'success',
          message: t('userDataExportBeingGenerated'),
        });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('downloadDataRequestError'),
          });
        });
      }
    }

    setIsDownloadingData(false);
  };

  return (
    <PageContainer
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('gdpr_ccpa'),
              onClick: () => props.history.push(INTERNAL_LINKS.GDPR),
            },
            { label: t('downloadUserData') },
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('Download User Data')}</title>
      </Helmet>

      <SectionStepWrapper
        step={1}
        showContent
        isCompleted={false}
        title={t('selectDataToDownload')}
      >
        <AnonymizeDataOptions
          t={t}
          isAdmin
          tripChecked={trips}
          reimbursementChecked={reimbursements}
          receiptsChecked={receipts}
          profileChecked={profileInfo}
          onTripsClick={setTrips}
          onReceiptClick={setReceipts}
          onReimbursmentClick={setReimbursements}
          onProfileClick={setProfileInfo}
          onSelectAllData={() => {
            setTrips(true);
            setReceipts(true);
            setReimbursements(true);
            setProfileInfo(true);
            setSelectUserMode('all');
            setDateRange([moment(currentCompany.created), moment()]);
          }}
        />
      </SectionStepWrapper>

      <SectionStepWrapper step={2} showContent isCompleted={false} title={t('selectDateRange')}>
        <DatePicker.RangePicker allowClear={false} value={dateRange} onChange={setDateRange} />
      </SectionStepWrapper>

      <SectionStepWrapper step={3} showContent isCompleted={false} title={t('selectUsers')}>
        <CustomRadio.Group value={selectUserMode} onChange={e => setSelectUserMode(e.target.value)}>
          <Row style={{ marginBottom: 15 }}>
            <CustomRadio value="all">{t('allUsersActiveInactivePending')}</CustomRadio>
          </Row>
          <Row>
            <Col flex={1}>
              <CustomRadio value="search">{t('searchAndSelectUsers')}</CustomRadio>

              {selectUserMode === 'search' && (
                <Row style={{ marginTop: 15 }}>
                  <Col flex={1}>
                    <Row justify="end">
                      <Col>
                        <CompanyUsersLookupSelect
                          t={t}
                          style={{ width: 250 }}
                          value={null}
                          placeholder={t('searchByFirstLastName')}
                          optionsFilter={user =>
                            !usersOnTable.map(user => user._id).includes(user._id)
                          }
                          onChange={(_, user) => {
                            setUsersOnTable(state => [...state, user]);
                            setSelectedUsers(state => [...state, user._id]);
                          }}
                        />
                      </Col>
                    </Row>

                    <DownloadUserDataUsersTable
                      t={t}
                      dataSource={usersOnTable}
                      rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedUsers,
                        onChange: selectedRowKeys => {
                          setSelectedUsers(selectedRowKeys);
                        },
                      }}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </CustomRadio.Group>
      </SectionStepWrapper>

      <SubmitCancelButtonGroup
        loading={isDownloadingData}
        onCancel={() => props.history.push(INTERNAL_LINKS.GDPR)}
        submitText={t('downloadData')}
        onSubmit={handleDownloadDataRequest}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(DownloadUserDataPage));
