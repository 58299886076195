import { Space } from 'antd';
import React from 'react';

import RadioCard from '../ReimbursementForm/RadioCard';
import Text from '../Text';

const SelectInsuranceCountry = props => {
  const { t, isUSAInsurance, onUsaCaClick, onOtherCountryClick } = props;

  return (
    <Space direction="vertical" size="middle" style={{ marginBottom: 30 }}>
      <Text variant="h5">{t('selectInsuranceCountry')}</Text>

      <RadioCard
        checked={isUSAInsurance && typeof isUSAInsurance === 'boolean'}
        title={t('insuranceFromUSA-CA')}
        onClick={onUsaCaClick}
      />
      <RadioCard
        checked={!isUSAInsurance && typeof isUSAInsurance === 'boolean'}
        title={t('insuranceNotFromUSA-CA')}
        onClick={onOtherCountryClick}
      />
    </Space>
  );
};

export default SelectInsuranceCountry;
