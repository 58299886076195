import React from 'react';
import PropTypes from 'prop-types';
// the hoc
import { withNamespaces } from 'react-i18next';
import './style.scss';

class ProgressHeader extends React.Component {

  render() {
    const { data } = this.props
    const getClassName = index => {
      const { current } = this.props
      const className = index === current
        ? 'current'
        : index > current ? 'last' : 'before'
      
      return `progress-header-item ${className} ${this.props.className}`
    }

    return (
      <div className="progress-header">
        {data.map((item, index) => {
          return (
            <span className={getClassName(index)} key={index}>
              {item}
            </span>
          )
        })}
      </div>
    )
  }
}

ProgressHeader.propTypes = {
  data: PropTypes.array,
  current: PropTypes.number,
  className: PropTypes.string,
}

ProgressHeader.defaultProps = {
  data: () => [],
  current: 0,
  className: '',
}

export default withNamespaces()(ProgressHeader);