import classnames from 'classnames';
import React from 'react';
import { Popup } from 'react-mapbox-gl';

import { IMAGES } from '../../enum';
import { formatCoordinatesToGoogleMapsURL } from '../../utils/map';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import LinkText from '../Text/LinkText';
import classNames from './styles.module.scss';

const ItineraryAddressMapPopup = props => {
  const { coordinates, address, time, t, isDeparture } = props;

  return (
    <Popup coordinates={coordinates} offset={[0, -35]} style={{ width: 220 }}>
      <div className={classnames([classNames.contentSection, classNames.noMargin])}>
        <div style={{ marginBottom: 5 }}>
          <Text size="xs" variant="strong">
            {t(isDeparture ? 'departBy' : 'arriveAt')}
          </Text>
        </div>

        <div className={classNames.rowWithIcon}>
          <img width="20px" src={IMAGES.TIME_CLOCK_CIRCLE_GRAY} alt="map" />
          <FadedText size="xs">{time}</FadedText>
        </div>

        <div className={classNames.rowWithIcon}>
          <img width="20px" src={IMAGES.MAP_PIN_ICON} alt="map" />
          <FadedText size="xs">{address}</FadedText>
        </div>

        <LinkText
          size="xs"
          renderAs="a"
          rel="noopener noreferrer"
          target="_blank"
          href={formatCoordinatesToGoogleMapsURL(coordinates[0], coordinates[1])}
        >
          {t('viewOnGoogleMaps')}
        </LinkText>
      </div>
    </Popup>
  );
};

export default ItineraryAddressMapPopup;
