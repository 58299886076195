import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class FAVRValuesAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'favr-values' });
  }
  /**
   * Allow company/system admin to apply adjusted rate values
   * @param {string} companyId
   * @param {string[]} usersIds
   */
  async applyAdjustedRateValues(companyId, usersIds) {
    const result = await handleApiCalls('post', `${this.baseUrl}/adjustment`, {
      companyId,
      usersIds,
    });

    return result.data;
  }

  /**
   * Request new rate for a given period anomaly
   * @param {string} periodAnomalyId
   */
  async requestNewRate(periodAnomalyId) {
    await handleApiCalls('post', `${this.baseUrl}/request`, { periodAnomalyId });
  }
}

export default FAVRValuesAPI;
