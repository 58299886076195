import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import { ERROR_MESSAGE } from '../../../utils/constants';
import Text from '../../Text';
import TextInput from '../../TextInput';

const TripPurposeExtendedForm = ({ t, form, isVisible, subList }) => {
  const [list, setList] = useState();

  const handleAddNewUserRow = () => {
    const name = `subList-${+new Date()}`;
    form.setFieldsValue({ [name]: undefined });
    setList([...list, { name }]);
  };

  const handleUserRowRemoval = inputName => {
    form.setFieldsValue({ [inputName]: undefined });

    setList([...list.filter(({ name }) => name !== inputName)]);
  };

  useEffect(() => {
    if (isVisible && Array.isArray(subList)) {
      const extendedValues = {};

      subList.forEach(({ name }) => {
        extendedValues[`subList-${name}`] = name;
      });

      form.setFieldsValue(extendedValues);
      setList(subList.map(({ name }) => ({ name: `subList-${name}`, value: name })));
    }

    // eslint-disable-next-line
  }, [isVisible]);

  if (!Array.isArray(list)) return null;

  return (
    <>
      <Text variant="strong" size="sm">
        {t('subList')}
      </Text>

      {list.map((item, i) => (
        <Row gutter={15}>
          <Col flex={1}>
            <Form.Item
              key={i}
              required
              name={item.name}
              rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <TextInput defaultValue={item.value} />
            </Form.Item>
          </Col>

          <Col>
            <div style={{ width: 14, marginTop: 7 }}>
              <DeleteFilled
                className="item-container-trash"
                onClick={() => handleUserRowRemoval(item.name)}
              />
            </div>
          </Col>
        </Row>
      ))}

      <Row justify="end" align="middle" style={{ marginBottom: 20 }}>
        <Col style={{ cursor: 'pointer' }} onClick={handleAddNewUserRow}>
          <Row gutter={5}>
            <Col>
              <PlusCircleOutlined />
            </Col>
            <Col>
              <span>Add item</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TripPurposeExtendedForm;
