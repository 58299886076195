import { Col, Row } from 'antd';
import React from 'react';

import { sortColumnByStringField } from '../../../utils/tables';
import { CustomTable } from '../..';
import SimpleEditPopoverForm from '../../Forms/SimpleEditPopoverForm';
import TripPurposeExtendedForm from '../../Forms/TripPurposeExtendedForm';
import LinkText from '../../Text/LinkText';

const TripPurposeTable = props => {
  const { t, dataSource, onEdit, onDelete, popoverTitle, isSubmitting, ...rest } = props;

  const COLUMNS = [
    {
      key: 'name',
      title: t('tripPurpose'),
      dataIndex: 'name',
      ...sortColumnByStringField('name'),
    },
    {
      width: 150,
      key: 'actions',
      title: t('actions'),
      render: (action, data) => (
        <Row gutter={16}>
          <Col>
            <SimpleEditPopoverForm
              t={t}
              inputName="name"
              title={popoverTitle}
              isSubmitting={isSubmitting}
              initialValues={{ name: data.name }}
              extendForm={<TripPurposeExtendedForm subList={data.subList} />}
              onSubmit={({ name, ...values }) =>
                onEdit({
                  ...values,
                  prevValue: data.name,
                  newValue: name,
                })
              }
            >
              <LinkText>{t('Edit')}</LinkText>
            </SimpleEditPopoverForm>
          </Col>
          <Col>
            <LinkText onClick={() => onDelete(data.name)}>{t('Delete')}</LinkText>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <CustomTable
      {...rest}
      rowKey={data => data.name}
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource}
    />
  );
};

export default TripPurposeTable;
