import React from 'react';

import { momentTimezone } from '../../../utils/common';
import { sortColumnByDateString, sortColumnByMomentTime } from '../../../utils/tables';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import Table from '..';

const CountryRateAuditHistoryTable = props => {
  const { t, dataSource, ...rest } = props;

  const TIME_FORMAT = 'hh:mm a';
  const DATE_FORMAT = 'MM-DD-YYYY';

  const COLUMNS = [
    {
      width: 120,
      key: 'created',
      title: t('date'),
      ...sortColumnByDateString('created'),
      render: (actions, data) => momentTimezone(data.created, null).format(DATE_FORMAT),
    },
    {
      key: 'time',
      title: t('time'),
      ...sortColumnByMomentTime('created', TIME_FORMAT),
      render: (actions, data) => momentTimezone(data.created, null).format(TIME_FORMAT),
    },
    {
      key: 'updatedField',
      title: t('updatedField'),
      render: (actions, data) => t(data.field),
    },
    {
      key: 'previousValue',
      title: t('previousValue'),
      render: (actions, data) => data.oldValue,
    },
    {
      key: 'updatedValue',
      title: t('updatedValue'),
      render: (actions, data) => data.newValue,
    },
    {
      key: 'user',
      title: t('User'),
      render: (actions, data) => <ProfileNamePicture user={data.modifiedBy} />,
    },
  ];

  return (
    <Table
      {...rest}
      rowKey={data => data._id}
      showSearchInput={false}
      columns={COLUMNS}
      dataSource={dataSource}
    />
  );
};

CountryRateAuditHistoryTable.defaultProps = {
  dataSource: [],
};

export default CountryRateAuditHistoryTable;
