import React, { useMemo } from 'react';

import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';

const UsersWithoutBankAccountsTable = props => {
  const { t, dataSource, ...rest } = props;

  const COLUMNS = useMemo(() => {
    return [
      {
        title: t('name'),
        dataIndex: 'user',
        render: user => (
          <ProfileNamePicture
            t={t}
            userLinkProps={{ target: '_blank' }}
            showStatus={false}
            showAvatar={false}
            user={user}
          />
        ),
      },
    ];
  }, [t]);

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

export default UsersWithoutBankAccountsTable;
