import { Badge, Col, Row } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';

import { INTERNAL_LINKS } from '../../enum';
import { getUserFullName, getUserInitials } from '../../utils/common';
import Avatar from '../Avatar';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import classNames from './style.module.scss';

const NOTIFICATION_TRANS_MAPPING = {
  approve_receipt: 'notificationTripSubmitted',
  anonymize_request: 'notificationAnonymizeRequest',
  more_info_request: 'notificationMoreInfoRequest',
  text: 'notificationTextRequest',
};

const NotificationItem = props => {
  const { t, notification, onNotificationRead, history } = props;

  const { variables, type } = notification;

  const handleNotificationCallback = () => {
    switch (type) {
      case 'anonymize_request':
        history.push({
          pathname: INTERNAL_LINKS.GDPR,
          search: '?activeTab=requests',
        });
        break;
      case 'approve_receipt':
        history.push(`${INTERNAL_LINKS.RECEIPTS}/${notification.entityId}`);
        break;
      case 'more_info_request':
        history.push(`${INTERNAL_LINKS.RECEIPTS}/${notification.entityId}`);
        break;
      // case 'test_request'
      default:
        break;
    }

    onNotificationRead(notification._id);
  };

  const NOTIF_DAY_DIFFERENCE = moment(notification.created).diff(moment(), 'days');

  return (
    <Row
      gutter={5}
      className={classNames.notificationItem}
      style={{ margin: 0 }}
      onClick={handleNotificationCallback}
    >
      <Col flex={1}>
        <Row align="middle" gutter={10}>
          <Col>
            <Avatar
              size="small"
              src={variables.profilePicture}
              initials={getUserInitials(variables)}
            />
          </Col>
          <Col flex={1}>
            <Text size="xs">
              {NOTIFICATION_TRANS_MAPPING[type] ? (
                <Trans
                  t={t}
                  i18nKey={NOTIFICATION_TRANS_MAPPING[type]}
                  values={{
                    ...notification,
                    ...notification.variables,
                    name: getUserFullName(variables),
                  }}
                  components={[
                    <Text variant="strong" size="xs">
                      DummyText
                    </Text>,
                  ]}
                />
              ) : (
                notification.message
              )}
            </Text>
          </Col>

          {!notification.read && (
            <Col>
              <Badge color="#16b296" />
            </Col>
          )}
        </Row>

        <Row>
          <Col style={{ width: 42 }} />
          <Col>
            <FadedText size="xs">
              {NOTIF_DAY_DIFFERENCE === 0 && t('today')}
              {NOTIF_DAY_DIFFERENCE === -1 && t('yesterday')}
              {NOTIF_DAY_DIFFERENCE < -1 &&
                t('xDaysAgo', { number: Math.abs(NOTIF_DAY_DIFFERENCE) })}
            </FadedText>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NotificationItem;
