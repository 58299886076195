import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { setCompanyPlan, setCompanyRegistrationStatus } from '../../../api/company';
import { PricePlansApi } from '../../../api/price-plans';
import PlanListSection from '../../../components/PlansListSection';
import Spinner from '../../../components/Spinner';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { INTERNAL_LINKS, USER_REG_STATUS } from '../../../enum';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { saveCompany } from '../../../stores/actions/common';
import { setRegStatus } from '../../../stores/actions/user';
import { formatPageTitle } from '../../../utils/common';

const SelectPlanTrial = props => {
  const { t, history } = props;
  const dispatch = useDispatch();
  const currentCompany = useSelector(state => state.common.currentCompany);
  const regStatus = useSelector(state => state.user.regStatus);

  const [plans, setPlans] = useState();
  const [isConfirming, setIsConfirming] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(currentCompany?.pricePlan);

  const loadPrices = async () => {
    try {
      const plans = await PricePlansApi.fetchPlanPrices(currentCompany._id);
      setPlans(plans);
    } catch (error) {
      Toast({
        type: 'error',
        description: t('fetchPlanPricesError'),
      });
    }
  };

  const handleConfirm = useCallback(async () => {
    if (!selectedPlan) {
      Toast({
        type: 'error',
        message: t('mustSelectPlan'),
      });
      return;
    }

    setIsConfirming(true);

    try {
      const updatedCompany = await setCompanyPlan(currentCompany._id, selectedPlan);

      await dispatch(
        saveCompany({
          pricePlan: updatedCompany.pricePlan,
          updated: updatedCompany.updated,
        }),
      );

      if (regStatus < USER_REG_STATUS.COMPANY || !regStatus) {
        try {
          await setCompanyRegistrationStatus(currentCompany._id, USER_REG_STATUS.COMPANY);
          await dispatch(setRegStatus(USER_REG_STATUS.COMPANY));
        } catch (error) {
          handleApiErrors(error.response, () => {
            Toast({
              type: 'error',
              message: t('setCompanyRegistrationStatusError'),
            });
          });
        }
      }

      Toast({
        type: 'success',
        message: t('setCompanyPlanSuccess'),
      });
      history.push(INTERNAL_LINKS.COMPANY_ONBOARDING);
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('setCompanyPlanTrialError'),
        });
      });
    }

    setIsConfirming(false);
    // eslint-disable-next-line
  }, [dispatch, history, selectedPlan, currentCompany._id]);

  useEffect(() => {
    loadPrices();
    // eslint-disable-next-line
  }, []);

  useDidUpdateEffect(() => {
    if (selectedPlan) {
      handleConfirm();
    }
  }, [selectedPlan, handleConfirm]);

  const PAGE_TITLE = (
    <Helmet>
      <title>{formatPageTitle('Select Your Plan | Onboarding')}</title>
    </Helmet>
  );

  if (!plans) {
    return (
      <Row flex={1} justify="center" align="middle" style={{ minHeight: 300 }}>
        {PAGE_TITLE}

        <Col>
          <Spinner />
        </Col>
      </Row>
    );
  }

  return (
    <PageContainer title={t('selectYourPlan')}>
      {PAGE_TITLE}

      <Row justify="center">
        <Col>
          <PlanListSection
            disabled={isConfirming}
            selectedPlan={selectedPlan}
            plans={plans}
            onCardClick={setSelectedPlan}
          />

          <div>
            <SubmitCancelButtonGroup
              hideSubmit
              hideCancel={!currentCompany?.pricePlan}
              cancelText={t('Back')}
              onCancel={() => history.push(INTERNAL_LINKS.COMPANY_ONBOARDING)}
              loading={isConfirming}
              submitText={t('Confirm')}
              onSubmit={handleConfirm}
            />
          </div>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(SelectPlanTrial);
