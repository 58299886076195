import { Col, Row } from 'antd';
import { get, merge } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { DWOLLA_RESOURCES_API } from '../../api/dwolla-resources';
import { HelpIcon } from '../../components';
import DownloadIcon from '../../components/DownloadIcon';
import UsersWithoutBankAccountsModal from '../../components/Modal/UsersWithoutBankAccountsModal';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import AccountReimbursementHistoryByUserTable from '../../components/Table/AccountReimbursementHistoryByUserTable';
import Text from '../../components/Text';
import UnpaidDueToInvalidACHInfoSummary from '../../components/UnpaidDueToInvalidACHInfoSummary';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import useRetryMassTransactionMutation from '../../hooks/mutations/useRetryMassTransactionMutation';
import usePaginatedDwollaMassPaymentDetails from '../../hooks/queries/usePaginatedDwollaMassPaymentDetails';
import useModal from '../../hooks/useModal';
import { formatPageTitle } from '../../utils/common';
import { formatCurrencyToCompanyLocale } from '../../utils/numbers';
import { selectStoreCompanySettings, useStoreSelector } from '../../utils/storeSelectors';
import { formatPaymentPeriodLabel } from '../../utils/trip-receipts';

const DwollaMassPaymentDetailsView = props => {
  const { t, history, match } = props;

  const companySettings = useStoreSelector(selectStoreCompanySettings);

  const [massPaymentDetails, setMassPaymentDetails] = useState({});

  const [
    isUsersWithoutBankModalVisible,
    openUsersWithoutBankModal,
    closeUsersWithoutBankModal,
    usersWithoutBankModalProps,
  ] = useModal();

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    query: massPaymentDetailsQuery,
  } = usePaginatedDwollaMassPaymentDetails(match.params.id);

  const retryTransactionMutation = useRetryMassTransactionMutation(t, {
    onSuccess: () => massPaymentDetailsQuery.refetch(),
  });

  const downloadReimbursementTransactions = useMutation(
    () =>
      DWOLLA_RESOURCES_API.exportDwollaMassPaymentItems(
        match.params.id,
        get(massPaymentDetails, 'period'),
      ),
    { onError: error => handleApiErrors(error.response) },
  );

  useEffect(() => {
    if (massPaymentDetailsQuery.data && !massPaymentDetails?.period?.paymentPeriodStart) {
      setMassPaymentDetails({
        period: merge(
          { scheduleType: get(massPaymentDetailsQuery.data, 'scheduleType') },
          get(massPaymentDetailsQuery.data, 'period'),
        ),
        totalPaidAmount: get(massPaymentDetailsQuery.data, 'totalPaidAmount', 0),
        totalApprovedAmount: get(massPaymentDetailsQuery.data, 'totalApprovedAmount', 0),
      });
    }
  }, [massPaymentDetailsQuery.data, massPaymentDetails]);

  return (
    <PageContainer
      loading={massPaymentDetailsQuery.isFetching}
      title={
        <PageBreadcrumbs
          items={[
            {
              label: t('kliksAccountBalance'),
              onClick: () => history.push(INTERNAL_LINKS.ACCOUNT_BALANCE),
            },
            {
              label: t('reimbursementTransactionsByUser'),
            },
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('Mass Payment Details')}</title>
      </Helmet>

      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col>
          <Text>
            <Text variant="b">{t('reimbursementPeriod')}:</Text>{' '}
            {massPaymentDetails.period
              ? formatPaymentPeriodLabel(
                  massPaymentDetails.period,
                  'paymentPeriodStart',
                  'paymentPeriodEnd',
                  'MMMM DD',
                  'MMMM DD',
                )
              : ''}
          </Text>

          <Row gutter={[8, 8]} align="middle" wrap={false}>
            <Col>
              <Text>
                <Text variant="b">{t('Total')}:</Text>{' '}
                {formatCurrencyToCompanyLocale(massPaymentDetails.totalPaidAmount || 0)}
              </Text>
            </Col>
            {typeof massPaymentDetails.totalPaidAmount === 'number' &&
              typeof massPaymentDetails.totalApprovedAmount === 'number' &&
              massPaymentDetails.totalPaidAmount < massPaymentDetails.totalApprovedAmount && (
                <Col>
                  <HelpIcon
                    placement="top"
                    hint={
                      <UnpaidDueToInvalidACHInfoSummary
                        t={t}
                        massPaymentId={match.params.id}
                        totalApprovedAmount={massPaymentDetails.totalApprovedAmount}
                        totalPaidAmount={massPaymentDetails.totalPaidAmount}
                        onTotalPaidInfoClick={openUsersWithoutBankModal}
                      />
                    }
                  />
                </Col>
              )}
          </Row>
        </Col>

        {!massPaymentDetailsQuery.isFetching && (
          <Col>
            <DownloadIcon
              t={t}
              loading={downloadReimbursementTransactions.isLoading}
              onClick={downloadReimbursementTransactions.mutateAsync}
            />
          </Col>
        )}
      </Row>

      <br />

      <AccountReimbursementHistoryByUserTable
        t={t}
        loading={massPaymentDetailsQuery.isFetching}
        onReimbursementClick={(id, tripReceiptId) => {
          if (companySettings?.hideReceipts) {
            history.push(`${INTERNAL_LINKS.REIMBURSEMENT}/${tripReceiptId}`);
          } else {
            history.push(`${INTERNAL_LINKS.REIMBURSEMENT}/${id}`);
          }
        }}
        dataSource={massPaymentDetailsQuery.data.documents}
        onChange={({ current }) => handlePageChange(current)}
        onRetry={retryTransactionMutation.mutateAsync}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />

      <UsersWithoutBankAccountsModal
        t={t}
        visible={isUsersWithoutBankModalVisible}
        onCancel={closeUsersWithoutBankModal}
        {...usersWithoutBankModalProps}
      />
    </PageContainer>
  );
};

export default withNamespaces()(DwollaMassPaymentDetailsView);
