import { handleApiCalls } from './axiosInstance';

export const PricePlansApi = {
  fetchPlanPrices: async companyID => {
    const url = `${process.env.REACT_APP_HOST_API}company/${companyID}/price-plans?locale=en-US`;
    const response = await handleApiCalls('get', url);

    return response.data;
  },
};
