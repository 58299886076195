import { ACTIONS_MAP } from 'enum';
import { findIndex, get } from 'lodash';

import { createReducer } from '../../utils/storeCreators';
import {
  ADD_DWOLLA_BENEFICIAL_OWNER_DOCUMENT,
  ADD_DWOLLA_CONTROLLER_DOCUMENT,
  SAVE_EMAIL_CONFIRM,
  SAVE_PHONE_PIN,
  SET_ACCOUNT_CREATE_STEP,
  SET_CURRENT_COMPANY,
  SET_EMAIL_CONFIRMED,
  SET_VISITED_SETUP,
  UNSET_CURRENT_COMPANY,
  UPDATE_BASENAME,
  UPDATE_COMPANY_LIST,
  UPDATE_COMPANY_PLAN,
  UPDATE_COMPANY_SETTINGS,
  UPDATE_COUNTRY_RATE,
  UPDATE_SUPPORTED_COUNTRIES,
  UPDATE_TIMEZONES,
} from '../actions/common';
import initialState from '../initialState';

const commonReducer = createReducer({
  initialState: initialState.common,
  reducer: (state, action, defaultState) => {
    let common = state;
    switch (action.type) {
      case UPDATE_SUPPORTED_COUNTRIES:
        return { ...state, ...action.data };
      case ACTIONS_MAP.SET_HEADER_TITLE:
        common.header = action.data;
        return { ...state, ...common };
      case UPDATE_COMPANY_LIST:
        common.companyData = {
          ...state.companyData,
          companyList: action.data.companyList,
          fetchFlag: true,
        };
        return { ...state, ...common };
      case UPDATE_COMPANY_PLAN:
        common.companyData = {
          ...state.companyData,
          companyPlan: action.data.companyPlan,
        };
        return { ...state, ...common };
      case UPDATE_COMPANY_SETTINGS:
        common.currentCompany.companySettingId = action.data.settings;
        return { ...state, ...common };
      case SET_ACCOUNT_CREATE_STEP:
        common.signupSubStep = action.data;
        return { ...state, ...common };
      case SAVE_PHONE_PIN:
        common.phoneForPin = action.data;
        return { ...state, ...common };
      case SAVE_EMAIL_CONFIRM:
        common.emailForConfirm = action.data;
        return { ...state, ...common };
      case SET_EMAIL_CONFIRMED:
        common.emailConfirmed = action.data;
        return { ...state, ...common };
      case SET_VISITED_SETUP:
        common.visitedSetupPage = action.data;
        return { ...state, ...common };
      case SET_CURRENT_COMPANY:
        common.currentCompany = {
          ...common.currentCompany,
          ...action.data,
        };
        return { ...state, ...common };
      case UNSET_CURRENT_COMPANY: {
        common.currentCompany = defaultState.currentCompany;
        common.companyData = defaultState.companyData;
        return { ...state, ...common };
      }
      case UPDATE_TIMEZONES: {
        common.timezones = action.data.timezones;
        return { ...state, ...common };
      }
      case UPDATE_COUNTRY_RATE: {
        common.countryRates = [...state.countryRates, action.data.countryRate];
        return { ...state, ...common };
      }
      case ADD_DWOLLA_CONTROLLER_DOCUMENT: {
        const dwollaPaymentInfo = get(common, 'currentCompany.dwollaPaymentInfo');
        const controllerInfo = get(dwollaPaymentInfo, 'controllerInfo');
        const documents = get(controllerInfo, 'documents', []);
        controllerInfo.documents = [...documents, action.data.document];
        dwollaPaymentInfo.controllerInfo = controllerInfo;

        common.currentCompany = { ...common.currentCompany, dwollaPaymentInfo };
        return { ...state, ...common };
      }
      case ADD_DWOLLA_BENEFICIAL_OWNER_DOCUMENT: {
        const dwollaPaymentInfo = get(common, 'currentCompany.dwollaPaymentInfo');
        const beneficialOwnerList = get(dwollaPaymentInfo, 'beneficialOwners');
        const beneficialOwnerIndex = findIndex(beneficialOwnerList, {
          beneficialOwnerId: action.data.beneficialOwnerId,
        });
        const beneficialOwner = beneficialOwnerList[beneficialOwnerIndex];
        const documents = get(beneficialOwner, 'documents', []);
        beneficialOwner.documents = [...documents, action.data.document];
        beneficialOwnerList.splice(beneficialOwnerIndex, 1, beneficialOwner);
        dwollaPaymentInfo.beneficialOwners = beneficialOwnerList;

        common.currentCompany = { ...common.currentCompany, dwollaPaymentInfo };
        return { ...state, ...common };
      }
      case UPDATE_BASENAME:
        common.basename = action.data.basename;
        return { ...state, ...common };
      default:
        return state;
    }
  },
});

export default commonReducer;
