import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

export const COUNTRY_RATES_API = {
  fetchCountryRates: async (params = {}, pageNumber, pageSize) => {
    const range = formatQueryRange(pageNumber, pageSize);
    const queryParams = cleanQueryParams({ ...params, range });
    const url = `${process.env.REACT_APP_HOST_API}country-rates?${queryParams}`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },
  /**
   * @param {string} rateId
   */
  fetchCountryRateDetails: async rateId => {
    const url = `${process.env.REACT_APP_HOST_API}country-rates/${rateId}`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },
  /**
   * @param {object} params
   */
  createCountryRate: async (params = {}) => {
    const url = `${process.env.REACT_APP_HOST_API}country-rates`;
    const response = await handleApiCalls('post', url, params);
    return response.data;
  },
  /**
   * @param {string} rateId
   * @param {object} params
   */
  updateCountryRate: async (rateId, params = {}) => {
    const url = `${process.env.REACT_APP_HOST_API}country-rates/${rateId}`;
    const response = await handleApiCalls('put', url, params);
    return response.data;
  },
  /**
   * @param {string} rateId
   */
  deleteCountryRate: async rateId => {
    const url = `${process.env.REACT_APP_HOST_API}country-rates/${rateId}`;
    return handleApiCalls('delete', url);
  },
};
