import { Col, Row } from 'antd';
import { get, merge } from 'lodash';
import React, { useCallback, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { COMPANY_API } from '../../../api/company';
import DownloadIcon from '../../../components/DownloadIcon';
import PageFiltersRenderer from '../../../components/shared-ui/PageFiltersRenderer';
import ComplianceStatusTable from '../../../components/Table/ComplianceStatusTable';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { STATUS_LIST } from '../../../enum';
import { FAVR_COMPLIANCE_STATUS_OPTIONS } from '../../../enum/FavrCompliances';
import withAuthentication from '../../../hocs/withAuthentication';
import useCompanyFavrComplianceUsersQuery from '../../../hooks/queries/useCompanyFavrComplianceUsersQuery';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useLocationSearchQueryParser from '../../../hooks/useLocationSearchQueryParser';
import useTableSort from '../../../hooks/useTableSort';
import { getMinutesAsMilliseconds } from '../../../utils/numbers';
import { replaceCurrentPageSearchQueryParams } from '../../../utils/queryParams';
import { selectStoreCurrentCompany } from '../../../utils/storeSelectors';

const COMMON_RESPONSE_FILTER_SETTINGS = {
  xs: 24,
  md: 12,
  lg: 4,
};

const ComplianceStatusView = props => {
  const { t, history, location } = props;
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const initialQueryParams = useLocationSearchQueryParser(location);

  const [filters, setFilters] = useState({
    complianceType: initialQueryParams.complianceType || null,
    searchTerm: initialQueryParams.searchTerm || '',
    status: initialQueryParams.status || null,
    userId: initialQueryParams.userId || null,
    userStatus: initialQueryParams.userStatus || null,
  });

  const usersQuery = useCompanyFavrComplianceUsersQuery(t);
  const { stringTableSort, handleTableSort } = useTableSort({ firstName: 1, lastName: 1 });

  const {
    query: complianceUserDataQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      placeholderData: { count: 0, documents: [] },
      staleTime: getMinutesAsMilliseconds(10),
      queryKey: [
        'getAllUsersFavrComplianceData',
        currentCompany._id,
        ...Object.values(filters),
        stringTableSort,
      ],
      queryFn: () =>
        COMPANY_API.getAllUsersFavrComplianceData(
          currentCompany._id,
          filters,
          paginationConfig.current,
          paginationConfig.pageSize,
          stringTableSort,
        ),
      onError: error =>
        handleApiErrors(error.response, () => {
          return Toast({
            type: 'error',
            message: t('getAllUsersFavrComplianceDataError'),
          });
        }),
    },
    {
      resetPageQueryKey: Object.values(filters),
      useQueryParams: true,
      initialPage: initialQueryParams?.page,
      initialPageSize: initialQueryParams?.pageSize,
    },
    'totalCount',
  );

  const downloadComplianceDataMutation = useMutation(
    () =>
      COMPANY_API.exportAllUsersFavrComplianceData(
        currentCompany._id,
        filters,
        paginationConfig.current,
        paginationConfig.pageSize,
        stringTableSort,
      ),
    {
      onError: error =>
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('exportAllUsersFavrComplianceDataError'),
          });
        }),
    },
  );

  const handleFiltersChange = useCallback(
    updatedFilters => {
      setFilters(filter => {
        const newFilters = merge({}, filter, updatedFilters);
        replaceCurrentPageSearchQueryParams(history, newFilters);
        return newFilters;
      });
    },
    [history],
  );

  return (
    <PageContainer title={t('userProgramCompliance')}>
      <Row gutter={16} align="middle" justify="space-between" wrap={false}>
        <Col flex={1}>
          <PageFiltersRenderer
            t={t}
            loading={usersQuery.isFetching || complianceUserDataQuery.isFetching}
            onFiltersChange={handleFiltersChange}
            search={{ defaultValue: filters.searchTerm }}
            filters={[
              {
                componentType: 'select',
                label: t('user'),
                name: 'userId',
                defaultValue: filters.userId,
                options: [{ label: t('All'), value: null }, ...usersQuery.data],
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              },
              {
                componentType: 'select',
                label: t('userStatus'),
                name: 'userStatus',
                defaultValue: filters.userStatus,
                options: [
                  { label: t('All'), value: null },
                  { label: t('Active'), value: STATUS_LIST().Status.ACTIVE },
                  { label: t('Inactive'), value: STATUS_LIST().Status.INACTIVE },
                  { label: t('deleted'), value: STATUS_LIST().Status.DELETED },
                ],
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              },
              {
                componentType: 'select',
                label: t('complianceType'),
                name: 'complianceType',
                defaultValue: filters.complianceType,
                options: [
                  { label: t('All'), value: null },
                  { label: t('insurance'), value: 'insurance' },
                  { label: t('businessVehicle'), value: 'businessVehicle' },
                  { label: t('businessMileage'), value: 'businessMileage' },
                ],
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              },
              {
                componentType: 'select',
                label: t('complianceStatus'),
                name: 'status',
                defaultValue: filters.status,
                options: [{ label: t('All'), value: null }, ...FAVR_COMPLIANCE_STATUS_OPTIONS()],
                responsive: COMMON_RESPONSE_FILTER_SETTINGS,
              },
            ]}
          />
        </Col>

        <DownloadIcon
          t={t}
          colWidth="65px"
          text={t('exportToCSV')}
          loading={downloadComplianceDataMutation.isLoading}
          onClick={downloadComplianceDataMutation.mutateAsync}
          disabled={!paginationConfig.total || paginationConfig.total === 0}
        />
      </Row>

      <ComplianceStatusTable
        t={t}
        asyncSort
        loading={complianceUserDataQuery.isFetching}
        dataSource={get(complianceUserDataQuery.data, 'documents')}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
          handleTableSort(sorters?.columnKey, sorters?.order);
        }}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(ComplianceStatusView));
