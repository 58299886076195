import { Col, Row } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../api/axiosInstance';
import { COUNTRY_RATES_API } from '../../api/countryRates';
import Box from '../../components/Box';
import CountryRateForm from '../../components/Forms/CountryRateForm';
import PageBreadcrumbs from '../../components/PageBreadcrumbs';
import Toast from '../../components/Toast';
import PageContainer from '../../containers/PageContainer';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
// import { emitFetchCountryRates } from '../../stores/actions/systemAdmin';
import { formatPageTitle } from '../../utils/common';

const CreateCountryRateView = props => {
  const { t, history } = props;

  const queryClient = useQueryClient();
  const { supportedCountries } = useSelector(store => store.common);

  const createCountryRateMutation = useMutation(COUNTRY_RATES_API.createCountryRate, {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchCountryRates'], { exact: false });
      Toast({
        type: 'open',
        message: t('countryRateCreateSuccess'),
      });
      // dispatch(emitFetchCountryRates());
      history.push(INTERNAL_LINKS.COUNTRY_RATES);
    },
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('countryRateCreateError'),
        });
      });
    },
  });

  return (
    <PageContainer
      loading={!Array.isArray(supportedCountries)}
      title={
        <PageBreadcrumbs
          items={[
            { label: t('countryRates'), onClick: () => history.push(INTERNAL_LINKS.COUNTRY_RATES) },
            { label: t('createNewRate') },
          ]}
        />
      }
    >
      <Helmet>
        <title>{formatPageTitle('Create Country Rate')}</title>
      </Helmet>

      <Row justify="center">
        <Col flex="832px">
          <Box>
            <CountryRateForm
              t={t}
              isSubmitting={createCountryRateMutation.isLoading}
              countries={supportedCountries}
              onSubmit={createCountryRateMutation.mutateAsync}
              onCancel={() => history.push(INTERNAL_LINKS.COUNTRY_RATES)}
            />
          </Box>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(CreateCountryRateView));
