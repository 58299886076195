import { Col, Radio, Row } from 'antd';
import React from 'react';

import { CustomRadio } from '../../../components';
import {
  AD_HOC_SCHEDULE_TYPE,
  CUSTOM_SCHEDULE_TYPE,
  MONTHLY_SCHEDULE_TYPE,
  WEEKLY_SCHEDULE_TYPE,
} from '../../../enum/ScheduleTypes';
import classNames from './style.module.scss';

const SelectPaymentSchedule = props => {
  const { disabled, paymentFrequency, onFrequencyChange, tripApprovalWorkflow } = props;

  return (
    <Radio.Group
      disabled={disabled}
      name="paymentFrequency"
      value={paymentFrequency}
      className={classNames.kliksRowGroup}
    >
      <Row className={classNames.kliksRow} onClick={() => onFrequencyChange(AD_HOC_SCHEDULE_TYPE)}>
        <Col>
          <CustomRadio value={AD_HOC_SCHEDULE_TYPE}>
            <p>Ad Hoc Payment Schedule</p>
            <p>(Drivers can submit anytime and Admins can approve and pay anytime)</p>
          </CustomRadio>
        </Col>
      </Row>

      {!!tripApprovalWorkflow && (
        <>
          <Row
            className={classNames.kliksRow}
            onClick={() => onFrequencyChange(MONTHLY_SCHEDULE_TYPE)}
          >
            <Col>
              <CustomRadio value={MONTHLY_SCHEDULE_TYPE}>
                <p>Monthly Payment Schedule</p>
                <p>
                  (Next, you will select Day of Month for Payments and associated Trip Submission
                  and Approval timelines)
                </p>
              </CustomRadio>
            </Col>
          </Row>
          <Row
            className={classNames.kliksRow}
            onClick={() => onFrequencyChange(WEEKLY_SCHEDULE_TYPE)}
          >
            <Col>
              <CustomRadio value={WEEKLY_SCHEDULE_TYPE}>
                <p>Weekly Payment Schedule</p>
                <p>
                  (Next, you will select Day of Week for Payments and associated Trip Submission and
                  Approval timelines)
                </p>
              </CustomRadio>
            </Col>
          </Row>
          <Row
            className={classNames.kliksRow}
            onClick={() => onFrequencyChange(CUSTOM_SCHEDULE_TYPE)}
          >
            <Col>
              <CustomRadio value={CUSTOM_SCHEDULE_TYPE}>
                <p>Custom Payment Recurrence Schedule</p>
                <p>
                  (Next, you will select a day of week and the number of days for the recurrence to
                  occur)
                </p>
              </CustomRadio>
            </Col>
          </Row>
        </>
      )}
    </Radio.Group>
  );
};

export default SelectPaymentSchedule;
