import React from 'react';
import { Link } from 'react-router-dom';

import { IMAGES, INTERNAL_LINKS } from '../../../../enum';

import RowButton from '../../../../components/RowButton';
import Text from '../../../../components/Text';

const UserInformationTab = props => {
  const { t } = props;
  return (
    <div>
      <Link to={INTERNAL_LINKS.DOWNLOAD_USER_DATA}>
        <RowButton
          title={t('downloadUserData')}
          icon={IMAGES.TEXT_FILE_DOWNLOAD_ICON}
          content={
            <>
              <Text size="sm">{t('downloadUserData')}</Text>
              <Text size="sm">{t('selectUserDataToDownload')}</Text>
            </>
          }
        />
      </Link>

      <Link to={INTERNAL_LINKS.ANONYMIZE_USER_DATA}>
        <RowButton
          title={t('anonymizeUserData')}
          icon={IMAGES.TEXT_FILE_REMOVE_ICON}
          content={
            <>
              <Text size="sm">{t('anonymizeUserData')}</Text>
              <Text size="sm">{t('selectUserDataToAnonymize')}</Text>
            </>
          }
        />
      </Link>

      <br />
    </div>
  );
};

export default UserInformationTab;
