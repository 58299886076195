import { DeleteFilled, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Row } from 'antd';
import { find, get, mapKeys } from 'lodash';
import React, { useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { STATUS_LIST } from '../../../enum';
import { UserRoleSelectOptions } from '../../../enum/UserRoles';
import { momentEST } from '../../../utils/common';
import {
  EMAIL_VALIDATION_FORM_RULES,
  EMPLOYEE_NUMBER_FORM_RULES,
  ERROR_MESSAGE,
  NAME_MAX_CHARACTER,
} from '../../../utils/constants';
import { selectStoreCountries } from '../../../utils/storeSelectors';
import { USER_NAME_VALIDATION_REGEXP } from '../../../utils/validate';
import { USER_PERMISSIONS } from '../../../views/userdetails/userdetails-permissions';
import { Button } from '../..';
import AddressForm from '../../AddressForm';
import Checkbox from '../../Checkbox';
import HorizontalLineDivider from '../../HorizontalLineDivider';
import Select from '../../Select';
import TextInput from '../../TextInput';
import FormItem from '../FormItem';
import classNames from './style.module.scss';

const InviteMultipleUsersForm = props => {
  const {
    t,
    isOnboarding,
    optionalInvite,
    onFinish,
    onCancel,
    existingUsers,
    defaultValues,
    requireEmployeeID,
    requireGroupID,
    companyAddress,
  } = props;

  const supportedCountries = useSelector(selectStoreCountries);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const groupList = useSelector(store => (store.group ? store.group.groupList : []));

  const [enabledAddressForUser, setEnabledAddressForUser] = useState({});

  const DEFAULT_EMPTY_USER = useMemo(
    () => ({
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      status: STATUS_LIST().Status.NEW,
      groupId: get(find(groupList, { defaultGroup: true }), '_id'),
      sendActivationEmailImmediately: true,
      sendActivationEmailDate: null,
    }),
    [groupList],
  );

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(
    Array.isArray(defaultValues) && !!defaultValues.length ? defaultValues : [DEFAULT_EMPTY_USER],
  );

  const handleAddNewUserRow = () => {
    setFormValues(currentValues => {
      const updatedState = [...currentValues, DEFAULT_EMPTY_USER];
      form.setFieldsValue(
        mapKeys(DEFAULT_EMPTY_USER, (_, key) => `${updatedState.length - 1}_${key}`),
      );
      return updatedState;
    });
  };

  const handleUserRowRemoval = () => {
    const updatedUserRows = formValues;
    const newIndex = updatedUserRows.length - 1;

    form.setFieldsValue({
      [`${newIndex}_firstName`]: undefined,
      [`${newIndex}_lastName`]: undefined,
      [`${newIndex}_email`]: undefined,
      [`${newIndex}_role`]: undefined,
      [`${newIndex}_department`]: undefined,
      [`${newIndex}_streetOne`]: undefined,
      [`${newIndex}_streetTwo`]: undefined,
      [`${newIndex}_country`]: undefined,
      [`${newIndex}_state`]: undefined,
      [`${newIndex}_city`]: undefined,
      [`${newIndex}_postalCode`]: undefined,
      [`${newIndex}_sendActivationEmailImmediately`]: true,
      [`${newIndex}_sendActivationEmailDate`]: null,
    });

    updatedUserRows.length -= 1;
    setFormValues([...updatedUserRows]);
  };

  const handleFieldChange = event => {
    const updatedUserRows = formValues;
    const [rowIndex, fieldName] = event.target.id.split('_');

    updatedUserRows[rowIndex] = {
      ...updatedUserRows[rowIndex],
      [fieldName]: event.target.value,
    };

    setFormValues([...updatedUserRows]);
    form.setFieldValue(event.target.id, event.target.value);
  };

  const handleSubmit = async () => {
    let isFormValid = true;

    formValues.forEach(({ firstName, lastName, email, role, employeeId, groupId }, i) => {
      if (!firstName) {
        isFormValid = false;
        form.setFields([
          {
            name: `${i}_firstName`,
            value: firstName,
            errors: [ERROR_MESSAGE().BLANK_FIELD],
          },
        ]);
      } else {
        // Clear form error
        form.setFields([
          {
            name: `${i}_firstName`,
            value: firstName,
            errors: [],
          },
        ]);
      }

      if (!lastName) {
        isFormValid = false;
        form.setFields([
          {
            name: `${i}_lastName`,
            value: lastName,
            errors: [ERROR_MESSAGE().BLANK_FIELD],
          },
        ]);
      } else {
        // Clear form error
        form.setFields([
          {
            name: `${i}_lastName`,
            value: lastName,
            errors: [],
          },
        ]);
      }

      if (requireGroupID) {
        if (!groupId) {
          isFormValid = false;
          form.setFields([
            {
              name: `${i}_groupId`,
              value: groupId,
              errors: [ERROR_MESSAGE().BLANK_FIELD],
            },
          ]);
        } else {
          form.setFields([
            {
              name: `${i}_groupId`,
              value: groupId,
              errors: [],
            },
          ]);
        }
      }

      if (requireEmployeeID) {
        if (!employeeId) {
          isFormValid = false;
          form.setFields([
            {
              name: `${i}_employeeId`,
              value: employeeId,
              errors: [ERROR_MESSAGE().BLANK_FIELD],
            },
          ]);
        } else {
          form.setFields([
            {
              name: `${i}_employeeId`,
              value: employeeId,
              errors: [],
            },
          ]);
        }
      }

      if (email) {
        const isAlreadyPartOfInvited =
          formValues.filter(values => values.email === email.trim()).length > 1;

        const isAlreadyPartOfCompany = Array.isArray(existingUsers)
          ? existingUsers.filter(u => u.email === email.trim()).length >= 1
          : false;

        if (isAlreadyPartOfCompany || isAlreadyPartOfInvited) {
          isFormValid = false;
        }

        form.setFields([
          {
            name: `${i}_email`,
            value: email,
            ...(isAlreadyPartOfCompany ? { errors: [t('emailAlreadyUsed')] } : {}),
            ...(isAlreadyPartOfInvited ? { errors: [t('emailAlreadyUsedByInvited')] } : {}),
            // Clear error
            ...(!isAlreadyPartOfCompany && !isAlreadyPartOfInvited ? { errors: [] } : {}),
          },
        ]);
      } else {
        isFormValid = false;
        form.setFields([
          {
            name: `${i}_email`,
            value: email,
            errors: [ERROR_MESSAGE().BLANK_FIELD],
          },
        ]);
      }

      if (!role) {
        isFormValid = false;
        form.setFields([
          {
            name: `${i}_role`,
            value: role,
            errors: [ERROR_MESSAGE().BLANK_FIELD],
          },
        ]);
      } else {
        // Clear form error
        form.setFields([
          {
            name: `${i}_role`,
            value: role,
            errors: [],
          },
        ]);
      }
    });

    if (isFormValid) {
      onFinish(formValues);
    }
  };

  const handleSubmitFailed = data => {
    if (optionalInvite) {
      if (!Object.values(data.values).filter(Boolean).length) {
        form.resetFields();
        onFinish([]);
      }
    }
  };

  const FORM_COLUMN_SPAM = useMemo(() => {
    if (requireEmployeeID) {
      return {
        firstName: { xs: 24, md: 12, lg: 5 },
        lastName: { xs: 24, md: 12, lg: 5 },
        email: { xs: 24, md: 12, lg: 8 },
        role: { xs: 24, md: 12, lg: 6 },
        groupId: { xs: 24, md: 12, lg: 12 },
        employeeId: { xs: 24, md: 12, lg: 12 },
        department: { xs: 24, md: 12, lg: 12 },
      };
    } else {
      return {
        firstName: { xs: 24, md: 12, lg: 5 },
        lastName: { xs: 24, md: 12, lg: 5 },
        email: { xs: 24, md: 12, lg: 8 },
        role: { xs: 24, md: 12, lg: 6 },
        groupId: { xs: 24, md: 12, lg: 12 },
        department: { xs: 24, md: 12, lg: 12 },
      };
    }
  }, [requireEmployeeID]);

  const handleFormFieldsValidate = () => {
    setIsSubmitting(true);
    form
      .validateFields()
      .then(async values => {
        await handleSubmit(values).finally(() => {
          setIsSubmitting(false);
        });
      })
      .catch(async values => {
        await handleSubmitFailed(values);
        setIsSubmitting(false);
      });
  };

  const toggleUserAddress = i => {
    setEnabledAddressForUser(state => {
      if (!state[i] === false) {
        const updatedUserRows = formValues;
        updatedUserRows[i] = {
          ...updatedUserRows[i],
          streetOne: undefined,
          streetTwo: undefined,
          country: undefined,
          state: undefined,
          city: undefined,
          postalCode: undefined,
        };

        form.setFieldsValue({
          [`${i}_streetOne`]: undefined,
          [`${i}_streetTwo`]: undefined,
          [`${i}_country`]: undefined,
          [`${i}_state`]: undefined,
          [`${i}_city`]: undefined,
          [`${i}_postalCode`]: undefined,
        });
        setFormValues([...updatedUserRows]);
      }

      return {
        ...state,
        [i]: !state[i],
      };
    });
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      onFinishFailed={handleSubmitFailed}
      initialValues={formValues.reduce((allUsers, user, i) => {
        return {
          ...allUsers,
          [`${i}_firstName`]: user.firstName,
          [`${i}_lastName`]: user.lastName,
          [`${i}_email`]: user.email,
          [`${i}_role`]: user.role,
          [`${i}_groupId`]: user.groupId,
          [`${i}_streetOne`]: user.streetOne,
          [`${i}_streetTwo`]: user.streetTwo,
          [`${i}_country`]: user.country,
          [`${i}_state`]: user.state,
          [`${i}_city`]: user.city,
          [`${i}_postalCode`]: user.postalCode,
          [`${i}_sendActivationEmailImmediately`]: true,
          [`${i}_sendActivationEmailDate`]: user.sendActivationEmailDate,
        };
      }, {})}
    >
      {formValues.map((value, i) => (
        <Row gutter={15} key={i} className={classNames.userFormRow}>
          <Col flex={1}>
            <Row gutter={15}>
              <Col {...FORM_COLUMN_SPAM.firstName}>
                <Form.Item
                  label={t('firstName')}
                  name={`${i}_firstName`}
                  validateTrigger={['onSubmit', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      message: ERROR_MESSAGE().BLANK_FIELD,
                    },
                    {
                      min: 2,
                      message: ERROR_MESSAGE().STRING_MIN_LENGTH(2),
                    },
                    {
                      pattern: USER_NAME_VALIDATION_REGEXP,
                      message: t('numberOrSpecialCharsNotAllowed'),
                    },
                    {
                      max: NAME_MAX_CHARACTER,
                      message: ERROR_MESSAGE().MAX_PASSWORD_LENGTH,
                    },
                  ]}
                >
                  <TextInput onChange={handleFieldChange} autoComplete="given-name" />
                </Form.Item>
              </Col>

              <Col {...FORM_COLUMN_SPAM.lastName}>
                <FormItem
                  label={t('lastName')}
                  name={`${i}_lastName`}
                  validateTrigger={['onSubmit', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      message: ERROR_MESSAGE().BLANK_FIELD,
                    },
                    {
                      min: 2,
                      message: ERROR_MESSAGE().STRING_MIN_LENGTH(2),
                    },
                    {
                      pattern: USER_NAME_VALIDATION_REGEXP,
                      message: t('numberOrSpecialCharsNotAllowed'),
                    },
                    {
                      max: NAME_MAX_CHARACTER,
                      message: ERROR_MESSAGE().MAX_PASSWORD_LENGTH,
                    },
                  ]}
                >
                  <TextInput onChange={handleFieldChange} autoComplete="family-name" />
                </FormItem>
              </Col>

              <Col {...FORM_COLUMN_SPAM.email}>
                <FormItem
                  required
                  label={t('email')}
                  name={`${i}_email`}
                  validateTrigger={['onChange', 'onSubmit', 'onBlur']}
                  rules={EMAIL_VALIDATION_FORM_RULES()}
                >
                  <TextInput type="email" onChange={handleFieldChange} autoComplete="email" />
                </FormItem>
              </Col>

              <Col {...FORM_COLUMN_SPAM.role}>
                <FormItem label={t('userRole')} required name={`${i}_role`}>
                  <Select
                    onChange={role =>
                      handleFieldChange({
                        target: {
                          id: `${i}_role`,
                          value: role,
                        },
                      })
                    }
                    options={UserRoleSelectOptions}
                  />
                </FormItem>
              </Col>

              {requireGroupID && (
                <Col {...FORM_COLUMN_SPAM.groupId}>
                  <Form.Item
                    name={`${i}_groupId`}
                    rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
                    label={t('associatedGroup')}
                  >
                    <Select
                      placeholder={t('selectGroup')}
                      options={
                        groupList
                          ? groupList.map((group, key) => ({ value: group._id, label: group.name }))
                          : []
                      }
                      onChange={groupId =>
                        handleFieldChange({
                          target: {
                            id: `${i}_groupId`,
                            value: groupId,
                          },
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              )}

              {requireEmployeeID && (
                <Col {...FORM_COLUMN_SPAM.employeeId}>
                  <FormItem
                    required
                    label={t('employeeNumber')}
                    name={`${i}_employeeId`}
                    rules={EMPLOYEE_NUMBER_FORM_RULES()}
                  >
                    <TextInput placeholder={t('employeeNumber')} onChange={handleFieldChange} />
                  </FormItem>
                </Col>
              )}

              <Col {...FORM_COLUMN_SPAM.department}>
                <FormItem label={t('Cost Center')} name={`${i}_department`}>
                  <TextInput onChange={handleFieldChange} />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[24, 0]} align="middle">
              <Col xs={24} md={10}>
                <Form.Item name={`${i}_sendActivationEmailImmediately`} valuePropName="checked">
                  <Checkbox
                    defaultChecked
                    onChange={e => {
                      handleFieldChange({
                        target: {
                          ...e.target,
                          value: e.target.checked,
                        },
                      });

                      if (e.target.checked) {
                        handleFieldChange({
                          target: {
                            id: `${i}_sendActivationEmailDate`,
                            value: null,
                          },
                        });
                      } else {
                        handleFieldChange({
                          target: {
                            id: `${i}_sendActivationEmailDate`,
                            value: momentEST().add(2, 'hour').startOf('hour'),
                          },
                        });
                      }
                    }}
                  >
                    {t('sendActivationEmailImmediately')}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={24} md={10}>
                <Form.Item
                  label={t('sendActivationEmailDate')}
                  name={`${i}_sendActivationEmailDate`}
                  hidden={
                    !USER_PERMISSIONS.canChangeActivationEmailDate() ||
                    formValues[i].sendActivationEmailImmediately
                  }
                  rules={[
                    {
                      validateTrigger: ['onChange', 'onSubmit'],
                      validator: (_, value) => {
                        if (!value) return Promise.resolve();
                        if (momentEST().isAfter(momentEST(value))) {
                          return Promise.reject(t('dateMustBeInFuture'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    use12Hours
                    showNow={false}
                    format="YYYY-MM-DD hh:mm a zz"
                    onChange={date => {
                      handleFieldChange({
                        target: {
                          id: `${i}_sendActivationEmailDate`,
                          value: date,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="start" align="middle">
              <Col
                style={{ cursor: 'pointer', marginBottom: 10 }}
                onClick={() => toggleUserAddress(i)}
              >
                <Row gutter={5}>
                  <Col>
                    {enabledAddressForUser[i] ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                  </Col>
                  <Col>
                    <span>{t(enabledAddressForUser[i] ? 'removeAddress' : 'addAddress')}</span>
                  </Col>
                </Row>
              </Col>
            </Row>

            {enabledAddressForUser[i] && (
              <Row>
                <AddressForm
                  t={t}
                  formInstance={form}
                  countries={supportedCountries}
                  companyAddress={companyAddress}
                  values={formValues[i]}
                  fieldNames={{
                    streetOne: `${i}_streetOne`,
                    streetTwo: `${i}_streetTwo`,
                    country: `${i}_country`,
                    state: `${i}_state`,
                    city: `${i}_city`,
                    postalCode: `${i}_postalCode`,
                  }}
                  onValueChange={addressValues => {
                    Object.keys(addressValues).forEach(key => {
                      handleFieldChange({
                        target: {
                          id: `${i}_${key}`,
                          value: addressValues[key],
                        },
                      });
                    });
                  }}
                />
              </Row>
            )}

            {i !== formValues.length - 1 && (
              <HorizontalLineDivider color="#D1D3D9" marginBottom="39px" />
            )}
          </Col>

          <Col>
            <div style={{ width: 14, marginTop: 30 }}>
              {i === formValues.length - 1 && i !== 0 && (
                <DeleteFilled className="item-container-trash" onClick={handleUserRowRemoval} />
              )}
            </div>
          </Col>
        </Row>
      ))}

      <Row justify="end" align="middle" style={{ marginBottom: 20 }}>
        <Col style={{ cursor: 'pointer' }} onClick={handleAddNewUserRow}>
          <Row gutter={5}>
            <Col>
              <PlusCircleOutlined />
            </Col>
            <Col>
              <span>{t('addAnotherUser')}</span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={17} justify="end" align="middle">
        <Col>
          <Button type="secondary" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Col>

        <Col>
          {isOnboarding ? (
            <Button
              type="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={handleFormFieldsValidate}
            >
              {t(
                optionalInvite && !Object.values(form.getFieldsValue()).filter(Boolean).length
                  ? 'Next'
                  : 'invite',
              )}
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={handleFormFieldsValidate}
            >
              {t('invite')}
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

InviteMultipleUsersForm.defaultProps = {
  requireGroupID: true,
};

export default withNamespaces()(InviteMultipleUsersForm);
