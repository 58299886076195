import React from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import withAuthentication from '../../hocs/withAuthentication';
import { formatPageTitle } from '../../utils/common';
import ReimbursementsDetailsOnPeriodView from './limit-one-receipt-reimbursements/details';
import UserReimbursementsDetails from './reimbursements/details';

const PageHelmet = () => (
  <Helmet>
    <title>{formatPageTitle('Reimbursement Details')}</title>
  </Helmet>
);

const ReimbursementDetailsPageSwitcher = props => {
  const companySettings = useSelector(store => store.common.currentCompany.companySettingId);

  if (companySettings?.settings?.hideReceipts) {
    return (
      <>
        <PageHelmet />
        <ReimbursementsDetailsOnPeriodView {...props} />;
      </>
    );
  }

  return (
    <>
      <PageHelmet />
      <UserReimbursementsDetails {...props} />;
    </>
  );
};

export default withNamespaces()(withAuthentication(ReimbursementDetailsPageSwitcher));
