import { find, get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { resendActivationEmail } from '../../api/auth';
import { sendAppLink } from '../../api/emails';
import { USER_API } from '../../api/user';
import Toast from '../../components/Toast';
import useInfinitePeriodByGroupQuery from '../../hooks/queries/useInfinitePeriodByGroupQuery';
import i18n from '../../i18n';
import { getMinutesAsMilliseconds } from '../../utils/numbers';
import { hasCompanyManagerOrAdminRole, hasUserRole } from '../../utils/roles';
import { selectStoreCurrentAuthUser } from '../../utils/storeSelectors';

const useDashboardState = ({ myProfile, timelineFilters }) => {
  const authUser = useSelector(selectStoreCurrentAuthUser);

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [addUsersModalVisibility, setAddUsersModalVisibility] = useState(false);

  const [groupIdFilter, startDateFilter, endDateFilter] = useMemo(
    () => [
      get(timelineFilters, 'groupID'),
      get(timelineFilters, 'startDate'),
      get(timelineFilters, 'endDate'),
    ],
    [timelineFilters],
  );
  const {
    query: groupPeriodsQuery,
    fullyFlatPages: groupPeriodsFlatPages,
  } = useInfinitePeriodByGroupQuery(groupIdFilter, {
    enabled: hasCompanyManagerOrAdminRole(authUser),
  });

  const canFetchDashboardData = useCallback(() => {
    if (
      hasCompanyManagerOrAdminRole(authUser) &&
      timelineFilters.groupID &&
      groupPeriodsQuery.isFetched
    ) {
      return (
        !!find(groupPeriodsFlatPages, { hasAdHocPeriod: true }) ||
        (!!startDateFilter && !!endDateFilter)
      );
    }

    return hasUserRole(authUser) && !!groupIdFilter;
  }, [
    authUser,
    timelineFilters.groupID,
    groupPeriodsQuery.isFetched,
    groupPeriodsFlatPages,
    groupIdFilter,
    startDateFilter,
    endDateFilter,
  ]);

  const dashboardDataQuery = useQuery({
    placeholderData: {},
    staleTime: getMinutesAsMilliseconds(5),
    queryKey: [
      'fetchDashboardData',
      authUser?.profile?._id,
      groupIdFilter,
      startDateFilter,
      endDateFilter,
    ],
    enabled: canFetchDashboardData(),
    queryFn: () =>
      USER_API.fetchDashboardData({
        groupId: groupIdFilter,
        ...(hasCompanyManagerOrAdminRole(authUser) && startDateFilter && endDateFilter
          ? {
              startDate: startDateFilter,
              endDate: endDateFilter,
            }
          : {}),
      }),
  });

  /**
   * Resend the Activation email to a given user
   *
   * @param {string} email Email of User
   */
  const handleResendActivationEmail = async email => {
    setIsSendingEmail(true);
    await resendActivationEmail(email, () => {
      Toast({
        type: 'open',
        message: i18n.t('activationEmailSent'),
      });
    });
    setIsSendingEmail(false);
  };

  const handleVisibility = value => {
    setAddUsersModalVisibility(value);
  };

  /**
   * Send an email to a given User with a link to the mobile app
   *
   * @param {string} userId ID of User
   */
  const handleSendAppLink = async userId => {
    setIsSendingEmail(true);
    await sendAppLink(userId, ({ message }) => {
      Toast({
        type: 'open',
        message: message || i18n.t('emailWithLinkSent'),
      });
    });
    setIsSendingEmail(false);
  };

  return {
    isSendingEmail,
    addUsersModalVisibility,
    dashboardDataQuery,
    handleResendActivationEmail,
    handleVisibility,
    handleSendAppLink,
  };
};

export default useDashboardState;
