import { Col, Row, Spin } from 'antd';
import { find } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useInfinitePeriodByGroupQuery from '../../hooks/queries/useInfinitePeriodByGroupQuery';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { checkIfElementIsScrolledToBottom } from '../../utils/common';
import { formatGroupPeriodsSelectOptions } from '../../utils/groups';
import { hasCompanyManagerOrAdminRole } from '../../utils/roles';
import { selectStoreCurrentAuthUser } from '../../utils/storeSelectors';
import FormItem from '../Form/FormItem';
import Select from '../Select';

const GroupPeriodLookupSelect = props => {
  const {
    t,
    value,
    groupId,
    showAllOption,
    onChange,
    notFoundContent,
    hideOnEmpty,
    hideFormItem,
    onEmptyPeriods,
    formItemProps,
    disabled,
    ...rest
  } = props;

  const authUser = useSelector(selectStoreCurrentAuthUser);

  const [initialCount, setInitialCount] = useState();
  const [hasAdHocPeriod, setHasAdHocPeriod] = useState(false);

  const {
    query: groupPeriodsQuery,
    flatPages,
    fullyFlatPages,
  } = useInfinitePeriodByGroupQuery(groupId, { enabled: hasCompanyManagerOrAdminRole(authUser) });

  const options = useMemo(() => {
    let optionsArray = formatGroupPeriodsSelectOptions(flatPages);

    if (showAllOption) {
      optionsArray = [{ value: null, label: t('All') }, ...optionsArray];
    }

    return optionsArray;
  }, [t, showAllOption, flatPages]);

  const handleChange = val => {
    if (typeof onChange === 'function') {
      onChange(val);
    }
  };

  const handleOptionsScroll = e => {
    const bottom = checkIfElementIsScrolledToBottom(e.target);

    if (bottom && !groupPeriodsQuery.isFetchingNextPage && groupPeriodsQuery.hasNextPage) {
      groupPeriodsQuery.fetchNextPage();
    }
  };

  const [SelectWrapper, selectWrapperProps] = useMemo(
    () =>
      hideFormItem ? [React.Fragment, {}] : [FormItem, { label: t('period'), ...formItemProps }],
    [t, hideFormItem, formItemProps],
  );

  useDidUpdateEffect(() => {
    if (
      typeof fullyFlatPages[0]?.count !== 'undefined' &&
      initialCount !== fullyFlatPages[0]?.count
    ) {
      setInitialCount(fullyFlatPages[0]?.count);
    }
  }, [fullyFlatPages, initialCount]);

  useDidUpdateEffect(() => {
    const filteredPages = flatPages.filter(period => !!period.start && !!period.end);
    if (!!fullyFlatPages.length && !filteredPages.length && typeof onEmptyPeriods === 'function') {
      onEmptyPeriods();
    }
  }, [fullyFlatPages, flatPages, onEmptyPeriods]);

  useDidUpdateEffect(() => {
    if (
      !!fullyFlatPages.length > 0 &&
      ((showAllOption && typeof value === 'undefined') || (!showAllOption && !value))
    ) {
      const period =
        find(fullyFlatPages[0]?.periods, { period: 'present' }) || fullyFlatPages[0]?.periods?.[0];

      const periodValue =
        period && !!period?.start && !!period?.end
          ? JSON.stringify({ fromDate: period?.start, endDate: period?.end })
          : null;

      if (periodValue !== value) {
        handleChange(periodValue);
      }
    }
  }, [fullyFlatPages, showAllOption, value]);

  useDidUpdateEffect(() => {
    const hasAdHoc = !!find(fullyFlatPages, { hasAdHocPeriod: true });
    if (hasAdHoc !== hasAdHocPeriod) {
      setHasAdHocPeriod(hasAdHoc);
    }
  }, [fullyFlatPages]);

  if (hideOnEmpty && (!flatPages.length || hasAdHocPeriod)) return null;

  return (
    <SelectWrapper {...selectWrapperProps}>
      <Select
        {...rest}
        value={value}
        onSelect={handleChange}
        fullWidth
        showSearch={false}
        allowClear={false}
        disabled={disabled || (!options.length && groupPeriodsQuery.isFetching)}
        loading={
          groupPeriodsQuery.isFetchingNextPage || (!options.length && groupPeriodsQuery.isFetching)
        }
        options={options}
        placeholder={t('selectPeriod')}
        onPopupScroll={handleOptionsScroll}
        notFoundContent={
          groupPeriodsQuery.isFetching ? (
            <Row justify="center" align="middle">
              <Col>
                <Spin size="default" />
              </Col>
            </Row>
          ) : initialCount === 0 ? (
            /* message to display only if the initial search has not results,
           hence any other search term queries will be return the same */
            notFoundContent
          ) : undefined
        }
      />
    </SelectWrapper>
  );
};

GroupPeriodLookupSelect.defaultProps = {
  formItemProps: {},
};

export default GroupPeriodLookupSelect;
