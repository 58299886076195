import React, { useMemo } from 'react';

import { checkIfStringContainsValue } from '../../../utils/common';
import { CustomTable } from '../..';

const StandardVehiclesTable = props => {
  const { t, dataSource, defaultSearchTerm, ...rest } = props;

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (defaultSearchTerm) {
      array = array.filter(item => {
        return (
          checkIfStringContainsValue(item.year, defaultSearchTerm) ||
          checkIfStringContainsValue(item.make, defaultSearchTerm) ||
          checkIfStringContainsValue(item.model, defaultSearchTerm) ||
          checkIfStringContainsValue(item.trim, defaultSearchTerm)
        );
      });
    }

    return array;
    // eslint-disable-next-line
  }, [dataSource, defaultSearchTerm]);

  const COLUMNS = [
    {
      title: t('year'),
      dataIndex: 'year',
    },
    {
      title: t('make'),
      dataIndex: 'make',
    },
    {
      title: t('model'),
      dataIndex: 'model',
    },
    {
      title: t('trim'),
      dataIndex: 'trim',
    },
  ];

  return <CustomTable {...rest} columns={COLUMNS} dataSource={filteredDataSource} />;
};

StandardVehiclesTable.defaultProps = {
  dataSource: [],
};

export default StandardVehiclesTable;
