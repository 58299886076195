import { Col, Row } from 'antd';
import React from 'react';

import Text from '../Text';
import Box from '.';
import classNames from './style.module.scss';

const BoxWithHeader = props => {
  const { header, extra, headerPadding, contentPadding, variant, children, ...rest } = props;

  return (
    <Box padding="0px" variant={variant} {...rest}>
      <Row justify="space-between" align="middle" gutter={5} style={{ padding: headerPadding }}>
        <Col>
          {typeof header === 'string' ? (
            <Text variant="h4" className={classNames.header}>
              {header}
            </Text>
          ) : (
            header
          )}
        </Col>

        {extra && <Col>{extra}</Col>}
      </Row>

      <Box noShadow variant={variant} padding={contentPadding}>
        {children}
      </Box>
    </Box>
  );
};

BoxWithHeader.defaultProps = {
  headerPadding: 24,
  contentPadding: 24,
};

export default BoxWithHeader;
