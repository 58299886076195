import { Row } from 'antd';
import CompanyForm from 'components/CompanyForm';
import Spinner from 'components/Spinner';
import Toast from 'components/Toast';
import PageContainer from 'containers/PageContainer';
import { INTERNAL_LINKS, USER_REG_STATUS } from 'enum';
import { pick } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveCompany } from 'stores/actions/common';
import { setRegStatus } from 'stores/actions/user';

import {
  handleApiCalls,
  handleApiErrors,
  performApiCallIfCompanySubIsActive,
} from '../../../api/axiosInstance';
import Text from '../../../components/Text';
import { emitFetchTimezones } from '../../../stores/actions/common';
import { formatPageTitle } from '../../../utils/common';
import { ERROR_MESSAGE } from '../../../utils/constants';
import companyClassNames from '../../company/info-form/style.module.scss';
import classNames from './style.module.scss';

const CompanyInfo = ({ t, ...props }) => {
  const history = useHistory();
  const { timezones, currentCompany, supportedCountries } = useSelector(state => state.common);
  const regStatus = useSelector(state => state.user.regStatus);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(emitFetchTimezones());

    return () => {
      setLoading(false);
    };
    // eslint-disable-next-line
  }, []);

  const updateCompany = async values => {
    setLoading(true);

    try {
      const result = await performApiCallIfCompanySubIsActive(
        'put',
        `${process.env.REACT_APP_HOST_API}company/${currentCompany._id}`,
        {
          name: currentCompany.name,
          country: values.country,
          city: values.city,
          state: values.state,
          postalCode: values.postalCode,
          streetOne: values.streetOne,
          ...(values.streetTwo ? { streetTwo: values.streetTwo } : {}),
          currencyUnit: values.currencyUnit,
          numEmployee: values.numEmployee,
          phone: values.phone,
          preferredTimezone: values.preferredTimezone,
          timeFormat: values.timeFormat,
          logoImage: values.logoImage,
        },
      );

      if (result.data.success) {
        moment.tz.setDefault(values.preferredTimezone);

        // if user has completed this step before
        // skip regStatus update
        await dispatch(
          saveCompany(
            pick(result.data.data, [
              'address',
              'currencyUnit',
              'name',
              'numEmployee',
              'phone',
              'preferredTimezone',
            ]),
          ),
        );
        if (regStatus < USER_REG_STATUS.PRICES) {
          try {
            const regStatusUpdateResult = await handleApiCalls(
              'patch',
              `${process.env.REACT_APP_HOST_API}company/${currentCompany._id}/update-registration-status`,
              {
                regStatus: USER_REG_STATUS.PRICES,
              },
            );
            if (regStatusUpdateResult.data.success) {
              await dispatch(setRegStatus(USER_REG_STATUS.PRICES));
              await dispatch(
                saveCompany({
                  regStatus: regStatusUpdateResult.data.data.regStatus,
                  regCompleted: regStatusUpdateResult.data.data.regCompleted,
                }),
              );
              setLoading(false);
            }
          } catch (error) {
            setLoading(false);
            return;
          }
        }
        history.push(INTERNAL_LINKS.COMPANY_ONBOARDING);
      } else {
        return;
      }
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: ERROR_MESSAGE().UPDATE_COMPANY,
        });
      });

      setLoading(false);
      return;
    }
    setLoading(false);
  };

  return (
    <div className={companyClassNames.companyPage}>
      <Helmet>
        <title>{formatPageTitle('Company Information | Onboarding')}</title>
      </Helmet>

      <PageContainer title="" className={`${classNames.companyInfo} onboarding company-page`}>
        <Text variant="h2" textAlign="center">
          {t('companyInformation')}
        </Text>

        <Row>
          <div className="company-name">
            {currentCompany.name ? currentCompany.name.toLowerCase() : ''}
          </div>
        </Row>

        <div className="company-header">
          <CompanyForm
            t={t}
            isOnboarding
            updateCompany={updateCompany}
            currentCompany={currentCompany}
            history={props.history}
            timezones={timezones}
            countries={supportedCountries}
          />
        </div>

        {loading && (
          <div className="loading-container">
            <Spinner />
          </div>
        )}
      </PageContainer>
    </div>
  );
};

export default withNamespaces()(CompanyInfo);
