import { Col, Form, Radio as AntdRadio, Row } from 'antd';
import React from 'react';

import Radio from '../../../components/Radio';
import SubmitCancelButtonGroup from '../../../components/SubmitCancelButtonGroup';

const DefaultMapViewProviderForm = props => {
  const { t } = props;
  return (
    <Form>
      <Form.Item>
        <AntdRadio.Group>
          <Row gutter={[0, 8]}>
            <Col flex="100%">
              <Radio value="mapbox" disabled>
                {t('geolocationForAllCompanies')}
              </Radio>
            </Col>

            <Col flex="100%">
              <Radio value="leaflet" disabled>
                {t('geofencingForAllCompanies')}
              </Radio>
            </Col>
          </Row>
        </AntdRadio.Group>
      </Form.Item>

      <SubmitCancelButtonGroup isForm hideCancel submitText={t('Save')} />
    </Form>
  );
};

export default DefaultMapViewProviderForm;
