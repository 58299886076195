import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import classNames from './style.module.scss';
import Box from '../Box';
import Avatar from '../Avatar';

import { getUserInitials } from '../../utils/common';

const RowButton = props => {
  const { icon, avatar, title, content, clickable, sideContent, onClick, disabled } = props;

  const classes = [classNames.rowBox];
  if (disabled) classes.push(classNames.disabled);
  if (clickable) classes.push(classNames.clickable);

  return (
    <Box
      noShadow
      variant={disabled ? 'gray' : undefined}
      padding="12px 24px"
      className={classes.join(' ')}
      onClick={!disabled ? onClick : undefined}
    >
      <Row align="middle" gutter={17}>
        <Col>
          {avatar ? (
            <Avatar size="large" src={avatar.photo} initials={getUserInitials(avatar)} />
          ) : (
            <img width="32px" src={icon} alt={title} />
          )}
        </Col>
        <Col flex={1}>{content}</Col>
        {sideContent && <Col>{sideContent}</Col>}
      </Row>
    </Box>
  );
};

RowButton.propTypes = {
  icon: PropTypes.string.isRequired,
  avatar: PropTypes.shape({
    photo: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  title: PropTypes.string,
  content: PropTypes.node.isRequired,
  clickable: PropTypes.bool,
  sideContent: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

RowButton.defaultProps = {
  title: undefined,
  clickable: true,
  disabled: false,
  avatar: undefined,
};

export default RowButton;
