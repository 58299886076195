import { isUndefined } from 'lodash';
import { useQuery } from 'react-query';

import { USER_API } from '../../api/user';

/**
 * @param {"string"} userId
 * @param {import('react-query').UseQueryOptions} otherOptions
 */
const useUserGroupsQuery = (userId, otherOptions = {}) => {
  return useQuery({
    enabled: !isUndefined(otherOptions.enabled) ? otherOptions.enabled && !!userId : !!userId,
    placeholderData: [],
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: ['fetchUserGroups', userId],
    queryFn: () => USER_API.fetchUserGroups(userId),
  });
};

export default useUserGroupsQuery;
