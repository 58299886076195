const drivers = [
  {
    value: '1-14',
    label: 'Less than 15 drivers',
  },
  {
    value: '15-99',
    label: '15-99 drivers',
  },
  {
    value: '100-499',
    label: '100-499 drivers',
  },
  {
    value: '500-1000',
    label: '500-1000 drivers',
  },
  {
    value: '1000+',
    label: 'More than 1000 drivers',
  },
];

export default drivers;
