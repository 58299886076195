import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchUserVehicles } from '../../api/vehicle';
import Toast from '../../components/Toast';
import i18n from '../../i18n';
import * as actions from '../actions/userVehicles';
import { emitUpdateUserVehicles } from '../actions/userVehicles';

export function* loadUserVehicles(action) {
  try {
    const userVehicles = yield select(store => store.userVehicles);

    if (!userVehicles[action.data.userID] || action.data.forceFetch) {
      const vehicles = yield call(fetchUserVehicles, action.data.userID);

      yield put(emitUpdateUserVehicles(action.data.userID, vehicles));
    }
  } catch (error) {
    Toast({
      type: 'error',
      message: i18n.t('errorLoadingUserVehicles'),
    });
  }
}

/**
 * watcher saga
 */
export const userVehiclesSaga = [takeLatest(actions.FETCH_USER_VEHICLES, loadUserVehicles)];
