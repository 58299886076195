import ContentDisposition from 'content-disposition';
import storage from 'store';

import Toast from '../components/Toast';
import i18n from '../i18n';
import { cleanQueryParams, removeFalseyParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';

const downloadPayrollFile = async (response, filename) => {
  let blob = await response.blob();
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();
};

export const REIMBURSEMENT_API = {
  fetchReimbursements: async params => {
    const queryParams = cleanQueryParams(params);
    const url = `${process.env.REACT_APP_HOST_API}reimbursements?${queryParams}`;
    const response = await handleApiCalls('get', url);
    return response.data;
  },
  exportReimbursementsToPayrollAuditsCSV: async (
    { reimbursementIds = [], paidAfterExport, ...filters },
    callback,
  ) => {
    const url = `${process.env.REACT_APP_HOST_API}payroll-audits`;
    const params = {
      ...filters,
      reimbursementIds: JSON.stringify(reimbursementIds),
      setStatusToPaid: paidAfterExport,
    };
    let formBody = [];
    for (let property in params) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');
    const result = await fetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + storage.get('accessToken'),
      },
      body: formBody,
    });
    if (result.status === 200) {
      const disposition = ContentDisposition.parse(result.headers.get('content-disposition'));
      const { filename } = disposition.parameters || {};
      await downloadPayrollFile(result, filename);

      if (typeof callback === 'function') callback();
    } else {
      await result.json().then(data => {
        Toast({
          type: 'error',
          message:
            data.errors && data.errors.reimbursementIds
              ? data.errors.reimbursementIds.msg
              : i18n.t('generateCSVError'),
        });
      });
    }
  },
  /**
   * Export reimbursement
   * @param {object} filters
   */
  exportReimbursement: async (filters = {}) => {
    const url = `${process.env.REACT_APP_HOST_API}reimbursements/export`;
    return handleApiCalls('post', url, removeFalseyParams(filters));
  },
};
