import { Col, Row } from 'antd';
import React from 'react';

import { IMAGES } from '../../enum';
import Text from '../Text';
import StepCircle from './StepCircle';
import classNames from './style.module.scss';

const SectionStepWrapper = props => {
  const {
    hideStepNumber,
    children,
    step,
    title,
    titleProps = {},
    isCompleted,
    showContent,
  } = props;

  return (
    <Row gutter={17} className={classNames.noWrapFlex} style={{ marginBottom: 20 }}>
      {!hideStepNumber && (
        <Col flex="24px">
          {!isCompleted ? (
            <StepCircle step={step} disabled={!showContent} />
          ) : (
            <img
              alt="Checkmark"
              src={IMAGES.CIRCLE_CHECK_MARK_ICON}
              width="24px"
              className="scale-in-center"
            />
          )}
        </Col>
      )}

      <Col flex={1}>
        {title && (
          <Row>
            <Text variant="h4" color={!showContent ? 'faded' : undefined} {...titleProps}>
              {title}
            </Text>
          </Row>
        )}
        <Row>
          {showContent && (
            <Col flex={1} className="fade-in-left">
              {children}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

SectionStepWrapper.propTypes = {};

export default SectionStepWrapper;
