import { Col, Layout, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import BoxWithHeader from '../../../components/Box/BoxWithHeader';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ItineraryMap from '../../../components/ItineraryMap';
import MapLoader from '../../../components/MapLoader';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import useItineraries from '../../../hooks/useItineraries';
import useItineraryMap from '../../../hooks/useItineraryMap';
import useLayout from '../../../layout/useLayout';
import { useStoreSelector } from '../../../utils/storeSelectors';
import DayMapSideMenu from './DayMapSideMenu';
import classNames from './styles.module.scss';

const RealtorDayMapBox = props => {
  const { t, onScheduleItineraryClick } = props;

  const { browserStore } = useLayout();
  const { isFetchingContacts } = useStoreSelector('realtor');
  const [selectedItinerary, setSelectedItinerary] = useState();
  const [selectedDates, setSelectedDates] = useDebouncedState(
    [moment(), moment().add(7, 'days')],
    1000,
  );
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const [mapInstance, setMapInstance] = useState();
  const {
    itinerariesQuery,
    itinerariesPaginationConfig,
    handleFilterUpdate,
    handleItinerariesPageChange,
  } = useItineraries({
    t,
    initialFilters: {
      fromDate: selectedDates[0].toISOString(),
      toDate: selectedDates[1].toISOString(),
    },
  });
  const { itineraryMarkers } = useItineraryMap(selectedItinerary);

  const handleCollapsedSidebar = collapsed => setIsSiderCollapsed(collapsed);

  useEffect(() => {
    if (itinerariesQuery.data?.documents?.length) {
      setSelectedItinerary(itinerariesQuery.data.documents[0]);
    }
  }, [itinerariesQuery.data]);

  useDidUpdateEffect(() => {
    handleFilterUpdate({
      fromDate: selectedDates[0].toISOString(),
      toDate: selectedDates[1].toISOString(),
    });
  }, [selectedDates, handleFilterUpdate]);

  useDidUpdateEffect(() => {
    if (mapInstance) {
      setTimeout(() => {
        mapInstance.resize();
      }, [200]);
    }
  }, [mapInstance, isSiderCollapsed]);

  const MAP_LOADING_CONTENT = useMemo(() => {
    if (browserStore.isMobile) return null;
    return (
      <Layout>
        <Layout.Content>
          {itinerariesQuery.isFetching ? (
            <Row justify="center" align="middle" className={classNames.mapLoaderRow}>
              <Col>
                <MapLoader />
              </Col>
            </Row>
          ) : (
            <ErrorBoundary t={t} fallback={<MapLoader text={t('errorLoadingMap')} />}>
              <ItineraryMap
                t={t}
                markers={itineraryMarkers}
                trips={selectedItinerary ? selectedItinerary.trips : []}
                routes={selectedItinerary ? selectedItinerary.routeCoordinates : []}
                onMapLoad={setMapInstance}
              />
            </ErrorBoundary>
          )}
        </Layout.Content>
      </Layout>
    );
    // eslint-disable-next-line
  }, [
    browserStore.isMobile,
    itinerariesQuery.isFetching,
    itineraryMarkers,
    selectedItinerary,
    setMapInstance,
  ]);

  const handleDateChange = useCallback(
    ([start, end]) => {
      setSelectedDates([start, end]);
    },
    [setSelectedDates],
  );

  return (
    <BoxWithHeader
      header={t('yourDayAtGlance')}
      contentPadding="0px"
      className={classNames.boxWrapper}
    >
      <Row
        justify="start"
        style={{ height: browserStore.isMobile ? undefined : 600, flexWrap: 'nowrap' }}
      >
        <Col flex={1}>
          <Layout style={{ height: '100%' }}>
            {MAP_LOADING_CONTENT}

            <Layout.Sider
              collapsible={!browserStore.isMobile}
              collapsed={isSiderCollapsed}
              onCollapse={handleCollapsedSidebar}
              style={{ position: 'initial' }}
              width={browserStore.isMobile ? '100%' : 300}
              collapsedWidth={40}
            >
              <DayMapSideMenu
                t={t}
                isMobile={browserStore.isMobile}
                selectedDates={selectedDates}
                visible={!isSiderCollapsed}
                isLoading={itinerariesQuery.isFetching || isFetchingContacts}
                itineraries={itinerariesQuery.data?.documents || []}
                onPageChange={handleItinerariesPageChange}
                paginationConfig={itinerariesPaginationConfig}
                selectedItinerary={selectedItinerary}
                onItinerarySelect={setSelectedItinerary}
                onScheduleItineraryClick={onScheduleItineraryClick}
                onDateChange={handleDateChange}
              />
            </Layout.Sider>
          </Layout>
        </Col>
      </Row>
    </BoxWithHeader>
  );
};

export default RealtorDayMapBox;
