import moment from 'moment-timezone';
import React from 'react';
import { Trans } from 'react-i18next';

import Toast from '../../components/Toast';
import i18n from '../../i18n';

/**
 * Validation function for the Trips creation
 *
 * @param {object} tripData
 */
export function validateManualTrip(tripData) {
  let isValid = true;

  const CREATE_TRIP_ERROR_TITLE = i18n.t('createTripErrorTitle');

  if (!tripData) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: i18n.t('cantCreateTripWithoutLocations'),
    });
  } else if (!tripData.purpose) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: i18n.t('tripPurposeRequired'),
    });
  } else if (!tripData.startDate) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: i18n.t('selectStartDate'),
    });
  } else if (!tripData.startTime) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: i18n.t('selectStartTime'),
    });
  } else if (!tripData.endDate) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: i18n.t('selectEndDate'),
    });
  } else if (!tripData.isKliksCalculatingEndTime && !tripData.endTime) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: i18n.t('selectEndTime'),
    });
  } else if (tripData.startDate.isAfter(moment(), 'date')) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <Trans t={i18n.t} i18nKey="startDateCannotBeInFuture" components={[<b>dummy</b>]} />
      ),
    });
  } else if (!tripData.isKliksCalculatingEndTime && tripData.endDate.isAfter(moment(), 'date')) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <Trans t={i18n.t} i18nKey="endDateCannotBeInFuture" components={[<b>dummy</b>]} />
      ),
    });
  } else if (
    !tripData.isKliksCalculatingEndTime &&
    tripData.startDate.isAfter(tripData.endDate, 'date')
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <Trans t={i18n.t} i18nKey="startDateCannotBeAfterTheEndDate" components={[<b>dummy</b>]} />
      ),
    });
  } else if (
    moment(
      `${tripData.startDate.format('MM/DD/YYYY')} ${tripData.startTime.format('HH:mm:ss')}`,
      'MM/DD/YYYY HH:mm:ss',
    ).isAfter(moment())
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <Trans t={i18n.t} i18nKey="startTimeCannotBeInFuture" components={[<b>dummy</b>]} />
      ),
    });
  } else if (
    !tripData.isKliksCalculatingEndTime &&
    moment(
      `${tripData.endDate.format('MM/DD/YYYY')} ${tripData.endTime.format('HH:mm:ss')}`,
      'MM/DD/YYYY HH:mm:ss',
    ).isAfter(moment())
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: CREATE_TRIP_ERROR_TITLE,
      description: (
        <Trans t={i18n.t} i18nKey="endTimeCannotBeInFuture" components={[<b>dummy</b>]} />
      ),
    });
  } else if (
    !tripData.isKliksCalculatingEndTime &&
    // If a trip's start DateTime is AFTER its end
    moment(
      `${tripData.startDate.format('MM/DD/YYYY')} ${tripData.startTime.format('HH:mm:ss')}`,
      'MM/DD/YYYY HH:mm:ss',
    ).isAfter(
      moment(
        `${tripData.endDate.format('MM/DD/YYYY')} ${tripData.endTime.format('HH:mm:ss')}`,
        'MM/DD/YYYY HH:mm:ss',
      ),
    )
  ) {
    isValid = false;
    Toast({
      type: 'error',
      message: <Trans t={i18n.t} i18nKey="startCannotBeAfterEnd" components={[<b>dummy</b>]} />,
    });
  }

  return isValid;
}
