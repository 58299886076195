import { Form } from 'antd';
import React from 'react';

import { ERROR_MESSAGE } from '../../utils/constants';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import TextInput from '../TextInput';

const CRMTicketForm = props => {
  const { t, onSubmit, onCancel, defaultValues, loading } = props;

  return (
    <Form onFinish={onSubmit} labelCol={{ span: 24 }} initialValues={defaultValues}>
      <Form.Item
        label={t('subject')}
        name="subject"
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput />
      </Form.Item>

      <Form.Item
        label={t('message')}
        name="description"
        rules={[{ required: true, message: ERROR_MESSAGE().BLANK_FIELD }]}
      >
        <TextInput textArea autoSize={{ minRows: 4, maxRows: 6 }} />
      </Form.Item>

      <SubmitCancelButtonGroup isForm loading={loading} onSubmit={onSubmit} onCancel={onCancel} />
    </Form>
  );
};

export default CRMTicketForm;
