import './style.scss';

import PropTypes from 'prop-types';
import React from 'react';
// the hoc
import { withNamespaces } from 'react-i18next';

import { CustomCheckboxGroup } from '../../components';

class SelectGroup extends React.Component {
  render() {
    const {
      disabled,
      groupsWithRates,
      currentRateName,
      options,
      defaultValues,
      onGroupChange,
      onCreateGroup,
      showPopover,
      companyGroups,
      t,
    } = this.props;

    return (
      <div className="select-group">
        <CustomCheckboxGroup
          companyGroups={companyGroups}
          currentRateName={currentRateName}
          options={options}
          groupsWithRates={groupsWithRates}
          onChange={onGroupChange}
          showPopover={showPopover}
          type="secondary"
          value={defaultValues}
          disabled={disabled}
        />
        <div className="select-group-container">
          <span className="create-group" onClick={disabled ? undefined : onCreateGroup}>
            {t('Create group')}
          </span>
        </div>
      </div>
    );
  }
}

SelectGroup.propTypes = {
  options: PropTypes.array,
  defaultValues: PropTypes.array,
  onGroupChange: PropTypes.func,
  onAllUserSelected: PropTypes.func,
  onCreateGroup: PropTypes.func,
};

SelectGroup.defaultProps = {
  options: [],
  defaultValues: [],
  onGroupChange: () => {},
  onAllUserSelected: () => {},
  onCreateGroup: () => {},
};

export default withNamespaces()(SelectGroup);
