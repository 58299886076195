import { USER_ROLES } from '../enum';

export const canFilterByDriver = userData => {
  if (!userData) return false;
  return [USER_ROLES.COMPANY_MANAGER, USER_ROLES.COMPANY_ADMIN].includes(userData.profile.role);
};

export const canFilterByGroup = userData => {
  if (!userData) return false;
  return [USER_ROLES.COMPANY_MANAGER, USER_ROLES.COMPANY_ADMIN].includes(userData.profile.role);
};
