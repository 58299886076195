import React, { useRef } from 'react';

import useIsInViewport from '../../ho../../hooks/useIsInViewport';
import LoadingSkeleton from '../LoadingSkeleton';

const VisibleWhenOnViewport = props => {
  const { children, height, threshold } = props;

  const ref = useRef();
  const isOnViewport = useIsInViewport(ref, threshold);

  return (
    <div ref={ref} style={{ minHeight: height }}>
      {isOnViewport ? children : <LoadingSkeleton style={{ height }} />}
    </div>
  );
};

VisibleWhenOnViewport.defaultProps = {
  height: 300,
};

export default VisibleWhenOnViewport;
