import './style.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Form, Spin } from 'antd';
import { Button } from 'components';
import AuthContainer from 'containers/Auth';
import { INTERNAL_LINKS } from 'enum';
import { get } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
// import ReCAPTCHA from 'react-google-recaptcha';
// the hoc
import { withNamespaces } from 'react-i18next';
import { isValidPassword } from 'utils/validate';

import { handleApiCalls, handleApiErrors } from '../../api/axiosInstance';
import TextInput from '../../components/TextInput';
import Toast from '../../components/Toast';
import { formatPageTitle } from '../../utils/common';

class ResetPassword extends React.Component {
  form = null;

  constructor(props) {
    super(props);

    // const secretKey =
    //   process.env.NODE_ENV === 'production'
    //     ? process.env.REACT_APP_RECAPTCHA_SECRET_KEY_PRODUCTION
    //     : process.env.REACT_APP_RECAPTCHA_SECRET_KEY_LOCAL;

    this.state = {
      recaptcha: '',
      loading: false,
      model: {
        name: {
          value: '',
        },
        email: {
          value: '',
        },
        password: {
          value: '',
        },
        confirmPassword: {
          value: '',
        },
      },
      // secretKey,
    };
  }

  componentDidMount() {
    this.isValidResetToken();
  }

  isValidResetToken = async () => {
    const { token } = this.props.match.params;

    try {
      const result = await handleApiCalls(
        'get',
        `${process.env.REACT_APP_HOST_API}auth/reset-password/${token}`,
      );

      if (result.status === 200) {
        const { data } = result.data;
        this.setState(
          state => {
            state.model.name.value = `${data.firstName} ${data.lastName}`;
            state.model.email.value = data.email;
          },
          () => {
            this.form.setFieldsValue({
              name: this.state.model.name.value,
              email: this.state.model.email.value,
            });
          },
        );
      }
    } catch (err) {
      if (new RegExp('request another password reset email').test(err?.response?.data)) {
        Toast({
          type: 'error',
          message: this.props.t('resetPasswordLinkExpired'),
          description: err.response.data,
        });
      } else {
        Toast({
          type: 'error',
          description: get(err.response, 'data', this.props.t('resetPasswordError')),
        });
      }

      this.goToForgotPassword();
    }
  };

  onFinish = async values => {
    const { recaptcha } = this.state;
    const { password, password_repeat } = values;
    const { token } = this.props.match.params;
    // if (!recaptcha) {
    //   Toast({
    //     type: 'error',
    //     message: 'Error',
    //     description: 'Please resolve the reCAPTCHA',
    //   });
    //   return;
    // }
    const url = `${process.env.REACT_APP_HOST_API}auth/reset-password/${token}`;
    const data = {
      password,
      password_repeat,
      reCaptchaResponse: recaptcha,
    };

    this.setState({ loading: true });
    try {
      await handleApiCalls('post', url, data);
      Toast({
        type: 'success',
        message: this.props.t('setNewPasswordSuccess'),
      });
      this.goToSignin();
    } catch (err) {
      handleApiErrors(err.response, () => {
        Toast({
          type: 'error',
          description: this.props.t('setNewPasswordError'),
        });
      });
    }
    this.setState({ loading: false });
  };

  onFinishFailed = errorInfo => {
    const { password, confirmPassword } = this.state.model;
    this.setState(state => {
      state.model.password = {
        ...this.validateFields('password', password),
        value: password,
      };
      state.model.confirmPassword = {
        ...this.validateFields('confirmPassword', confirmPassword),
        value: confirmPassword,
      };
      return state;
    });
  };

  onChange = value => {
    this.setState({ recaptcha: value });
  };

  goToForgotPassword = () => {
    this.props.history.push(INTERNAL_LINKS.FORGOT_PASSWORD);
  };

  goToSignin = () => {
    this.props.history.push(INTERNAL_LINKS.LOGIN);
  };

  validateFields = (field, value) => {
    let result = {};
    switch (field) {
      case 'password':
        switch (isValidPassword(value)) {
          case 0:
            result = {
              validateStatus: 'success',
              errorMsg: null,
            };
            break;
          case 1:
            result = {
              validateStatus: 'error',
              errorMsg: this.props.t('invalidPassword'),
            };
            break;
          case 2:
            result = {
              validateStatus: 'error',
              errorMsg: this.props.t('invalidPassword'),
            };
            break;
          case 3:
            result = {
              validateStatus: 'error',
              errorMsg: this.props.t('invalidPassword'),
            };
            break;
          case 4:
            result = {
              validateStatus: 'error',
              errorMsg: this.props.t('pleaseEnterYourPassword'),
            };
            break;
          case 5:
            result = {
              validateStatus: 'error',
              errorMsg: this.props.t('invalidPassword'),
            };
            break;

          default:
            break;
        }
        break;
      case 'confirmPassword':
        const { password } = this.state.model;
        if (value) {
          const isValid = isValidPassword(value);
          if (isValid === 0 && value === password.value) {
            result = {
              validateStatus: 'success',
              errorMsg: null,
            };
          } else {
            result = {
              validateStatus: 'error',
              errorMsg: this.props.t('passwordsDontMatch'),
            };
          }
        } else {
          result = {
            validateStatus: 'error',
            errorMsg: this.props.t('passwordsDontMatch'),
          };
        }
        break;
      default:
    }

    return result;
  };

  onValuesChange = values => {
    const { name, email, password, password_repeat } = values;
    this.setState(state => {
      if (name !== undefined) {
        state.model.name.value = name;
      }
      if (email !== undefined) {
        state.model.email.value = email;
      }
      if (password !== undefined) {
        state.model.password = {
          ...this.validateFields('password', password),
          value: password,
        };
      }
      if (password_repeat !== undefined) {
        state.model.confirmPassword = {
          ...this.validateFields('confirmPassword', password_repeat),
          value: password_repeat,
        };
      }
      return state;
    });
  };

  render() {
    const { model } = this.state;
    const { t } = this.props;
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

    return (
      <div className="rpp">
        <Helmet>
          <title>{formatPageTitle('Reset Password')}</title>
        </Helmet>

        <div className="rpp-container">
          <h5 className="rpp-container-title">{t('changePassword')}</h5>
          <div className="rpp-container-section">
            <Form
              labelCol={{ span: 24 }}
              className="rpp-container-section-form"
              name="basic"
              onValuesChange={this.onValuesChange}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              ref={node => (this.form = node)}
            >
              <Form.Item label={t('name')} name="name">
                <TextInput disabled />
              </Form.Item>
              <Form.Item label={t('Email')} name="email">
                <TextInput disabled />
              </Form.Item>
              <Form.Item
                label={t('Password')}
                name="password"
                validateStatus={model.password.validateStatus || 'success'}
                help={model.password.errorMsg}
                rules={[{ required: true, message: t('pleaseEnterYourPassword') }]}
              >
                <TextInput password />
              </Form.Item>
              <Form.Item
                label={t('verifyPassword')}
                name="password_repeat"
                validateStatus={model.confirmPassword.validateStatus || 'success'}
                help={model.confirmPassword.errorMsg}
                rules={[{ required: true, message: t('pleaseConfirmPassword') }]}
              >
                <TextInput password />
              </Form.Item>
              {/* <div className="d-flex justify-content-center mb-3">
                <ReCAPTCHA sitekey={this.state.secretKey} onChange={this.onChange} />
              </div> */}
              <div className="d-flex justify-content-center">
                <Button
                  text={t('changePassword')}
                  htmlType="submit"
                  className="rpp-container-submit text-center"
                />
              </div>
            </Form>
          </div>
        </div>
        {this.state.loading && (
          <div className="loading-container">
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>
    );
  }
}

export default AuthContainer(withNamespaces()(ResetPassword));
